import React, { useState, useEffect, useRef } from "react";
import { Button, Loader, Dimmer } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { StepperNav } from "vertical-stepper-nav";
import { Link } from "react-router-dom";
import config from "../../../config/config";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { storage, auth, db } from "../../../config/firebase";
import { connect } from "react-redux";
import {
  fetchAllSubDepartment,
  showShowZoneList,
  hideShowZoneList,
} from "../../../store/actions/incidentAction";
import { useMediaQuery } from "react-responsive";

import Geocode from "react-geocode";
import moment from "moment";
//pages
import Incident_overview from "./new_incidents_forms/Incident_overview";
import IncidentLocation from "./new_incidents_forms/IncidentLocation";
import PropertyDamageDetails from "./new_incidents_forms/PropertyDamageDetails";
import CasualtyDetails from "./new_incidents_forms/CasualtyDetails";
import WitnessParticulars from "./new_incidents_forms/WitnessParticulars";
import IncidentSummary from "./new_incidents_forms/IncidentSummary";
import PhotoVideo from "./new_incidents_forms/PhotoVideo";
import IncidentAnalysis from "./new_incidents_forms/IncidentAnalysis";

//images
import LeftArrowIcon from "../../../assets/images/incident/leftarrow.svg";
import RightArrowIcon from "../../../assets/images/incident/rightarrow.svg";
import DownArrowIcon from "../../../assets/images/incident/arrowdowndrop.svg";
import ExportImage from "../../../assets/images/incident/export.svg";
import ExitIcon from "../../../assets/images/incident/exit.svg";

// css
import "./new_incident.css";
import api from "../../../config/api";
import NotificationsSetting from "./new_incidents_forms/notifications/NotificationsSetting";
import NotificationsList from "./new_incidents_forms/notifications/NotificationsList";
import { RESET_PASSWORD_ROUTE } from "../../../routes";

function NewIncident(props) {
  const gApi = "AIzaSyCsvLI--q5rNFAGwq0Cgr7IWkh6y11x1wQ";
  Geocode.setApiKey(gApi);
  Geocode.setLanguage("en");
  Geocode.enableDebug();
  let history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramId = query.get("id");
  const paramPendingBool = query.get("pending");
  const scrollRef = useRef(null);
  const {
    CREATE_INCIDENTS,
    GET_ALL_NOTI_USERS,
    GET_SUB_DEPARTMENT,
    SEARCH_NOTI_USERS,
    GET_ALL_SUB_DEPARTMENT,
    CREATE_AUDIT_INCIDENTS,
    GET_USER_DETAILS,
  } = config.api_endpoint;

  const [createLoader, setCreateLoader] = useState(false);
  const [firstTimeClick, setFirstTimeClick] = useState(1);
  const [detailSelected, setDetailSelected] = useState("Incident Description");
  const [summarySelected, setSummarySelected] = useState(true);
  const [showCasualtyForm, setShowCasualtyForm] = useState(false);
  const [showWitnessForm, setShowWitnessForm] = useState(false);
  const [showZoneDropDown, setShowZoneDropDown] = useState(false);
  const [fakeId, setFake] = useState(Math.random());
  const [submitToggle, setSubmitToggle] = useState(false);
  const [changedValue, setChangedValue] = useState(false);
  const [rc, setRC] = useState(false);
  const [checkStatus, setCheckStatus] = useState(null);
  // incident options
  // options
  const [incidentTypeList, setIncidentTypeList] = useState([]);
  const [incidentPlacementList, setIncidentPlacementList] = useState([]);
  const [incidentClassificationList, setIncidentClassificationList] = useState(
    []
  );
  const [allSubDepartmentList, setAllSubDepartmentList] = useState([]);
  const [totalpageForAllSubDepart, setTotalPageForAllSubDepart] = useState(0);
  const [lastItemForAllSubDepart, setLastItemForAllSubDepart] = useState({
    from: 0,
    to: 10,
  });
  const [subDepartmentForPagination, setSubDepartmentForPagination] = useState(
    []
  );
  const [currentAllSubDepartPage, setCurrentAllSubDepartPage] = useState(1);
  const [injuryTypesList, setInjuryTypesList] = useState([]);
  // from
  const [parkList, setParkList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [subStandardActionList, setSubStandardActionList] = useState([]);
  const [subStandardContionList, setSubStandardContionList] = useState([]);
  const [personnelFactorsList, setPersonnelFactorsList] = useState([]);
  const [jobFactorList, setJobFactorList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [notiSettingValues, setNotiSettingValues] = useState({
    frequency_type: "one time",
  });
  const [userListForNoti, setUserListForNoti] = useState({});
  const [userListForNotiLoader, setUserlistForNotiLoader] = useState(false);
  const [departFoldState, setDepartFoldState] = useState([]);
  const [nt, setNt] = useState(false);
  const [departState, setDepartState] = useState([]);
  const [usersState, setUsersState] = useState([]);
  const [haveFakeId, setHaveFakeId] = useState(null);
  // dropdown id
  const [dropdownId, setDropdownId] = useState({
    incidentPlacementId: "",
    incidentClassificationId: "",
    injuryTypesId: "",
    // incidentTypesId: "",
  });
  const [showNotiPage, setShowNotiPage] = useState(false);
  const [searchBy, setSearchBy] = useState("department");
  // forms values
  const [overViewValues, setOverViewValues] = useState({
    incident_type: "",
    incident_placement: "",
    is_emergency_incident: "No",
    is_followup_required: "Yes",
    category: "c1",
    incident_classification: "",
    recall_people: "No",
    injury_type: [],
    emergency_services_activated: [],
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [locationValues, setLocationValues] = useState({
    park: "",
    weather: "",
    area_condition: [],
    date: moment(new Date()).format("D MMM yyyy"),
    time: moment(new Date()).format("HH:mm:ss"),
  });
  const [wait, setWait] = useState(false);
  const [mainZone, setMainZone] = useState(null);
  const [subZone, setSubZone] = useState("");
  const [zoneType, setZoneType] = useState("");
  // if values are not filled then true
  const [canCreate, setCanCreate] = useState(false);
  // click save button then true after this false
  const [toApi, setToApi] = useState(false);
  // for view
  const [locationDate, setLocationDate] = useState({
    selectedDate: new Date(),
    selectedTime: new Date(),
  });

  //Remake state for notification
  const [reSubDepartmentList, setReSubDepartmentList] = useState([]);
  const [reUsersList, setReUsersList] = useState({});
  const [reFetchUser, setReFetchUser] = useState(false);
  const [reSearchDepartment, setReSearchDepartment] = useState([]);
  const [reSearchUsersWithDepart, setReSearchUsersWithDepart] = useState([]);
  const [clickSearchBtnNoti, setClickSearchBtnNoti] = useState("");

  // for view
  const [selectedAddress, setSelectedAddress] = useState(null);
  // combine all casualty forms
  const [casualtyValues, setCasualtyValues] = useState([]);
  const [witnessValues, setWitnessValues] = useState([]);
  const [summaryValues, setSummaryValues] = useState({
    incident_title: "",
    incident_remarks: "",
  });
  const [propertyValues, setPropertyValues] = useState({
    property_type: "",
    damaged_things: "",
    party_type: "",
    party_info: "",
    priority_of_fix: "",
  });
  const [photoValues, setPhotoValues] = useState([]);
  const [removedPhotoValues, setRemovedPhotoValues] = useState([]);
  const [newAddedSignature, setNewAddedSignature] = useState([]);
  const [analysis, setAnalysis] = useState({
    stoppage: "Yes",
    action: "",
    condition: "",
    personnel: "",
    job: "",
    stoppage_text: "",
  });
  const [searchUsersDataPagiantion, setSearchUsersPagination] = useState({});
  const [totalUsersPage, setTotalUserspage] = useState(0);
  const [lastUsersItem, setLastUsersItem] = useState({
    from: 0,
    to: 50,
  });
  // Incident Overview Errors
  const [typeError, setTypeError] = useState(false);
  const [placementError, setPlacementError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [involvedError, setInvolvedError] = useState(false);
  const [classificationError, setClassificationError] = useState(false);
  const [injuryTypeError, setSetInjuryTypeError] = useState(false);

  // Incidet Timestamp Erros
  const [parkError, setParkError] = useState(false);
  const [zoneError, setZoneError] = useState(false);
  const [weatherError, setWeatherError] = useState(false);
  const [areaError, setAreaError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);

  //Incident Summary Errors
  const [titleError, setTitleError] = useState(false);
  const [remarkError, setRemarkError] = useState(false);

  // Incident Property Erros
  const [propertyInputError, setPropertyInputError] = useState(false);
  const [damagesError, setDamagesError] = useState(false);
  const [damagesErrorTxt, setDamagesErrorTxt] = useState(false);
  const [partyTypeError, setPartyTypeError] = useState(false);
  const [partyTypeErrorTxt, setPartyTypeErrorTxt] = useState("");

  const [partyError, setPartyError] = useState(false);
  const [partyErrorTxt, setPartyErrorTxt] = useState(false);
  const [proxityError, setProxityError] = useState(false);

  // Incident AnalysisErros
  const [actionError, setActionError] = useState(false);
  const [conditionError, setConditionError] = useState(false);
  const [personnelError, setPersonnelError] = useState(false);
  const [jobError, setJobError] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [checkAllDepartmentNoti, setCheckAllDepartmentNoti] = useState([]);
  const [userNameForSearch, setUserNameForSearch] = useState("");
  // sidebar error if values are empty
  const [overViewError, setOverViewError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [casualtyError, setCasualtyError] = useState(false);
  const [witnessError, setWitnessError] = useState(false);
  const [summaryError, setSummaryError] = useState(false);
  const [propertyError, setPropertyError] = useState(false);
  const [photoError, setPhotoError] = useState(false);
  const [analysisError, setAnalysisError] = useState(false);

  // Remake Function for notifcation

  useEffect(() => {
    if (overViewValues.is_emergency_incident === "Yes") {
      api
        .get(GET_ALL_SUB_DEPARTMENT, {
          platform: "web",
        })
        .then((res) => {
          let data = [];
          res.data.map((d) => {
            data.push({
              title: d,
              state: true,
              displayUserList: false,
            });
          });
          setReSubDepartmentList(data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      api
        .get(GET_SUB_DEPARTMENT, {
          platform: "web",
          classification: overViewValues.incident_classification,
          classification_id: dropdownId.incidentClassificationId,
          is_emergency:
            overViewValues.is_emergency_incident === "Yes" ? true : false,
          id: "",
        })
        .then((res) => {
          let data = [];
          res.data.map((d) => {
            data.push({
              title: d.name,
              state:
                d.status === 1 ? true : d.status === -1 ? "minusSign" : false,
              displayUserList: false,
            });
          });
          setReSubDepartmentList(data);
        })
        .catch((err) => {
          console.log("err...", err);
        });
    }
  }, [showNotiPage, overViewValues]);

  const reHandleImageClickDepartment = (sub_department, isSearch) => {
    console.log("click", sub_department, isSearch);
    setReFetchUser(true);
    let cloneReSubdepartmentList = reSubDepartmentList;
    let cloneReSearchSubDepartment = reSearchDepartment;

    if (isSearch) {
      let findIndex = cloneReSearchSubDepartment.findIndex(
        (f) => f.title === sub_department
      );
      if (findIndex != -1) {
        if (!reUsersList[sub_department]) {
          reHandleClickDepartment(sub_department);
          setRC(!rc);
        }
        cloneReSearchSubDepartment[findIndex].displayUserList =
          !cloneReSearchSubDepartment[findIndex].displayUserList;

        setReSearchDepartment(cloneReSearchSubDepartment);
        setRC(!rc);
      }
    } else {
      let findIndex = cloneReSubdepartmentList.findIndex(
        (f) => f.title === sub_department
      );
      if (findIndex != -1) {
        if (!reUsersList[sub_department]) {
          reHandleClickDepartment(sub_department);
          setRC(!rc);
        }
        cloneReSubdepartmentList[findIndex].displayUserList =
          !cloneReSubdepartmentList[findIndex].displayUserList;

        setReSubDepartmentList(cloneReSubdepartmentList);
        setRC(!rc);
      }
    }

    setRC(!rc);
  };

  const handleSubDepartCheckBox = (sub_department, checked, isSearch) => {
    setReFetchUser(false);
    let cloneReSubdepartmentList = reSubDepartmentList;
    let cloneReSearchSubDepartment = reSearchDepartment;

    if (isSearch) {
      let findIndex = cloneReSearchSubDepartment.findIndex(
        (f) => f.title == sub_department
      );

      if (findIndex != -1) {
        if (!reUsersList[sub_department]) {
          reHandleClickDepartment(sub_department, "Go", checked);
        } else {
          if (checked === true || checked === false) {
            let cloneUsersList = reUsersList;
            let searchUsersByDepart = cloneUsersList[sub_department];
            let data = [];
            searchUsersByDepart.map((user) => {
              data.push({
                id: user.id,
                state: checked,
                name: user.name,
                platform: user.platform,
                key: sub_department,
              });
            });
            cloneUsersList[sub_department] = data;
            setReUsersList(cloneUsersList);
          }
        }

        cloneReSearchSubDepartment[findIndex].state = checked;
        setReSearchDepartment(cloneReSearchSubDepartment);
        let cloneUserList = reUsersList;
        let searchDepartByusers = cloneUserList[sub_department];

        setRC(!rc);
      }
      let findIndex2 = cloneReSubdepartmentList.findIndex(
        (f) => f.title === sub_department
      );

      if (findIndex2 != -1) {
        if (!reUsersList[sub_department]) {
          reHandleClickDepartment(sub_department, "Go", checked);
        } else {
          if (checked === true || checked === false) {
            let cloneUsersList = reUsersList;
            let searchUsersByDepart = cloneUsersList[sub_department];
            let data = [];
            searchUsersByDepart.map((user) => {
              data.push({
                id: user.id,
                state: checked,
                name: user.name,
                platform: user.platform,
                key: sub_department,
              });
            });
            cloneUsersList[sub_department] = data;
            setReUsersList(cloneUsersList);
          }
        }

        cloneReSubdepartmentList[findIndex2].state = checked;
        setReSubDepartmentList(cloneReSubdepartmentList);
        setRC(!rc);
      }
    } else {
      let findIndex = cloneReSubdepartmentList.findIndex(
        (f) => f.title === sub_department
      );

      if (findIndex != -1) {
        if (!reUsersList[sub_department]) {
          reHandleClickDepartment(
            sub_department,

            "Go",
            checked
          );
        } else {
          if (checked === true || checked === false) {
            let cloneUsersList = reUsersList;
            let searchUsersByDepart = cloneUsersList[sub_department];
            let data = [];
            searchUsersByDepart.map((user) => {
              data.push({
                id: user.id,
                state: checked,
                name: user.name,
                platform: user.platform,
                key: sub_department,
              });
            });
            cloneUsersList[sub_department] = data;
            setReUsersList(cloneUsersList);
          }
        }

        cloneReSubdepartmentList[findIndex].state = checked;
        setReSubDepartmentList(cloneReSubdepartmentList);
        setRC(!rc);
      }
    }
  };

  const reHandleClickDepartment = (sub_department, hint, checked) => {
    api
      .get(GET_ALL_NOTI_USERS, {
        platform: "web",
        sub_department,
        id: "",
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        is_emergency:
          overViewValues.is_emergency_incident === "Yes" ? true : false,
      })
      .then((res) => {
        console.log("resy", res);
        if (res.data.length > 0) {
          let data = [];
          res.data.map((user) => {
            data.push({
              id: user.id,
              state: hint === "Go" ? checked : user.is_notified_user,
              name: user.name,
              platform: user.platform,
              key: sub_department,
            });
          });

          let groupUser = data.reduce((groupDepartment, user) => {
            const subDepartment = user.key;
            if (
              groupDepartment[subDepartment] === null ||
              groupDepartment[subDepartment] === undefined
            )
              groupDepartment[subDepartment] = [];
            groupDepartment[subDepartment].push(user);
            return groupDepartment;
          }, {});
          let cloneReUserList = reUsersList;
          if (cloneReUserList[sub_department] === null)
            cloneReUserList[sub_department] = [];
          cloneReUserList[sub_department] = groupUser[sub_department];

          setReUsersList(cloneReUserList);
          setReFetchUser(false);
          setRC(!rc);
        }
      })
      .catch((err) => {
        setReFetchUser(false);
        console.log("err", err);
      });
  };

  const reUserStateOnChange = (id, sub_department, checked, isSearch) => {
    let cloneUserList = reUsersList;
    let cloneSubDepartmentList = reSubDepartmentList;

    if (cloneUserList[sub_department]) {
      let findIndex = cloneUserList[sub_department].findIndex(
        (u) => u.id == id
      );
      if (findIndex !== -1) {
        cloneUserList[sub_department][findIndex].state = checked;

        let falseUsers = [];
        let trueUsers = [];
        let totalUser = cloneUserList[sub_department];
        totalUser.map((u) => {
          if (u.state) {
            trueUsers.push(u);
          } else {
            falseUsers.push(u);
          }
        });

        if (totalUser.length === trueUsers.length) {
          handleSubDepartCheckBox(sub_department, true, isSearch);
        } else if (
          falseUsers.length > 0 &&
          totalUser.length !== falseUsers.length
        ) {
          handleSubDepartCheckBox(sub_department, "minusSign", isSearch);
        } else {
          handleSubDepartCheckBox(sub_department, false, isSearch);
        }

        setReUsersList(cloneUserList);

        setRC(!rc);
      }
    }
  };

  const reSearchByDepartment = (search) => {
    setClickSearchBtnNoti(search);
    api
      .get(SEARCH_NOTI_USERS, {
        platform: "web",
        keyword: search,
        search_by: "department",
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        is_emergency:
          overViewValues.is_emergency_incident === "Yes" ? true : false,
        id: "",
      })
      .then((res) => {
        console.log("departmentdata", res);
        let data = [];
        console.log("[Depart]", res);
        res.data.map((d) => {
          let cloneReAllSubdepartment = reSubDepartmentList;
          let searchIndex = cloneReAllSubdepartment.findIndex(
            (f) => f.title === d.name
          );
          // reUserStateOnChange
          data.push({
            title: d.name,
            state:
              searchIndex != -1
                ? cloneReAllSubdepartment[searchIndex].state
                : d.state == 1
                ? true
                : d.state == -1
                ? "minusSign"
                : false,
            displayUserList: false,
          });
        });
        setReSearchDepartment(data);

        setRC(!rc);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const reSearchByUser = (search) => {
    setClickSearchBtnNoti(search);
    api
      .get(SEARCH_NOTI_USERS, {
        platform: "web",
        keyword: search,
        search_by: "user",
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        id: "",
        is_emergency:
          overViewValues.is_emergency_incident === "Yes" ? true : false,
      })
      .then((res) => {
        console.log("search", res);
        // const { data, others } = res;

        let groupUsers = [...res.data, ...res.others].reduce(
          (groupDepartment, user) => {
            const subDepartment = user.sub_department;
            if (
              groupDepartment[subDepartment] === null ||
              groupDepartment[subDepartment] === undefined
            )
              groupDepartment[subDepartment] = [];
            groupDepartment[subDepartment].push(user);
            return groupDepartment;
          },
          {}
        );
        let datag = [];
        let cloneSubDepartment = reSubDepartmentList;
        let cloneUserList = reUsersList;

        Object.entries(groupUsers).map(([key, value]) => {
          let searchIndex = cloneSubDepartment.findIndex(
            (s) => s.title === key
          );
          console.log("Clone", searchIndex);

          datag.push({
            title: key,
            state:
              searchIndex === -1 ? true : cloneSubDepartment[searchIndex].state,
            displayUserList: true,
          });

          if (!cloneUserList[key]) {
            let userData = [];
            value.map((user) => {
              let findIndex;
              if (cloneUserList[key]) {
                findIndex = cloneUserList[key].findIndex(
                  (f) => f.name === user.name
                );
              } else {
                findIndex = -1;
              }

              userData.push({
                id: user.id,
                state:
                  findIndex !== -1
                    ? cloneUserList[key][findIndex].state
                    : user.is_notified_user,
                name: user.name,
                platform: user.platform,
                key: user.sub_department,
              });
            });
            cloneUserList[key] = userData;
          }

          setRC(!rc);
        });
        // setReSubDepartmentList
        console.log("dataTag", datag);
        setReSearchDepartment(datag);
        setReUsersList(cloneUserList);
        setRC(!rc);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {}, [rc, reUsersList]);
  //remake end

  let cases = [
    {
      title: "Incident Description",
    },

    {
      title: "Person Involved Details",
    },
    {
      title: "Witnesses Details",
    },
    // {
    //   title: "Incident Summary",
    // },
    {
      title: "Details of Property Damage",
    },
    // {
    //   title: "MPD Incident Analysis",
    // },
  ];

  let notis = [
    {
      title: "Notification List",
    },
    {
      title: "Notification Settings",
    },
  ];

  const onChangeNotiSetting = (key, value) => {
    setNotiSettingValues({
      ...notiSettingValues,
      [key]: value,
    });
  };
  useEffect(() => {}, [rc]);
  useEffect(() => {}, [userListForNoti, userListForNotiLoader]);

  const fetchDefaultDepartment = () => {
    api
      .get(GET_SUB_DEPARTMENT, {
        id: "",
        platform: "web",
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        is_emergency:
          overViewValues.is_emergency_incident === "Yes" ? true : false,
      })
      .then((res) => {
        let data = [];
        res.data.map((d, index) => {
          data.push({
            key: d.name,
            title: d.name,
            state: d.status === 1 ? true : false,
          });
        });

        const sortData = data.sort((a, b) =>
          a.title.toLowerCase().localeCompare(b.title.toLowerCase())
        );

        setDepartFoldState(sortData);
        setAllSubDepartmentList(sortData);
        setTotalPageForAllSubDepart(Math.ceil(sortData.length / 10));
        setSubDepartmentForPagination(sortData.slice(0, 10));
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    fetchDefaultDepartment();
    // db.collection("noti_default_departments")
    //   .where(
    //     "classification_title",
    //     "==",
    //     overViewValues.incident_classification
    //   )
    //   .get()
    //   .then((querySnapshot) => {
    //     let data = [];

    //     querySnapshot.forEach(function (doc) {
    //       doc.data().department_arr.map((d, index) => {
    //         data.push({
    //           key: d,
    //           title: d,
    //           state: false,
    //         });
    //       });
    //     });
    //     setDepartFoldState(data);
    //   });
  }, [
    overViewValues.incident_classification,
    overViewValues.is_emergency_incident,
  ]);

  const handlePaginationForAllSubDepart = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage;
    setLastItemForAllSubDepart({
      from: (page - 1) * 10,
      to: page * 10,
    });
    let from = (page - 1) * 10;
    let to = page * 10;
    setSubDepartmentForPagination(allSubDepartmentList.slice(from, to));

    setCurrentAllSubDepartPage(page);
  };

  const refreshAllSubDepart = () => {
    // props.fetchAllSubDepartment();
  };

  const onChangeUsersState = (id, state, key) => {
    setNt(!nt);
    let cloneUsersState = usersState;
    const findIndex = cloneUsersState.findIndex((_, index) => _.id === id);

    cloneUsersState[findIndex].state = state;

    setUsersState(cloneUsersState);

    var allUsersChecked = true;
    for (var index = 0; index < cloneUsersState.length; index++) {
      if (
        cloneUsersState[index].key === key &&
        cloneUsersState[index].state === false
      ) {
        allUsersChecked = false;
      }
    }

    let cloneDepartState = departState;
    const departindex = cloneDepartState.findIndex((_, index) => _.key === key);

    cloneDepartState[departindex].state = allUsersChecked;
  };

  const getUsersState = (id, key, state) => {
    const findIndex = usersState.findIndex((_, index) => _.id === id);
    if (findIndex === -1) {
      setUsersState([
        ...usersState,
        { id: id, state: state ? state : getDepartState(key), key: key },
      ]);
      return getDepartState(key);
    }

    return usersState[findIndex].state;
  };

  const onChangeDepartState = (key, state) => {
    setNt(!nt);
    let cloneDepartState = departState;
    const findIndex = cloneDepartState.findIndex((_, index) => _.key === key);

    cloneDepartState[findIndex].state = state;

    setDepartState(cloneDepartState);

    let cloneUsersState = usersState;

    for (var index = 0; index < cloneUsersState.length; index++) {
      if (cloneUsersState[index].key === key) {
        cloneUsersState[index].state = state;
      }
    }

    setUsersState(cloneUsersState);
  };
  const getDepartState = (key, state) => {
    let cloneListUserListNoti = userListForNoti;
    let getDepartmentUsers = [];
    if (cloneListUserListNoti[key]) {
      getDepartmentUsers = cloneListUserListNoti[key];
    }
    // getDepartmentUsers = cloneListUserListNoti[key];

    let cloneUserState = usersState;
    let filterUserBySubDepart = [];
    let filterUserBySubDepartStateFalse = [];
    if (cloneUserState) {
      cloneUserState.map((u) => {
        if (u.key === key && u.state) {
          filterUserBySubDepart.push(u);
        } else {
          if (!u.state) {
            filterUserBySubDepartStateFalse.push(u);
          }
        }
      });
    }

    // if (searchBy == "user") {
    //   if (
    //     getDepartmentUsers &&
    //     filterUserBySubDepartStateFalse &&
    //     getDepartmentUsers.length != 0 &&
    //     filterUserBySubDepartStateFalse.length > 0
    //   ) {
    //     return "minuSign";
    //   } else if (
    //     filterUserBySubDepartStateFalse.length == getDepartmentUsers.length
    //   ) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // } else if (
    //   getDepartmentUsers.length != 0 &&
    //   filterUserBySubDepart.length != 0 &&
    //   getDepartmentUsers.length == filterUserBySubDepart.length
    // ) {
    //   return true;
    // } else

    if (
      getDepartmentUsers &&
      filterUserBySubDepart &&
      getDepartmentUsers.length !== 0 &&
      filterUserBySubDepart.length !== 0 &&
      getDepartmentUsers.length === filterUserBySubDepart.length
    ) {
      // setDepartState([...departState, { key: key, state: true }]);
      return true;
    } else if (
      getDepartmentUsers &&
      filterUserBySubDepart &&
      getDepartmentUsers.length !== 0 &&
      filterUserBySubDepart.length !== 0 &&
      filterUserBySubDepart.length > 0
    ) {
      if (searchBy === "user") {
        if (
          filterUserBySubDepartStateFalse.length === getDepartmentUsers.length
        ) {
          return false;
        } else if (
          getDepartmentUsers.length > filterUserBySubDepartStateFalse.length
        ) {
          return "minuSign";
        } else {
          return true;
        }
      } else {
        return "minuSign";
      }

      return "minuSign";
    } else {
      if (searchBy === "department") {
        if (
          getDepartmentUsers &&
          getDepartmentUsers &&
          getDepartmentUsers.length > 0 &&
          filterUserBySubDepartStateFalse.length > 0 &&
          getDepartmentUsers.length != filterUserBySubDepartStateFalse.length
        ) {
          return "minuSign";
        }
      }
      const findIndex = departState.findIndex((_, index) => _.key === key);
      if (findIndex === -1) {
        const fIndex = departFoldState.findIndex((_, index) => _.key === key);
        setDepartState([
          ...departState,
          {
            key: key,
            title: key,
            state: fIndex !== -1 ? departFoldState[fIndex].state : false,
          },
        ]);
        return fIndex !== -1 ? departFoldState[fIndex].state : false;
      }
      setWait(true);
      return departState[findIndex].state;
    }
  };

  useEffect(() => {}, [searchUsersDataPagiantion]);

  const onChangeDepartFoldState = (key, state) => {
    setNt(!nt);
    let cloneDepartFoldState = departFoldState;
    const findIndex = cloneDepartFoldState.findIndex(
      (_, index) => _.key === key
    );
    cloneDepartFoldState[findIndex].state = state;
    setDepartFoldState(cloneDepartFoldState);
  };

  const getDepartFoldState = (key) => {
    const findIndex = departFoldState.findIndex((_, index) => _.key === key);

    if (findIndex === -1) {
      setDepartFoldState([...departFoldState, { key: key, state: false }]);

      return false;
    }

    return departFoldState[findIndex].state;
  };

  const onChangeDepartmentForNoti = (title, checked) => {
    const searchCheckedOrNot = checkAllDepartmentNoti.filter(
      (c) => c.title === title
    );

    if (searchCheckedOrNot.length === 0)
      setCheckAllDepartmentNoti([
        ...checkAllDepartmentNoti,
        { title, isChecked: checked },
      ]);
    if (searchCheckedOrNot.length > 0)
      setCheckAllDepartmentNoti(
        checkAllDepartmentNoti.filter((c) => c.title !== title)
      );
  };
  const handleClickDepartment = (sub_department) => {
    let data = [];
    setUserlistForNotiLoader(true);
    let cloneUserListForNoti = userListForNoti;

    api
      .get(GET_ALL_NOTI_USERS, {
        platform: "web",
        sub_department,
        id: "",
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        is_emergency:
          overViewValues.is_emergency_incident === "Yes" ? true : false,
      })
      .then((res) => {
        res.data.map((user) => {
          data.push({
            id: user.id,
            state: user.is_notified_user,
            name: user.name,
            platform: user.platform,
            key: sub_department,
          });
        });
        setSearchUsersPagination({
          ...searchUsersDataPagiantion,
          [sub_department]: data.slice(0, 50),
        });
        setLastUsersItem({
          from: 0,
          to: 50,
        });
        setTotalUserspage(Math.ceil(data.length / 50));

        cloneUserListForNoti[sub_department] = data;
        setUserlistForNotiLoader(false);
        setTimeout(() => {
          getDepartState(sub_department);
        }, 1000);
        setWait(true);
        setRC(!rc);
        setUserListForNoti(cloneUserListForNoti);
      })
      .catch((err) => {
        console.log("err", err);
        setUserlistForNotiLoader(false);
      });
  };
  useEffect(() => {
    setSearchUsersPagination(searchUsersDataPagiantion);
  }, [searchUsersDataPagiantion, rc, userNameForSearch]);

  const fetchUsersBySearch = (
    search,
    setSearchDataList,
    setLastItem,
    setTotalpage,
    setSearchPagination
  ) => {
    setUserNameForSearch(search);
    let data = [];
    setUserlistForNotiLoader(true);
    let cloneUserListForNoti = userListForNoti;
    api
      .get(SEARCH_NOTI_USERS, {
        platform: "web",
        keyword: search,
        search_by: "user",
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        id: "",
        is_emergency:
          overViewValues.is_emergency_incident === "Yes" ? true : false,
      })
      .then((res) => {
        const { data, others } = res;
        let updateData = [];
        let otherData = [];
        let id = 0;
        let usersAndDepart = {};
        data.map((user) => {
          id += 1;

          updateData.push({
            id: user.id,
            state: user.is_notified_user,
            name: user.name,
            platform: user.platform,
            key: user.sub_department,
          });
        });
        others.map((user) => {
          id += 1;

          otherData.push({
            id: user.id,
            state: user.is_notified_user,
            name: user.name,
            platform: user.platform,
            key: user.sub_department,
          });
        });

        let groupUser = updateData.reduce((groupDepartment, user) => {
          const subDepartment = user.key;
          if (groupDepartment[subDepartment] === null)
            groupDepartment[subDepartment] = [];
          groupDepartment[subDepartment].push(user);
          return groupDepartment;
        }, {});
        let groupUsersWithOthers = [...otherData, ...updateData].reduce(
          (groupDepartment, user) => {
            const subDepartment = user.key;
            if (groupDepartment[subDepartment] === null)
              groupDepartment[subDepartment] = [];
            groupDepartment[subDepartment].push(user);
            return groupDepartment;
          },
          {}
        );
        Object.entries(groupUsersWithOthers).map(([key, value]) => {
          cloneUserListForNoti[key] = value;
        });
        let groupUsers;
        let subDeparts = [];

        let ide = 0;
        Object.entries(groupUser).map(([key, value]) => {
          ide += 1;
          subDeparts.push({
            id: ide,
            key: key,
            title: key,
            state: true,
          });
          usersAndDepart = {
            ...usersAndDepart,
            [key]: value.slice(0, 50),
          };
          // usersAndDepart.push({
          //   [key]: value.slice(0, 50),
          // });
          setLastUsersItem({
            from: 0,
            to: 50,
          });
          setTotalUserspage(Math.ceil(value.length / 50));

          // cloneUserListForNoti[key] = value;
          setUserlistForNotiLoader(false);
          getDepartState(key);
        });
        // Object.entries(groupUsersWithOthers).map(([key, value]) => {
        //   cloneUserListForNoti[key] = value;
        // });
        setSearchUsersPagination(usersAndDepart);
        setDepartFoldState(subDeparts);
        let sortData = subDeparts.sort((a, b) => a - b);

        setSearchDataList(subDeparts);
        setLastItem({
          from: 0,
          to: 10,
        });
        setTotalpage(Math.ceil(subDeparts.length / 10));
        setSearchPagination(subDeparts.slice(0, 10));
        // if (!searchUsersDataPagiantion[sub_department]) {
        //   setSearchUsersPagination({
        //     ...searchUsersDataPagiantion,
        //     [sub_department]: data.slice(0, 50),
        //   });
        //   setLastUsersItem({
        //     from: 0,
        //     to: 50,
        //   });
        //   setTotalUserspage(Math.ceil(data.length / 50));
        // }

        // cloneUserListForNoti[sub_department] = data;
        // setUserlistForNotiLoader(false);
        // getDepartState(sub_department);
        setRC(!rc);
        setUserlistForNotiLoader(false);
      })
      .catch((err) => {
        setUserlistForNotiLoader(false);
        console.log("err", err);
      });
  };

  // const changeDropDownId = (key, value) => {
  //   setDropdownId({
  //     ...dropdownId,
  //     [key]: value,
  //   });
  // };

  const changeDropDownId = (key, value) => {
    if (key === "incident_type") {
      setDropdownId({
        ...dropdownId,
        [key]: value,
        incidentPlacementId: "",
        incidentClassificationId: "",
        injuryTypesId: "",
      });
    } else if (key === "incidentPlacementId") {
      setDropdownId({
        ...dropdownId,
        [key]: value,
        incidentClassificationId: "",
        injuryTypesId: "",
      });
    } else {
      setDropdownId({
        ...dropdownId,
        [key]: value,
      });
    }
  };

  const changeShowCasualyFormBool = () => {
    setShowCasualtyForm(true);
  };

  const changeHideCasualyFormBool = () => {
    setShowCasualtyForm(false);
  };

  const changeShowWitnessFormBool = () => {
    setShowWitnessForm(true);
  };

  const changeHideWitnessFormBool = () => {
    setShowWitnessForm(false);
  };

  function customModal(onClose) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Confirm to save
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            Do you want to save and exit this form?
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button className="witness_cancel_button" onClick={onClose}>
                No
              </Button>
            </div>
            <div onClick={() => history.push("/incidents_list")}>
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={onClose}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const sidebarcssColors = (title) => {
    if (title === "Incident Description" && overViewError) {
      return "error";
    } else if (title === "Incident Description" && locationError) {
      return "error";
    }
    //  else if (title == "Person Involved Details" && casualtyError) {
    //   return "error";
    // } else if (title == "Witnesses Details" && witnessError) {
    //   return "error";
    // }
    else if (title === "Incident Descriptio" && summaryError) {
      return "error";
    } else if (title === "Details of Property Damage" && propertyError) {
      return "error";
    } else if (title === "Incident Description" && photoError) {
      return "error";
    } else if (title === "MPD Incident Analysis" && analysisError) {
      return "error";
    } else {
      return "nth";
    }
  };

  const scrollToTopFunction = () => {
    if (window.screen.width > 800) {
      scrollRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    props.hideShowZoneList();
  }, []);

  const Sidebar = () => {
    return (
      <div className="sidebar" onClick={props.hideShowZoneList}>
        {/* Cases */}
        <div className="title">Case details</div>
        {cases.map((c, index) => {
          return (
            <div
              className={`option_container ${
                c.title === detailSelected && "active"
              } `}
              onClick={() => {
                scrollToTopFunction();

                setDetailSelected(c.title);
                changeHideCasualyFormBool();
                changeHideWitnessFormBool();
              }}
            >
              <div style={{ width: "95%" }}>{c.title}</div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    background: "#E72D35",
                    color: "#fff",
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    display: "flex",
                    justifyContent: "center",
                    marginRight: 10,
                    opacity: sidebarcssColors(c.title) === "error" ? 1 : 0,
                  }}
                >
                  !
                </div>
                <img
                  src={
                    c.title === detailSelected ? RightArrowIcon : DownArrowIcon
                  }
                />
              </div>
            </div>
          );
        })}
        {overViewValues.is_emergency_incident === "Yes" || showNotiPage ? (
          <>
            <div className="title" style={{ marginTop: 15 }}>
              Notification
            </div>
            {notis.map((c, index) => {
              return (
                <div
                  className={`option_container ${
                    c.title === detailSelected && "active"
                  }`}
                  onClick={() => {
                    scrollToTopFunction();
                    setDetailSelected(c.title);
                    changeHideCasualyFormBool();
                    changeHideWitnessFormBool();
                  }}
                >
                  <div style={{ width: "90%" }}>{c.title}</div>
                  <div>
                    <img
                      src={
                        c.title === detailSelected
                          ? RightArrowIcon
                          : DownArrowIcon
                      }
                    />
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    );
  };

  const MobileSideBar = () => {
    return (
      <div
        className="__incident_mobile_form_tabs"
        style={{ display: "flex", margin: "15px" }}
      >
        {cases.map((c, index) => {
          return (
            <div
              className={`mobile_filter_tabs __incident_tabs ${
                c.title === detailSelected && "active"
              }`}
              style={{ display: "flex", position: "relative" }}
            >
              <Button
                onClick={() => {
                  scrollToTopFunction();

                  setDetailSelected(c.title);
                  changeHideCasualyFormBool();
                  changeHideWitnessFormBool();
                }}
              >
                {c.title}
              </Button>
              <div
                style={{
                  background: "#E72D35",
                  color: "#fff",
                  width: 20,
                  height: 20,
                  borderRadius: 50,
                  display: "flex",
                  justifyContent: "center",
                  marginRight: 10,
                  opacity: sidebarcssColors(c.title) === "error" ? 1 : 0,
                  position: "absolute",
                  top: 6,
                  right: 0,
                }}
              >
                !
              </div>
            </div>
          );
        })}
        {overViewValues.is_emergency_incident === "Yes" || showNotiPage ? (
          <>
            {notis.map((c, index) => {
              return (
                <div
                  className={`mobile_filter_tabs __incident_tabs ${
                    c.title === detailSelected && "active"
                  }`}
                  style={{ display: "flex", position: "relative" }}
                >
                  <Button
                    onClick={() => {
                      scrollToTopFunction();

                      setDetailSelected(c.title);
                      changeHideCasualyFormBool();
                      changeHideWitnessFormBool();
                    }}
                  >
                    {c.title}
                  </Button>
                  <div
                    style={{
                      background: "#E72D35",
                      color: "#fff",
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      display: "flex",
                      justifyContent: "center",
                      marginRight: 10,
                      opacity: sidebarcssColors(c.title) === "error" ? 1 : 0,
                      position: "absolute",
                      top: 6,
                      right: 0,
                    }}
                  >
                    !
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    );
  };
  // check new one or edit the pending data
  useEffect(() => {
    if (paramId && paramPendingBool) {
      const items = JSON.parse(localStorage.getItem("pending"));
      const filteredData = items.filter((i) => i.id == paramId);
      if (filteredData.length > 0) {
        const {
          incident_type,
          incident_placement,

          is_followup_required,
          category,
          incident_classification,
          injury_type,
          park,
          weather,
          area_condition,
          date,
          time,
          main_zone, // -
          sub_zones, // -
          zone_type, // -
          casualty_particulars,
          witness_particulars,
          property_type,
          damaged_things,
          party_type,
          party_info,
          priority_of_fix, // total 20
          // r - 24
          incident_status,
          photoValues,
          dateTime,
          action,
          stoppage_text,
          condition,
          personnel,
          job,
          stoppage,
          incident_remarks,
          input,
          is_emergency_incident,
          emergency_services_activated,
          recall_people,
          dropdownId,
          incident_summary,
          incident_title,
          noti_frequency,
        } = filteredData[0];
        setOverViewValues({
          incident_type,
          incident_placement,
          is_emergency_incident,
          is_followup_required,
          category,
          incident_classification,
          injury_type,
          emergency_services_activated,
          recall_people,
        });
        setSummaryValues({
          incident_remarks:
            incident_summary && incident_summary.incident_remarks
              ? incident_summary.incident_remarks
              : incident_remarks
              ? incident_remarks
              : "",
          incident_title:
            incident_summary && incident_summary.incident_title
              ? incident_summary.incident_title
              : incident_title
              ? incident_title
              : "",
        });
        setDropdownId(dropdownId);
        if (noti_frequency) {
          setNotiSettingValues({
            frequency_type: noti_frequency,
          });
        }
        setLocationValues({
          park,
          weather,
          area_condition,
          date,
          time,
        });

        if (typeof main_zone === "object" && main_zone != null) {
          setMainZone({
            lat: main_zone.lat,
            lng: main_zone.lng,
          });
          Geocode.fromLatLng(main_zone.lat, main_zone.lng).then(
            (response) => {
              const address = response.results[0].formatted_address;
              onChangeMainZone({
                lat: main_zone.lat,
                lng: main_zone.lng,
              });
              onChangeSelectedAddress(address);
              onChangeZoneType("Other: Pin current location");
            },
            (error) => {
              console.error(error);
            }
          );
        } else {
          setMainZone(main_zone);
        }

        setSubZone(sub_zones);
        setZoneType(zone_type);
        setCasualtyValues(casualty_particulars);
        setWitnessValues(witness_particulars);

        setPropertyValues({
          property_type,
          damaged_things,
          party_type,
          party_info,
          priority_of_fix,
        });
        setAnalysis({
          action,
          condition,
          personnel,
          job,
          stoppage: stoppage ? stoppage : "Yes",
          stoppage_text,
        });
        setPhotoValues(photoValues);

        if (dateTime.selectedDate !== null && dateTime.selectedTime !== null) {
          setLocationDate({
            selectedDate: new Date(dateTime.selectedDate),
            selectedTime: new Date(dateTime.selectedTime),
          });
        } else if (dateTime.selectedDate !== null) {
          setLocationDate({
            ...locationDate,
            selectedDate: new Date(dateTime.selectedDate),
          });
        } else if (dateTime.selectedTime !== null) {
          setLocationDate({
            ...locationDate,
            selectedTime: new Date(dateTime.selectedTime),
          });
        }
      }
      CheckfilledMatodaryField();
    }
    CheckfilledMatodaryField();
  }, [paramId, paramPendingBool]);

  function customDeleteModal(onClose) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Confirm to delete
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            Do you want to delete this item?
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button className="witness_cancel_button" onClick={onClose}>
                No
              </Button>
            </div>
            <div>
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={() => deletePendingCustomModal(onClose)}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const deletePendingCustomModal = (onClose) => {
    const items = JSON.parse(localStorage.getItem("pending"));
    const DeleteData = items.filter((i) => i.id != paramId);
    const filteredData = items.filter((i) => i.id == paramId);
    if (filteredData.length === 1) {
      let { photoValues, casualty_particulars, witness_particulars } =
        filteredData[0];
      if (photoValues.length > 0) {
        photoValues.map((p) => {
          if (p) {
            storage
              .refFromURL(p)
              .delete()
              .then((res) => console.log("delete photovalues"))
              .catch((err) => console.log("err", err));
          }
        });
      }
      if (witness_particulars.length > 0) {
        witness_particulars.map((w) => {
          if (w.signature) {
            storage
              .refFromURL(w.signature)
              .delete()
              .then((res) => console.log("delete witness_particulars"))
              .catch((err) => console.log("err", err));
          }
        });
      }
      if (casualty_particulars.length > 0) {
        casualty_particulars.map((c) => {
          if (c.signature) {
            storage
              .refFromURL(c.signature)
              .delete()
              .then((res) => console.log("delete casualty_particulars"))
              .catch((err) => console.log("err", err));
          }
        });
      }
    }
    localStorage.setItem("pending", JSON.stringify(DeleteData));

    onClose();
    return history.push("/incidents_list");
  };

  const deletePendingItem = () => {
    if (paramId && paramPendingBool) {
      return confirmAlert({
        customUI: ({ onClose }) => customDeleteModal(onClose),
      });
    }
  };

  // check saving local or not

  const saveLocalOrApi = () => {
    if (
      !overViewError &&
      !locationError &&
      // !casualtyError &&
      // !witnessError &&
      !summaryError &&
      // !propertyError &&
      // !photoError &&
      !typeError &&
      !placementError &&
      !categoryError &&
      !parkError &&
      !zoneError &&
      !weatherError &&
      !areaError &&
      !titleError &&
      // !propertyInputError &&
      // !damagesError &&
      // !partyError &&
      // !proxityError &&
      // !analysisError &&
      !canCreate
    ) {
      return console.log("do nth"); // do nth = filled all call api
    } else {
      try {
        if (paramId && paramPendingBool) {
          let parseId = Number(paramId);
          const data = {
            id: parseId,
            incident_status: "draft",
            platform: "web",
            ...overViewValues,
            ...locationValues,
            main_zone: mainZone,
            sub_zones: subZone,
            zone_type: zoneType,
            casualty_particulars: casualtyValues,
            witness_particulars: witnessValues,
            incident_summary: {
              ...summaryValues,
            },
            ...propertyValues,
            photoValues: photoValues,
            dateTime: locationDate,
            ...analysis,
            dropdownId: dropdownId,
            noti_frequency: notiSettingValues.frequency_type,
            pending: true,
            latest_updated_at: new Date(),
            department: props.AuthDetail.department,
            creator_name: props.AuthDetail.creator_name,
          };
          if (localStorage.getItem("pending")) {
            const previousData = JSON.parse(localStorage.getItem("pending"));
            const searchIndex = previousData.findIndex((p) => {
              return p.id === parseId;
            });

            if (searchIndex === -1) {
              previousData.push(data);
            } else {
              previousData[searchIndex] = data;
            }

            localStorage.setItem("pending", JSON.stringify(previousData));

            return confirmAlert({
              customUI: ({ onClose }) => customModal(onClose),
            });
          } else {
            localStorage.setItem("pending", JSON.stringify([data]));
            return confirmAlert({
              customUI: ({ onClose }) => customModal(onClose),
            });
            // return confirmAlert({
            //   title: "Confirm to submit",
            //   message: "Do you want to save and exit this form?",
            //   buttons: [
            //     {
            //       label: "Yes",
            //       onClick: () => history.push("/incidents_list"),
            //     },
            //     {
            //       label: "No",
            //       onClick: () => null,
            //     },
            //   ],
            // });
          }
        } else {
          const data = {
            id: fakeId,
            incident_status: "draft",
            platform: "web",
            ...overViewValues,
            ...locationValues,
            main_zone: mainZone,
            sub_zones: subZone,
            zone_type: zoneType,
            casualty_particulars: casualtyValues,
            witness_particulars: witnessValues,
            ...summaryValues,
            ...propertyValues,
            photoValues: photoValues,
            dateTime: locationDate,
            ...analysis,
            dropdownId: dropdownId,
            noti_frequency: notiSettingValues.frequency_type,
            pending: true,
            latest_updated_at: new Date(),
            department: props.AuthDetail.department,
            creator_name: props.AuthDetail.name,
          };
          window.history.pushState(
            "",
            "",
            `new_incident/?pending=true&id=${fakeId}`
          );
          setHaveFakeId(fakeId);
          // not found param Id New One
          if (localStorage.getItem("pending")) {
            const previousData = JSON.parse(localStorage.getItem("pending"));

            const searchIndex = previousData.findIndex((p) => {
              return p.id === fakeId;
            });
            if (searchIndex === -1) {
              previousData.push(data);
            } else {
              previousData[searchIndex] = data;
            }

            localStorage.setItem("pending", JSON.stringify(previousData));

            return confirmAlert({
              customUI: ({ onClose }) => customModal(onClose),
            });
          } else {
            localStorage.setItem("pending", JSON.stringify([data]));
            return confirmAlert({
              customUI: ({ onClose }) => customModal(onClose),
            });
          }
        }
      } catch (e) {
        console.log("err", e);
        alert("Local storage is full");
      }
    }
  };

  useEffect(() => {
    if (
      overViewError ||
      locationError ||
      // casualtyError ||
      // witnessError ||
      summaryError ||
      // propertyError ||
      // photoError ||
      typeError ||
      placementError ||
      categoryError ||
      parkError ||
      zoneError ||
      weatherError ||
      areaError ||
      titleError ||
      propertyInputError ||
      damagesError ||
      partyError
      // ||
      // proxityError
      // ||
      // analysisError
    ) {
      if (overViewValues.is_emergency_incident === "Yes") {
      } else {
        saveLocalOrApi();
      }
    } else {
      if (firstTimeClick !== 1) {
        saveLocalOrApi();
      }
    }
  }, [
    // overViewError,
    // locationError,
    // casualtyError,
    // witnessError,
    // propertyError,
    // photoError,
    // canCreate,
    // analysisError,
    submitToggle,
  ]);

  useEffect(() => {
    setCanCreate(false);
  }, [
    overViewError,
    locationError,
    // casualtyError,
    // witnessError,
    propertyError,
    photoError,
    // analysisError,
  ]);

  useEffect(() => {
    const {
      incident_type,
      incident_placement,
      involved,
      category,
      incident_classification,
      injury_type,
    } = overViewValues;
    if (incident_type === "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    // if (photoValues.length == 0) {
    //   return setShowNotiPage(false);
    // } else {
    //   setShowNotiPage(true);
    // }
    if (incident_placement === "" || !incident_placement) {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    if (!category) {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    // if (involved == "") {
    //   setInvolvedError(true);
    // } else {
    //   setInvolvedError(false);
    // }
    if (incident_classification === "" || !incident_classification) {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }
    // validateLocation();
    const data = locationValues;
    if (data.park === "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    if (mainZone === null || mainZone === "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    if (data.date === "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }
    if (data.time === "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }
    // validateSummary();
    const dataSummary = summaryValues;
    if (
      dataSummary.incident_title === "" ||
      !dataSummary.incident_title ||
      dataSummary.incident_title.length > 100
    ) {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    if (dataSummary.incident_remarks === "" || !dataSummary.incident_remarks) {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }
    // validateProperty();
    const dataProperty = propertyValues;

    // if (casualtyValues.length === 0) {
    //   return setShowNotiPage(false);
    // } else {
    //   setShowNotiPage(true);
    // }

    // if (dataProperty.property_type == "") {
    //   return setShowNotiPage(false);
    // } else {
    //   setShowNotiPage(true);
    // }

    // if (dataProperty.damaged_things == "") {
    //   return setShowNotiPage(false);
    // } else {
    //   if (
    //     /[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(dataProperty.damaged_things)
    //   ) {
    //     return setShowNotiPage(false);
    //   } else {
    //     setShowNotiPage(true);
    //   }
    // }
    // if (dataProperty.party_type == "") {
    //   return setShowNotiPage(false);
    // } else {
    //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(dataProperty.party_type)) {
    //     return setShowNotiPage(false);
    //   } else {
    //     setShowNotiPage(true);
    //   }
    // }

    // if (dataProperty.party_info == "") {
    //   return setShowNotiPage(false);
    // } else {
    //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(dataProperty.party_info)) {
    //     return setShowNotiPage(false);
    //   } else {
    //     setShowNotiPage(true);
    //   }
    // }

    // if (dataProperty.priority_of_fix == "") {
    //   return setShowNotiPage(false);
    // } else {
    //   setShowNotiPage(true);
    // }
  }, [
    overViewValues,
    summaryValues,
    propertyValues,
    photoValues,
    // casualtyValues,
    // witnessValues,
    locationValues,
    mainZone,
  ]);

  const ValidationAllForms = () => {
    validateIncidentOverView();
    validateLocation();
    validateSummary();
    validateProperty();
    validationAnalysisForm();

    if (locationValues.park === "") {
      setOverViewError(true);
      setCanCreate(true);
    } else {
      setOverViewError(false);
      setCanCreate(false);
    }
    if (mainZone == null || !mainZone) {
      setOverViewError(true);
      setCanCreate(true);
    } else {
      setOverViewError(false);
      setCanCreate(false);
    }
    // mainZone

    if (casualtyValues.length === 0) {
      // setCasualtyError(true);
      setCanCreate(true);
    } else {
      // setCasualtyError(false);
      setCanCreate(false);
    }

    if (overViewValues) {
      for (var key in overViewValues) {
        if (key === "injury_type") {
          setOverViewError(false);
          setCanCreate(false);
        }
        if (key === "emergency_services_activated") {
          setOverViewError(false);
          setCanCreate(false);
        }
        if (locationValues.park === "") {
          setOverViewError(true);
          break;
        } else {
          setOverViewError(false);
        }
        if (mainZone === null || !mainZone) {
          setOverViewError(true);
          break;
        } else if (overViewValues[key] !== null && overViewValues[key] !== "") {
          setOverViewError(false);
          setCanCreate(false);
        } else {
          if (key === "injury_type" || key === "emergency_services_activated") {
            setOverViewError(false);
            setCanCreate(false);
          } else {
            setOverViewError(true);
            setCanCreate(true);
            break;
          }
        }
      }
    } else {
      setOverViewError(true);
      setCanCreate(true);
    }

    // if (locationValues) {
    //   for (var key in locationValues) {
    //     if (locationValues[key] !== null && locationValues[key] != "") {
    //       setLocationError(false);
    //       setCanCreate(false);
    //     } else {
    //       setLocationError(true);
    //       setCanCreate(true);
    //     }
    //   }
    // } else {
    //   setLocationError(true);
    //   setCanCreate(true);
    // }

    // if (witnessValues.length == 0) {
    //   setWitnessError(true);
    //   setCanCreate(true);
    // } else {
    //   setWitnessError(false);
    //   setCanCreate(false);
    // }
    if (summaryValues) {
      for (var key in summaryValues) {
        if (summaryValues[key] !== null && summaryValues[key] !== "") {
          setSummaryError(false);
          setCanCreate(false);
        } else {
          setSummaryError(true);
          setCanCreate(true);
          break;
        }
      }
    } else {
      setSummaryError(true);
      setCanCreate(true);
    }

    // if (propertyValues) {
    //   for (var key in propertyValues) {
    //     if (propertyValues[key] !== null && propertyValues[key] != "") {
    //       setPropertyError(false);
    //       setCanCreate(false);
    //     } else {
    //       setPropertyError(true);
    //       setCanCreate(true);
    //       break;
    //     }
    //   }
    // } else {
    //   setPropertyError(true);
    //   setCanCreate(true);
    // }

    // if (propertyValues.priority_of_fix == "") {
    //   setPropertyError(true);
    // } else {
    //   setPropertyError(false);
    // }

    // if (photoValues.length == 0) {
    //   setPhotoError(true);
    //   setCanCreate(true);
    // } else {
    //   setPhotoError(false);
    //   setCanCreate(false);
    // }

    // if (analysis) {
    //   for (var key in analysis) {
    //     if (analysis[key] != "Yes") {
    //       if (analysis[key] !== null && analysis[key] != "") {
    //         setAnalysisError(false);
    //         setCanCreate(false);
    //       } else {
    //         setPropertyError(true);
    //         setCanCreate(true);
    //       }
    //     }
    //   }
    // } else {
    //   setAnalysisError(true);
    //   setCanCreate(true);
    // }
  };
  const CheckfilledMatodaryField = () => {
    // validateIncidentOverView();
  };

  useEffect(() => {
    if (props.subDeparts.length === 0) {
      props.fetchAllSubDepartment();
    }
  }, []);
  useEffect(() => {
    // if (actionError || conditionError || personnelError || jobError) {
    //   setAnalysisError(true);
    //   setCanCreate(true);
    // } else {
    //   setAnalysisError(false);
    //   setCanCreate(false);
    // }

    if (
      propertyInputError ||
      damagesError ||
      partyError
      // || proxityError
    ) {
      setPropertyError(true);
      setCanCreate(true);
    } else {
      setPropertyError(false);
      setCanCreate(false);
    }
  }, [
    actionError,
    conditionError,
    personnelError,
    jobError,
    propertyInputError,
    damagesError,
    partyError,
    proxityError,
  ]);

  const callAPI = () => {
    console.log("call 1");
    console.log(props.AuthDetail);
    const DataResult = api.get(GET_USER_DETAILS, {
      id: props.AuthDetail.id,
      platform: "web",
    });
    return DataResult;
  };

  const createIncident = async () => {
    const userData = await callAPI();
    console.log("call2");
    console.log("data", userData.data);
    setFirstTimeClick(1 + firstTimeClick);
    let selected_noti_departs = [];
    let unchecked_users = [];
    setCreateLoader(true);
    if (reUsersList) {
      Object.entries(reUsersList).map(([key, value]) => {
        if (value && value.length > 0) {
          value.map((u) => {
            if (!u.state) {
              unchecked_users.push(u.id);
            }
          });
        }
      });
    }
    // if (removedPhotoValues) {
    //   removedPhotoValues.map((img) => {
    //     storage
    //       .refFromURL(img)
    //       .delete()
    //       .catch((err) => console.log("err", err));
    //   });
    // }
    if (reSubDepartmentList && reSubDepartmentList.length > 0) {
      reSubDepartmentList.map((sub) => {
        selected_noti_departs.push({
          name: sub.title,
          status: sub.state === "minusSign" ? -1 : sub.state ? 1 : 0,
        });
      });
    }

    // let unchecked_users = [];
    usersState.length > 0 &&
      usersState.map((user) => {
        if (!user.state) {
          unchecked_users.push(user.id);
        }
      });

    let statusConditionValue;
    if (checkStatus === "Yes") {
      if (overViewValues.category === "c1") {
        if (overViewValues.is_followup_required === "Yes") {
          statusConditionValue = "Open";
        } else {
          statusConditionValue = "Closed";
        }
      } else {
        statusConditionValue = "Pending Approval";
      }
    } else {
      statusConditionValue = "Draft";
    }

    // setCreateLoader(true);
    let modiftedEmergencyServices = [];
    overViewValues.emergency_services_activated.map((e) => {
      if (e && e.name && e.checked) {
        modiftedEmergencyServices.push(e.name);
      }
    });
    // return console.log("witness_particulars", witnessValues);
    let newData = {
      incident_status: statusConditionValue,
      platform: "web",
      ...overViewValues,
      emergency_services_activated: modiftedEmergencyServices,
      park: locationValues.park,
      weather: locationValues.weather,
      area_condition: locationValues.area_condition,
      date: locationValues.date
        ? locationValues.date
        : moment(new Date()).format("D MMM yyyy"),
      time: locationValues.time
        ? locationValues.time
        : moment(new Date()).format("HH:mm:ss"),
      // ...locationValues,
      zone: mainZone
        ? typeof mainZone === "object"
          ? mainZone.lat + "," + mainZone.lng
          : mainZone
        : "",
      sub_zones: subZone,
      zone_type: zoneType,
      person_involved_details: casualtyValues,
      witness_particulars: witnessValues,
      ...summaryValues,
      ...propertyValues,
      photo_video: photoValues,
      substandard_action: analysis.action,
      substandard_condition: analysis.condition,
      personnel_factors: analysis.personnel,
      stoppage_text: analysis.stoppage_text,
      job_factors: analysis.job,
      stoppage_text: analysis.stoppage_text,
      work_stoppage: analysis.stoppage,
      selected_incident_ids: {
        incident_placement_id: dropdownId.incidentPlacementId,
        incident_classification_id: dropdownId.incidentClassificationId,
        injury_type_id: dropdownId.injuryTypesId,
      },
      department: userData.data.department,
      sub_department: userData.data.sub_department,
      unchecked_users,
      noti_frequency: notiSettingValues.frequency_type,
    };
    if (overViewValues.is_emergency_incident === "Yes") {
      newData.selected_noti_departs = selected_noti_departs;
    }
    console.log("new", newData);
    auth.onAuthStateChanged((user) => {
      api
        .post(
          CREATE_INCIDENTS,
          JSON.stringify({
            ...newData,
            created_by: userData.data.id,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          api
            .post(
              CREATE_AUDIT_INCIDENTS,
              JSON.stringify({
                incident_id: res.data.id,
                changed_by: userData.data.id,
                description: ["Create incident"],
                platform: "web",
              }),
              {
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((res) => {
              setCreateLoader(false);
              setToApi(false);
              const items = JSON.parse(localStorage.getItem("pending"));

              if (paramId) {
                const filteredData = items.filter((i) => i.id != paramId);
                if (filteredData) {
                  localStorage.setItem("pending", JSON.stringify(filteredData));
                }
              }
              if (haveFakeId) {
                const filteredData = items.filter((i) => i.id != haveFakeId);
                if (filteredData) {
                  localStorage.setItem("pending", JSON.stringify(filteredData));
                }
              }

              history.push("/incidents_list");
            })
            .catch((err) => {
              setCreateLoader(false);
              console.log("Error...", err);
            });

          // setCreateLoader(false);
          // setToApi(false);
          // const items = JSON.parse(localStorage.getItem("pending"));

          // if (paramId) {
          //   const filteredData = items.filter((i) => i.id != paramId);
          //   if (filteredData) {
          //     localStorage.setItem("pending", JSON.stringify(filteredData));
          //   }
          // }
          // if (haveFakeId) {
          //   const filteredData = items.filter((i) => i.id != haveFakeId);
          //   if (filteredData) {
          //     localStorage.setItem("pending", JSON.stringify(filteredData));
          //   }
          // }

          // history.push("/incidents_list");
        })
        .catch((err) => {
          console.log("err", err);
          setCreateLoader(false);
        });
      console.log("create Post Api");
    });
  };

  // creating Incident
  useEffect(() => {
    if (overViewValues.is_emergency_incident === "Yes") {
      if (firstTimeClick === 1) {
        return createIncident();
      }
    } else {
      if (!canCreate && toApi) {
        if (
          !overViewError &&
          !locationError &&
          // !casualtyError &&
          // !witnessError &&
          !summaryError &&
          // !propertyError &&
          // !photoError &&
          !typeError &&
          !placementError &&
          !categoryError &&
          !parkError &&
          !zoneError &&
          !weatherError &&
          !areaError &&
          !titleError &&
          // !propertyInputError &&
          // !damagesError &&
          // !partyTypeError &&
          // partyTypeErrorTxt == "" &&
          // !partyError &&
          // !proxityError &&
          // !analysisError &&
          !canCreate
        ) {
          if (firstTimeClick === 1) {
            return createIncident();
          }
        } else {
          return saveLocalOrApi();
        }
      }
    }
  }, [canCreate, toApi, submitToggle]);

  const onChangeLocationDateTime = (key, value) => {
    setLocationDate({
      ...locationDate,
      [key]: value,
    });
  };

  useEffect(() => {}, [photoValues]);

  const onChangePhoto = (images) => {
    setChangedValue(true);
    setPhotoValues([...photoValues, images]);
  };
  const filterPhotoFun = async (img) => {
    const searchPhoto = photoValues.filter((p) => p === img);
    const cloneRemovedPhotoValues = removedPhotoValues;
    // if (searchPhoto) {
    //   cloneRemovedPhotoValues.push(searchPhoto);
    //   setRemovedPhotoValues(cloneRemovedPhotoValues);
    // }
    // if (searchPhoto) {
    //   storage
    //     .refFromURL(searchPhoto[0])
    //     .delete()
    //     .catch((err) => console.log("err", err));
    // }

    const filteredPhoto = photoValues.filter((f) => f !== img);
    setPhotoValues(filteredPhoto);
    if (filteredPhoto.length > 0) {
      setChangedValue(true);
    } else {
      setChangedValue(false);
    }
  };
  const mainSaveButton = async () => {
    console.log("Click");
    setToApi(true);
    setSubmitToggle(!submitToggle);

    ValidationAllForms();
    // console.log("toggle button.....", submitToggle);

    // if(!typeError && !placementError &&  !categoryError &&  !involvedError && !parkError && !zoneError && !weatherError && !areaError&& !titleError&& !propertyInputError && !damagesError && !partyError&& !proxityError ){
    //   console.log('createing api')
    // } {

    // }
  };

  console.log("auth details........", props.AuthDetail);

  const validateIncidentOverView = () => {
    const {
      incident_type,
      incident_placement,
      involved,
      category,
      incident_classification,
      injury_type,
    } = overViewValues;
    // if (incident_type == "") {
    //   setTypeError(true);
    // } else {
    //   setTypeError(false);
    // }

    if (incident_placement === "" || !incident_placement) {
      setPlacementError(true);
    } else {
      setPlacementError(false);
    }

    if (!category) {
      setCategoryError(true);
    } else {
      setCategoryError(false);
    }
    if (incident_type === "" || !incident_type) {
      setTypeError(true);
    } else {
      setTypeError(false);
    }
    // if (involved == "") {
    //   setInvolvedError(true);
    // } else {
    //   setInvolvedError(false);
    // }
    if (incident_classification === "" || !incident_classification) {
      setClassificationError(true);
    } else {
      setClassificationError(false);
    }

    // if (injury_type == "") {
    //   setSetInjuryTypeError(true);
    // } else {
    //   setSetInjuryTypeError(false);
    // }
    if (!typeError && !placementError && !categoryError) {
    }
  };

  const validateLocation = () => {
    const data = locationValues;
    if (data.park === "") {
      setParkError(true);
      setLocationError(true);
      setCanCreate(true);
    } else {
      setParkError(false);
      setLocationError(false);
      setCanCreate(false);
    }

    if (mainZone === null || mainZone === "") {
      setZoneError(true);
      setLocationError(true);
      setCanCreate(true);
    } else {
      setZoneError(false);
      setLocationError(false);
      setCanCreate(false);
    }

    // if (data.weather == "") {
    //   setWeatherError(true);
    //   setLocationError(true);
    //   setCanCreate(true);
    // } else {
    //   setWeatherError(false);
    //   setLocationError(false);
    //   setCanCreate(false);
    // }

    // if (data.area_condition == "") {
    //   setAreaError(true);
    //   setLocationError(true);
    //   setCanCreate(true);
    // } else {
    //   setAreaError(false);
    //   setLocationError(false);
    //   setCanCreate(false);
    // }

    if (data.date === "") {
      setDateError(true);
      setLocationError(true);
      setCanCreate(true);
    } else {
      setDateError(false);
      setLocationError(false);
      setCanCreate(false);
    }
    if (data.time === "") {
      setTimeError(true);
      setLocationError(true);
      setCanCreate(true);
    } else {
      setTimeError(false);
      setLocationError(false);
      setCanCreate(false);
    }
  };

  const validateSummary = () => {
    const data = summaryValues;
    if (
      data.incident_title === "" ||
      !data.incident_title ||
      data.incident_title.length > 100
    ) {
      setTitleError(true);
    } else {
      setTitleError(false);
    }

    if (data.incident_remarks === "" || !data.incident_remarks) {
      setRemarkError(true);
    } else {
      setRemarkError(false);
    }
  };

  const validateProperty = () => {
    const data = propertyValues;
    // if (data.property_type == "") {
    //   setPropertyInputError(true);
    // } else {
    //   setPropertyInputError(false);
    // }

    // if (data.damaged_things == "") {
    //   setDamagesError(true);
    //   setDamagesErrorTxt("Required Field");
    // } else {
    //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(data.damaged_things)) {
    //     setDamagesError(true);
    //     setDamagesErrorTxt("Must be valid");
    //   } else {
    //     setDamagesError(false);
    //     setDamagesErrorTxt("");
    //   }
    // }
    // if (data.party_type == "") {
    //   setPartyTypeError(true);
    //   setPartyTypeErrorTxt("Required Field");
    // } else {
    //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(data.party_type)) {
    //     setPartyTypeError(true);
    //     setPartyTypeErrorTxt("Must be valid");
    //   } else {
    //     setPartyTypeError(false);
    //     setPartyTypeErrorTxt("");
    //   }
    // }

    // if (data.party_info == "") {
    //   setPartyError(true);
    //   setPartyErrorTxt("Required Field");
    // } else {
    //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(data.party_info)) {
    //     setPartyError(true);
    //     setPartyErrorTxt("Must be valid");
    //   } else {
    //     setPartyError(false);
    //     setPartyErrorTxt("");
    //   }
    // }

    // if (data.priority_of_fix == "") {
    //   setProxityError(true);
    // } else {
    //   setProxityError(false);
    // }
  };
  const validatePhoto = () => {};
  const saveMainDraftButton = () => {};

  const validationAnalysisForm = () => {
    const data = analysis;
    if (data.action === "") {
      setActionError(true);
    } else {
      setActionError(false);
    }

    if (data.condition === "") {
      setConditionError(true);
    } else {
      setConditionError(false);
    }

    if (data.personnel === "") {
      setPersonnelError(true);
    } else {
      setPersonnelError(false);
    }

    if (data.job === "") {
      setJobError(true);
    } else {
      setJobError(false);
    }

    if (data.input === "") {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  const onChangeIncidentOverView = (key, value, e) => {
    if (key === "emergency_services_activated") {
      let cloneServiceAcivate = overViewValues.emergency_services_activated;
      let searchIndex = cloneServiceAcivate.findIndex((e) => e.name === value);
      if (searchIndex === -1) {
        cloneServiceAcivate.push({
          name: value,
          checked: true,
        });
      } else {
        cloneServiceAcivate[searchIndex].checked =
          !cloneServiceAcivate[searchIndex].checked;
      }
      setOverViewValues({
        ...overViewValues,
        emergency_services_activated: cloneServiceAcivate,
      });
    } else {
      if (key === "incident_type") {
        setOverViewValues({
          ...overViewValues,
          [key]: value,
          incident_placement: null,
          incident_classification: null,
        });
      } else if (key === "incident_placement") {
        setOverViewValues({
          ...overViewValues,
          [key]: value,
          incident_classification: null,
        });
      } else {
        setOverViewValues({
          ...overViewValues,
          [key]: value,
        });
      }
    }

    setChangedValue(true);
    if (key === "is_emergency_incident" && value === "Yes") {
      setSummaryValues({
        ...summaryValues,
        incident_title: "Emergency broadcast",
      });
      setOverViewValues({
        ...overViewValues,
        category: "c1",
        [key]: value,
      });
    } else if (key === "is_emergency_incident" && value === "No") {
      let srchString = "Emergency broadcast";
      setSummaryValues({
        ...summaryValues,
        incident_title: summaryValues.incident_title.toLowerCase(
          srchString.toLowerCase()
        )
          ? ""
          : summaryValues.title,
      });
    }
  };

  // for selecting Incident Location only Zone Dropdown
  const onChangeIncidentLocationZone = (value) => {
    setMainZone(value);
    setZoneType(value);
    setChangedValue(true);
  };

  const onChangeIncidentInjuryType = (data) => {
    setOverViewValues({
      ...overViewValues,
      injury_type: data,
    });
    setChangedValue(true);
  };

  const onChangeIncidentLocationCondtionsArea = (data) => {
    // let selected = locationValues.area_condition;
    // let find = selected.indexOf(data);

    // if (find > -1) {
    //   selected.splice(find, 1);
    // } else {
    //   selected.push(data);
    // }

    setLocationValues({
      ...locationValues,
      area_condition: data,
    });
    setChangedValue(true);
  };

  // only for view, not for api
  const onChangeSelectedAddress = (value) => {
    setSelectedAddress(value);
  };

  // if  google type lat lng, if not same as zone type
  const onChangeMainZone = (value) => {
    setMainZone(value);
  };

  // title
  const onChangeZoneType = (value) => {
    setZoneType(value);
  };

  // for selecting Incident Location only Address Dropdown
  const onChangeIncidentAddressZone = (value) => {
    setSelectedAddress(value);
  };

  // for selecting Incident Location all input forms
  const onChangeIncidentLocation = (key, value) => {
    setLocationValues({
      ...locationValues,
      [key]: value,
    });
    setChangedValue(true);
  };

  const onChangeIncidentSummary = (key, value) => {
    if (value.length > 0) {
      setChangedValue(true);
    } else {
      setChangedValue(false);
    }
    setSummaryValues({
      ...summaryValues,
      [key]: value,
    });
  };

  const onChangeIncidentProperty = (key, value) => {
    if (key === "party_type") {
      if (value.length > 0) {
        setChangedValue(true);
      } else {
        setChangedValue(false);
      }
    } else {
      setChangedValue(true);
    }
    setPropertyValues({
      ...propertyValues,
      [key]: value,
    });
  };

  const onChangeIncidentAnalysis = (key, value) => {
    setAnalysis({
      ...analysis,
      [key]: value,
    });
    setChangedValue(true);
  };

  const onChangeIncidentCasulaty = (arrayValue) => {
    setCasualtyValues(arrayValue);
    setChangedValue(true);
  };

  const onChangeIncidetWitness = (arrayValue) => {
    setWitnessValues(arrayValue);
    setChangedValue(true);
  };

  const onChangeSubZone = (value) => {
    setSubZone(value);
  };
  useEffect(() => {
    scrollToTopFunction();
  }, []);

  const customModalForConfirmCancel = (onClose) => {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Confirm to submit
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            This will undo all the changes you have made to the form. Do you
            want to leave the page?
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button className="witness_cancel_button" onClick={onClose}>
                No
              </Button>
            </div>
            <div
              onClick={() => {
                if (removedPhotoValues.length > 0) {
                  removedPhotoValues.map((p) => {
                    storage
                      .refFromURL(p)
                      .delete()
                      .catch((err) => console.log("err", err));
                  });
                }
                if (newAddedSignature.length > 0) {
                  newAddedSignature.map((p) => {
                    storage
                      .refFromURL(p)
                      .delete()
                      .catch((err) => console.log("err", err));
                  });
                }
                // if (casualtyValues.length > 0) {
                //   casualtyValues.map((c) => {
                //     if (c.signature) {
                //       storage
                //         .refFromURL(c.signature)
                //         .delete()
                //         .catch((err) => console.log("err", err));
                //     }
                //   });
                // }
                // if (witnessValues.length > 0) {
                //   witnessValues.map((w) => {
                //     if (w.signature) {
                //       storage
                //         .refFromURL(w.signature)
                //         .delete()
                //         .catch((err) => console.log("err", err));
                //     }
                //   });
                // }

                history.push("/incidents_list");
              }}
            >
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={onClose}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {createLoader ? (
        <Dimmer active inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      ) : (
        <div
          className="detail_container"
          ref={scrollRef}
          onClick={() => props.hideShowZoneList()}
        >
          {createLoader && (
            <Dimmer active inverted>
              <Loader inverted />
            </Dimmer>
          )}
          {/* Left Side */}

          <div className="case_detail">
            <div className="detail_header">
              <div
                to=""
                className="header_leftside"
                // onClick={() => history.goBack()}
              >
                {/* <img src={LeftArrowIcon} style={{ marginTop: 0 }} /> */}
                <div className="detail_title_incident_form">New Incident</div>
              </div>
            </div>
            {isMobile && <MobileSideBar />}
            <div className="detail_form ">
              {!isMobile && Sidebar()}

              <div className="form add_flex">
                <div
                  className={`form_container ${
                    showCasualtyForm || showWitnessForm
                      ? "add_width"
                      : "remove_width"
                  }`}
                >
                  {detailSelected === "Incident Description" ? (
                    <Incident_overview
                      CheckfilledMatodaryField={CheckfilledMatodaryField}
                      setCheckStatus={setCheckStatus}
                      mainSaveButton={mainSaveButton}
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      onChangeSelectedAddress={onChangeSelectedAddress}
                      selectedAddress={selectedAddress}
                      saveMainDraftButton={saveMainDraftButton}
                      onChangeIncidentOverView={onChangeIncidentOverView}
                      changedValue={changedValue}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      overViewInputError={{
                        typeError,
                        placementError,
                        categoryError,
                        involvedError,
                        injuryTypeError,
                        classificationError,
                      }}
                      photoError={photoError}
                      showNotiPage={showNotiPage}
                      // TimeStamp Location
                      hideShowZoneList={props.hideShowZoneList}
                      showShowZoneList={props.showShowZoneList}
                      showZoneDropDown={props.showZoneDropDown}
                      locationValues={locationValues}
                      onChangeIncidentLocation={onChangeIncidentLocation}
                      onChangeIncidentLocationZone={
                        onChangeIncidentLocationZone
                      }
                      onChangeIncidentInjuryType={onChangeIncidentInjuryType}
                      onChangeIncidentLocationCondtionsArea={
                        onChangeIncidentLocationCondtionsArea
                      }
                      locationInputError={{
                        parkError,
                        zoneError,
                        weatherError,
                        areaError,
                        dateError,
                        timeError,
                      }}
                      onChangeLocationDateTime={onChangeLocationDateTime}
                      locationDate={locationDate}
                      onChangeIncidentAddressZone={onChangeIncidentAddressZone}
                      subZone={subZone}
                      zoneType={zoneType}
                      mainZone={mainZone}
                      onChangeSubZone={onChangeSubZone}
                      onChangeZoneType={onChangeZoneType}
                      onChangeMainZone={onChangeMainZone}
                      // Photo & Video
                      photoValues={photoValues}
                      removedPhotoValues={removedPhotoValues}
                      setRemovedPhotoValues={setRemovedPhotoValues}
                      onChangePhoto={onChangePhoto}
                      filterPhotoFun={filterPhotoFun}
                      optionsState={{
                        incidentTypeList,
                        setIncidentTypeList,
                        incidentPlacementList,
                        setIncidentPlacementList,
                        incidentClassificationList,
                        setIncidentClassificationList,
                        injuryTypesList,
                        setInjuryTypesList,
                        parkList,
                        setParkList,
                        zoneList,
                        setZoneList,
                      }}
                      summaryValues={summaryValues}
                      onChangeIncidentSummary={onChangeIncidentSummary}
                      summaryInputError={{
                        titleError,
                        remarkError,
                      }}
                      changeDropDownId={changeDropDownId}
                      deletePendingItem={deletePendingItem}
                      paramPendingBool={paramPendingBool}
                    />
                  ) : detailSelected === "Person Involved Details" ? (
                    <CasualtyDetails
                      showNotiPage={showNotiPage}
                      setCheckStatus={setCheckStatus}
                      changeShowCasualyFormBool={changeShowCasualyFormBool}
                      changeHideCasualyFormBool={changeHideCasualyFormBool}
                      overViewValues={overViewValues}
                      showCasualtyForm={showCasualtyForm}
                      mainSaveButton={mainSaveButton}
                      casualtyValues={casualtyValues}
                      onChangeIncidentCasulaty={onChangeIncidentCasulaty}
                      saveMainDraftButton={saveMainDraftButton}
                      optionsState={{
                        nationalityList,
                        setNationalityList,
                      }}
                      changedValue={changedValue}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      deletePendingItem={deletePendingItem}
                      paramPendingBool={paramPendingBool}
                      newAddedSignature={newAddedSignature}
                      setNewAddedSignature={setNewAddedSignature}
                    />
                  ) : detailSelected === "Witnesses Details" ? (
                    <WitnessParticulars
                      showNotiPage={showNotiPage}
                      setCheckStatus={setCheckStatus}
                      changeShowWitnessFormBool={changeShowWitnessFormBool}
                      changeHideWitnessFormBool={changeHideWitnessFormBool}
                      overViewValues={overViewValues}
                      showWitnessForm={showWitnessForm}
                      mainSaveButton={mainSaveButton}
                      witnessValues={witnessValues}
                      onChangeIncidetWitness={onChangeIncidetWitness}
                      saveMainDraftButton={saveMainDraftButton}
                      changedValue={changedValue}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      deletePendingItem={deletePendingItem}
                      paramPendingBool={paramPendingBool}
                      newAddedSignature={newAddedSignature}
                      setNewAddedSignature={setNewAddedSignature}
                    />
                  ) : detailSelected === "Incident Summary" ? (
                    <IncidentSummary
                      showNotiPage={showNotiPage}
                      setCheckStatus={setCheckStatus}
                      mainSaveButton={mainSaveButton}
                      overViewValues={overViewValues}
                      saveMainDraftButton={saveMainDraftButton}
                      summaryValues={summaryValues}
                      onChangeIncidentSummary={onChangeIncidentSummary}
                      summaryInputError={{
                        titleError,
                        remarkError,
                      }}
                      deletePendingItem={deletePendingItem}
                      paramPendingBool={paramPendingBool}
                    />
                  ) : detailSelected === "Details of Property Damage" ? (
                    <PropertyDamageDetails
                      showNotiPage={showNotiPage}
                      setCheckStatus={setCheckStatus}
                      mainSaveButton={mainSaveButton}
                      propertyValues={propertyValues}
                      overViewValues={overViewValues}
                      saveMainDraftButton={saveMainDraftButton}
                      onChangeIncidentProperty={onChangeIncidentProperty}
                      propertyError={{
                        propertyInputError,
                        damagesError,
                        partyError,
                        proxityError,
                        damagesErrorTxt,
                        partyErrorTxt,
                        partyTypeError,
                        partyTypeErrorTxt,
                      }}
                      optionsState={{
                        priorityList,
                        setPriorityList,
                      }}
                      changedValue={changedValue}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      deletePendingItem={deletePendingItem}
                      paramPendingBool={paramPendingBool}
                    />
                  ) : detailSelected === "MPD Incident Analysis" ? (
                    <IncidentAnalysis
                      showNotiPage={showNotiPage}
                      setCheckStatus={setCheckStatus}
                      onChangeIncidentAnalysis={onChangeIncidentAnalysis}
                      analysis={analysis}
                      mainSaveButton={mainSaveButton}
                      overViewValues={overViewValues}
                      analysisError={{
                        actionError,
                        conditionError,
                        personnelError,
                        inputError,
                        jobError,
                      }}
                      optionsState={{
                        subStandardActionList,
                        setSubStandardActionList,
                        subStandardContionList,
                        setSubStandardContionList,
                        personnelFactorsList,
                        setPersonnelFactorsList,
                        jobFactorList,
                        setJobFactorList,
                      }}
                      changedValue={changedValue}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      deletePendingItem={deletePendingItem}
                      paramPendingBool={paramPendingBool}
                    />
                  ) : detailSelected === "Notification List" ? (
                    <NotificationsList
                      showNotiPage={showNotiPage}
                      setCheckStatus={setCheckStatus}
                      AuthDetail={props.AuthDetail}
                      mainSaveButton={mainSaveButton}
                      overViewValues={overViewValues}
                      departState={{ departState, setDepartState }}
                      usersState={{ usersState, setUsersState }}
                      onChangeUsersState={onChangeUsersState}
                      getUsersState={getUsersState}
                      onChangeDepartState={onChangeDepartState}
                      getDepartState={getDepartState}
                      departFoldState={{ departFoldState, setDepartFoldState }}
                      getDepartFoldState={getDepartFoldState}
                      onChangeDepartFoldState={onChangeDepartFoldState}
                      incident_classification={
                        overViewValues.incident_classification
                      }
                      fetchUsersBySearch={fetchUsersBySearch}
                      dropdownId={dropdownId}
                      deletePendingItem={deletePendingItem}
                      paramPendingBool={paramPendingBool}
                      setCheckAllDepartmentNoti={setCheckAllDepartmentNoti}
                      checkAllDepartmentNoti={checkAllDepartmentNoti}
                      onChangeDepartmentForNoti={onChangeDepartmentForNoti}
                      changedValue={changedValue}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      handleClickDepartment={handleClickDepartment}
                      userListForNoti={userListForNoti}
                      userListForNotiLoader={userListForNotiLoader}
                      searchUsersDataPagiantion={searchUsersDataPagiantion}
                      setSearchUsersPagination={setSearchUsersPagination}
                      totalUsersPage={totalUsersPage}
                      setTotalUserspage={setTotalUserspage}
                      lastUsersItem={lastUsersItem}
                      setLastUsersItem={setLastUsersItem}
                      allSubDepartmentList={allSubDepartmentList}
                      totalpageForAllSubDepart={totalpageForAllSubDepart}
                      lastItemForAllSubDepart={lastItemForAllSubDepart}
                      subDepartmentForPagination={subDepartmentForPagination}
                      handlePaginationForAllSubDepart={
                        handlePaginationForAllSubDepart
                      }
                      currentAllSubDepartPage={currentAllSubDepartPage}
                      refreshAllSubDepart={refreshAllSubDepart}
                      setCurrentAllSubDepartPage={setCurrentAllSubDepartPage}
                      fetchDefaultDepartment={fetchDefaultDepartment}
                      setSearchBy={setSearchBy}
                      searchBy={searchBy}
                      wait={wait}
                      reSubDepartmentList={reSubDepartmentList}
                      reHandleImageClickDepartment={
                        reHandleImageClickDepartment
                      }
                      handleSubDepartCheckBox={handleSubDepartCheckBox}
                      reUsersList={reUsersList}
                      reFetchUser={reFetchUser}
                      reUserStateOnChange={reUserStateOnChange}
                      reSearchByDepartment={reSearchByDepartment}
                      reSearchDepartment={reSearchDepartment}
                      reSearchByUser={reSearchByUser}
                      reSearchUsersWithDepart={reSearchUsersWithDepart}
                      clickSearchBtnNoti={clickSearchBtnNoti}
                      setClickSearchBtnNoti={setClickSearchBtnNoti}
                    />
                  ) : detailSelected === "Notification Settings" ? (
                    <NotificationsSetting
                      showNotiPage={showNotiPage}
                      setCheckStatus={setCheckStatus}
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      mainSaveButton={mainSaveButton}
                      notiSettingValues={notiSettingValues}
                      onChangeNotiSetting={onChangeNotiSetting}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      deletePendingItem={deletePendingItem}
                      paramPendingBool={paramPendingBool}
                      changedValue={changedValue}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  AuthDetail: state.loggedInUserDetail.userDetail,
  subDeparts: state.incident.subDeparts,
  showZoneDropDown: state.incident.showZoneDropDown,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAllSubDepartment: () => dispatch(fetchAllSubDepartment()),
  showShowZoneList: () => dispatch(showShowZoneList()),
  hideShowZoneList: () => dispatch(hideShowZoneList()),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewIncident);
