import config from "./config";
import { auth } from "./firebase";

const get = (path, params, token) => {
  return doRequest(path, params, "GET", token);
};

const post = (path, params, token) => {
  return doRequest(path, params, "POST", token);
};

const remove = (path, params, token) => {
  return doRequest(path, params, "DELETE", token);
};

const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
};

const doRequest = async (path, params, method, token) => {
  const options = { method, headers: {} };

  // auth.onAuthStateChanged(function (user) {
  //   if (user) {
  //     user.getIdToken().then(function (data) {
  //       console.log(data);
  //     });
  //   }
  // });
  const getToken = await auth.currentUser.getIdToken();

  // convert the object to params
  params
    ? method === "GET"
      ? (path += `?${objectToQueryString(params)}`)
      : (options.body = params)
    : (options.body = params);

  // headers
  options.headers = new Headers({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${getToken}`,
  });

  console.log("dsafasdf", config.url + path);

  return fetch(config.url + path, options).then((response) => {
    if (response.status === 204) {
      return undefined;
    }
    if (response.status === 403) {
      token = null;
    }
    return response.json().then((result) => {
      return result;
    });
  });
};
export default {
  get,
  post,
  remove,
};
