import React, { useState, useEffect } from "react";
import PlaceList from "../../../../../components/PlaceList";
import { db } from "../../../../../config/firebase";
import DraftFooter from "../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../components/ReopenFooter";

import { Checkbox, Input, Button } from "semantic-ui-react";
import ClosedFooter from "../../../../../components/ClosedFooter";
import { useHistory } from "react-router-dom";

function IncidentAnalysis(props) {
  const [subStandardActionLoader, setSubStandardActionLoader] = useState(true);
  const [subStandardContionLoader, setSubStandardContionLoader] =
    useState(false);
  const [personnelFactorsLoader, setPersonnelFactorsLoader] = useState(false);
  const [jobFactorLoader, setJobFactorListLoader] = useState(false);
  const { onChangeIncidentAnalysis, analysis, mainSaveButton, analysisError } =
    props;
  const { actionError, conditionError, personnelError, jobError, inputError } =
    analysisError;
  const incidentStatus = props.incidentStatus;
  let history = useHistory();

  useEffect(() => {
    // fetch Substandard Action
    setSubStandardActionLoader(true);
    setSubStandardContionLoader(true);
    setPersonnelFactorsLoader(true);
    setJobFactorListLoader(true);
    db.collection("static_data")
      .doc("substandard_action")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().substandard_action_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setSubStandardActionLoader(true);
        setSubStandardActionList(data);
      });

    // fetch Substandard Condition
    db.collection("static_data")
      .doc("substandard_condition")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().substandard_condition.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setSubStandardContionLoader(false);
        setSubStandardContionList(data);
      });

    // fetch personnel Factors
    db.collection("static_data")
      .doc("personnel_factors")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().personnel_factors_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setPersonnelFactorsLoader(false);
        setPersonnelFactorsList(data);
      });

    // fetch jobs Factors
    db.collection("static_data")
      .doc("job_factors")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().job_factors_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setJobFactorListLoader(false);
        setJobFactorList(data);
      });
  }, []);

  const {
    subStandardActionList,
    setSubStandardActionList,
    subStandardContionList,
    setSubStandardContionList,
    personnelFactorsList,
    setPersonnelFactorsList,
    jobFactorList,
    setJobFactorList,
  } = props.optionsState;

  const onListenValueChange = props.onListenValueChange;
  const previousIncident = props.previousIncident;

  const checkPreviousValueAndCurrent = (previous, current, key) => {
    let splitUnderScore = key.replace("_", " ");

    if (previous != current) {
      onListenValueChange(
        key,
        `Update ${splitUnderScore}  from ${previous} to ${current}`
      );
    } else {
      onListenValueChange(key, null);
    }
  };
  function Navigation() {
    return (
      <>
        {incidentStatus == "Draft" ? (
          <DraftFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Pending Approval" ? (
          <PendingApprovalFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Approve" ? (
          <PendingRejectFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Closed" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            onListenValueChange={props.onListenValueChange}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Open" ? (
          <ClosedFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : (
          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  let filterP4 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4");
  let filterP4A =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4a");

  let filterP5 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p5");
  return (
    <div className="incident_overview_container">
      {/* Substandard Action */}
      <h3 className={`dropdown_titl`}>Substandard Action {}</h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown`}
        icon_classes="incident-place-list-icon"
        value={analysis.action}
        options={subStandardActionList}
        onChange={(e, data) => {
          onChangeIncidentAnalysis("action", data.value);
          checkPreviousValueAndCurrent(
            previousIncident.case_details.mpd_incident_analysis
              .substandard_action,
            data.value,
            "substandard_action"
          );
        }}
      />
      {/* {actionError && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a selection
        </div>
      )} */}
      {/* Substandard Condition */}
      <h3 className={`dropdown_title`}>Substandard Condition</h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown`}
        icon_classes="incident-place-list-icon"
        value={analysis.condition}
        options={subStandardContionList}
        onChange={(e, data) => {
          onChangeIncidentAnalysis("condition", data.value);
          checkPreviousValueAndCurrent(
            previousIncident.case_details.mpd_incident_analysis
              .substandard_condition,
            data.value,
            "substandard_condition"
          );
        }}
      />
      {/* {conditionError && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a selection
        </div>
      )} */}
      {/* Personnel Factors */}
      <h3 className={`dropdown_title`}>Personnel Factors</h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown `}
        icon_classes="incident-place-list-icon"
        options={personnelFactorsList}
        value={analysis.personnel}
        onChange={(e, data) => {
          onChangeIncidentAnalysis("personnel", data.value);
          checkPreviousValueAndCurrent(
            previousIncident.case_details.mpd_incident_analysis
              .personnel_factors,
            data.value,
            "personnel_factors"
          );
        }}
      />
      {/* {personnelError && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a selection
        </div>
      )} */}
      {/* Job Factors */}
      <h3 className={`dropdown_title `}>Job Factors</h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown`}
        icon_classes="incident-place-list-icon"
        value={analysis.job}
        options={jobFactorList}
        onChange={(e, data) => {
          onChangeIncidentAnalysis("job", data.value);
          checkPreviousValueAndCurrent(
            previousIncident.case_details.mpd_incident_analysis.job_factors,
            data.value,
            "job_factors"
          );
        }}
      />
      {/* {jobError && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a selection
        </div>
      )} */}
      {/* Work Stoppage   */}
      <div style={{ marginBottom: 30, marginTop: 30 }}>
        <h3>Work Stoppage </h3>
        <div>
          <Checkbox
            radio
            label="Yes"
            checked={analysis.stoppage == "Yes"}
            onChange={() => {
              onChangeIncidentAnalysis("stoppage", "Yes");
              checkPreviousValueAndCurrent(
                previousIncident.case_details.mpd_incident_analysis
                  .work_stoppage,
                "Yes",
                "work_stoppage"
              );
            }}
            className="checkBox"
          />
        </div>
        <div>
          <Checkbox
            radio
            label="No"
            checked={analysis.stoppage == "No"}
            onChange={() => {
              onChangeIncidentAnalysis("stoppage", "No");
              checkPreviousValueAndCurrent(
                previousIncident.case_details.mpd_incident_analysis
                  .work_stoppage,
                "No",
                "work_stoppage"
              );
            }}
            className="checkBox"
          />
          <div
            style={{
              marginTop: 10,
            }}
          ></div>
          <Input
            fluid
            className={`damaged_input`}
            value={analysis.stoppage_text}
            onKeyPress={(event) => {
              if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.target.value)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              onChangeIncidentAnalysis("stoppage_text", e.target.value);
              checkPreviousValueAndCurrent(
                previousIncident.case_details.mpd_incident_analysis
                  .stoppage_text,
                e.target.value,
                "stoppage_text"
              );
            }}
          />
        </div>
      </div>

      {/* Footer */}
      {filterP4A &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" &&
        props.previousIncident.created_by == props.AuthDetail.id && (
          <>{Navigation()}</>
        )}
      {filterP4 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" && <>{Navigation()}</>}

      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c2" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c3" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c4" && <>{Navigation()}</>}
      {props.previousIncident &&
      props.previousIncident.filtered_category == "" ? (
        <>
          {filterP4 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
        </>
      ) : null}
      <div
        className="incident_footer"
        style={{ bottom: 0, justifyContent: "flex-end", zIndex: 1 }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }} onClick={() => {}}>
            <Button
              className="witness_cancel_button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncidentAnalysis;
