import React, { useState, useEffect } from "react";
import { Input, Button } from "semantic-ui-react";
import Incident_detail_dropdown from "../../../../../components/incident_detail_dropdown";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import update from "immutability-helper";
import TypeList from "../../../../../components/TypeList";
import DatePicker from "react-datepicker";
import { db } from "../../../../../config/firebase";
import DraftFooter from "../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../components/ReopenFooter";

//images
import CalendarIcon from "../../../../../assets/images/incident/ic_calendar.svg";
import DeleteIcon from "../../../../../assets/images/incident/btn_delete.svg";

import "../cases/cases.css";
import PlaceList from "../../../../../components/PlaceList";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import ClosedFooter from "../../../../../components/ClosedFooter";

function RecommendedCorrectiveActions(props) {
  const incidentStatus = props.incidentStatus;
  let history = useHistory();
  var section_index = 0;
  const [selectedDate, setSelectedDate] = useState(null);
  const [section, setSection] = useState([
    { id: Math.random(), description: "" },
  ]);
  const [statusOption, setStatusOptions] = useState([]);
  const onChangeValue = (e, type, id) => {
    let cloneSection = section;
    const findIndex = cloneSection.findIndex((sec) => sec.id === id);
    if (type == "description") {
      cloneSection[findIndex].description = e.target.value;
    }

    setSection(cloneSection);
  };

  const values = props.correctiveActionValues;
  const AddCorrectiveAction = props.AddCorrectiveAction;
  const RemoveCorrectiveAction = props.RemoveCorrectiveAction;
  const onChangeCorrectiveAction = props.onChangeCorrectiveAction;
  const { actionAssignedList, setActionAssignedList } = props.optionsState;

  useEffect(() => {
    // fetch Action Assigned
    db.collection("static_data")
      .doc("action_assigned_to")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().action_assigned_to_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setActionAssignedList(data);
      });
  }, []);

  useEffect(() => {
    db.collection("static_data")
      .doc("corrective_actions_status")
      .get()
      .then((snapshot) => {
        let data = [];
        // setStatusOptions
        snapshot.data().corrective_actions_status.forEach((s) => {
          data.push({
            key: s,
            text: s,
            value: s,
          });
        });
        setStatusOptions(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  function Navigation() {
    return (
      <>
        {incidentStatus == "Draft" ? (
          <DraftFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Pending Approval" ? (
          <PendingApprovalFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            authDetail={props.AuthDetail}
            created_by={props.previousIncident.created_by}
          />
        ) : incidentStatus == "Approve" ? (
          <PendingRejectFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Closed" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            onListenValueChange={props.onListenValueChange}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Open" ? (
          <ClosedFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Archived" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            isArchived={true}
            showNotiPage={props.showNotiPage}
            onListenValueChange={props.onListenValueChange}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : (
          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  let filterP4 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4");
  let filterP4A =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4a");

  let filterP5 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p5");

  return (
    <div className="incident_overview_container">
      {" "}
      {values &&
        values.length > 0 &&
        values
          .sort((a, b) => {
            return (
              new Date(a.arr_created_at).getTime() -
              new Date(b.arr_created_at).getTime()
            );
          })
          .map((_, index) => {
            if (_.status != "delete") {
              section_index += 1;
              return (
                <div key={_.id}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h3 style={{ color: "#008237" }}>Action {index + 1} </h3>
                    {values.length === 1 ? null : (
                      <div
                        onClick={() => {
                          section_index -= 1;
                          RemoveCorrectiveAction(_.id);
                        }}
                      >
                        <img src={DeleteIcon} />
                      </div>
                    )}
                  </div>
                  <div style={{ marginBottom: 30 }}>
                    <h3>Description </h3>
                    <Input
                      fluid
                      value={_.description}
                      className="damaged_input"
                      onChange={(e, data) => {
                        onChangeCorrectiveAction(
                          data.value,
                          "description",
                          _.id,
                          index + 1
                        );
                      }}
                    />
                    <h3 className="dropdown_title">Action assigned to</h3>
                    <PlaceList
                      custom_classes={`incident-place-list incident-list-dropdown

                  `}
                      icon_classes="incident-place-list-icon"
                      value={_.action_assigned_to}
                      onChange={(e, data) =>
                        onChangeCorrectiveAction(
                          data.value,
                          "action_assigned_to",
                          _.id,
                          index + 1
                        )
                      }
                      options={actionAssignedList}
                    />
                    {/* To be done by  */}
                    <div
                      className="input_container_fluid"
                      style={{ marginTop: 30 }}
                    >
                      <h3>To be done by </h3>
                      {/* <div className="input_title">To be done by </div> */}
                      <div className="datePicker">
                        <DatePicker
                          onChangeRaw={(e) => e.preventDefault()}
                          onChange={(date) =>
                            onChangeCorrectiveAction(
                              moment(date).format("D MMM yyyy"),
                              "to_be_done_by",
                              _.id,
                              index + 1
                            )
                          }
                          value={
                            _.to_be_done_by &&
                            moment(_.to_be_done_by).format("D MMM yyyy")
                          }
                        />
                        <img src={CalendarIcon} />
                      </div>
                    </div>
                    {/* Status */}
                    <h3 className="dropdown_title">Status</h3>
                    <PlaceList
                      custom_classes={`incident-place-list incident-list-dropdown`}
                      icon_classes="incident-place-list-icon"
                      options={statusOption}
                      value={_.recommended_ca_status}
                      onChange={(e, data) =>
                        onChangeCorrectiveAction(
                          data.value,
                          "recommended_ca_status",
                          _.id,
                          index + 1
                        )
                      }
                    />

                    <div className="underline_add_btn"></div>
                    {/* Add Button */}
                  </div>
                </div>
              );
            }
          })}
      <div
        className="add_button"
        onClick={() => {
          const newItem = {
            recommended_ca_status: "",
            description: "",
            recommended_ca_no: uuidv4(),
            to_be_done_by: "",
            action_assigned_to: "",
            status: "",
            id: "temp-" + uuidv4(),
            arr_created_at: new Date().toISOString(),
          };
          AddCorrectiveAction(newItem);
        }}
      >
        + Add
      </div>
      {/* Footer */}
      {filterP4A &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" &&
        props.previousIncident.created_by == props.AuthDetail.id && (
          <>{Navigation()}</>
        )}
      {filterP4 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c2" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c3" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c4" && <>{Navigation()}</>}
      <div
        className="incident_footer"
        style={{ bottom: 0, justifyContent: "flex-end", zIndex: 1 }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }} onClick={() => {}}>
            <Button
              className="witness_cancel_button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecommendedCorrectiveActions;
