import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { INCIDENTS_AUDIT_ROUTE } from "../routes";
import { useHistory, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import api from "../config/api";
import config from "../config/config";

function ReopenFooter(props) {
  const [showModal, setShowModal] = useState(false);
  const [remark, setRemark] = useState("");
  const [remarkError, setRemarkError] = useState(false);
  const [loader, setLoader] = useState(false);
  let history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramId = query.get("id");
  const onListenValueChange = props.onListenValueChange;
  const { UPDATE_INCIDENTS, CREATE_AUDIT_INCIDENTS } = config.api_endpoint;
  let filterP16 = props.perms.some((p) => p == "p16");
  const openIncidentFunction = () => {
    setLoader(true);
    let data = {
      id: paramId,
      incident_status: props.prevCategory == "c1" ? "Open" : "Pending Approval",
      changed_by: props.AuthDetail.id,
      platform: "web",
    };
    let audit = {
      incident_id: paramId,
      changed_by: props.AuthDetail.id,
      description: ["Reopen reason:" + remark],
      platform: "web",
    };

    api
      .post(CREATE_AUDIT_INCIDENTS, JSON.stringify(audit), {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (result) => {
        setLoader(false);
      })
      .catch((err) => console.log("errgg", err));
  };

  const modalFor = () => {
    return (
      <div onClick={() => setShowModal(false)} className="suspendAlertBox">
        <div
          style={{
            minWidth: 600,
            minHeight: 200,
            maxHeight: 500,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
            overflowY: "scroll",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div>
            <h3 style={{ color: remarkError && "#e72d35" }}>Remark*</h3>
            <textarea
              className="remark_text_area"
              style={{ marginBottom: 20, height: 100 }}
              onChange={(e) => {
                setRemark(e.target.value);
                onListenValueChange(
                  "Reopen",
                  `Reopen reason: ${e.target.value}`
                );
              }}
              style={{
                backgroundColor: remarkError && "#fff0f0",
                border: remarkError && "1px solid #e72d35",
              }}
            ></textarea>
          </div>
          {/* Footer */}
          <div className="incident_update_footer">
            <div style={{ display: "flex" }}>
              <div
                style={{ width: "100%" }}
                onClick={() => setShowModal(false)}
              >
                <Button className="witness_cancel_button">Cancel</Button>
              </div>
              <div
                style={{ width: "100%" }}
                onClick={() => {
                  if (remark == "") {
                    return setRemarkError(true);
                  }
                  props.setCheckStatus("noStatus");
                  // openIncidentFunction();
                  props.mainSaveButton();
                }}
              >
                {loader ? (
                  <div>Loading</div>
                ) : (
                  <Button className="witness_save_button">Reopen </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="incident_footer" style={{ bottom: 0 }}>
      {showModal && modalFor()}
      <div style={{ display: "flex" }}>
        {/* {props.showNotiPage && (
          <div
            style={{ width: "100%" }}
            onClick={() => {
              props.setCheckStatus(null);
              props.mainSaveButton();
            }}
          >
            <Button className="witness_cancel_button">Submit </Button>
          </div>
        )} */}
        {/* {!props.isArchived && (
          <div
            style={{ width: "100%" }}
            onClick={() => {
              props.setCheckStatus("Archive");
              props.mainSaveButton();
            }}
          >
            <Button className="incident_archive_button">Archive</Button>
          </div>
        )} */}

        {/* <div style={{ width: "100%" }} onClick={() => {}}>
          <Button className="incident_cancel_button">Closed</Button>
        </div>
        <div style={{ width: "100%" }}>
          <Button className="witness_cancel_button">Approve </Button>
        </div> */}
      </div>
      <div style={{ display: "flex" }}>
        {!props.isArchived && filterP16 && (
          <div
            className="view_audit"
            onClick={() =>
              history.push(`${INCIDENTS_AUDIT_ROUTE}?id=${paramId}`)
            }
          >
            View Audit Trail
          </div>
        )}

        <div style={{ width: "100%" }} onClick={() => {}}>
          <Button
            className="witness_cancel_button"
            onClick={() => {
              if (
                props.valueChanged == null ||
                props.valueChanged == undefined
              ) {
                return history.goBack();
              } else {
                if (Object.values(props.valueChanged)[0] == null) {
                  history.push("/incidents_list");
                  // history.goBack();
                } else {
                  if (Object.values(props.valueChanged).length > 0) {
                    return confirmAlert({
                      customUI: ({ onClose }) =>
                        props.customModalForConfirmCancel(onClose),
                    });
                  } else {
                    history.push("/incidents_list");
                    // history.goBack();
                  }
                }
              }
            }}
          >
            Cancel
          </Button>
        </div>
        <div
          style={{ width: "100%" }}
          onClick={() => {
            setShowModal(true);
          }}
        >
          <Button className="witness_save_button">Reopen </Button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  AuthDetail: state.loggedInUserDetail.userDetail,
});
export default connect(mapStateToProps, null)(ReopenFooter);
