import React from "react";
import { Checkbox, Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../../routes";
import DraftFooter from "../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../components/ReopenFooter";
import ClosedFooter from "../../../../../components/ClosedFooter";

function NotificationsSetting(props) {
  const values = props.notiSettingValues;
  const onChangeNotiSetting = props.onChangeNotiSetting;
  const incidentStatus = props.incidentStatus;
  const previousIncident = props.previousIncident;
  let history = useHistory();
  const submitForm = () => {
    props.mainSaveButton();
  };
  function Navigation() {
    return (
      <>
        {incidentStatus == "Draft" ? (
          <DraftFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
          />
        ) : incidentStatus == "Pending Approval" ? (
          <PendingApprovalFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
          />
        ) : incidentStatus == "Approve" ? (
          <PendingRejectFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
          />
        ) : incidentStatus == "Closed" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
          />
        ) : incidentStatus == "Open" ? (
          <ClosedFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
          />
        ) : incidentStatus == "Archived" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            isArchived={true}
          />
        ) : (
          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  let filterP4 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4");
  let filterP4A =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4a");
  let filterP5 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p5");

  let filterP6 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p6");
  let filterP7 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p7");
  let filterP15 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p15");

  return (
    <div className="incident_overview_container">
      <div>
        {/* CheckBox Container */}
        <div className="checkbox_container">
          <h3>Set the frequency for the notification - This is for SMS only</h3>
          <div>
            <Checkbox
              radio
              label="One time message"
              checked={values.frequency_type == "one time"}
              onChange={() => onChangeNotiSetting("frequency_type", "one time")}
              className="checkBox"
            />
          </div>
          {/* <div>
            <Checkbox
              radio
              label="Response Required"
              checked={values.frequency_type == "repeat"}
              onChange={() => onChangeNotiSetting("frequency_type", "repeat")}
              className="checkBox"
            />
          </div> */}
        </div>
      </div>

      {/* Footer */}
      <div
        className="incident_footer"
        style={{
          bottom: -100,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          {props.overViewValues.is_emergency_incident == "Yes" ||
          props.showNotiPage ? (
            <div
              onClick={() => {
                props.setCheckStatus("Yes");
                props.mainSaveButton();
              }}
            >
              <Button className="witness_cancel_button">Submit </Button>
            </div>
          ) : null}
          {props.paramPendingBool && (
            <div>
              <Button
                className="incident_cancel_button"
                onClick={props.deletePendingItem}
              >
                Delete
              </Button>
            </div>
          )}
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              if (props.changedValue) {
                return confirmAlert({
                  customUI: ({ onClose }) =>
                    props.customModalForConfirmCancel(onClose),
                });
              } else {
                history.push("/incidents_list");
              }
            }}
          >
            <Button className="witness_cancel_button">Cancel</Button>
          </div>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              submitForm();
            }}
          >
            <Button className="witness_save_button">Save </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationsSetting;
