import React from "react";
import { Checkbox, Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import { INCIDENTS_AUDIT_ROUTE } from "../../../../../routes";
import DraftFooter from "../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../components/ReopenFooter";
import ClosedFooter from "../../../../../components/ClosedFooter";

function NotificationsSetting(props) {
  const values = props.notiSettingValues;
  const onChangeNotiSetting = props.onChangeNotiSetting;
  const incidentStatus = props.incidentStatus;
  const previousIncident = props.previousIncident;
  let history = useHistory();
  function Navigation() {
    return (
      <>
        {incidentStatus == "Draft" ? (
          <DraftFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Pending Approval" ? (
          <PendingApprovalFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            authDetail={props.AuthDetail}
            created_by={props.previousIncident.created_by}
          />
        ) : incidentStatus == "Approve" ? (
          <PendingRejectFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Closed" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            onListenValueChange={props.onListenValueChange}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Open" ? (
          <ClosedFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Archived" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            isArchived={true}
            onListenValueChange={props.onListenValueChange}
          />
        ) : (
          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  let filterP4 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4");
  let filterP4A =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4a");
  let filterP5 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p5");

  let filterP6 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p6");
  let filterP7 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p7");
  let filterP15 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p15");

  return (
    <div className="incident_overview_container">
      <div>
        {/* CheckBox Container */}
        <div className="checkbox_container">
          <h3>Set the frequency for the notification - This is for SMS only</h3>
          <div>
            <Checkbox
              radio
              label="One time message"
              checked={values.frequency_type == "one time"}
              onChange={() => onChangeNotiSetting("frequency_type", "one time")}
              className="checkBox"
            />
          </div>
          {/* <div>
            <Checkbox
              radio
              label="Response Required"
              checked={values.frequency_type == "repeat"}
              onChange={() => onChangeNotiSetting("frequency_type", "repeat")}
              className="checkBox"
            />
          </div> */}
        </div>
      </div>

      {/* Footer */}
      {filterP4A &&
        previousIncident &&
        previousIncident.filtered_category == "c1" &&
        previousIncident.created_by == props.AuthDetail.id && (
          <>{Navigation()}</>
        )}

      {filterP4 &&
        previousIncident &&
        previousIncident.filtered_category == "c1" && <>{Navigation()}</>}
      {filterP5 &&
        previousIncident &&
        previousIncident.filtered_category == "c2" && <>{Navigation()}</>}
      {filterP5 &&
        previousIncident &&
        previousIncident.filtered_category == "c3" && <>{Navigation()}</>}
      {filterP5 &&
        previousIncident &&
        previousIncident.filtered_category == "c4" && <>{Navigation()}</>}
      {props.previousIncident &&
      props.previousIncident.filtered_category == "" ? (
        <>
          {filterP4 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
        </>
      ) : null}
      <div
        className="incident_footer"
        style={{ bottom: 0, justifyContent: "flex-end", zIndex: 1 }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }} onClick={() => {}}>
            <Button
              className="witness_cancel_button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationsSetting;
