import React, { useState, useEffect } from "react";
import { Button, Loader, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { storage } from "../../../../../config/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faDownload } from "@fortawesome/free-solid-svg-icons";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// images
import TrashIcon from "../../../../../assets/images/incident/btn_delete.svg";

// css
import "./cases.css";

function PhotoVideo(props) {
  const [imageModal, setImageModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [imageModalFile, setImageModalFile] = useState("");
  let history = useHistory();
  const [files, setFile] = useState([]);
  const [imageName, setImageName] = useState(false);

  const onChangePhoto = props.onChangePhoto;
  const photoValues = props.photoValues;
  const filterPhotoFun = props.filterPhotoFun;
  useEffect(() => {
    if (photoValues.length > 0) {
      let data = [];
      photoValues.map((p) => {
        let image;
        if (p && p.includes(":443")) {
          image = p.replace(":443", "");
        } else {
          image = p;
        }

        const name = storage.refFromURL(image).name;
        var parts = name.split(/[#\?&]/g);
        var filteredParts = parts
          .filter(function (part) {
            return part.split("=")[0] == "name";
          })
          .toString()
          .replace("name=", "");

        data.push({
          img: p,
          name: filteredParts,
        });
      });
      setFile(data);
    } else {
      setFile([]);
    }
  }, [photoValues]);

  const previousIncident = props.previousIncident;
  const onListenValueChange = props.onListenValueChange;

  const checkPreviousValueAndCurrent = (previous, current, key) => {
    let splitUnderScore = key.replace("_", " ");

    onListenValueChange("photoVideos", current);
  };

  const download = (image, imageName) => {
    fetch(image, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", imageName);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="incident_overview_container" style={{ marginTop: -30 }}>
      <h3 className={`category_title ${props.photoError && "error"}`}>
        Add Video & Photos
      </h3>

      <div className={`asset_container ${props.photoError && "error"} `}>
        <div className="asset_item_container ">
          {files && files.length > 0 ? (
            files.map((_, index) => {
              return (
                <div className="asset_item" key={_.id}>
                  {_.img.toLowerCase().includes("mp4") ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="asset_dot"></div>
                      <p
                        className="asset_text"
                        onClick={() => {
                          props.setVideoModal(true);
                          props.setVideoUrl(_.img);

                          // let reader = new FileReader();
                          // reader.onload = (e) => {
                          //   setImageModalFile(e.target.result);
                          // };

                          setImageModalFile(_.img);
                        }}
                      >
                        {_.name ? _.name : `video ${index + 1}`}
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="asset_dot"></div>
                      <p
                        className="asset_text"
                        onClick={() => {
                          setImageModal(true);
                          setImageName(_.name);

                          // let reader = new FileReader();
                          // reader.onload = (e) => {
                          //   setImageModalFile(e.target.result);
                          // };

                          setImageModalFile(_.img);
                        }}
                      >
                        {_.name ? _.name : `image ${index + 1}`}
                      </p>
                    </div>
                  )}

                  <div
                    onClick={() => {
                      // setFile(files.filter((f) => f.id !== _.id));
                      filterPhotoFun(_.img);
                      checkPreviousValueAndCurrent(
                        null,
                        "Deleted photo or video",
                        "photos_vidoes"
                      );
                    }}
                  >
                    <img src={TrashIcon} />
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center", color: "#8F8F8F" }}>
              No Photo/Video added yet.
            </div>
          )}
        </div>
        <div className="underline_add_btn "></div>
        {loader ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader active size="mini" inline="right" />
          </div>
        ) : (
          <div className="add_asset" style={{ justifyContent: "center" }}>
            <input
              type="file"
              className="asset_file"
              accept="image/jpg, image/jpeg, image/png, video/*"
              onChange={(e) => {
                let type = e.target.files[0].type;
                if (
                  type.includes("jpg") ||
                  type.includes("jpeg") ||
                  type.includes("png") ||
                  type.includes("video")
                ) {
                  if (e.target.files[0]) {
                    const nameForImage =
                      "name=" +
                      e.target.files[0].name +
                      "&" +
                      "time=" +
                      new Date().getTime() +
                      Math.random();
                    setLoader(true);
                    storage
                      .ref(`/photovideos/${nameForImage}`)
                      .put(e.target.files[0])
                      .then(async (img) => {
                        const image = await storage
                          .ref(`/photovideos/${nameForImage}`)
                          .getDownloadURL();
                        const cloneRemovedPhotoValues =
                          props.removedPhotoValues;
                        cloneRemovedPhotoValues.push(image);
                        props.setRemovedPhotoValues(cloneRemovedPhotoValues);
                        onChangePhoto(image);
                        checkPreviousValueAndCurrent(
                          null,
                          "Added new photo or video",
                          "photos_vidoes"
                        );
                        setLoader(false);
                      })
                      .catch((err) => {
                        setLoader(false);
                        console.log("err", err);
                      });
                  }
                } else {
                  alert("File format not allowed");
                }
              }}
            />
            + Add
          </div>
        )}
      </div>
      {imageModal && (
        <Lightbox
          mainSrc={imageModalFile}
          onCloseRequest={() => setImageModal(false)}
          toolbarButtons={[
            <div
              className="download_btn"
              onClick={() => download(imageModalFile, imageName)}
            >
              <Icon name="download" id="download-icon" />
            </div>,
          ]}
        />
      )}
      {/* Footer */}
    </div>
  );
}

export default PhotoVideo;
