import React, { useState, useEffect, useRef } from "react";
import { Button } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { StepperNav } from "vertical-stepper-nav";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { auth, storage, db } from "../../../config/firebase";
import api from "../../../config/api";
import config from "../../../config/config";
import Geocode from "react-geocode";
import { useMediaQuery } from "react-responsive";

//pages
import Incident_overview from "./forms/cases/Incident_overview";
import IncidentLocation from "./forms/cases/IncidentLocation";
import PropertyDamageDetails from "./forms/cases/PropertyDamageDetails";
import CasualtyDetails from "./forms/cases/CasualtyDetails";
import WitnessParticulars from "./forms/cases/WitnessParticulars";
import IncidentSummary from "./forms/cases/IncidentSummary";
import PhotoVideo from "./forms/cases/PhotoVideo";
import AddationAction from "./forms/aar/AddationAction";
import RootCauseAnalysis from "./forms/aar/RootCauseAnalysis";
import RecommendedCorrectiveActions from "./forms/aar/RecommendedCorrectiveActions";
import MedicalStatus from "./forms/aar/MedicalStatus";

//images
import LeftArrowIcon from "../../../assets/images/incident/leftarrow.svg";
import RightArrowIcon from "../../../assets/images/incident/rightarrow.svg";
import DownArrowIcon from "../../../assets/images/incident/arrowdowndrop.svg";
import ExportImage from "../../../assets/images/incident/export.svg";
import ExitIcon from "../../../assets/images/incident/exit.svg";

// css
import "./detail.css";
import IncidentUpdate from "./forms/cases/IncidentUpdate";
import IncidentAnalysis from "./forms/cases/IncidentAnalysis";
import moment from "moment";
import NotificationsList from "./forms/notifications/NotificationsList";
import NotificationsSetting from "./forms/notifications/NotificationsSetting";
import { v4 as uuidv4 } from "uuid";

import { Loader, Dimmer } from "semantic-ui-react";
import {
  fetchAllSubDepartment,
  showShowZoneList,
  hideShowZoneList,
} from "../../../store/actions/incidentAction";
import PostIncident from "./forms/aar/PostIncident";

function Detail(props) {
  const gApi = "AIzaSyCsvLI--q5rNFAGwq0Cgr7IWkh6y11x1wQ";
  Geocode.setApiKey(gApi);
  Geocode.setLanguage("en");
  Geocode.enableDebug();
  let history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramId = query.get("id");
  const searchBool = query.get("search");
  const scrollRef = useRef(null);
  const {
    UPDATE_INCIDENTS,
    CREATE_AUDIT_INCIDENTS,
    GET_ONE_INCIDENT,
    GET_ALL_NOTI_USERS,
    GET_SUB_DEPARTMENT,
    GET_ALL_SUB_DEPARTMENT,

    SEARCH_NOTI_USERS,
  } = config.api_endpoint;
  let searchIncident;
  const [detailSelected, setDetailSelected] = useState("Incident Description");
  const [summarySelected, setSummarySelected] = useState(true);
  const [showCasualtyForm, setShowCasualtyForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showWitnessForm, setShowWitnessForm] = useState(false);
  const [showZoneDropDown, setShowZoneDropDown] = useState(false);
  const [submitToggle, setSubmitToggle] = useState(false);
  const [toApi, setToApi] = useState(false);
  const [previousIncident, setPreviousIncident] = useState(null);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [createLoader, setCreateLoader] = useState(false);
  const [cacheData, setCacheData] = useState([]);
  const [lastItemForAllSubDepart, setLastItemForAllSubDepart] = useState({
    from: 0,
    to: 10,
  });
  const [incidentUpdateLoader, setIncidentUpdateLoader] = useState(false);
  const [fetchSubDepartCount, setFetchSubDepartCount] = useState(1);
  const [currentAllSubDepartPage, setCurrentAllSubDepartPage] = useState(1);
  const [valueChanged, setValueChanged] = useState([]);
  const [showModalForIncidentUpdate, setShowModalForIncidentUpdate] =
    useState(false);
  const [dropdownId, setDropdownId] = useState({
    incidentPlacementId: "",
    incidentClassificationId: "",
    injuryTypesId: "",
    // incidentTypesId: "",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [allSubDepartmentList, setAllSubDepartmentList] = useState([]);
  const [totalpageForAllSubDepart, setTotalPageForAllSubDepart] = useState(0);
  // forms values
  const [overViewValues, setOverViewValues] = useState({
    incident_type: "All",
    incident_placement: "All",
    is_emergency_incident: "No",
    is_followup_required: "No",
    category: "",
    incident_classification: "",
    injury_type: "",
    emergency_services_activated: [],
    recall_people: "No",
  });
  const [previousNotiUserList, setPreviousNotiUserList] = useState([]);
  const [incidentUpRemark, setIncidentUpRemark] = useState("");
  const [incidentUpImg, setIncidentUpImg] = useState([]);
  const [showNotiPage, setShowNotiPage] = useState(false);
  const [searchBy, setSearchBy] = useState("department");
  const [incidentStatus, setIncidentStatus] = useState(null);
  const [checkStatusSideCondition, setCheckStatusSideCondition] =
    useState(null);
  const [checkStatus, setCheckStatus] = useState(null);
  const [currentSubDepartment, setCurrentSubDepartment] = useState("");
  const [locationValues, setLocationValues] = useState({
    park: "All",
    weather: "",
    area_condition: [],
    date: "",
    time: "",
  });
  const [rc, setRC] = useState(false);
  const [firstTimeClick, setFirstTimeClick] = useState(1);
  const [propertyValues, setPropertyValues] = useState({
    property_type: "",
    damaged_things: "",
    party_type: "external",
    party_info: "",
    priority_of_fix: "",
  });

  const [photoValues, setPhotoValues] = useState([]);
  const [removedPhotoValues, setRemovedPhotoValues] = useState([]);
  const [newAddedSignature, setNewAddedSignature] = useState([]);
  const [subDepartmentForPagination, setSubDepartmentForPagination] = useState(
    []
  );
  const [correctiveActionValues, setcorrectiveActionValues] = useState([
    {
      description: "",
      to_be_done_by: "",
      action_assigned_to: "",
      recommended_ca_status: "",
      recommended_ca_no: uuidv4(),
      status: "",
      id: "temp-" + uuidv4(),
      arr_created_at: new Date().toISOString(),
    },
  ]);

  const [nt, setNt] = useState(false);
  const [departState, setDepartState] = useState([]);
  const [usersState, setUsersState] = useState([]);
  // const [departFoldState, setDepartFoldState] = useState([
  //   { key: "ORD", state: true },
  //   { key: "ZOD", state: true },
  // ]);
  const [checkAllDepartmentNoti, setCheckAllDepartmentNoti] = useState([]);

  // Incident Overview Errors
  const [typeError, setTypeError] = useState(false);
  const [placementError, setPlacementError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [involvedError, setInvolvedError] = useState(false);
  const [classificationError, setClassificationError] = useState(false);
  const [injuryTypeError, setSetInjuryTypeError] = useState(false);

  // Incidet Timestamp Erros
  const [parkError, setParkError] = useState(false);
  const [zoneError, setZoneError] = useState(false);
  const [weatherError, setWeatherError] = useState(false);
  const [areaError, setAreaError] = useState(false);

  //Incident Summary Errors
  const [titleError, setTitleError] = useState(false);
  const [remarkError, setRemarkError] = useState(false);

  // Incident Property Erros
  const [propertyInputError, setPropertyInputError] = useState(false);
  const [damagesError, setDamagesError] = useState(false);
  const [damagesErrorTxt, setDamagesErrorTxt] = useState(false);
  const [partyTypeError, setPartyTypeError] = useState(false);
  const [partyTypeErrorTxt, setPartyTypeErrorTxt] = useState("");
  const [partyError, setPartyError] = useState(false);
  const [partyErrorTxt, setPartyErrorTxt] = useState(false);
  const [proxityError, setProxityError] = useState(false);

  // Incident AnalysisErros
  const [actionError, setActionError] = useState(false);
  const [conditionError, setConditionError] = useState(false);
  const [personnelError, setPersonnelError] = useState(false);
  const [jobError, setJobError] = useState(false);
  const [inputError, setInputError] = useState(false);

  // sidebar error if values are empty
  const [overViewError, setOverViewError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  // const [casualtyError, setCasualtyError] = useState(false);
  // const [witnessError, setWitnessError] = useState(false);
  const [summaryError, setSummaryError] = useState(false);
  const [propertyError, setPropertyError] = useState(false);
  const [photoError, setPhotoError] = useState(false);
  const [analysisError, setAnalysisError] = useState(false);
  const [fetchingSubDepart, setFetchingSubDepart] = useState(false);
  // incident options
  // options
  const [incidentTypeList, setIncidentTypeList] = useState([]);
  const [incidentPlacementList, setIncidentPlacementList] = useState([]);
  const [incidentClassificationList, setIncidentClassificationList] = useState(
    []
  );
  const [searchUsersDataPagiantion, setSearchUsersPagination] = useState({});
  const [totalUsersPage, setTotalUserspage] = useState(0);
  const [lastUsersItem, setLastUsersItem] = useState({
    from: 0,
    to: 50,
  });
  const [injuryTypesList, setInjuryTypesList] = useState([]);
  // from
  const [parkList, setParkList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [subStandardActionList, setSubStandardActionList] = useState([]);
  const [subStandardContionList, setSubStandardContionList] = useState([]);
  const [personnelFactorsList, setPersonnelFactorsList] = useState([]);
  const [jobFactorList, setJobFactorList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [unsafeActList, setUnsafeActList] = useState([]);
  const [unsafeConditionList, setUnsafeConditionList] = useState([]);
  const [actionAssignedList, setActionAssignedList] = useState([]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function (position) {
        localStorage.setItem("lat", position.coords.latitude);
        localStorage.setItem("lng", position.coords.longitude);
      });
    }
  }, []);

  useEffect(() => {
    props.fetchAllSubDepartment();
    // const findDepartment = props.subDeparts.filter(
    //   (u) => u.title === props.AuthDetail.department
    //   // console.log("u.....", u.title, "k...", props.AuthDetail.department)
    // );
    // console.log("findCheck.....", findDepartment);
  }, []);
  //Remake state for notification
  const [reSubDepartmentList, setReSubDepartmentList] = useState([]);
  const [reUsersList, setReUsersList] = useState({});
  const [reFetchUser, setReFetchUser] = useState(false);
  const [reSearchDepartment, setReSearchDepartment] = useState([]);
  const [reSearchUsersWithDepart, setReSearchUsersWithDepart] = useState([]);
  const [clickSearchBtnNoti, setClickSearchBtnNoti] = useState("");

  const hideShowZoneList = () => setShowZoneDropDown(false);
  const showShowZoneList = () => setShowZoneDropDown(true);

  // for view
  const [locationDate, setLocationDate] = useState({
    selectedDate: null,
    selectedTime: null,
  });

  // combine all casualty forms
  const [casualtyValues, setCasualtyValues] = useState([]);
  // combine all witnes  forms
  const [witnessValues, setWitnessValues] = useState([]);

  const [summaryValues, setSummaryValues] = useState({
    incident_title: "",
    incident_remarks: "",
  });

  //combine arr addational action form
  const [addationalValues, setAddationalValues] = useState({
    ambulance_called: "No",
    time_called: "",
    zone: "All",
    arrival_time: "",
    remark: "",
  });

  //combine medical status form
  const [medicalValues, setMedicalValues] = useState({
    hospital_name: "",
    medical_days: "",
    hospitalize_days: "",
    diagnosis: "",
  });
  console.log("ste...", props.state);

  //combine root cause analysis form
  const [rootCauseValues, setRootCauseValues] = useState({
    unsafe_act: "",
    unsafe_condition: "",
    follow_up: "",
  });

  const [analysis, setAnalysis] = useState({
    action: "",
    condition: "",
    personnel: "",
    job: "",
    stoppage: "Yes",
    stoppage_text: "",
  });

  const [userListForNoti, setUserListForNoti] = useState({});
  const [userListForNotiLoader, setUserlistForNotiLoader] = useState(false);
  const [departFoldState, setDepartFoldState] = useState([]);
  const [allSubDepartsForSearch, setAllSubDepartsForSearch] = useState([]);
  //combine noti setting form
  const [notiSettingValues, setNotiSettingValues] = useState({
    frequency_type: "one time",
  });
  const handlePaginationForAllSubDepart = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage;
    setLastItemForAllSubDepart({
      from: (page - 1) * 10,
      to: page * 10,
    });
    let from = (page - 1) * 10;
    let to = page * 10;
    setSubDepartmentForPagination(allSubDepartmentList.slice(from, to));

    setCurrentAllSubDepartPage(page);
  };
  const onChangeUsersState = (id, state, key) => {
    setNt(!nt);
    let cloneUsersState = usersState;
    const findIndex = cloneUsersState.findIndex((_, index) => _.id === id);

    cloneUsersState[findIndex].state = state;

    setUsersState(cloneUsersState);

    var allUsersChecked = true;
    for (var index = 0; index < cloneUsersState.length; index++) {
      if (
        cloneUsersState[index].key == key &&
        cloneUsersState[index].state == false
      ) {
        allUsersChecked = false;
      }
    }

    let cloneDepartState = departState;
    const departindex = cloneDepartState.findIndex((_, index) => _.key === key);

    cloneDepartState[departindex].state = allUsersChecked;
  };

  const getUsersState = (id, key, state) => {
    const findIndex = usersState.findIndex((_, index) => _.id === id);
    if (findIndex == -1) {
      setUsersState([
        ...usersState,
        { id: id, state: state ? state : getDepartState(key), key: key },
      ]);
      return getDepartState(key);
    }

    return usersState[findIndex].state;
  };

  const onChangeDepartState = (key, state) => {
    setNt(!nt);
    let cloneDepartState = departState;
    const findIndex = cloneDepartState.findIndex((_, index) => _.key === key);

    cloneDepartState[findIndex].state = state;

    setDepartState(cloneDepartState);

    let cloneUsersState = usersState;

    for (var index = 0; index < cloneUsersState.length; index++) {
      if (cloneUsersState[index].key == key) {
        cloneUsersState[index].state = state;
      }
    }

    setUsersState(cloneUsersState);
  };

  const getDepartState = (key, type) => {
    if (type == "must") {
      return false;
    }
    let cloneListUserListNoti = userListForNoti;
    let getDepartmentUsers = [];
    if (cloneListUserListNoti[key]) {
      getDepartmentUsers = cloneListUserListNoti[key];
    }
    // getDepartmentUsers = cloneListUserListNoti[key];
    let cloneUserState = usersState;
    let filterUserBySubDepart = [];
    let filterUserBySubDepartStateFalse = [];
    if (cloneUserState) {
      cloneUserState.map((u) => {
        if (u.key == key && u.state) {
          filterUserBySubDepart.push(u);
        } else {
          if (!u.state) {
            filterUserBySubDepartStateFalse.push(u);
          }
        }
      });
    }

    if (searchBy == "user") {
      if (
        getDepartmentUsers &&
        filterUserBySubDepartStateFalse &&
        getDepartmentUsers.length != 0 &&
        filterUserBySubDepartStateFalse.length > 0
      ) {
        return "minuSign";
      } else if (
        filterUserBySubDepartStateFalse.length == getDepartmentUsers.length
      ) {
        return false;
      } else {
        return true;
      }
    } else if (
      getDepartmentUsers &&
      filterUserBySubDepart &&
      getDepartmentUsers.length != 0 &&
      filterUserBySubDepart.length != 0 &&
      getDepartmentUsers.length == filterUserBySubDepart.length
    ) {
      // setDepartState([...departState, { key: key, state: true }]);
      return true;
    } else if (
      getDepartmentUsers &&
      filterUserBySubDepart &&
      getDepartmentUsers.length != 0 &&
      filterUserBySubDepart.length != 0 &&
      filterUserBySubDepart.length > 0
    ) {
      return "minuSign";
      // if (searchBy == "user") {
      //   if (
      //     filterUserBySubDepartStateFalse.length == getDepartmentUsers.length
      //   ) {
      //     return "minuSign";
      //   } else {
      //     return true;
      //   }
      // } else {
      //   return "minuSign";
      // }
    } else {
      const findIndex = departState.findIndex((_, index) => _.key == key);
      if (searchBy == "department") {
        if (
          getDepartmentUsers &&
          getDepartmentUsers &&
          getDepartmentUsers.length > 0 &&
          filterUserBySubDepartStateFalse.length > 0 &&
          getDepartmentUsers.length != filterUserBySubDepartStateFalse.length
        ) {
          return "minuSign";
        }
      }
      if (findIndex == -1) {
        const fIndex = departFoldState.findIndex((_, index) => _.key == key);
        setDepartState([
          ...departState,
          {
            key: key,
            title: key,
            state: fIndex != -1 ? departFoldState[fIndex].state : false,
          },
        ]);
        return fIndex != -1 ? departFoldState[fIndex].state : false;
      }
      return departState[findIndex].state;
    }
  };
  // const getDepartState = (key) => {
  //   const findIndex = departState.findIndex((_, index) => _.key === key);
  //   const findUserTotalLengthOfDepart = usersState.filter((u) => u.key == key);
  //   const findCheckLength = usersState.filter((u) => u.key == key && u.state);
  //   // gggggggg
  //   if (findIndex == -1) {
  //     setDepartState([
  //       ...departState,
  //       {
  //         key: key,
  //         state:
  //           findCheckLength.length > 0
  //             ? findUserTotalLengthOfDepart.length == findCheckLength.length
  //               ? true
  //               : false
  //             : false,
  //       },
  //     ]);
  //     if (
  //       findCheckLength.length > 0 &&
  //       findUserTotalLengthOfDepart.length == findCheckLength.length
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  //   let modiStat =
  //     findCheckLength.length > 0
  //       ? findUserTotalLengthOfDepart.length == findCheckLength.length
  //         ? true
  //         : false
  //       : false;
  //   return (departState[findIndex].state = modiStat);
  // };

  const getDepartStateForSearch = (key) => {
    const findIndex = departState.findIndex((_, index) => _.key === key);

    if (findIndex == -1) {
      setDepartState([...departState, { key: key, state: false }]);
      return false;
    }

    return departState[findIndex].state;
  };

  const onChangeDepartFoldState = (key, state) => {
    setNt(!nt);
    let cloneDepartFoldState = departFoldState;
    const findIndex = cloneDepartFoldState.findIndex(
      (_, index) => _.key === key
    );
    cloneDepartFoldState[findIndex].state = state;
    setDepartFoldState(cloneDepartFoldState);
  };

  const getDepartFoldState = (key, bool) => {
    const findIndex = departFoldState.findIndex((_, index) => _.key === key);
    let clone = departFoldState;
    if (findIndex == -1) {
      setDepartFoldState([...departFoldState, { key: key, state: false }]);

      return false;
    }
    if (departFoldState[findIndex].firstTime == 2) {
      clone[findIndex].state = true;
      setDepartFoldState(clone);
      return true;
    }
    if (departFoldState[findIndex].firstTime == 1) {
      clone[findIndex].state = clone[findIndex].state
        ? clone[findIndex].state
        : false;

      setDepartFoldState(clone);
      return false;
    }
    return departFoldState[findIndex].state;
  };

  const onChangeDepartmentForNoti = (title, checked) => {
    const searchCheckedOrNot = checkAllDepartmentNoti.filter(
      (c) => c.title === title
    );

    if (searchCheckedOrNot.length == 0)
      setCheckAllDepartmentNoti([
        ...checkAllDepartmentNoti,
        { title, isChecked: checked },
      ]);
    if (searchCheckedOrNot.length > 0)
      setCheckAllDepartmentNoti(
        checkAllDepartmentNoti.filter((c) => c.title != title)
      );
  };

  const [ambulance_called_Error, setAmbulance_called_Error] = useState(false);
  const [time_called_Error, setTime_called_Error] = useState(false);
  const [arrival_time_Error, setArrival_time_Error] = useState(false);
  const [remark_Error, setRemark_Error] = useState(false);
  const [zone_Error, setZone_Error] = useState(false);

  const [hospital_name_Error, setHospital_name_Error] = useState(false);
  const [medical_days_Error, setMedical_days_Error] = useState(false);
  const [hospitalize_days_Error, setHospitalize_days_Error] = useState(false);
  const [diagnosis_Error, setDiagnosis_Error] = useState(false);

  const [unsafe_act_Error, setUnsafe_act_Error] = useState(false);
  const [unsafe_condition_Error, setUnsafe_condition_Error] = useState(false);

  const [mainZone, setMainZone] = useState("");
  const [subZone, setSubZone] = useState("");
  const [zoneType, setZoneType] = useState("");

  // for view
  const [selectedAddress, setSelectedAddress] = useState(null);

  const customModalForConfirmCancel = (onClose) => {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Confirm to leave
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            This will undo all the changes you have made to the form. Do you
            want to leave the page?
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button className="witness_cancel_button" onClick={onClose}>
                No
              </Button>
            </div>
            <div
              onClick={() => {
                if (removedPhotoValues.length > 0) {
                  removedPhotoValues.map((p) => {
                    storage
                      .refFromURL(p)
                      .delete()
                      .catch((err) => console.log("err", err));
                  });
                }
                if (newAddedSignature.length > 0) {
                  newAddedSignature.map((p) => {
                    storage
                      .refFromURL(p)
                      .delete()
                      .catch((err) => console.log("err", err));
                  });
                }
                // if (casualtyValues.length > 0) {
                //   casualtyValues.map((c) => {
                //     if (c.signature) {
                //       storage
                //         .refFromURL(c.signature)
                //         .delete()
                //         .catch((err) => console.log("err", err));
                //     }
                //   });
                // }
                // if (witnessValues.length > 0) {
                //   witnessValues.map((w) => {
                //     if (w.signature) {
                //       storage
                //         .refFromURL(w.signature)
                //         .delete()
                //         .catch((err) => console.log("err", err));
                //     }
                //   });
                // }

                history.push("/incidents_list");
              }}
            >
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={onClose}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const incidentLists = props.incidentList;
    const searchincidentList = props.searchincidentListState;
    searchIncident = incidentLists.filter((i) => i.id == paramId);

    if (searchBool || paramId || searchIncident.length != 1) {
      setFetchLoader(true);
      api
        .get(GET_ONE_INCIDENT, {
          id: paramId,
          platform: "web",
        })
        .then((res) => {
          setFetchLoader(false);
          if (res.data) {
            setNotiSettingValues({
              frequency_type: res.data.noti_frequency
                ? res.data.noti_frequency
                : "repeat",
            });
            console.log("res", res);
            setPreviousNotiUserList(res.data.notification_list);
            setPreviousIncident(res.data);
            localStorage.setItem(
              "oldData",
              JSON.stringify(res.data.aar.recommended_ca)
            );
            const { case_details, aar } = res.data;
            setIncidentStatus(res.data.incident_status);
            setCheckStatusSideCondition(res.data.incident_status);
            const {
              incident_type,
              incident_placement,

              is_followup_required,
              category,
              emergency_services_activated,
              recall_people,
              selected_incident_ids,
              is_emergency_incident,
              incident_classification,
              injury_type,
            } = case_details.incident_description;

            const {
              park,
              weather,
              area_condition,
              date,
              time,
              zone,
              sub_zones,
            } = case_details.incident_description;

            const {
              damaged_things,
              party_info,
              party_type,
              priority_of_fix,
              property_type,
            } = case_details.property_damage_details;

            const { incident_remarks, incident_title } =
              case_details.incident_description;

            const {
              job_factors,
              personnel_factors,
              substandard_action,
              stoppage_text,
              substandard_condition,
              work_stoppage,
            } = case_details.mpd_incident_analysis;

            const { person_involved_details, witness_particulars } =
              case_details;

            const { amb_pol_called, remarks, time_arrival, time_called } =
              aar.additional_actions;
            const {
              diagnosis,
              hospital_name,
              hospitalisation_leave,
              medical_leave,
            } = aar.medical_status;

            const { unsafe_act, unsafe_cond, follow_up } = aar.root_cause;
            console.log("incident_title -------", incident_title);
            let modiftedEmergencyServices = [];
            emergency_services_activated &&
              emergency_services_activated.map((e) => {
                modiftedEmergencyServices.push({
                  name: e,
                  checked: true,
                });
              });

            setOverViewValues({
              incident_type,
              incident_placement,
              is_followup_required,
              category,
              incident_classification,
              // classification,
              injury_type,
              is_emergency_incident: is_emergency_incident,
              emergency_services_activated: modiftedEmergencyServices,
              recall_people: recall_people ? recall_people : "No",
            });

            setLocationValues({
              park,
              weather,
              area_condition:
                typeof area_condition == "object"
                  ? [...area_condition]
                  : [area_condition],
              date,
              time,
            });

            if (
              selected_incident_ids &&
              Object.keys(selected_incident_ids).length > 0
            ) {
              setDropdownId({
                incidentPlacementId:
                  selected_incident_ids.incident_placement_id,
                incidentClassificationId:
                  selected_incident_ids.incident_classification_id,
                injuryTypesId: selected_incident_ids.injury_type_id,
              });
            }

            const zoneSplit = zone.split(",");

            if (zoneSplit.length == 2) {
              setMainZone({
                lat: zoneSplit[0],
                lng: zoneSplit[1],
              });
              Geocode.fromLatLng(zoneSplit[0], zoneSplit[1]).then(
                (response) => {
                  const address = response.results[0].formatted_address;
                  onChangeMainZone({
                    lat: zoneSplit[0],
                    lng: zoneSplit[1],
                  });
                  onChangeSelectedAddress(address);
                  onChangeZoneType("Google Map");
                },
                (error) => {
                  console.error(error);
                }
              );
            } else {
              setMainZone(zoneSplit[0]);
            }

            setSubZone(sub_zones);
            setZoneType(res.data.filtered_zone_type);
            setCasualtyValues(person_involved_details);

            setWitnessValues(witness_particulars);

            setSummaryValues({
              incident_title,
              incident_remarks,
            });
            setPropertyValues({
              property_type,
              damaged_things,
              party_type,
              party_info,
              priority_of_fix: priority_of_fix,
            });
            setAnalysis({
              action: substandard_action,
              condition: substandard_condition,
              personnel: personnel_factors,
              job: job_factors,
              stoppage: work_stoppage ? work_stoppage : "Yes",
              stoppage_text,
            });

            setAddationalValues({
              ambulance_called: amb_pol_called ? amb_pol_called : "No",
              time_called: new Date(time_called),
              arrival_time: new Date(time_arrival),
              zone: aar.additional_actions.zone,
              remark: remarks,
            });
            setMedicalValues({
              hospital_name: hospital_name,
              medical_days: medical_leave,
              hospitalize_days: hospitalisation_leave,
              diagnosis: diagnosis,
            });

            setRootCauseValues({
              unsafe_act,
              unsafe_condition: unsafe_cond,
              follow_up,
            });
            if (aar.recommended_ca.length > 0) {
              setcorrectiveActionValues(aar.recommended_ca);
            }
            let changedDateFormat, combinedDateTime;
            if (date) {
              changedDateFormat = moment(date).format("YYYY-MM-DD");
            }
            if (time) {
              combinedDateTime = `${changedDateFormat}T${time}`;
            }

            setPhotoValues(res.data.case_details.photo_video);
            if (
              date !== null &&
              time !== null &&
              changedDateFormat &&
              combinedDateTime
            ) {
              setLocationDate({
                ...locationDate,
                selectedDate: new Date(date),
                selectedTime: new Date(combinedDateTime),
              });
            }
            console.log("ddid....", paramId);
            console.log(
              "ttid......",
              selected_incident_ids.incident_classification_id
            );
            api
              .get(GET_SUB_DEPARTMENT, {
                platform: "web",
                classification: incident_classification,
                classification_id:
                  selected_incident_ids.incident_classification_id,
                is_emergency: is_emergency_incident == "Yes" ? true : false,
                id: paramId,
              })
              .then((res) => {
                setFetchSubDepartCount(2);
                let data = [];
                res.data.map((d) => {
                  data.push({
                    title: d.name,
                    state:
                      d.status == 1
                        ? true
                        : d.status == -1
                        ? "minusSign"
                        : false,
                    displayUserList: false,
                  });
                });
                setReSubDepartmentList(data);
              })
              .catch((err) => {
                console.log("err", err);
              });
            // if (date != null && time != null) {
            //   setLocationDate({
            //     selectedDate: new Date(date),
            //     selectedTime: new Date(time),
            //   });
            // } else if (date != null) {
            //   setLocationDate({
            //     ...locationDate,
            //     selectedDate: new Date(date),
            //   });
            // } else if (time != null) {
            //   setLocationDate({
            //     ...locationDate,
            //     selectedTime: new Date(time),
            //   });
            // }
          }
        })
        .catch((err) => {
          setFetchLoader(false);
          console.log("err");
        });

      // ------------------------------------
    } else {
      if (incidentLists.length > 0 && paramId) {
        if (searchIncident.length == 1) {
          setPreviousIncident(searchIncident[0]);
          const { case_details, aar } = searchIncident[0];
          setIncidentStatus(searchIncident[0].incident_status);
          setCheckStatusSideCondition(searchIncident[0].incident_status);
          const {
            incident_type,
            incident_placement,

            is_followup_required,
            category,
            emergency_services_activated,
            recall_people,
            selected_incident_ids,
            is_emergency_incident,
            incident_classification,
            injury_type,
          } = case_details.incident_description;

          const { park, weather, area_condition, date, time, zone, sub_zones } =
            case_details.incident_description;

          const {
            damaged_things,
            party_info,
            party_type,
            priority_of_fix,
            property_type,
          } = case_details.property_damage_details;

          const { incident_remarks, incident_title } =
            case_details.incident_description;

          const {
            job_factors,
            personnel_factors,
            substandard_action,
            substandard_condition,
            work_stoppage,
            stoppage_text,
          } = case_details.mpd_incident_analysis;

          const { person_involved_details, witness_particulars } = case_details;

          const { amb_pol_called, remarks, time_arrival, time_called } =
            aar.additional_actions;
          const {
            diagnosis,
            hospital_name,
            hospitalisation_leave,
            medical_leave,
          } = aar.medical_status;

          const { unsafe_act, unsafe_cond } = aar.root_cause;

          setOverViewValues({
            incident_type,
            incident_placement,
            is_followup_required,
            category,
            incident_classification,
            // classification,
            injury_type,
            is_emergency_incident: is_emergency_incident,
            emergency_services_activated: emergency_services_activated,
            recall_people: recall_people,
          });

          setLocationValues({
            park,
            weather,
            area_condition:
              typeof area_condition == "object"
                ? [...area_condition]
                : [area_condition],
            date,
            time,
          });

          if (Object.keys(selected_incident_ids).length > 0) {
            setDropdownId({
              incidentPlacementId: selected_incident_ids.incident_placement_id,
              incidentClassificationId:
                selected_incident_ids.incident_classification_id,
              injuryTypesId: selected_incident_ids.injury_type_id,
            });
          }
          const zoneSplit = zone.split(",");

          if (zoneSplit.length == 2) {
            setMainZone({
              lat: zoneSplit[0],
              lng: zoneSplit[1],
            });
            Geocode.fromLatLng(zoneSplit[0], zoneSplit[1]).then(
              (response) => {
                const address = response.results[0].formatted_address;
                onChangeMainZone({
                  lat: zoneSplit[0],
                  lng: zoneSplit[1],
                });
                onChangeSelectedAddress(address);
                onChangeZoneType("Google Map");
              },
              (error) => {
                console.error(error);
              }
            );
          } else {
            setMainZone(zoneSplit[0]);
          }

          setSubZone(sub_zones);
          setZoneType(searchIncident[0].filtered_zone_type);
          setCasualtyValues(person_involved_details);

          setWitnessValues(witness_particulars);

          setSummaryValues({
            incident_title,
            incident_remarks,
          });
          setPropertyValues({
            property_type,
            damaged_things,
            party_type,
            party_info,
            priority_of_fix: priority_of_fix,
          });
          setAnalysis({
            action: substandard_action,
            condition: substandard_condition,
            personnel: personnel_factors,
            job: job_factors,
            stoppage: work_stoppage ? work_stoppage : "Yes",
            stoppage_text,
          });

          setAddationalValues({
            ambulance_called: amb_pol_called ? amb_pol_called : "No",
            time_called: new Date(time_called),
            arrival_time: new Date(time_arrival),
            zone: aar.additional_actions.zone,
            remark: remarks,
          });
          setMedicalValues({
            hospital_name: hospital_name,
            medical_days: medical_leave,
            hospitalize_days: hospitalisation_leave,
            diagnosis: diagnosis,
          });

          setRootCauseValues({
            unsafe_act,
            unsafe_condition: unsafe_cond,
          });
          if (aar.recommended_ca.length > 0) {
            setcorrectiveActionValues(aar.recommended_ca);
          }
          let changedDateFormat, combinedDateTime;
          if (date) {
            changedDateFormat = moment(date).format("YYYY-MM-DD");
          }
          if (time) {
            combinedDateTime = `${changedDateFormat}T${time}`;
          }

          setPhotoValues(searchIncident[0].case_details.photo_video);
          if (
            date !== null &&
            time !== null &&
            changedDateFormat &&
            combinedDateTime
          ) {
            setLocationDate({
              ...locationDate,
              selectedDate: new Date(date),
              selectedTime: new Date(combinedDateTime),
            });
          }

          api
            .get(GET_SUB_DEPARTMENT, {
              platform: "web",
              classification: incident_classification,
              classification_id:
                selected_incident_ids.incident_classification_id,
              is_emergency: is_emergency_incident == "Yes" ? true : false,
              id: paramId,
            })
            .then((res) => {
              setFetchSubDepartCount(2);
              let data = [];
              res.data.map((d) => {
                data.push({
                  title: d.name,
                  state:
                    d.status == 1 ? true : d.status == -1 ? "minusSign" : false,
                  displayUserList: false,
                });
              });
              setReSubDepartmentList(data);
            })
            .catch((err) => {
              console.log("err", err);
            });
          // if (date != null && time != null) {
          //   setLocationDate({
          //     selectedDate: new Date(date),
          //     selectedTime: new Date(time),
          //   });
          // } else if (date != null) {
          //   setLocationDate({
          //     ...locationDate,
          //     selectedDate: new Date(date),
          //   });
          // } else if (time != null) {
          //   setLocationDate({
          //     ...locationDate,
          //     selectedTime: new Date(time),
          //   });
          // }
        }
        // //////////////////////////
      }
    }
  }, []);
  // Remake Function for notifcation

  useEffect(() => {
    if (fetchSubDepartCount == 2) {
      api
        .get(GET_SUB_DEPARTMENT, {
          platform: "web",
          classification: overViewValues.incident_classification,
          classification_id: dropdownId.incidentClassificationId,
          is_emergency:
            overViewValues.is_emergency_incident == "Yes" ? true : false,
          id: paramId,
        })
        .then((res) => {
          let data = [];
          res.data.map((d) => {
            data.push({
              title: d.name,
              state:
                d.status == 1 ? true : d.status == -1 ? "minusSign" : false,
              displayUserList: false,
            });
          });
          setReSubDepartmentList(data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [
    overViewValues.incident_classification,
    dropdownId.incidentClassificationId,
    overViewValues.is_emergency_incident,
  ]);

  const reHandleImageClickDepartment = (sub_department, isSearch) => {
    setReFetchUser(true);
    let cloneReSubdepartmentList = reSubDepartmentList;
    let cloneReSearchSubDepartment = reSearchDepartment;

    if (isSearch) {
      let findIndex = cloneReSearchSubDepartment.findIndex(
        (f) => f.title == sub_department
      );
      if (findIndex != -1) {
        if (!reUsersList[sub_department]) {
          reHandleClickDepartment(sub_department);
          setRC(!rc);
        }
        cloneReSearchSubDepartment[findIndex].displayUserList =
          !cloneReSearchSubDepartment[findIndex].displayUserList;

        setReSearchDepartment(cloneReSearchSubDepartment);
        setRC(!rc);
      }
    } else {
      let findIndex = cloneReSubdepartmentList.findIndex(
        (f) => f.title == sub_department
      );
      if (findIndex != -1) {
        if (!reUsersList[sub_department]) {
          reHandleClickDepartment(sub_department);
          setRC(!rc);
        }
        cloneReSubdepartmentList[findIndex].displayUserList =
          !cloneReSubdepartmentList[findIndex].displayUserList;

        setReSubDepartmentList(cloneReSubdepartmentList);
        setRC(!rc);
      }
    }

    setRC(!rc);
  };

  const handleSubDepartCheckBox = (sub_department, checked, isSearch) => {
    setReFetchUser(false);
    let cloneReSubdepartmentList = reSubDepartmentList;
    let cloneReSearchSubDepartment = reSearchDepartment;

    if (isSearch) {
      let findIndex = cloneReSearchSubDepartment.findIndex(
        (f) => f.title == sub_department
      );
      const searchDepartIndex = cloneReSubdepartmentList.findIndex(
        (c) => c.title == sub_department
      );

      if (searchDepartIndex == -1) {
        let searchDataFromSearch = reSearchDepartment.filter(
          (s) => s.title == sub_department
        );
        if (searchDataFromSearch) {
          cloneReSubdepartmentList.push({
            title: sub_department,
            state: checked,
            displayUserList: false,
          });
          setReSubDepartmentList(cloneReSubdepartmentList);
        }
      }
      if (findIndex != -1) {
        if (!reUsersList[sub_department]) {
          reHandleClickDepartment(sub_department, "Go", checked);
        } else {
          if (checked == true || checked == false) {
            let cloneUsersList = reUsersList;
            let searchUsersByDepart = cloneUsersList[sub_department];
            let data = [];
            searchUsersByDepart.map((user) => {
              data.push({
                id: user.id,
                state: checked,
                name: user.name,
                platform: user.platform,
                key: sub_department,
              });
            });
            cloneUsersList[sub_department] = data;
            setReUsersList(cloneUsersList);
          }
        }

        cloneReSearchSubDepartment[findIndex].state = checked;
        setReSearchDepartment(cloneReSearchSubDepartment);
        let cloneUserList = reUsersList;
        let searchDepartByusers = cloneUserList[sub_department];

        setRC(!rc);
      }
      let findIndex2 = cloneReSubdepartmentList.findIndex(
        (f) => f.title == sub_department
      );

      if (findIndex2 != -1) {
        if (!reUsersList[sub_department]) {
          reHandleClickDepartment(
            sub_department,

            "Go",
            checked
          );
        } else {
          if (checked == true || checked == false) {
            let cloneUsersList = reUsersList;
            let searchUsersByDepart = cloneUsersList[sub_department];
            let data = [];
            searchUsersByDepart.map((user) => {
              data.push({
                id: user.id,
                state: checked,
                name: user.name,
                platform: user.platform,
                key: sub_department,
              });
            });
            cloneUsersList[sub_department] = data;
            setReUsersList(cloneUsersList);
          }
        }

        cloneReSubdepartmentList[findIndex2].state = checked;
        setReSubDepartmentList(cloneReSubdepartmentList);
        setRC(!rc);
      }
    } else {
      let findIndex = cloneReSubdepartmentList.findIndex(
        (f) => f.title == sub_department
      );

      if (findIndex != -1) {
        if (!reUsersList[sub_department]) {
          reHandleClickDepartment(
            sub_department,

            "Go",
            checked
          );
        } else {
          if (checked == true || checked == false) {
            let cloneUsersList = reUsersList;
            let searchUsersByDepart = cloneUsersList[sub_department];
            let data = [];
            searchUsersByDepart.map((user) => {
              data.push({
                id: user.id,
                state: checked,
                name: user.name,
                platform: user.platform,
                key: sub_department,
              });
            });
            cloneUsersList[sub_department] = data;
            setReUsersList(cloneUsersList);
          }
        }

        cloneReSubdepartmentList[findIndex].state = checked;

        setReSubDepartmentList(cloneReSubdepartmentList);
        setRC(!rc);
      }
    }
  };

  const reHandleClickDepartment = (sub_department, hint, checked) => {
    api
      .get(GET_ALL_NOTI_USERS, {
        platform: "web",
        sub_department,
        id: paramId,
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        is_emergency:
          overViewValues.is_emergency_incident == "Yes" ? true : false,
      })
      .then((res) => {
        let data = [];

        res.data.map((user) => {
          data.push({
            id: user.id,
            state: hint == "Go" ? checked : user.is_notified_user,
            name: user.name,
            platform: user.platform,
            key: sub_department,
          });
        });

        let groupUser = data.reduce((groupDepartment, user) => {
          const subDepartment = user.key;
          if (
            groupDepartment[subDepartment] == null ||
            groupDepartment[subDepartment] == undefined
          )
            groupDepartment[subDepartment] = [];
          groupDepartment[subDepartment].push(user);
          return groupDepartment;
        }, {});
        let cloneReUserList = reUsersList;
        if (cloneReUserList[sub_department] == null)
          cloneReUserList[sub_department] = [];
        cloneReUserList[sub_department] = groupUser[sub_department];

        setReUsersList(cloneReUserList);
        setReFetchUser(false);
        setRC(!rc);
      })
      .catch((err) => {
        setReFetchUser(false);
        console.log("err", err);
      });
  };

  const reUserStateOnChange = (id, sub_department, checked, isSearch) => {
    let cloneUserList = reUsersList;
    let cloneReSubdepartmentList = reSubDepartmentList;

    const searchDepartIndex = cloneReSubdepartmentList.findIndex(
      (c) => c.title == sub_department
    );

    if (cloneUserList[sub_department]) {
      let findIndex = cloneUserList[sub_department].findIndex(
        (u) => u.id == id
      );
      if (findIndex != -1) {
        cloneUserList[sub_department][findIndex].state = checked;

        let falseUsers = [];
        let trueUsers = [];
        let totalUser = cloneUserList[sub_department];
        totalUser.map((u) => {
          if (u.state) {
            trueUsers.push(u);
          } else {
            falseUsers.push(u);
          }
        });

        if (totalUser.length == trueUsers.length) {
          if (searchDepartIndex == -1) {
            let searchDataFromSearch = reSearchDepartment.filter(
              (s) => s.title == sub_department
            );
            if (searchDataFromSearch) {
              cloneReSubdepartmentList.push({
                title: sub_department,
                state: true,
                displayUserList: false,
              });
              setReSubDepartmentList(cloneReSubdepartmentList);
            }
          }
          handleSubDepartCheckBox(sub_department, true, isSearch);
        } else if (
          falseUsers.length > 0 &&
          totalUser.length != falseUsers.length
        ) {
          if (searchDepartIndex == -1) {
            let searchDataFromSearch = reSearchDepartment.filter(
              (s) => s.title == sub_department
            );
            if (searchDataFromSearch) {
              cloneReSubdepartmentList.push({
                title: sub_department,
                state: "minusSign",
                displayUserList: false,
              });
              setReSubDepartmentList(cloneReSubdepartmentList);
            }
          }

          handleSubDepartCheckBox(sub_department, "minusSign", isSearch);
        } else {
          if (searchDepartIndex == -1) {
            let searchDataFromSearch = reSearchDepartment.filter(
              (s) => s.title == sub_department
            );
            if (searchDataFromSearch) {
              cloneReSubdepartmentList.push({
                title: sub_department,
                state: false,
                displayUserList: false,
              });
              setReSubDepartmentList(cloneReSubdepartmentList);
            }
          }

          handleSubDepartCheckBox(sub_department, false, isSearch);
        }

        setReUsersList(cloneUserList);

        setRC(!rc);
      }
    }
  };

  const reSearchByDepartment = (search) => {
    setClickSearchBtnNoti(search);
    api
      .get(SEARCH_NOTI_USERS, {
        platform: "web",
        keyword: search,
        search_by: "department",
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        is_emergency:
          overViewValues.is_emergency_incident == "Yes" ? true : false,
        id: paramId,
      })
      .then((res) => {
        let data = [];
        res.data.map((d) => {
          let cloneReAllSubdepartment = reSubDepartmentList;
          let searchIndex = cloneReAllSubdepartment.findIndex(
            (f) => f.title == d.name
          );
          // reUserStateOnChange
          data.push({
            title: d.name,
            state:
              searchIndex != -1
                ? cloneReAllSubdepartment[searchIndex].state
                : d.state == 1
                ? true
                : d.state == -1
                ? "minusSign"
                : false,
            displayUserList: false,
          });
        });

        setReSearchDepartment(data);

        setRC(!rc);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const reSearchByUser = (search) => {
    setClickSearchBtnNoti(search);
    api
      .get(SEARCH_NOTI_USERS, {
        platform: "web",
        keyword: search,
        search_by: "user",
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        id: paramId,
        is_emergency:
          overViewValues.is_emergency_incident == "Yes" ? true : false,
      })
      .then((res) => {
        const { data, others } = res;

        let groupUsers = [...data, ...others].reduce(
          (groupDepartment, user) => {
            const subDepartment = user.sub_department;
            if (
              groupDepartment[subDepartment] == null ||
              groupDepartment[subDepartment] === undefined
            )
              groupDepartment[subDepartment] = [];
            groupDepartment[subDepartment].push(user);
            return groupDepartment;
          },
          {}
        );
        let datag = [];
        let cloneSubDepartment = reSubDepartmentList;
        let cloneUserList = reUsersList;

        Object.entries(groupUsers).map(([key, value]) => {
          let searchIndex = cloneSubDepartment.findIndex(
            (s) => s.title === key
          );

          datag.push({
            title: key,
            state:
              searchIndex === -1 ? true : cloneSubDepartment[searchIndex].state,
            displayUserList: true,
          });

          if (!cloneUserList[key]) {
            let userData = [];
            value.map((user) => {
              let findIndex;
              if (cloneUserList[key]) {
                findIndex = cloneUserList[key].findIndex(
                  (f) => f.name == user.name
                );
              } else {
                findIndex = -1;
              }

              userData.push({
                id: user.id,
                state:
                  findIndex != -1
                    ? cloneUserList[key][findIndex].state
                    : user.is_notified_user,
                name: user.name,
                platform: user.platform,
                key: user.sub_department,
              });
            });
            cloneUserList[key] = userData;
          }
          setRC(!rc);
        });
        // setReSubDepartmentList

        setReSearchDepartment(datag);
        setReUsersList(cloneUserList);
        setRC(!rc);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {}, [rc, reUsersList]);
  //remake end
  let cases = [
    {
      title: "Incident Description",
    },

    {
      title: "Person Involved Details",
    },
    {
      title: "Witnesses Details",
    },
    {
      title: "Details of Property Damage",
    },
    // {
    //   title: "MPD Incident Analysis",
    // },
    {
      title: "Incident Updates",
    },
  ];
  let aar = [
    // {
    //   title: "Additional actions",
    // },
    // {
    //   title: "Medical status",
    // },
    {
      title: "Post-Incident Follow-Up",
    },
    {
      title: "Root Cause Analysis",
    },
    {
      title: "Recommended Corrective Actions",
    },
  ];
  let notis = [
    {
      title: "Notification List",
    },
    {
      title: "Notification Settings",
    },
  ];
  let steppers = [
    {
      title: "Edit the Casualty detail",
      timestamp: "30 Apr 2021 16:5",
    },
    {
      title: "Edit the Casualty detail",
      timestamp: "30 Apr 2021 16:5",
    },
    {
      title: "Edit the Casualty detail",
      timestamp: "30 Apr 2021 16:5",
    },
  ];

  const saveMainDraftButton = () => {};

  // for selecting Incident Location only Zone Dropdown
  const onChangeIncidentLocationZone = (value) => {
    setMainZone(value);
    setSelectedAddress(null);
    setZoneType("");
  };

  const changeShowCasualyFormBool = () => {
    setShowCasualtyForm(true);
  };

  const changeHideCasualyFormBool = () => {
    setShowCasualtyForm(false);
  };

  const changeShowWitnessFormBool = () => {
    setShowWitnessForm(true);
  };

  const changeHideWitnessFormBool = () => {
    setShowWitnessForm(false);
  };

  const onChangeIncidentOverView = (key, value) => {
    if (key === "emergency_services_activated") {
      let cloneServiceAcivate = overViewValues.emergency_services_activated;
      let searchIndex = cloneServiceAcivate.findIndex((e) => e.name === value);
      if (searchIndex === -1) {
        cloneServiceAcivate.push({
          name: value,
          checked: true,
        });
      } else {
        cloneServiceAcivate[searchIndex].checked =
          !cloneServiceAcivate[searchIndex].checked;
      }
      return setOverViewValues({
        ...overViewValues,
        emergency_services_activated: cloneServiceAcivate,
      });
    } else {
      if (key == "incident_type") {
        setOverViewValues({
          ...overViewValues,
          [key]: value,
          incident_placement: null,
          incident_classification: null,
        });
      } else if (key == "incident_placement") {
        setOverViewValues({
          ...overViewValues,
          [key]: value,
          incident_classification: null,
        });
      } else {
        setOverViewValues({
          ...overViewValues,
          [key]: value,
        });
      }
    }
  };

  const onChangeLocationDateTime = (key, value) => {
    setLocationDate({
      ...locationDate,
      [key]: value,
    });
  };

  const onChangeIncidentInjuryType = (data) => {
    setOverViewValues({
      ...overViewValues,
      injury_type: data,
    });
  };
  const onChangeIncidentLocationCondtionsArea = (data) => {
    // let selected = locationValues.area_condition;
    // let find = selected.indexOf(data);

    // if (find > -1) {
    //   selected.splice(find, 1);
    // } else {
    //   selected.push(data);
    // }

    setLocationValues({
      ...locationValues,
      area_condition: data,
    });
  };

  // for selecting IncitypeErrordent Location all input forms
  const onChangeIncidentLocation = (key, value) => {
    setLocationValues({
      ...locationValues,
      [key]: value,
    });
  };

  // if  google type lat lng, if not same as zone type
  const onChangeMainZone = (value) => {
    setMainZone(value);
  };

  // title
  const onChangeZoneType = (value) => {
    setZoneType(value);
  };

  // for selecting Incident Location only Address Dropdown
  const onChangeIncidentAddressZone = (value) => {
    setSelectedAddress(value);
  };

  const onChangeIncidentCasulaty = (arrayValue) => {
    setCasualtyValues(arrayValue);
  };

  const onChangeIncidetWitness = (arrayValue) => {
    setWitnessValues(arrayValue);
  };

  const onChangeSubZone = (value) => {
    setSubZone(value);
  };

  // only for view, not for api
  const onChangeSelectedAddress = (value) => {
    setSelectedAddress(value);
  };

  const onChangeIncidentSummary = (key, value) => {
    setSummaryValues({
      ...summaryValues,
      [key]: value,
    });
  };

  const onChangeIncidentProperty = (key, value) => {
    setPropertyValues({
      ...propertyValues,
      [key]: value,
    });
  };
  const onChangeIncidentAnalysis = (key, value) => {
    setAnalysis({
      ...analysis,
      [key]: value,
    });
  };

  const onChangeAddationAction = (key, value) => {
    setAddationalValues({
      ...addationalValues,
      [key]: value,
    });
  };

  const onChangeMedicalStatusAction = (key, value) => {
    setMedicalValues({
      ...medicalValues,
      [key]: value,
    });
  };

  const onChangeRootCauseAction = (key, value) => {
    setRootCauseValues({
      ...rootCauseValues,
      [key]: value,
    });
  };

  const onChangeNotiSetting = (key, value) => {
    setNotiSettingValues({
      ...notiSettingValues,
      [key]: value,
    });
  };

  useEffect(() => {}, [photoValues]);
  useEffect(() => {}, [incidentUpImg]);

  const onChangePhoto = (images) => {
    setPhotoValues([...photoValues, images]);
  };
  const filterPhotoFun = async (img) => {
    const searchPhoto = photoValues.filter((p) => p == img);
    const cloneRemovedPhotoValues = removedPhotoValues;
    // if (searchPhoto) {
    //   cloneRemovedPhotoValues.push(searchPhoto);
    //   setRemovedPhotoValues(cloneRemovedPhotoValues);
    // }
    // if (searchPhoto) {
    //   storage
    //     .refFromURL(searchPhoto[0])
    //     .delete()
    //     .catch((err) => console.log("err", err));
    // }

    const filteredPhoto = photoValues.filter((f) => f !== img);
    setPhotoValues(filteredPhoto);
  };
  const AddCorrectiveAction = (data) => {
    onListenValueChange(
      "added" + data.recommended_ca_no,
      `Add new Action form`
    );
    setcorrectiveActionValues([...correctiveActionValues, data]);
  };

  {
    /*const RemoveCorrectiveAction = (id) => {
    let filteredArray = correctiveActionValues.filter(
      (_, index) => index !== id
    );

    setcorrectiveActionValues(filteredArray);
  };*/
  }

  const RemoveCorrectiveAction = (id) => {
    setRC(!rc);
    let cloneSection = correctiveActionValues;

    // const filterAction = cloneSection.filter((_) => _.id != id);
    // setcorrectiveActionValues(filterAction);
    // onListenValueChange("deleted" + id, `Deleted Action form`);
    const findIndex = cloneSection.findIndex((_, index) => _.id === id);

    cloneSection[findIndex].status = "delete";
    setcorrectiveActionValues(cloneSection);
    onListenValueChange("deleted" + id, `Deleted Action form`);
  };

  const onChangeCorrectiveAction = (data, type, id, section_no) => {
    setRC(!rc);
    let cloneSection = correctiveActionValues;
    let oldeDataRecommended = JSON.parse(localStorage.getItem("oldData"));
    const findIndex = cloneSection.findIndex((_, index) => _.id === id);
    const oldDatafindIndex = oldeDataRecommended.findIndex(
      (_, index) => _.id === id
    );

    if (type == "description") {
      cloneSection[findIndex].description = data;
      if (
        oldeDataRecommended[oldDatafindIndex] &&
        oldeDataRecommended[oldDatafindIndex].description != data
      ) {
        onListenValueChange(
          section_no + "description",
          `Update Action ${section_no} "description" from ${oldeDataRecommended[oldDatafindIndex].description} to ${data}`
        );
      }
    }
    if (type == "to_be_done_by") {
      cloneSection[findIndex].to_be_done_by = data;
      if (
        oldeDataRecommended[oldDatafindIndex] &&
        oldeDataRecommended[oldDatafindIndex].to_be_done_by != data
      ) {
        onListenValueChange(
          section_no + "to_be_done_by",
          `Update Action ${section_no} "to be done by"  from ${oldeDataRecommended[oldDatafindIndex].to_be_done_by} to ${data}`
        );
      }
    }
    if (type == "action_assigned_to") {
      cloneSection[findIndex].action_assigned_to = data;
      if (
        oldeDataRecommended[oldDatafindIndex] &&
        oldeDataRecommended[oldDatafindIndex].action_assigned_to != data
      ) {
        onListenValueChange(
          section_no + "action_assigned_to",
          `Update Action ${section_no} "action assigned" to  from ${oldeDataRecommended[oldDatafindIndex].action_assigned_to} to ${data}`
        );
      }
    }
    if (type == "recommended_ca_status") {
      cloneSection[findIndex].recommended_ca_status = data;
      if (
        oldeDataRecommended[oldDatafindIndex] &&
        oldeDataRecommended[oldDatafindIndex].recommended_ca_status != data
      ) {
        onListenValueChange(
          section_no + "recommended_ca_status",
          `Update Action ${section_no} "status"  from ${oldeDataRecommended[oldDatafindIndex].recommended_ca_status} to ${data}`
        );
      }
    }
    setcorrectiveActionValues(cloneSection);
  };

  const ValidationAllForms = () => {
    validateIncidentOverView();
    validateLocation();
    validateSummary();
    validateProperty();
    validationAnalysisForm();

    if (locationValues.park == "") {
      setOverViewError(true);
    } else {
      setOverViewError(false);
    }
    if (mainZone == null || !mainZone) {
      setOverViewError(true);
    } else {
      setOverViewError(false);
    }
    // if (casualtyValues.length == 0) {
    //   setCasualtyError(true);
    // } else {
    //   setCasualtyError(false);
    // }

    if (overViewValues) {
      for (var key in overViewValues) {
        if (key == "injury_type") {
          setOverViewError(false);
        }
        if (key == "emergency_services_activated") {
          setOverViewError(false);
        }
        if (locationValues.park == "") {
          setOverViewError(true);
          break;
        } else {
          setOverViewError(false);
        }
        if (mainZone == null || !mainZone) {
          setOverViewError(true);
          break;
        } else if (overViewValues[key] !== null && overViewValues[key] != "") {
          setOverViewError(false);
        } else {
          if (key == "injury_type" || key == "emergency_services_activated") {
            setOverViewError(false);
          } else {
            setOverViewError(true);

            break;
          }
        }
      }
    } else {
      setOverViewError(true);
    }

    // if (locationValues) {
    //   for (var key in locationValues) {
    //     if (locationValues[key] !== null && locationValues[key] != "") {
    //       setLocationError(false);
    //     } else {
    //       setLocationError(true);
    //     }
    //   }
    // } else {
    //   setLocationError(true);
    // }

    // if (witnessValues.length == 0) {
    //   setWitnessError(true);
    // } else {
    //   setWitnessError(false);
    // }
    if (summaryValues) {
      for (var key in summaryValues) {
        if (summaryValues[key] !== null && summaryValues[key] != "") {
          setSummaryError(false);
        } else {
          setSummaryError(true);
          break;
        }
      }
    } else {
      setSummaryError(true);
    }

    // if (propertyValues) {
    //   for (var key in propertyValues) {
    //     if (propertyValues[key] !== null && propertyValues[key] != "") {
    //       setPropertyError(false);
    //     } else {
    //       setPropertyError(true);
    //     }
    //   }
    // } else {
    //   setPropertyError(true);
    // }

    // if (photoValues.length == 0) {
    //   setPhotoError(true);
    // } else {
    //   setPhotoError(false);
    // }

    // if (analysis) {
    //   for (var key in analysis) {
    //     if (analysis[key] !== null && analysis[key] != "") {
    //       setAnalysisError(false);
    //     } else {
    //       setAnalysisError(true);
    //     }
    //   }
    // } else {
    //   setAnalysisError(true);
    // }

    // if (propertyValues.priority_of_fix == "") {
    //   setPropertyError(true);
    // } else {
    //   setPropertyError(false);
    // }
  };
  const scrollToTopFunction = () => {
    if (window.screen.width > 800) {
      scrollRef.current.scrollIntoView();
    }
  };
  useEffect(() => {
    scrollToTopFunction();
  }, []);
  useEffect(() => {
    // if (actionError || conditionError || personnelError || jobError) {
    //   setAnalysisError(true);
    // } else {
    //   setAnalysisError(false);
    // }
    if (
      propertyInputError ||
      damagesError ||
      partyError
      // || proxityError
    ) {
      setPropertyError(true);
    } else {
      setPropertyError(false);
    }
  }, [
    actionError,
    conditionError,
    personnelError,
    jobError,
    propertyInputError,
    damagesError,
    partyError,
    proxityError,
  ]);

  const onListenValueChange = (key, data) => {
    if (key == "photoVideos") {
      if (!valueChanged.photoVideos) {
        return setValueChanged({
          ...valueChanged,
          photoVideos: [data],
        });
      } else {
        return setValueChanged({
          ...valueChanged,
          photoVideos: valueChanged.photoVideos.concat(data),
        });
      }
    } else {
      return setValueChanged({
        ...valueChanged,
        [key]: data,
      });
    }
  };

  let finalImageForIncidentUpdate = [];
  const imageUploadIncidentUpdate = async (_) => {
    return new Promise((resolve, reject) => {
      let data = [];
      console.log("test1", _);
      const nameForImage =
        "name=" + _.name + "&" + "time=" + new Date().getTime() + Math.random();

      storage
        .ref(`/photovideos/${nameForImage}`)
        .put(_)
        .then(async (img) => {
          const image = await storage
            .ref(`/photovideos/${nameForImage}`)
            .getDownloadURL();
          if (image) {
            data.push(image);
          }

          finalImageForIncidentUpdate.push(image);
          resolve();
        })
        .catch((err) => {
          console.log("err", err);
          reject(err);
        });
    });
  };

  const createIncidentUpdate = async (update, updateData) => {
    setIncidentUpdateLoader(true);
    let incident_updates;
    if (incidentUpImg.length > 0) {
      let imagetoUpload = [];
      incidentUpImg.map((data) => {
        if (data.type) {
          imagetoUpload.push(data);
        } else {
          finalImageForIncidentUpdate.push(data);
        }
      });
      await Promise.all(imagetoUpload.map(imageUploadIncidentUpdate));
    }

    incident_updates = {
      submitted_by: props.AuthDetail.name,
      remark: incidentUpRemark,
      img_url: finalImageForIncidentUpdate,

      submitted_by: props.AuthDetail.name,
    };
    console.log("test2", incident_updates);
    if (update) {
      console.log("test3", finalImageForIncidentUpdate);
      api
        .post(
          UPDATE_INCIDENTS,
          JSON.stringify({
            id: paramId,
            incident_updates: {
              ...incident_updates,
              id: updateData.id,
              deleted: "no",
            },
            platform: "web",
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          console.log(res);
          setIncidentUpdateLoader(false);
          let cloneIncidentUpdate = [];
          cloneIncidentUpdate = [...res.data.incident_updates];
          setIncidentUpImg([]);
          setPreviousIncident({
            ...previousIncident,
            incident_updates: cloneIncidentUpdate,
          });
          setShowModalForIncidentUpdate(false);
        })
        .catch((err) => {
          setIncidentUpdateLoader(false);
          console.log("err", err);
        });
    } else {
      api
        .post(
          UPDATE_INCIDENTS,
          JSON.stringify({ id: paramId, incident_updates, platform: "web" }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          setIncidentUpdateLoader(false);
          let cloneIncidentUpdate = [];
          cloneIncidentUpdate = [...res.data.incident_updates];
          setIncidentUpImg([]);
          setPreviousIncident({
            ...previousIncident,
            incident_updates: cloneIncidentUpdate,
          });
          setShowModalForIncidentUpdate(false);
        })
        .catch((err) => {
          setIncidentUpdateLoader(false);
          console.log("err", err);
        });
    }
  };

  const DeleteIncidentUpdate = async (data) => {
    console.log(data);
    let incident_updates = {
      ...data,
      deleted: "yes",
    };
    if (incidentUpImg.length > 0) {
      await Promise.all(incidentUpImg.map(imageUploadIncidentUpdate));
    }
    api
      .post(
        UPDATE_INCIDENTS,
        JSON.stringify({
          id: paramId,
          incident_updates: incident_updates,
          platform: "web",
        }),
        {
          headers: { "Content-Type": "application/json" },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        console.log(res);
        setIncidentUpdateLoader(false);
        let cloneIncidentUpdate = [];
        cloneIncidentUpdate = [...res.data.incident_updates];
        setIncidentUpImg([]);
        setPreviousIncident({
          ...previousIncident,
          incident_updates: cloneIncidentUpdate,
        });
        setShowModalForIncidentUpdate(false);
      })
      .catch((err) => {
        setIncidentUpdateLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    const {
      incident_type,
      incident_placement,
      involved,
      category,
      incident_classification,
      injury_type,
    } = overViewValues;
    if (incident_type == "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    if (photoValues.length == 0) {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }
    if (incident_placement == "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    if (!category) {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    // if (involved == "") {
    //   setInvolvedError(true);
    // } else {
    //   setInvolvedError(false);
    // }
    if (incident_classification == "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }
    // validateLocation();
    const data = locationValues;
    if (data.park == "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    if (mainZone == null || mainZone == "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    if (data.date == "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }
    if (data.time == "") {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }
    // validateSummary();
    const dataSummary = summaryValues;
    if (
      dataSummary.incident_title == "" ||
      !dataSummary.incident_title ||
      dataSummary.incident_title.length > 100
    ) {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    if (dataSummary.incident_remarks == "" || !dataSummary.incident_remarks) {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }
    // validateProperty();
    const dataProperty = propertyValues;

    if (casualtyValues.length == 0) {
      return setShowNotiPage(false);
    } else {
      setShowNotiPage(true);
    }

    // if (dataProperty.property_type == "") {
    //   return setShowNotiPage(false);
    // } else {
    //   setShowNotiPage(true);
    // }

    // if (dataProperty.damaged_things == "") {
    //   return setShowNotiPage(false);
    // } else {
    //   if (
    //     /[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(dataProperty.damaged_things)
    //   ) {
    //     return setShowNotiPage(false);
    //   } else {
    //     setShowNotiPage(true);
    //   }
    // }
    // if (dataProperty.party_type == "") {
    //   return setShowNotiPage(false);
    // } else {
    //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(dataProperty.party_type)) {
    //     return setShowNotiPage(false);
    //   } else {
    //     setShowNotiPage(true);
    //   }
    // }

    // if (dataProperty.party_info == "") {
    //   return setShowNotiPage(false);
    // } else {
    //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(dataProperty.party_info)) {
    //     return setShowNotiPage(false);
    //   } else {
    //     setShowNotiPage(true);
    //   }
    // }

    // if (dataProperty.priority_of_fix == "") {
    //   return setShowNotiPage(false);
    // } else {
    //   setShowNotiPage(true);
    // }
  }, [
    overViewValues,
    summaryValues,
    propertyValues,
    photoValues,
    casualtyValues,
    witnessValues,
    locationValues,
    mainZone,
  ]);

  const createIncident = async () => {
    setCreateLoader(true);
    setLoading(true);

    setFirstTimeClick(firstTimeClick + 1);
    let notification_list = previousNotiUserList;
    let checked_users = [];
    let unchecked_users = [];
    if (reUsersList) {
      Object.entries(reUsersList).map(([key, value]) => {
        if (value && value.length > 0) {
          value.map((u) => {
            if (!u.state) {
              unchecked_users.push(u.id);
            } else {
              checked_users.push(u.id);
            }
          });
        }
      });
    }
    // if (removedPhotoValues) {
    //   removedPhotoValues.map((img) => {
    //     storage
    //       .refFromURL(img)
    //       .delete()
    //       .catch((err) => console.log("err", err));
    //   });
    // }
    let selected_noti_departs = [];
    if (reSubDepartmentList && reSubDepartmentList.length > 0) {
      reSubDepartmentList.map((sub) => {
        selected_noti_departs.push({
          name: sub.title,
          status: sub.state == "minusSign" ? -1 : sub.state ? 1 : 0,
        });
      });
    }

    // setLoading(true);
    let statusConditionValue;
    let auditList = [];

    // incidentUpImg.map(in => {
    //   storage.ref(`/photovidoes/${nameForImage}`)
    //   .put(e.target.files[0])
    // })

    if (checkStatus) {
      if (checkStatus == "Closed") statusConditionValue = "Closed";
      if (checkStatus == "Open") statusConditionValue = "Open";
      if (checkStatus == "Approve") statusConditionValue = "Open";
      if (checkStatus == "Reject") statusConditionValue = "Draft";
      if (checkStatus == "Draft") statusConditionValue = "Draft";
      if (checkStatus == "Archive") statusConditionValue = "Archived";
      if (checkStatus == "noStatus") {
        if (overViewValues.category == "c1") {
          statusConditionValue = "Open";
        } else {
          statusConditionValue = "Pending Approval";
        }
        // statusConditionValue = "Open";
      }
    } else {
      if (overViewValues.category == "c1") {
        // statusConditionValue = "Open";
        if (overViewValues.is_followup_required == "Yes") {
          statusConditionValue = "Open";
        } else {
          statusConditionValue = "Closed";
        }
      } else {
        statusConditionValue = "Pending Approval";
        // statusConditionValue = "Open";
      }
    }
    if (previousIncident.incident_status !== statusConditionValue) {
      auditList.push(
        `Update status from ${previousIncident.incident_status} to ${statusConditionValue} `
      );
    }

    await auth.onAuthStateChanged((user) => {
      // setEmail(user.email);
      // for arr
      const { ambulance_called, time_called, zone, arrival_time, remark } =
        addationalValues;
      const { hospital_name, medical_days, hospitalize_days, diagnosis } =
        medicalValues;
      const { unsafe_act, unsafe_condition, follow_up } = rootCauseValues;
      let valuesRecommended = [];
      correctiveActionValues.map((e) => {
        //for new creation, don't need to send for new deletion
        let obj;
        if (e.id.startsWith("temp-") && e.status != "delete") {
          obj = {
            recommended_ca_no: e.recommended_ca_no,
            description: e.description,
            action_assigned_to: e.action_assigned_to,
            to_be_done_by: e.to_be_done_by,
            recommended_ca_status: e.recommended_ca_status,
            status: "",
            arr_created_at: e.arr_created_at
              ? e.arr_created_at
              : new Date().toISOString(),
          };
        }
        //for old records update, delete
        else if (!e.id.startsWith("temp-")) {
          //for deletion
          if (e.status) {
            obj = {
              recommended_ca_no: e.recommended_ca_no,
              description: e.description,
              action_assigned_to: e.action_assigned_to,
              to_be_done_by: e.to_be_done_by,
              recommended_ca_status: e.recommended_ca_status,
              status: e.status,
              id: e.id,
              arr_created_at: e.arr_created_at
                ? e.arr_created_at
                : new Date().toISOString(),
            };
          } else {
            obj = {
              recommended_ca_no: e.recommended_ca_no,
              description: e.description,
              action_assigned_to: e.action_assigned_to,
              to_be_done_by: e.to_be_done_by,
              recommended_ca_status: e.recommended_ca_status,
              status: "",
              id: e.id,
              arr_created_at: e.arr_created_at
                ? e.arr_created_at
                : new Date().toISOString(),
            };
          }
        }

        if (obj) {
          valuesRecommended.push(obj);
        }
      });

      const arr = {
        // amb_pol_called: ambulance_called,
        // time_called,
        // zone: zone,
        // time_arrival: arrival_time,
        // remarks: remark,
        // hospital_name,
        // medical_leave: medical_days,
        // hospitalisation_leave: hospitalize_days,
        // diagnosis: diagnosis,
        unsafe_act: unsafe_act,
        unsafe_cond: unsafe_condition,
        follow_up: follow_up,
        recommended_ca: valuesRecommended,
      };
      let modiftedEmergencyServices = [];
      overViewValues.emergency_services_activated.map((e) => {
        if (e && e.name && e.checked) {
          modiftedEmergencyServices.push(e.name);
        }
      });
      let newData = {
        id: paramId,
        incident_status: statusConditionValue,

        ...overViewValues,
        emergency_services_activated: modiftedEmergencyServices,
        ...locationValues,
        zone:
          typeof mainZone === "object"
            ? mainZone.lat + "," + mainZone.lng
            : mainZone,

        sub_zones: subZone,
        zone_type: zoneType,
        person_involved_details: casualtyValues,
        witness_particulars: witnessValues,
        ...summaryValues,
        ...propertyValues,
        photo_video: photoValues,
        substandard_action: analysis.action,
        substandard_condition: analysis.condition,
        personnel_factors: analysis.personnel,
        stoppage_text: analysis.stoppage_text,
        job_factors: analysis.job,
        work_stoppage: analysis.stoppage,
        selected_incident_ids: {
          incident_placement_id: dropdownId.incidentPlacementId,
          incident_classification_id: dropdownId.incidentClassificationId,
          injury_type_id: dropdownId.injuryTypesId,
        },
        latest_updated_by: user.uid,
        aar: arr,
        sub_department: props.AuthDetail.sub_department,
        notification_list,
        noti_frequency: notiSettingValues.frequency_type,
        checked_users,
        unchecked_users,
        selected_noti_departs,
      };

      if (valueChanged) {
        for (var key in valueChanged) {
          if (valueChanged[key] !== null && valueChanged[key] != "") {
            auditList.push(valueChanged[key]);
          } else {
          }
        }
      }
      let finalAuditList = [];
      auditList.map((a) => {
        if (typeof a == "object") {
          a.map((b) => {
            finalAuditList.push(b);
          });
        } else {
          finalAuditList.push(a);
        }
      });
      if (finalAuditList.length > 0) {
        let sendDataAudit = {
          incident_id: paramId,
          changed_by: user.uid,
          description: finalAuditList,
          platform: "web",
        };

        api
          .post(CREATE_AUDIT_INCIDENTS, JSON.stringify(sendDataAudit), {
            headers: { "Content-Type": "application/json" },
          })
          .then((result) => {
            api
              .post(
                UPDATE_INCIDENTS,
                JSON.stringify({ ...newData, platform: "web", send_noti: 1 }),
                {
                  headers: { "Content-Type": "application/json" },
                }
              )
              .then((res) => {
                let cachefilter = cacheData
                  .filter((c) => c.id != paramId)
                  .concat(res.data);

                setCreateLoader(false);
                setSubmitToggle(false);
                setToApi(false);
                setLoading(false);
                return history.push("/incidents_list");
              })
              .catch((err) => {
                setCreateLoader(false);
                setSubmitToggle(false);
                setLoading(false);
                console.log("err", err);
              });
          });
      } else {
        api
          .post(
            UPDATE_INCIDENTS,
            JSON.stringify({ ...newData, platform: "web", send_noti: 1 }),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((res) => {
            setCreateLoader(false);
            setSubmitToggle(false);
            setToApi(false);
            setLoading(false);
            return history.push("/incidents_list");
          })
          .catch((err) => {
            setLoading(false);
            setCreateLoader(false);
            setSubmitToggle(false);
            console.log("err", err);
          });
      }

      setLoading(false);
    });
  };

  // creating Incident
  useEffect(() => {
    if (overViewValues.is_emergency_incident == "Yes") {
      if (firstTimeClick == 1) {
        // setCreateLoader(true);
        return createIncident();
      }
    } else if (toApi) {
      if (
        !overViewError &&
        !locationError &&
        // !casualtyError &&
        // !witnessError &&
        !summaryError &&
        !propertyError &&
        // !photoError &&
        !typeError &&
        !placementError &&
        !categoryError &&
        !parkError &&
        !zoneError &&
        !weatherError &&
        !areaError &&
        !titleError
        // &&
        // !propertyInputError &&
        // !damagesError &&
        // !partyError &&
        // damagesErrorTxt == "" &&
        // !partyTypeError &&
        // partyTypeErrorTxt == "" &&
        // partyErrorTxt == "" &&
        // !proxityError
        // !analysisError
      ) {
        if (firstTimeClick == 1) {
          return createIncident();
        }
      }
    }
    return () => setToApi(false);
  }, [submitToggle, toApi]);

  const mainSaveButton = async () => {
    setSubmitToggle(!submitToggle);
    setToApi(true);
    ValidationAllForms();
  };

  useEffect(() => {}, [userListForNoti, userListForNotiLoader]);

  const fetchDefaultDepartment = () => {
    setFetchingSubDepart(true);
    api
      .get(GET_SUB_DEPARTMENT, {
        id: previousIncident.id,
        platform: "web",
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        is_emergency:
          overViewValues.is_emergency_incident == "Yes" ? true : false,
      })
      .then((res) => {
        let data = [];
        res.data.map((d, index) => {
          data.push({
            key: d.name,
            title: d.name,
            state: d.status == 1 ? true : false,
            firstTime: 1,
          });
        });

        const sortData = data.sort((a, b) =>
          a.title.toLowerCase().localeCompare(b.title.toLowerCase())
        );
        setDepartFoldState(sortData);
        setAllSubDepartmentList(sortData);
        setTotalPageForAllSubDepart(Math.ceil(sortData.length / 10));
        setSubDepartmentForPagination(sortData.slice(0, 10));
        setFetchingSubDepart(false);
      })
      .catch((err) => {
        setFetchingSubDepart(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    //gggggggggggggggg
    if (previousIncident && previousIncident.id) {
      fetchDefaultDepartment();
    }

    // db.collection("noti_default_departments")
    //   .where(
    //     "classification_title",
    //     "==",
    //     overViewValues.incident_classification
    //   )
    //   .get()
    //   .then((querySnapshot) => {
    //     let data = [];

    //     querySnapshot.forEach(function (doc) {
    //       doc.data().department_arr.map((d, index) => {
    //         data.push({
    //           key: d,
    //           title: d,
    //           state: false,
    //         });
    //       });
    //     });
    //     setDepartFoldState(data);
    //   });
  }, [
    overViewValues.incident_classification,
    overViewValues.is_emergency_incident,
  ]);

  const handleClickDepartment = (sub_department, state) => {
    let data = [];
    setUserlistForNotiLoader(true);
    let cloneUserListForNoti = userListForNoti;
    const findIndex = departFoldState.findIndex(
      (_, index) => _.key === sub_department
    );
    let clone = departFoldState;
    clone[findIndex].firstTime = clone[findIndex].firstTime + 1;

    setDepartFoldState(clone);
    getDepartFoldState(sub_department);
    api
      .get(GET_ALL_NOTI_USERS, {
        platform: "web",
        sub_department,
        id: previousIncident.id,
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        is_emergency:
          overViewValues.is_emergency_incident == "Yes" ? true : false,
      })
      .then((res) => {
        res.data.map((user) => {
          data.push({
            id: user.id,
            state: user.is_notified_user,
            name: user.name,
            platform: user.platform,
            key: sub_department,
          });
        });

        setSearchUsersPagination({
          ...searchUsersDataPagiantion,
          [sub_department]: data.slice(0, 50),
        });
        setLastUsersItem({
          from: 0,
          to: 50,
        });
        setTotalUserspage(Math.ceil(data.length / 50));

        cloneUserListForNoti[sub_department] = data;
        setUserlistForNotiLoader(false);
        if (state) {
          const fIndex = departFoldState.findIndex(
            (_, index) => _.key == sub_department
          );
          if (fIndex != -1) {
            let clone = departState;
            clone[fIndex].state = false;
            setDepartState(clone);
            getDepartState(sub_department, "must");
          }
        } else {
          getDepartState(sub_department);
        }

        setRC(!rc);
      })
      .catch((err) => {
        console.log("err", err);
        setUserlistForNotiLoader(false);
      });

    setUserListForNoti(cloneUserListForNoti);
  };

  useEffect(() => {
    getDepartFoldState(currentSubDepartment, true);
  }, [userListForNoti, currentSubDepartment, rc, userListForNotiLoader]);
  const fetchUsersBySearch = (
    search,
    setSearchDataList,
    setLastItem,
    setTotalpage,
    setSearchPagination
  ) => {
    let data = [];
    setUserlistForNotiLoader(true);
    let cloneUserListForNoti = userListForNoti;
    api
      .get(SEARCH_NOTI_USERS, {
        platform: "web",
        keyword: search,
        search_by: "user",
        classification: overViewValues.incident_classification,
        classification_id: dropdownId.incidentClassificationId,
        id: "",
        is_emergency:
          overViewValues.is_emergency_incident == "Yes" ? true : false,
      })
      .then((res) => {
        const { data, others } = res;
        let updateData = [];
        let otherData = [];
        let id = 0;
        let usersAndDepart = {};
        data.map((user) => {
          id += 1;

          updateData.push({
            id: user.id,
            state: user.is_notified_user,
            name: user.name,
            platform: user.platform,
            key: user.sub_department,
          });
        });
        others.map((user) => {
          id += 1;

          otherData.push({
            id: user.id,
            state: user.is_notified_user,
            name: user.name,
            platform: user.platform,
            key: user.sub_department,
          });
        });
        let groupUser = updateData.reduce((groupDepartment, user) => {
          const subDepartment = user.key;
          if (groupDepartment[subDepartment] == null)
            groupDepartment[subDepartment] = [];
          groupDepartment[subDepartment].push(user);
          return groupDepartment;
        }, {});
        let groupUsersWithOthers = [...otherData, ...updateData].reduce(
          (groupDepartment, user) => {
            const subDepartment = user.key;
            if (groupDepartment[subDepartment] == null)
              groupDepartment[subDepartment] = [];
            groupDepartment[subDepartment].push(user);
            return groupDepartment;
          },
          {}
        );
        Object.entries(groupUsersWithOthers).map(([key, value]) => {
          cloneUserListForNoti[key] = value;
        });
        let subDeparts = [];

        let ide = 0;
        Object.entries(groupUser).map(([key, value]) => {
          ide += 1;
          subDeparts.push({
            id: ide,
            key: key,
            title: key,
            state: true,
          });
          usersAndDepart = {
            ...usersAndDepart,
            [key]: value.slice(0, 50),
          };
          // usersAndDepart.push({
          //   [key]: value.slice(0, 50),
          // });
          setLastUsersItem({
            from: 0,
            to: 50,
          });
          setTotalUserspage(Math.ceil(value.length / 50));

          // cloneUserListForNoti[key] = value;
          setUserlistForNotiLoader(false);
          getDepartState(key);
        });

        setSearchUsersPagination(usersAndDepart);
        setDepartFoldState(subDeparts);
        let sortData = subDeparts.sort((a, b) => a - b);

        setSearchDataList(subDeparts);
        setLastItem({
          from: 0,
          to: 10,
        });
        setTotalpage(Math.ceil(subDeparts.length / 10));
        setSearchPagination(subDeparts.slice(0, 10));

        // if (!searchUsersDataPagiantion[sub_department]) {
        //   setSearchUsersPagination({
        //     ...searchUsersDataPagiantion,
        //     [sub_department]: data.slice(0, 50),
        //   });
        //   setLastUsersItem({
        //     from: 0,
        //     to: 50,
        //   });
        //   setTotalUserspage(Math.ceil(data.length / 50));
        // }

        // cloneUserListForNoti[sub_department] = data;
        // setUserlistForNotiLoader(false);
        // getDepartState(sub_department);
        setRC(!rc);
        setUserlistForNotiLoader(false);
      })
      .catch((err) => {
        setUserlistForNotiLoader(false);
        console.log("err", err);
      });
  };
  const validateIncidentOverView = () => {
    const {
      incident_type,
      incident_placement,
      category,
      incident_classification,
      injury_type,
    } = overViewValues;
    if (incident_type == "") {
      setTypeError(true);
    } else {
      setTypeError(false);
    }

    if (incident_placement == "") {
      setPlacementError(true);
    } else {
      setPlacementError(false);
    }

    if (!category) {
      setCategoryError(true);
      setOverViewError(true);
    } else {
      setCategoryError(false);
      setOverViewError(false);
    }
    if (category == "") {
    }
    if (incident_classification == "" || !incident_classification) {
      setClassificationError(true);
    } else {
      setClassificationError(false);
    }
    // if (injury_type == "") {
    //   setSetInjuryTypeError(true);
    // } else {
    //   setSetInjuryTypeError(false);
    // }
    if (!typeError && !placementError && !categoryError) {
    }
  };

  const validateLocation = () => {
    const data = locationValues;
    if (data.park == "") {
      setParkError(true);
      setLocationError(true);
    } else {
      setParkError(false);
      setLocationError(false);
    }

    if (mainZone == null || mainZone == "") {
      setZoneError(true);
      setLocationError(true);
    } else {
      setZoneError(false);
      setLocationError(false);
    }

    // if (data.weather == "") {
    //   setWeatherError(true);
    //   setLocationError(true);
    //   setCanCreate(true);
    // } else {
    //   setWeatherError(false);
    //   setLocationError(false);
    //   setCanCreate(false);
    // }

    // if (data.area_condition == "") {
    //   setAreaError(true);
    //   setLocationError(true);
    //   setCanCreate(true);
    // } else {
    //   setAreaError(false);
    //   setLocationError(false);
    //   setCanCreate(false);
    // }
  };

  const validateSummary = () => {
    const data = summaryValues;

    if (
      data.incident_title == "" ||
      !data.incident_title ||
      data.incident_title.length > 100
    ) {
      setTitleError(true);
      setSummaryError(true);
    } else {
      setTitleError(false);
      setSummaryError(false);
    }

    if (data.incident_remarks == "") {
      setRemarkError(true);
    } else {
      setRemarkError(false);
    }
  };

  const validateProperty = () => {
    const data = propertyValues;
    // if (data.property_type == "") {
    //   setPropertyInputError(true);
    // } else {
    //   setPropertyInputError(false);
    // }

    // if (data.damaged_things == "") {
    //   setDamagesError(true);
    //   setDamagesErrorTxt("Required Field");
    // } else {
    //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(data.damaged_things)) {
    //     setDamagesError(true);
    //     setDamagesErrorTxt("Must be valid");
    //   } else {
    //     setDamagesError(false);
    //     setDamagesErrorTxt("");
    //   }
    // }
    // if (data.party_type == "") {
    //   setPartyTypeError(true);
    //   setPartyTypeErrorTxt("Required Field");
    // } else {
    //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(data.party_type)) {
    //     setPartyTypeError(true);
    //     setPartyTypeErrorTxt("Must be valid");
    //   } else {
    //     setPartyTypeError(false);
    //     setPartyTypeErrorTxt("");
    //   }
    // }
    // if (data.party_info == "") {
    //   setPartyError(true);
    //   setPartyErrorTxt("Required Field");
    // } else {
    //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(data.party_info)) {
    //     setPartyError(true);
    //     setPartyErrorTxt("Must be valid");
    //   } else {
    //     setPartyError(false);
    //     setPartyErrorTxt("");
    //   }
    // }

    // if (data.priority_of_fix == "") {
    //   setProxityError(true);
    // } else {
    //   setProxityError(false);
    // }
  };
  const validatePhoto = () => {};

  const validationAnalysisForm = () => {
    const data = analysis;
    if (data.action == "") {
      setActionError(true);
    } else {
      setActionError(false);
    }

    if (data.condition == "") {
      setConditionError(true);
    } else {
      setConditionError(false);
    }

    if (data.personnel == "") {
      setPersonnelError(true);
    } else {
      setPersonnelError(false);
    }

    if (data.job == "") {
      setJobError(true);
    } else {
      setJobError(false);
    }

    if (data.input == "") {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };
  // const changeDropDownId = (key, value) => {
  //   setDropdownId({
  //     ...dropdownId,
  //     [key]: value,
  //   });
  // };

  const changeDropDownId = (key, value) => {
    if (key == "incident_type") {
      setDropdownId({
        ...dropdownId,
        [key]: value,
        incidentPlacementId: "",
        incidentClassificationId: "",
        injuryTypesId: "",
      });
    } else if (key == "incidentPlacementId") {
      setDropdownId({
        ...dropdownId,
        [key]: value,
        incidentClassificationId: "",
        injuryTypesId: "",
      });
    } else {
      setDropdownId({
        ...dropdownId,
        [key]: value,
      });
    }
  };

  const sidebarcssColors = (title) => {
    if (title == "Incident Description" && overViewError) {
      return "error";
    } else if (title == "Incident Description" && locationError) {
      return "error";
    }
    // else if (title == "Person Involved Details" && casualtyError) {
    //   return "error";
    // } else if (title == "Witnesses Details" && witnessError) {
    //   return "error";
    // }
    else if (title == "Incident Description" && summaryError) {
      return "error";
    } else if (title == "Details of Property Damage" && propertyError) {
      return "error";
    } else if (title == "Incident Description" && photoError) {
      return "error";
    } else if (title == "MPD Incident Analysis" && analysisError) {
      return "error";
    } else {
      return "nth";
    }
  };
  let filterP15 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p15");
  useEffect(() => {}, [correctiveActionValues, rc]);
  useEffect(() => {
    props.hideShowZoneList();
  }, []);
  const Sidebar = () => {
    return (
      <div className="sidebar" onClick={props.hideShowZoneList}>
        {/* Cases */}
        <div className="title">Case details</div>
        {cases.map((c, index) => {
          return (
            <>
              {c.title == "Incident Updates" ? (
                checkStatusSideCondition == "Open" ||
                checkStatusSideCondition == "Closed" ? (
                  <div
                    className={`option_container ${
                      c.title == detailSelected && "active"
                    }`}
                    onClick={() => {
                      scrollToTopFunction();
                      setDetailSelected(c.title);
                      changeHideCasualyFormBool();
                      changeHideWitnessFormBool();
                    }}
                  >
                    <div style={{ width: "95%" }}>{c.title} </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          background: "#E72D35",
                          color: "#fff",
                          width: 20,
                          height: 20,
                          borderRadius: 50,
                          display: "flex",
                          justifyContent: "center",
                          marginRight: 10,
                          opacity: sidebarcssColors(c.title) == "error" ? 1 : 0,
                        }}
                      >
                        !
                      </div>
                      <img
                        src={
                          c.title == detailSelected
                            ? RightArrowIcon
                            : DownArrowIcon
                        }
                      />
                    </div>
                  </div>
                ) : null
              ) : (
                <div
                  className={`option_container ${
                    c.title == detailSelected && "active"
                  }`}
                  onClick={() => {
                    scrollToTopFunction();
                    setDetailSelected(c.title);
                    changeHideCasualyFormBool();
                    changeHideWitnessFormBool();
                  }}
                >
                  <div style={{ width: "95%" }}>{c.title}</div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        background: "#E72D35",
                        color: "#fff",
                        width: 20,
                        height: 20,
                        borderRadius: 50,
                        display: "flex",
                        justifyContent: "center",
                        marginRight: 10,
                        opacity: sidebarcssColors(c.title) == "error" ? 1 : 0,
                      }}
                    >
                      !
                    </div>
                    <img
                      src={
                        c.title == detailSelected
                          ? RightArrowIcon
                          : DownArrowIcon
                      }
                    />
                  </div>
                </div>
              )}
            </>
          );
        })}

        {/* AAR */}

        {incidentStatus == "Open" ||
        incidentStatus == "Closed" ||
        incidentStatus == "Pending Approval" ||
        incidentStatus == "Archived" ? (
          <>
            <div className="title" style={{ marginTop: 15 }}>
              Post Incidents
            </div>
            {aar.map((c, index) => {
              return (
                <div
                  className={`option_container ${
                    c.title == detailSelected && "active"
                  }`}
                  onClick={() => {
                    scrollToTopFunction();
                    setDetailSelected(c.title);
                    changeHideCasualyFormBool();
                    changeHideWitnessFormBool();
                  }}
                >
                  <div style={{ width: "90%" }}>{c.title}</div>
                  <div>
                    <img
                      src={
                        c.title == detailSelected
                          ? RightArrowIcon
                          : DownArrowIcon
                      }
                    />
                  </div>
                </div>
              );
            })}
            {/* Notification */}
          </>
        ) : null}
        <>
          <div className="title" style={{ marginTop: 15 }}>
            Notification
          </div>
          {notis.map((c, index) => {
            return (
              <div
                className={`option_container ${
                  c.title == detailSelected && "active"
                }`}
                onClick={() => {
                  scrollToTopFunction();

                  setDetailSelected(c.title);
                  changeHideCasualyFormBool();
                  changeHideWitnessFormBool();
                }}
              >
                <div style={{ width: "90%" }}>{c.title}</div>
                <div>
                  <img
                    src={
                      c.title == detailSelected ? RightArrowIcon : DownArrowIcon
                    }
                  />
                </div>
              </div>
            );
          })}
        </>
      </div>
    );
  };
  const MobileSideBar = () => {
    return (
      <div
        className="__incident_mobile_form_tabs"
        style={{ display: "flex", margin: "15px" }}
      >
        {/* Cases */}

        {cases.map((c, index) => {
          return (
            <>
              {c.title == "Incident Updates" ? (
                checkStatusSideCondition == "Open" ||
                checkStatusSideCondition == "Closed" ? (
                  <div
                    className={`mobile_filter_tabs __incident_tabs ${
                      c.title == detailSelected && "active"
                    }`}
                    style={{ display: "flex", position: "relative" }}
                  >
                    <Button
                      onClick={() => {
                        scrollToTopFunction();

                        setDetailSelected(c.title);
                        changeHideCasualyFormBool();
                        changeHideWitnessFormBool();
                      }}
                    >
                      {c.title}
                    </Button>
                    <div
                      style={{
                        background: "#E72D35",
                        color: "#fff",
                        width: 20,
                        height: 20,
                        borderRadius: 50,
                        display: "flex",
                        justifyContent: "center",
                        marginRight: 10,
                        opacity: sidebarcssColors(c.title) == "error" ? 1 : 0,
                        position: "absolute",
                        top: 6,
                        right: 0,
                      }}
                    >
                      !
                    </div>
                  </div>
                ) : null
              ) : (
                <div
                  className={`mobile_filter_tabs __incident_tabs ${
                    c.title == detailSelected && "active"
                  }`}
                  style={{ display: "flex", position: "relative" }}
                >
                  <Button
                    onClick={() => {
                      scrollToTopFunction();

                      setDetailSelected(c.title);
                      changeHideCasualyFormBool();
                      changeHideWitnessFormBool();
                    }}
                  >
                    {c.title}
                  </Button>
                  <div
                    style={{
                      background: "#E72D35",
                      color: "#fff",
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      display: "flex",
                      justifyContent: "center",
                      marginRight: 10,
                      opacity: sidebarcssColors(c.title) == "error" ? 1 : 0,
                      position: "absolute",
                      top: 6,
                      right: 0,
                    }}
                  >
                    !
                  </div>
                </div>
              )}
            </>
          );
        })}
        {/* AAR */}

        {incidentStatus == "Open" ||
        incidentStatus == "Closed" ||
        incidentStatus == "Pending Approval" ||
        incidentStatus == "Archived" ? (
          <>
            {aar.map((c, index) => {
              return (
                <div
                  className={`mobile_filter_tabs __incident_tabs ${
                    c.title == detailSelected && "active"
                  }`}
                  style={{ display: "flex", position: "relative" }}
                >
                  <Button
                    onClick={() => {
                      scrollToTopFunction();

                      setDetailSelected(c.title);
                      changeHideCasualyFormBool();
                      changeHideWitnessFormBool();
                    }}
                  >
                    {c.title}
                  </Button>
                  <div
                    style={{
                      background: "#E72D35",
                      color: "#fff",
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      display: "flex",
                      justifyContent: "center",
                      marginRight: 10,
                      opacity: sidebarcssColors(c.title) == "error" ? 1 : 0,
                      position: "absolute",
                      top: 6,
                      right: 0,
                    }}
                  >
                    !
                  </div>
                </div>
              );
            })}
            {/* Notification */}
          </>
        ) : null}
        <>
          {notis.map((c, index) => {
            return (
              <div
                className={`mobile_filter_tabs __incident_tabs ${
                  c.title == detailSelected && "active"
                }`}
                style={{ display: "flex", position: "relative" }}
              >
                <Button
                  onClick={() => {
                    scrollToTopFunction();

                    setDetailSelected(c.title);
                    changeHideCasualyFormBool();
                    changeHideWitnessFormBool();
                  }}
                >
                  {c.title}
                </Button>
                <div
                  style={{
                    background: "#E72D35",
                    color: "#fff",
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    display: "flex",
                    justifyContent: "center",
                    marginRight: 10,
                    opacity: sidebarcssColors(c.title) == "error" ? 1 : 0,
                    position: "absolute",
                    top: 6,
                    right: 0,
                  }}
                >
                  !
                </div>
              </div>
            );
          })}
        </>
      </div>
    );
  };
  const SummaryDetail = () => {
    return (
      <div className="summary_detail">
        <div>
          <div className="summary_detail_content">
            <div className="summary_detail_content_title">Status</div>
            <div className="summary_detail_content_value">Open</div>
          </div>
          <div className="summary_detail_content">
            <div className="summary_detail_content_title">Type of incident</div>
            <div className="summary_detail_content_value">Safety</div>
          </div>
          <div className="summary_detail_content">
            <div className="summary_detail_content_title">
              Incident placement
            </div>
            <div className="summary_detail_content_value">Guest</div>
          </div>
          <div className="summary_detail_content">
            <div className="summary_detail_content_title">Category</div>
            <div className="summary_detail_content_value">2</div>
          </div>
          <div className="summary_detail_content">
            <div className="summary_detail_content_title">Park</div>
            <div className="summary_detail_content_value">Jurong Bird Park</div>
          </div>
          <div className="summary_detail_content">
            <div className="summary_detail_content_title">Zone</div>
            <div className="summary_detail_content_value">Tram Station</div>
          </div>
          <div className="summary_detail_content">
            <div className="summary_detail_content_title">Date</div>
            <div className="summary_detail_content_value">30 Apr 2021</div>
          </div>
          <div className="summary_detail_content">
            <div className="summary_detail_content_title">Time</div>
            <div className="summary_detail_content_value">13:00</div>
          </div>
          <div className="summary_detail_content">
            <div className="summary_detail_content_title">
              Condition of area{" "}
            </div>
            <div className="summary_detail_content_value">-</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {fetchLoader || createLoader ? (
        <Dimmer active inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      ) : (
        <div
          className="detail_container"
          ref={scrollRef}
          onClick={() => props.hideShowZoneList()}
        >
          {/* Left Side */}
          <div className="case_detail">
            {/* <div className="detail_header">
       <div
         to=""
         className="header_leftside"
         onClick={() => history.goBack()}
       >
         <img src={LeftArrowIcon} style={{ marginTop: 0 }} />
         <div className="detail_title">Incident Details </div>
       </div>


     </div> */}
            {/* {!showCasualtyForm && !showWitnessForm && (
         <div>
           <Button className="detail_save_button">Save </Button>
         </div>
       )} */}
            {isMobile && <MobileSideBar />}
            <div className="detail_form ">
              {!isMobile && Sidebar()}
              <div className="form add_flex">
                <div
                  className={`form_container ${
                    showCasualtyForm || showWitnessForm
                      ? "add_width"
                      : "remove_width"
                  }`}
                >
                  {detailSelected == "Incident Description" ? (
                    <Incident_overview
                      AuthDetail={props.AuthDetail}
                      mainSaveButton={mainSaveButton}
                      onListenValueChange={onListenValueChange}
                      onChangeIncidentOverView={onChangeIncidentOverView}
                      onChangeSelectedAddress={onChangeSelectedAddress}
                      selectedAddress={selectedAddress}
                      overViewValues={overViewValues}
                      overViewInputError={{
                        typeError,
                        placementError,
                        categoryError,
                        involvedError,
                        classificationError,
                      }}
                      photoError={photoError}
                      previousIncident={previousIncident}
                      // TimeStamp Location
                      hideShowZoneList={props.hideShowZoneList}
                      showShowZoneList={props.showShowZoneList}
                      showZoneDropDown={props.showZoneDropDown}
                      locationValues={locationValues}
                      saveMainDraftButton={saveMainDraftButton}
                      onChangeIncidentLocation={onChangeIncidentLocation}
                      onChangeIncidentLocationZone={
                        onChangeIncidentLocationZone
                      }
                      locationInputError={{
                        parkError,
                        zoneError,
                        weatherError,
                        areaError,
                      }}
                      onChangeLocationDateTime={onChangeLocationDateTime}
                      locationDate={locationDate}
                      onChangeIncidentAddressZone={onChangeIncidentAddressZone}
                      onChangeIncidentInjuryType={onChangeIncidentInjuryType}
                      onChangeIncidentLocationCondtionsArea={
                        onChangeIncidentLocationCondtionsArea
                      }
                      subZone={subZone}
                      zoneType={zoneType}
                      mainZone={mainZone}
                      onChangeSubZone={onChangeSubZone}
                      onChangeZoneType={onChangeZoneType}
                      onChangeMainZone={onChangeMainZone}
                      // Photo & Video
                      photoValues={photoValues}
                      onChangePhoto={onChangePhoto}
                      filterPhotoFun={filterPhotoFun}
                      removedPhotoValues={removedPhotoValues}
                      setRemovedPhotoValues={setRemovedPhotoValues}
                      optionsState={{
                        incidentTypeList,
                        setIncidentTypeList,
                        incidentPlacementList,
                        setIncidentPlacementList,
                        incidentClassificationList,
                        setIncidentClassificationList,
                        injuryTypesList,
                        setInjuryTypesList,
                        parkList,
                        setParkList,
                        zoneList,
                        setZoneList,
                      }}
                      summaryValues={summaryValues}
                      onChangeIncidentSummary={onChangeIncidentSummary}
                      summaryInputError={{
                        titleError,
                        remarkError,
                      }}
                      dropdownId={dropdownId}
                      changeDropDownId={changeDropDownId}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                      showNotiPage={showNotiPage}
                    />
                  ) : detailSelected == "Person Involved Details" ? (
                    <CasualtyDetails
                      AuthDetail={props.AuthDetail}
                      overViewValues={overViewValues}
                      mainSaveButton={mainSaveButton}
                      changeShowCasualyFormBool={changeShowCasualyFormBool}
                      changeHideCasualyFormBool={changeHideCasualyFormBool}
                      showCasualtyForm={showCasualtyForm}
                      casualtyValues={casualtyValues}
                      onChangeIncidentCasulaty={onChangeIncidentCasulaty}
                      optionsState={{
                        nationalityList,
                        setNationalityList,
                      }}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      onListenValueChange={onListenValueChange}
                      previousIncident={previousIncident}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                      showNotiPage={showNotiPage}
                      newAddedSignature={newAddedSignature}
                      setNewAddedSignature={setNewAddedSignature}
                      subDeparts={props.subDeparts}
                    />
                  ) : detailSelected == "Witnesses Details" ? (
                    <WitnessParticulars
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      mainSaveButton={mainSaveButton}
                      changeShowWitnessFormBool={changeShowWitnessFormBool}
                      changeHideWitnessFormBool={changeHideWitnessFormBool}
                      showWitnessForm={showWitnessForm}
                      witnessValues={witnessValues}
                      onChangeIncidetWitness={onChangeIncidetWitness}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      onListenValueChange={onListenValueChange}
                      previousIncident={previousIncident}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                      showNotiPage={showNotiPage}
                      newAddedSignature={newAddedSignature}
                      setNewAddedSignature={setNewAddedSignature}
                      subDeparts={props.subDeparts}
                    />
                  ) : detailSelected == "Incident Summary" ? (
                    <IncidentSummary
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      mainSaveButton={mainSaveButton}
                      summaryValues={summaryValues}
                      onChangeIncidentSummary={onChangeIncidentSummary}
                      summaryInputError={{
                        titleError,
                        remarkError,
                      }}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                      showNotiPage={showNotiPage}
                    />
                  ) : detailSelected == "Details of Property Damage" ? (
                    <PropertyDamageDetails
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      propertyValues={propertyValues}
                      mainSaveButton={mainSaveButton}
                      onChangeIncidentProperty={onChangeIncidentProperty}
                      propertyError={{
                        propertyInputError,
                        damagesError,
                        partyError,
                        proxityError,
                        damagesErrorTxt,
                        partyErrorTxt,
                        partyTypeError,
                        partyTypeErrorTxt,
                      }}
                      optionsState={{
                        priorityList,
                        setPriorityList,
                      }}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      onListenValueChange={onListenValueChange}
                      previousIncident={previousIncident}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                      showNotiPage={showNotiPage}
                    />
                  ) : detailSelected == "MPD Incident Analysis" ? (
                    <IncidentAnalysis
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      onChangeIncidentAnalysis={onChangeIncidentAnalysis}
                      analysis={analysis}
                      mainSaveButton={mainSaveButton}
                      analysisError={{
                        actionError,
                        conditionError,
                        personnelError,
                        inputError,
                        jobError,
                      }}
                      optionsState={{
                        subStandardActionList,
                        setSubStandardActionList,
                        subStandardContionList,
                        setSubStandardContionList,
                        personnelFactorsList,
                        setPersonnelFactorsList,
                        jobFactorList,
                        setJobFactorList,
                      }}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      onListenValueChange={onListenValueChange}
                      previousIncident={previousIncident}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                      showNotiPage={showNotiPage}
                    />
                  ) : detailSelected == "Additional actions" ? (
                    <AddationAction
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      addationalValues={addationalValues}
                      mainSaveButton={mainSaveButton}
                      onChangeAddationAction={onChangeAddationAction}
                      addationalError={{
                        ambulance_called_Error,
                        time_called_Error,
                        arrival_time_Error,
                        remark_Error,
                        zone_Error,
                      }}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                      showNotiPage={showNotiPage}
                    />
                  ) : detailSelected == "Medical status" ? (
                    <MedicalStatus
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      medicalValues={medicalValues}
                      mainSaveButton={mainSaveButton}
                      onChangeMedicalStatusAction={onChangeMedicalStatusAction}
                      MedicalStatusError={{
                        hospital_name_Error,
                        medical_days_Error,
                        hospitalize_days_Error,
                        diagnosis_Error,
                      }}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                    />
                  ) : detailSelected == "Root Cause Analysis" ? (
                    <RootCauseAnalysis
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      rootCauseValues={rootCauseValues}
                      mainSaveButton={mainSaveButton}
                      onChangeRootCauseAction={onChangeRootCauseAction}
                      RootCauseError={{
                        unsafe_act_Error,
                        unsafe_condition_Error,
                      }}
                      optionsState={{
                        unsafeActList,
                        setUnsafeActList,
                        unsafeConditionList,
                        setUnsafeConditionList,
                      }}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      onListenValueChange={onListenValueChange}
                      previousIncident={previousIncident}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                    />
                  ) : detailSelected == "Recommended Corrective Actions" ? (
                    <RecommendedCorrectiveActions
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      mainSaveButton={mainSaveButton}
                      AddCorrectiveAction={AddCorrectiveAction}
                      correctiveActionValues={correctiveActionValues}
                      RemoveCorrectiveAction={RemoveCorrectiveAction}
                      onChangeCorrectiveAction={onChangeCorrectiveAction}
                      optionsState={{
                        actionAssignedList,
                        setActionAssignedList,
                      }}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      onListenValueChange={onListenValueChange}
                      previousIncident={previousIncident}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                      showNotiPage={showNotiPage}
                    />
                  ) : detailSelected == "Incident Updates" ? (
                    <IncidentUpdate
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      mainSaveButton={mainSaveButton}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      previousIncident={previousIncident}
                      setIncidentUpRemark={setIncidentUpRemark}
                      incidentUpRemark={incidentUpRemark}
                      DeleteIncidentUpdate={DeleteIncidentUpdate}
                      incidentUpImg={incidentUpImg}
                      setIncidentUpImg={setIncidentUpImg}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                      incidentUpdateLoader={incidentUpdateLoader}
                      createIncidentUpdate={createIncidentUpdate}
                      setShowModalForIncidentUpdate={
                        setShowModalForIncidentUpdate
                      }
                      showModalForIncidentUpdate={showModalForIncidentUpdate}
                      showNotiPage={showNotiPage}
                      onListenValueChange={onListenValueChange}
                      incidentStatus={incidentStatus}
                    />
                  ) : detailSelected == "Notification List" ? (
                    // overViewValues.is_emergency_incident == "Yes"
                    <NotificationsList
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      mainSaveButton={mainSaveButton}
                      departState={{ departState, setDepartState }}
                      usersState={{ usersState, setUsersState }}
                      onChangeUsersState={onChangeUsersState}
                      getUsersState={getUsersState}
                      onChangeDepartState={onChangeDepartState}
                      getDepartState={getDepartState}
                      departFoldState={{ departFoldState, setDepartFoldState }}
                      getDepartFoldState={getDepartFoldState}
                      onChangeDepartFoldState={onChangeDepartFoldState}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      incident_classification={
                        overViewValues.incident_classification
                      }
                      fetchUsersBySearch={fetchUsersBySearch}
                      setCheckAllDepartmentNoti={setCheckAllDepartmentNoti}
                      checkAllDepartmentNoti={checkAllDepartmentNoti}
                      onChangeDepartmentForNoti={onChangeDepartmentForNoti}
                      previousIncident={previousIncident}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      handleClickDepartment={handleClickDepartment}
                      valueChanged={valueChanged}
                      userListForNoti={userListForNoti}
                      userListForNotiLoader={userListForNotiLoader}
                      searchUsersDataPagiantion={searchUsersDataPagiantion}
                      setSearchUsersPagination={setSearchUsersPagination}
                      totalUsersPage={totalUsersPage}
                      setTotalUserspage={setTotalUserspage}
                      lastUsersItem={lastUsersItem}
                      setLastUsersItem={setLastUsersItem}
                      onListenValueChange={onListenValueChange}
                      allSubDepartmentList={allSubDepartmentList}
                      totalpageForAllSubDepart={totalpageForAllSubDepart}
                      lastItemForAllSubDepart={lastItemForAllSubDepart}
                      subDepartmentForPagination={subDepartmentForPagination}
                      handlePaginationForAllSubDepart={
                        handlePaginationForAllSubDepart
                      }
                      setCurrentAllSubDepartPage={setCurrentAllSubDepartPage}
                      fetchDefaultDepartment={fetchDefaultDepartment}
                      setSearchBy={setSearchBy}
                      searchBy={searchBy}
                      fetchingSubDepart={fetchingSubDepart}
                      setCurrentSubDepartment={setCurrentSubDepartment}
                      reSubDepartmentList={reSubDepartmentList}
                      reHandleImageClickDepartment={
                        reHandleImageClickDepartment
                      }
                      handleSubDepartCheckBox={handleSubDepartCheckBox}
                      reUsersList={reUsersList}
                      reFetchUser={reFetchUser}
                      reUserStateOnChange={reUserStateOnChange}
                      reSearchByDepartment={reSearchByDepartment}
                      reSearchDepartment={reSearchDepartment}
                      reSearchByUser={reSearchByUser}
                      clickSearchBtnNoti={clickSearchBtnNoti}
                      reSearchUsersWithDepart={reSearchUsersWithDepart}
                      showNotiPage={showNotiPage}
                    />
                  ) : detailSelected == "Notification Settings" ? (
                    // overViewValues.is_emergency_incident == "Yes"
                    <>
                      <NotificationsSetting
                        overViewValues={overViewValues}
                        AuthDetail={props.AuthDetail}
                        mainSaveButton={mainSaveButton}
                        notiSettingValues={notiSettingValues}
                        onChangeNotiSetting={onChangeNotiSetting}
                        incidentStatus={incidentStatus}
                        setIncidentStatus={setIncidentStatus}
                        previousIncident={previousIncident}
                        checkStatus={checkStatus}
                        setCheckStatus={setCheckStatus}
                        customModalForConfirmCancel={
                          customModalForConfirmCancel
                        }
                        valueChanged={valueChanged}
                        onListenValueChange={onListenValueChange}
                        showNotiPage={showNotiPage}
                      />
                    </>
                  ) : detailSelected == "Post-Incident Follow-Up" ? (
                    <PostIncident
                      overViewValues={overViewValues}
                      AuthDetail={props.AuthDetail}
                      rootCauseValues={rootCauseValues}
                      mainSaveButton={mainSaveButton}
                      onChangeRootCauseAction={onChangeRootCauseAction}
                      RootCauseError={{
                        unsafe_act_Error,
                        unsafe_condition_Error,
                      }}
                      optionsState={{
                        unsafeActList,
                        setUnsafeActList,
                        unsafeConditionList,
                        setUnsafeConditionList,
                      }}
                      incidentStatus={incidentStatus}
                      setIncidentStatus={setIncidentStatus}
                      checkStatus={checkStatus}
                      setCheckStatus={setCheckStatus}
                      onListenValueChange={onListenValueChange}
                      previousIncident={previousIncident}
                      customModalForConfirmCancel={customModalForConfirmCancel}
                      valueChanged={valueChanged}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* Right Side */}

          {/* {!showCasualtyForm && !showWitnessForm && (
     <div className="summary">{Summary()}</div>
   )} */}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  incidentList: state.incident.incidentLists,
  searchincidentListState: state.incident.incidentListForSearch,
  AuthDetail: state.loggedInUserDetail.userDetail,
  subDeparts: state.incident.subDeparts,
  showZoneDropDown: state.incident.showZoneDropDown,
  state: state.incident,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllSubDepartment: () => dispatch(fetchAllSubDepartment()),
  showShowZoneList: () => dispatch(showShowZoneList()),
  hideShowZoneList: () => dispatch(hideShowZoneList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
