import React, { useState, useEffect } from "react";

import { Checkbox, Input, Button } from "semantic-ui-react";
import Incident_detail_dropdown from "../../../../components/incident_detail_dropdown";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../routes";
import { useHistory } from "react-router-dom";
import TypeList from "../../../../components/TypeList";
import { db } from "../../../../config/firebase";

//css
import "./cases.css";
import PlaceList from "../../../../components/PlaceList";
import { confirmAlert } from "react-confirm-alert";

function PropertyDamageDetails(props) {
  let history = useHistory();
  const values = props.propertyValues;
  const onChangeIncidentProperty = props.onChangeIncidentProperty;
  const {
    propertyInputError,
    damagesError,
    partyError,
    proxityError,
    damagesErrorTxt,
    partyErrorTxt,
    partyTypeError,
    partyTypeErrorTxt,
  } = props.propertyError;

  const { priorityList, setPriorityList } = props.optionsState;

  useEffect(() => {
    // fetch priority
    db.collection("static_data")
      .doc("priority_of_fix")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().priority_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setPriorityList(data);
      });
  }, []);

  return (
    <div className="incident_overview_container">
      {/* Type of property */}
      <h3 className={`dropdown_title ${propertyInputError && "error"}`}>
        Type of Property{" "}
      </h3>
      <Input
        fluid
        className={`damaged_input ${propertyInputError && "error"}`}
        value={values.property_type}
        onKeyPress={(event) => {
          if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        onChange={(e) => {
          onChangeIncidentProperty("property_type", e.target.value);
        }}
      />
      {/* <TypeList
        custom_classes={`incident-place-list  incident-list-dropdown ${
          propertyInputError && "error"
        }`}
        icon_classes="incident-place-list-icon"
        value={values.property_type}
        onChange={(e, data) => {
          onChangeIncidentProperty("property_type", data.value);
        }}
      /> */}
      {propertyInputError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          Required field
        </div>
      )}
      {/* What is/are damaged   */}
      <div style={{ marginBottom: 30, marginTop: 30 }}>
        <h3 style={{ color: damagesError && "#E72D35" }}>
          What is/are damaged
        </h3>
        <Input
          fluid
          className={`damaged_input ${damagesError && "error"}`}
          value={values.damaged_things}
          onKeyPress={(event) => {
            if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            onChangeIncidentProperty("damaged_things", e.target.value);
          }}
        />
      </div>
      {damagesError && (
        <div
          style={{
            color: "#E72D35",
            marginLeft: 3,
            fontSize: 13,
            marginTop: -25,
            marginBottom: 15,
          }}
        >
          {damagesErrorTxt}
        </div>
      )}
      {/* CheckBox   */}
      <div style={{ marginBottom: 30 }}>
        <h3 className={`dropdown_title ${partyTypeError && "error"}`}>
          Did the incident involved internal or external party?{" "}
        </h3>
        <Input
          fluid
          className={`damaged_input ${partyTypeError && "error"}`}
          value={values.party_type}
          onKeyPress={(event) => {
            if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            onChangeIncidentProperty("party_type", e.target.value);
          }}
        />

        {/* <div>
          <Checkbox
            radio
            label="Internal"
            checked={values.party_type == "internal"}
            onChange={() => onChangeIncidentProperty("party_type", "internal")}
            className="checkBox"
          />
        </div>
        <div>
          <Checkbox
            radio
            label="External"
            checked={values.party_type == "external"}
            onChange={() => onChangeIncidentProperty("party_type", "external")}
            className="checkBox"
          />
        </div> */}
      </div>
      {partyTypeError && (
        <div
          style={{
            color: "#E72D35",
            marginLeft: 3,
            fontSize: 13,
            marginTop: -25,
            marginBottom: 15,
          }}
        >
          {partyTypeErrorTxt}
        </div>
      )}
      {/* Information of internal / external party  */}
      <div>
        <h3 style={{ color: partyError && "#E72D35" }}>
          Information of internal / external party
        </h3>
        <Input
          fluid
          className={`damaged_input ${partyError && "error"}`}
          value={values.party_info}
          onKeyPress={(event) => {
            if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            onChangeIncidentProperty("party_info", e.target.value);
          }}
        />
      </div>
      {partyError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          {partyErrorTxt}
        </div>
      )}

      {/* Priority of fix */}
      <h3 className={`dropdown_title ${proxityError && "error"}`}>
        Priority of Fix{" "}
      </h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown ${
          proxityError && "error"
        }`}
        options={priorityList}
        icon_classes="incident-place-list-icon"
        value={values.priority_of_fix}
        onChange={(e, data) => {
          onChangeIncidentProperty("priority_of_fix", data.value);
        }}
      />
      {proxityError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          Required field
        </div>
      )}
      {/* Footer */}
      <div
        className="incident_footer"
        style={{
          bottom: -65,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          {props.overViewValues.is_emergency_incident == "Yes" ||
          props.showNotiPage ? (
            <div
              onClick={() => {
                props.setCheckStatus("Yes");
                props.mainSaveButton();
              }}
            >
              <Button className="witness_cancel_button">Submit </Button>
            </div>
          ) : null}
          {props.paramPendingBool && (
            <div>
              <Button
                className="incident_cancel_button"
                onClick={props.deletePendingItem}
              >
                Delete
              </Button>
            </div>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              if (props.changedValue) {
                return confirmAlert({
                  customUI: ({ onClose }) =>
                    props.customModalForConfirmCancel(onClose),
                });
              } else {
                history.push("/incidents_list");
              }
            }}
          >
            <Button className="witness_cancel_button">Cancel</Button>
          </div>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              props.mainSaveButton();
            }}
          >
            <Button className="witness_save_button">Save </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyDamageDetails;
