const api = "https://asia-southeast2-mandaiims.cloudfunctions.net";

const config = {
  url: "https://asia-southeast2-mandaiims.cloudfunctions.net",
  SECRET_CODE: "b65bc479a634_572e7b3f7",
  api_endpoint: {
    GET_INCIDENTS: "/incidents/get_all_incidents/",
    GET_SUB_DEPARTMENT: "/incidents/get_noti_subdepart_list/",
    GET_ONE_INCIDENT: "/incidents/get_incident_details/",
    GET_AUDIT_INCIDENTS: "/incidents/get_incident_audit_trails/",
    CREATE_AUDIT_INCIDENTS: "/incidents/create_incident_audit_trail/",
    CREATE_INCIDENTS: "/incidents/create_incident/",
    UPDATE_INCIDENTS: "/incidents/update_incident_details/",
    GET_USERS: "/user/get_all_users/",
    GET_USER_DETAILS: "/user/user/",
    SAVE_USER: "/user/user/",
    CHANGE_PASSWORD: "/user/change_password/",
    FORGOT_PASSWORD: "/user/forget_password/",
    SAVE_USER_AUDIT: "/user/create_user_audit_trail/",
    GET_USER_AUDIT: "/user/get_user_audit_trails/",
    SETTINGS: "/user/setting/",
    SEND_OTP: "/user/send_otp/",
    SEND_EMAIL: "/user/send_email/",
    CHECK_OTP: "/user/check_otp/",
    STORE_DEVICE_TOKEN: "/push_notification/store_device_token/",
    REMOVE_TOKEN: "/push_notification/remove_device_token/",
    GET_ALL_GROUPS: "/user/get_all_groups/",
    GET_ALL_GP_USERS: "/user/get_all_users/",
    GET_ALL_NOTI_USERS: "/user/get_all_notification_users/",
    GET_PUSH_MESSAGES: "/incidents/get_push_messages/",
    REMOVE_NOTI: "/incidents/delete_push_message/",
    READ_NOTI: "/incidents/have_read_message/",
    GET_SUB_NOTIFICATIONS: "/incidents/all_notification_subdeparts/",
    GET_ALL_SUB_DEPARTMENT: "/incidents/get_all_subdeparts/",
    FILTER_PUSH_MESSAGES: "/incidents/filter_push_messages/",
    GET_DASHBOARD_COUNT: "/dashboard/incident_info/",
    UNLOCK_USER: "/user/unlock_user/",
    GET_NOTIS_READ: "/incidents/has_new_push_message/",
    SEARCH_NOTI_USERS: "/incidents/search_notification_user/",
    HAVE_VIEWED_INCIDENT: "/incidents/have_viewed_incident/",
  },
};

export default config;
