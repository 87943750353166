import React from "react";
import { Input, Button } from "semantic-ui-react";
import Incident_detail_dropdown from "../../../../../components/incident_detail_dropdown";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import YearList from "../../../../../components/YearList";

// css
import "../cases/cases.css";

function MedicalStatus(props) {
  const values = props.medicalValues;
  const onChangeMedicalStatusAction = props.onChangeMedicalStatusAction;
  const {
    hospital_name_Error,
    medical_days_Error,
    hospitalize_days_Error,
    diagnosis_Error,
  } = props.MedicalStatusError;
  let history = useHistory();
  return (
    <div className="incident_overview_container">
      <div style={{ marginBottom: 30 }}>
        <h3 style={{ color: hospital_name_Error && "#E72D35" }}>
          Name of clinic / hospital{" "}
        </h3>
        <Input
          fluid
          className={`damaged_input {${hospital_name_Error && "error"}}`}
          value={values.hospital_name}
          onChange={(e, data) =>
            onChangeMedicalStatusAction("hospital_name", data.value)
          }
        />
      </div>
      {hospital_name_Error && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a selection
        </div>
      )}
      {/* Medical leave (days)  */}
      <h3
        style={{ color: medical_days_Error && "#E72D35" }}
        className="dropdown_title"
      >
        Medical leave (days){" "}
      </h3>
      <YearList
        custom_classes={`incident-place-list  incident-list-dropdown {${
          medical_days_Error && "error"
        }}`}
        icon_classes="incident-place-list-icon"
        value={values.medical_days}
        onChange={(e, data) =>
          onChangeMedicalStatusAction("medical_days", data.value)
        }
      />
      {medical_days_Error && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a selection
        </div>
      )}
      {/* Hospitalisation leave (days )  */}
      <h3
        style={{ color: hospitalize_days_Error && "#E72D35" }}
        className="dropdown_title"
      >
        Hospitalisation leave (day ){" "}
      </h3>
      <YearList
        custom_classes={`incident-place-list  incident-list-dropdown {${
          hospitalize_days_Error && "error"
        }}`}
        icon_classes="incident-place-list-icon"
        value={values.hospitalize_days}
        onChange={(e, data) =>
          onChangeMedicalStatusAction("hospitalize_days", data.value)
        }
      />
      {hospitalize_days_Error && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a selection
        </div>
      )}

      <div style={{ marginBottom: 30, marginTop: 30 }}>
        <h3 style={{ color: diagnosis_Error && "#E72D35" }}>Diagnosis </h3>
        <Input
          fluid
          className="damaged_input"
          value={values.diagnosis}
          onChange={(e, data) =>
            onChangeMedicalStatusAction("diagnosis", data.value)
          }
        />
      </div>
      {diagnosis_Error && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a selection
        </div>
      )}

      {/* Footer */}
      <div className="incident_footer" style={{ bottom: 0 }}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }} onClick={() => {}}>
            <Button className="incident_cancel_button">Archive</Button>
          </div>
          <div style={{ width: "100%" }}>
            <Button className="incident_save_button">Closed </Button>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="view_audit"
            onClick={() => history.push(INCIDENTS_AUDIT_ROUTE)}
          >
            View Audit Trail
          </div>
          <div style={{ width: "100%" }} onClick={() => {}}>
            <Button className="witness_cancel_button">Cancel</Button>
          </div>
          <div style={{ width: "100%" }} onClick={props.mainSaveButton}>
            <Button className="witness_save_button">Save </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedicalStatus;
