import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

//images
import ArrowDownDropDownIcon from "../assets/images/incident/arrowdowndrop.svg";

function RoleList(obj) {
  // later need to retrieve
  let roles = [
    {
      key: "Junior User",
      text: "Junior User",
      value: "Junior User",
    },
    {
      key: "Senior User",
      text: "Senior User",
      value: "Senior User",
    },
    {
      key: "Super User",
      text: "Super User",
      value: "Super User",
    },
    {
      key: "Admin",
      text: "Admin",
      value: "Admin",
    },
    {
      key: "Super Admin",
      text: "Super Admin",
      value: "Super Admin",
    },
  ];

  return (
    <>
      <Dropdown
        // search
        selection
        options={obj.options && obj.options.length > 0 ? obj.options : roles}
        value={obj["value"]}
        // defaultValue={obj["value"]}
        icon={
          <img src={ArrowDownDropDownIcon} className={obj["icon_classes"]} />
        }
        className={obj["custom_classes"]}
        onChange={obj["onChange"]}
        disabled={obj.profileBool}
      />
    </>
  );
}

export default RoleList;
