import React, { useState } from "react";
import CategoryOneIcon from "../../../../../assets/images/incident/category1.svg";
import IncidentUpdateForm from "./forms/IncidentUpdateForm";
import { Button, Loader } from "semantic-ui-react";
import {
  INCIDENTS_LIST_ROUTE,
  INCIDENTS_AUDIT_ROUTE,
} from "../../../../../routes";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import DraftFooter from "../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../components/ReopenFooter";
import { Lightbox } from "react-modal-image";
import { useMediaQuery } from "react-responsive";
//css
import "reactjs-popup/dist/index.css";

//images
import ExitIcon from "../../../../../assets/images/incident/exit.svg";
import ClosedFooter from "../../../../../components/ClosedFooter";
import GalleryIcon from "../../../../../assets/images/incident/gallery.svg";

import moment from "moment";

function IncidentUpdate(props) {
  const incidentStatus = props.incidentStatus;
  const [showIncidentUpdateForm, setShowIncidentUpdateForm] = useState(false);
  let history = useHistory();
  const [images, setImages] = useState([]);
  const [imageModal, setImageModal] = useState(false);
  const [imageModalFile, setImageModalFile] = useState("");
  const [imageModalUrl, setImageModalUrl] = useState(false);
  const [imageModalFileUrl, setImageModalFileUrl] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [loader, setLoader] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [remark, setRemark] = useState("");
  const hideUpdateForm = () => setShowIncidentUpdateForm(false);
  const showUpdateForm = () => setShowIncidentUpdateForm(true);

  function Navigation() {
    return (
      <>
        {incidentStatus == "Draft" ? (
          <DraftFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Pending Approval" ? (
          <PendingApprovalFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
            authDetail={props.AuthDetail}
            created_by={props.previousIncident.created_by}
          />
        ) : incidentStatus == "Approve" ? (
          <PendingRejectFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Closed" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            onListenValueChange={props.onListenValueChange}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Open" ? (
          <ClosedFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Archived" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            isArchived={true}
            showNotiPage={props.showNotiPage}
            onListenValueChange={props.onListenValueChange}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : (
          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  let filterP4 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4");
  let filterP4A =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4a");
  let filterP5 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p5");

  let filterP6 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p6");
  let filterP7 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p7");
  let filterP15 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p15");
  const closedIncidentBoxPop = () => {
    return (
      <Popup
        modal
        trigger={
          <div style={{ width: "100%" }}>
            <Button className="incident_save_button">Closed </Button>
          </div>
        }
        width="200px"
        className="in_update_pop"
      >
        {(close) => {
          return (
            <>
              <div style={{ padding: 10 }}>
                <div
                  onClick={close}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <img src={ExitIcon} />
                </div>
                <p
                  style={{
                    margin: "10px 0px",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  You have chosen to close this incident. Please update the
                  remark before you close.
                </p>
                <div>
                  <h3>Remark</h3>
                  <textarea
                    className="remark_text_area"
                    style={{ marginBottom: 20, height: 100 }}
                  ></textarea>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "99%" }} onClick={close}>
                    <Button className="update_incident_cancel_button">
                      Cancel
                    </Button>
                  </div>
                  <div style={{ width: "99%" }} onClick={() => {}}>
                    <Button className="closed_incident_button">
                      Closed Incident
                    </Button>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Popup>
    );
  };

  const writeUpdateModal = () => {
    return (
      <div
        onClick={() => {
          props.setIncidentUpImg([]);

          setIsUpdate(false);
          props.setShowModalForIncidentUpdate(false);
        }}
        className="suspendAlertBox"
        style={{
          zIndex: 5,
        }}
      >
        <div
          style={{
            width: isMobile ? "100%" : 630,
            minHeight: 200,
            maxHeight: 500,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
            overflowY: "scroll",
            zIndex: 1,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{
              // display: "flex",
              // justifyContent: "flex-end",
              position: "absolute",
              right: 10,
              zIndex: 1,
            }}
          >
            <img
              src={ExitIcon}
              onClick={() => {
                props.setIncidentUpImg([]);
                props.setShowModalForIncidentUpdate(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            className="incident_overview_container"
            style={{ paddingTop: 20 }}
          >
            <div className="in_update_form_container">
              <div className="title">Photo</div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: 5,
                }}
              >
                {console.log(props.incidentUpImg)}
                {props.incidentUpImg &&
                  props.incidentUpImg.map((i) => {
                    return (
                      <>
                        <div
                          style={{
                            width: 180,
                            height: 150,
                            marginRight: 10,
                            marginBottom: 10,
                          }}
                        >
                          {i && (
                            <img
                              src={
                                i.type
                                  ? URL.createObjectURL(i)
                                  : i.includes("http")
                                  ? i
                                  : URL.createObjectURL(i)
                              }
                              width="180px"
                              height="150px"
                              style={{
                                borderRadius: 12,
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setImageModal(true);
                                setImageModalFile(i);
                              }}
                            />
                          )}

                          {/*
                        <img
                          src={i.img}
                          width="180px"
                          height="150px"
                          style={{ borderRadius: 12 }}
                        /> */}
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="upload_image">
                <img src={GalleryIcon} />
                <input
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  onChange={(e) => {
                    let type = e.target.files[0].type
                      ? e.target.files[0].type
                      : "";
                    if (
                      type.includes("jpg") ||
                      type.includes("jpeg") ||
                      type.includes("png")
                    ) {
                      if (e.target.files[0]) {
                        props.setIncidentUpImg([
                          ...props.incidentUpImg,

                          e.target.files[0],
                        ]);
                      }
                    } else {
                      alert("Only allowed Png, Jpeg, jpg");
                    }
                    // setImages([...images, { imglocal: e.target.files[0] }]);
                  }}
                  style={{
                    position: "absolute",
                    opacity: 0,
                    cursor: "pointer",
                  }}
                />
                <span style={{ marginLeft: 8 }}>Upload image</span>
              </div>
              <div>
                <h3>Remark</h3>
                {console.log(props.incidentUpRemark)}
                <textarea
                  value={props.incidentUpRemark}
                  className="remark_text_area"
                  style={{ marginBottom: 50 }}
                  onChange={(e) => props.setIncidentUpRemark(e.target.value)}
                ></textarea>
              </div>

              {/* Footer */}
              <div className="incident_update_footer">
                <div style={{ display: "flex" }}>
                  <div
                    style={{ width: "100%" }}
                    onClick={() => {
                      props.setIncidentUpImg([]);
                      setIsUpdate(false);
                      props.setShowModalForIncidentUpdate(false);
                    }}
                  >
                    <Button className="witness_cancel_button">Cancel</Button>
                  </div>
                  <div
                    style={{ width: "100%" }}
                    onClick={() =>
                      props.createIncidentUpdate(isUpdate, updateData)
                    }
                  >
                    {props.incidentUpdateLoader ? (
                      <div
                        className="witness_save_button"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader active size="mini" inline="right" inverted />
                      </div>
                    ) : (
                      <Button className="witness_save_button">Save </Button>
                    )}
                    {/* props.incidentUpdateLoader */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const localImagePreviewModal = () => {
    return (
      <div
        onClick={() => setImageModal(false)}
        className="suspendAlertBox"
        style={{
          zIndex: 6,
        }}
      >
        <div
          style={{
            // display: "flex",
            // justifyContent: "flex-end",
            position: "absolute",

            zIndex: 10,
            background: "rgba(0,0,0,0.8)",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: 30,
          }}
        >
          <div
            style={{
              color: "#fff",
              fontSize: 25,
              cursor: "pointer",
              marginRight: 10,
            }}
            onClick={() => {
              setImageModal(false);
            }}
          >
            X
          </div>
          {/* <img
            src={ExitIcon}
            onClick={() => {
              setImageModal(false);
            }}
            style={{ cursor: "pointer" }}
          /> */}
        </div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
            minWidth: 500,
            minHeight: 500,
            zIndex: 6,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src={URL.createObjectURL(imageModalFile)}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          ></img>
        </div>
      </div>
    );
  };

  const previousIncident = props.previousIncident;
  const incidentUpdateList = props.previousIncident.incident_updates;

  return (
    <>
      {imageModal && localImagePreviewModal()}

      {imageModalUrl && (
        <Lightbox
          medium={imageModalFileUrl}
          large={imageModalFileUrl}
          onClose={() => setImageModalUrl(false)}
        />
      )}
      {props.showModalForIncidentUpdate && writeUpdateModal()}
      {!showIncidentUpdateForm ? (
        <div className="incident_overview_container">
          {/* Content Box */}

          <div className="incident_update_detail">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {previousIncident.case_details.incident_description
                .incident_type ? (
                <div className="in_update_security">
                  {
                    previousIncident.case_details.incident_description
                      .incident_type
                  }
                </div>
              ) : (
                <div></div>
              )}

              <div className="in_update_code">
                {previousIncident.incident_id}
              </div>
            </div>
            <h1 className="in_update_title">
              {" "}
              {previousIncident.filtered_park}
            </h1>
            <div style={{ display: "flex", alignItems: "center" }}>
              {previousIncident.filtered_park && (
                <div className="in_update_area">
                  {" "}
                  {previousIncident.filtered_park}
                </div>
              )}

              {previousIncident.filtered_zone_type && (
                <div className="in_update_area">
                  {" "}
                  {previousIncident.filtered_zone_type}
                </div>
              )}

              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 12,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: 14,
                  textTransform: "uppercase",
                  background:
                    previousIncident.filtered_category == "c1"
                      ? "#EFFFF2"
                      : previousIncident.filtered_category == "c2"
                      ? "#FFF7CC"
                      : previousIncident.filtered_category == "c3"
                      ? "#FFD6B0"
                      : previousIncident.filtered_category == "c4"
                      ? "#FFB4B4"
                      : null,
                  color:
                    previousIncident.filtered_category == "c1"
                      ? "#42BE5C"
                      : previousIncident.filtered_category == "c2"
                      ? "#ECBB00"
                      : previousIncident.filtered_category == "c3"
                      ? "#FF8A62"
                      : previousIncident.filtered_category == "c4"
                      ? "#E85757"
                      : null,
                  border:
                    previousIncident.filtered_category == "c1"
                      ? "1px #42BE5C solid "
                      : previousIncident.filtered_category == "c2"
                      ? "1px #ECBB00 solid "
                      : previousIncident.filtered_category == "c3"
                      ? "1px #FF8A62 solid "
                      : previousIncident.filtered_category == "c4"
                      ? "1px #E85757 solid "
                      : null,
                }}
              >
                {previousIncident.filtered_category}

                {/* <img src={CategoryOneIcon} /> */}
              </div>
            </div>
            <div className="in_update_timestamp">
              {moment
                .utc(previousIncident.latest_updated_at)
                .local("SG")
                .format("D MMM YYYY,")}
              {"  "}
              {moment
                .utc(previousIncident.latest_updated_at)
                .local("SG")
                .format("HH:mm")}
            </div>
            <div className="in_update_image_container">
              {previousIncident.case_details.photo_video.map((img) => (
                <div
                  style={{
                    width: 250,
                    height: 180,
                    marginRight: 10,
                    marginBottom: 10,
                  }}
                >
                  {img.toLowerCase().includes("mp4") ? (
                    <video
                      src={img}
                      controls
                      style={{
                        width: "250px",
                        height: "180px",
                        borderRadius: 12,
                      }}
                      playsinline
                    />
                  ) : (
                    <img
                      src={img}
                      width="250px"
                      height="180px"
                      style={{ borderRadius: 12 }}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="in_update_placement">
              <div className="header">Placement:</div>
              <div>{previousIncident.filtered_incident_placement}</div>
            </div>
            <div className="in_update_placement">
              <div className="header">Classification:</div>
              <div>
                {
                  previousIncident.case_details.incident_description
                    .incident_classification
                }
              </div>
            </div>
            {console.log("previousIncident", previousIncident)}
            <div className="in_update_placement">
              <div className="header">Casualty :</div>
              <div>
                {previousIncident.case_details.person_involved_details.length}
              </div>
            </div>
            {/* <div className="in_update_placement">
              <div className="header">Injuries:</div>
              <div>
                {previousIncident.filtered_incident_placement}</div>
            </div> */}
            {/* <div className="in_update_detail">Details</div> */}
          </div>

          {/* End Cotent Box */}

          {/* Update Container */}
          <div
            className="incident_update_form"
            style={{ marginLeft: isMobile && 5, marginRight: isMobile && 5 }}
          >
            <div className="in_update_header">
              <div className="title">Updates</div>
              <div
                className="btn"
                onClick={() => {
                  //  showUpdateForm()
                  props.setIncidentUpRemark("");
                  props.setIncidentUpImg([]);
                  setIsUpdate(false);
                  props.setShowModalForIncidentUpdate(true);
                }}
              >
                Click to add an update
              </div>
            </div>
            <div className="in_update_reason">
              {previousIncident.incident_updates.map((_, index) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "0.5px solid #ceced2",
                  }}
                >
                  <div
                    className="in_update_reason_item"
                    style={{
                      width: "70%",
                      borderBottom: "none",
                    }}
                  >
                    <div className="title" style={{ margin: "10px 0px" }}>
                      {_.remark}
                    </div>
                    {_.img_url.length > 0 && (
                      <>
                        {_.img_url.map((i) => (
                          <img
                            src={i}
                            width="250px"
                            height="180px"
                            style={{
                              borderRadius: 12,
                              margin: "10px",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setImageModalUrl(true);
                              setImageModalFileUrl(i);
                            }}
                          />
                        ))}
                      </>
                    )}
                    <div className="submit_by">
                      Submitted by{" "}
                      <span className="name">{_.submitted_by}</span>
                      {""}
                      <span
                        style={{
                          marginLeft: -5,
                        }}
                      >
                        on
                      </span>
                      {"   "}
                      {/* {_.submitted_at} */}
                      {moment
                        .utc(_.submitted_at)
                        .local("SG")
                        .format("D MMM YYYY,")}
                      {"  "}
                      {moment.utc(_.submitted_at).local("SG").format("HH:mm")}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      textAlign: "center",
                      width: "30%",
                    }}
                  >
                    <div
                      style={{
                        width: "48%",
                        border: "1px solid #008237",
                        borderRadius: "100px",
                        padding: "5px 0px",
                        fontFamily:
                          "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                        color: "rgb(0, 130, 55)",
                        fontStyle: "normal",
                        lineHeight: "1em",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        props.setIncidentUpRemark(_.remark);
                        props.setIncidentUpImg((old) => [...old, ..._.img_url]);
                        console.log("data", ..._.img_url);
                        setIsUpdate(true);
                        setUpdateData(_);
                        props.setShowModalForIncidentUpdate(true);
                      }}
                    >
                      Edit
                    </div>
                    <div
                      style={{
                        width: "48%",
                        border: "1px solid #e72d35",
                        background: "#e72d35",
                        borderRadius: "100px",
                        padding: "5px 0px",
                        fontFamily:
                          "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                        color: "white",
                        fontStyle: "normal",
                        lineHeight: "1em",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => props.DeleteIncidentUpdate(_)}
                    >
                      Delete
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* End Update Container */}
          {/* Footer */}
          {filterP4A &&
            previousIncident &&
            previousIncident.filtered_category == "c1" &&
            previousIncident.created_by == props.AuthDetail.id && (
              <>{Navigation()}</>
            )}

          {filterP4 &&
            previousIncident &&
            previousIncident.filtered_category == "c1" && <>{Navigation()}</>}
          {filterP5 &&
            previousIncident &&
            previousIncident.filtered_category == "c2" && <>{Navigation()}</>}
          {filterP5 &&
            previousIncident &&
            previousIncident.filtered_category == "c3" && <>{Navigation()}</>}
          {filterP5 &&
            previousIncident &&
            previousIncident.filtered_category == "c4" && <>{Navigation()}</>}
          {previousIncident && previousIncident.filtered_category == "" ? (
            <>
              {filterP4 && previousIncident && <>{Navigation()}</>}
              {filterP5 && previousIncident && <>{Navigation()}</>}
              {filterP5 && previousIncident && <>{Navigation()}</>}
              {filterP5 && previousIncident && <>{Navigation()}</>}
            </>
          ) : null}

          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end", zIndex: 1 }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <IncidentUpdateForm
          hideUpdateForm={hideUpdateForm}
          mainSaveButton={props.mainSaveButton}
          AuthDetail={props.AuthDetail}
          setIncidentUpRemark={props.setIncidentUpRemark}
          setIncidentUpImg={props.setIncidentUpImg}
          incidentUpImg={props.incidentUpImg}
        />
      )}
    </>
  );
}

export default IncidentUpdate;
