import React, { useEffect, useState, useRef } from "react";
import { Input, Loader } from "semantic-ui-react";
import config from "../../config/config";
import api from "../../config/api";
import Cryptr from "cryptr";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import ArrowLeftIcon from "../../assets/images/incident/leftarrow.svg";

function ResendOtp() {
  const [otp, setOtp] = useState("");
  const [decOtp, setDecOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [showResetBtn, setShowResetBtn] = useState(false);
  const [fetchexpireTime, setFetchExpireTime] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [message, setMessage] = useState("");
  const { SEND_OTP, CHECK_OTP, SETTINGS } = config.api_endpoint;
  const { SECRET_CODE, url } = config;

  let interval;
  const countDownAction = () => {
    interval = setInterval(() => {
      clearInterval(interval);

      if (seconds === 0) {
        if (minutes !== 0) {
          setSeconds(59);
          setMinutes(minutes - 1);
        } else {
          setDecOtp("");
          setShowResetBtn(true);
          setMessage("");
        }
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  };
  useEffect(() => {
    axios
      .get(url + SETTINGS)
      .then((res) => {
        console.log("ggsecond ", res);
        if (res.data.message == "Success") {
          localStorage.setItem("life_time", res.data.data.otp_life_time);
          setMinutes(res.data.data.otp_life_time);
          setSeconds(0);
          setFetchExpireTime(true);
        }
      })
      .catch((err) => console.log("err", err));
  }, []);

  useEffect(() => {
    if (fetchexpireTime) {
      countDownAction();
    }
  }, [seconds, minutes, fetchexpireTime]);
  const timerMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const timerSeconds = seconds < 10 ? `0${seconds}` : seconds;

  const history = useHistory();

  const cryptr = new Cryptr(SECRET_CODE);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  let email = query.get("email");

  useEffect(() => {
    setLoading(true);
    // const enot = localStorage.getItem("enot");
    // if (enot) {
    //   const decryptedString = cryptr.decrypt(enot);
    //   setDecOtp(decryptedString);
    //   setLoading(false);
    // }
    setLoading(false);
  }, []);
  const submitHandle = (e) => {
    if (email.toString().includes("2p")) {
      email = email.replace("2p", "+");
    }

    e.preventDefault();
    setMessage("");
    axios
      .post(url + CHECK_OTP, {
        platform: "web",
        email,
        otp_code: otp,
      })
      .then((res) => {
        console.log("res", res);
        if (res.data.message == "Success") {
          return history.push(`/reset_password?email=${email}`);
        }
        if (res.data.message == "Failed") {
          return setError("Code is invalid. Try again.");
        } else {
          return setError("Code is invalid. Try again.");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    // if (decOtp !== otp) {
    //   return setError("Code is invalid. Try again");
    // } else {
    //   history.push(`/reset_password?email=${email}`);
    // }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("enot");
    };
  }, []);

  const resetOtp = () => {
    setShowResetBtn(false);
    const getMinute = localStorage.getItem("life_time");
    console.log("string", parseInt(getMinute));
    console.log("number)", getMinute);
    if (getMinute) {
      setMinutes(parseInt(getMinute));
    } else {
      setMinutes(1);
    }
    setSeconds(0);
    setMessage("");
    localStorage.removeItem("enot");
    axios
      .post(url + SEND_OTP, {
        platform: "web",
        email,
      })
      .then((res) => {
        setDecOtp(res.data.opt_code);
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    setOtp(otp.replace(/\s/g, ""));
  }, [otp]);

  function AttributeValidationOtp(e) {
    setOtp(e.target.value.replace(/\s/g, ""));
  }

  return (
    <form className="login_form" style={{ zIndex: 9999999 }}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Loader active inline="centered" />
        </div>
      ) : (
        <>
          <div style={{ flex: 1 }}>
            <div
              style={{
                fontSize: 15,
                fontWeight: "bold",
                marginBottom: 30,
                display: "flex",
                flex: 1,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img
                src={ArrowLeftIcon}
                style={{ marginRight: 18 }}
                onClick={() => history.goBack()}
              />
              <span style={{ color: "#008237", fontSize: 18 }}>
                Forgot password
              </span>
            </div>
            {message && (
              <div
                style={{
                  color: "#E72D35",
                  textAlign: "center",
                  marginBottom: 5,
                }}
              >
                {message}
              </div>
            )}

            <div style={{ marginBottom: 30 }}>
              {error ? (
                <div
                  style={{
                    color: "#E72D35",
                    textAlign: "center",
                    marginBottom: 5,
                  }}
                >
                  {error}
                </div>
              ) : null}
              <div
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  marginBottom: 5,
                }}
              >
                Key in the OTP sent to your registered email address..
              </div>
              <Input
                fluid
                className="damaged_input"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                onKeyDown={AttributeValidationOtp}
              />
            </div>
            {showResetBtn ? (
              <div
                style={{
                  color: "#008237",
                  fontSize: 14,
                  marginTop: -10,
                  textDecoration: "underline",
                  fontWeight: 600,
                }}
                onClick={resetOtp}
              >
                Resent OTP
              </div>
            ) : (
              <div>
                {timerMinutes}:{timerSeconds}
              </div>
            )}
          </div>

          <div className="login_btn">
            <button onClick={submitHandle}>Submit</button>
          </div>
        </>
      )}
    </form>
  );
}

export default ResendOtp;
