const incidentActionTypes = {
  FETCH_ALL_INCIDENTS: "FETCH_ALL_INCIDENTS",
  FETCH_ALL_INCIDENTS_SUCCESS: " FETCH_ALL_INCIDENTS_SUCCESS",
  FETCH_ALL_INCIDENTS_FAIL: "FETCH_ALL_INCIDENTS_FAIL",
  FETCH_ALL_INCIDENT_EXPORT: "FETCH_ALL_INCIDENT_EXPORT",
  FETCHING_ALL_INCIDENT_EXPORT: "FETCHING_ALL_INCIDENT_EXPORT",

  FETCH_ALL_INCIDENT_SEARCH: "FETCH_ALL_INCIDENT_SEARCH",
  FETCHING_ALL_INCIDENT_SEARCH: "FETCHING_ALL_INCIDENT_SEARCH",
  FETCH_ALL_INCIDENT_SEARCH_SUCCESS: "FETCH_ALL_INCIDENT_SEARCH_SUCCESS",

  FETCH_ALL_AUDIT_INCIDENT: "FETCH_ALL_AUDIT_INCIDENT",
  FETCH_ALL_AUDIT_INCIDENT_SUCCESS: "FETCH_ALL_AUDIT_INCIDENT_SUCCESS",
  FETCH_ALL_AUDIT_INCIDENT_FAIL: "FETCH_ALL_AUDIT_INCIDENT_FAIL",

  FETCH_SUB_NOTIFCATION: "FETCH_SUB_NOTIFCATION",
  FETCH_SUB_NOTIFCATION_SUCCESS: "FETCH_SUB_NOTIFCATION_SUCCESS",

  FETCH_ALL_SUB_DEPARTMENT: "FETCH_ALL_SUB_DEPARTMENT",
  FETCH_ALL_SUB_DEPARTMENT_SUCCESS: "FETCH_ALL_SUB_DEPARTMENT_SUCCESS",
};

export default incidentActionTypes;
