const userDetailsActionTypes = {
  FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
  FETCH_USER_DETAILS_SUCCESS: "FETCH_USER_DETAILS_SUCCESS",
  FETCH_USER_DETAILS_FAIL: "FETCH_USER_DETAILS_FAIL",

  FETCH_USER_AUDIT: "FETCH_USER_AUDIT",
  FETCH_USER_AUDIT_SUCCESS: "FETCH_USER_AUDIT_SUCCESS",
  FETCH_USER_AUDIT_FAIL: "FETCH_USER_AUDIT_FAIL",
};
export default userDetailsActionTypes;
