import React from "react";
import { Button } from "semantic-ui-react";
import { INCIDENTS_AUDIT_ROUTE } from "../routes";
import { useHistory, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

function PendingRejectFooter(props) {
  let { search } = useLocation();
  let history = useHistory();
  const query = new URLSearchParams(search);
  const paramId = query.get("id");
  let filterP16 = props.perms.some((p) => p == "p16");
  let filterP8ForReject = props.perms.some((p) => p === "p8");
  return (
    <div className="incident_footer" style={{ bottom: 0 }}>
      <div style={{ display: "flex" }}>
        <div
          style={{ width: "100%" }}
          onClick={() => {
            props.setCheckStatus("Closed");
            props.mainSaveButton();
          }}
        >
          <Button className="incident_cancel_button">Closed</Button>
        </div>
        {filterP8ForReject && props.prevCategory != "c1" && (
          <div
            style={{ width: "100%" }}
            onClick={() => {
              props.setCheckStatus("Reject");
              props.mainSaveButton();
            }}
          >
            <Button className="reject_button">Reject we </Button>
          </div>
        )}
        <div
          style={{ width: "100%" }}
          onClick={() => {
            props.setCheckStatus("Reject");
            props.mainSaveButton();
          }}
        >
          <Button className="reject_button">Reject we </Button>
        </div>
        <div
          style={{ width: "100%" }}
          onClick={() => {
            props.setCheckStatus("Archive");
            props.mainSaveButton();
          }}
        >
          <Button className="incident_archive_button">Archive</Button>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {filterP16 && (
          <div
            className="view_audit"
            onClick={() =>
              history.push(`${INCIDENTS_AUDIT_ROUTE}?id=${paramId}`)
            }
          >
            View Audit Trail
          </div>
        )}
        <div style={{ width: "100%" }} onClick={() => {}}>
          <Button
            className="witness_cancel_button"
            onClick={() => {
              if (
                props.valueChanged == null ||
                props.valueChanged == undefined
              ) {
                return history.goBack();
              } else {
                if (Object.values(props.valueChanged)[0] == null) {
                  history.push("/incidents_list");
                  // history.goBack();
                } else {
                  if (Object.values(props.valueChanged).length > 0) {
                    return confirmAlert({
                      customUI: ({ onClose }) =>
                        props.customModalForConfirmCancel(onClose),
                    });
                  } else {
                    history.push("/incidents_list");
                    // history.goBack();
                  }
                }
              }
            }}
          >
            Cancel
          </Button>
        </div>
        <div style={{ width: "100%" }} onClick={props.mainSaveButton}>
          <Button className="witness_save_button">Save </Button>
        </div>
      </div>
    </div>
  );
}

export default PendingRejectFooter;
