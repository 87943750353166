import React, { useState, useEffect } from "react";
import { Table, Pagination, Loader } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { fetchGroupManagement } from "../../store/actions/userGroupManagement";
import { connect } from "react-redux";

//routes
import { VIEW_GROUP_ROUTE } from "../../routes.js";

//css
import "./groupList.css";

const GroupList = (props) => {
  let history = useHistory();
  let filterP14 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p14");

  useEffect(() => {
    if (!filterP14) window.location.href = "/";
    props.fetchGroupManagement();
  }, []);
  return (
    <div style={{ paddingTop: 0 }}>
      <div className="col-45">
        <h2 style={{ color: "#008237" }}>Group List</h2>
      </div>
      {props.gploading ? (
        <Loader active inline="centered" />
      ) : (
        <div className="table_group_list">
          <Table basic>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>
                  <div className="gplist_tableHeader name">
                    <div> Group name</div>
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="3">
                  <div className="gplist_tableHeader description">
                    <div>Description</div>
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="5" textAlign="left">
                  <div className="gplist_tableHeader noofmember">
                    <div> No. of Member</div>
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {props.gpList.map((_, index) => (
                <Table.Row
                  onClick={() => {
                    if (filterP14) {
                      return history.push(`/group_list/detail?id=${_.id}`);
                    } else {
                      return null;
                    }
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Table.Cell className="gp_name_" collapsing>
                    <div stlye={{ width: 500 }}>{_.group_name}</div>
                  </Table.Cell>
                  <Table.Cell colSpan="3">
                    {_.description.length > 70 ? (
                      <>
                        {_.description.toString().slice(1).slice(0, 125)}
                        ...
                      </>
                    ) : (
                      <> {_.description} </>
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing textAlign="left" colSpan="5">
                    {" "}
                    {_.no_of_user}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {/* <Pagination
                activePage={1}
                firstItem={null}
                lastItem={null}
                siblingRange={3}
                floated="right"
                totalPages={5}
              /> */}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  gpList: state.userGroupManagement.gpList,
  gploading: state.userGroupManagement.gploading,
  AuthDetail: state.loggedInUserDetail.userDetail,
});

const mapDispatchToProps = (dispatch) => ({
  fetchGroupManagement: () => dispatch(fetchGroupManagement()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
