import React, { useState, useEffect } from "react";
import {
  Button,
  Loader,
  Dimmer,
  Input,
  Dropdown,
  Table,
} from "semantic-ui-react";
// ../../../assets/images/incident/leftarrow.svg
import LeftArrowIcon from "../../assets/images/incident/leftarrow.svg";
import ArrowDownDropDownIcon from "../../assets/images/incident/arrowdowndrop.svg";
import { useHistory, useLocation } from "react-router-dom";
import api from "../../config/api";
import config from "../../config/config";

const container = {
  marginTop: 30,
  backgroundColor: "#fff",
  marginLeft: 45,
  marginRight: 85,
  width: "93vw",
  minHeight: "68vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 100,
};

function NotificationDetail() {
  const [depart, setDepart] = useState([]);
  const [departList, setDepartList] = useState([]);
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const clsId = query.get("cls_id");
  const cls = query.get("cls");
  const { GET_SUB_DEPARTMENT } = config.api_endpoint;

  useEffect(() => {
    api
      .get(GET_SUB_DEPARTMENT, {
        id: "",
        platform: "web",
        classification: cls,
        classification_id: clsId,
      })
      .then((res) => {
        let data = [];
        res.data.map((d, index) => {
          data.push({
            key: d,
            text: d,
            value: d,
          });
        });
        console.log("res", res);
        setDepartList(res.data);

        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  }, []);

  console.log("loading", loading);
  console.log("departList", departList);
  return (
    <div style={{ height: "100vh", marginBottom: 150 }}>
      {loading ? (
        <div style={{ marginTop: 100 }}>
          <Loader active inline="centered" />
        </div>
      ) : (
        <div>
          <div style={{ padding: 10 }}>
            <div className="detail_header">
              <div
                to=""
                className="header_leftside"
                onClick={() => history.goBack()}
              >
                <img src={LeftArrowIcon} style={{ marginTop: 0 }} />
                <div className="detail_title">Notification setting</div>
              </div>
            </div>
          </div>
          <div className="table_noti_list">
            <Table basic>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell collapsing>
                    <div>S/No</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan="3">
                    <div className="notilist_tableHeader description">
                      <div>Sub Departments(s)</div>
                    </div>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {departList.length > 0 &&
                  departList.map((_, index) => {
                    return (
                      <Table.Row>
                        <Table.Cell className="noti_name_" collapsing>
                          <div stlye={{ width: 50 }}>{index + 1}</div>
                        </Table.Cell>
                        <Table.Cell colSpan="3">{_.name}</Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </div>
          <div
            className="incident_footer"
            style={{
              bottom: -100,
              justifyContent: "flex-end",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                style={{ width: "100%" }}
                onClick={() => {
                  history.goBack();
                }}
              >
                <Button className="witness_cancel_button">Cancel</Button>
              </div>
            </div>
          </div>
        </div>
        // <div style={{ padding: 10 }}>
        //   <div className="detail_header">
        //     <div
        //       to=""
        //       className="header_leftside"
        //       onClick={() => history.goBack()}
        //     >
        //       <img src={LeftArrowIcon} style={{ marginTop: 0 }} />
        //       <div className="detail_title">Notification setting</div>
        //     </div>
        //   </div>

        //   <>
        //     <div style={container}>
        //       <div
        //         style={{
        //           width: 500,
        //           marginTop: -200,
        //           display: "flex",
        //           justifyContent: "left",
        //           flexDirection: "column",
        //         }}
        //       >
        //         {/* <h3 style={{ fontWeight: "bold", fontSize: 23, marginBottom: 30 }}>
        //           Add group{" "}
        //         </h3> */}
        //         <div>
        //           {/* <h3 className="dropdown_title">Group name</h3> */}
        //           {/* <Input
        //             fluid
        //             className="noti_input"

        //             maxLength="100"
        //             disabled={true}
        //           /> */}
        //         </div>
        //         <div style={{ marginTop: 20 }}>
        //           <h3 className="dropdown_title">List of Sub Departments</h3>
        //           <Dropdown
        //             className="incident-place-list  incident-list-dropdown"
        //             style={{ height: "auto" }}
        //             // icon={
        //             //   <img
        //             //     src={ArrowDownDropDownIcon}
        //             //     style={{
        //             //       float: "right",
        //             //       marginTop: "2px",
        //             //       marginRight: "-20px",
        //             //       color: "#008237",
        //             //       cursor: "pointer",
        //             //     }}
        //             //   />
        //             // }
        //             disabled
        //             fluid
        //             multiple
        //             selection
        //             options={depart}
        //             value={departVal}
        //           />
        //         </div>
        //       </div>
        //     </div>
        //     {/* <div
        //       style={{
        //         display: "flex",
        //         justifyContent: "flex-end",
        //         background: "#ffffff",
        //         width: "100vw",
        //         alignItems: "flex-end",
        //         paddingTop: 10,
        //         marginLeft: 45,
        //         marginRight: 45,
        //         paddingRight: 50,
        //         boxShadow: "rgb(0 0 0 / 30%) 0px -6px 6px -6px",
        //         position: "absolute",
        //         left: -45,
        //         bottom: 5,
        //       }}
        //     >
        //       <div>
        //         <Button
        //           className="witness_cancel_button"
        //           onClick={() => history.goBack()}
        //         >
        //           Cancel
        //         </Button>
        //         <Button className="witness_save_button" onClick={() => {}}>
        //           Save{" "}
        //         </Button>
        //       </div>
        //     </div> */}
        //   </>
        // </div>
      )}
    </div>
  );
}

export default NotificationDetail;
