import React from "react";
import { Route } from "react-router";
import {
  NOTIFICATION_List_ROUTE,
  NOTIFICATION_Detail_ROUTE,
} from "../../routes";
import NotifcationList from "./NotifcationList";
import NotificationDetail from "./NotificationDetail";

export const Notification = () => (
  <React.Fragment>
    <Route exact path={NOTIFICATION_List_ROUTE} component={NotifcationList} />
    <Route
      exact
      path={NOTIFICATION_Detail_ROUTE}
      component={NotificationDetail}
    />
  </React.Fragment>
);

export default Notification;
