import React from "react";
import { Button } from "semantic-ui-react";
import { INCIDENTS_AUDIT_ROUTE } from "../routes";
import { useHistory, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import ExitIcon from "../assets/images/incident/exit.svg";

function ClosedFooter(props) {
  let history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramId = query.get("id");
  let filterP6 = props.perms.some((p) => p == "p6");
  let filterP7 = props.perms.some((p) => p == "p7");
  let filterP16 = props.perms.some((p) => p == "p16");

  function customCloseModal(onClose) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Confirm to close
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            Do you really want to close the incident?
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button className="witness_cancel_button" onClick={onClose}>
                Cancel
              </Button>
            </div>
            <div>
              <Button
                className="downloadBtn"
                // style={{ paddingLeft: 20 }}
                onClick={() => {
                  props.setCheckStatus("Closed");
                  props.mainSaveButton();
                  onClose();
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function Cat2To4() {
    return (
      <div
        style={{ width: "100%" }}
        // onClick={() => {
        //   props.setCheckStatus("Closed");
        //   props.mainSaveButton();
        // }}
        onClick={() => {
          return confirmAlert({
            customUI: ({ onClose }) => customCloseModal(onClose),
          });
        }}
      >
        <Button
          className="incident_cancel_button"
          style={{ width: "fit-content" }}
        >
          Close Incident
        </Button>
      </div>
    );
  }

  return (
    <div className="incident_footer" style={{ bottom: 0 }}>
      <div style={{ display: "flex" }}>
        {/* <div
          style={{ width: "100%" }}
          onClick={() => {
            props.setCheckStatus("Archive");
            props.mainSaveButton();
          }}
        >
          <Button className="incident_archive_button">Archive</Button>
        </div> */}
        {/* {props.showNotiPage && (
          <div
            style={{ width: "100%" }}
            onClick={() => {
              props.setCheckStatus(null);
              props.mainSaveButton();
            }}
          >
            <Button className="witness_cancel_button">Submit </Button>
          </div>
        )} */}

        {filterP6 && props.prevCategory == "c1" && Cat2To4()}
        {filterP7 && props.prevCategory == "c2" && Cat2To4()}
        {filterP7 && props.prevCategory == "c3" && Cat2To4()}
        {filterP7 && props.prevCategory == "c4" && Cat2To4()}
      </div>
      <div style={{ display: "flex" }}>
        {filterP16 && (
          <div
            className="view_audit"
            onClick={() =>
              history.push(`${INCIDENTS_AUDIT_ROUTE}?id=${paramId}`)
            }
          >
            View Audit Trail
          </div>
        )}

        <div style={{ width: "100%" }} onClick={() => {}}>
          <Button
            className="witness_cancel_button"
            onClick={() => {
              if (
                props.valueChanged == null ||
                props.valueChanged == undefined
              ) {
                return history.goBack();
              } else {
                if (Object.values(props.valueChanged)[0] == null) {
                  history.push("/incidents_list");
                  // history.goBack();
                } else {
                  if (Object.values(props.valueChanged).length > 0) {
                    return confirmAlert({
                      customUI: ({ onClose }) =>
                        props.customModalForConfirmCancel(onClose),
                    });
                  } else {
                    history.push("/incidents_list");
                    // history.goBack();
                  }
                }
              }
            }}
          >
            Cancel
          </Button>
        </div>
        <div
          style={{ width: "100%" }}
          onClick={() => {
            props.setCheckStatus("noStatus");

            props.mainSaveButton();
          }}
        >
          <Button className="witness_save_button">Save</Button>
        </div>
      </div>
    </div>
  );
}

export default ClosedFooter;
