import React, { useState } from "react";
import { Checkbox, Button } from "semantic-ui-react";

import Incident_detail_dropdown from "../../../../../components/incident_detail_dropdown";
import DatePicker from "react-datepicker";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import ZoneList from "../../../../../components/ZoneList";
import DraftFooter from "../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../components/ReopenFooter";

//images
import CalendarIcon from "../../../../../assets/images/incident/ic_calendar.svg";
import TimeIcon from "../../../../../assets/images/incident/ic_time.svg";

// css
import "../cases/cases.css";
import ClosedFooter from "../../../../../components/ClosedFooter";

function AddationAction(props) {
  const incidentStatus = props.incidentStatus;
  let history = useHistory();
  //  const [checkBox, setCheckBox] = useState(null);
  //  const [selectedDate, setSelectedDate] = useState(null);
  //  const [selectedTime, setSelectedTime] = useState(null);
  const value = props.addationalValues;
  const onChangeAddationAction = props.onChangeAddationAction;
  const {
    ambulance_called_Error,
    time_called_Error,
    arrival_time_Error,
    remark_Error,
    zone_Error,
  } = props.addationalError;

  return (
    <div className="incident_overview_container">
      {" "}
      {/* CheckBox   */}
      <div style={{ marginBottom: 30 }}>
        <h3 style={{ color: ambulance_called_Error && "#E72D35" }}>
          Ambulance / police called
        </h3>
        <div>
          <Checkbox
            radio
            label="Yes"
            checked={value.ambulance_called == "Yes"}
            onChange={() => onChangeAddationAction("ambulance_called", "Yes")}
            className="checkBox"
          />
        </div>
        <div>
          <Checkbox
            radio
            label="No"
            checked={value.ambulance_called == "No"}
            onChange={() => onChangeAddationAction("ambulance_called", "No")}
            className="checkBox"
          />
        </div>
      </div>
      {ambulance_called_Error && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a selection
        </div>
      )}
      {/* Zone */}
      <h3 style={{ color: zone_Error && "#E72D35" }} className="dropdown_title">
        Zone{" "}
      </h3>
      <ZoneList
        custom_classes={`incident-place-list  incident-list-dropdown {${
          zone_Error && "error"
        }`}
        icon_classes="incident-place-list-icon"
        value={value.zone}
        onChange={(e, data) => onChangeAddationAction("zone", data.value)}
      />
      {zone_Error && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a decision
        </div>
      )}
      <div style={{ display: "flex" }}>
        {/* Date */}
        <div style={{ flex: 1 }}>
          <div className="input_container">
            <div
              className="input_title"
              style={{ fontSize: 13, color: time_called_Error && "#E72D35" }}
            >
              Time called
            </div>
            <div className="datePicker">
              <DatePicker
                onChangeRaw={(e) => e.preventDefault()}
                selected={value.time_called}
                onChange={(date) => onChangeAddationAction("time_called", date)}
              />
              <img src={CalendarIcon} />
            </div>
          </div>
          {time_called_Error && (
            <div style={{ color: "#E72D35", marginTop: 15 }}>
              Please make a decision
            </div>
          )}
        </div>

        {/* Time */}
        <div style={{ flex: 1 }}>
          <div className="input_container">
            <div
              className="input_title"
              style={{ fontSize: 13, color: arrival_time_Error && "#E72D35" }}
            >
              Time arrival
            </div>
            <div className="datePicker timePicker">
              <DatePicker
                onChangeRaw={(e) => e.preventDefault()}
                selected={value.arrival_time}
                onChange={(date) =>
                  onChangeAddationAction("arrival_time", date)
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat={"hh:mm:ss"}
              />
              <img src={TimeIcon} />
            </div>
          </div>
          {arrival_time_Error && (
            <div style={{ color: "#E72D35", marginTop: 15 }}>
              Please make a decision
            </div>
          )}
        </div>
      </div>
      <h3 style={{ color: remark_Error && "#E72D35" }}>Remarks</h3>
      <textarea
        className="remark_small_text_area"
        onChange={(e) => onChangeAddationAction("remark", e.target.value)}
        value={value.remark}
      ></textarea>
      {remark_Error && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a decision
        </div>
      )}
      {/* Footer */}
      {incidentStatus == "Draft" ? (
        <DraftFooter
          mainSaveButton={props.mainSaveButton}
          setIncidentStatus={props.setIncidentStatus}
          setCheckStatus={props.setCheckStatus}
        />
      ) : incidentStatus == "Pending Approval" ? (
        <PendingApprovalFooter
          mainSaveButton={props.mainSaveButton}
          setIncidentStatus={props.setIncidentStatus}
          setCheckStatus={props.setCheckStatus}
        />
      ) : incidentStatus == "Approve" ? (
        <PendingRejectFooter
          mainSaveButton={props.mainSaveButton}
          setIncidentStatus={props.setIncidentStatus}
          setCheckStatus={props.setCheckStatus}
        />
      ) : incidentStatus == "Closed" ? (
        <ReopenFooter
          mainSaveButton={props.mainSaveButton}
          setIncidentStatus={props.setIncidentStatus}
          setCheckStatus={props.setCheckStatus}
        />
      ) : incidentStatus == "Open" ? (
        <ClosedFooter
          mainSaveButton={props.mainSaveButton}
          setIncidentStatus={props.setIncidentStatus}
          setCheckStatus={props.setCheckStatus}
        />
      ) : null}
    </div>
  );
}

export default AddationAction;
