import React, { useEffect, useState } from "react";

import {
  Divider,
  Image,
  Menu,
  Label,
  Loader,
  Dimmer,
  Checkbox,
  Button,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import { browserName } from "react-device-detect";
import { messaging, db, Timestamp } from "../config/firebase";
import ExitIcon from "../assets/images/incident/exit.svg";
import TrashIcon from "../assets/images/incident/ic_trash.svg";
import RedDotIcon from "../assets/images/incident/red_dot.svg";
import FilterIcon from "../assets/images/incident/filter.svg";
import SearchIcon from "../assets/images/incident/searchiconbg.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import CategoryOne from "../assets/images/incident/category1.svg";
import CategoryTwo from "../assets/images/incident/category2.svg";
import CategoryThree from "../assets/images/incident/category3.svg";
import CategoryFour from "../assets/images/incident/category4.svg";
import CategoryChecked from "../assets/images/incident/category_checked.svg";
import Category_Question from "../assets/images/incident/category_question.svg";
import Popup from "reactjs-popup";
import { useMediaQuery } from "react-responsive";

import moment from "moment";
import momentTz from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DASHBOARD_ROUTE,
  INCIDENTS_LIST_ROUTE,
  GROUP_LIST_ROUTE,
  INCIDENTS_AUDIT_ROUTE,
  INCIDENTS_DETAIL_ROUTE,
  NEW_INCIDENT_ROUTE,
  USER_LIST_ROUTE,
  USER_DETAIL_ROUTE,
  USER_AUDIT_ROUTE,
  VIEW_GROUP_ROUTE,
  SETTINGS_ROUTE,
  NOTIFICATION_List_ROUTE,
  NOTIFICATION_Detail_ROUTE,
  NEW_USER_ROUTE,
} from "../routes";
import logo from "../assets/images/logo/logo.png";
// import logo3 from "../assets/images/logo/logo2.png";
import logo3 from "../assets/images/logo/logo2.svg";
import LeftArrowIcon from "../assets/images/incident/leftarrow.svg";
import CalendarIcon from "../assets/images/incident/ic_calendar.svg";

import logo2 from "../assets/images/logo/logo.svg";
import MenuItem from "./MenuItem";
import { Redirect } from "react-router-dom";
import Dashboard from "../views/dashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSignOutAlt,
  faTachometerAlt,
  faExclamationTriangle,
  faUsers,
  faBroadcastTower,
  faBell,
  faUserCircle,
  faCog,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { auth } from "../config/firebase";
import { connect } from "react-redux";
import {
  userLogout,
  getUserLoginDetail,
} from "../store/actions/loggedInUserDetailAction";
import { notiRedDot, hideShowZoneList } from "../store/actions/incidentAction";
import api from "../config/api";
import config from "../config/config";
import CategoryList from "./CategoryList";
import PlaceList from "./PlaceList";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";

const Navigation = (props) => {
  const { children, selected, AuthDetail, AuthToken } = props;
  let pathName = window.location.pathname;
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [logoutBool, setLogoutBool] = useState(false);
  const [notiModal, setNotiModal] = useState(false);
  const [notiList, setNotiList] = useState([]);
  const [nextUrl, setNextUrl] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [allRead, setAllRead] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [showSearchData, setShowSearchData] = useState(false);
  const [cacheData, setCacheData] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [category, setCategory] = useState("All");
  const [park, setPark] = useState("All");
  const [parkList, setParkList] = useState([]);
  const [status, setStatus] = useState(1);
  const [fromDate, setFromDate] = useState({
    displayFromDate: "",
    apiFromDate: "",
  });
  const [readAllLoader, setReadAllLoader] = useState(false);
  const [filterMode, setFilterMode] = useState(false);
  const [toDate, setToDate] = useState({
    displayToDate: "",
    apiToDate: "",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [showMobileSetting, setShowMobileSetting] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { url } = config;
  const {
    REMOVE_TOKEN,
    GET_PUSH_MESSAGES,
    REMOVE_NOTI,
    READ_NOTI,
    FILTER_PUSH_MESSAGES,
    GET_NOTIS_READ,
    SAVE_USER_AUDIT,
  } = config.api_endpoint;
  let filterP12 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p12");
  let filterP14 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p14");

  let categoryOptions = [
    {
      value: "c1",
      image: CategoryOne,
    },
    {
      value: "c2",
      image: CategoryTwo,
    },
    {
      value: "c3",
      image: CategoryThree,
    },
    {
      value: "c4",
      image: CategoryFour,
    },
  ];

  const timeRangeAlert = (message) => {
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="incidentAlertBox"
            onClick={onClose}
            style={{ zIndex: 9999 }}
          >
            <div
              style={{
                width: 400,
                height: 200,
                backgroundColor: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "13px 20px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                onClick={() => {
                  onClose();
                }}
              >
                <img src={ExitIcon} />
              </div>
              <div
                style={{ fontSize: 16, fontWeight: "bold", marginBottom: 15 }}
              >
                Confirm to submit
              </div>
              <div style={{ fontSize: 14, marginBottom: 18 }}>{message}</div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <Button
                    className="witness_cancel_button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  useEffect(() => {
    db.collection("static_data")
      .doc("parks")
      .get()
      .then((querySnapshot) => {
        let parkData = [];
        parkData.push({
          key: "All",
          text: "All",
          value: "All",
        });
        querySnapshot.data().parks_arr.forEach((doc, index) => {
          parkData.push({
            key: index,
            text: doc.park,
            value: doc.park,
          });
        });

        // all Zone List

        setParkList(parkData);
      })
      .catch((err) => {
        // setTypeListLoader(false);
        console.log("err", err);
      });
  }, []);

  const logoutHandle = () => {
    setLogoutBool(true);
    api
      .post(
        REMOVE_TOKEN,
        JSON.stringify({
          user_id: AuthDetail.id,
          platform: "web",
          device_token: AuthToken.token,
        })
      )
      .then((res) => {
        api
          .post(
            SAVE_USER_AUDIT,
            JSON.stringify({
              user_id: AuthDetail.id,
              email: AuthDetail.email,
              changed_by: AuthDetail.id,
              description: [`Logout on ${browserName}`],
              platform: "web",
            }),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((res) => {
            window.location.reload();
          })
          .catch((err) => console.log("errors....", err));
        auth.signOut().then(async () => {
          props.userLogout();
          await localStorage.removeItem("authExpire");
          // window.location.href = "/";
          setLogoutBool(false);
        });
      });
  };

  const hideModal = () => {
    setNotiModal(false);
    setFilterModal(false);
  };

  useEffect(() => {}, [cacheData]);

  const removeNoti = (id, sData) => {
    let cloneCacheData = cacheData;
    let cloneSearchData = searchData;
    setCacheData(cloneCacheData.filter((c) => c.id != id));
    setSearchData(cloneSearchData.filter((c) => c.id != id));

    // setSearchData(cloneNotiList);

    setNotiList(notiList.filter((n) => n.id !== id));
    api
      .remove(
        REMOVE_NOTI,
        JSON.stringify({
          user_id: AuthDetail.id,
          id,
          platform: "web",
        })
      )
      .then((res) => {
        if (res.message == "success") {
        }
      })
      .catch((err) => console.log("err", err));
  };

  const readMessage = async (id, sData) => {
    // fetchDataForSearchBar(data);
    if (sData) {
      let cloneCacheData = cacheData;
      let findIndex = cloneCacheData.findIndex((n) => n.id == id);
      if (findIndex != -1) {
        cloneCacheData[findIndex].read_users.push(AuthDetail.id);

        setCacheData(cloneCacheData);
      }
    } else {
      let cloneNoti = notiList;
      let findIndex = notiList.findIndex((n) => n.id == id);
      cloneNoti[findIndex].is_new = false;
      setNotiList(cloneNoti);
    }

    api
      .post(
        READ_NOTI,
        JSON.stringify({
          user_id: AuthDetail.id,
          platform: "web",
          id,
        })
      )
      .then((res) => {
        fetchNotiReadCount();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const readAllMessageHandle = () => {
    setReadAllLoader(true);
    api
      .post(
        READ_NOTI,
        JSON.stringify({
          user_id: AuthDetail.id,
          platform: "web",
          id: "",
        })
      )
      .then((res) => {
        setAllRead(true);
        setReadAllLoader(false);

        fetchNotiReadCount();
      })
      .catch((err) => {
        setReadAllLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {}, [reRender]);

  const searchSubmit = () => {
    if (searchInput.length > 0) {
      // setShowSearchData(true);
      // gg
      // fetchAllPushMessages();
      filterNotifcation();
    } else {
      setShowSearchData(false);
    }
  };

  const notiItemsRender = (noti, sData) => {
    let replaceText = noti.text.replaceAll("_", "  ");

    let read = false;
    if (sData) {
      if (noti.read_users != undefined) {
        if (noti.read_users && noti.read_users.length != 0) {
          const read_user = noti.read_users.filter(
            (usr) => usr === AuthDetail.id
          );
          if (read_user.length === 1) {
            read = true;
          } else {
            read = false;
          }
        } else {
          read = false;
        }
      } else {
        read = false;
      }
    }
    return (
      <div
        className="noti_item"
        onClick={(e) => {
          e.stopPropagation();
          setReRender(!reRender);
          if (noti.is_new || !read) {
            readMessage(noti.id, sData);
            fetchNotiReadCount();
          }
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flex: 1,
          }}
        >
          <div style={{ marginRight: 10 }}>
            {allRead ? (
              <></>
            ) : sData ? (
              <>{!read && <img src={RedDotIcon} />}</>
            ) : (
              <>{noti.is_new && <img src={RedDotIcon} />}</>
            )}
          </div>
          <div>
            <div
              className="noti_text"
              style={{
                fontWeight: "bold",
                color: "#000",
                fontSize: 14,
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (noti.is_new || !read) {
                  readMessage(noti.id, sData);
                  fetchNotiReadCount();
                }
                window.location.href = `/incidents_list/detail?id=${noti.incident_id}`;
                hideModal();
              }}
            >
              {replaceText}
            </div>
            <div
              style={{
                fontSize: "smaller",
                color: "#838383",
              }}
            >
              {sData ? (
                <>
                  {momentTz
                    .tz(
                      new Timestamp(
                        noti.timestamp.seconds,
                        noti.timestamp.nanoseconds
                      ).toDate(),
                      "Asia/Singapore"
                    )
                    .format("D MMM YYYY,")}
                  {"  "}
                  {momentTz
                    .tz(
                      new Timestamp(
                        noti.timestamp.seconds,
                        noti.timestamp.nanoseconds
                      ).toDate(),
                      "Asia/Singapore"
                    )
                    .format("HH:mm:ss")}
                </>
              ) : (
                <>
                  {momentTz
                    .tz(Date.parse(noti.timestamp), "Asia/Singapore")
                    .format("D MMM YYYY,")}
                  {"  "}
                  {momentTz
                    .tz(noti.timestamp, "Asia/Singapore")
                    .format("HH:mm:ss")}
                </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{ marginLeft: 10, cursor: "pointer" }}
          className="noti_trash"
          onClick={(e) => {
            e.stopPropagation();
            return confirmAlert({
              customUI: ({ onClose }) =>
                customModalForConfirmDelete(onClose, noti.id, sData),
            });

            // removeNoti(noti.id, sData);
          }}
        >
          <img src={TrashIcon} />
        </div>
      </div>
    );
  };

  const filterModalForNotis = () => {
    return (
      <div className="filter_noti">
        <div style={{ flex: 1 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={LeftArrowIcon}
              style={{ cursor: "pointer" }}
              onClick={() => setFilterModal(false)}
            />
            <div
              style={{
                fontWeight: "bold",
                marginLeft: 8,
                fontSize: 20,
              }}
            >
              Filter
            </div>
          </div>
          <div>
            <div className="input_title">Park</div>
            <PlaceList
              custom_classes="incident-place-list  incident-list-dropdown"
              icon_classes="incident-place-list-icon"
              onChange={(e, data) => {
                setPark(data.value);
              }}
              value={park}
              options={parkList}
            />
          </div>
          <div style={{ margin: "10px 0px", marginTop: 20 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "8px",
              }}
            >
              <div className="input_title" style={{ marginTop: 0 }}>
                Severity Categories
              </div>
              <Popup
                trigger={
                  <div style={{ marginLeft: 8, marginTop: -5 }}>
                    <img src={Category_Question} />{" "}
                  </div>
                }
                modal
                closeOnDocumentClick={true}
                className="category_info_model-container filter_info_model"
              >
                {(close) => {
                  return categoryInfoModel(close);
                }}
              </Popup>
            </div>

            <div className={`category_options`}>
              {categoryOptions.map((c) => {
                return (
                  <div
                    className="c_option"
                    key={c.value}
                    onClick={() => {
                      setCategory(c.value);
                      // onChangeIncidentOverView("category", c.value);
                      // props.hideShowZoneList();
                      // props.CheckfilledMatodaryField();
                    }}
                  >
                    <img src={c.image} />
                    {category === c.value ? (
                      <div className="c_circle">
                        <img src={CategoryChecked} />
                      </div>
                    ) : (
                      <div className="c_circle"></div>
                    )}
                  </div>
                );
              })}
            </div>

            {/* <div className="input_title">Severity Categories</div>
            <CategoryList
              custom_classes="incident-place-list  incident-list-dropdown"
              icon_classes="incident-place-list-icon"
              // value={category}
              value={category}
              perms={props.AuthDetail.perms && props.AuthDetail.perms}
              onChange={(e, data) => {
                setCategory(data.value);
              }}
            /> */}
          </div>
          <div style={{ marginTop: 25 }}>
            <div>
              <Checkbox
                radio
                label="Read Message"
                checked={status == 1}
                onChange={() => setStatus(1)}
                className="nav_checkBox"
                style={{ marginRight: 15, fontWeight: "bold", fontSize: 18 }}
              />
            </div>
            <div>
              <Checkbox
                radio
                label="Unread Message"
                checked={status == 0}
                style={{ fontWeight: "bold", fontSize: 18 }}
                onChange={() => setStatus(0)}
                className="nav_checkBox"
              />
            </div>
          </div>

          <div style={{ display: "flex", marginTop: -10 }}>
            <div>
              <div className="input_title">Date From</div>
              <div
                className="sidebar_datePicker"
                style={{ margin: "0px 5px 20px 5px" }}
              >
                <DatePicker
                  selected={fromDate.displayFromDate}
                  onChangeRaw={(e) => e.preventDefault()}
                  onChange={(date) => {
                    if (
                      toDate.displayToDate != "" &&
                      date > toDate.displayToDate
                    ) {
                      timeRangeAlert(
                        'Plesae select "To Date" less than "From Date"'
                      );
                      setFromDate({
                        displayFromDate: "",
                        apiFromDate: "",
                      });
                    } else {
                      setFromDate({
                        displayFromDate: date,
                        apiFromDate: moment(date).format("D MMM YYYY"),
                      });
                    }
                  }}
                />
                <img src={CalendarIcon} />
              </div>
            </div>
            <div>
              <div className="input_title">to</div>
              <div
                className="sidebar_datePicker"
                style={{ margin: "0px 5px 20px 5px" }}
              >
                <DatePicker
                  selected={toDate.displayToDate}
                  onChangeRaw={(e) => e.preventDefault()}
                  onChange={(date) => {
                    if (
                      fromDate.displayFromDate != "" &&
                      date < fromDate.displayFromDate
                    ) {
                      timeRangeAlert(
                        'Plesae select "To Date" greater than "From Date"'
                      );
                      setToDate({
                        displayToDate: "",
                        apiToDate: "",
                      });
                    } else {
                      setToDate({
                        displayToDate: date,
                        apiToDate: moment(date).format("D MMM YYYY"),
                      });
                    }
                  }}
                />
                <img src={CalendarIcon} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Button
                className="gg_button"
                style={{ width: 150, fontWeight: "bold", background: "white" }}
                onClick={() => {
                  setFromDate({
                    displayFromDate: "",
                    apiFromDate: "",
                  });
                  setToDate({
                    displayToDate: "",
                    apiToDate: "",
                  });
                  setStatus(1);
                  setIsFilter(false);
                  setCategory("All");
                  setPark("All");
                  setFilterMode(false);
                  setFilterModal(false);
                  fetchAllPushMessages();
                }}
              >
                Reset Filter
              </Button>
            </div>
            <div>
              <Button
                className="witness_save_button"
                style={{ width: 150 }}
                onClick={() => {
                  setIsFilter(true);
                  filterNotifcation(true);
                }}
              >
                Save Filter{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const fetchNotiReadCount = () => {
    api
      .get(GET_NOTIS_READ, {
        platform: "web",
        user_id: props.AuthDetail.id,
      })
      .then((res) => {
        props.notiRedDot(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    fetchNotiReadCount();
  }, []);

  const customModalForConfirmCancel = (onClose) => {
    return (
      <div
        className="incidentAlertBox"
        onClick={onClose}
        style={{ zIndex: 10000000000000000 }}
      >
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
            zIndex: 10000000000000000,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Confirm to submit
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            Are you sure you want to mark all notifications as read?
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button className="witness_cancel_button" onClick={onClose}>
                No
              </Button>
            </div>
            <div
              onClick={() => {
                readAllMessageHandle();
              }}
            >
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={onClose}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const customModalForConfirmDelete = (onClose, notiId, sData) => {
    return (
      <div
        className="incidentAlertBox"
        onClick={onClose}
        style={{ zIndex: 10000000000000000 }}
      >
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
            zIndex: 10000000000000000,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Confirm to submit
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            Are you sure you want to delete this item?
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button className="witness_cancel_button" onClick={onClose}>
                No
              </Button>
            </div>
            <div
              onClick={() => {
                removeNoti(notiId, sData);
              }}
            >
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={onClose}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  let categoriesLevel = [
    {
      cat: "CAT",
      num: 1,
      subTitle: "Minor",

      status: "Injury or Ill-health requiring First-Aid Only",
      impact: "Minor impact to services and/or business operations",
      affected: "up to S$100k",
      reputation: "Minor complains from any party",
    },
    {
      cat: "CAT",
      num: 2,
      subTitle: "Moderate",

      status:
        "Serious injury requiring medical treatment or ill health leading to disability",
      impact: "short term impact to services and/or business operationss",
      affected: "S$100k to S$1m",
      reputation: "Adverse but low key media",
    },
    {
      cat: "CAT",
      num: 3,
      subTitle: "Moderate",

      status:
        "Fatality, serious injuries, or major outbreak or life threatening diseases",
      impact: "Extended disruptions to services and/or business operations",
      affected: "S$1m to S$5m",
      reputation: "Adverse media attention",
    },
    {
      cat: "CAT",
      num: 4,
      subTitle: "Catastrophic",

      status: "Multiple Fatality",
      impact:
        "Disastrous  and uncontrolled disruptions to services and/or business operations",
      affected: "More than S$5m",
      reputation: "Prolong adverse media attention",
    },
  ];

  function categoryInfoModel(onClose) {
    return (
      <div style={{ padding: 10 }} className="catquestion">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={onClose}
        >
          <img src={ExitIcon} />
        </div>
        <div style={{ fontSize: 16, fontWeight: "bolder", marginBottom: 10 }}>
          Incident Category
        </div>
        {categoriesLevel.map((c, index) => {
          return (
            <div
              style={{
                padding: 15,
                border: "1px solid #D8D8D8",
                borderRadius: 5,
                marginBottom: 15,
              }}
              key={index}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    background:
                      c.num == 1
                        ? "#EFFFF2"
                        : c.num == 2
                        ? "#FFF7CC"
                        : c.num == 3
                        ? "#FFD6B0"
                        : c.num == 4
                        ? "#FFB4B4"
                        : null,

                    color:
                      c.num == 1
                        ? "#42BE5C"
                        : c.num == 2
                        ? "#ECBB00"
                        : c.num == 3
                        ? "#FF8A62"
                        : c.num == 4
                        ? "#E85757"
                        : null,
                    border:
                      c.num == 1
                        ? "1px #42BE5C solid "
                        : c.num == 2
                        ? "1px #ECBB00 solid "
                        : c.num == 3
                        ? "1px #FF8A62 solid "
                        : c.num == 4
                        ? "1px #E85757 solid "
                        : null,
                    width: 40,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: " center",
                    alignItems: "center",
                    borderRadius: 8,
                    fontSize: 13,
                    fontWeight: "bold",
                    marginRight: 10,
                  }}
                >
                  <div>CAT</div>
                  <div>{c.num}</div>
                </div>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  {c.subTitle}
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#D5D9CF",
                  margin: "10px 0px",
                  width: "100%",
                  height: 1,
                }}
              ></div>

              <div style={{ marginBottom: 15, marginTop: 30 }}>
                <div style={{ fontWeight: "bold", marginBottom: 3 }}>
                  Status
                </div>
                <div>{c.status}</div>
              </div>
              <div style={{ marginBottom: 15, marginTop: 30 }}>
                <div style={{ fontWeight: "bold", marginBottom: 3 }}>
                  Impact
                </div>
                <div>{c.impact}</div>
              </div>
              <div style={{ marginBottom: 5, marginTop: 30 }}>
                <div style={{ fontWeight: "bold", marginBottom: 3 }}>
                  Affected
                </div>
                <div>{c.affected}</div>
              </div>
              <div style={{ marginBottom: 5, marginTop: 30 }}>
                <div style={{ fontWeight: "bold", marginBottom: 3 }}>
                  Reputation
                </div>
                <div>{c.reputation}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  const sideNotifcation = () => {
    return (
      <div className="side_noti">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <img
            src={ExitIcon}
            onClick={(e) => {
              e.stopPropagation();
              hideModal();
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        {/* Search  ------------------*/}
        <div
          style={{
            width: "100%",
            height: 50,
            margin: "10px 0px",
          }}
        >
          <form
            className="search_bar_form"
            onSubmit={(e) => {
              e.preventDefault();
              searchSubmit();
            }}
            style={{
              border: "1px solid #d8d8d8",
              height: 40,
              borderRadius: 8,
            }}
          >
            <input
              type="text"
              onChange={(e) => {
                if (e.target.value.length == 0) {
                  setShowSearchData(false);
                  fetchAllPushMessages();
                  setFilterMode(false);
                }
                setSearchInput(e.target.value);
              }}
              placeholder="Search"
              style={{
                position: "relative",
                // border: "1px solid red",
                border: "none",
                width: "90%",
                outline: "none",
                marginLeft: 3,
                height: 38,
                paddingRight: 5,
              }}
            />
            <img
              src={SearchIcon}
              style={{
                position: "absolute",
                top: 5,
                right: 3,
                cursor: "pointer",
              }}
              onClick={() => {
                searchSubmit();
              }}
            />
          </form>
        </div>
        {/* items-------------- */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 13,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            Notifications
          </div>
          <div
            style={{
              width: 40,
              height: 40,
              cursor: "pointer",
            }}
            onClick={() => setFilterModal(true)}
          >
            <img src={FilterIcon} width="40px" height="40px" />
          </div>
        </div>
        {showSearchData ? (
          <>
            <div
              id="scrollableDiv"
              style={{
                overflowY: "scroll",
                height: "75%",
                paddingBottom: 10,
              }}
            >
              {searchData.map((n) => notiItemsRender(n, true))}
            </div>
          </>
        ) : notiList.length == 0 ? (
          <p
            style={{
              textAlign: "center",
              color: "#838383",
              margin: "10px 0px",
              fontSize: 14,
            }}
          >
            <b>No notifications.</b>
          </p>
        ) : (
          <>
            {readAllLoader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  marginRight: 10,
                  marginBottom: 10,
                }}
              >
                <Loader active size="mini" inline="right" />
              </div>
            ) : (
              <div
                style={{
                  color: "#008237",
                  fontWeight: "bold",
                  marginBottom: 10,
                  display: "flex",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
                // onClick={readAllMessageHandle}
                onClick={() => {
                  return confirmAlert({
                    customUI: ({ onClose }) =>
                      customModalForConfirmCancel(onClose),
                  });
                }}
              >
                Read All
              </div>
            )}

            <div
              id="scrollableDiv"
              style={{
                overflowY: "scroll",
                height: "75%",
                paddingBottom: 10,
              }}
            >
              {loader ? (
                <div>
                  <Loader active inline="centered" size="mini" />
                </div>
              ) : filterMode ? (
                notiList.map((n) => notiItemsRender(n, false))
              ) : (
                <InfiniteScroll
                  dataLength={notiList.length}
                  next={fetchMoreData}
                  hasMore={nextUrl ? true : false}
                  scrollableTarget="scrollableDiv"
                  loader={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "10px 0px",
                      }}
                    >
                      <Loader size="mini"></Loader>
                    </div>
                  }
                  endMessage={
                    <p
                      style={{
                        textAlign: "center",
                        color: "#838383",
                        margin: "10px 0px",
                        fontSize: 14,
                      }}
                    >
                      <b>You reached the end.</b>
                    </p>
                  }
                >
                  {notiList.map((n) => notiItemsRender(n, false))}
                </InfiniteScroll>
              )}
            </div>
          </>
        )}

        {/* <div
          style={{
            overflowY: "scroll",
            height: "75%",
          }}
        >
          {[0, 1, 2, 3, 5, 6, 7].map(() => notiItemsRender())}
        </div> */}
      </div>
    );
  };

  const fetchAllPushMessages = (bool) => {
    setLoader(bool);
    api
      .get(GET_PUSH_MESSAGES, {
        user_id: AuthDetail.id,
        platform: "web",
        size: 10,
        search: searchInput,
      })
      .then((res) => {
        setLoader(false);
        setNextUrl(res.next);

        setNotiList(res.data);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (messaging) {
      messaging.onMessage((payload) => {
        setTimeout(() => {
          fetchAllPushMessages(false);
          setAllRead(false);
          fetchNotiReadCount();
        }, 1000);
      });
    }
    fetchAllPushMessages(true);
  }, []);

  const filterNotifcation = (ISFilter) => {
    setLoader(true);
    setFilterModal(false);

    api
      .get(FILTER_PUSH_MESSAGES, {
        user_id: AuthDetail.id,
        platform: "web",
        size: 10,
        start_date: fromDate.apiFromDate,
        end_date: toDate.apiToDate,
        category: category == "All" ? "" : category,
        park: park == "All" ? "" : park,
        status: ISFilter ? status : isFilter ? status : "",
        search: searchInput,
      })
      .then((res) => {
        setLoader(false);
        setFilterMode(true);
        setNotiList(res.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const fetchMoreData = () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const getToken = await auth.currentUser.getIdToken();
        axios
          .get(url + nextUrl, {
            headers: { Authorization: `Bearer ${getToken}` },
          })
          .then((res) => {
            setNextUrl(res.data.next);

            setNotiList(notiList.concat(res.data.data));
          })
          .catch((err) => console.log("err", err));
      }
    });
  };
  const options = [
    { key: "edit", icon: "edit", text: "Edit Post", value: "edit" },
    { key: "delete", icon: "delete", text: "Remove Post", value: "delete" },
    { key: "hide", icon: "hide", text: "Hide Post", value: "hide" },
  ];
  return (
    <div
      onClick={(e) => {
        setShowMobileNav(false);
      }}
      style={{
        zIndex: 99,
      }}
    >
      {notiModal && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            background: "rgba(0,0,0,0.3)",
            zIndex: 10,
          }}
          onClick={() => hideModal()}
        ></div>
      )}
      {notiModal && sideNotifcation()}
      {filterModal && filterModalForNotis()}
      <div className="company-header">
        <Image
          className="logo"
          src={logo3}
          id="company-logo"
          onClick={(e) => {
            history.push("/dashboard");
          }}
        />
        {isMobile ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "70%",
              position: "relative",
            }}
          >
            <div
              id="index-title"
              style={{
                fontSize: isMobile && 12,
              }}
            >
              Incident Management System
            </div>
            <div
              style={{
                cursor: "pointer",
                backgroundColor: "#008237",
                borderRadius: 5,
                padding: 3,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                setShowMobileSetting((prev) => !prev);
              }}
            >
              {" "}
              <Label as="a" image id="login-label">
                <FontAwesomeIcon icon={faUser} id="login-username" width={10} />

                {AuthDetail.name.length > 4 ? (
                  <span>{AuthDetail.name.slice(0, 3)}...</span>
                ) : (
                  AuthDetail.name
                )}
              </Label>
              <Icon
                name="angle down"
                color="white"
                className="arrow_down_header_mobile"
              />
              {isMobile && showMobileSetting && (
                <div
                  style={{
                    position: "absolute",
                    border: "1px solid #008237",
                    zIndex: 1,
                    background: "#fff",
                    width: "fit-content",
                    right: 0,
                    top: 40,
                  }}
                >
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                      padding: "8px 10px",
                      borderBottom: "1px solid #008237",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMobileSetting(false);
                      setNotiModal(true);
                      props.hideShowZoneList();
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        display: "inline",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBell}
                        id="noti-icon"
                        className="green-text"
                        style={{
                          fontSize: 16,
                          marginRight: 10,
                        }}
                      />
                      {props.hasRead && (
                        <div
                          style={{
                            width: 8,
                            height: 8,
                            background: "red",
                            position: "absolute",
                            top: "5%",
                            right: 10,
                            borderRadius: "50%",
                          }}
                        ></div>
                      )}
                    </div>
                    <div>Notification</div>
                  </div>
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                      padding: "8px 10px",
                      borderBottom: "1px solid #008237",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowMobileSetting(false);
                      history.push(
                        `/user_management/detail?id=${AuthDetail.id}&profile=true`
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      id="profile-icon"
                      className="green-text"
                      style={{
                        fontSize: 16,
                        marginRight: 10,
                      }}
                    />
                    <div>Profile</div>
                  </div>
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                      padding: "8px 10px",
                      borderBottom: "1px solid #008237",
                      cursor: "pointer",
                    }}
                    onClick={logoutHandle}
                  >
                    {logoutBool ? (
                      <Dimmer active inverted>
                        <Loader size="small"></Loader>
                      </Dimmer>
                    ) : (
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        id="logout-icon"
                        className="green-text"
                      />
                    )}

                    <div style={{ marginLeft: 0 }}>Logout</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <div style={{ display: "inline-block" }}>
              <div id="login-welcome" className="green-text">
                Welcome,{" "}
              </div>

              <Label
                as="a"
                image
                id="login-label"
                // onClick={() =>
                //   history.push(
                //     `/user_management/detail?id=${AuthDetail.id}&profile=true`
                //   )
                // }
              >
                <FontAwesomeIcon icon={faUser} id="login-username" width={10} />
                {/* Andy Brenard */}

                {AuthDetail.name}
              </Label>
              <p id="index-title">Incident Management System</p>
            </div>

            <div style={{ display: "inline-block" }} id="right-float">
              <div style={{ position: "relative", display: "inline" }}>
                <FontAwesomeIcon
                  icon={faBell}
                  id="noti-icon"
                  onClick={(e) => {
                    e.stopPropagation();

                    setNotiModal(true);
                    props.hideShowZoneList();
                  }}
                />
                {props.hasRead && (
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      background: "red",
                      position: "absolute",
                      top: "5%",
                      right: 10,
                      borderRadius: "50%",
                    }}
                  ></div>
                )}
              </div>

              <FontAwesomeIcon
                icon={faUserCircle}
                id="profile-icon"
                onClick={() => {
                  history.push(
                    `/user_management/detail?id=${AuthDetail.id}&profile=true`
                  );
                }}
              />
              {logoutBool ? (
                <Dimmer active inverted>
                  <Loader size="small"></Loader>
                </Dimmer>
              ) : (
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  id="logout-icon"
                  className="green-text"
                  onClick={logoutHandle}
                />
              )}
            </div>
          </>
        )}
      </div>
      {pathName == INCIDENTS_DETAIL_ROUTE ||
      pathName == INCIDENTS_AUDIT_ROUTE ||
      pathName == USER_DETAIL_ROUTE ||
      pathName == USER_AUDIT_ROUTE ||
      pathName.includes(NEW_INCIDENT_ROUTE) ||
      pathName == VIEW_GROUP_ROUTE ||
      pathName == NOTIFICATION_Detail_ROUTE ||
      pathName == NEW_USER_ROUTE ? (
        <></>
      ) : (
        <>
          <div className="nav_desktop">
            <Menu pointing secondary>
              <MenuItem
                to={DASHBOARD_ROUTE}
                active={selected == "dashboard"}
                className="menu-text"
              >
                <FontAwesomeIcon icon={faTachometerAlt} /> &nbsp;&nbsp;
                <span style={{ fontFamily: "Poppins" }}>Dashboard</span>
              </MenuItem>
              <MenuItem
                to={INCIDENTS_LIST_ROUTE}
                active={selected == "incidents_list"}
                className="menu-text"
              >
                <FontAwesomeIcon icon={faExclamationTriangle} /> &nbsp;&nbsp;
                <span style={{ fontFamily: "Poppins" }}>Incidents</span>
              </MenuItem>
              {filterP12 ? (
                <MenuItem
                  to={USER_LIST_ROUTE}
                  active={selected == "user_management"}
                  className="menu-text"
                >
                  <FontAwesomeIcon icon={faUsers} /> &nbsp;&nbsp;
                  <span style={{ fontFamily: "Poppins" }}>
                    Account Management
                  </span>
                </MenuItem>
              ) : (
                <div></div>
              )}
              {filterP14 ? (
                <MenuItem
                  to={GROUP_LIST_ROUTE}
                  active={selected == "group_list"}
                  className="menu-text"
                >
                  <FontAwesomeIcon icon={faBroadcastTower} /> &nbsp;&nbsp;
                  <span style={{ fontFamily: "Poppins" }}>
                    User Group Management
                  </span>
                </MenuItem>
              ) : (
                <div></div>
              )}

              <MenuItem
                to={SETTINGS_ROUTE}
                active={selected == "settings"}
                className="menu-text"
              >
                <FontAwesomeIcon icon={faCog} /> &nbsp;&nbsp;
                <span style={{ fontFamily: "Poppins" }}>Settings</span>
              </MenuItem>
              <MenuItem
                to={NOTIFICATION_List_ROUTE}
                active={selected == "notification_list"}
                className="menu-text"
              >
                <FontAwesomeIcon icon={faBroadcastTower} /> &nbsp;&nbsp;
                <span style={{ fontFamily: "Poppins" }}>
                  Notification Listing
                </span>
              </MenuItem>
            </Menu>
          </div>
          {/* <div className="nav_mobile">
            <div style={{ marginLeft: 50, marginTop: 20 }}>
              <FontAwesomeIcon
                icon={faBars}
                style={{ fontSize: 25, color: "#008237", cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMobileNav(true);
                }}
              />
            </div>
            {showMobileNav && (
              <div
                style={{
                  width: "90%",
                  height: "100%",
                  position: "fixed",
                  zIndex: 9999999,
                  backgroundColor: "white",
                  top: 0,
                  left: 0,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              ></div>
            )}
          </div> */}
        </>
      )}
      {selected === "" && (
        <Redirect to={DASHBOARD_ROUTE} component={Dashboard} />
      )}
      {pathName == DASHBOARD_ROUTE ? (
        <div
          className="main-content-width dashbord-width"
          // style={{ overflow: "hidden", height: "80vh" }}
        >
          {children}
        </div>
      ) : pathName == USER_DETAIL_ROUTE ||
        pathName == INCIDENTS_DETAIL_ROUTE ? (
        <div
          className="main-content-width"
          // style={{ overflowX: "auto", overflowY: "auto", height: "80vh" }}
        >
          {children}
        </div>
      ) : pathName == VIEW_GROUP_ROUTE ? (
        <div
          className="main-content-width"
          // style={{
          //   overflowX: "auto",
          //   overflowY: "auto",
          //   height: "80vh",
          // }}
        >
          {children}
        </div>
      ) : (
        <div
          className="main-content-width"
          // style={{ overflowX: "auto", overflowY: "auto", height: "80vh" }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  AuthDetail: state.loggedInUserDetail.userDetail,
  AuthToken: state.loggedInUserDetail,
  hasRead: state.incident.noti_has_read,
});

const mapDispatchToProps = (dispatch) => ({
  userLogout: () => dispatch(userLogout()),
  notiRedDot: (data) => dispatch(notiRedDot(data)),
  hideShowZoneList: () => dispatch(hideShowZoneList()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
