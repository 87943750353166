import React, { useState, useEffect } from "react";

import { Input, Loader } from "semantic-ui-react";

//images
import ViewPassword from "../../assets/images/user/ic_password_view.svg";
import UnViewPassword from "../../assets/images/user/ic_password_unview.svg";
import ArrowLeftIcon from "../../assets/images/incident/leftarrow.svg";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../../config/config";
import api from "../../config/api";

function ResetPassword() {
  const history = useHistory();

  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramEmail = query.get("email");
  const paramId = query.get("id");

  const { url } = config;
  const { CHANGE_PASSWORD, SAVE_USER, FORGOT_PASSWORD } = config.api_endpoint;

  // password input type
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [newPasswordState, setnewPasswordState] = useState({
    type: "password",
  });
  const [newRePasswordState, setnewRePasswordState] = useState({
    type: "password",
  });

  // show/hide icon
  const [beforeNPIcon, setbeforeNPIcon] = useState(UnViewPassword);
  const [beforeRNPIcon, setbeforeRNPIcon] = useState(UnViewPassword);

  useEffect(() => {
    setEmail(paramEmail);
  }, []);

  const handleSubmit = () => {
    if (password.length < 6) {
      return setError("Please enter password at least 6 characters");
    } else if (password != repassword) {
      return setError("Please enter same password");
    } else {
      setLoading(true);

      if (paramId && email) {
        axios
          .post(url + FORGOT_PASSWORD, {
            platform: "web",
            email,
            password,
          })

          .then((res) => {
            console.log("res One", res);

            axios
              .post(url + SAVE_USER, {
                id: paramId,
                is_active: 1,
                is_new: 0,
                platform: "web",
              })
              .then((result) => {
                if (result.data.message === "Success") {
                  setError(null);
                  history.push("/?reset=true");
                  setSuccess("Successfully changed password");
                }
                console.log("result m", result.data.message);
              })
              .catch((err) => console.log("err", err));

            setLoading(false);
            if (res.data.data.message == "Success") {
              setError(null);
              setSuccess("Successfully changed password");
            } else {
              setError(res.data.message);
            }
          })
          .catch((err) => {
            setLoading(false);

            console.log("err", err.message);
          });
      } else {
        console.log("hmm", {
          email,
          password,
        });
        axios
          .post(url + FORGOT_PASSWORD, {
            platform: "web",
            email,
            password,
          })

          .then((res) => {
            setLoading(false);
            console.log("res Two", res);
            if (res.data.message == "Success") {
              setError(null);
              history.push("/?reset=true");
              setSuccess("Successfully changed password");
            } else {
              setError(res.data.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };

  useEffect(() => {
    setPassword(password.replace(/\s/g, ""));
  }, [password]);

  function AttributeValidationPassword(e) {
    setPassword(e.target.value.replace(/\s/g, ""));
  }

  useEffect(() => {
    setRePassword(repassword.replace(/\s/g, ""));
  }, [repassword]);

  function AttributeValidationRePassword(e) {
    setRePassword(e.target.value.replace(/\s/g, ""));
  }
  return (
    <div className="login_form" style={{ zIndex: 9999999 }}>
      <div
        style={{
          fontSize: 15,
          fontWeight: "bold",
          marginBottom: 30,
          display: "flex",

          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {/* <img
          src={ArrowLeftIcon}
          style={{ marginRight: 18 }}
          onClick={() => history.goBack()}
        /> */}
        <span style={{ color: "#008237", fontSize: 18 }}>Reset password</span>
      </div>

      <div
        style={{
          fontSize: 14,
          margin: "20px 0px 25px 0px",
          fontWeight: "bold",
        }}
      >
        Please set your new password
      </div>
      {error ? (
        <div
          style={{
            color: "#E72D35",
            textAlign: "center",
            marginBottom: 5,
          }}
        >
          {error}
        </div>
      ) : null}
      <div style={{ marginBottom: 30 }}>
        <div style={{ fontSize: 13, fontWeight: "bold", marginBottom: 5 }}>
          Password
        </div>
        <div className="ui input password-div" style={{ marginBottom: 15 }}>
          <Input
            type={newPasswordState}
            value={password}
            fluid
            className="user_new_pass"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={AttributeValidationPassword}
          />
          <i
            className="icon password-icon_login"
            style={{ position: "relative", left: -2 }}
          >
            <img
              src={beforeNPIcon}
              onClick={() => {
                if (newPasswordState === "text") {
                  setnewPasswordState("password");
                  setbeforeNPIcon(UnViewPassword);
                } else {
                  setnewPasswordState("text");
                  setbeforeNPIcon(ViewPassword);
                }
              }}
            />
          </i>
        </div>

        <div style={{ fontSize: 13, fontWeight: "bold", marginBottom: 5 }}>
          Retype New Password
        </div>
        <div className="ui input password-div">
          <Input
            type={newRePasswordState}
            value={repassword}
            fluid
            className="user_renew_pass"
            onChange={(e) => setRePassword(e.target.value)}
            onKeyDown={AttributeValidationRePassword}
          />
          <i
            className="icon password-icon_login"
            style={{ position: "relative", left: -2 }}
          >
            <img
              src={beforeRNPIcon}
              onClick={() => {
                if (newRePasswordState === "text") {
                  setnewRePasswordState("password");
                  setbeforeRNPIcon(UnViewPassword);
                } else {
                  setnewRePasswordState("text");
                  setbeforeRNPIcon(ViewPassword);
                }
              }}
            />
          </i>
        </div>
      </div>
      <div className="login_btn">
        {loading ? (
          <Loader active inline="centered" />
        ) : (
          <button onClick={handleSubmit}>Submit</button>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
