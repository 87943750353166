import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

//images
import ArrowDownDropDownIcon from "../assets/images/incident/arrowdowndrop.svg";

function MonthList(obj) {
  // later need to retrieve
  const months = [
    {
      key: "January",
      text: "January",
      value: "January",
    },
    {
      key: "February",
      text: "February",
      value: "February",
    },
    {
      key: "March",
      text: "March",
      value: "March",
    },
    {
      key: "April",
      text: "April",
      value: "April",
    },
    {
      key: "May",
      text: "May",
      value: "May",
    },
    {
      key: "June",
      text: "June",
      value: "June",
    },
    {
      key: "July",
      text: "July",
      value: "July",
    },
    {
      key: "August",
      text: "August",
      value: "August",
    },
    {
      key: "September",
      text: "September",
      value: "September",
    },
    {
      key: "October",
      text: "October",
      value: "October",
    },
    {
      key: "November",
      text: "November",
      value: "November",
    },
    {
      key: "December",
      text: "December",
      value: "December",
    },
  ];

  var today = new Date();
  var current_month = months[today.getMonth()]["value"];

  return (
    <Dropdown
      placeholder={obj["title"]}
      search
      selection
      options={months}
      defaultValue={current_month}
      onChange={obj["onChange"]}
      icon={
        <img
          src={ArrowDownDropDownIcon}
          style={{
            float: "right",
            marginTop: "2px",
            marginRight: "-20px",
            color: "#008237",
            cursor: "pointer",
          }}
        />
      }
      className={obj["custom_classes"]}
    />
  );
}

export default MonthList;
