import incidentActionTypes from "../actions/actionTypes/incidentAcionTypes";

const {
  FETCH_ALL_INCIDENTS,
  FETCH_ALL_INCIDENTS_SUCCESS,
  FETCH_ALL_INCIDENTS_FAIL,
  FETCH_ALL_INCIDENT_EXPORT,
  FETCHING_ALL_INCIDENT_EXPORT,
  FETCH_ALL_INCIDENT_SEARCH_SUCCESS,
  FETCH_ALL_SUB_DEPARTMENT,
  FETCH_ALL_SUB_DEPARTMENT_SUCCESS,
  FETCH_SUB_NOTIFCATION,
  FETCH_SUB_NOTIFCATION_SUCCESS,
} = incidentActionTypes;

const initialState = {
  error: null,
  incidentLists: [],
  loading: false,
  nextUrl: null,
  total_pages: 0,
  incidentListForExport: [],
  incidentListForSearch: [],
  subDeparts: [],
  incidentListForExports: [],
  exportLoading: true,
  sub_notis: {},
  sub_notis_loading: true,
  sub_depart_loading: true,
  noti_has_read: false,
  showZoneDropDown: false,
};

const incidentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_INCIDENTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_INCIDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        nextUrl: action.payload.next,
        incidentLists: action.payload.data,
        total_pages: action.payload.total_pages,
      };
    case FETCH_ALL_INCIDENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_ALL_INCIDENT_EXPORT:
      return {
        ...state,
        incidentListForExport: action.payload.data,
        incidentListForExports: action.payload.data,
        exportLoading: false,
      };
    case FETCHING_ALL_INCIDENT_EXPORT:
      return {
        ...state,
        incidentListForExport: [],
        exportLoading: true,
      };
    case FETCH_ALL_INCIDENT_SEARCH_SUCCESS:
      return {
        ...state,
        incidentListForSearch: action.payload.data,
      };
    case FETCH_SUB_NOTIFCATION:
      return {
        ...state,
        sub_notis: {},
        sub_notis_loading: true,
      };
    case FETCH_SUB_NOTIFCATION_SUCCESS:
      return {
        ...state,
        sub_notis_loading: false,
        sub_notis: action.payload,
      };
    case FETCH_ALL_SUB_DEPARTMENT:
      return {
        ...state,
        sub_depart_loading: true,
        subDeparts: [],
      };
    case FETCH_ALL_SUB_DEPARTMENT_SUCCESS:
      return {
        ...state,
        sub_depart_loading: false,
        subDeparts: action.payload.data,
      };
    case "CHANGING_LOADER":
      return {
        ...state,
        exportLoading: true,
      };
    case "FETCH_INCIDENT_NOTI_SUSCCESS":
      return {
        ...state,
        noti_has_read: action.payload.has_new,
      };
    case "SHOW_ZONE_LIST":
      return {
        ...state,
        showZoneDropDown: action.payload,
      };
    case "HIDE_ZONE_LIST":
      return {
        ...state,
        showZoneDropDown: action.payload,
      };
    default:
      return state;
  }
};
export default incidentReducer;
