import React, { useState, useEffect } from "react";
import { Input } from "semantic-ui-react";

//images
import ViewPassword from "../../../../assets/images/user/ic_password_view.svg";
import UnViewPassword from "../../../../assets/images/user/ic_password_unview.svg";

//css
import "./forms.css";

function UserReset(props) {
  // password input type
  const [newPasswordState, setnewPasswordState] = useState({
    type: "password",
  });
  const [newRePasswordState, setnewRePasswordState] = useState({
    type: "password",
  });

  // show/hide icon
  const [beforeNPIcon, setbeforeNPIcon] = useState(UnViewPassword);
  const [beforeRNPIcon, setbeforeRNPIcon] = useState(UnViewPassword);

  const values = props.values;
  const resetvalues = props.resetvalues;
  const onChangePasswordView = props.onChangePasswordView;
  const userResetError = props.userResetError;

  return (
    <div className="userreset_container">
      {!props.profileBool && (
        <>
          <h3>Send to </h3>
          <div className="sent-to-div">
            <div className="child-sent-to-div">
              <span className="child-sent-to-div-label1">
                {values.name && values.name}
              </span>{" "}
              <div className="child-sent-to-div-icon">
                {values.role && values.role.includes("_")
                  ? values.role
                      .replaceAll("_", " ")
                      .split(" ")[0]
                      .charAt(0)
                      .toUpperCase() +
                    values.role.replaceAll("_", " ").split(" ")[0].slice(1) +
                    "  " +
                    values.role
                      .replaceAll("_", " ")
                      .split(" ")[1]
                      .charAt(0)
                      .toUpperCase() +
                    values.role.replaceAll("_", " ").split(" ")[1].slice(1)
                  : values.role}
              </div>{" "}
              <br></br>
              <span className="child-sent-to-div-label2">
                {values.staff_id && values.staff_id}
              </span>
            </div>
          </div>
        </>
      )}

      <h3 style={{ color: userResetError && "#E72D35" }}>
        Enter New Password{" "}
      </h3>
      <div className="ui input password-div">
        <Input
          type={newPasswordState}
          fluid
          className="user_new_pass"
          value={resetvalues.password}
          onChange={(e, data) => {
            onChangePasswordView("password", data.value);
          }}
        />
        <i className="icon password-icon">
          <img
            src={beforeNPIcon}
            onClick={() => {
              if (newPasswordState === "text") {
                setnewPasswordState("password");
                setbeforeNPIcon(UnViewPassword);
              } else {
                setnewPasswordState("text");
                setbeforeNPIcon(ViewPassword);
              }
            }}
          />
        </i>
      </div>
      {userResetError && (
        <>
          <br></br>
          <div className="input_error_text">{userResetError}</div>
        </>
      )}

      <h3 style={{ color: userResetError && "#E72D35" }}>
        Re-enter New Password{" "}
      </h3>
      <div className="ui input password-div">
        <Input
          type={newRePasswordState}
          fluid
          className="user_renew_pass"
          value={resetvalues.repassword}
          onChange={(e, data) => {
            onChangePasswordView("repassword", data.value);
          }}
        />
        <i className="icon password-icon">
          <img
            src={beforeRNPIcon}
            onClick={() => {
              if (newRePasswordState === "text") {
                setnewRePasswordState("password");
                setbeforeRNPIcon(UnViewPassword);
              } else {
                setnewRePasswordState("text");
                setbeforeRNPIcon(ViewPassword);
              }
            }}
          />
        </i>
      </div>
      {userResetError && (
        <>
          <br></br>
          <div className="input_error_text">{userResetError}</div>
        </>
      )}
    </div>
  );
}

export default UserReset;
