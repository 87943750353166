import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../routes";
import { useHistory } from "react-router-dom";

// images
import EditIcon from "../../../../assets/images/incident/btn_edit.svg";
import DeleteIcon from "../../../../assets/images/incident/btn_delete.svg";

// css
import "./cases.css";
import WitnessForm from "./forms/WitnessForm";
import { confirmAlert } from "react-confirm-alert";
import { db } from "../../../../config/firebase";

function WitnessParticulars(props) {
  let history = useHistory();
  const {
    changeShowWitnessFormBool,
    changeHideWitnessFormBool,
    showWitnessForm,
    witnessValues,
  } = props;

  const [forms, setForms] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState(null);

  function addForm(data) {
    let clonesForms = forms;

    const filteredForms = clonesForms.filter(
      (c) => c.witness_no !== data.witness_no
    );
    filteredForms.push(data);
    setForms(filteredForms);
    props.onChangeIncidetWitness(filteredForms);
  }

  function removeWitness(item, index) {
    let newArr = [];
    forms.forEach((form, i) => {
      if (i == index) {
        newArr.push({ ...form, status: "delete" });
      } else {
        newArr.push({ ...form });
      }
    });
    setForms(newArr);
    props.onChangeIncidetWitness(newArr);
  }

  useEffect(() => {
    if (witnessValues) {
      setForms(witnessValues);
    }
  }, [witnessValues]);

  return (
    <>
      {!showWitnessForm ? (
        <div>
          <div className="incident_overview_container">
            <div className="casual_container">
              <div className="casual_item_container">
                {forms.length == 0 ? (
                  <div style={{ textAlign: "center", color: "#8F8F8F" }}>
                    No Witness added yet
                  </div>
                ) : (
                  forms.map((_, index) =>
                    _.status && _.status == "delete" ? null : (
                      <div className="casual_item" key={_.witness_no}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="casual_dot"></div>
                          <p className="casual_text">
                            Witness {index + 1} - {_.guest_name}{" "}
                          </p>
                        </div>
                        <div className="action_container">
                          <div
                            className="delete_btn"
                            onClick={() => removeWitness(_, index)}
                          >
                            <img src={DeleteIcon} />
                          </div>
                          <div
                            onClick={() => {
                              setSelectedDetail(_);
                              changeShowWitnessFormBool();
                            }}
                          >
                            <img src={EditIcon} />
                          </div>
                        </div>
                      </div>
                    )
                  )
                )}
              </div>
              <div
                onClick={() => {
                  setSelectedDetail(null);
                  changeShowWitnessFormBool();
                }}
              >
                <div className="underline_add_btn "></div>
                <div
                  className="add_casual"
                  style={{
                    justifyContent: "left",
                    marginTop: forms.length > 0 ? 0 : 10,
                  }}
                >
                  + Add Witness
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
          <div
            className="incident_footer"
            style={{
              bottom: -65,
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              {props.overViewValues.is_emergency_incident == "Yes" ||
              props.showNotiPage ? (
                <div
                  onClick={() => {
                    props.setCheckStatus("Yes");
                    props.mainSaveButton();
                  }}
                >
                  <Button className="witness_cancel_button">Submit </Button>
                </div>
              ) : null}
              {props.paramPendingBool && (
                <div>
                  <Button
                    className="incident_cancel_button"
                    onClick={props.deletePendingItem}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{ width: "100%" }}
                onClick={() => {
                  if (props.changedValue) {
                    return confirmAlert({
                      customUI: ({ onClose }) =>
                        props.customModalForConfirmCancel(onClose),
                    });
                  } else {
                    history.push("/incidents_list");
                  }
                }}
              >
                <Button className="witness_cancel_button">Cancel</Button>
              </div>
              <div
                style={{ width: "100%" }}
                onClick={() => {
                  props.mainSaveButton();
                }}
              >
                <Button className="witness_save_button">Save</Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <WitnessForm
          changeShowWitnessFormBool={changeShowWitnessFormBool}
          changeHideWitnessFormBool={changeHideWitnessFormBool}
          showWitnessForm={showWitnessForm}
          addForm={addForm}
          selectedDetail={selectedDetail}
          totalForms={forms}
          mainSaveButton={props.mainSaveButton}
          showNotiPage={props.showNotiPage}
          overViewValues={props.overViewValues}
          paramPendingBool={props.paramPendingBool}
          changedValue={props.changedValue}
          customModalForConfirmCancel={props.customModalForConfirmCancel}
          newAddedSignature={props.newAddedSignature}
          setNewAddedSignature={props.setNewAddedSignature}
        />
      )}
    </>
  );
}

export default WitnessParticulars;
