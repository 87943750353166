import userGroupManagementTypes from "../actions/actionTypes/userGroupManagementTypes";

const {
  FETCH_GROUP_MANAGEMENT,
  FETCH_GROUP_MANAGEMENT_SUCCESS,
  FETCH_GROUP_MANAGEMENT_FAIL,

  FETCH_GP_USERS,
  FETCH_GP_USERS_SUCCESS,
  FETCH_GP_USERS_FAIL,
} = userGroupManagementTypes;

const initialState = {
  gploading: true,
  userLoading: true,
  gpList: [],
  userList: [],
  nextUrl: null,
  total_pages: 0,
  click: 1,
};

const userGroupManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROUP_MANAGEMENT:
      return {
        ...state,
        loading: true,
        gploading: true,
        gpList: [],
        userList: [],
      };
    case FETCH_GROUP_MANAGEMENT_SUCCESS:
      return {
        ...state,
        gploading: false,
        userLoading: true,
        gpList: action.payload.data,
        userList: [],
      };
    case FETCH_GP_USERS:
      return {
        ...state,
        gploading: true,
        userLoading: true,
        userList: [],
        nextUrl: null,
        total_pages: 0,
        click: 1,
      };
    case FETCH_GP_USERS_SUCCESS:
      return {
        ...state,
        gploading: true,
        userLoading: false,
        userList: action.payload.data,
        nextUrl: action.payload.next,
        total_pages: action.payload.total_pages,
        click: 2,
      };
    case "RESET_CLICK":
      return {
        ...state,
        click: 1,
      };
    default:
      return state;
  }
};
export default userGroupManagementReducer;
