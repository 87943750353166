import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { db } from "../../../../config/firebase";

// images
import EditIcon from "../../../../assets/images/incident/btn_edit.svg";
import DeleteIcon from "../../../../assets/images/incident/btn_delete.svg";

// css
import "./cases.css";
import CasualtyForm from "./forms/CasualtyForm";
import { confirmAlert } from "react-confirm-alert";

function CasualtyDetails(props) {
  const {
    changeShowCasualyFormBool,
    changeHideCasualyFormBool,
    showCasualtyForm,
    casualtyValues,
  } = props;
  let history = useHistory();
  const { nationalityList, setNationalityList } = props.optionsState;

  const [forms, setForms] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState(null);

  function addForm(data) {
    let clonesForms = forms;

    const filteredForms = clonesForms.filter(
      (c) => c.casualty_no !== data.casualty_no
    );

    filteredForms.push(data);
    setForms(filteredForms);
    props.onChangeIncidentCasulaty(filteredForms);
  }

  function removeCasualty(item, index) {
    let newArr = [];
    forms.forEach((form, i) => {
      if (i == index) {
        newArr.push({ ...form, status: "delete" });
      } else {
        newArr.push({ ...form });
      }
    });
    setForms(newArr);
    props.onChangeIncidentCasulaty(newArr);
  }

  useEffect(() => {
    if (casualtyValues) {
      setForms(casualtyValues);
    }
  }, [casualtyValues]);

  useEffect(() => {
    // get nationalityList
    db.collection("static_data")
      .doc("nationality")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().nationality_arr.forEach((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });

        setNationalityList(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <div>
      {!showCasualtyForm ? (
        <div className="incident_overview_container">
          <div className="casual_container">
            <div className="casual_item_container">
              {forms.length == 0 ? (
                <div style={{ textAlign: "center", color: "#8F8F8F" }}>
                  No Casualty added yet
                </div>
              ) : (
                forms.map((_, index) =>
                  _.status && _.status == "delete" ? null : (
                    <div className="casual_item">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="casual_dot"></div>
                        <p className="casual_text">
                          Casualty {index + 1} - {_.injury_name}{" "}
                        </p>
                      </div>
                      <div className="action_container">
                        <div
                          className="delete_btn"
                          onClick={() => removeCasualty(_, index)}
                        >
                          <img src={DeleteIcon} />
                        </div>
                        <div
                          onClick={() => {
                            setSelectedDetail(_);
                            changeShowCasualyFormBool();
                          }}
                        >
                          <img src={EditIcon} />
                        </div>
                      </div>
                    </div>
                  )
                )
              )}
            </div>
            <div
              onClick={() => {
                changeShowCasualyFormBool();
                setSelectedDetail(null);
              }}
            >
              <div className="underline_add_btn "></div>
              <div
                className="add_casual"
                style={{
                  justifyContent: "left",
                  marginTop: forms.length > 0 ? 0 : 10,
                }}
              >
                + Add Casualty
              </div>
            </div>
          </div>

          {/* Footer */}
          <div
            className="incident_footer"
            style={{
              bottom: -65,
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              {props.overViewValues.is_emergency_incident == "Yes" ||
              props.showNotiPage ? (
                <div
                  onClick={() => {
                    props.setCheckStatus("Yes");
                    props.mainSaveButton();
                  }}
                >
                  <Button className="witness_cancel_button">Submit </Button>
                </div>
              ) : null}
              {props.paramPendingBool && (
                <div>
                  <Button
                    className="incident_cancel_button"
                    onClick={props.deletePendingItem}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{ width: "100%" }}
                onClick={() => {
                  if (props.changedValue) {
                    return confirmAlert({
                      customUI: ({ onClose }) =>
                        props.customModalForConfirmCancel(onClose),
                    });
                  } else {
                    history.push("/incidents_list");
                  }
                }}
              >
                <Button className="witness_cancel_button">Cancel</Button>
              </div>
              <div
                style={{ width: "100%" }}
                onClick={() => {
                  props.mainSaveButton();
                }}
              >
                <Button className="witness_save_button">Save</Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CasualtyForm
          changeShowCasualyFormBool={changeShowCasualyFormBool}
          changeHideCasualyFormBool={changeHideCasualyFormBool}
          addForm={addForm}
          selectedDetail={selectedDetail}
          totalForms={forms}
          optionsState={{ nationalityList }}
          mainSaveButton={props.mainSaveButton}
          showNotiPage={props.showNotiPage}
          overViewValues={props.overViewValues}
          paramPendingBool={props.paramPendingBool}
          changedValue={props.changedValue}
          customModalForConfirmCancel={props.customModalForConfirmCancel}
          newAddedSignature={props.newAddedSignature}
          setNewAddedSignature={props.setNewAddedSignature}
        />
      )}
    </div>
  );
}

export default CasualtyDetails;
