import React from "react";
import { SETTINGS_ROUTE } from "../../routes";
import { Route } from "react-router";
import Modifiy from "./Modifiy";

function index() {
  return (
    <React.Fragment>
      <Route exact path={SETTINGS_ROUTE} component={Modifiy} />
    </React.Fragment>
  );
}

export default index;
