import React, { useState, useEffect } from "react";
import PlaceList from "../../../../components/PlaceList";
import { db } from "../../../../config/firebase";

import { Checkbox, Input, Button } from "semantic-ui-react";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";

function IncidentAnalysis(props) {
  const [subStandardActionLoader, setSubStandardActionLoader] = useState(true);
  const [subStandardContionLoader, setSubStandardContionLoader] =
    useState(false);
  const [personnelFactorsLoader, setPersonnelFactorsLoader] = useState(false);
  const [jobFactorLoader, setJobFactorListLoader] = useState(false);
  const { onChangeIncidentAnalysis, analysis, mainSaveButton, analysisError } =
    props;
  const { actionError, conditionError, personnelError, jobError, inputError } =
    analysisError;
  let history = useHistory();
  useEffect(() => {
    // fetch Substandard Action
    setSubStandardActionLoader(true);
    setSubStandardContionLoader(true);
    setPersonnelFactorsLoader(true);
    setJobFactorListLoader(true);
    db.collection("static_data")
      .doc("substandard_action")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().substandard_action_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setSubStandardActionLoader(true);
        setSubStandardActionList(data);
      });

    // fetch Substandard Condition
    db.collection("static_data")
      .doc("substandard_condition")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().substandard_condition.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setSubStandardContionLoader(false);
        setSubStandardContionList(data);
      });

    // fetch personnel Factors
    db.collection("static_data")
      .doc("personnel_factors")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().personnel_factors_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setPersonnelFactorsLoader(false);
        setPersonnelFactorsList(data);
      });

    // fetch jobs Factors
    db.collection("static_data")
      .doc("job_factors")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().job_factors_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setJobFactorListLoader(false);
        setJobFactorList(data);
      });
  }, []);

  const {
    subStandardActionList,
    setSubStandardActionList,
    subStandardContionList,
    setSubStandardContionList,
    personnelFactorsList,
    setPersonnelFactorsList,
    jobFactorList,
    setJobFactorList,
  } = props.optionsState;

  return (
    <div className="incident_overview_container">
      {/* Substandard Action */}
      <h3 className={`dropdown_title`}>Substandard Action {}</h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown `}
        icon_classes="incident-place-list-icon"
        value={analysis.action}
        options={subStandardActionList}
        onChange={(e, data) => {
          onChangeIncidentAnalysis("action", data.value);
        }}
        // loading={subStandardActionLoader}
      />
      {/* {actionError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          Please make a selection
        </div>
      )} */}
      {/* Substandard Condition */}
      <h3 className={`dropdown_title`}>Substandard Condition</h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown 
    
        
        `}
        icon_classes="incident-place-list-icon"
        options={subStandardContionList}
        value={analysis.condition}
        onChange={(e, data) => {
          onChangeIncidentAnalysis("condition", data.value);
        }}
        loading={subStandardContionLoader}
      />
      {/* {conditionError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          Please make a selection
        </div>
      )} */}
      {/* Personnel Factors */}
      <h3 className={`dropdown_title `}>Personnel Factors</h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown`}
        icon_classes="incident-place-list-icon"
        options={personnelFactorsList}
        value={analysis.personnel}
        onChange={(e, data) => {
          onChangeIncidentAnalysis("personnel", data.value);
        }}
        loading={personnelFactorsLoader}
      />

      {/* Job Factors */}
      <h3 className={`dropdown_title`}>Job Factors</h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown `}
        icon_classes="incident-place-list-icon"
        options={jobFactorList}
        value={analysis.job}
        onChange={(e, data) => {
          onChangeIncidentAnalysis("job", data.value);
        }}
        loading={jobFactorLoader}
      />

      {/* Work Stoppage   */}
      <div style={{ marginBottom: 30, marginTop: 30 }}>
        <h3>Work Stoppage </h3>
        <div>
          <Checkbox
            radio
            label="Yes"
            checked={analysis.stoppage == "Yes"}
            onChange={() => onChangeIncidentAnalysis("stoppage", "Yes")}
            className="checkBox"
          />
        </div>
        <div>
          <Checkbox
            radio
            label="No"
            checked={analysis.stoppage == "No"}
            onChange={() => onChangeIncidentAnalysis("stoppage", "No")}
            className="checkBox"
          />
        </div>
        <div
          style={{
            marginTop: 10,
          }}
        ></div>
        {/* <h3 className={`dropdown_title`}>Type of property* </h3> */}
        <Input
          fluid
          className={`damaged_input`}
          value={analysis.stoppage_text}
          onKeyPress={(event) => {
            if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            onChangeIncidentAnalysis("stoppage_text", e.target.value);
          }}
        />
      </div>

      {/* Footer */}
      <div
        className="incident_footer"
        style={{
          bottom: -100,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          {props.overViewValues.is_emergency_incident == "Yes" ||
          props.showNotiPage ? (
            <div
              onClick={() => {
                props.setCheckStatus("Yes");
                props.mainSaveButton();
              }}
            >
              <Button className="witness_cancel_button">Submit </Button>
            </div>
          ) : null}
          {props.paramPendingBool && (
            <div>
              <Button
                className="incident_cancel_button"
                onClick={props.deletePendingItem}
              >
                Delete
              </Button>
            </div>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              if (props.changedValue) {
                return confirmAlert({
                  customUI: ({ onClose }) =>
                    props.customModalForConfirmCancel(onClose),
                });
              } else {
                history.push("/incidents_list");
              }
            }}
          >
            <Button className="witness_cancel_button">Cancel</Button>
          </div>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              mainSaveButton();
            }}
          >
            <Button className="witness_save_button">Save </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncidentAnalysis;
