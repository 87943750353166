import React from "react";
import { Route } from "react-router";
import { DASHBOARD_ROUTE } from "../../routes";
import IncidentList from "./IncidentList";
import "./dashboard.css";

export const Dashboard = () => (
  <React.Fragment>
    <Route exact path={DASHBOARD_ROUTE} component={IncidentList} />
  </React.Fragment>
);
export default Dashboard;
