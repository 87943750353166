import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

//images
import ArrowDownDropDownIcon from "../assets/images/incident/arrowdowndrop.svg";

function PlaceList(obj) {
  // later need to retrieve
  let places = [
    {
      key: "All",
      text: "All",
      value: "All",
    },
    {
      key: "Connectivity",
      text: "Connectivity",
      value: "Connectivity",
    },
    {
      key: "Jurong Bird Park",
      text: "Jurong Bird Park",
      value: "Jurong Bird Park",
    },
    {
      key: "Mandai Development",
      text: "Mandai Development",
      value: "Mandai Development",
    },
    {
      key: "Night Safari",
      text: "Night Safari",
      value: "Night Safari",
    },
    {
      key: "River Safari",
      text: "River Safari",
      value: "River Safari",
    },
    {
      key: "Singapore Zoo",
      text: "Singapore Zoo",
      value: "Singapore Zoo",
    },
  ];

  return (
    <Dropdown
      fluid={obj.isSideBar ? false : true}
      selection
      // options={obj.options && obj.options.length > 0 ? obj.options : places}
      options={obj.options && obj.options.length > 0 ? obj.options : []}
      // defaultValue={obj["value"]}
      value={obj["value"]}
      onChange={obj["onChange"]}
      icon={
        !obj.loading && (
          <img src={ArrowDownDropDownIcon} className={obj["icon_classes"]} />
        )
      }
      className={obj["custom_classes"]}
      loading={obj.loading}
      noResultsMessage={
        obj.noResultsMessage ? obj.noResultsMessage : "No results found."
      }
      onClick={obj.onClick}
      disabled={
        obj.disabled ? obj.disabled : obj.profileBool ? obj.profileBool : false
      }
      placeholder="Please Select..."
      // disabled={obj.disabled}
    />
  );
}

export default PlaceList;
