import React, { useState, useEffect } from "react";
import { Table, Pagination, Loader, Dimmer } from "semantic-ui-react";
import "./NotificationList.css";
import { useHistory } from "react-router-dom";
import { fetchNotificationSubDepartment } from "../../store/actions/incidentAction";
import { connect } from "react-redux";

function NotifcationList(props) {
  const [currentPage, setCurrentPage] = useState(1);

  let history = useHistory();

  useEffect(() => {
    props.fetchNotificationSubDepartment(currentPage, 10);
  }, []);

  const handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage;
    setCurrentPage(page);
    props.fetchNotificationSubDepartment(page, 10);
  };

  return (
    <div style={{ paddingBottom: 80 }}>
      <div className="col-45-noti">
        <h2 style={{ color: "#008237" }}>Notification Listing</h2>
      </div>
      <div className="table_noti_list">
        {props.sub_notis_loading ? (
          <Loader active inline="centered" />
        ) : (
          <Table basic>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>
                  <div className="notilist_tableHeader name">
                    <div>Incident Classification</div>
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="3">
                  <div className="notilist_tableHeader description">
                    <div>Sub Departments(s)</div>
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="5" textAlign="left">
                  <div className="notilist_tableHeader noofmember">
                    <div> No of. Sub Dept</div>
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {props.sub_notis_list &&
                props.sub_notis_list.data &&
                props.sub_notis_list.data.length > 0 &&
                props.sub_notis_list.data.map((_, index) => {
                  let lastSubDepart = _.subdeparts[_.subdeparts.length - 1];

                  return (
                    <Table.Row
                      onClick={() =>
                        history.push(
                          `/notification_list/notification_detail?cls=${_.classification_title}&cls_id=${_.classification_id}`
                        )
                      }
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Table.Cell className="noti_name_" collapsing>
                        <div stlye={{ width: 500 }}>
                          {_.classification_title}
                        </div>
                      </Table.Cell>
                      {console.log("subdeparts", _.subdeparts)}
                      <Table.Cell colSpan="3">
                        {_.subdeparts.map((s) =>
                          s == lastSubDepart ? s : `${s}, `
                        )}
                      </Table.Cell>
                      <Table.Cell collapsing textAlign="left" colSpan="5">
                        {_.depart_count}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        )}

        <div style={{}}>
          {props.sub_notis_list &&
            props.sub_notis_list.data &&
            props.sub_notis_list.data.length > 0 && (
              <Pagination
                activePage={currentPage}
                firstItem={null}
                lastItem={null}
                siblingRange={3}
                floated="right"
                totalPages={props.sub_notis_list.total_pages}
                className={`${
                  currentPage == 1
                    ? "paginat__fist_disable"
                    : props.sub_notis_list.total_pages == currentPage
                    ? "paginat__last_disable"
                    : null
                }`}
                onPageChange={handlePaginationChange}
                style={{
                  opacity: props.sub_notis_loading ? 0 : 1,
                }}
              />
            )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sub_notis_list: state.incident.sub_notis,
  sub_notis_loading: state.incident.sub_notis_loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotificationSubDepartment: (page, size) =>
    dispatch(fetchNotificationSubDepartment(page, size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotifcationList);
