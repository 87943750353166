import userGroupManagementTypes from "./actionTypes/userGroupManagementTypes";
import api from "../../config/api";
import config from "../../config/config";

const { GET_ALL_GROUPS, GET_ALL_GP_USERS } = config.api_endpoint;

const {
  FETCH_GROUP_MANAGEMENT,
  FETCH_GROUP_MANAGEMENT_SUCCESS,
  FETCH_GROUP_MANAGEMENT_FAIL,

  FETCH_GP_USERS,
  FETCH_GP_USERS_SUCCESS,
  FETCH_GP_USERS_FAIL,
} = userGroupManagementTypes;

const fetchingGroupManagement = () => {
  return {
    type: FETCH_GROUP_MANAGEMENT,
  };
};

const fetchingGroupManagementSuccess = (data) => {
  return {
    type: FETCH_GROUP_MANAGEMENT_SUCCESS,
    payload: data,
  };
};

const fetchingGroupManagementFail = () => {
  return {
    type: FETCH_GROUP_MANAGEMENT_FAIL,
  };
};

export const fetchGroupManagement = () => {
  return (dispatch) => {
    dispatch(fetchingGroupManagement);

    return api
      .get(GET_ALL_GROUPS)
      .then((res) => {
        return dispatch(fetchingGroupManagementSuccess(res));
      })
      .catch((err) => console.log("err", err));
  };
};

const fetchingGroupUsers = () => {
  return {
    type: FETCH_GP_USERS,
  };
};

const fetchingGroupUsersSuccess = (data) => {
  return {
    type: FETCH_GP_USERS_SUCCESS,
    payload: data,
  };
};

const fetchingGroupUsersFail = () => {
  return {
    type: FETCH_GP_USERS_FAIL,
  };
};

export const fetchGroupUsers = (role, currentPage, sort, search) => {
  return (dispatch) => {
    dispatch(fetchingGroupUsers);
    sort = sort ? "desc" : "asc";

    return api
      .get(GET_ALL_GP_USERS, {
        role,
        size: 10,
        page: currentPage - 1,
        sorting: sort,
        search,
      })
      .then((res) => {
        return dispatch(fetchingGroupUsersSuccess(res));
      })
      .catch((err) => console.log("err", err));
  };
};

export const reSetClick = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_CLICK",
    });
  };
};
