import React from "react";
import { Checkbox, Dropdown } from "semantic-ui-react";

//images
import ArrowDownDropDownIcon from "../assets//images/incident/arrowdowndrop.svg";

function Incident_detail_dropdown({ title }) {
  let testOptions = [
    {
      key: 1,
      text: "Test One",
      value: 1,
    },
    {
      key: 2,
      text: "Test Two",
      value: 2,
    },
    {
      key: 3,
      text: "Test Three",
      value: 3,
    },
  ];
  return (
    <div className="dropdown_container">
      <h3 className="dropdown_title">{title} </h3>
      <div className="Dropdown">
        <Dropdown
          placeholder={title}
          search
          selection
          options={testOptions}
          icon={null}
        />
        <img src={ArrowDownDropDownIcon} />
      </div>
    </div>
  );
}

export default Incident_detail_dropdown;
