import React, { useState, useEffect, useRef } from "react";
import { Button } from "semantic-ui-react";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import { db } from "../../../../../config/firebase";
import DraftFooter from "../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../components/ReopenFooter";

// images
import EditIcon from "../../../../../assets/images/incident/btn_edit.svg";
import DeleteIcon from "../../../../../assets/images/incident/btn_delete.svg";
import { faEye } from "@fortawesome/free-solid-svg-icons";

// css
import "./cases.css";
import CasualtyForm from "./forms/CasualtyForm";
import ClosedFooter from "../../../../../components/ClosedFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CasualtyDetails(props) {
  const {
    changeShowCasualyFormBool,
    changeHideCasualyFormBool,
    showCasualtyForm,
    casualtyValues,
  } = props;
  const [forms, setForms] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const scrollRef = useRef();
  // for checking create new one edit one
  // if new one, it'll be null
  // else casualty number
  const [selectedCasualtyNo, setSelectedCasualtyNo] = useState(null);
  const incidentStatus = props.incidentStatus;
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  console.log("authDetails.........", props.AuthDetail);

  let history = useHistory();
  console.log("form......", forms);
  function addForm(data) {
    let clonesForms = forms;
    const filteredForms = clonesForms.filter(
      (c) => c.casualty_no !== data.casualty_no
    );

    filteredForms.push(data);

    setForms(filteredForms);
    props.onChangeIncidentCasulaty(filteredForms);
  }
  function removeCasualty(item, index) {
    let newArr = [];
    forms.forEach((form, i) => {
      if (i == index) {
        newArr.push({ ...form, status: "delete" });
      } else {
        newArr.push({ ...form });
      }
    });
    setForms(newArr);
    props.onChangeIncidentCasulaty(newArr);
    // const filteredForms = newArr.filter((c, i) => c.status !== "delete");
    // setForms(filteredForms);
    // console.log("newArr........", filteredForms);
    // props.onChangeIncidentCasulaty(filteredForms);
  }

  useEffect(() => {
    if (casualtyValues) {
      setForms(casualtyValues);
    }
    const findDepartment = props.subDeparts.filter(
      (u) => u.title === props.AuthDetail.sub_department
      // (u) => console.log("u......", u.title)
    );
    // console.log("subDepartment..", props.subDeparts);
    // console.log("depar...", props.AuthDetail);
    // console.log("findCheck.....", findDepartment);
    // console.log("status....", props.incidentStatus);

    // if (selectedDetail) {

    if (props.AuthDetail.role === "junior_user") {
      if (props.incidentStatus === "Open") {
        if (props.AuthDetail.id === props.previousIncident.created_by) {
          setIsView(true);
          setIsEdit(true);
          console.log("junior user is the creator in Open");
        } else {
          setIsView(false);
          setIsEdit(false);
          // if (findDepartment.length > 0) {
          //   setIsView(false);
          //   setIsEdit(false)
          //   console.log(
          //     "junior user is not the creator and included in department in open"
          //   );
          // } else {
          //   setIsView(false);
          //   setIsEdit(false)
          //   console.log(
          //     "junior user is not the creator but not included in department in open"
          //   );
          // }
        }
      }
      // else if (
      //   props.incidentStatus === "Closed" ||
      //   props.incidentStatus === "Archived"
      // ) {
      //   setIsView(false);
      //   console.log("junior user is in close incident");
      // }
    } else {
      setIsView(true);
      setIsEdit(true);
      console.log("sernior user");
    }
    // } else {
    //   setIsView(false);
    //   setIsEdit(false);
    // }
  }, [casualtyValues, showCasualtyForm]);

  const { nationalityList, setNationalityList } = props.optionsState;

  useEffect(() => {
    // get nationalityList

    db.collection("static_data")
      .doc("nationality")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().nationality_arr.forEach((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });

        setNationalityList(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  function Navigation() {
    return (
      <>
        {incidentStatus == "Draft" ? (
          <DraftFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Pending Approval" ? (
          <PendingApprovalFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            authDetail={props.AuthDetail}
            created_by={props.previousIncident.created_by}
          />
        ) : incidentStatus == "Approve" ? (
          <PendingRejectFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Closed" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            onListenValueChange={props.onListenValueChange}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Open" ? (
          <ClosedFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Archived" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            isArchived={true}
            showNotiPage={props.showNotiPage}
            onListenValueChange={props.onListenValueChange}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : (
          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  let filterP4 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4");
  let filterP10 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p10");
  let filterP4A =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4a");
  let filterP5 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p5");

  console.log("filterP10......", filterP10);

  const scrollToTopFunction = () => {
    if (window.screen.width > 800) {
      scrollRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    scrollToTopFunction();
  }, [showCasualtyForm]);
  return (
    <div ref={scrollRef}>
      {!showCasualtyForm ? (
        <div className="incident_overview_container">
          <div className="casual_container">
            <div className="casual_item_container">
              {forms.length == 0 ? (
                <div style={{ textAlign: "center", color: "#8F8F8F" }}>
                  No Casualty added yet
                </div>
              ) : (
                forms.map((_, index) =>
                  _.status && _.status == "delete" ? null : (
                    <div className="casual_item">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="casual_dot"></div>
                        <p className="casual_text">
                          Casualty {index + 1} -{" "}
                          {filterP10
                            ? _.injury_name
                            : _.injury_name.replaceAll(/./g, "*")}{" "}
                        </p>
                      </div>
                      {/* {filterP10 ? ( */}
                      <div className="action_container">
                        {/* <div
                            className="delete_btn"
                            onClick={() => removeCasualty(_, index)}
                          >
                            <img src={DeleteIcon} />
                          </div> */}

                        {isView ? (
                          <div
                            onClick={() => {
                              setSelectedDetail(_);
                              changeShowCasualyFormBool();
                              if (_.casualty_no) {
                                setSelectedCasualtyNo(_.casualty_no);
                              } else {
                                setSelectedCasualtyNo(null);
                              }
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              className="sort-users-down-icon"
                            />
                            {/* <img src={EditIcon} /> */}
                          </div>
                        ) : null}
                      </div>
                      {/* ) : (
                        <div style={{ height: 35 }}></div>
                      )} */}
                    </div>
                  )
                )
              )}
            </div>
            <div
              onClick={() => {
                changeShowCasualyFormBool();
                setSelectedDetail(null);
                setSelectedCasualtyNo(null);
              }}
            >
              <div className="underline_add_btn "></div>
              {/* {filterP10 && ( */}
              {isView ? (
                <div
                  className="add_casual"
                  style={{
                    justifyContent: "flex-start",
                    marginTop: forms.length > 0 ? 0 : 10,
                  }}
                >
                  + Add Casualty
                </div>
              ) : null}

              {/* )} */}
            </div>
          </div>

          {/* Footer */}
          {filterP4A &&
            props.previousIncident &&
            props.previousIncident.filtered_category == "c1" &&
            props.previousIncident.created_by == props.AuthDetail.id && (
              <>{Navigation()}</>
            )}
          {filterP4 &&
            props.previousIncident &&
            props.previousIncident.filtered_category == "c1" && (
              <>{Navigation()}</>
            )}

          {filterP5 &&
            props.previousIncident &&
            props.previousIncident.filtered_category == "c2" && (
              <>{Navigation()}</>
            )}
          {filterP5 &&
            props.previousIncident &&
            props.previousIncident.filtered_category == "c3" && (
              <>{Navigation()}</>
            )}
          {filterP5 &&
            props.previousIncident &&
            props.previousIncident.filtered_category == "c4" && (
              <>{Navigation()}</>
            )}
          {props.previousIncident &&
          props.previousIncident.filtered_category == "" ? (
            <>
              {filterP4 && props.previousIncident && <>{Navigation()}</>}
              {filterP5 && props.previousIncident && <>{Navigation()}</>}
              {filterP5 && props.previousIncident && <>{Navigation()}</>}
              {filterP5 && props.previousIncident && <>{Navigation()}</>}
            </>
          ) : null}
          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end", zIndex: 1 }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CasualtyForm
          changeShowCasualyFormBool={changeShowCasualyFormBool}
          changeHideCasualyFormBool={changeHideCasualyFormBool}
          addForm={addForm}
          selectedDetail={selectedDetail}
          totalForms={forms}
          optionsState={{ nationalityList }}
          onListenValueChange={props.onListenValueChange}
          previousIncident={props.previousIncident}
          selectedCasualtyNo={selectedCasualtyNo}
          incidentStatus={incidentStatus}
          AuthDetail={props.AuthDetail}
          mainSaveButton={props.mainSaveButton}
          setIncidentStatus={props.setIncidentStatus}
          setCheckStatus={props.setCheckStatus}
          perms={props.AuthDetail.perms}
          prevCategory={props.previousIncident.filtered_category}
          customModalForConfirmCancel={props.customModalForConfirmCancel}
          valueChanged={props.valueChanged}
          showNotiPage={props.showNotiPage}
          overViewValues={props.overViewValues}
          newAddedSignature={props.newAddedSignature}
          setNewAddedSignature={props.setNewAddedSignature}
          isView={isView}
          setIsView={setIsView}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          forms={forms}
          setForms={setForms}
          onChangeIncidentCasulaty={props.onChangeIncidentCasulaty}
        />
      )}
    </div>
  );
}

export default CasualtyDetails;
