import React, { useState, useEffect } from "react";

import { Checkbox, Input, Button } from "semantic-ui-react";
import Incident_detail_dropdown from "../../../../../components/incident_detail_dropdown";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import TypeList from "../../../../../components/TypeList";
import { db } from "../../../../../config/firebase";
import DraftFooter from "../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../components/ReopenFooter";

//css
import "./cases.css";
import PlaceList from "../../../../../components/PlaceList";
import ClosedFooter from "../../../../../components/ClosedFooter";

function PropertyDamageDetails(props) {
  const values = props.propertyValues;
  const incidentStatus = props.incidentStatus;
  const onChangeIncidentProperty = props.onChangeIncidentProperty;
  const {
    propertyInputError,
    damagesError,
    partyError,
    proxityError,
    partyErrorTxt,
    damagesErrorTxt,
    partyTypeError,
    partyTypeErrorTxt,
  } = props.propertyError;
  let history = useHistory();
  const { priorityList, setPriorityList } = props.optionsState;

  useEffect(() => {
    // fetch priority
    db.collection("static_data")
      .doc("priority_of_fix")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().priority_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });

        setPriorityList(data);
      });
  }, []);
  const onListenValueChange = props.onListenValueChange;
  const previousIncident = props.previousIncident;

  const checkPreviousValueAndCurrent = (previous, current, key) => {
    let splitUnderScore = key.replace("_", " ");

    if (previous != current) {
      onListenValueChange(
        key,
        `Update ${splitUnderScore}  from ${previous} to ${current}`
      );
    } else {
      onListenValueChange(key, null);
    }
  };
  function Navigation() {
    return (
      <>
        {incidentStatus == "Draft" ? (
          <DraftFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Pending Approval" ? (
          <PendingApprovalFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            authDetail={props.AuthDetail}
            created_by={props.previousIncident.created_by}
          />
        ) : incidentStatus == "Approve" ? (
          <PendingRejectFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Closed" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            onListenValueChange={props.onListenValueChange}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Open" ? (
          <ClosedFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Archived" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            isArchived={true}
            showNotiPage={props.showNotiPage}
            onListenValueChange={props.onListenValueChange}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : (
          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  let filterP4 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4");
  let filterP4A =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4a");
  let filterP5 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p5");
  return (
    <div className="incident_overview_container">
      {/* Type of property */}
      <h3 className={`dropdown_title ${propertyInputError && "error"}`}>
        Type of Property{" "}
      </h3>
      <Input
        fluid
        className={`damaged_input ${damagesError && "error"}`}
        value={values.property_type}
        onKeyPress={(event) => {
          if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        onChange={(e) => {
          onChangeIncidentProperty("property_type", e.target.value);
          checkPreviousValueAndCurrent(
            previousIncident.case_details.property_damage_details.property_type,
            e.target.value,
            "property_type"
          );
        }}
      />
      {/* <TypeList
        custom_classes={`incident-place-list  incident-list-dropdown ${
          propertyInputError && "error"
        }`}
        icon_classes="incident-place-list-icon"
        value={values.property_type}
        onChange={(e, data) => {
          onChangeIncidentProperty("property_type", data.value);
          checkPreviousValueAndCurrent(
            previousIncident.case_details.property_damage_details.property_type,
            data.value,
            "property_type"
          );
        }}
      /> */}
      {propertyInputError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          Required field
        </div>
      )}
      {/* What is/are damaged   */}
      <div style={{ marginBottom: 30, marginTop: 30 }}>
        <h3 style={{ color: damagesError && "#E72D35" }}>
          What is/are damaged
        </h3>
        <Input
          fluid
          className={`damaged_input ${damagesError && "error"}`}
          value={values.damaged_things}
          onKeyPress={(event) => {
            if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            onChangeIncidentProperty("damaged_things", e.target.value);
            checkPreviousValueAndCurrent(
              previousIncident.case_details.property_damage_details
                .damaged_things,
              e.target.value,
              "damaged_things"
            );
          }}
        />
      </div>
      {damagesError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          {damagesErrorTxt}
        </div>
      )}
      {/* CheckBox   */}
      <div style={{ marginBottom: 30 }}>
        <h3 className={`dropdown_title ${partyTypeError && "error"}`}>
          Did the incident involved internal or external party?{" "}
        </h3>
        <Input
          fluid
          className={`damaged_input ${partyTypeError && "error"}`}
          value={values.party_type}
          onKeyPress={(event) => {
            if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            onChangeIncidentProperty("party_type", e.target.value);
          }}
        />
        {/* <div>
          <Checkbox
            radio
            label="Internal"
            checked={values.party_type == "internal"}
            onChange={() => {
              onChangeIncidentProperty("party_type", "internal");
              checkPreviousValueAndCurrent(
                previousIncident.case_details.property_damage_details
                  .party_type,
                "internal",
                "party_type"
              );
            }}
            className="checkBox"
          />
        </div>
        <div>
          <Checkbox
            radio
            label="External"
            checked={values.party_type == "external"}
            onChange={() => {
              onChangeIncidentProperty("party_type", "external");
              checkPreviousValueAndCurrent(
                previousIncident.case_details.property_damage_details
                  .party_type,
                "external",
                "party_type"
              );
            }}
            className="checkBox"
          />
        </div> */}
      </div>
      {partyTypeError && (
        <div
          style={{
            color: "#E72D35",
            marginLeft: 3,
            fontSize: 13,
            marginTop: -25,
            marginBottom: 15,
          }}
        >
          {partyTypeErrorTxt}
        </div>
      )}
      {/* Information of internal / external party  */}
      <div>
        <h3 style={{ color: partyError && "#E72D35" }}>
          Information of internal / external party
        </h3>
        <Input
          fluid
          className={`damaged_input ${partyError && "error"}`}
          value={values.party_info}
          onKeyPress={(event) => {
            if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            onChangeIncidentProperty("party_info", e.target.value);
            checkPreviousValueAndCurrent(
              previousIncident.case_details.property_damage_details.party_info,
              e.target.value,
              "party_info"
            );
          }}
        />
      </div>

      {partyError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          {partyErrorTxt}
        </div>
      )}
      {/* Priority of fix */}
      <h3 className={`dropdown_title ${proxityError && "error"}`}>
        Priority of Fix{" "}
      </h3>

      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown ${
          proxityError && "error"
        }`}
        icon_classes="incident-place-list-icon"
        value={values.priority_of_fix}
        onChange={(e, data) => {
          onChangeIncidentProperty("priority_of_fix", data.value);
          checkPreviousValueAndCurrent(
            previousIncident.case_details.property_damage_details
              .priority_of_fix,
            data.value,
            "priority"
          );
        }}
        options={priorityList}
      />
      {proxityError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          Required field
        </div>
      )}
      {/* Footer */}
      {filterP4A &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" &&
        props.previousIncident.created_by == props.AuthDetail.id && (
          <>{Navigation()}</>
        )}
      {filterP4 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" && <>{Navigation()}</>}

      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c2" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c3" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c4" && <>{Navigation()}</>}
      {props.previousIncident &&
      props.previousIncident.filtered_category == "" ? (
        <>
          {filterP4 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
        </>
      ) : null}
      <div
        className="incident_footer"
        style={{ bottom: 0, justifyContent: "flex-end", zIndex: 1 }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }} onClick={() => {}}>
            <Button
              className="witness_cancel_button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyDamageDetails;
