import React, { useState, useRef, useEffect } from "react";
import { Checkbox, Input, Button, Loader } from "semantic-ui-react";
import SignaturePad from "react-signature-canvas";
import Popup from "reactjs-popup";
import { storage } from "../../../../../config/firebase";

//css
import "reactjs-popup/dist/index.css";

// images
import ExitIcon from "../../../../../assets/images/incident/exit.svg";

function WitnessGuest(props) {
  const [day, setDay] = useState("AM");
  const [signatureImage, setSignatureImage] = useState(null);
  const [previousSignature, setPreviousSignature] = useState(null);
  const [loader, setLoader] = useState(false);
  const sigPad = useRef({});
  const [values, setValues] = useState({
    guest_name: "",
    guest_address: "",
    guest_email: "",
    guest_contact_number: "",
    witness_no: 0,
    remark: "",
    pdpa: "",
  });

  // error
  const [nameError, setNameError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [remarkError, setRemarkError] = useState(false);
  const [pdpaError, setPdpaError] = useState(false);

  const clearSignature = () => {
    sigPad.current.clear();
  };

  const {
    changeShowWitnessFormBool,
    changeHideWitnessFormBool,
    showWitnessForm,
    guestForm,
    selectedDetail,
  } = props;

  const doneSignature = () => {
    setSignatureImage(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  useEffect(() => {
    setLoader(false);
    let index = props.totalForms.length > 0 && props.totalForms.length - 1;
    let w_no =
      parseInt(
        props.totalForms.length > 0 &&
          props.totalForms[index].witness_no.split(/\s+/)[1]
      ) + 1;
    if (selectedDetail) {
      const {
        guest_name,
        guest_contact_number,
        guest_address,
        guest_email,
        signature,
        pdpa,
        remark,
        witness_no,
      } = selectedDetail;
      setValues({
        guest_name,
        guest_address,
        guest_email,
        guest_contact_number,
        witness_no,
        pdpa,
        remark,
      });
      setSignatureImage(signature);
      setPreviousSignature(signature);
    } else {
      setValues({
        guest_name: "",
        guest_address: "",
        guest_email: "",
        guest_contact_number: "",
        pdpa: "",
        remark: "",
        // witness_no: `Witness ${
        //   props.totalForms.length == 0 ? 1 : props.totalForms.length + 1
        // }`,
        witness_no: `Witness ${props.totalForms.length == 0 ? 1 : w_no}`,
      });
      setSignatureImage(null);
    }
  }, [selectedDetail]);

  const submitForm = () => {
    let data = {
      type: "guest",
      ...values,
      signature: signatureImage
        ? signatureImage.replace("data:image/png;base64,", "")
        : null,
    };

    if (data.guest_name == "") {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (data.guest_contact_number == "") {
      setContactError(true);
    } else {
      setContactError(false);
    }

    if (data.guest_address == "") {
      setAddressError(true);
    } else {
      setAddressError(false);
    }

    if (data.guest_email == "") {
      setemailError(true);
    } else {
      setemailError(false);
    }

    if (data.remark == "") {
      setRemarkError(true);
    } else {
      setPdpaError(false);
    }

    if (data.pdpa == "") {
      setPdpaError(true);
    } else {
      setPdpaError(false);
    }

    setLoader(true);
    setemailError(false);
    // if (
    //   !nameError &&
    //   !addressError &&
    //   !contactError &&
    //   !emailError &&
    //   signatureImage
    // ) {
    if (previousSignature) {
      storage
        .refFromURL(previousSignature)
        .delete()
        .catch((err) => console.log("err", err));
    }
    if (previousSignature == signatureImage) {
      guestForm(data);
      return changeHideWitnessFormBool();
    } else {
      const nameForImage = new Date().getTime() + Math.random();
      if (signatureImage) {
        storage
          .ref(`/signatures/${nameForImage}`)
          .putString(signatureImage, "data_url")
          .then(async (img) => {
            const image = await storage
              .ref(`/signatures/${nameForImage}`)
              .getDownloadURL();

            data.signature = image;
            guestForm(data);
            return changeHideWitnessFormBool();
          })
          .catch((err) => console.log("err", err));
      } else {
        guestForm(data);
        return changeHideWitnessFormBool();
      }
    }
    // }
    // else {
    //   console.log("weeee");
    //   return changeHideWitnessFormBool();
    // }
  };

  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        {/* First Line */}
        <div style={{ marginBottom: 30 }}>
          <h3 style={{ color: nameError && "#E72D35" }}>Name </h3>
          <Input
            fluid
            className={`damaged_input ${nameError && "error"}`}
            value={values.guest_name}
            onChange={(e) =>
              setValues({ ...values, guest_name: e.target.value })
            }
          />
        </div>
        {/* Second Line */}
        <h3 style={{ color: contactError && "#E72D35" }}>Contact Number </h3>
        <Input
          fluid
          className={`damaged_input ${contactError && "error"}`}
          value={values.guest_contact_number}
          onChange={(e) =>
            setValues({ ...values, guest_contact_number: e.target.value })
          }
        />
      </div>
      {/* Third Line */}
      <div style={{ marginBottom: 30 }}>
        <h3 style={{ color: addressError && "#E72D35" }}>Address </h3>
        <Input
          fluid
          className={`damaged_input ${addressError && "error"}`}
          value={values.guest_address}
          onChange={(e) =>
            setValues({ ...values, guest_address: e.target.value })
          }
        />
      </div>
      {/* Fourth Line */}
      <div style={{ marginBottom: 30 }}>
        <h3 style={{ color: emailError && "#E72D35" }}>Email Address </h3>
        <Input
          fluid
          className={`damaged_input ${emailError && "error"}`}
          value={values.guest_email}
          onChange={(e) =>
            setValues({ ...values, guest_email: e.target.value })
          }
        />
      </div>
      <div style={{ marginBottom: 30 }}>
        <h3 style={{ color: nameError && "#E72D35" }}>Remarks </h3>
        <Input
          fluid
          className={`damaged_input ${remarkError && "error"}`}
          value={values.remark}
          onChange={(e) => setValues({ ...values, remark: e.target.value })}
        />
      </div>
      <div style={{ marginBottom: 30 }}>
        <h3 style={{ color: nameError && "#E72D35" }}>PDPA Text </h3>
        <Input
          fluid
          className={`damaged_input ${pdpaError && "error"}`}
          value={values.pdpa}
          onChange={(e) => setValues({ ...values, pdpa: e.target.value })}
        />
      </div>
      <p>
        By signing this form, you consent to the collection, use and disclosure
        of your personal data for the purpose to manage and prepare any
        incidents or accidents report in accordance with the Personal Data
        Protection Act 2012 and our data protection policy is available at
      </p>
      <p>
        <a
          href="https://www.mandai.com/"
          target="_blank"
          style={{ color: "#228A4C" }}
        >
          www.mandai.com :{" "}
        </a>
      </p>
      <p>
        Please visit our website at www.mandai.com for further details on our
        data protection policy, including how you may access and correct your
        personal data or withdraw consent to the collection, use or disclosure
        of your personal data.
      </p>
      {/* eighth Line */}
      <div style={{ marginBottom: 100 }}>
        <h3 className="input_title">Signature</h3>
        <div
          style={{
            width: "100%",
            height: 350,
            background: "#F8F8F8",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #D8D8D8",
            borderRadius: 12,
          }}
        >
          <Popup
            modal
            trigger={
              <div
                style={{
                  width: "95%",
                  height: 300,
                  border: "1px solid #D8D8D8",
                  borderRadius: 12,
                  backgroundImage: `url(${signatureImage})`,
                  backgroundRepeat: "no-repeat",
                  position: "relative",
                  backgroundPosition: "center",
                }}
              >
                <div>
                  {" "}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setSignatureImage(null);
                    }}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      background: "none",
                      padding: "10px 30px",
                      color: "#008237",
                      fontWeight: "bold",
                      border: "1px solid #008237",
                      borderRadius: 12,
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            }
            closeOnDocumentClick={false}
          >
            {(close) => {
              return (
                <div className="signature_Container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img src={ExitIcon} onClick={close} />
                  </div>
                  <h3 className="input_title">Signature</h3>
                  <SignaturePad
                    ref={sigPad}
                    canvasProps={{
                      className: "signatureCanvas",
                    }}
                  />
                  <div>
                    <button
                      onClick={clearSignature}
                      className="signature_clear_button"
                    >
                      Clear
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button onClick={close} className="signature_reset_button">
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        doneSignature();
                        close();
                      }}
                      className="signature_done_button"
                    >
                      Done
                    </button>
                  </div>
                </div>
              );
            }}
          </Popup>
        </div>
      </div>
      {/* Footer */}
      <div className="witness_footer">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              changeHideWitnessFormBool();
            }}
          >
            <Button className="witness_cancel_button">Cancel</Button>
          </div>
          <div style={{ width: "100%" }} onClick={submitForm}>
            <Button className="witness_save_button">
              {" "}
              {loader ? (
                <Loader
                  active
                  inline="centered"
                  size="tiny"
                  inverted
                  indeterminate
                />
              ) : (
                <span>Save</span>
              )}{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WitnessGuest;
