import userActionTypes from "./actionTypes/userActionTypes";
import { auth } from "../../config/firebase";
import api from "../../config/api";
import config from "../../config/config";

const { GET_USER_DETAILS } = config.api_endpoint;

const {
  GET_LOGGED_IN_USER_DETAIL,
  GET_LOGGED_IN_USER_DETAIL_SUCCESS,
  GET_LOGGED_IN_USER_DETAIL_FAIL,
  USER_LOGOUT,
  STORE_TOKEN_USER,
} = userActionTypes;

export const getuserloginDetail = () => {
  return {
    type: GET_LOGGED_IN_USER_DETAIL,
  };
};

export const getuserloginDetailSuccess = (data) => {
  return {
    type: GET_LOGGED_IN_USER_DETAIL_SUCCESS,
    payload: data,
  };
};

export const getuserloginDetailFail = () => {
  return {
    type: GET_LOGGED_IN_USER_DETAIL_FAIL,
  };
};

export const getUserLoginDetail = (id) => {
  return (dispatch) => {
    // dispatch(getuserloginDetail());

    return api
      .get(GET_USER_DETAILS, {
        id: id,
      })
      .then((res) => {
        return dispatch(getuserloginDetailSuccess(res));
      })
      .catch((err) => {
        console.log("err", err);
        return dispatch(getuserloginDetailFail());
      });
  };
};

export const userLogoutT = () => {
  return {
    type: USER_LOGOUT,
  };
};

export const userLogout = () => {
  return (dispatch) => {
    return dispatch(userLogoutT());
  };
};

const storeTokeSuccess = (token) => {
  return {
    type: STORE_TOKEN_USER,
    payload: token,
  };
};
export const storeToken = (token) => {
  return (dispatch) => {
    return dispatch(storeTokeSuccess(token));
  };
};
