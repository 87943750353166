import React from "react";
import { Route } from "react-router";
import { USER_LIST_ROUTE, USER_DETAIL_ROUTE, USER_AUDIT_ROUTE, NEW_USER_ROUTE } from "../../routes";

import UserList from "./userList/userList";
import UserDetail from "./userDetail/userDetail";
import UserAudit from "./userAudit/userAudit";
import NewUserDetail from "./userDetail/newUserDetail";

export const User = () => (
  <React.Fragment>
    <Route exact path={USER_LIST_ROUTE} component={UserList} />
    <Route exact path={USER_DETAIL_ROUTE} component={UserDetail} />
    <Route exact path={USER_AUDIT_ROUTE} component={UserAudit} />
    <Route exact path={NEW_USER_ROUTE} component={NewUserDetail} />
  </React.Fragment>
);

export default User;
