import React, { useState, useEffect } from "react";
import { Dropdown, Input } from "semantic-ui-react";

import { db } from "../../../../config/firebase";

//dropdowns
import PlaceList from "../../../../components/PlaceList";
import DepartmentList from "../../../../components/DepartmentList";
import SubDepartmentList from "../../../../components/SubDepartmentList";
import RoleList from "../../../../components/RoleList";

//images
import UserDeactivatedIcon from "../../../../assets/images/user/user_deactivated.svg";

//css
import "./forms.css";

function UserProfile(props) {
  const [loader, setLoader] = useState(false);
  // console.log("userProfile props: ", props);
  const values = props.values;
  const onChangeProfileView = props.onChangeProfileView;
  // console.log("userProfile values: ", values);

  const {
    placeList,
    setPlaceList,
    roleList,
    setRoleList,
    departmentList,
    setDepartmentList,
    subDepartmentList,
    setSubDepartmentList,
  } = props.optionsState;

  const {
    nameError,
    emailError,
    emailErrorTxt,
    mobileError,
    staffidError,
    roleError,
    parkError,
    departmentError,
    subdepartmentError,
  } = props.profileViewInputError;

  const profileBool = props.profileBool;

  useEffect(() => {
    db.collection("static_data")
      .doc("parks")
      .get()
      .then((doc) => {
        let data = [];
        if (doc.data()) {
          doc.data()["parks_arr"].forEach((_park) => {
            data.push({
              key: _park.park,
              text: _park.park,
              value: _park.park,
            });
          });

          setPlaceList(data);
        }
      });

    db.collection("static_data")
      .doc("roles")
      .get()
      .then((doc) => {
        let data = [];
        if (doc.data()) {
          doc.data()["roles_arr"].forEach((_role) => {
            data.push({
              key: _role.id,
              text: _role.title,
              value: _role.id,
            });
          });

          setRoleList(data);
        }
      });

    db.collection("departments")
      .get()
      .then((doc) => {
        let data = [];
        if (doc.docs) {
          // console.log("values.sub_department:", values.department);
          var sel_dept_key = "";
          doc.docs.forEach((_dept) => {
            if (values.department) {
              if (values.department == _dept.data().title) {
                sel_dept_key = _dept.data().id;
              }
            }
            data.push({
              key: _dept.data().id,
              text: _dept.data().title,
              value: _dept.data().title,
            });
          });
        }
        // console.log("sel_dept_key:", sel_dept_key);
        setDepartmentList(data);

        if (sel_dept_key) {
          db.collection("sub_departments")
            .doc("Sub_" + sel_dept_key)
            .get()
            .then((doc) => {
              let subdata = [];
              if (doc.data()) {
                setSubDepartmentList([]);
                doc.data().sub_department_arr.forEach((_dept) => {
                  subdata.push({
                    key: _dept,
                    text: _dept,
                    value: _dept,
                  });
                });
              }
              // console.log("subdata:", subdata);
              // console.log("values.sub_department:", values.sub_department);
              setSubDepartmentList(subdata);
            });
        }
      });
  }, [values]);

  return (
    <div className="userprofile_container">
      {values == undefined ? (
        <div>Loading</div>
      ) : (
        <>
          {values.is_active == -1 && (
            <div className="user_deactivated_div">
              <img src={UserDeactivatedIcon} />
              <p>This user has been suspended.</p>
            </div>
          )}

          <h3
            style={{
              color: emailError && "#E72D35",
            }}
          >
            Email {!profileBool && "*"}{" "}
          </h3>
          <Input
            fluid
            className={`user_email ${emailError && "input_error_cls"}`}
            value={values.email && values.email}
            onChange={(e, data) => {
              onChangeProfileView("email", data.value.replace(/\s/g, ""));
            }}
            disabled={
              profileBool ? true : values.is_active == -1 ? true : false
            }
          />

          {emailError && <div className="input_error_text">{emailError}</div>}
          <h3 style={{ color: nameError && "#E72D35" }}>
            Name {!profileBool && "*"}{" "}
          </h3>
          <Input
            fluid
            className={`user_name ${nameError && "input_error_cls"}`}
            value={values.name && values.name}
            onChange={(e, data) => {
              onChangeProfileView("name", data.value);
            }}
            disabled={
              profileBool ? true : values.is_active == -1 ? true : false
            }
          />
          {nameError && <div className="input_error_text">{nameError}</div>}
          <h3 style={{ color: mobileError && "#E72D35" }}>
            Mobile No{!profileBool && "*"}{" "}
          </h3>
          <Input
            fluid
            className={`user_mobile ${mobileError && "input_error_cls"}`}
            value={values.mobile_no && values.mobile_no}
            onKeyPress={(event) => {
              // if (!/^[+]?\d+$/.test(event.key)) {
              if (!/^[\d\(\)\-+]+$/.test(event.key)) {
                event.preventDefault();
              }
            }}
            disabled={values.is_active == -1 ? true : false}
            onChange={(e, data) => {
              onChangeProfileView("mobile_no", data.value);
            }}
          />
          {mobileError && <div className="input_error_text">{mobileError}</div>}

          <h3 style={{ color: staffidError && "#E72D35" }}>
            Staff ID {!profileBool && "*"}{" "}
          </h3>
          <Input
            fluid
            className={`user_staff_id ${staffidError && "input_error_cls"}`}
            value={values.staff_id && values.staff_id}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e, data) => {
              onChangeProfileView("staff_id", data.value);
            }}
            disabled={
              profileBool ? true : values.is_active == -1 ? true : false
            }
          />
          {staffidError && (
            <div className="input_error_text">{staffidError}</div>
          )}

          <h3 style={{ color: roleError && "#E72D35" }}>
            Role {!profileBool && "*"}
          </h3>
          <RoleList
            custom_classes={`profile-list-dropdown ${
              roleError && "input_error_cls"
            }`}
            icon_classes="icon-profile-list-dropdown"
            value={values.role && values.role}
            onChange={(e, data) => {
              onChangeProfileView("role", data.value);
            }}
            options={roleList}
            profileBool={
              profileBool ? true : values.is_active == -1 ? true : false
            }
          />
          {roleError && (
            <div className="input_error_text">Please choose the role.</div>
          )}

          <h3 style={{ color: parkError && "#E72D35" }}>
            Park {!profileBool && "*"}
          </h3>
          <PlaceList
            custom_classes={`profile-list-dropdown ${
              parkError && "input_error_cls"
            }`}
            icon_classes="icon-profile-list-dropdown"
            value={values.park && values.park}
            onChange={(e, data) => {
              onChangeProfileView("park", data.value);
            }}
            options={placeList}
            profileBool={
              profileBool ? true : values.is_active == -1 ? true : false
            }
          />
          {parkError && (
            <div className="input_error_text">Please choose the park.</div>
          )}

          <h3 style={{ color: departmentError && "#E72D35" }}>
            Department {!profileBool && "*"}
          </h3>
          <DepartmentList
            custom_classes={`profile-list-dropdown ${
              departmentError && "input_error_cls"
            }`}
            icon_classes="icon-profile-list-dropdown"
            value={values.department && values.department}
            options={departmentList}
            profileBool={
              profileBool ? true : values.is_active == -1 ? true : false
            }
            onChange={(e, data) => {
              var dept_key = "";
              data.options.forEach((tmp) => {
                if (tmp.value == data.value) {
                  dept_key = tmp.key;
                }
              });
              onChangeProfileView("department", data.value);

              db.collection("sub_departments")
                .doc("Sub_" + dept_key)
                .get()
                .then((doc) => {
                  let data = [];

                  if (doc.data()) {
                    doc.data().sub_department_arr.forEach((_dept) => {
                      data.push({
                        key: _dept,
                        text: _dept,
                        value: _dept,
                      });
                    });
                  }
                  setSubDepartmentList(data);
                });
            }}
          />
          {departmentError && (
            <div className="input_error_text">
              Please choose the department.
            </div>
          )}

          <h3 style={{ color: subdepartmentError && "#E72D35" }}>
            Sub Department {!profileBool && "*"}{" "}
          </h3>
          <SubDepartmentList
            custom_classes={`profile-list-dropdown ${
              subdepartmentError && "input_error_cls"
            }`}
            icon_classes="icon-profile-list-dropdown"
            value={values.sub_department && values.sub_department}
            options={subDepartmentList}
            onChange={(e, data) => {
              onChangeProfileView("sub_department", data.value);
            }}
            profileBool={
              profileBool ? true : values.is_active == -1 ? true : false
            }
          />
          {subdepartmentError && (
            <div className="input_error_text">
              Please choose the sub department.
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default UserProfile;
