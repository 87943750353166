import React, { useState, useEffect } from "react";
import { Button, Input } from "semantic-ui-react";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../routes";
import { useHistory } from "react-router-dom";

//css
import "./cases.css";

function IncidentSummary(props) {
  const values = props.summaryValues;
  const onChangeIncidentSummary = props.onChangeIncidentSummary;
  const { titleError, remarkError } = props.summaryInputError;

  return (
    <div className="incident_overview_container">
      <div>
        <h3 style={{ color: titleError && "#E72D35" }}>
          Incident Title* ( Max 100 Characters Only )
        </h3>
        <Input
          fluid
          className={`damaged_input ${titleError && "error"}`}
          value={values.incident_title}
          maxLength="100"
          onChange={(e) => {
            onChangeIncidentSummary("incident_title", e.target.value);
            props.CheckfilledMatodaryField();
          }}
        />
      </div>
      {titleError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          Required field
        </div>
      )}
      <h3 style={{ color: remarkError && "#E72D35" }}>Incident Details*</h3>
      <textarea
        className={`remark_text_area ${remarkError && "error"}`}
        value={values.incident_remarks}
        onChange={(e) => {
          onChangeIncidentSummary("incident_remarks", e.target.value);
          props.CheckfilledMatodaryField();
        }}
      ></textarea>
      {remarkError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          Required field
        </div>
      )}
      {/* Footer */}
      <div
        className="incident_footer"
        style={{ bottom: -65, justifyContent: "flex-end" }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }} onClick={() => {}}>
            <Button className="witness_cancel_button">Cancel</Button>
          </div>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              props.mainSaveButton();
            }}
          >
            <Button className="witness_save_button">Save </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncidentSummary;
