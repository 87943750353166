const userGroupManagementTypes = {
  FETCH_GROUP_MANAGEMENT: "FETCH_GROUP_MANAGEMENT",
  FETCH_GROUP_MANAGEMENT_SUCCESS: "FETCH_GROUP_MANAGEMENT_SUCCESS",
  FETCH_GROUP_MANAGEMENT_FAIL: "FETCH_GROUP_MANAGEMENT_FAIL",

  FETCH_GP_USERS: "FETCH_GP_USERS",
  FETCH_GP_USERS_SUCCESS: "FETCH_GP_USERS_SUCCESS",
  FETCH_GP_USERS_FAIL: "FETCH_GP_USERS_FAIL",
};

export default userGroupManagementTypes;
