import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

//images
import ArrowDownDropDownIcon from "../assets/images/incident/arrowdowndrop.svg";

function UserGroupList(obj) {
  // later need to retrieve
  let groups = [
    {
      key: "Group 1",
      text: "Group 1",
      value: "Group 1",
    },
    {
      key: "Group 2",
      text: "Group 2",
      value: "Group 2",
    },
    {
      key: "Group 3",
      text: "Group 3",
      value: "Group 3",
    },
    {
      key: "Group 4",
      text: "Group 4",
      value: "Group 4",
    },
    {
      key: "Group 5",
      text: "Group 5",
      value: "Group 5",
    },
  ];

  return (
    <Dropdown
      fluid
      multiple
      // search
      selection
      options={groups}
      icon={<img src={ArrowDownDropDownIcon} className={obj["icon_classes"]} />}
      className={obj["custom_classes"]}
    />
  );
}

export default UserGroupList;
