import React, { useState, useEffect } from "react";
import { Checkbox, Input, Button } from "semantic-ui-react";
import WitnessEmployee from "./WitnessEmployee";
import WitnessGuest from "./WitnessGuest";

import EditIcon from "../../../../../../assets/images/incident/btn_edit.svg";
import DeleteIcon from "../../../../../../assets/images/incident/btn_delete.svg";

function WitnessForm(props) {
  const [checkBox, setCheckBox] = useState("guest");
  const [checkdayIncident, setCheckIncident] = useState(null);
  const [isView, setIsView] = useState(props.isView);
  const {
    changeShowWitnessFormBool,
    changeHideWitnessFormBool,
    showWitnessForm,
  } = props;

  const employeeForm = (values) => {
    props.addForm({
      employee_or_guest: "employee",
      ...values,
    });
  };

  const guestForm = (values) =>
    props.addForm({
      employee_or_guest: "guest",
      ...values,
    });

  const removeWitness = () => {
    let newArr = [];
    props.forms.forEach((form) => {
      if (form.witness_no == props.selectedWitnessNo) {
        newArr.push({ ...form, status: "delete" });
      } else {
        newArr.push({ ...form });
      }
    });
    props.setForms(newArr);
    props.onChangeIncidetWitness(newArr);
    props.changeHideWitnessFormBool();
  };

  useEffect(() => {
    if (props.selectedDetail) {
      setIsView(true);
      props.selectedDetail.employee_or_guest
        ? setCheckBox(props.selectedDetail.employee_or_guest)
        : setCheckBox(props.selectedDetail.employee_or_guest);
    } else {
      setIsView(false);
      setCheckBox("employee");
    }
  }, [props.selectedDetail]);

  return (
    <div className="witness_form_container">
      <div className="witness_form" style={{ display: "flex" }}>
        <div className="witness_form_title">Witness particulars</div>
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
          }}
        >
          {props.isEdit ? (
            isView ? (
              <div
                className="delete_btn"
                onClick={() => {
                  props.setIsView(false);
                  setIsView(false);
                }}
                // onClick={() => removeWitness(_, index)}
              >
                <img src={EditIcon} />
              </div>
            ) : null
          ) : null}
          {props.selectedDetail ? (
            <div className="delete_btn" onClick={removeWitness}>
              <img src={DeleteIcon} />
            </div>
          ) : null}
        </div>
      </div>
      {/* Check CheckBox */}
      {/* <div style={{ marginBottom: 30 }}>
        <h3>Witness is an employee or a guest? </h3>
        <div>
          <Checkbox
            radio
            label="Employee"
            checked={checkBox == "employee"}
            onChange={() => setCheckBox("employee")}
            className="checkBox"
          />
        </div>
        <div>
          <Checkbox
            radio
            label="Guest"
            checked={checkBox == "guest"}
            onChange={() => setCheckBox("guest")}
            className="checkBox"
          />
        </div>
      </div> */}
      <WitnessEmployee
        employeeForm={employeeForm}
        changeShowWitnessFormBool={changeShowWitnessFormBool}
        changeHideWitnessFormBool={changeHideWitnessFormBool}
        showWitnessForm={showWitnessForm}
        selectedDetail={props.selectedDetail}
        totalForms={props.totalForms}
        previousIncident={props.previousIncident}
        selectedWitnessNo={props.selectedWitnessNo}
        onListenValueChange={props.onListenValueChange}
        mainSaveButton={props.mainSaveButton}
        incidentStatus={props.incidentStatus}
        setIncidentStatus={props.setIncidentStatus}
        setCheckStatus={props.setCheckStatus}
        perms={props.AuthDetail.perms}
        prevCategory={props.previousIncident.filtered_category}
        customModalForConfirmCancel={props.customModalForConfirmCancel}
        valueChanged={props.valueChanged}
        showNotiPage={props.showNotiPage}
        overViewValues={props.overViewValues}
        AuthDetail={props.AuthDetail}
        newAddedSignature={props.newAddedSignature}
        setNewAddedSignature={props.setNewAddedSignature}
        isView={isView}
        setIsView={setIsView}
        isEdit={props.isEdit}
        setIsEdit={props.setIsEdit}
      />
      {/* {checkBox == "employee" ? (
        <WitnessEmployee
          employeeForm={employeeForm}
          changeShowWitnessFormBool={changeShowWitnessFormBool}
          changeHideWitnessFormBool={changeHideWitnessFormBool}
          showWitnessForm={showWitnessForm}
          selectedDetail={props.selectedDetail}
          totalForms={props.totalForms}
          previousIncident={props.previousIncident}
          selectedWitnessNo={props.selectedWitnessNo}
          onListenValueChange={props.onListenValueChange}
          mainSaveButton={props.mainSaveButton}
          incidentStatus={props.incidentStatus}
          setIncidentStatus={props.setIncidentStatus}
          setCheckStatus={props.setCheckStatus}
          perms={props.AuthDetail.perms}
          prevCategory={props.previousIncident.filtered_category}
          customModalForConfirmCancel={props.customModalForConfirmCancel}
          valueChanged={props.valueChanged}
          showNotiPage={props.showNotiPage}
          overViewValues={props.overViewValues}
          AuthDetail={props.AuthDetail}
        />
      ) : (
        <WitnessGuest
          guestForm={guestForm}
          changeShowWitnessFormBool={changeShowWitnessFormBool}
          changeHideWitnessFormBool={changeHideWitnessFormBool}
          showWitnessForm={showWitnessForm}
          selectedDetail={props.selectedDetail}
          totalForms={props.totalForms}
          previousIncident={props.previousIncident}
          selectedWitnessNo={props.selectedWitnessNo}
          onListenValueChange={props.onListenValueChange}
        />
      )} */}
    </div>
  );
}

export default WitnessForm;
