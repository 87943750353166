export const DASHBOARD_ROUTE = "/dashboard";

export const LOGIN_ROUTE = "/";
export const LOGIN_LINK = "/linkexistingaccount";
export const LOGIN_WITH_ID_ROUTE = "/login_with_id";
export const FORGOT_ROUTE = "/forgot";
export const RESET_OTP_ROUTE = "/reset_otp";
export const RESET_PASSWORD_ROUTE = "/reset_password";
export const RESET_SUCCESS_ROUTE = "/reset_success";

// Incidents
export const INCIDENTS_LIST_ROUTE = "/incidents_list";
export const INCIDENTS_DETAIL_ROUTE = "/incidents_list/detail";
export const INCIDENTS_AUDIT_ROUTE = "/incidents_list/audit";
export const NEW_INCIDENT_ROUTE = "/incidents_list/new_incident";
export const EXPORT_INCIDENT_ROUTE = "/incidents_list/export_incident_route";

// User Management
export const USER_LIST_ROUTE = "/user_management";
export const USER_DETAIL_ROUTE = "/user_management/detail";
export const USER_AUDIT_ROUTE = "/user_management/audit";
export const NEW_USER_ROUTE = "/user_management/new_user_detail";

// Group Management
export const GROUP_LIST_ROUTE = "/group_list";
export const VIEW_GROUP_ROUTE = "/group_list/detail";

export const SETTINGS_ROUTE = "/settings";

// notifcations
export const NOTIFICATION_List_ROUTE = "/notification_list";
export const NOTIFICATION_Detail_ROUTE =
  "/notification_list/notification_detail";
