import incidentActionTypes from "./actionTypes/incidentAcionTypes";
import api from "../../config/api";
import config from "../../config/config";
import moment from "moment";

const {
  GET_INCIDENTS,
  GET_AUDIT_INCIDENTS,
  GET_SUB_NOTIFICATIONS,
  GET_ALL_SUB_DEPARTMENT,
} = config.api_endpoint;

const {
  FETCH_ALL_INCIDENTS,
  FETCH_ALL_INCIDENTS_SUCCESS,
  FETCH_ALL_INCIDENTS_FAIL,
  FETCH_ALL_INCIDENT_EXPORT,
  FETCHING_ALL_INCIDENT_EXPORT,
  FETCH_ALL_INCIDENT_SEARCH_SUCCESS,
  FETCHING_ALL_INCIDENT_SEARCH,

  FETCH_ALL_AUDIT_INCIDENT,
  FETCH_ALL_AUDIT_INCIDENT_SUCCESS,
  FETCH_ALL_AUDIT_INCIDENT_FAIL,

  FETCH_SUB_NOTIFCATION,
  FETCH_SUB_NOTIFCATION_SUCCESS,

  FETCH_ALL_SUB_DEPARTMENT,
  FETCH_ALL_SUB_DEPARTMENT_SUCCESS,
} = incidentActionTypes;

// fetch incident Lists
export const fetchAllIncidentsRequest = () => {
  return {
    type: FETCH_ALL_INCIDENTS,
  };
};

export const fetchAllIncidentsSuccess = (data) => {
  return {
    type: FETCH_ALL_INCIDENTS_SUCCESS,
    payload: data,
  };
};

export const fetchAllIncidentsFail = (error) => {
  return {
    type: FETCH_ALL_INCIDENTS_FAIL,
    payload: error,
  };
};

export const fetchAllIncidents = (
  fromDate,
  toDate,
  category,
  park,
  zone,
  type,
  placement,
  currentPage,
  selectedDraft,
  selectedCase,
  uid,
  size,
  sorting,
  incidentNoSorting,
  search
) => {
  console.log(fromDate, toDate);
  return (dispatch) => {
    dispatch(fetchAllIncidentsRequest());

    fromDate = fromDate ? fromDate.replace(/\s/g, "%20") : "";
    toDate = toDate ? toDate.replace(/\s/g, "%20") : "";
    category = category == "All" ? "" : category.toLowerCase();
    park = park == "All" ? "" : park.replace(/\s/g, "%20");
    zone = zone == "All" ? "" : zone.replace(/\s/g, "%20");
    zone =
      zone == "Other: Pin current location"
        ? "Other: Pin current location"
        : zone;
    type = type == "All" ? "" : type;
    placement = placement == "All" ? "" : placement;
    currentPage = currentPage - 1;
    selectedDraft = selectedDraft == "All" ? "" : selectedDraft;
    selectedCase = selectedCase == "all cases" ? "" : selectedCase;

    return api
      .get(GET_INCIDENTS, {
        platform: "web",
        category: category,
        user_id: uid,
        case: selectedCase,
        incident_type: type,
        incident_placement: placement,
        park: park,
        zone_type: zone,
        start_date: fromDate,
        end_date: toDate,
        page: currentPage,
        size: size,
        incident_status: selectedDraft,
        sorting: sorting,
        incident_sorting: incidentNoSorting,
        search,
      })
      .then((res) => {
        dispatch(fetchAllIncidentsSuccess(res));
      })
      .catch((err) => {
        console.log("err", err);
        fetchAllIncidentsFail(err);
      });
  };
};

// fetch audit Lists
export const fetchAuditIncidentsRequest = () => {
  return {
    type: FETCH_ALL_AUDIT_INCIDENT,
  };
};

export const fetchAuditIncidentsSuccess = (data) => {
  return {
    type: FETCH_ALL_AUDIT_INCIDENT_SUCCESS,
    payload: data,
  };
};

export const fetchAuditIncidentsFail = (error) => {
  return {
    type: FETCH_ALL_AUDIT_INCIDENT_FAIL,
    payload: error,
  };
};

export const fetchAuditAllIncidents = (incident_id, page) => {
  return (dispatch) => {
    dispatch(fetchAuditIncidentsRequest());

    return api
      .get(GET_AUDIT_INCIDENTS, {
        platform: "web",
        incident_id: incident_id,
        page: page - 1,
        size: 10,
      })
      .then((res) => {
        dispatch(fetchAuditIncidentsSuccess(res));
      })
      .catch((err) => console.log("err", err));
  };
};

const fetchAllIncidentExportSuccess = (data) => {
  return {
    type: FETCH_ALL_INCIDENT_EXPORT,
    payload: data,
  };
};
const fetchAllIncidentExport = () => {
  return {
    type: FETCHING_ALL_INCIDENT_EXPORT,
  };
};
export const fetchAllIncidentsForExport = (
  fromDate,
  toDate,
  category,
  park,
  zone,
  type,
  placement,
  currentPage,
  selectedDraft,
  selectedCase,
  uid,
  size,
  sorting,
  incidentNoSorting,
  search
) => {
  return (dispatch) => {
    dispatch(fetchAllIncidentExport());
    fromDate = fromDate.replace(/\s/g, "%20");
    toDate = toDate.replace(/\s/g, "%20");
    category = category == "All" ? "" : category.toLowerCase();
    park = park == "All" ? "" : park.replace(/\s/g, "%20");
    zone = zone == "All" ? "" : zone.replace(/\s/g, "%20");
    zone =
      zone == "Other: Pin current location"
        ? "Other: Pin current location"
        : zone;
    type = type == "All" ? "" : type;
    placement = placement == "All" ? "" : placement;
    currentPage = currentPage - 1;
    selectedDraft = selectedDraft == "All" ? "" : selectedDraft;
    selectedCase = selectedCase == "all cases" ? "" : selectedCase;

    return api
      .get(GET_INCIDENTS, {
        platform: "web",
        category: category,
        user_id: uid,
        case: selectedCase,
        incident_type: type,
        incident_placement: placement,
        park: park,
        zone_type: zone,
        start_date: fromDate,
        end_date: toDate,
        page: currentPage,
        size: 1000,
        incident_status: selectedDraft,
        incident_sorting: "",
        search,
      })
      .then((res) => {
        dispatch(fetchAllIncidentExportSuccess(res));
      })
      .catch((err) => {
        console.log("err", err);
        // fetchAllIncidentsFail(err);
      });
  };
};

// --------------------

const fetchAllIncidentSearchSuccess = (data) => {
  return {
    type: FETCH_ALL_INCIDENT_SEARCH_SUCCESS,
    payload: { data },
  };
};

export const fetchAllIncidentsForSearch = (data) => {
  return (dispatch) => {
    if (data == undefined) {
      return dispatch(fetchAllIncidentSearchSuccess([]));
    } else {
      return dispatch(fetchAllIncidentSearchSuccess(data));
    }
  };
};

export const fetchingNotificationSubDepartment = () => {
  return {
    type: FETCH_SUB_NOTIFCATION,
  };
};
export const fetchNotificationSubDepartmentSuccess = (data) => {
  return {
    type: FETCH_SUB_NOTIFCATION_SUCCESS,
    payload: data,
  };
};

export const fetchNotificationSubDepartment = (page, size) => {
  return (dispatch) => {
    dispatch(fetchingNotificationSubDepartment());
    api
      .get(GET_SUB_NOTIFICATIONS, {
        platform: "web",
        size,
        page: page - 1,
      })
      .then((res) => {
        dispatch(fetchNotificationSubDepartmentSuccess(res));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const fetchingAllSubDepartment = () => {
  return {
    type: FETCH_ALL_SUB_DEPARTMENT,
  };
};

export const fetchAllSubDepartmentSuccess = (data) => {
  let id = 0;
  let updateData = [];
  data.data.map((d) => {
    id += 1;
    updateData.push({
      id,
      key: d,
      title: d,
      state: false,
    });
  });
  return {
    type: FETCH_ALL_SUB_DEPARTMENT_SUCCESS,
    payload: { data: updateData },
  };
};

export const fetchAllSubDepartment = () => {
  return (dispatch) => {
    dispatch(fetchingAllSubDepartment());
    api
      .get(GET_ALL_SUB_DEPARTMENT, {
        platform: "web",
      })
      .then((res) => {
        dispatch(fetchAllSubDepartmentSuccess(res));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const changingLoaderForExport = () => {
  return {
    type: "CHANGING_LOADER",
  };
};
export const changeLoaderForExport = () => {
  return (dispatch) => {
    dispatch(changingLoaderForExport());
  };
};

export const notiRedDotSuccess = (data) => {
  return {
    type: "FETCH_INCIDENT_NOTI_SUSCCESS",
    payload: data,
  };
};
export const notiRedDot = (data) => {
  return (dispatch) => {
    dispatch(notiRedDotSuccess(data));
  };
};

export const showShowZoneListSuccess = () => {
  return {
    type: "SHOW_ZONE_LIST",
    payload: true,
  };
};

export const showShowZoneList = () => {
  return (dispatch) => {
    dispatch(showShowZoneListSuccess());
  };
};
export const hideShowZoneListSuccess = () => {
  return {
    type: "HIDE_ZONE_LIST",
    payload: false,
  };
};

export const hideShowZoneList = () => {
  return (dispatch) => {
    dispatch(hideShowZoneListSuccess());
  };
};
