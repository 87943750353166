import React, { useEffect } from "react";
import { Route } from "react-router";
import {
  INCIDENTS_LIST_ROUTE,
  INCIDENTS_DETAIL_ROUTE,
  INCIDENTS_AUDIT_ROUTE,
  NEW_INCIDENT_ROUTE,
  EXPORT_INCIDENT_ROUTE,
} from "../../routes";

// pages
import IncidentList from "./incidentList/incidentList";
import Detail from "./detail/detail";
import Audit from "./detail/forms/Audit";
import NewIncident from "./new_incident/newIncident";

function index() {
  return (
    <div>
      <Route exact path={INCIDENTS_LIST_ROUTE} component={IncidentList} />
      <Route exact path={INCIDENTS_DETAIL_ROUTE} component={Detail} />
      <Route exact path={INCIDENTS_AUDIT_ROUTE} component={Audit} />
      <Route exact path={NEW_INCIDENT_ROUTE} component={NewIncident} />
    </div>
  );
}

export default index;
