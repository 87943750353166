import React, { useState, useEffect } from "react";
import { Loader, Button } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import {
  getUserLoginDetail,
  getuserloginDetailFail,
  getuserloginDetailSuccess,
} from "../../store/actions/loggedInUserDetailAction";
import { useLocation, Redirect, useHistory } from "react-router-dom";
//images
import { Link } from "react-router-dom";
import { auth } from "../../config/firebase";

import config from "../../config/config";
import axios from "axios";
import Firebase from "firebase";
import { confirmAlert } from "react-confirm-alert";
import ExitIcon from "../../assets/images/incident/exit.svg";
import api from "../../config/api";
import { browserName } from "react-device-detect";
import { DASHBOARD_ROUTE, LOGIN_LINK } from "../../routes";
import LoginLink from "./loginLink";

function Login(props) {
  // const microsoftProvider = new firebase.auth().OAuthProvider("microsoft.com");
  const {
    SEND_OTP,
    SETTINGS,
    SAVE_USER,
    SEND_EMAIL,
    GET_USER_DETAILS,
    SAVE_USER_AUDIT,
  } = config.api_endpoint;
  const { url } = config;
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramDisableError = query.get("disableerror");
  const resetParam = query.get("reset");
  const minusError = query.get("minusError");

  // password input type
  const [newPasswordState, setnewPasswordState] = useState({
    type: "password",
  });
  const [logInWrong, setLogInWrong] = useState(0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [disableError, setDisableError] = useState("");
  const [successReset, setSuccessReset] = useState("");
  const [loading, setLoading] = useState(false);
  const [linktoLogin, setLinktologin] = useState(false);
  let history = useHistory();
  const [errorAlert, setErrorAlert] = useState(false);
  const [attempts, setAttempts] = useState(null);
  const [credential, setCredential] = useState();

  const loginWithAzure = (e) => {
    e.preventDefault();
    const provider = new Firebase.auth.OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      // prompt: "consent",
      tenant: "68888baa-6230-4456-9d16-95d34db8fe8b",
      redirect_uri: "https://mandaiims.firebaseapp.com/__/auth/handler",
    });
    auth
      .signInWithPopup(provider)
      .then(async (data) => {
        setLoading(true);
        let accessToken = data.credential.accessToken;
        const AuthStr = "Bearer ".concat(accessToken);
        await axios
          .get(
            url +
              GET_USER_DETAILS +
              "?access_token=" +
              accessToken +
              "&platform=web",
            {
              headers: {
                Authorization: AuthStr,
              },
            }
          )
          .then((res) => {
            props.getuserloginDetailSuccess(res.data);
            setSuccessReset("");
            setLoading(false);
            const date = moment().add(7, "d").toDate();
            localStorage.setItem("authExpire", date);
            props.setLoggedIn(true);
            history.push(DASHBOARD_ROUTE);
            setError("");
            api
              .post(
                SAVE_USER_AUDIT,
                JSON.stringify({
                  user_id: res.data.data.id,
                  email: res.data.data.email,
                  changed_by: res.data.data.id,
                  description: [`Login on ${browserName}`],
                  platform: "web",
                }),
                {
                  headers: { "Content-Type": "application/json" },
                }
              )
              .then((res) => {
                setLoading(false);
              });
          })
          .catch((err) => {
            props.getuserloginDetailFail();
            setErrorAlert(true);
            setLoading(false);
            return confirmAlert({
              customUI: ({ onClose }) =>
                customModalText(onClose, JSON.stringify(err.message)),
            });
          });
      })
      .catch((err) => {
        // An error happened.
        if (err.code === "auth/account-exists-with-different-credential") {
          var pendingCred = err.credential;
          var email = err.email;
          setCredential(err);
          localStorage.setItem(
            "loginLink",
            JSON.stringify(JSON.stringify({ err }))
          );
          auth.fetchSignInMethodsForEmail(email).then(function (methods) {
            if (methods[0] === "password") {
              setLinktologin(true);
            }
          });
        }
      });
  };

  function customModalText(onClose, text) {
    return (
      <div className="loginAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Error Message
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>{text}</div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button
                className="login_cancel_button"
                onClick={() => {
                  onClose();
                  setErrorAlert(false);
                  history.push("/");
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (paramDisableError) {
      setDisableError("Your account is disabled");
    }
    if (resetParam) {
      setSuccessReset("Successfully changed password.");
    }
  }, []);

  useEffect(() => {
    axios
      .get(url + SETTINGS)
      .then((res) => {
        if (res.data.message == "Success") {
          setAttempts(res.data.data.failed_login_attempts);
        }
      })
      .catch((err) => console.log("err", err));
  }, []);

  useEffect(() => {
    if (logInWrong == attempts) {
      console.log("attempts", {
        email: username,
        is_active: 0,
        is_new: 0,
        platform: "web",
      });
      setLoading(true);

      axios
        .post(url + SAVE_USER, {
          email: username,
          is_active: 0,
          is_new: 0,
          platform: "web",
          is_disable_acc: true,
        })
        .then((result) => {
          setLogInWrong(0);
          setDisableError("Your account is disabled");
          setLoading(false);
          axios
            .post(url + SEND_EMAIL, {
              platform: "web",
              email: username,
              mail_type: "locked_acc",
            })
            .then((res) => console.log("lock res ", res))
            .catch((err) => console.log("err", err));
        })
        .catch((err) => {
          setLoading(false);
          setLogInWrong(0);
        });
    }
  }, [logInWrong]);

  useEffect(() => {
    setUsername(username.replace(/\s/g, ""));
  }, [username]);

  function AttributeValidationUsername(e) {
    setUsername(e.target.value.replace(/\s/g, ""));
  }

  useEffect(() => {
    setPassword(password.replace(/\s/g, ""));
  }, [password]);

  function AttributeValidationPassword(e) {
    setPassword(e.target.value.replace(/\s/g, ""));
  }

  return (
    <>
      {linktoLogin ? (
        <LoginLink credential={credential} />
      ) : (
        <form
          className="login_type_form"
          style={{ zIndex: errorAlert ? 99 : 9999999 }}
        >
          <div
            style={{
              fontSize: 15,
              fontWeight: "bold",
              marginBottom: 30,
              color: "#000000",
              fontSize: 18,
              fontFamily: "Poppins",
            }}
          >
            Login Using Your Account{" "}
          </div>
          <div className="login_btn" style={{ fontWeight: "bolder" }}>
            {loading || props.AuthLoading ? (
              <Loader active inline="centered" />
            ) : (
              <button onClick={loginWithAzure}>Login</button>
            )}
          </div>
          {/* <Link
        to="/login_with_id"
        style={{
          textAlign: "center",
          color: "#008237",
          textDecoration: "underline",
          marginTop: 30,
          marginRight: -20,
        }}
        >
        Login via User ID instead
      </Link> */}
        </form>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  AuthLoading: state.loggedInUserDetail.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getUserLoginDetail: (id) => dispatch(getUserLoginDetail(id)),
  getuserloginDetailSuccess: (res) => dispatch(getuserloginDetailSuccess(res)),
  getuserloginDetailFail: () => dispatch(getuserloginDetailFail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
