import React, { useState, useEffect, useRef, forwardRef } from "react";
import {
  Grid,
  Table,
  Dropdown,
  Input,
  Search,
  Menu,
  Icon,
  Button,
  Loader,
  Pagination,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useHistory, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAllUsers } from "../../../store/actions/userAccountAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { db, Timestamp } from "../../../config/firebase";

//images
import ExitIcon from "../../../assets/images/incident/exit.svg";
import CategoryImage from "../../../assets/images/incident/category.svg";
import ExportImage from "../../../assets/images/incident/export.svg";
import ArrowDownHeaderIcon from "../../../assets/images/incident/arrowdownheader.svg";
import ArrowDownDropDownIcon from "../../../assets/images/incident/arrowdowndrop.svg";
import CalendarIcon from "../../../assets/images/incident/ic_calendar.svg";
import SearchIcon from "../../../assets/images/incident/search.svg";
import { useMediaQuery } from "react-responsive";
import FilterIcon from "../../../assets/images/incident/filter.svg";
//css
import "./userList.css";
import "react-datepicker/dist/react-datepicker.css";
//font
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// Dropdowns
import PlaceList from "../../../components/PlaceList";
import CategoryList from "../../../components/CategoryList";
import DepartmentList from "../../../components/DepartmentList";
import TypeList from "../../../components/TypeList";
import RoleList from "../../../components/RoleList";
import IncidentPlacementList from "../../../components/IncidentPlacementList";
import momentTz from "moment-timezone";

function UserList(props) {
  const [fromDate, setFromDate] = useState({
    displayFromDate: "",
    apiFromDate: "",
  });
  const [toDate, setToDate] = useState({
    displayFromDate: "",
    apiFromDate: "",
  });
  const [sortData, setSortData] = useState({
    isDescending: true,
    label: "last_modified",
    count: 1,
  });
  const [sorting, setSorting] = useState(true);
  const [sortTriggerData, setSortTriggerData] = useState(false);
  const [sortingList, setSortingList] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedPlace, setSelectedPark] = useState("All");
  const [placeList, setPlaceList] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [departmentList, setDepartmentList] = useState([]);
  const [cacheData, setCacheData] = useState([]);
  const [showSearchData, setShowSearchData] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedRole, setSelectedRole] = useState("All");
  const [roleList, setRoleList] = useState([
    {
      key: "All",
      text: "All",
      value: "All",
    },
    {
      key: "Junior User",
      text: "Junior User",
      value: "junior_user",
    },
    {
      key: "Senior User",
      text: "Senior User",
      value: "senior_user",
    },
    {
      key: "Super User",
      text: "Super User",
      value: "super_user",
    },
    {
      key: "Admin",
      text: "Admin",
      value: "admin",
    },
    {
      key: "Super Admin",
      text: "Super Admin",
      value: "super_admin",
    },
  ]);
  const [showFilter, setShowFilter] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const scrollRef = useRef(null);

  const scrollToTopFunction = () => {
    // var topOfElement =
    //   document.querySelector(".user_list_contianer").offsetTop - 500;
    // window.scroll({ top: topOfElement });
    if (window.screen.width > 768) {
      scrollRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    scrollToTopFunction();

    setShowSearchData(false);
  }, [
    fromDate.apiFromDate,
    toDate.apiFromDate,
    selectedPlace,
    selectedDepartment,
    selectedRole,
    selectedFilter,
    currentPage,
  ]);

  let history = useHistory();
  let filterP9 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p9");
  let filterP12 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p12");
  useEffect(() => {
    if (!filterP12) {
      window.location.href = `/`;
    }
  }, []);
  let filterP13 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p13");
  const fetchUserListFunction = () => {
    props.fetchAllUsers(
      fromDate.apiFromDate,
      toDate.apiFromDate,
      selectedPlace,
      selectedDepartment,
      selectedRole,
      selectedFilter,
      currentPage,
      sortData.isDescending,
      sortData.label,
      searchInput
    );
  };

  useEffect(() => {
    db.collection("static_data")
      .doc("parks")
      .get()
      .then((doc) => {
        let data = [];
        data.push({
          key: "All",
          text: "All",
          value: "All",
        });

        if (doc.data()) {
          doc.data()["parks_arr"].forEach((_park) => {
            data.push({
              key: _park.park,
              text: _park.park,
              value: _park.park,
            });
          });

          setPlaceList(data);
        }
      });

    db.collection("departments")
      .get()
      .then((doc) => {
        let data = [];
        data.push({
          key: "All",
          text: "All",
          value: "All",
        });
        if (doc.docs) {
          doc.docs.forEach((_dept) => {
            data.push({
              key: _dept.data().id,
              text: _dept.data().title,
              value: _dept.data().title,
            });
          });
        }
        setDepartmentList(data);
      });
  }, []);

  // for fetching user lists
  useEffect(async () => {
    fetchUserListFunction();
  }, [
    fromDate,
    toDate,
    selectedPlace,
    selectedDepartment,
    selectedRole,
    selectedFilter,
    currentPage,

    showSearchData,
    // sortData,
  ]);

  const handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage;
    setCurrentPage(page);
  };

  let testOptions = [
    {
      key: 1,
      text: "Test One",
      value: 1,
    },
    {
      key: 2,
      text: "Test Two",
      value: 2,
    },
    {
      key: 3,
      text: "Test Three",
      value: 3,
    },
  ];
  const sortedDataFunction = () => {
    let cloneUserList = [...props.userList];
    if (sortData.label == "date") {
      cloneUserList.sort((a, b) =>
        sortData.isDescending
          ? new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          : new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    } else if (sortData.label == "staff") {
      cloneUserList.sort((a, b) =>
        sortData.isDescending
          ? b.staff_id - a.staff_id
          : a.staff_id - b.staff_id
      );
    } else if (sortData.label == "name") {
      cloneUserList.sort((a, b) =>
        sortData.isDescending
          ? b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 1
          : a.name.toLowerCase() < b.name.toLowerCase()
          ? 1
          : -1
      );
    } else if (sortData.label == "email") {
      cloneUserList.sort((a, b) =>
        sortData.isDescending
          ? b.email.toLowerCase() > a.email.toLowerCase()
            ? -1
            : 1
          : a.email.toLowerCase() < b.email.toLowerCase()
          ? 1
          : -1
      );
    } else if (sortData.label == "department") {
      cloneUserList.sort((a, b) =>
        sortData.isDescending
          ? b.department.toLowerCase() > a.department.toLowerCase()
            ? -1
            : 1
          : a.department.toLowerCase() < b.department.toLowerCase()
          ? 1
          : -1
      );
    } else if (sortData.label == "park") {
      cloneUserList.sort((a, b) =>
        sortData.isDescending
          ? b.park.toLowerCase() > a.park.toLowerCase()
            ? -1
            : 1
          : a.park.toLowerCase() < b.park.toLowerCase()
          ? 1
          : -1
      );
    }
    setSortingList(cloneUserList);
    setSortTriggerData(!sortTriggerData);
  };
  const timeRangeAlert = (message) => {
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="incidentAlertBox" onClick={onClose}>
            <div
              style={{
                width: 400,
                height: 200,
                backgroundColor: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "13px 20px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                onClick={() => {
                  onClose();
                }}
              >
                <img src={ExitIcon} />
              </div>
              <div
                style={{ fontSize: 16, fontWeight: "bold", marginBottom: 15 }}
              >
                Confirm to submit
              </div>
              <div style={{ fontSize: 14, marginBottom: 18 }}>{message}</div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <Button
                    className="witness_cancel_button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };
  useEffect(() => {
    if (props.userList.length > 0) {
      sortedDataFunction();
    }
  }, [props.userList, sortData.isDescending, sortData.label, sortData.count]);

  useEffect(() => {}, [
    sortingList,
    sortTriggerData,
    sortData,
    sortData.isDescending,
    sortData.label,
    sortData.count,
  ]);

  const responseUserStatus = (is_active, is_new) => {
    if (is_active == 1) {
      return "Active";
    } else if (is_active == 0 && is_new == 1) {
      return "Non Active";
    } else if (is_active == -1) {
      return "Archived";
    } else if (is_active == 0 && is_new == 0) {
      return "Locked";
    }
    // return "New";
  };

  const searchSubmit = () => {
    if (searchInput.length > 0) {
      setShowSearchData(true);
      props.fetchAllUsers(
        fromDate.apiFromDate,
        toDate.apiFromDate,
        selectedPlace,
        selectedDepartment,
        selectedRole,
        selectedFilter,
        currentPage,
        sortData.isDescending,
        sortData.label,
        searchInput
      );
    } else {
      setShowSearchData(false);
    }
  };

  const SideBar = () => {
    const CustomFromDatepicker = forwardRef(({ value, onClick }, ref) => (
      <div className="sidebar_datePicker" onClick={onClick} ref={ref}>
        <input type="text" value={value} />
        {value ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "#008237",
              fontSize: "small",
              cursor: "pointer",
              position: "absolute",
              right: 35,
              top: 13,
              // zIndex: 10,
              fontSize: 20,
              cursor: "pointer",
            }}
            onClick={() => {
              setFromDate({
                displayFromDate: "",
                apiFromDate: "",
              });
            }}
          >
            X
          </div>
        ) : null}
        <img src={CalendarIcon} />
      </div>
    ));
    const CustomToDatepicker = forwardRef(({ value, onClick }, ref) => (
      <div className="sidebar_datePicker" onClick={onClick} ref={ref}>
        <input type="text" value={value} />
        {value ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "#008237",
              fontSize: "small",
              cursor: "pointer",
              position: "absolute",
              right: 35,
              top: 13,
              // zIndex: 10,
              fontSize: 20,
              cursor: "pointer",
            }}
            onClick={() => {
              setToDate({
                displayToDate: "",
                apiToDate: "",
              });
            }}
          >
            X
          </div>
        ) : null}
        <img src={CalendarIcon} />
      </div>
    ));
    return (
      <div className="userlist_sidebar">
        <div>
          {/* ---- From Date*/}
          <div className="input_container">
            <div className="input_title">Account Join From</div>
            {/* <div className="sidebar_datePicker"> */}
            <DatePicker
              customInput={<CustomFromDatepicker />}
              onChangeRaw={(e) => e.preventDefault()}
              selected={fromDate.displayFromDate}
              onChange={(date) => {
                if (
                  toDate.displayFromDate != "" &&
                  date > toDate.displayFromDate
                ) {
                  timeRangeAlert(
                    'Plesae select "To Date" less than "From Date"'
                  );
                  setFromDate({
                    displayFromDate: "",
                    apiFromDate: "",
                  });
                } else {
                  setCurrentPage(1);
                  setFromDate({
                    displayFromDate: date,
                    apiFromDate: moment(date).format("D MMM YYYY"),
                  });
                  scrollToTopFunction();
                }
              }}
            />
            {/* <img src={CalendarIcon} /> */}
            {/* </div> */}
          </div>

          {/* ---- To Date*/}
          <div className="input_container">
            <div className="input_title">Account Join To</div>
            {/* <div className="sidebar_datePicker"> */}
            <DatePicker
              customInput={<CustomToDatepicker />}
              onChangeRaw={(e) => e.preventDefault()}
              selected={toDate.displayFromDate}
              onChange={(date) => {
                if (
                  fromDate.displayFromDate != "" &&
                  date < fromDate.displayFromDate
                ) {
                  timeRangeAlert(
                    'Plesae select "To Date" greater than "From Date"'
                  );
                  setToDate({
                    displayFromDate: "",
                    apiFromDate: "",
                  });
                } else {
                  setCurrentPage(1);
                  setToDate({
                    displayFromDate: date,
                    apiFromDate: moment(date).format("D MMM YYYY"),
                  });
                  scrollToTopFunction();
                }
              }}
            />
            {/* <img src={CalendarIcon} />
            </div> */}
          </div>

          {/* ---- Category*/}
          <div className="input_container">
            <div className="input_title">Role</div>
            <div>
              <PlaceList
                custom_classes="usermg-place-list user-list-dropdown"
                icon_classes="user-place-list-icon"
                options={roleList}
                value={selectedRole}
                onChange={(e, data) => {
                  setCurrentPage(1);
                  setSelectedRole(data.value);
                }}
              />
            </div>
          </div>

          {/* ---- Park*/}
          <div className="input_container">
            <div className="input_title">Park</div>
            <div>
              <PlaceList
                custom_classes="usermg-place-list user-list-dropdown"
                icon_classes="user-place-list-icon"
                options={placeList}
                value={selectedPlace}
                onChange={(e, data) => {
                  setCurrentPage(1);
                  setSelectedPark(data.value);
                }}
              />
            </div>
          </div>

          {/* ---- Zone*/}
          <div className="input_container">
            <div className="input_title">Department</div>
            <div>
              <PlaceList
                custom_classes="usermg-dept-list user-list-dropdown"
                icon_classes="user-place-list-icon"
                options={departmentList}
                value={selectedDepartment}
                onChange={(e, data) => {
                  setCurrentPage(1);
                  setSelectedDepartment(data.value);
                }}
              />
            </div>
          </div>
          {isMobile && (
            <Button
              className="witness_save_button"
              style={{ width: 150 }}
              onClick={() => {
                setShowFilter(false);
              }}
            >
              Exit
            </Button>
          )}
          {/* ----End */}
        </div>
      </div>
    );
  };

  const UserTableList = () => {
    return (
      <div style={{ marginTop: 50 }}>
        {isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            <div
              className={`mobile_filter_tabs ${
                selectedFilter == "All" && "active"
              }`}
            >
              <Button
                onClick={() => {
                  setShowSearchData(false);
                  setSearchInput("");
                  setSelectedFilter("All");
                  setCurrentPage(1);
                }}
              >
                All
              </Button>
            </div>
            <div
              className={`mobile_filter_tabs ${
                selectedFilter == "suspended" && "active"
              }`}
            >
              <Button
                onClick={() => {
                  setSelectedFilter("suspended");
                  setCurrentPage(1);

                  setShowSearchData(false);
                  setSearchInput("");
                }}
              >
                Archived
              </Button>
            </div>
            <div
              className={`mobile_filter_tabs ${
                selectedFilter == "locked" && "active"
              }`}
            >
              <Button
                onClick={() => {
                  setSelectedFilter("locked");
                  setCurrentPage(1);

                  setShowSearchData(false);
                  setSearchInput("");
                }}
              >
                Locked
              </Button>
            </div>
          </div>
        )}

        {!isMobile && (
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={2}>
                <div
                  className={`list_users ${
                    selectedFilter == "All" && "active"
                  }`}
                  onClick={() => {
                    setShowSearchData(false);
                    setSearchInput("");
                    setSelectedFilter("All");
                    setCurrentPage(1);
                  }}
                >
                  All
                </div>
              </Grid.Column>

              {/* <Grid.Column mobile={16} computer={2}>
                <div
                  className={`list_users ${
                    selectedFilter == "active" && "active"
                  }`}
                  onClick={() => {
                    setSelectedFilter("active");
                    setCurrentPage(1);
                    setSearchInput("");
                    setShowSearchData(false);
                  }}
                >
                  Active
                </div>
              </Grid.Column> */}

              {/* <Grid.Column mobile={16} computer={2}>
                <div
                  className={`list_users ${
                    selectedFilter == "non-active" && "active"
                  }`}
                  onClick={() => {
                    setSelectedFilter("non-active");
                    setCurrentPage(1);
                    setSearchInput("");
                    setShowSearchData(false);
                  }}
                >
                  Non Active
                </div>
              </Grid.Column> */}
              <Grid.Column mobile={16} computer={2}>
                <div
                  className={`list_users ${
                    selectedFilter == "suspended" && "active"
                  }`}
                  onClick={() => {
                    setSelectedFilter("suspended");
                    setCurrentPage(1);

                    setShowSearchData(false);
                    setSearchInput("");
                  }}
                >
                  Archived
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} computer={2}>
                <div
                  className={`list_users ${
                    selectedFilter == "locked" && "active"
                  }`}
                  onClick={() => {
                    setSelectedFilter("locked");
                    setCurrentPage(1);

                    setShowSearchData(false);
                    setSearchInput("");
                  }}
                >
                  Locked
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} computer={8}></Grid.Column>
              <Grid.Column mobile={16} computer={2}>
                {filterP13 && (
                  <div
                    className="user_add"
                    onClick={() =>
                      history.push("/user_management/new_user_detail")
                    }
                  >
                    <Icon name="plus" />
                    {/* <Button
                      className="add_new_user"
                      onClick={() =>
                        history.push("/user_management/new_user_detail")
                      }
                    > */}
                    Add
                    {/* </Button> */}
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        {/* Table Incident List */}
        {props.userListLoading ? (
          <Loader active inline="centered" />
        ) : isMobile ? (
          <div>
            {props.userList.length === 0 ? (
              <div
                style={{
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                No data found
              </div>
            ) : (
              <div>
                {props.userList.map((user) => {
                  return (
                    <div
                      key={user.id}
                      style={{
                        border: "1px solid #000",
                        padding: 10,
                        fontSize: 13,
                      }}
                      onClick={() => {
                        if (filterP9) {
                          return history.push(
                            `/user_management/detail?id=${user.id}`
                          );
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginBottom: 10 }}>{user.staff_id}</div>
                        <div style={{ marginBottom: 10 }}>
                          {" "}
                          {responseUserStatus(user.is_active, user.is_new)}
                        </div>
                      </div>
                      <div style={{ marginBottom: 10 }}>{user.name}</div>
                      <div style={{ marginBottom: 10 }}>{user.email}</div>
                      <div style={{ marginBottom: 10 }}>
                        {user.role && user.role.includes("_")
                          ? user.role.replaceAll("_", " ").split(" ").length > 1
                            ? user.role
                                .replaceAll("_", " ")
                                .split(" ")[0]
                                .charAt(0)
                                .toUpperCase() +
                              user.role
                                .replaceAll("_", " ")
                                .split(" ")[0]
                                .slice(1) +
                              "  " +
                              user.role
                                .replaceAll("_", " ")
                                .split(" ")[1]
                                .charAt(0)
                                .toUpperCase() +
                              user.role
                                .replaceAll("_", " ")
                                .split(" ")[1]
                                .slice(1)
                            : user.role.charAt(0).toUpperCase() +
                              user.role.slice(1)
                          : user.role}
                      </div>
                      <div style={{ marginBottom: 10 }}>{user.park}</div>
                      <div style={{ marginBottom: 10 }}>
                        {user.sub_department}
                      </div>
                      <div style={{ marginBottom: 10 }}>
                        {" "}
                        {momentTz
                          .tz(user.created_at, "Asia/Singapore")
                          .format("D MMM YYYY,")}
                        {"  "}
                        {momentTz
                          .tz(user.created_at, "Asia/Singapore")
                          .format("HH:mm")}
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {momentTz
                          .tz(user.latest_updated_at, "Asia/Singapore")
                          .format("D MMM YYYY,")}
                        {"  "}
                        {momentTz
                          .tz(user.latest_updated_at, "Asia/Singapore")
                          .format("HH:mm")}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <div className="table_user_list">
            <Table basic>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <div
                      className="userlist_tableHeader no"
                      onClick={() => {
                        setSortTriggerData(!sortTriggerData);
                        if (sortData.count == 1) {
                          setSortData({
                            isDescending: true,
                            label: "staff",
                            count: sortData.count + 1,
                          });
                        } else {
                          setSortData({
                            isDescending: !sortData.isDescending,
                            label: "staff",
                            count: sortData.count + 1,
                          });
                        }
                      }}
                    >
                      <div>Staff ID</div>
                      {sortData.count == 1 ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : sortData.isDescending && sortData.label == "staff" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          className="sort-users-up-icon"
                        />
                      ) : !sortData.isDescending &&
                        sortData.label == "staff" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : !sortData.isDescending &&
                        sortData.label != "staff" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      )}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div
                      className="userlist_tableHeader category"
                      onClick={() => {
                        setSortTriggerData(!sortTriggerData);
                        if (sortData.count == 1) {
                          setSortData({
                            isDescending: true,
                            label: "name",
                            count: sortData.count + 1,
                          });
                        } else {
                          setSortData({
                            isDescending: !sortData.isDescending,
                            label: "name",
                            count: sortData.count + 1,
                          });
                        }
                      }}
                    >
                      <div>Name</div>
                      {sortData.count == 1 ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : sortData.isDescending && sortData.label == "name" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          className="sort-users-up-icon"
                        />
                      ) : !sortData.isDescending && sortData.label == "name" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : !sortData.isDescending && sortData.label != "name" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      )}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div
                      className="userlist_tableHeader category"
                      onClick={() => {
                        setSortTriggerData(!sortTriggerData);

                        if (sortData.count == 1) {
                          setSortData({
                            isDescending: true,
                            label: "email",
                            count: sortData.count + 1,
                          });
                        } else {
                          setSortData({
                            isDescending: !sortData.isDescending,
                            label: "email",
                            count: sortData.count + 1,
                          });
                        }
                      }}
                    >
                      <div>Email</div>
                      {sortData.count == 1 ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : sortData.isDescending && sortData.label == "email" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          className="sort-users-up-icon"
                        />
                      ) : !sortData.isDescending &&
                        sortData.label == "email" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : !sortData.isDescending &&
                        sortData.label != "email" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      )}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="userlist_tableHeader approval">
                      <div>Role</div>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div
                      className="userlist_tableHeader park"
                      onClick={() => {
                        setSortTriggerData(!sortTriggerData);
                        if (sortData.count == 1) {
                          setSortData({
                            isDescending: true,
                            label: "park",
                            count: sortData.count + 1,
                          });
                        } else {
                          setSortData({
                            isDescending: !sortData.isDescending,
                            label: "park",
                            count: sortData.count + 1,
                          });
                        }
                      }}
                    >
                      <div>Park</div>
                      {sortData.count == 1 ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : sortData.isDescending && sortData.label == "park" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          className="sort-users-up-icon"
                        />
                      ) : !sortData.isDescending && sortData.label == "park" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : !sortData.isDescending && sortData.label != "park" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      )}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div
                      className="userlist_tableHeader type"
                      onClick={() => {
                        setSortTriggerData(!sortTriggerData);
                        if (sortData.count == 1) {
                          setSortData({
                            isDescending: true,
                            label: "department",
                            count: sortData.count + 1,
                          });
                        } else {
                          setSortData({
                            isDescending: !sortData.isDescending,
                            label: "department",
                            count: sortData.count + 1,
                          });
                        }
                      }}
                    >
                      <div>Department</div>
                      {sortData.count == 1 ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : sortData.isDescending &&
                        sortData.label == "department" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          className="sort-users-up-icon"
                        />
                      ) : !sortData.isDescending &&
                        sortData.label == "department" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : !sortData.isDescending &&
                        sortData.label != "department" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      )}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="userlist_tableHeader approval">
                      <div>Status</div>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div
                      className="userlist_tableHeader placement"
                      onClick={() => {
                        setSortTriggerData(!sortTriggerData);
                        let cloneSorting = !sorting;
                        let cloneLabel = "date";
                        setSorting(!sorting);

                        props.fetchAllUsers(
                          fromDate.apiFromDate,
                          toDate.apiFromDate,
                          selectedPlace,
                          selectedDepartment,
                          selectedRole,
                          selectedFilter,
                          1,
                          cloneSorting,
                          cloneLabel,
                          searchInput
                        );

                        // setSortData({
                        //   ...sortData,
                        //   isDescending: !sortData.isDescending,
                        // });

                        if (sortData.label === "last_modified") {
                          setSortData({
                            isDescending: !sortData.isDescending,
                            label: "date",
                            count: sortData.count + 1,
                          });
                        } else if (sortData.count == 1) {
                          setSortData({
                            isDescending: false,
                            label: "date",
                            count: sortData.count + 1,
                          });
                        } else {
                          setSortData({
                            isDescending: !sortData.isDescending,
                            label: "date",
                            count: sortData.count + 1,
                          });
                        }
                        // setSorting(!sorting);
                      }}
                    >
                      <div>Join Date</div>
                      {/* sortData.isDescending ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : */}
                      {sortData.isDescending && sortData.label == "date" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          className="sort-users-up-icon"
                        />
                      ) : !sortData.isDescending && sortData.label == "date" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : !sortData.isDescending && sortData.label != "date" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      )}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div
                      className="userlist_tableHeader placement"
                      onClick={() => {
                        setSortTriggerData(!sortTriggerData);
                        let cloneSorting = !sorting;
                        setSorting(!sorting);
                        let cloneLabel = "last_modified";
                        props.fetchAllUsers(
                          fromDate.apiFromDate,
                          toDate.apiFromDate,
                          selectedPlace,
                          selectedDepartment,
                          selectedRole,
                          selectedFilter,
                          1,
                          cloneSorting,
                          cloneLabel,
                          searchInput
                        );

                        // setSortData({
                        //   ...sortData,
                        //   isDescending: !sortData.isDescending,
                        // });

                        if (sortData.label == "date") {
                          setSortData({
                            isDescending: !sortData.isDescending,
                            label: "last_modified",
                            count: sortData.count + 1,
                          });
                        } else if (sortData.count == 1) {
                          setSortData({
                            isDescending: false,
                            label: "last_modified",
                            count: sortData.count + 1,
                          });
                        } else {
                          setSortData({
                            isDescending: !sortData.isDescending,
                            label: "last_modified",
                            count: sortData.count + 1,
                          });
                        }
                        // setSorting(!sorting);
                      }}
                    >
                      <div>Last Modified</div>
                      {/* sortData.isDescending ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : */}
                      {sortData.isDescending &&
                      sortData.label == "last_modified" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          className="sort-users-up-icon"
                        />
                      ) : !sortData.isDescending &&
                        sortData.label == "last_modified" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : !sortData.isDescending &&
                        sortData.label != "last_modified" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-users-down-icon"
                        />
                      )}
                    </div>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {/* let date = new Date(
          _.created_at.seconds * 1000 +
            _.created_at.nanoseconds / 1000000
        ); */}
                {console.log("props.userList", props.userList)}
                {props.userList.length === 0 ? (
                  <Table.Row>
                    <Table.Cell rowSpan={7}>
                      <div
                        style={{
                          height: 60,
                          display: "flex",
                          alignItems: "center",
                          padding: 10,
                        }}
                      >
                        No data found
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  sortingList.length > 0 &&
                  sortingList
                    // .sort((a, b) =>
                    //   isDescending
                    //     ? new Date(b.created_at).getTime() -
                    //       new Date(a.created_at).getTime()
                    //     : new Date(a.created_at).getTime() -
                    //       new Date(b.created_at).getTime()
                    // )
                    .map((_, index) => (
                      <Table.Row
                        key={_.id}
                        className="userlist_tableRow"
                        onClick={() => {
                          if (filterP9) {
                            return history.push(
                              `/user_management/detail?id=${_.id}`
                            );
                          }
                        }}
                      >
                        <Table.Cell alignitems="left">{_.staff_id}</Table.Cell>
                        <Table.Cell>{_.name}</Table.Cell>
                        <Table.Cell>{_.email}</Table.Cell>
                        <Table.Cell>
                          {_.role && _.role.includes("_")
                            ? _.role.replaceAll("_", " ").split(" ").length > 1
                              ? _.role
                                  .replaceAll("_", " ")
                                  .split(" ")[0]
                                  .charAt(0)
                                  .toUpperCase() +
                                _.role
                                  .replaceAll("_", " ")
                                  .split(" ")[0]
                                  .slice(1) +
                                "  " +
                                _.role
                                  .replaceAll("_", " ")
                                  .split(" ")[1]
                                  .charAt(0)
                                  .toUpperCase() +
                                _.role
                                  .replaceAll("_", " ")
                                  .split(" ")[1]
                                  .slice(1)
                              : _.role.charAt(0).toUpperCase() + _.role.slice(1)
                            : _.role}
                        </Table.Cell>
                        <Table.Cell>{_.park}</Table.Cell>
                        <Table.Cell>{_.department}</Table.Cell>
                        <Table.Cell>
                          {responseUserStatus(_.is_active, _.is_new)}
                        </Table.Cell>
                        <Table.Cell>
                          {/* {moment
                          .utc(_.created_at)
                          .local("SG")
                          .format("DD-MM-YYYY h:mm ")} */}
                          {momentTz
                            .tz(_.created_at, "Asia/Singapore")
                            .format("D MMM YYYY,")}
                          {"  "}
                          {momentTz
                            .tz(_.created_at, "Asia/Singapore")
                            .format("HH:mm")}
                          {/* {moment
                            .utc(_.created_at)
                            .local("SG")
                            .format("D MMM YYYY,")}
                          {"  "}
                          {moment.utc(_.created_at).local("SG").format("HH:mm")} */}
                        </Table.Cell>
                        <Table.Cell>
                          {/* {moment
                          .utc(_.created_at)
                          .local("SG")
                          .format("DD-MM-YYYY h:mm ")} */}
                          {momentTz
                            .tz(_.latest_updated_at, "Asia/Singapore")
                            .format("D MMM YYYY,")}
                          {"  "}
                          {momentTz
                            .tz(_.latest_updated_at, "Asia/Singapore")
                            .format("HH:mm")}
                          {/* {moment
                            .utc(_.created_at)
                            .local("SG")
                            .format("D MMM YYYY,")}
                          {"  "}
                          {moment.utc(_.created_at).local("SG").format("HH:mm")} */}
                        </Table.Cell>
                      </Table.Row>
                    ))
                )}
              </Table.Body>
            </Table>
          </div>
        )}
      </div>
    );
  };

  const onChangeFromDate = (date) => {
    setFromDate(date);
  };

  return (
    <>
      <div className="user_list_contianer" ref={scrollRef}>
        {!isMobile && (
          <div className="search_bar">
            <form
              className="search_bar_form"
              onSubmit={(e) => {
                e.preventDefault();
                searchSubmit();
              }}
            >
              <input
                type="text"
                onChange={(e) => {
                  if (e.target.value.length == 0) {
                    setShowSearchData(false);
                    props.fetchAllUsers(
                      fromDate.apiFromDate,
                      toDate.apiFromDate,
                      selectedPlace,
                      selectedDepartment,
                      selectedRole,
                      selectedFilter,
                      1,
                      sortData.isDescending,
                      sortData.label,
                      ""
                    );
                  }
                  setSearchInput(e.target.value);
                }}
                placeholder="Search"
                value={searchInput}
              />
              {searchInput.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#008237",
                    fontSize: "small",
                    cursor: "pointer",
                    position: "absolute",
                    right: 30,
                    top: 8,
                    zIndex: 10,
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowSearchData(false);
                    setSearchInput("");
                  }}
                >
                  X
                </div>
              )}
              <img
                src={SearchIcon}
                className="usersearch_icon"
                onClick={searchSubmit}
              />
            </form>
          </div>
        )}

        {isMobile && showFilter && (
          <div
            style={{
              position: "fixed",
              background: "rgba(0,0,0,.5)",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></div>
        )}
        {isMobile && showFilter && (
          <div className={`user_sidebar ${isMobile && "sidebar_mobile"}`}>
            <SideBar />
          </div>
        )}
        {!isMobile && (
          <div className={`user_sidebar`}>
            <SideBar />
          </div>
        )}
        <div
          className={`user_list ${
            isMobile ? "mobile_user_list" : "desktop_user_list"
          }`}
        >
          {isMobile && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flex: 0.2, padding: 10 }}>
                <div
                  style={{
                    background: "#008237",
                    height: 35,
                    borderRadius: 10,
                    fontSize: 35,
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    history.push("/user_management/new_user_detail")
                  }
                >
                  +
                </div>
              </div>
              <div style={{ flex: 0.9, padding: 10 }}>
                {" "}
                <form
                  className="search_bar_form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    searchSubmit();
                  }}
                >
                  <input
                    type="text"
                    onChange={(e) => {
                      if (e.target.value.length == 0) {
                        setShowSearchData(false);
                        props.fetchAllUsers(
                          fromDate.apiFromDate,
                          toDate.apiFromDate,
                          selectedPlace,
                          selectedDepartment,
                          selectedRole,
                          selectedFilter,
                          1,
                          sortData.isDescending,
                          sortData.label,
                          ""
                        );
                      }
                      setSearchInput(e.target.value);
                    }}
                    placeholder="Search"
                    value={searchInput}
                  />
                  {searchInput.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "#008237",
                        fontSize: "small",
                        cursor: "pointer",
                        position: "absolute",
                        right: 30,
                        top: 8,
                        zIndex: 10,
                        fontSize: 20,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowSearchData(false);
                        setSearchInput("");
                      }}
                    >
                      X
                    </div>
                  )}
                  <img
                    src={SearchIcon}
                    className="search_icon"
                    onClick={searchSubmit}
                  />
                </form>
              </div>
              <div
                style={{
                  flex: 0.2,
                  padding: 10,
                }}
              >
                <div onClick={() => setShowFilter(true)}>
                  <img src={FilterIcon} width="40px" height="40px" />
                </div>
              </div>
            </div>
          )}

          <UserTableList />
          <div style={{ margin: "10px 0px" }}></div>
          {!showSearchData &&
            !props.userListLoading &&
            props.userList.length > 0 && (
              <Pagination
                activePage={currentPage}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                floated="right"
                totalPages={props.total_pages}
                onPageChange={handlePaginationChange}
                className={`${
                  currentPage == 1
                    ? props.total_pages == currentPage
                      ? "paginat__fist_disable paginat__last_disable"
                      : "paginat__fist_disable"
                    : props.total_pages == currentPage
                    ? "paginat__last_disable"
                    : null
                }`}
                style={{
                  opacity: props.userListLoading ? 0 : 1,
                  marginTop: 30,
                  marginBottom: 30,
                }}
              />
            )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userList: state.userAccount.userLists,
  userListLoading: state.userAccount.loading,
  nexturl: state.userAccount.nexturl,
  total_pages: state.userAccount.total_pages,
  AuthDetail: state.loggedInUserDetail.userDetail,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllUsers: (
    fromDate,
    toDate,
    selectedPlace,
    selectedDepartment,
    selectedRole,
    selectedFilter,
    currentPage,
    sorting,
    label,
    search
  ) =>
    dispatch(
      fetchAllUsers(
        fromDate,
        toDate,
        selectedPlace,
        selectedDepartment,
        selectedRole,
        selectedFilter,
        currentPage,
        sorting,
        label,
        search
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserList);
