import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Button, Table, Pagination, Loader } from "semantic-ui-react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { connect } from "react-redux";
import { fetchAuditAllIncidents } from "../../../../store/actions/incidentAction";

//images
import LeftArrowIcon from "../../../../assets/images/incident/leftarrow.svg";
import ExitIcon from "../../../../assets/images/incident/exit.svg";
import momentTz from "moment-timezone";

function Audit(props) {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [currentPage, setCurrentPage] = useState(1);
  const paramId = query.get("id");
  useEffect(() => {
    try {
      props.fetchAuditAllIncidents(paramId, currentPage);
      setCurrentPage(1);
    } catch (error) {
      console.log("ErrorTT...", error);
    }
  }, [paramId]);

  const handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage;
    setCurrentPage(page);
  };

  useEffect(() => {
    props.fetchAuditAllIncidents(paramId, currentPage);
  }, [currentPage]);

  const displayLoopDescription = (a) => {
    for (let i = 0; i < a.length; i++) {
      if (i == a.length - 1) {
        return a;
      } else {
        return `${a} , `;
      }
    }
  };

  const history = useHistory();
  return (
    <div className="audit_container">
      <div>
        <div
          to=""
          className="audit_header_leftside"
          onClick={() => history.goBack()}
        >
          <img src={LeftArrowIcon} style={{ marginTop: 0 }} />
          <div className="audit_title">View Audit Trail</div>
        </div>
      </div>

      {/* List */}
      {/* <div className="audit_list">
        <h3>Audit Trail for Category 1, A guest slip and fall</h3>
      </div> */}
      <Table basic>
        {/* Header */}
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Timestamp</Table.HeaderCell>
            <Table.HeaderCell width={3}>Changed By</Table.HeaderCell>
            <Table.HeaderCell width={7}>Description of Change</Table.HeaderCell>
            <Table.HeaderCell width={3}>Details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {/* Body */}
        {props.auditLoading ? (
          <div
            style={{
              height: 60,
              display: "flex",
              alignItems: "center",
              width: "96%",
              padding: 10,

              position: "absolute",
            }}
          >
            <Loader active inline="centered" />
          </div>
        ) : (
          <Table.Body>
            {props.auditList.length == 0 ? (
              <div
                style={{
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  width: "96%",
                  padding: 10,
                  backgroundColor: "white",
                  position: "absolute",
                }}
              >
                No data found
              </div>
            ) : (
              props.auditList.map((a) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      {/* {a.timestamp} */}
                      {momentTz
                        .tz(Date.parse(a.timestamp), "Asia/Singapore")
                        .format("D MMM YYYY,")}
                      {"  "}
                      {momentTz
                        .tz(a.timestamp, "Asia/Singapore")
                        .format("HH:mm")}
                    </Table.Cell>
                    <Table.Cell>{a.changed_by_name}</Table.Cell>
                    <Table.Cell>
                      {/* {a.description.map((d) => 
                        <>{d} ,</>
                      ))} */}
                      {a.description
                        .toString()
                        .replace(/, /g, ",")
                        .replace(/,/g, ",   ")}
                      {/* {displayLoopDescription(a.description)} */}
                    </Table.Cell>
                    <Table.Cell>
                      <Popup
                        modal
                        nested
                        trigger={
                          <Button className="audit_btn">View Details</Button>
                        }
                      >
                        {(close) => {
                          return (
                            <div className="audit_popup">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <img src={ExitIcon} onClick={close} />
                              </div>
                              <div>
                                <h3 className="title">Details</h3>
                                <div className="audit_detail_content">
                                  <div
                                    style={{
                                      borderBottom: "1px solid #d8d8d8",
                                    }}
                                  >
                                    <div className="header">
                                      <div className="audit_name">
                                        {a.changed_by_name}
                                      </div>
                                      <div className="audit_dot"></div>
                                      <div>{a.role.replace("_", " ")}</div>
                                    </div>
                                    <div className="audit_timestamp">
                                      {momentTz
                                        .tz(
                                          Date.parse(a.timestamp),
                                          "Asia/Singapore"
                                        )
                                        .format("D MMM YYYY,")}
                                      {"  "}
                                      {momentTz
                                        .tz(a.timestamp, "Asia/Singapore")
                                        .format("HH:mm")}
                                    </div>
                                  </div>
                                  <div className="audit_description">
                                    <div className="description_title ">
                                      Description of Change
                                    </div>
                                    <div className="description_content">
                                      {a.description.map((des) => {
                                        return (
                                          <div className="description_name">
                                            {des}.
                                          </div>
                                        );
                                      })}
                                      {/* <div className="description_name">
                                      First name defined as <span>Herry</span>
                                    </div>
                                    <div className="description_name">
                                      Contact details of casualty defined as{" "}
                                      <span>”903778468”</span>
                                    </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </Popup>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        )}
      </Table>
      {props.auditList.length > 0 && (
        <Pagination
          activePage={currentPage}
          firstItem={null}
          lastItem={null}
          siblingRange={3}
          floated="right"
          totalPages={props.total_pages}
          onPageChange={handlePaginationChange}
          style={{ opacity: props.auditLoading ? 0 : 1 }}
        />
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  auditList: state.auditIncident.auditLists,
  auditLoading: state.auditIncident.loading,
  nexturl: state.auditIncident.nexturl,
  total_pages: state.auditIncident.total_pages,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAuditAllIncidents: (id, page) =>
    dispatch(fetchAuditAllIncidents(id, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Audit);
