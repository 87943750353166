import React, { useState, useEffect } from "react";
import { Button, Form, Input, TextArea } from "semantic-ui-react";

import Incident_detail_dropdown from "../../../../../components/incident_detail_dropdown";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import TypeList from "../../../../../components/TypeList";
import { db } from "../../../../../config/firebase";

import "../cases/cases.css";
import PlaceList from "../../../../../components/PlaceList";
import DraftFooter from "../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../components/ReopenFooter";
import ClosedFooter from "../../../../../components/ClosedFooter";

function RootCauseAnalysis(props) {
  const values = props.rootCauseValues;
  const incidentStatus = props.incidentStatus;
  const onChangeRootCauseAction = props.onChangeRootCauseAction;
  const { unsafe_act_Error, unsafe_condition_Error } = props.RootCauseError;
  const {
    unsafeActList,
    setUnsafeActList,
    unsafeConditionList,
    setUnsafeConditionList,
  } = props.optionsState;

  useEffect(() => {
    // fetch unsafe act
    db.collection("static_data")
      .doc("unsafe_act")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().unsafe_act_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setUnsafeActList(data);
      });
    // fetch unsafe condition
    db.collection("static_data")
      .doc("unsafe_condition")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().unsafe_condition_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setUnsafeConditionList(data);
      });
  }, []);

  let history = useHistory();
  const onListenValueChange = props.onListenValueChange;

  const checkPreviousValueAndCurrent = (previous, current, key) => {
    let splitUnderScore = key.replace("_", " ");
    if (previous != current) {
      onListenValueChange(
        key,
        `Update ${splitUnderScore}  from ${previous} to ${current}`
      );
    } else {
      onListenValueChange(key, null);
    }
  };

  function Navigation() {
    return (
      <>
        {incidentStatus == "Draft" ? (
          <DraftFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Pending Approval" ? (
          <PendingApprovalFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            authDetail={props.AuthDetail}
            created_by={props.previousIncident.created_by}
          />
        ) : incidentStatus == "Approve" ? (
          <PendingRejectFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Closed" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            onListenValueChange={props.onListenValueChange}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Open" ? (
          <ClosedFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
          />
        ) : incidentStatus == "Archived" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            isArchived={true}
            showNotiPage={props.showNotiPage}
            onListenValueChange={props.onListenValueChange}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : (
          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  let filterP4 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4");
  let filterP4A =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4a");

  let filterP5 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p5");
  return (
    <div className="incident_overview_container">
      <h3
        style={{ color: unsafe_act_Error && "#E72D35" }}
        className="dropdown_title"
      >
        Unsafe Act{" "}
      </h3>
      <PlaceList
        custom_classes={`incident-place-list incident-list-dropdown {${
          unsafe_act_Error && "error"
        }}`}
        icon_classes="incident-place-list-icon"
        value={values.unsafe_act}
        onChange={(e, data) => {
          onChangeRootCauseAction("unsafe_act", data.value);
          checkPreviousValueAndCurrent(
            props.previousIncident.aar.root_cause.unsafe_act,
            data.value,
            "unsafe_act"
          );
        }}
        options={unsafeActList}
      />
      {unsafe_act_Error && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a selection
        </div>
      )}
      {/* Unsafe condition */}
      <h3
        style={{ color: unsafe_condition_Error && "#E72D35" }}
        className="dropdown_title"
      >
        Unsafe Condition{" "}
      </h3>
      <PlaceList
        custom_classes={`incident-place-list incident-list-dropdown {${
          unsafe_condition_Error && "error"
        }}`}
        icon_classes="incident-place-list-icon"
        value={values.unsafe_condition}
        onChange={(e, data) => {
          onChangeRootCauseAction("unsafe_condition", data.value);
          checkPreviousValueAndCurrent(
            props.previousIncident.aar.root_cause.unsafe_cond,
            data.value,
            "unsafe_condition"
          );
        }}
        options={unsafeConditionList}
      />
      {unsafe_condition_Error && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>
          Please make a selection
        </div>
      )}

      {/* Footer */}
      {filterP4A &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" &&
        props.previousIncident.created_by == props.AuthDetail.id && (
          <>{Navigation()}</>
        )}
      {filterP4 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" && <>{Navigation()}</>}

      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c2" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c3" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c4" && <>{Navigation()}</>}
      <div
        className="incident_footer"
        style={{ bottom: 0, justifyContent: "flex-end", zIndex: 1 }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }} onClick={() => {}}>
            <Button
              className="witness_cancel_button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RootCauseAnalysis;
