import React, { useState, useEffect } from "react";
import { Checkbox, Button, Dropdown } from "semantic-ui-react";
import PlaceList from "../../../../components/PlaceList";
import IncidentLocation from "./IncidentLocation";
import PhotoVideo from "./PhotoVideo";
import { db } from "../../../../config/firebase";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert";
//css
import "./cases.css";

//images
import Category_Question from "../../../../assets/images/incident/category_question.svg";
import CategoryOne from "../../../../assets/images/incident/category1.svg";
import CategoryTwo from "../../../../assets/images/incident/category2.svg";
import CategoryThree from "../../../../assets/images/incident/category3.svg";
import CategoryFour from "../../../../assets/images/incident/category4.svg";
import CategoryChecked from "../../../../assets/images/incident/category_checked.svg";
import ExitIcon from "../../../../assets/images/incident/exit.svg";
import ArrowDownDropDownIcon from "../../../../assets/images/incident/arrowdowndrop.svg";

import { useLocation, useHistory } from "react-router";
import IncidentSummary from "./IncidentSummary";

function Incident_overview(props) {
  // loader
  const [typeListLoader, setTypeListLoader] = useState(false);
  const [placementListLoader, setPlacementLoader] = useState(false);
  const [classificationLoader, setClassificationLoader] = useState(false);
  const [injuryLoader, setInjuryLoader] = useState(false);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramId = query.get("id");
  const paramPendingBool = query.get("pending");
  let history = useHistory();
  const [videoModal, setVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [weatherList, setWeatherList] = useState([]);
  const [conditionAreaList, setConditionAreaList] = useState([]);

  let categoryOptions = [
    {
      value: "c1",
      image: CategoryOne,
    },
    {
      value: "c2",
      image: CategoryTwo,
    },
    {
      value: "c3",
      image: CategoryThree,
    },
    {
      value: "c4",
      image: CategoryFour,
    },
  ];

  const {
    incidentTypeList,
    setIncidentTypeList,
    incidentPlacementList,
    setIncidentPlacementList,
    incidentClassificationList,
    setIncidentClassificationList,
    injuryTypesList,
    setInjuryTypesList,
    parkList,
    setParkList,
    zoneList,
    setZoneList,
  } = props.optionsState;

  const changeDropDownId = props.changeDropDownId;

  useEffect(() => {
    setTypeListLoader(true);
    db.collection("incident_types")
      .get()
      .then((querySnapshot) => {
        let data = [];
        setIncidentTypeList([]);
        querySnapshot.forEach((doc) => {
          data.push({
            key: doc.data().incident_placements,
            text: doc.data().title,
            value: doc.data().title,
          });
        });

        setTypeListLoader(false);
        setIncidentTypeList(data);
      })
      .catch((err) => {
        setTypeListLoader(false);
        console.log("err", err);
      });
    db.collection("static_data")
      .doc("weather")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().weather_arr.forEach((doc, index) => {
          data.push({
            value: doc,
            text: doc,
            key: doc,
          });
        });

        setWeatherList(data);
      });
    db.collection("static_data")
      .doc("area_condition")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().area_condition_arr.forEach((doc, index) => {
          data.push({
            key: doc,
            text: doc,
            value: doc,
          });
        });

        setConditionAreaList(data);
      });
  }, []);

  // get storage dropdown id
  useEffect(() => {
    if (paramId && paramPendingBool) {
      const items = JSON.parse(localStorage.getItem("pending"));

      const filteredData = items.filter((i) => i.id == paramId);

      if (filteredData.length > 0 && filteredData[0].dropdownId) {
        const { incidentPlacementId, incidentClassificationId, injuryTypesId } =
          filteredData[0].dropdownId;
        if (incidentPlacementId) fetchPlacementOptions(incidentPlacementId);
        if (incidentClassificationId)
          fetchClassificationOptions(incidentClassificationId);
        if (injuryTypesId) fetchInjuryOptions(injuryTypesId);
      } else {
      }
    }
  }, []);

  // second
  const fetchPlacementOptions = (id) => {
    setPlacementLoader(true);
    changeDropDownId("incidentPlacementId", id);
    setIncidentPlacementList([]);
    db.collection("incident_placements")
      .doc(id)
      .get()
      .then((querySnapshot) => {
        let data = [];
        setIncidentPlacementList([]);
        querySnapshot.data().incident_placement_arr.map((doc, index) => {
          data.push({
            key: doc.incident_classifications,
            text: doc.title,
            value: doc.title,
          });
        });
        setIncidentPlacementList(data);

        setPlacementLoader(false);
      })
      .catch((err) => {
        setPlacementLoader(false);
        console.log("err", err);
      });
  };
  // Third
  const fetchClassificationOptions = (id) => {
    setClassificationLoader(true);
    changeDropDownId("incidentClassificationId", id);
    db.collection("incident_classifications")
      .doc(id)
      .get()
      .then((querySnapshot) => {
        let data = [];
        setIncidentClassificationList([]);
        querySnapshot.data().incident_classification_arr.map((doc, index) => {
          data.push({
            key: doc.injury_types,
            text: doc.title,
            value: doc.title,
          });
        });

        setIncidentClassificationList(data);

        setClassificationLoader(false);
      })
      .catch((err) => {
        setClassificationLoader(false);
        console.log("err", err);
      });
  };

  // Four
  const fetchInjuryOptions = (id) => {
    setInjuryLoader(true);
    changeDropDownId("injuryTypesId", id);
    db.collection("injury_types")
      .doc(id)
      .get()
      .then((querySnapshot) => {
        let data = [];
        setInjuryTypesList([]);
        querySnapshot.data().injury_type_arr.map((doc, index) => {
          data.push({
            key: index,
            text: doc,
            value: doc,
          });
        });
        setInjuryTypesList(data);

        setInjuryLoader(false);
      })
      .catch((err) => {
        setInjuryLoader(false);
        console.log("err", err);
      });
  };

  const submitForm = () => {
    props.mainSaveButton();
  };

  const saveDraftForm = () => {
    props.saveMainDraftButton();
  };
  let filterP15 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p15");

  let categoriesLevel = [
    {
      cat: "CAT",
      num: 1,
      subTitle: "Minor",

      status: "Injury or Ill-health requiring First-Aid Only",
      impact: "Minor impact to services and/or business operations",
      affected: "up to S$100k",
      reputation: "Minor complains from any party",
    },
    {
      cat: "CAT",
      num: 2,
      subTitle: "Moderate",

      status:
        "Serious injury requiring medical treatment or ill health leading to disability",
      impact: "short term impact to services and/or business operationss",
      affected: "S$100k to S$1m",
      reputation: "Adverse but low key media",
    },
    {
      cat: "CAT",
      num: 3,
      subTitle: "Moderate",

      status:
        "Fatality, serious injuries, or major outbreak or life threatening diseases",
      impact: "Extended disruptions to services and/or business operations",
      affected: "S$1m to S$5m",
      reputation: "Adverse media attention",
    },
    {
      cat: "CAT",
      num: 4,
      subTitle: "Catastrophic",

      status: "Multiple Fatality",
      impact:
        "Disastrous  and uncontrolled disruptions to services and/or business operations",
      affected: "More than S$5m",
      reputation: "Prolong adverse media attention",
    },
  ];

  function categoryInfoModel(onClose) {
    return (
      <div style={{ padding: 10 }} className="catquestion">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={onClose}
        >
          <img src={ExitIcon} />
        </div>
        <div style={{ fontSize: 16, fontWeight: "bolder", marginBottom: 10 }}>
          Incident Category
        </div>
        {categoriesLevel.map((c, index) => {
          return (
            <div
              style={{
                padding: 15,
                border: "1px solid #D8D8D8",
                borderRadius: 5,
                marginBottom: 15,
              }}
              key={index}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    background:
                      c.num == 1
                        ? "#EFFFF2"
                        : c.num == 2
                        ? "#FFF7CC"
                        : c.num == 3
                        ? "#FFD6B0"
                        : c.num == 4
                        ? "#FFB4B4"
                        : null,

                    color:
                      c.num == 1
                        ? "#42BE5C"
                        : c.num == 2
                        ? "#ECBB00"
                        : c.num == 3
                        ? "#FF8A62"
                        : c.num == 4
                        ? "#E85757"
                        : null,
                    border:
                      c.num == 1
                        ? "1px #42BE5C solid "
                        : c.num == 2
                        ? "1px #ECBB00 solid "
                        : c.num == 3
                        ? "1px #FF8A62 solid "
                        : c.num == 4
                        ? "1px #E85757 solid "
                        : null,
                    width: 40,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: " center",
                    alignItems: "center",
                    borderRadius: 8,
                    fontSize: 13,
                    fontWeight: "bold",
                    marginRight: 10,
                  }}
                >
                  <div>CAT</div>
                  <div>{c.num}</div>
                </div>
                <div style={{ fontSize: 15, fontWeight: "bold" }}>
                  {c.subTitle}
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#D5D9CF",
                  margin: "10px 0px",
                  width: "100%",
                  height: 1,
                }}
              ></div>

              <div style={{ marginBottom: 15, marginTop: 30 }}>
                <div style={{ fontWeight: "bold", marginBottom: 3 }}>
                  Status
                </div>
                <div>{c.status}</div>
              </div>
              <div style={{ marginBottom: 15, marginTop: 30 }}>
                <div style={{ fontWeight: "bold", marginBottom: 3 }}>
                  Impact
                </div>
                <div>{c.impact}</div>
              </div>
              <div style={{ marginBottom: 5, marginTop: 30 }}>
                <div style={{ fontWeight: "bold", marginBottom: 3 }}>
                  Affected
                </div>
                <div>{c.affected}</div>
              </div>
              <div style={{ marginBottom: 5, marginTop: 30 }}>
                <div style={{ fontWeight: "bold", marginBottom: 3 }}>
                  Reputation
                </div>
                <div>{c.reputation}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  const values = props.overViewValues;
  const onChangeIncidentOverView = props.onChangeIncidentOverView;
  const {
    typeError,
    placementError,
    involvedError,
    categoryError,
    injuryTypeError,
    classificationError,
  } = props.overViewInputError;

  let checkedSPF = false;
  let hasSPF =
    values &&
    values.emergency_services_activated &&
    values.emergency_services_activated.find((v) => v.name == "SPF");

  if (!hasSPF) {
    checkedSPF = false;
  } else {
    checkedSPF = hasSPF.checked;
  }

  let checkedSCDF = false;
  let hasSCDF =
    values &&
    values.emergency_services_activated &&
    values.emergency_services_activated.find((v) => v.name == "SCDF");

  if (!hasSCDF) {
    checkedSCDF = false;
  } else {
    checkedSCDF = hasSCDF.checked;
  }

  let checkedAuthorities = false;
  let hasAuthorities =
    values &&
    values.emergency_services_activated &&
    values.emergency_services_activated.find(
      (v) => v.name == "Other Authorities"
    );

  if (!hasAuthorities) {
    checkedAuthorities = false;
  } else {
    checkedAuthorities = hasAuthorities.checked;
  }

  return (
    <div
      className="incident_overview_container"
      onClick={() => {
        props.hideShowZoneList();
      }}
    >
      {videoModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 999998,
            backgroundColor: "rgba(0,0,0,.5)",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setVideoModal(false);
            setVideoUrl("");
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <video
              src={videoUrl}
              controls
              style={{
                position: "absolute",

                width: "50%",
                height: "50%",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: 999999,
              }}
              playsinline
            />
          </div>
        </div>
      )}
      <div>
        {/* CheckBox Container */}
        {filterP15 && (
          <div
            className="checkbox_container"
            style={{
              marginBottom: 30,
              backgroundColor: "#E6F8E5",
              padding: "30px",
            }}
          >
            <h3>Is this an emergency incident?</h3>
            <div>
              <Checkbox
                radio
                label="Yes"
                checked={values.is_emergency_incident == "Yes"}
                onChange={() =>
                  onChangeIncidentOverView("is_emergency_incident", "Yes")
                }
                className="checkBox"
              />
            </div>
            <div>
              <Checkbox
                radio
                label="No"
                checked={values.is_emergency_incident == "No"}
                onChange={() =>
                  onChangeIncidentOverView("is_emergency_incident", "No")
                }
                className="checkBox"
              />
            </div>
          </div>
        )}
        {/* Category */}
        <div className="category_container">
          <div className="category_header">
            <h3 className={`category_title ${categoryError && "error"}`}>
              Category*
            </h3>
            <Popup
              trigger={
                <img src={Category_Question} className="category_image" />
              }
              modal
              closeOnDocumentClick={true}
              className="category_info_model-container"
            >
              {(close) => {
                return categoryInfoModel(close);
              }}
            </Popup>
          </div>
          <div className={`category_options ${categoryError && "error"}`}>
            {categoryOptions.map((c) => {
              return (
                <div
                  className="c_option"
                  key={c.value}
                  onClick={() => {
                    onChangeIncidentOverView("category", c.value);
                    props.hideShowZoneList();
                    props.CheckfilledMatodaryField();
                  }}
                >
                  <img src={c.image} />
                  {values.category === c.value ? (
                    <div className="c_circle">
                      <img src={CategoryChecked} />
                    </div>
                  ) : (
                    <div className="c_circle"></div>
                  )}
                </div>
              );
            })}
          </div>
          {categoryError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              Required field
            </div>
          )}
        </div>

        {/* CheckBox Container */}
        <div className="checkbox_container" style={{ marginTop: 30 }}>
          <h3>Is follow up required?</h3>
          <div>
            <Checkbox
              radio
              label="Yes"
              checked={values.is_followup_required == "Yes"}
              onChange={() =>
                onChangeIncidentOverView("is_followup_required", "Yes")
              }
              className="checkBox"
            />
          </div>
          <div>
            <Checkbox
              disabled={values.category == "c1" ? false : true}
              radio
              label="No"
              checked={values.is_followup_required == "No"}
              onChange={() =>
                onChangeIncidentOverView("is_followup_required", "No")
              }
              className="checkBox"
            />
          </div>
        </div>

        <IncidentLocation
          hideShowZoneList={props.hideShowZoneList}
          showShowZoneList={props.showShowZoneList}
          showZoneDropDown={props.showZoneDropDown}
          mainSaveButton={props.mainSaveButton}
          locationValues={props.locationValues}
          onChangeSelectedAddress={props.onChangeSelectedAddress}
          selectedAddress={props.selectedAddress}
          saveMainDraftButton={props.saveMainDraftButton}
          onChangeIncidentLocation={props.onChangeIncidentLocation}
          onChangeIncidentLocationZone={props.onChangeIncidentLocationZone}
          CheckfilledMatodaryField={props.CheckfilledMatodaryField}
          onChangeIncidentLocationCondtionsArea={
            props.onChangeIncidentLocationCondtionsArea
          }
          locationInputError={{
            parkError: props.locationInputError.parkError,
            zoneError: props.locationInputError.zoneError,
            weatherError: props.locationInputError.weatherError,
            areaError: props.locationInputError.areaError,
            dateError: props.locationInputError.dateError,
            timeError: props.locationInputError.timeError,
          }}
          onChangeLocationDateTime={props.onChangeLocationDateTime}
          locationDate={props.locationDate}
          onChangeZoneType={props.onChangeZoneType}
          onChangeMainZone={props.onChangeMainZone}
          onChangeIncidentAddressZone={props.onChangeIncidentAddressZone}
          subZone={props.subZone}
          zoneType={props.zoneType}
          mainZone={props.mainZone}
          onChangeSubZone={props.onChangeSubZone}
          // options
          optionsState={{
            parkList,
            setParkList,
            zoneList,
            setZoneList,
          }}
        />
        <IncidentSummary
          summaryValues={props.summaryValues}
          onChangeIncidentSummary={props.onChangeIncidentSummary}
          summaryInputError={{
            titleError: props.summaryInputError.titleError,
            remarkError: props.summaryInputError.remarkError,
          }}
          CheckfilledMatodaryField={props.CheckfilledMatodaryField}
        />
        <PhotoVideo
          photoValues={props.photoValues}
          saveMainDraftButton={props.saveMainDraftButton}
          onChangePhoto={props.onChangePhoto}
          filterPhotoFun={props.filterPhotoFun}
          photoError={props.photoError}
          setVideoUrl={setVideoUrl}
          setVideoModal={setVideoModal}
          CheckfilledMatodaryField={props.CheckfilledMatodaryField}
          removedPhotoValues={props.removedPhotoValues}
          setRemovedPhotoValues={props.setRemovedPhotoValues}
        />

        {/* Type of incident */}
        <h3 className={`dropdown_title ${typeError && "error"}`}>
          Type of Incident*{" "}
        </h3>
        <PlaceList
          title="Type of incident"
          custom_classes={`incident-place-list  incident-list-dropdown ${
            typeError && "error"
          }`}
          icon_classes="incident-place-list-icon"
          value={values.incident_type}
          onChange={(e, data) => {
            changeDropDownId("incident_type", null);
            if (data.options.length > 0) {
              const filterId = data.options.filter(
                (d) => d.value == data.value
              );
              fetchPlacementOptions(filterId[0].key);
            }
            props.CheckfilledMatodaryField();
            onChangeIncidentOverView("incident_type", data.value);
          }}
          loading={typeListLoader}
          options={incidentTypeList}
          onClick={props.hideShowZoneList}
        />
        {typeError && (
          <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
            Required field
          </div>
        )}
        {/*Incident placement */}
        <h3 className={`dropdown_title ${placementError && "error"}`}>
          Incident Placement*{" "}
        </h3>
        <PlaceList
          title="Incident placement"
          custom_classes={`incident-place-list  incident-list-dropdown ${
            placementError && "error"
          }`}
          onClick={props.hideShowZoneList}
          icon_classes="incident-place-list-icon"
          value={values.incident_placement}
          disabled={!values.incident_type && true}
          onChange={(e, data) => {
            if (incidentPlacementList) {
              const filteredData = incidentPlacementList.filter(
                (i) => i.value == data.value
              );

              if (filteredData.length > 0 && filteredData[0].key) {
                const idKey = filteredData[0].key;
                if (idKey) fetchClassificationOptions(idKey);
              }
            }

            onChangeIncidentOverView("incident_placement", data.value);
            props.CheckfilledMatodaryField();
          }}
          options={incidentPlacementList}
          loading={placementListLoader}
        />
        {placementError && (
          <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
            Required field
          </div>
        )}
        <h3 className={`dropdown_title ${classificationError && "error"}`}>
          Incident Classifications*
        </h3>
        <PlaceList
          title="Incident placement"
          custom_classes={`incident-place-list  incident-list-dropdown ${
            classificationError && "error"
          }`}
          disabled={!values.incident_placement && true}
          options={incidentClassificationList}
          loading={classificationLoader}
          icon_classes="incident-place-list-icon"
          onClick={props.hideShowZoneList}
          onChange={(e, data) => {
            if (incidentClassificationList) {
              const filteredData = incidentClassificationList.filter(
                (i) => i.value == data.value
              );

              if (filteredData.length > 0 && filteredData[0].key) {
                const idKey = filteredData[0].key;
                if (idKey) fetchInjuryOptions(idKey);
              }
            }

            onChangeIncidentOverView("incident_classification", data.value);
            props.CheckfilledMatodaryField();
          }}
          value={values.incident_classification}
        />
        {classificationError && (
          <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
            Required field
          </div>
        )}
        <h3 className={`dropdown_title ${injuryTypeError && "error"} `}>
          Type of Injury
        </h3>
        <Dropdown
          className={`incident-place-list  incident-list-dropdown multiple_dropdown_list ${
            injuryTypeError && "error"
          }`}
          style={{ paddingTop: 10 }}
          disabled={!values.incident_classification && true}
          icon={
            <img
              src={ArrowDownDropDownIcon}
              className="incident-place-list-icon"
            />
          }
          fluid
          multiple
          selection
          options={injuryTypesList}
          value={values.injury_type}
          onChange={(e, data) => {
            props.onChangeIncidentInjuryType(data.value);
            props.CheckfilledMatodaryField();
          }}
          placeholder="Please Select..."
          onClick={props.hideShowZoneList}
        />
        {/* <PlaceList
          title="Type of injury"
          custom_classes={`incident-place-list  incident-list-dropdown ${
            injuryTypeError && "error"
          }`}
          icon_classes="incident-place-list-icon"
          value={values.injury_type}
          options={injuryTypesList}
          onChange={(e, data) => {
            onChangeIncidentOverView("injury_type", data.value);
            props.CheckfilledMatodaryField();
          }}
          
          onClick={props.hideShowZoneList}
        /> */}
        {injuryTypeError && (
          <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
            Required field
          </div>
        )}

        {/* Weather */}

        <h3 className={`dropdown_title ${props.weatherError && "error"}`}>
          Weather{" "}
        </h3>
        <PlaceList
          custom_classes={`incident-place-list  incident-list-dropdown`}
          icon_classes="incident-place-list-icon"
          value={props.locationValues.weather}
          onChange={(e, data) => {
            props.onChangeIncidentLocation("weather", data.value);
            props.CheckfilledMatodaryField();
          }}
          options={weatherList}
          disabled={props.selectedAddress || props.zoneType ? false : true}
          onClick={props.hideShowZoneList}
        />

        {/* Condition of area */}
        <h3 className={`dropdown_title  ${props.areaError && "error"}`}>
          Condition of Area{" "}
        </h3>

        <Dropdown
          className="incident-place-list  incident-list-dropdown multiple_dropdown_list"
          style={{ paddingTop: 10 }}
          icon={
            <img
              src={ArrowDownDropDownIcon}
              className="incident-place-list-icon"
            />
          }
          fluid
          multiple
          selection
          options={conditionAreaList}
          value={props.locationValues.area_condition}
          onChange={(e, data) => {
            props.onChangeIncidentLocationCondtionsArea(data.value);
            props.CheckfilledMatodaryField();
          }}
          placeholder="Please Select..."
          onClick={props.hideShowZoneList}
        />
        {/* CheckBox Container */}
        <div className="checkbox_container" style={{ margin: "30px 0px" }}>
          <h3>Emergency Services Activated</h3>

          <div>
            <Checkbox
              multiple
              label="SPF"
              checked={checkedSPF}
              onChange={() =>
                onChangeIncidentOverView("emergency_services_activated", "SPF")
              }
              className="checkBox"
              style={{
                marginBottom: 8,
              }}
            />
          </div>
          <div>
            <Checkbox
              multiple
              label="SCDF"
              checked={checkedSCDF}
              onChange={() =>
                onChangeIncidentOverView("emergency_services_activated", "SCDF")
              }
              className="checkBox"
              style={{
                marginBottom: 8,
              }}
            />
          </div>
          <div>
            <Checkbox
              multiple
              label="Other Authorities"
              checked={checkedAuthorities}
              onChange={() =>
                onChangeIncidentOverView(
                  "emergency_services_activated",
                  "Other Authorities"
                )
              }
              className="checkBox"
            />
          </div>
        </div>
        {/* CheckBox Container */}
        <div className="checkbox_container" style={{ margin: "30px 0px" }}>
          <h3>Will there be a need to recall people? </h3>
          <div>
            <Checkbox
              radio
              label="Yes"
              checked={values.recall_people == "Yes"}
              onChange={() => onChangeIncidentOverView("recall_people", "Yes")}
              className="checkBox"
            />
          </div>
          <div>
            <Checkbox
              radio
              label="No"
              checked={values.recall_people == "No"}
              onChange={() => onChangeIncidentOverView("recall_people", "No")}
              className="checkBox"
            />
          </div>
        </div>
        {/* Footer */}
        <div
          className="incident_footer"
          style={{
            bottom: -100,
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            {props.overViewValues.is_emergency_incident == "Yes" ||
            props.showNotiPage ? (
              <div
                onClick={() => {
                  props.setCheckStatus("Yes");
                  props.mainSaveButton();
                }}
              >
                <Button className="witness_cancel_button">Submit</Button>
              </div>
            ) : null}

            {props.paramPendingBool && (
              <div>
                <Button
                  className="incident_cancel_button"
                  onClick={props.deletePendingItem}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{ width: "100%" }}
                onClick={() => {
                  if (props.changedValue) {
                    return confirmAlert({
                      customUI: ({ onClose }) =>
                        props.customModalForConfirmCancel(onClose),
                    });
                  } else {
                    history.push("/incidents_list");
                  }
                }}
              >
                <Button className="witness_cancel_button">Cancel</Button>
              </div>
              <div
                style={{ width: "100%" }}
                onClick={() => {
                  submitForm();
                }}
              >
                <Button className="witness_save_button">Save </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Incident_overview;
