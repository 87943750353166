import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

//images
import ArrowDownDropDownIcon from "../assets/images/incident/arrowdowndrop.svg";

function ZoneList(obj) {
  // later need to retrieve
  let zones = [
    {
      key: "All",
      text: "All",
      value: "All",
    },
    {
      key: "Entrance Plaza",
      text: "Entrance Plaza",
      value: "Entrance Plaza",
    },
    {
      key: "Treetops Trail",
      text: "Treetops Trail",
      value: "Treetops Trail",
    },
    {
      key: "West Node",
      text: "West Node",
      value: "West Node",
    },
    {
      key: "Kidsworld",
      text: "Kidsworld",
      value: "Kidsworld",
    },
  ];

  return (
    <Dropdown
      // search
      selection
      options={zones}
      defaultValue={obj["value"]}
      icon={<img src={ArrowDownDropDownIcon} className={obj["icon_classes"]} />}
      className={obj["custom_classes"]}
      onChange={obj["onChange"]}
    />
  );
}

export default ZoneList;
