import React, { useState, useRef, useEffect } from "react";
import {
  Input,
  Grid,
  Dropdown,
  Button,
  Loader,
  Checkbox,
} from "semantic-ui-react";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import YearList from "../../../../../../components/YearList";
import { storage, db } from "../../../../../../config/firebase";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

//css
import "reactjs-popup/dist/index.css";

// images
import ArrowDownDropDownIcon from "../../../../../../assets/images/incident/arrowdowndrop.svg";
import ExitIcon from "../../../../../../assets/images/incident/exit.svg";
import DraftFooter from "../../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../../components/ReopenFooter";
import ClosedFooter from "../../../../../../components/ClosedFooter";

import EditIcon from "../../../../../../assets/images/incident/btn_edit.svg";
import DeleteIcon from "../../../../../../assets/images/incident/btn_delete.svg";

function CasualtyForm(props) {
  let history = useHistory();
  const [submit, setSubmit] = useState(false);
  const [rc, setRc] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [previousSignature, setPreviousSignature] = useState(null);
  const [values, setValues] = useState({
    injury_name: "",
    age: "",
    gender: "",
    contact_no: "",
    address: "",
    email: "",
    nationality: "",
    incident_calssification: "",
    contractor_shift: "",
    is_bodily_injury: "Yes",
    injury_type: [],
    other: "",
    injury_desription: "",
    casualty_no: 0,
    allow_up: "Yes",
  });
  const [isView, setIsView] = useState(props.isView);
  const [causedInjuryList, setCausedInjuryList] = useState([]);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  // error
  const [injuryNameError, setInjuryNameError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorTxt, setEmailErrorTxt] = useState("");
  const [nationalityError, setNationalityError] = useState(false);
  const [calssificationError, setCalssificationError] = useState(false);
  const [contractorError, setContractorError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [desriptionError, setDesriptionError] = useState(false);
  const [otherError, setOtherError] = useState(false);
  const [prevData, setPrevData] = useState(null);
  const [signatureError, setSignatureError] = useState(false);

  const sigPad = useRef({});
  const {
    changeShowCasualyFormBool,
    changeHideCasualyFormBool,
    showCasualtyForm,
    selectedDetail,
  } = props;

  const clearSignature = () => {
    sigPad.current.clear();
  };

  const doneSignature = () => {
    setSignatureImage(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  useEffect(() => {
    db.collection("static_data")
      .doc("what_caused_injury")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().what_caused_injury_arr.forEach((doc, index) => {
          data.push({
            value: doc,
            text: doc,
            key: doc,
          });
        });

        setCausedInjuryList(data);
      });
  }, []);
  useEffect(() => {
    setLoader(false);
    let index = props.totalForms.length > 0 && props.totalForms.length - 1;
    let c_no =
      parseInt(
        props.totalForms.length > 0 &&
          props.totalForms[index].casualty_no.split(/\s+/)[1]
      ) + 1;
    if (props.previousIncident && props.selectedCasualtyNo) {
      let filteredData =
        props.previousIncident.case_details.person_involved_details.filter(
          (c) => c.casualty_no == props.selectedCasualtyNo
        );
      setPrevData(filteredData[0]);
    } else {
      setPrevData(null);
    }
    if (selectedDetail) {
      setIsView(true);
      const {
        injury_name,
        age,
        gender,
        contact_no,
        address,
        email,
        nationality,
        incident_calssification,
        contractor_shift,
        is_bodily_injury,
        injury_type,
        other,
        injury_desription,
        signature,
        casualty_no,
        allow_up,
      } = selectedDetail;
      setValues({
        injury_name,
        age,
        gender,
        contact_no,
        address,
        email,
        nationality,
        incident_calssification,
        contractor_shift,
        is_bodily_injury,
        injury_type,
        other,
        injury_desription,
        casualty_no,
        allow_up,
      });
      let modifiedSignature = "";
      if (signature) {
        if (signature.includes(":443")) {
          modifiedSignature = signature.replace(":443", "");
        } else {
          modifiedSignature = signature;
        }
      }

      setSignatureImage(modifiedSignature);
      setPreviousSignature(modifiedSignature);
    } else {
      setIsView(false);
      setValues({
        injury_name: "",
        age: "",
        gender: "",
        contact_no: "",
        address: "",
        email: "",
        nationality: "",
        incident_calssification: "",
        contractor_shift: "",
        is_bodily_injury: "Yes",
        injury_type: [],
        other: "",
        allow_up: "Yes",
        injury_desription: "",
        // casualty_no: `Casualty ${
        //   props.totalForms.length == 0 ? 1 : props.totalForms.length + 1
        // }`,
        casualty_no: `Casualty ${props.totalForms.length == 0 ? 1 : c_no}`,
      });
      setSignatureImage(null);
    }
  }, [selectedDetail]);

  useEffect(() => {}, [values.injury_type]);
  const incidentStatus = props.incidentStatus;
  const submitForm = () => {
    setRc(!rc);
    const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
    let data = {
      ...values,
      signature: signatureImage
        ? signatureImage.replace("data:image/png;base64,", "")
        : null,
    };

    if (data.injury_name == "") {
      // setInjuryNameError("Required Field");
    } else {
      // if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(data.injury_name)) {
      if (/[^A-Za-z ]/g.test(data.injury_name)) {
        setInjuryNameError("Must be valid.");
      } else {
        setInjuryNameError("");
      }
    }

    if (data.age == "") {
      // setAgeError("Required Field");
    } else {
      if (!/^[0-9]+$/.test(data.age) || data.age > 100 || data.age == 0) {
        setAgeError("Must be vaild.");
      } else {
        setAgeError("");
      }
    }

    if (data.gender == "") {
      setGenderError("Required Field");
    } else {
      setGenderError("");
    }

    if (data.address == "" && data.contact_no == "" && data.email == "") {
      setAddressError(
        "Please one must be filled between address, email and contact number."
      );
      setContactError(
        "Please one must be filled between address, email and contact number."
      );
      setEmailError(true);
      setEmailErrorTxt(
        "Please one must be filled between address, email and contact number."
      );
    }
    if (data.address.length > 0) {
      if (data.address.length < 3) {
        setAddressError("Please enter at least 3 characters.");
      } else {
        if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(data.address)) {
          setAddressError("Must be vaild");
          // setEmailError(false);
          // setContactError("");
        } else {
          setAddressError("");
          setEmailError(false);
          setContactError("");
        }
      }
    } else {
      setAddressError("");
    }

    if (data.email.length > 0) {
      if (!regex.test(data.email)) {
        setEmailErrorTxt("Must be valid");
        setEmailError(true);
        // setContactError("");
        // setAddressError("");
      } else {
        setAddressError("");
        setContactError("");
        setEmailError(false);
      }
    } else {
      setEmailError(false);
      setEmailErrorTxt("");
    }

    if (data.contact_no.length > 0) {
      if (data.contact_no == "") {
        setContactError(
          "Please one must be filled between address, email and contact number."
        );
      } else if (data.contact_no.length > 15) {
        setContactError("Must be valid.");
        // setAddressError("");
        // setEmailError(false);
      } else {
        if (
          !/^\d*(\.\d+)?$/.test(data.contact_no) ||
          data.contact_no.length > 15 ||
          !/^[0-9]+$/.test(data.contact_no)
        ) {
          setContactError("Must be vaild");
        } else {
          setContactError("");
          if (addressError && addressError.includes("Please one must be")) {
            setAddressError("");
          }
          if (emailErrorTxt && emailErrorTxt.includes("Please one must be")) {
            setEmailError(false);
          }
        }
      }
    } else {
      setContactError("");
    }

    if (data.incident_calssification == "") {
      setCalssificationError("Required Field");
    } else {
      if (
        /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
          data.incident_calssification
        )
      ) {
        setCalssificationError("Must be valid.");
      } else {
        setCalssificationError("");
      }
    }

    if (data.contractor_shift == "") {
      setContractorError("Required Field");
    } else {
      setContractorError("");
    }

    if (values.is_bodily_injury == "Yes") {
      if (data.injury_type == "" || data.injury_type.length == 0) {
        setTypeError("Required Field");
      } else {
        setTypeError("");
      }
      if (data.injury_type.includes("Others")) {
        if (data.other == "") {
          setOtherError("Required Field");
        } else {
          if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(data.other)) {
            setOtherError(true);
          } else {
            setOtherError(false);
          }
        }
      }
    } else {
      setValues({
        ...values,
        injury_type: [],
        other: "",
      });
      setTypeError("");
      setOtherError("");
    }

    // if (data.signature == null) {
    //   setSignatureError(true);
    // } else {
    //   setSignatureError(false);
    // }

    // if (data.injury_desription == "") {
    //   setDesriptionError("Required Field");
    // } else {
    //   setDesriptionError("");
    // }
  };
  let filterP4 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4");
  let filterP10 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p10");
  let filterP4A =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4a");
  let filterP5 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p5");
  useEffect(() => {
    if (submit) {
      const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

      let data = {
        ...values,
        signature: signatureImage
          ? signatureImage.replace("data:image/png;base64,", "")
          : null,
      };

      if (data.address.length > 0) {
        if (data.address.length < 3) {
          setAddressError("Please enter at least 3 characters.");
        } else {
          if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(data.address)) {
            setAddressError("Must be vaild");
            // setEmailError(false);
            // setContactError("");
          } else {
            setAddressError("");
            setEmailError(false);
            setContactError("");
          }
        }
      }

      if (data.email.length > 0) {
        if (data.email.length == 0) {
          setEmailErrorTxt(
            "Please one must be filled between address, email and contact number."
          );
          setEmailError(true);
        } else {
          if (!regex.test(data.email)) {
            setEmailErrorTxt("Must be valid");
            setEmailError(true);
            // setContactError("");
            // setAddressError("");
          } else {
            setAddressError("");
            setContactError("");
            setEmailError(false);
          }
        }
      }

      if (data.contact_no.length > 0) {
        if (data.contact_no == "") {
          setContactError(
            "Please one must be filled between address, email and contact number."
          );
        } else if (data.contact_no.length > 15) {
          setContactError("Must be valid.");
          // setAddressError("");
          // setEmailError(false);
        } else {
          if (
            !/^\d*(\.\d+)?$/.test(data.contact_no) ||
            data.contact_no.length > 15 ||
            !/^[0-9]+$/.test(data.contact_no)
          ) {
            setContactError("Must be vaild");
          } else {
            setContactError("");
            if (addressError && addressError.includes("Please one must be")) {
              setAddressError("");
            }
            if (emailErrorTxt && emailErrorTxt.includes("Please one must be")) {
              setEmailError(false);
            }
          }
        }
      } else {
        setContactError("");
      }

      if (
        !injuryNameError &&
        // !ageError &&
        !genderError &&
        !typeError &&
        !otherError
      ) {
        if (!contactError && !addressError && !emailError) {
          setContactError("");
          setEmailError(false);
          setAddressError("");

          if (data) {
            console.log("111");
            setLoader(true);

            const nameForImage = new Date().getTime() + Math.random();

            if (previousSignature == signatureImage) {
              props.addForm(data);
              if (!props.prevData && !props.selectedCasualtyNo) {
                createNewFormForAduit();
              }
              return props.changeHideCasualyFormBool();
            } else {
              if (previousSignature) {
                console.log("here");
                storage
                  .refFromURL(previousSignature)
                  .delete()
                  .catch((err) => console.log("err", err));
              }

              storage
                .ref(`/signatures/${nameForImage}`)
                .putString(signatureImage, "data_url")
                .then(async (img) => {
                  const image = await storage
                    .ref(`/signatures/${nameForImage}`)
                    .getDownloadURL();

                  data.signature = image;
                  props.addForm(data);
                  const cloneRemovedPhotoValues = props.newAddedSignature;
                  cloneRemovedPhotoValues.push(image);
                  props.setNewAddedSignature(cloneRemovedPhotoValues);
                  if (!props.prevData && !props.selectedCasualtyNo) {
                    createNewFormForAduit();
                  }
                  return props.changeHideCasualyFormBool();
                })
                .catch((err) => console.log("err", err));
            }
          } else {
            setLoader(false);
            return null;
          }
        }
      } else {
        return null;
      }
      setSubmit(false);
    }
  }, [rc, submit]);

  const previousIncident = props.previousIncident;
  const onListenValueChange = props.onListenValueChange;

  const checkPreviousValueAndCurrent = (previous, current, key, number) => {
    let splitUnderScore = key.replace("_", " ");

    if (previous != current) {
      onListenValueChange(
        number + key,
        `Update ${splitUnderScore} from ${previous} to ${current} in ${number} form `
      );
    } else {
      onListenValueChange(key, null);
    }
  };
  const createNewFormForAduit = () => {
    onListenValueChange(
      props.totalForms + 1,
      "Added Person Involved Details Form"
    );
  };
  const removeCasualty = () => {
    console.log("forms........", props.forms);
    console.log("form c.....", props.selectedCasualtyNo);
    let newArr = [];
    props.forms.forEach((form) => {
      if (form.casualty_no == props.selectedCasualtyNo) {
        newArr.push({ ...form, status: "delete" });
      } else {
        newArr.push({ ...form });
      }
    });
    props.setForms(newArr);
    props.onChangeIncidentCasulaty(newArr);
    props.changeHideCasualyFormBool();
  };
  function Navigation() {
    return (
      <>
        {incidentStatus === "Draft" ? (
          <DraftFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus === "Pending Approval" ? (
          <PendingApprovalFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
            authDetail={props.AuthDetail}
            created_by={props.previousIncident.created_by}
          />
        ) : incidentStatus === "Approve" ? (
          <PendingRejectFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus === "Closed" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={values.category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            onListenValueChange={onListenValueChange}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus === "Open" ? (
          <ClosedFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus === "Archived" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            isArchived={true}
            showNotiPage={props.showNotiPage}
            onListenValueChange={props.onListenValueChange}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : (
          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  const { nationalityList } = props.optionsState;
  return (
    <div className="casual_form_container">
      <div className="casual_form">
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div className="casual_form_title">Casualty details</div>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
            }}
          >
            {props.isEdit ? (
              isView ? (
                <div
                  className="delete_btn"
                  onClick={() => setIsView(false)}
                  // onClick={() => removeCasualty(_, index)}
                >
                  <img src={EditIcon} />
                </div>
              ) : null
            ) : null}
            {selectedDetail ? (
              <div className="delete_btn" onClick={removeCasualty}>
                <img src={DeleteIcon} />
              </div>
            ) : null}
          </div>
        </div>
        {/* First Line  */}
        <div
          style={{
            display: "flex",
            marginBottom: 30,
            flexDirection: isMobile && "column",
          }}
        >
          <div style={{ width: !isMobile && "60%" }}>
            <h3 className={`input_title ${injuryNameError && "error"}`}>
              Name
            </h3>
            <Input
              disabled={isView ? true : false}
              className={`casual_input not_fluid ${injuryNameError && "error"}`}
              value={values.injury_name}
              onKeyPress={(event) => {
                // if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(event.key)) {
                if (/[^A-Za-z ]/g.test(event.key)) {
                  event.preventDefault();
                }
              }}
              style={{ width: isMobile && "100%" }}
              onChange={(e) => {
                setValues({
                  ...values,
                  injury_name: e.target.value,
                });
                if (props.selectedCasualtyNo && prevData) {
                  checkPreviousValueAndCurrent(
                    prevData.injury_name,
                    e.target.value,
                    "injury_name",
                    props.selectedCasualtyNo
                  );
                }
              }}
            />
            {injuryNameError && (
              <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
                {injuryNameError}
              </div>
            )}
          </div>

          <div className="casual_dropdown_gender_container">
            <h3 className={`input_title ${ageError && "error"}`}>Age</h3>
            <Input
              maxLength={3}
              disabled={isView ? true : false}
              className={`casual_input  ${ageError && "error"}`}
              style={{
                width: !isMobile && 180,
                display: "flex",
                justifyContent: "flex-end",
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              // icon_classes="incident-place-list-icon-age"
              value={values.age}
              onChange={(e, data) => {
                setValues({
                  ...values,
                  age: data.value,
                });
                if (props.selectedCasualtyNo && prevData) {
                  checkPreviousValueAndCurrent(
                    prevData.age,
                    data.value,
                    "age",
                    props.selectedCasualtyNo
                  );
                }
              }}
            />
            {/* <YearList
              custom_classes={`incident-place-list  incident-list-dropdown-age ${
                ageError && "error"
              }`}
              icon_classes="incident-place-list-icon-age"
              value={values.age}
              onChange={(e, data) => {
                setValues({
                  ...values,
                  age: data.value,
                });
                if (props.selectedCasualtyNo && prevData) {
                  checkPreviousValueAndCurrent(
                    prevData.age,
                    data.value,
                    "age",
                    props.selectedCasualtyNo
                  );
                }
              }}
            /> */}
            {ageError && (
              <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
                {ageError}
              </div>
            )}
          </div>

          <div className="casual_dropdown_gender_container">
            <h3 className={`input_title ${genderError && "error"}`}>Gender*</h3>
            <Dropdown
              disabled={isView ? true : false}
              selection
              compact
              options={[
                {
                  value: "Male",
                  text: "Male",
                },
                {
                  value: "Female",
                  text: "Female",
                },
                {
                  value: "Unknown",
                  text: "Unknown",
                },
              ]}
              placeholder="Please Select..."
              className={`casual_dropdown_gender ${genderError && "error"}`}
              icon={
                <img
                  src={ArrowDownDropDownIcon}
                  className="incident-place-list-icon-age"
                />
              }
              value={values.gender}
              onChange={(e, data) => {
                setValues({
                  ...values,
                  gender: data.value,
                });
                if (props.selectedCasualtyNo && prevData) {
                  checkPreviousValueAndCurrent(
                    prevData.gender,
                    data.value,
                    "gender",
                    props.selectedCasualtyNo
                  );
                }
              }}
            />
            {genderError && (
              <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
                {genderError}
              </div>
            )}
          </div>
        </div>

        {/* Second Line */}
        <div style={{ width: "100%", marginBottom: 30 }}>
          <h3 className={`input_title ${addressError && "error"}`}>Address </h3>
          <Input
            disabled={isView ? true : false}
            className={`casual_input ${addressError && "error"}`}
            value={values.address}
            // onKeyPress={(event) => {
            //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
            //     event.preventDefault();
            //   }
            // }}
            onChange={(e) => {
              setValues({
                ...values,
                address: e.target.value,
              });
            }}
          />
          {addressError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              {addressError}
            </div>
          )}
        </div>
        <div style={{ flex: 1, marginBottom: 30 }}>
          <h3 className={`input_title ${emailError && "error"}`}>
            Email address
          </h3>
          <Input
            disabled={isView ? true : false}
            className={`casual_input  ${emailError && "error"}`}
            value={values.email}
            onChange={(e) => {
              setValues({
                ...values,
                email: e.target.value,
              });
            }}
          />
          {emailError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              {emailErrorTxt}
            </div>
          )}
        </div>
        {/* Fourth Line */}
        <div style={{ display: "flex", marginBottom: 30 }}>
          <div style={{ flex: 2, marginBottom: 30 }}>
            <h3 className={`input_title  ${contactError && "error"}`}>
              Contact Number.
            </h3>
            <Input
              disabled={isView ? true : false}
              className={`casual_input not_fluid ${contactError && "error"}`}
              value={values.contact_no}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                setValues({
                  ...values,
                  contact_no: e.target.value,
                });
              }}
            />
            {contactError && (
              <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
                {contactError}
              </div>
            )}
          </div>
          <div
            className="casual_divide_mid_container spacer"
            style={{ flex: 1 }}
          >
            <h3 className={`input_title ${nationalityError && "error"}`}>
              Nationality{" "}
            </h3>
            <Dropdown
              disabled={isView ? true : false}
              selection
              compact
              options={nationalityList}
              className={`casual_dropdown_age ${nationalityError && "error"}`}
              icon={
                <img
                  src={ArrowDownDropDownIcon}
                  className="incident-place-list-icon-age"
                />
              }
              placeholder="Please Select..."
              value={values.nationality}
              onChange={(e, data) => {
                setValues({
                  ...values,
                  nationality: data.value,
                });
                if (props.selectedCasualtyNo && prevData) {
                  checkPreviousValueAndCurrent(
                    prevData.nationality,
                    data.value,
                    "nationality",
                    props.selectedCasualtyNo
                  );
                }
              }}
            />
            {nationalityError && (
              <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
                {nationalityError}
              </div>
            )}
          </div>
        </div>
        {/* Fifth Line */}
        {/* CheckBox Container */}
        <div
          className="checkbox_container"
          style={{ marginBottom: 30, marginTop: -30 }}
        >
          <h3>Is there bodily injury? </h3>
          <div>
            <Checkbox
              disabled={isView ? true : false}
              radio
              label="Yes"
              checked={values.is_bodily_injury == "Yes"}
              onChange={(e, data) => {
                setValues({
                  ...values,
                  is_bodily_injury: "Yes",
                });
                if (props.selectedCasualtyNo && prevData) {
                  checkPreviousValueAndCurrent(
                    prevData.is_bodily_injury,
                    "Yes",
                    "bodily_injury",
                    props.selectedCasualtyNo
                  );
                }
              }}
              className="checkBox"
            />
          </div>
          <div>
            <Checkbox
              disabled={isView ? true : false}
              radio
              label="No"
              checked={values.is_bodily_injury == "No"}
              onChange={(e, data) => {
                setValues({
                  ...values,
                  is_bodily_injury: "No",
                  injury_type: [],
                });

                if (props.selectedCasualtyNo && prevData) {
                  checkPreviousValueAndCurrent(
                    prevData.is_bodily_injury,
                    "No",
                    "bodily_injury",
                    props.selectedCasualtyNo
                  );
                }
              }}
              className="checkBox"
            />
          </div>
        </div>
        {/* Sixth Line */}
        <div className="dropdown_fluid">
          <h3 className={`input_title ${typeError && "error"}`}>
            What caused the injury?*
          </h3>
          <Dropdown
            selection
            compact
            options={causedInjuryList}
            className={`casual_dropdown_age ${typeError && "error"}`}
            icon={
              <img
                src={ArrowDownDropDownIcon}
                className="incident-place-list-icon-age"
              />
            }
            multiple
            value={values.injury_type}
            disabled={values.is_bodily_injury == "No" || isView ? true : false}
            onChange={(e, data) => {
              setValues({
                ...values,
                injury_type: data.value,
                other: "",
              });
              if (props.selectedCasualtyNo && prevData) {
                checkPreviousValueAndCurrent(
                  prevData.injury_type,
                  data.value,
                  "injury_type",
                  props.selectedCasualtyNo
                );
              }
            }}
          />
          {typeError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              {typeError}
            </div>
          )}
        </div>
        {values.injury_type.includes("Others") && (
          <div style={{ marginBottom: 30 }}>
            <h3 style={{ color: otherError && "#E72D35" }}>Other*</h3>
            <Input
              disabled={isView ? true : false}
              fluid
              className={`damaged_input  ${otherError && "error"}`}
              value={values.other}
              onChange={(e) =>
                setValues({
                  ...values,
                  other: e.target.value,
                })
              }
            />
            {otherError && (
              <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
                {otherError}
              </div>
            )}
          </div>
        )}
        {/* <div style={{ marginTop: 80 }}>
          <h3 style={{ color: otherError && "#E72D35" }}>Other *</h3>
          <Input
            fluid
            className={`damaged_input ${otherError && "error"}`}
            value={values.other}
            onChange={(e) => {
              setValues({
                ...values,
                other: e.target.value,
              });
            }}
          />
        </div> */}

        {/* Seventh Line */}
        <div style={{ marginBottom: 30, marginTop: 30 }}>
          <h3 className={`input_title ${desriptionError && "error"}`}>
            Description of Injury
          </h3>
          <textarea
            disabled={isView ? true : false}
            className={`remark_text_area ${desriptionError && "error"}`}
            value={values.injury_desription}
            // onKeyPress={(event) => {
            //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
            //     event.preventDefault();
            //   }
            // }}
            onChange={(e) => {
              setValues({
                ...values,
                injury_desription: e.target.value,
              });
              if (props.selectedCasualtyNo && prevData) {
                checkPreviousValueAndCurrent(
                  prevData.injury_desription,
                  e.target.value,
                  "injury_desription",
                  props.selectedCasualtyNo
                );
              }
            }}
          ></textarea>
          {desriptionError && (
            <div style={{ color: "#E72D35", marginTop: 10 }}>
              {desriptionError}
            </div>
          )}
        </div>
        {/* CheckBox Container */}
        <div className="checkbox_container" style={{ marginBottom: 30 }}>
          <h3>Allow to follow-up with you? </h3>
          <div>
            <Checkbox
              disabled={isView ? true : false}
              radio
              label="Yes"
              checked={values.allow_up == "Yes"}
              onChange={(e, data) =>
                setValues({
                  ...values,
                  allow_up: "Yes",
                })
              }
              className="checkBox"
            />
          </div>
          <div>
            <Checkbox
              disabled={isView ? true : false}
              radio
              label="No"
              checked={values.allow_up == "No"}
              onChange={(e, data) =>
                setValues({
                  ...values,
                  allow_up: "No",
                })
              }
              className="checkBox"
            />
          </div>
        </div>
        <p>
          By signing this form, you consent to the collection, use and
          disclosure of your personal data for the purpose to manage and prepare
          any incidents or accidents report in accordance with the Personal Data
          Protection Act 2012 and our data protection policy is available at{" "}
          <a
            href="https://www.mandai.com/"
            target="_blank"
            style={{ color: "#228A4C" }}
          >
            www.mandai.com :{" "}
          </a>
        </p>

        <p>
          Please visit our website at{" "}
          <a
            href="https://www.mandai.com/"
            target="_blank"
            style={{ color: "#228A4C" }}
          >
            www.mandai.com{" "}
          </a>{" "}
          for further details on our data protection policy, including how you
          may access and correct your personal data or withdraw consent to the
          collection, use or disclosure of your personal data.
        </p>
        {/* eighth Line */}
        <div style={{ marginBottom: 100 }}>
          <h3 className="input_title">Signature</h3>
          <div
            disabled={isView ? true : false}
            style={{
              width: "100%",
              height: 360,

              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 12,
              border: signatureError
                ? " 1px solid #e72d35 "
                : "1px solid #D8D8D8",
              backgroundColor: signatureError && "#fff0f0",
            }}
          >
            <Popup
              disabled={isView ? true : false}
              modal
              trigger={
                <div
                  style={{
                    width: "95%",
                    height: 300,
                    border: "1px solid #D8D8D8",
                    borderRadius: 12,
                    backgroundImage: `url(${signatureImage})`,
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                    backgroundPosition: "center",
                  }}
                >
                  <div>
                    {" "}
                    <button
                      disabled={isView ? true : false}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSignatureImage(null);
                      }}
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        background: "none",
                        padding: "10px 30px",
                        color: "#008237",
                        fontWeight: "bold",
                        border: "1px solid #008237",
                        borderRadius: 12,
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              }
              closeOnDocumentClick={false}
            >
              {(close) => {
                return (
                  <div className="signature_Container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <img src={ExitIcon} onClick={close} />
                    </div>
                    <h3 className="input_title">Signature</h3>
                    <SignaturePad
                      ref={sigPad}
                      canvasProps={{
                        className: "signatureCanvas",
                      }}
                    />
                    <div>
                      <button
                        onClick={clearSignature}
                        className="signature_clear_button"
                      >
                        Clear
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        onClick={close}
                        className="signature_reset_button"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          doneSignature();
                          close();
                        }}
                        className="signature_done_button"
                      >
                        Done
                      </button>
                    </div>
                  </div>
                );
              }}
            </Popup>
          </div>
        </div>
        {signatureError && (
          <div
            style={{
              color: "#E72D35",
              marginLeft: 3,
              fontSize: 13,
              marginTop: -100,
            }}
          >
            {" "}
            Required field
          </div>
        )}
      </div>
      {isView ? null : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: -50,
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{ width: "100%" }}
              onClick={() => changeHideCasualyFormBool()}
            >
              <Button className="casual_cancel_button">Cancel </Button>
            </div>
            {props.incidentStatus != "Archived" && (
              <div
                style={{ width: "100%" }}
                onClick={() => {
                  setRc(!rc);
                  setSubmit(true);
                  submitForm();
                }}
              >
                <Button
                  className="casual_save_button"
                  style={{ width: 180 }}
                  onClick={() => {
                    setRc(!rc);
                  }}
                >
                  {" "}
                  {loader ? (
                    <Loader
                      active
                      inline="centered"
                      size="tiny"
                      inverted
                      indeterminate
                    />
                  ) : (
                    <span>
                      {props.selectedDetail
                        ? `Update Casualty`
                        : `Add Casualty`}
                    </span>
                  )}{" "}
                </Button>
              </div>
            )}

            {/* <div
              style={{ width: "100%" }}
              onClick={() => {
                setRc(!rc);
                setSubmit(true);
                submitForm();
              }}
            >
              <Button
                className="casual_delete_button"
                style={{ width: 180 }}
                onClick={() => {
                  setRc(!rc);
                }}
              >
                {" "}
                {loader ? (
                  <Loader
                    active
                    inline="centered"
                    size="tiny"
                    inverted
                    indeterminate
                  />
                ) : (
                  <span>Delete</span>
                )}{" "}
              </Button>
            </div> */}
          </div>
        </div>
      )}
      {/* Main Footer */}
      {filterP4A &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" &&
        props.previousIncident.created_by == props.AuthDetail.id && (
          <>{Navigation()}</>
        )}
      {filterP4 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" && <>{Navigation()}</>}

      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c2" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c3" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c4" && <>{Navigation()}</>}
      {props.previousIncident &&
      props.previousIncident.filtered_category == "" ? (
        <>
          {filterP4 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
        </>
      ) : null}
      <div
        className="incident_footer"
        style={{ bottom: 0, justifyContent: "flex-end", zIndex: 1 }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }} onClick={() => {}}>
            <Button
              className="witness_cancel_button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      {/* <div className="casual_footer">
        <div style={{ display: "flex" }}>
          <div
            style={{ width: "100%" }}
            onClick={() => changeHideCasualyFormBool()}
          >
            <Button className="casual_cancel_button">Cancel </Button>
          </div>
          {props.incidentStatus != "Archived" && (
            <div
              style={{ width: "100%" }}
              onClick={() => {
                submitForm();
              }}
            >
              <Button className="casual_save_button">
                {" "}
                {loader ? (
                  <Loader
                    active
                    inline="centered"
                    size="tiny"
                    inverted
                    indeterminate
                  />
                ) : (
                  <span>Save</span>
                )}{" "}
              </Button>
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
}

export default CasualtyForm;
