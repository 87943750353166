import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import incidentReducer from "./incidentReducer";
import incidentAuditReducer from "./incidentAuditReducer";
import loggedInUserDetail from "./loggedInUserDetailReducer";
import userAccountReducer from "./userAccountReducer";
import userAccountDetailReducer from "./userAccountDetailReducer";
import userAuditReducer from "./userAuditReducer";
import userGroupManagementReducer from "./userGroupManagementReducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    incident: incidentReducer,
    auditIncident: incidentAuditReducer,
    loggedInUserDetail: loggedInUserDetail,
    userAccount: userAccountReducer,
    userAccountDetail: userAccountDetailReducer,
    userAudit: userAuditReducer,
    userGroupManagement: userGroupManagementReducer,
  });

export default createRootReducer;
