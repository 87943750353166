import React, { useEffect, useState } from "react";
import { Checkbox, Button, Loader, Input } from "semantic-ui-react";
import PlaceList from "../../components/PlaceList";
import api from "../../config/api";
import config from "../../config/config";
import { auth } from "../../config/firebase";
import ExitIcon from "../../assets/images/incident/exit.svg";
import { confirmAlert } from "react-confirm-alert";
import { useMediaQuery } from "react-responsive";
import "./style.css";

function Modifiy() {
  const [sms, setSms] = useState(true);
  const [email, setEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpLife, setOtpLife] = useState(1);
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [attempts, setAttempts] = useState(null);
  const { SETTINGS } = config.api_endpoint;

  const container = {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    height: "85vh",
  };

  let numbers = [];
  for (let i = 1; i <= 10; i++) {
    numbers.push({
      key: i,
      text: i,
      value: i,
    });
  }

  useEffect(() => {
    setLoading(true);
    api
      .get(SETTINGS)
      .then((res) => {
        setLoading(false);
        if (res.message == "Success") {
          console.log("reserwerwer", res);
          setOtpLife(res.data.otp_life_time);
          setEmail(res.data.email);
          setSms(res.data.sms);
          setAttempts(res.data.failed_login_attempts);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  }, []);

  // useEffect(() => {
  //   // setOtpLife(otpLife.toString().replace(/[^\d]/g, ""));
  //   if (otpLife && otpLife.length > 0) {
  //     setOtpLife(otpLife.replace(/[[1-9]/g, ""));
  //   }
  // }, [otpLife]);
  useEffect(() => {
    if (otpLife && otpLife.length > 0) {
      setOtpLife(otpLife.replace(/\D/g, ""));
    }
  }, [otpLife]);
  const handleSubmit = () => {
    if (otpLife == 0 || otpLife == "") {
      return setError(true);
    }
    console.log("/^[1-9][0-9]*$/.test(otpLife)", /^[1-9][0-9]*$/.test(otpLife));
    if (!/^[1-9][0-9]*$/.test(otpLife)) {
      return setError(true);
    }

    setError(false);

    auth.onAuthStateChanged((user) => {
      api
        .post(
          SETTINGS,
          JSON.stringify({
            platform: "web",
            sms,
            email,
            user_id: user.uid,
            failed_login_attempts: attempts,
            otp_life_time: parseInt(otpLife),
          })
        )
        .then((res) => {
          console.log("res", res);
          if (res.message == "Success") {
            return confirmAlert({
              customUI: ({ onClose }) => customModal(onClose),
            });
          }
        })
        .catch((err) => console.log("err", err));
    });
  };

  function customModal(onClose) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Saved settings
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            The changes have been successfully saved.
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div></div>
            <div>
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={onClose}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        className={`${!isMobile && "col-45"}`}
        style={{
          marginLeft: isMobile && 30,
        }}
      >
        <h2 style={{ color: "#008237" }}>Settings</h2>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Loader active inline="centered" />
        </div>
      ) : (
        <>
          <div style={container}>
            <div
              style={{
                width: 300,
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "",
                alignItems: "flex-start",
              }}
            >
              <h3>OTP</h3>
              <div>
                <Checkbox
                  radio
                  label="Email"
                  checked={email == true}
                  onChange={() => {
                    console.log("on change lite p");
                    setSms(false);
                    setEmail(true);
                  }}
                  className="checkBox"
                />
              </div>
              <div>
                <Checkbox
                  radio
                  label="SMS"
                  checked={sms == true}
                  onChange={() => {
                    setSms(true);
                    setEmail(false);
                  }}
                  className="checkBox"
                />
              </div>
              <h3 className={`dropdown_title`}>
                No. of failed login attempts{" "}
              </h3>
              <PlaceList
                title="Type of incident"
                custom_classes={`incident-place-list  incident-list-dropdown`}
                icon_classes="incident-place-list-icon"
                options={numbers}
                value={attempts}
                onChange={(e, data) => {
                  setAttempts(data.value);
                }}
              />
              <h3
                className={`dropdown_title`}
                style={{
                  color: error && "#e72d35",
                }}
              >
                OTP life time (minutes){" "}
              </h3>
              {error && (
                <div style={{ color: "#e72d35" }}>
                  Please enter at least 1 minute and must not start with zero.{" "}
                </div>
              )}
              <Input
                fluid
                className={`damaged_input ${error && "error"}`}
                style={{
                  width: "100%",
                  border: error && "1px solid e72d35",
                }}
                value={otpLife}
                onKeyPress={(event) => {
                  console.log(
                    "!/[0-9]/.test(event.key)",
                    !/[0-9]/.test(event.key)
                  );
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setOtpLife(e.target.value)}
              />
              <h3>Mobile App download link</h3>
              <div>
                <div>
                  IOS:{" "}
                  <a
                    href="https://install.appcenter.ms/users/common-u27u/apps/mwg-production-ios/distribution_groups/public"
                    target="_blank"
                  >
                    Download link
                  </a>
                </div>
                <div>
                  Android:{" "}
                  <a
                    href="https://install.appcenter.ms/users/common-u27u/apps/mwg-production-android/distribution_groups/public"
                    target="blank"
                  >
                    Download link
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100vw",
              // position: "fiex",
              boxShadow: "rgb(0 0 0 / 30%) 0px -6px 6px -6px",
              position: "fixed",
              // left: -45,
              display: "flex",
              justifyContent: "flex-end",
              bottom: 2,
              padding: "10px 0px",
              zIndex: 99999,
              backgroundColor: "white",

              // display: "flex",
              // justifyContent: "flex-end",
              // background: "#ffffff",
              // width: "100vw",
              // alignItems: "flex-end",
              // paddingTop: 10,
              // marginLeft: 45,
              // marginRight: 45,
              // paddingRight: 50,
              // boxShadow: "rgb(0 0 0 / 30%) 0px -6px 6px -6px",
              // position: "fixed",
              // left: -45,
              // bottom: 5,
            }}
          >
            <div>
              {/* <Button className="witness_cancel_button" onClick={() => {}}>
                Cancel
              </Button> */}
              <Button className="witness_save_button" onClick={handleSubmit}>
                Save{" "}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Modifiy;
