import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Grid,
  Table,
  Pagination,
  Loader,
  Dropdown,
  Button,
  Dimmer,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { INCIDENTS_DETAIL_ROUTE } from "../../../routes";
import { connect } from "react-redux";
import {
  fetchAllIncidents,
  fetchAllIncidentsForExport,
  fetchAllIncidentsForSearch,
  changeLoaderForExport,
  fetchAllSubDepartment,
} from "../../../store/actions/incidentAction";
import ReactExport from "react-data-export";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { confirmAlert } from "react-confirm-alert";
import { db, Timestamp } from "../../../config/firebase";
import config from "../../../config/config";

//images
import CategoryImage from "../../../assets/images/incident/category.svg";
import ExitIcon from "../../../assets/images/incident/exit.svg";
import ExportImage from "../../../assets/images/incident/export.svg";
import CalendarIcon from "../../../assets/images/incident/ic_calendar.svg";
import SearchIcon from "../../../assets/images/incident/search.svg";
import WhiteExport from "../../../assets/images/incident/whiteexport.svg";
import PlusIcon from "../../../assets/images/incident/plus.svg";
import ArrowDownDropDownIcon from "../../../assets/images/incident/arrowdowndrop.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import FilterIcon from "../../../assets/images/incident/filter.svg";

//css
import "./incidentList.css";
import "react-datepicker/dist/react-datepicker.css";
import CategoryList from "../../../components/CategoryList";
import PlaceList from "../../../components/PlaceList";
import ZoneList from "../../../components/ZoneList";
import TypeList from "../../../components/TypeList";
import IncidentPlacementList from "../../../components/IncidentPlacementList";
import PdfDocument from "./PdfDocument";

import momentTz from "moment-timezone";
import { forwardRef } from "react";
import api from "../../../config/api";
const { HAVE_VIEWED_INCIDENT } = config.api_endpoint;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function IncidentList(props) {
  const [fromDate, setFromDate] = useState({
    displayFromDate: moment().subtract(30, "days")._d, // moment().startOf("month")._d,
    apiFromDate: moment().subtract(30, "days").format("D MMM yyyy"), //moment().startOf("month").format("D MMM yyyy"),
  });
  const [toDate, setToDate] = useState({
    displayToDate: new Date(), // moment().endOf("month")._d,
    apiToDate: moment(new Date()).format("D MMM yyyy"), // moment().endOf("month").format("D MMM yyyy"),
  });
  const [pendingItems, setPendingItems] = useState([]);
  const [category, setCategory] = useState("All");
  const [park, setPark] = useState("All");
  const [zone, setZone] = useState("All");
  const [type, setType] = useState("All");
  const [placement, setPlacement] = useState("All");
  const [selectedCase, setSelectedCase] = useState("all cases");
  const [selectedDraft, setSelectedDraft] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIncdientIdForExcel, setSelectedIncdientIdForExcel] = useState(
    []
  );
  const [firstFetch, setFirstFetch] = useState(1);
  const [incidentPlacementListData, setIncidentPlacementList] = useState([]);
  const [cloneincidentList, setCloneincidentList] = useState([]);
  const excelRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [clickToGenerate, setClickToGenerate] = useState(null);
  const [allowDownload, setAllowDownload] = useState(false);
  const [isRemoveSearchInput, setIsRemoveSearchInput] = useState(false);
  const [filterZoneList, setFilterZoneList] = useState([]);
  const [parkList, setParkList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [cacheData, setCacheData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showSearchData, setShowSearchData] = useState(false);
  const [incidentAllList, setIncidentAllList] = useState([]);
  const [sortData, setSortData] = useState({
    isDescending: true,
    label: "date",
    count: 1,
  });

  const [selectedSort, setSelectedSort] = useState("date");

  const [sorting, setSorting] = useState("desc");
  const [incidentNoSorting, setIncidentNoSorting] = useState("");

  const [incidentTypeList, setIncidentTypeList] = useState([]);
  const scrollRef = useRef(null);
  let history = useHistory();

  const fetchIncidentsListFunction = (cat, exportOrNot, cases) => {
    let catfilter;
    if (category === "") {
      catfilter = cat;
    } else {
      catfilter = category;
    }
    console.log("fromDate", fromDate);

    const modifyCase = firstFetch === 1 ? cases : selectedCase;
    setClickToGenerate(null);
    props.fetchAllIncidents(
      fromDate.apiFromDate,
      toDate.apiToDate,
      catfilter,
      park,
      zone,
      type,
      placement,
      currentPage,
      selectedDraft,
      // modifyCase,
      selectedCase,
      props.AuthDetail.id,
      10,
      sorting,
      incidentNoSorting,
      searchInput
    );

    setCategory(catfilter);
    setLoading(false);

    // setLoading(true);
  };
  const [showFilter, setShowFilter] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  let filterP2 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p === "p2");
  let filterP3 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p === "p3");
  let filterP2a =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p === "p2a");
  let filterP3a =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p === "p3a");

  useEffect(() => {
    if (selectedDraft === "All" && currentPage === 1) {
      const items = JSON.parse(localStorage.getItem("pending"));
      setLoading(true);
      if (items) {
        let data = [...items];
        let filterCat;
        let filterPark;
        let filterZone;
        let filterType;
        let filterPlacement;

        if (category !== "All") {
          if (data && data.length > 0) {
            filterCat = data.filter((i) => {
              return i.category.toLowerCase() === category.toLowerCase();
            });
          }
        } else {
          filterCat = data;
        }

        if (park && park !== "All") {
          if (filterCat && filterCat.length > 0) {
            filterPark = filterCat.filter(
              (i) => i.park.toLowerCase() === park.toLowerCase()
            );
          } else {
            filterPark = filterCat;
          }
        } else {
          filterPark = filterCat;
        }

        if (zone && zone !== "All") {
          if (filterPark && filterPark.length > 0) {
            filterZone = filterPark.filter((i) => {
              if (i.main_zone) {
                return i.main_zone.toLowerCase() === zone.toLowerCase();
              }
            });
          } else {
            filterZone = filterPark;
          }
        } else {
          filterZone = filterPark;
        }
        if (type && type !== "All") {
          if (filterZone && filterZone.length > 0) {
            filterType = filterZone.filter((i) => {
              if (i.incident_type !== "") {
                return i.incident_type.toLowerCase() === type.toLowerCase();
              }
            });
          } else {
            filterType = filterZone;
          }
        } else {
          filterType = filterZone;
        }
        if (placement && placement !== "All") {
          if (filterType && filterType.length > 0) {
            filterType = filterType.filter((i) => {
              if (i.incident_placement !== "") {
                return (
                  i.incident_placement.toLowerCase() === placement.toLowerCase()
                );
              }
            });
          } else {
            filterPlacement = filterType;
          }
        } else {
          filterPlacement = filterType;
        }

        if (filterPlacement === undefined) {
          filterPlacement = [];
        }
        let finalPendingData = [];
        if (showSearchData) {
          if (filterPlacement.length > 0) {
            finalPendingData = filterPlacement.filter((val) => {
              if (searchInput === "") {
              } else {
                if (
                  val.incident_summary &&
                  val.incident_summary.incident_title
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
                ) {
                  return val;
                } else if (
                  val.incident_title &&
                  val.incident_title
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
                ) {
                  return val;
                } else if (
                  val.creator_name &&
                  val.creator_name
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
                ) {
                  return val;
                }
              }
            });
          } else {
            finalPendingData = filterPlacement;
          }
        }

        let reallyfinalPendingData = showSearchData
          ? finalPendingData
          : filterPlacement;

        let hasRangeDate = false;
        let filterRangeDatePendingDat = [];
        if (fromDate.apiFromDate && toDate.apiToDate) {
          filterRangeDatePendingDat = reallyfinalPendingData.filter((r) => {
            let rDate = new Date(r.date);
            return (
              rDate >= new Date(fromDate.apiFromDate) &&
              rDate <= new Date(toDate.apiToDate)
            );
          });
          hasRangeDate = true;
        }

        let mergeData = [];
        if (hasRangeDate) {
          mergeData = [...props.incidentList, ...filterRangeDatePendingDat];
        } else {
          mergeData = [...props.incidentList, ...reallyfinalPendingData];
        }
        let combinedata = [];
        if (sorting) {
          combinedata = mergeData.sort((a, b) => {
            let dataA = momentTz
              .tz(a.latest_updated_at, "Asia/Singapore")
              .format();
            let dataB = momentTz
              .tz(b.latest_updated_at, "Asia/Singapore")
              .format();
            return sorting === "desc"
              ? new Date(dataB).getTime() - new Date(dataA).getTime()
              : new Date(dataA).getTime() - new Date(dataB).getTime();
          });
          setIncidentAllList(combinedata);
        } else {
          setIncidentAllList(mergeData);
        }

        setLoading(false);
      } else {
        setIncidentAllList(props.incidentList);
        setLoading(false);
      }
    } else {
      setIncidentAllList(props.incidentList);
      setLoading(false);
    }
  }, [props.incidentList, selectedDraft]);

  // for fetching incident lists
  const fetchExportList = () => {
    let catfilter;
    let cat;
    let cases = "All";

    if (category === "") {
      catfilter = cat;
    } else {
      catfilter = category;
    }

    props.fetchAllIncidentsForExport(
      fromDate.apiFromDate,
      toDate.apiToDate,
      category,
      park,
      zone,
      type,
      placement,
      1,
      selectedDraft,
      selectedCase,
      props.AuthDetail.id,
      1000,
      sorting,
      incidentNoSorting,
      searchInput
    );
  };

  useEffect(() => {
    db.collection("incident_types")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          data.push({
            key: doc.data().incident_placements,
            text: doc.data().title,
            value: doc.data().title,
          });
        });
        setIncidentTypeList(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  // fetching "pending save item"
  useEffect(() => {
    fetchPendingItems();
    // setCurrentPage(1);
  }, [selectedDraft, category, park, zone, type, placement, fromDate, toDate]);

  const fetchPendingItems = () => {
    if (selectedDraft === "pending_save") {
      const items = JSON.parse(localStorage.getItem("pending"));
      let data = [];
      if (items) {
        data = [...items];
      } else {
        data = [];
      }

      let filterCat;
      let filterPark;
      let filterZone;
      let filterType;
      let filterPlacement;
      if (
        category === "All" &&
        park === "All" &&
        zone === "All" &&
        type === "All" &&
        placement === "All"
      ) {
        if (items) {
          let hasRangeDate = false;
          let checkDatePendingItems = [];
          if (fromDate.apiFromDate && toDate.apiToDate) {
            checkDatePendingItems = items.filter((r) => {
              let rDate = new Date(r.date);
              return (
                rDate >= new Date(fromDate.apiFromDate) &&
                rDate <= new Date(toDate.apiToDate)
              );
            });
            hasRangeDate = true;
          }
          if (hasRangeDate) {
            setPendingItems(checkDatePendingItems);
          } else {
            setPendingItems(items);
          }
          // setPendingItems(items);
        }
      } else {
        if (data === undefined) {
          setPendingItems([]);
        } else {
          if (category && category !== "All") {
            if (data.length > 0) {
              filterCat = data.filter((i) => {
                if (category !== "") {
                  return i.category.toLowerCase() === category.toLowerCase();
                }
              });
              if (filterCat.length === 0) {
                filterCat = [];
              }
            } else {
              filterCat = data;
            }
          } else {
            filterCat = data;
          }

          if (park && park !== "All") {
            if (filterCat.length > 0) {
              filterPark = filterCat.filter((i) => {
                if (i.park !== "") {
                  return i.park.toLowerCase() === park.toLowerCase();
                }
              });
            } else {
              filterPark = [];
            }
          } else {
            filterPark = filterCat;
          }

          if (zone && zone !== "All") {
            if (filterPark.length > 0) {
              filterZone = filterPark.filter((i) => {
                if (i.main_zone) {
                  return i.main_zone.toLowerCase() === zone.toLowerCase();
                }
              });
            } else {
              filterZone = [];
            }
          } else {
            filterZone = filterPark;
          }
          if (type && type !== "All") {
            if (filterZone.length > 0) {
              filterType = filterZone.filter((i) => {
                if (i.incident_type !== "") {
                  return i.incident_type.toLowerCase() === type.toLowerCase();
                }
              });
            } else {
              filterType = [];
            }
          } else {
            filterType = filterZone;
          }
          if (placement && placement !== "All") {
            if (filterZone.length > 0) {
              filterType = filterType.filter(
                (i) =>
                  i.incident_placement.toLowerCase() === placement.toLowerCase()
              );
            } else {
              filterPlacement = [];
            }
          } else {
            filterPlacement = filterType;
          }

          if (filterPlacement === undefined) {
            setPendingItems([]);
          }

          let hasRangeDate = false;
          let checkDatePendingItems = [];
          if (fromDate.apiFromDate && toDate.apiToDate) {
            checkDatePendingItems = filterPlacement.filter((r) => {
              let rDate = new Date(r.date);
              return (
                rDate >= new Date(fromDate.apiFromDate) &&
                rDate <= new Date(toDate.apiToDate)
              );
            });
            hasRangeDate = true;
          }

          if (hasRangeDate) {
            setPendingItems(checkDatePendingItems);
          } else {
            setPendingItems(filterPlacement);
          }
        }
      }
    }
  };

  const FilterPendingItems = (search) => {
    if (selectedDraft === "pending_save") {
      const items = JSON.parse(localStorage.getItem("pending"));
      let data = [];
      if (items) {
        data = [...items];
      } else {
        data = [];
      }

      let filterCat;
      let filterPark;
      let filterZone;
      let filterType;
      let filterPlacement;
      if (
        category === "All" &&
        park === "All" &&
        zone === "All" &&
        type === "All" &&
        placement === "All"
      ) {
        if (items) {
          let hasRangeDate = false;
          let checkDatePendingItems = [];
          if (fromDate.apiFromDate && toDate.apiToDate) {
            checkDatePendingItems = items.filter((r) => {
              let rDate = new Date(r.date);
              return (
                rDate >= new Date(fromDate.apiFromDate) &&
                rDate <= new Date(toDate.apiToDate)
              );
            });
            hasRangeDate = true;
          }
          if (hasRangeDate) {
            let data = [];
            checkDatePendingItems.filter((val) => {
              if (search === "") {
              } else {
                if (
                  (val.incident_summary &&
                    val.incident_summary.incident_title
                      .toLowerCase()
                      .includes(search)) ||
                  (val.incident_title &&
                    val.incident_title
                      .toLowerCase()
                      .includes(search.toLowerCase())) ||
                  (val.creator_name &&
                    val.creator_name
                      .toLowerCase()
                      .includes(search.toLowerCase()))
                ) {
                  setShowSearchData(true);
                  return data.push(val);
                }
              }
            });
            setPendingItems(data);
          } else {
            let data = [];
            items.filter((val) => {
              if (search === "") {
              } else {
                if (
                  (val.incident_summary &&
                    val.incident_summary.incident_title
                      .toLowerCase()
                      .includes(search)) ||
                  (val.incident_title &&
                    val.incident_title
                      .toLowerCase()
                      .includes(search.toLowerCase())) ||
                  (val.creator_name &&
                    val.creator_name
                      .toLowerCase()
                      .includes(search.toLowerCase()))
                ) {
                  setShowSearchData(true);
                  return data.push(val);
                }
              }
            });
            setPendingItems(data);
          }
          // setPendingItems(items);
        }
      } else {
        if (data === undefined) {
          setPendingItems([]);
        } else {
          if (category && category !== "All") {
            if (data.length > 0) {
              filterCat = data.filter((i) => {
                if (category !== "") {
                  return i.category.toLowerCase() === category.toLowerCase();
                }
              });
              if (filterCat.length === 0) {
                filterCat = [];
              }
            } else {
              filterCat = data;
            }
          } else {
            filterCat = data;
          }

          if (park && park !== "All") {
            if (filterCat.length > 0) {
              filterPark = filterCat.filter((i) => {
                if (i.park !== "") {
                  return i.park.toLowerCase() === park.toLowerCase();
                }
              });
            } else {
              filterPark = [];
            }
          } else {
            filterPark = filterCat;
          }

          if (zone && zone !== "All") {
            if (filterPark.length > 0) {
              filterZone = filterPark.filter((i) => {
                if (i.main_zone) {
                  return i.main_zone.toLowerCase() === zone.toLowerCase();
                }
              });
            } else {
              filterZone = [];
            }
          } else {
            filterZone = filterPark;
          }
          if (type && type !== "All") {
            if (filterZone.length > 0) {
              filterType = filterZone.filter((i) => {
                if (i.incident_type !== "") {
                  return i.incident_type.toLowerCase() === type.toLowerCase();
                }
              });
            } else {
              filterType = [];
            }
          } else {
            filterType = filterZone;
          }
          if (placement && placement !== "All") {
            if (filterZone.length > 0) {
              filterType = filterType.filter(
                (i) =>
                  i.incident_placement.toLowerCase() === placement.toLowerCase()
              );
            } else {
              filterPlacement = [];
            }
          } else {
            filterPlacement = filterType;
          }

          if (filterPlacement === undefined) {
            setPendingItems([]);
          }

          let hasRangeDate = false;
          let checkDatePendingItems = [];
          if (fromDate.apiFromDate && toDate.apiToDate) {
            checkDatePendingItems = filterPlacement.filter((r) => {
              let rDate = new Date(r.date);
              return (
                rDate >= new Date(fromDate.apiFromDate) &&
                rDate <= new Date(toDate.apiToDate)
              );
            });
            hasRangeDate = true;
          }

          if (hasRangeDate) {
            let data = [];

            filterPlacement.filter((val) => {
              if (search === "") {
              } else {
                if (
                  (val.incident_summary &&
                    val.incident_summary.incident_title
                      .toLowerCase()
                      .includes(search)) ||
                  (val.incident_title &&
                    val.incident_title
                      .toLowerCase()
                      .includes(search.toLowerCase())) ||
                  (val.creator_name &&
                    val.creator_name
                      .toLowerCase()
                      .includes(search.toLowerCase()))
                ) {
                  setShowSearchData(true);

                  return data.push(val);
                }
              }
            });

            setSearchData(data);
            setPendingItems(data);
          } else {
            let data = [];

            filterPlacement.filter((val) => {
              if (search === "") {
              } else {
                if (
                  (val.incident_summary &&
                    val.incident_summary.incident_title
                      .toLowerCase()
                      .includes(search)) ||
                  (val.incident_title &&
                    val.incident_title
                      .toLowerCase()
                      .includes(search.toLowerCase())) ||
                  (val.creator_name &&
                    val.creator_name
                      .toLowerCase()
                      .includes(search.toLowerCase()))
                ) {
                  setShowSearchData(true);
                  return data.push(val);
                }
              }
            });

            setSearchData(data);
            setPendingItems(data);
          }
        }
      }
    }
  };
  useEffect(() => {
    if (isRemoveSearchInput) {
      fetchPendingItems();
    }
  }, [isRemoveSearchInput]);

  // fetching dropdown data
  useEffect(() => {
    db.collection("static_data")
      .doc("parks")
      .get()
      .then((querySnapshot) => {
        let parkData = [];
        let zoneData = [];
        setParkList([]);
        setZoneList([]);
        setFilterZoneList([]);
        parkData.push({
          key: "All",
          text: "All",
          value: "All",
        });
        querySnapshot.data().parks_arr.forEach((doc, index) => {
          parkData.push({
            key: index,
            text: doc.park,
            value: doc.park,
          });
        });

        querySnapshot.data().parks_arr.forEach((doc, index) => {
          doc.mainzone.map((z, index) => {
            zoneData.push({
              key: index,
              text: z.title,
              value: z.title,
              mainTitle: doc.park,
            });
          });
        });

        setZoneList(zoneData); // all Zone List

        setParkList(parkData);
      })
      .catch((err) => {
        // setTypeListLoader(false);
        console.log("err", err);
      });
  }, []);

  // for exporting specific incident files
  useEffect(() => {
    if (excelRef.current && selectedIncdientIdForExcel.length === 1) {
      excelRef.current.click();
      setSelectedIncdientIdForExcel([]);
      excelRef.current = null;
    }
  }, [excelRef, selectedIncdientIdForExcel]);

  const handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage;
    setCurrentPage(page);
  };

  let optionsCases = [
    {
      key: "all cases",
      text: "All Cases",
      value: "all cases",
    },
    {
      key: "my_case",
      text: "My Cases",
      value: "my_case",
    },
  ];

  useEffect(() => {
    setAllowDownload(false);

    setCloneincidentList([]);

    if (!props.incidentLoading) {
      if (filterP2 && filterP3) {
        return fetchIncidentsListFunction("All", false, "All");
      }
      if (filterP2) {
        return fetchIncidentsListFunction("All", false, "All");
      }
      if (filterP3) {
        return fetchIncidentsListFunction("All", false, "All");
      }
      if (filterP2a) {
        return fetchIncidentsListFunction("All", false, "my_case");
      }
      if (filterP3a) {
        return fetchIncidentsListFunction("All", false, "my_case");
      }
    }

    var timeDiff =
      new Date(moment(toDate.apiToDate).format()) -
      new Date(moment(fromDate.apiFromDate).format());

    setFirstFetch(firstFetch + 1);
  }, [
    fromDate,
    toDate,
    category,
    park,
    zone,
    type,
    placement,
    currentPage,
    selectedDraft,
    selectedCase,
    showSearchData,
    sorting,
    incidentNoSorting,
  ]);
  const confirmAlertFunction = (data) => {
    console.log(data);
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="incidentAlertBox" onClick={onClose}>
            <div
              style={{
                width: 400,
                height: 200,
                backgroundColor: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "13px 20px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                onClick={onClose}
              >
                <img src={ExitIcon} />
              </div>
              <div
                style={{ fontSize: 16, fontWeight: "bold", marginBottom: 15 }}
              >
                Export Incident Report
              </div>
              <div style={{ fontSize: 14, marginBottom: 18 }}>
                Do you want to download or exit?
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <Button className="witness_cancel_button" onClick={onClose}>
                    No
                  </Button>
                </div>
                <div>
                  <PDFDownloadLink
                    document={<PdfDocument data={data} />}
                    fileName={`Incident ${data.incident_id}.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Dimmer active inverted>
                          <Loader inverted content="Loading" />
                        </Dimmer>
                      ) : (
                        <Button className="downloadBtn" onClick={onClose}>
                          <span style={{ marginLeft: -10 }}>Download</span>
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const downloadAlert = (message) => {
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="incidentAlertBox" onClick={onClose}>
            <div
              style={{
                width: 400,
                height: 200,
                backgroundColor: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "13px 20px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                onClick={() => {
                  setAllowDownload(false);
                  setCloneincidentList([]);
                  onClose();
                }}
              >
                <img src={ExitIcon} />
              </div>
              <div
                style={{ fontSize: 16, fontWeight: "bold", marginBottom: 15 }}
              >
                {message === "Please select less than 31 days."
                  ? "Export Incident Report"
                  : "Confirm to submit"}
              </div>
              <div style={{ fontSize: 14, marginBottom: 18 }}>{message}</div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <Button
                    className="witness_cancel_button"
                    onClick={() => {
                      setAllowDownload(false);
                      setCloneincidentList([]);
                      onClose();
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const timeRangeAlert = (message) => {
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="incidentAlertBox" onClick={onClose}>
            <div
              style={{
                width: 400,
                height: 200,
                backgroundColor: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "13px 20px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                onClick={() => {
                  onClose();
                }}
              >
                <img src={ExitIcon} />
              </div>
              <div
                style={{ fontSize: 16, fontWeight: "bold", marginBottom: 15 }}
              >
                Confirm to submit
              </div>
              <div style={{ fontSize: 14, marginBottom: 18 }}>{message}</div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <Button
                    className="witness_cancel_button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const ExcelTitle = (text) => {
    return {
      title: text,
      style: { font: { sz: "12", bold: true } },
      width: { wpx: 125 },
    };
  };

  const ExcelValue = (text) => {
    return {
      value: text,
      style: { font: { sz: "12" } },
    };
  };

  const ExcelValueArray = (arr) => {
    return {
      value: arr.length > 0 && arr ? arr.join(", \r\n ") : "",
      style: {
        font: { font: { sz: "12" } },
      },
    };
  };

  const changeTimeFormat = (date, time) => {
    let changedDateFormat = moment(date).format("YYYY-MM-DD");
    let combinedDateTime = `${changedDateFormat}T${time}`;

    return moment(new Date(combinedDateTime)).format("hh:mm:ss a");
  };

  const arrayToStringPersonInvolved = (data) => {
    // same as sorting  person involved form
    let sortingData = [];
    if (data && data.length > 0) {
      data.map((_) => {
        return sortingData.push({
          Casualty_No: _.casualty_no,
          Injury_name: _.injury_name,
          Age: _.age,
          Gender: _.gender,
          Contact_no: _.contact_no,
          Address: _.address,
          Email_address: _.email,
          Nationality: _.nationality,
          Is_there_bodily_injury: _.is_bodily_injury,
          What_caused_the_injury: _.injury_type,
          Injury_Description: _.injury_desription,
          break: "break",
        });
      });

      let tempArray = JSON.stringify(sortingData);
      let convert = tempArray
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll(":", " - ")
        .replaceAll("{", " ")
        .replaceAll("}", " ")
        .replaceAll('"', " ")
        .replaceAll("_", " ")
        .replaceAll(",", "\r\n")
        .replaceAll("break", "\r\n");

      return convert;
    } else {
      return "";
    }
  };

  const arrayToStringPhotoNVideo = (data) => {
    if (data.length > 0) {
      let tempArray = JSON.stringify(data);
      let convert = tempArray
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll(",", "\r\n");

      return convert;
    } else {
      return "";
    }
  };
  const arrayToStringCasualty = (data) => {
    let sortingData = [];
    if (data.length > 0) {
      data.map((_) => {
        return sortingData.push({
          Name: _.guest_name,
          Contact_Number: _.guest_contact_number,
          Address: _.guest_address,
          Email_Address: _.guest_email,
          Remarks: _.guest_remarks,
          break: "break",
        });
      });

      let tempArray = JSON.stringify(sortingData);
      let convert = tempArray
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll(":", " - ")
        .replaceAll("{", " ")
        .replaceAll("}", " ")
        .replaceAll('"', " ")
        .replaceAll("_", " ")
        .replaceAll(",", "\r\n")
        .replaceAll("break", "\r\n");

      return convert;
    } else {
      return "";
    }
  };
  const arrayToStringIncidentUpdate = (data) => {
    let sortingData = [];
    if (data.length > 0) {
      data.map((_) => {
        return sortingData.push({
          Remark: _.remark,
          Submitted_by: _.submitted_by,
          image: _.img_url,
          break: "break",
        });
      });
      let tempArray = JSON.stringify(sortingData);
      let convert = tempArray
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll(":", " - ")
        .replaceAll("https - ", "https:")
        .replaceAll("{", " ")
        .replaceAll("}", " ")
        .replaceAll('"', " ")
        .replaceAll("_", " ")
        .replaceAll(",", "\r\n")
        .replaceAll("break", "\r\n");

      return convert;
    } else {
      return "";
    }
  };

  const arrayToStringCA = (data) => {
    let sortingData = [];
    if (data.length > 0) {
      data.map((_, id) => {
        return sortingData.push({
          [`Action ${id + 1}`]: _.recommended_ca_no,
          Description: _.description,
          Status: _.recommended_ca_status,
          Action_Assigned: _.action_assigned_to,
          [`To Be Done By`]: _.to_be_done_by,
          break: "break",
        });
      });
      let tempArray = JSON.stringify(sortingData);
      let convert = tempArray
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll(":", " - ")
        .replaceAll("https - ", "https:")
        .replaceAll("{", " ")
        .replaceAll("}", " ")
        .replaceAll('"', " ")
        .replaceAll("_", " ")
        .replaceAll(",", "\r\n")
        .replaceAll("break", "\r\n");

      return convert;
    } else {
      return "";
    }
  };
  const changeStatus = () => {
    props.changeLoaderForExport();
    return "";
  };
  //  All Excel  Files  Generator

  const _AllExcelFiles = [
    {
      columns: [
        ExcelTitle("Is this an emergency incident?"),
        ExcelTitle("Category*"),
        ExcelTitle("Park*"),
        ExcelTitle("Zone*"),
        ExcelTitle("Date"),
        ExcelTitle("Time"),
        ExcelTitle("Incident Title*"),
        ExcelTitle("Incident Detail*"),
        ExcelTitle("Add Video & Photos*"),
        ExcelTitle("Type of incident*"),
        ExcelTitle("Incident placement*"),
        ExcelTitle("Incident classifications*"),
        ExcelTitle("Type of injury"),
        ExcelTitle("Is follow up required?"),
        ExcelTitle("Weather"),
        ExcelTitle("Condition of area"),
        ExcelTitle("Emergency Services Activated"),
        ExcelTitle("Will there be a need to recall people?*"),
        ExcelTitle("Person Involved Details"),
        ExcelTitle("Witnesses Details"),
        ExcelTitle("Type of property"),
        ExcelTitle("What is/are damaged"),
        ExcelTitle("Did the incident involved internal or external party?"),
        ExcelTitle("Information of internal / external party"),
        ExcelTitle("Priority of fix"),
        ExcelTitle("Substandard Action"),
        ExcelTitle("Substandard Condition"),
        ExcelTitle("Personnel Factors"),
        ExcelTitle("Job Factors"),
        ExcelTitle("Work Stoppage"),
        ExcelTitle("Incident updates"),
        ExcelTitle("Post-Incident Follow-Up"),
        ExcelTitle("Unsafe Act"),
        ExcelTitle("Unsafe Condition"),
        ExcelTitle("Recommended Corrective Actions"),
      ],
      //  All Export
      data:
        cloneincidentList.length > 0 &&
        cloneincidentList.map((data) => [
          ExcelValue(
            data.case_details.incident_description.is_emergency_incident
          ),

          ExcelValue(
            data.case_details.incident_description.category.toUpperCase()
          ),

          ExcelValue(data.case_details.incident_description.park),
          ExcelValue(data.filtered_zone_type),
          ExcelValue(data.case_details.incident_description.date),
          ExcelValue(
            changeTimeFormat(
              data.case_details.incident_description.date,
              data.case_details.incident_description.time
            )
          ),
          ExcelValue(data.case_details.incident_description.incident_title),
          ExcelValue(data.case_details.incident_description.incident_remarks),

          {
            value:
              data.case_details.photo_video.length > 0 &&
              data.case_details.photo_video.join(", \r\n "),
            style: {
              font: { font: { sz: "14" } },
            },
          },

          ExcelValue(data.case_details.incident_description.incident_type),

          ExcelValue(data.case_details.incident_description.incident_placement),
          ExcelValue(
            data.case_details.incident_description.incident_classification
          ),

          ExcelValueArray(data.case_details.incident_description.injury_type),

          ExcelValue(
            data.case_details.incident_description.is_followup_required
          ),

          ExcelValue(data.case_details.incident_description.weather),
          ExcelValueArray(
            data.case_details.incident_description.area_condition
          ),

          ExcelValueArray(
            data.case_details.incident_description.emergency_services_activated
          ),
          ExcelValue(data.case_details.incident_description.recall_people),
          ExcelValue(
            arrayToStringPersonInvolved(
              data.case_details.person_involved_details
            )
          ),
          ExcelValue(
            arrayToStringCasualty(data.case_details.witness_particulars)
          ),
          ExcelValue(data.case_details.property_damage_details.property_type),
          ExcelValue(data.case_details.property_damage_details.damaged_things),
          ExcelValue(data.case_details.property_damage_details.party_type),
          ExcelValue(data.case_details.property_damage_details.party_info),
          ExcelValue(data.case_details.property_damage_details.priority_of_fix),
          ExcelValue(
            data.case_details.mpd_incident_analysis.substandard_action
          ),
          ExcelValue(
            data.case_details.mpd_incident_analysis.substandard_condition
          ),
          ExcelValue(data.case_details.mpd_incident_analysis.personnel_factors),
          ExcelValue(data.case_details.mpd_incident_analysis.job_factors),
          ExcelValue(data.case_details.mpd_incident_analysis.work_stoppage),
          ExcelValue(arrayToStringIncidentUpdate(data.incident_updates)),
          ExcelValue(data.aar.root_cause.follow_up),
          ExcelValue(data.aar.root_cause.unsafe_act),
          ExcelValue(data.aar.root_cause.unsafe_cond),
          ExcelValue(arrayToStringCA(data.aar.recommended_ca)),
          ExcelValue(changeStatus()),
        ]),
    },
  ];
  console.log(incidentAllList);

  const _AllExcelFilesForSearch = [
    {
      columns: [
        ExcelTitle("Is this an emergency incident?"),
        ExcelTitle("Category*"),
        ExcelTitle("Park*"),
        ExcelTitle("Zone*"),
        ExcelTitle("Date"),
        ExcelTitle("Time"),
        ExcelTitle("Incident Title*"),
        ExcelTitle("Incident Detail*"),
        ExcelTitle("Add Video & Photos*"),
        ExcelTitle("Type of incident*"),
        ExcelTitle("Incident placement*"),
        ExcelTitle("Incident classifications*"),
        ExcelTitle("Type of injury"),
        ExcelTitle("Is follow up required?"),
        ExcelTitle("Weather"),
        ExcelTitle("Condition of area"),
        ExcelTitle("Emergency Services Activated"),
        ExcelTitle("Will there be a need to recall people?*"),
        ExcelTitle("Person Involved Details"),
        ExcelTitle("Witnesses Details"),
        ExcelTitle("Type of property"),
        ExcelTitle("What is/are damaged"),
        ExcelTitle("Did the incident involved internal or external party?"),
        ExcelTitle("Information of internal / external party"),
        ExcelTitle("Priority of fix"),
        ExcelTitle("Substandard Action"),
        ExcelTitle("Substandard Condition"),
        ExcelTitle("Personnel Factors"),
        ExcelTitle("Job Factors"),
        ExcelTitle("Work Stoppage"),
        ExcelTitle("Incident updates"),
        ExcelTitle("Post-Incident Follow-Up"),
        ExcelTitle("Unsafe Act"),
        ExcelTitle("Unsafe Condition"),
        ExcelTitle("Recommended Corrective Actions"),
      ],
      //  All Export
      data:
        incidentAllList.length > 0 &&
        incidentAllList.map((data) => [
          ExcelValue(
            !data.pending &&
              data.case_details.incident_description.is_emergency_incident
          ),

          ExcelValue(
            !data.pending && data.case_details.incident_description.category
          ),
          ExcelValue(
            !data.pending && data.case_details.incident_description.park
          ),
          ExcelValue(
            !data.pending && data.case_details.incident_description.zone
          ),
          ExcelValue(
            !data.pending && data.case_details.incident_description.date
          ),
          ExcelValue(
            changeTimeFormat(
              !data.pending && data.case_details.incident_description.date,
              !data.pending && data.case_details.incident_description.time
            )
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.incident_description.incident_title
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.incident_description.incident_remarks
          ),

          {
            value:
              !data.pending &&
              data.case_details.photo_video.length > 0 &&
              data.case_details.photo_video.join(", \r\n "),
            style: {
              font: { font: { sz: "14" } },
            },
          },

          ExcelValue(
            !data.pending &&
              data.case_details.incident_description.incident_type
          ),

          ExcelValue(
            !data.pending &&
              data.case_details.incident_description.incident_placement
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.incident_description.incident_classification
          ),

          ExcelValueArray(
            !data.pending && data.case_details.incident_description.injury_type
          ),

          ExcelValue(
            !data.pending && data.case_details.incident_description.weather
          ),
          ExcelValueArray(
            !data.pending &&
              data.case_details.incident_description.area_condition
          ),

          ExcelValue(
            !data.pending &&
              data.case_details.incident_description.is_followup_required
          ),

          ExcelValueArray(
            !data.pending &&
              data.case_details.incident_description
                .emergency_services_activated
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.incident_description.recall_people
          ),
          ExcelValue(
            arrayToStringPersonInvolved(
              !data.pending && data.case_details.person_involved_details
            )
          ),
          ExcelValue(
            arrayToStringCasualty(
              !data.pending && data.case_details.witness_particulars
            )
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.property_damage_details.property_type
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.property_damage_details.damaged_things
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.property_damage_details.party_type
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.property_damage_details.party_info
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.property_damage_details.priority_of_fix
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.mpd_incident_analysis.substandard_action
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.mpd_incident_analysis.substandard_condition
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.mpd_incident_analysis.personnel_factors
          ),
          ExcelValue(
            !data.pending && data.case_details.mpd_incident_analysis.job_factors
          ),
          ExcelValue(
            !data.pending &&
              data.case_details.mpd_incident_analysis.work_stoppage
          ),
          ExcelValue(
            arrayToStringIncidentUpdate(!data.pending && data.incident_updates)
          ),
          ExcelValue(!data.pending && data.aar.root_cause.follow_up),
          ExcelValue(!data.pending && data.aar.root_cause.unsafe_act),
          ExcelValue(!data.pending && data.aar.root_cause.unsafe_cond),
          ExcelValue(!data.pending && arrayToStringCA(data.aar.recommended_ca)),
        ]),
    },
  ];
  const fetchPlacementOptions = (id) => {
    let data = [];
    data.push({
      key: "All",
      text: "All",
      value: "All",
    });
    setIncidentPlacementList([]);
    db.collection("incident_placements")
      .doc(id)
      .get()
      .then((querySnapshot) => {
        setIncidentPlacementList([]);

        querySnapshot.data().incident_placement_arr.map((doc, index) => {
          data.push({
            key: doc.incident_classifications,
            text: doc.title,
            value: doc.title,
          });
        });
        setIncidentPlacementList(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const SideBar = () => {
    const CustomFromDatepicker = forwardRef(({ value, onClick }, ref) => (
      <div className="sidebar_datePicker" onClick={onClick} ref={ref}>
        <input type="text" value={value} />
        {value ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "#008237",
              fontSize: "small",
              cursor: "pointer",
              position: "absolute",
              right: 35,
              top: 13,
              // zIndex: 10,
              fontSize: 20,
              cursor: "pointer",
            }}
            onClick={() => {
              setFromDate({
                displayFromDate: "",
                apiFromDate: "",
              });
            }}
          >
            X
          </div>
        ) : null}
        <img src={CalendarIcon} />
      </div>
    ));
    const CustomToDatepicker = forwardRef(({ value, onClick }, ref) => (
      <div className="sidebar_datePicker" onClick={onClick} ref={ref}>
        <input type="text" value={value} />
        {value ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "#008237",
              fontSize: "small",
              cursor: "pointer",
              position: "absolute",
              right: 35,
              top: 13,
              // zIndex: 10,
              fontSize: 20,
              cursor: "pointer",
            }}
            onClick={() => {
              setToDate({
                displayToDate: "",
                apiToDate: "",
              });
            }}
          >
            X
          </div>
        ) : null}
        <img src={CalendarIcon} />
      </div>
    ));
    return (
      <div className="inlist_sidebar">
        <div>
          {/* ---- From Date*/}
          <div className="input_container DatePicker_custom">
            <div className="input_title">From</div>
            {/* <div className="sidebar_datePicker"> */}
            <DatePicker
              customInput={<CustomFromDatepicker />}
              onChangeRaw={(e) => e.preventDefault()}
              selected={fromDate.displayFromDate}
              onChange={(date) => {
                setSearchInput("");
                if (
                  toDate.displayToDate !== "" &&
                  date > toDate.displayToDate
                ) {
                  timeRangeAlert(
                    'Plesae select "To Date" less than "From Date"'
                  );
                  setFromDate({
                    displayFromDate: "",
                    apiFromDate: "",
                  });
                } else {
                  console.log("doing", date);
                  setCurrentPage(1);
                  setCloneincidentList([]);
                  setFromDate({
                    ...fromDate,
                    displayFromDate: date,
                    apiFromDate: moment(date).format("D MMM YYYY"),
                  });
                  scrollToTopFunction();
                }
              }}
            />
            {/* <img src={CalendarIcon} /> */}
            {/* </div> */}
          </div>

          {/* ---- To Date*/}
          <div className="input_container DatePicker_custom">
            <div className="input_title">To</div>
            {/* <div className="sidebar_datePicker"> */}
            <DatePicker
              customInput={<CustomToDatepicker />}
              onChangeRaw={(e) => e.preventDefault()}
              selected={toDate.displayToDate}
              onChange={(date) => {
                setSearchInput("");
                if (
                  fromDate.displayFromDate !== "" &&
                  date < fromDate.displayFromDate
                ) {
                  timeRangeAlert(
                    'Plesae select "To Date" greater than "From Date"'
                  );
                  setToDate({
                    displayToDate: "",
                    apiToDate: "",
                  });
                } else {
                  setCurrentPage(1);
                  setCloneincidentList([]);
                  setToDate({
                    displayToDate: date,
                    apiToDate: moment(date).format("D MMM YYYY"),
                  });
                  scrollToTopFunction();
                }
              }}
            />
            {/* <img src={CalendarIcon} />
            </div> */}
          </div>

          {/* ---- Category*/}

          <div className="input_container">
            <div className="input_title">Category</div>
            <div>
              <CategoryList
                custom_classes="incident-place-list  incident-list-dropdown"
                icon_classes="incident-place-list-icon"
                value={category}
                perms={props.AuthDetail.perms}
                onChange={(e, data) => {
                  setSearchInput("");
                  setCurrentPage(1);
                  setCloneincidentList([]);
                  scrollToTopFunction();
                  setCategory(data.value);
                }}
              />
            </div>
          </div>

          {/* ---- Park*/}
          <div className="input_container">
            <div className="input_title">Park</div>
            <div>
              <PlaceList
                custom_classes="incident-place-list  incident-list-dropdown park_side_drop"
                icon_classes="incident-place-list-icon"
                isSideBar={true}
                onChange={(e, data) => {
                  setSearchInput("");
                  let fZoneList = zoneList.filter(
                    (z) => z.mainTitle === data.value
                  );
                  scrollToTopFunction();
                  setCloneincidentList([]);
                  setFilterZoneList([]);
                  setPark(data.value);
                  setCurrentPage(1);

                  setFilterZoneList([
                    { key: "All", text: "All", value: "All", mainTitle: "All" },
                    ...fZoneList,
                  ]);
                }}
                value={park}
                options={parkList}
              />
            </div>
          </div>

          {/* ---- Zone*/}
          <div className="input_container">
            <div className="input_title">Zone</div>
            <div>
              <PlaceList
                isSideBar={true}
                custom_classes="incident-place-list  incident-list-dropdown "
                icon_classes="incident-place-list-icon"
                onChange={(e, data) => {
                  setSearchInput("");
                  setCurrentPage(1);
                  scrollToTopFunction();
                  setCloneincidentList([]);
                  setZone(data.value);
                }}
                noResultsMessage="Please select park."
                value={zone}
                options={filterZoneList}
              />
            </div>
          </div>

          {/* ---- Type*/}
          <div className="input_container">
            <div className="input_title">Type</div>
            <div>
              <TypeList
                custom_classes="incident-place-list  incident-list-dropdown"
                icon_classes="incident-place-list-icon"
                onChange={(e, data) => {
                  setSearchInput("");
                  setCurrentPage(1);
                  scrollToTopFunction();
                  setCloneincidentList([]);
                  setType(data.value);
                  if (data.options.length > 0) {
                    const filterId = data.options.filter(
                      (d) => d.value === data.value
                    );

                    fetchPlacementOptions(filterId[0].key);
                  }
                }}
                options={incidentTypeList}
                value={type}
              />
            </div>
          </div>

          {/* ---- Incident Placement*/}
          <div className="input_container">
            <div className="input_title">Incident Placement</div>
            <div>
              <IncidentPlacementList
                custom_classes="incident-place-list  incident-list-dropdown"
                icon_classes="incident-place-list-icon"
                onChange={(e, data) => {
                  setSearchInput("");
                  setCurrentPage(1);
                  scrollToTopFunction();
                  setCloneincidentList([]);
                  setPlacement(data.value);
                }}
                value={placement}
                options={incidentPlacementListData}
              />
            </div>
          </div>

          {isMobile && (
            <Button
              className="witness_save_button"
              style={{ width: 150 }}
              onClick={() => {
                setShowFilter(false);
              }}
            >
              Exit
            </Button>
          )}

          {/* ----End */}
        </div>
      </div>
    );
  };

  useEffect(() => {
    setAllowDownload(false);
    setCloneincidentList([]);
  }, []);
  useEffect(() => {
    props.fetchAllSubDepartment();
  }, []);
  useEffect(() => {
    if (props.incidentListForExport.length > 0) {
      setCloneincidentList(props.incidentListForExport);
    }
  }, [props.incidentListForExport, props.exportLoading]);

  var timeDiff =
    new Date(moment(toDate.apiToDate).format()) -
    new Date(moment(fromDate.apiFromDate).format());

  const IncidentTableList = () => {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} computer={2} tablet={16}>
              {!isMobile && (
                <div
                  className="new_incident"
                  onClick={() => {
                    history.push(`/incidents_list/new_incident`);
                  }}
                >
                  <img src={PlusIcon} style={{ marginRight: 10 }} /> New
                  Incident
                </div>
              )}
            </Grid.Column>
            <Grid.Column mobile={16} computer={2} tablet={16}>
              <div
                style={{
                  display: isMobile && "flex",
                  marginTop: isMobile && 15,
                }}
              >
                <PlaceList
                  custom_classes="incident-place-list  incident-list-dropdown test"
                  icon_classes="incident-place-list-icon"
                  onChange={(e, data) => {
                    setSelectedCase(data.value);
                    setFirstFetch(firstFetch + 1);
                  }}
                  value={selectedCase}
                  options={optionsCases}
                  disabled={props.incidentLoading}
                />
                {isMobile && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: 5,
                      zIndex: 2,
                    }}
                  >
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        cursor: "pointer",
                      }}
                      onClick={() => setShowFilter(true)}
                    >
                      <img src={FilterIcon} width="40px" height="40px" />
                    </div>
                  </div>
                )}
              </div>
            </Grid.Column>

            <Grid.Column
              mobile={16}
              computer={8}
              tablet={16}
              only="widescreen"
              alignItems="left"
            >
              <div className="inlist_draft">
                <div
                  className={`inlist_draft_item ${
                    selectedDraft === "All" && "active"
                  }`}
                  onClick={(e) => {
                    if (props.incidentLoading) {
                      return e.preventDefault();
                    }
                    setSearchInput("");
                    setShowSearchData(false);
                    setAllowDownload(false);
                    setCloneincidentList([]);
                    setClickToGenerate(null);
                    setSelectedDraft("All");
                    setCurrentPage(1);
                  }}
                >
                  All
                </div>
                <div
                  className={`inlist_draft_item ${
                    selectedDraft === "Open" && "active"
                  }`}
                  onClick={(e) => {
                    if (props.incidentLoading) {
                      return e.preventDefault();
                    }
                    setSearchInput("");
                    setShowSearchData(false);
                    setAllowDownload(false);
                    setCloneincidentList([]);
                    setClickToGenerate(null);
                    setSelectedDraft("Open");
                  }}
                >
                  Open
                </div>
                <div
                  className={`inlist_draft_item ${
                    selectedDraft === "Pending Approval" && "active"
                  }`}
                  onClick={(e) => {
                    if (props.incidentLoading) {
                      return e.preventDefault();
                    }
                    setSearchInput("");
                    setShowSearchData(false);
                    setAllowDownload(false);
                    setCloneincidentList([]);
                    setClickToGenerate(null);
                    setSelectedDraft("Pending Approval");
                    setCurrentPage(1);
                  }}
                >
                  Pending Approval
                </div>
                <div
                  className={`inlist_draft_item ${
                    selectedDraft === "Draft" && "active"
                  }`}
                  onClick={(e) => {
                    if (props.incidentLoading) {
                      return e.preventDefault();
                    }
                    setSearchInput("");
                    setShowSearchData(false);
                    setAllowDownload(false);
                    setCloneincidentList([]);
                    setClickToGenerate(null);
                    setSelectedDraft("Draft");
                    setCurrentPage(1);
                  }}
                >
                  Draft
                </div>
                <div
                  className={`inlist_draft_item ${
                    selectedDraft === "pending_save" && "active"
                  }`}
                  onClick={() => {
                    setAllowDownload(false);
                    setCloneincidentList([]);
                    setSelectedDraft("pending_save");
                    setSearchInput("");
                    setShowSearchData(false);
                  }}
                >
                  Pending Save
                </div>
                <div
                  className={`inlist_draft_item ${
                    selectedDraft === "Closed" && "active"
                  }`}
                  onClick={(e) => {
                    if (props.incidentLoading) {
                      return e.preventDefault();
                    }
                    setSearchInput("");
                    setShowSearchData(false);
                    setAllowDownload(false);
                    setCloneincidentList([]);
                    setClickToGenerate(null);
                    setSelectedDraft("Closed");
                    setCurrentPage(1);
                  }}
                >
                  Closed
                </div>

                <div
                  className={`inlist_draft_item ${
                    selectedDraft === "Archived" && "active"
                  }`}
                  onClick={() => {
                    setAllowDownload(false);
                    setCloneincidentList([]);
                    setClickToGenerate(null);
                    setSelectedDraft("Archived");
                    setCurrentPage(1);
                  }}
                >
                  Archived
                </div>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} computer={1} tablet={16}></Grid.Column>
            <Grid.Column mobile={16} computer={3} tablet={16}>
              {!isMobile && (
                <div className="expo_container">
                  {showSearchData ? (
                    <div className="inlist_expo" style={{ marginBottom: 10 }}>
                      <ExcelFile
                        filename="Incidents List"
                        element={
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <img
                              src={WhiteExport}
                              style={{ marginRight: 10 }}
                            />
                            Export Incident List
                          </div>
                        }
                      >
                        <ExcelSheet
                          dataSet={_AllExcelFilesForSearch}
                          name="Incidents Lists"
                        />
                      </ExcelFile>
                    </div>
                  ) : (
                    <>
                      <div
                        className="inlist_expo"
                        style={{ marginBottom: 10 }}
                        onClick={() => {
                          setAllowDownload(true);
                          setCloneincidentList([]);

                          if (timeDiff / (1000 * 60 * 60 * 24) <= 31) {
                            downloadAlert(
                              "This file size may be big. Downloading may take some time."
                            );
                            fetchExportList();
                          } else {
                            return downloadAlert(
                              "Please select less than 31 days."
                            );
                          }
                        }}
                      >
                        <img src={WhiteExport} style={{ marginRight: 10 }} />
                        Export Incident List
                      </div>
                      {cloneincidentList.length > 0 &&
                        !props.exportLoading &&
                        timeDiff / (1000 * 60 * 60 * 24) <= 31 && (
                          <ExcelFile
                            filename="Incidents List"
                            element={<button>Download Data</button>}
                            hideElement={true}
                          >
                            <ExcelSheet
                              dataSet={_AllExcelFiles}
                              name="Incidents Lists"
                            />
                          </ExcelFile>
                        )}
                    </>
                  )}

                  {/* <div
              className="inlist_expo"
              onClick={() => {
                history.push(`/incidents_list/new_incident`);
              }}
            >
              <img src={PlusIcon} style={{ marginRight: 10 }} /> New Incident
            </div> */}
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Table Incident List */}
        {props.incidentLoading || loading ? (
          <Loader active inline="centered" />
        ) : isMobile && selectedDraft === "pending_save" ? (
          pendingItems && pendingItems.length > 0 ? (
            pendingItems
              .sort((a, b) =>
                sorting
                  ? new Date(b.latest_updated_at) -
                    new Date(a.latest_updated_at)
                  : new Date(a.latest_updated_at) -
                    new Date(b.latest_updated_at)
              )
              .map((_, index) => {
                return (
                  <div
                    style={{
                      border: "1px solid #000",
                      padding: 10,
                      fontSize: 13,
                    }}
                    key={index}
                    onClick={() => {
                      history.push(
                        `/incidents_list/new_incident/?pending=true&id=${_.id}`
                      );
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <div> - </div>
                      <div>Pending Save</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: 25,
                          height: 25,
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "bold",
                          fontSize: 11,
                          textTransform: "uppercase",
                          background:
                            _.category === "c1"
                              ? "#EFFFF2"
                              : _.category === "c2"
                              ? "#FFF7CC"
                              : _.category === "c3"
                              ? "#FFD6B0"
                              : _.category === "c4"
                              ? "#FFB4B4"
                              : null,
                          color:
                            _.category === "c1"
                              ? "#42BE5C"
                              : _.category === "c2"
                              ? "#ECBB00"
                              : _.category === "c3"
                              ? "#FF8A62"
                              : _.category === "c4"
                              ? "#E85757"
                              : null,
                          border:
                            _.category === "c1"
                              ? "1px #42BE5C solid "
                              : _.category === "c2"
                              ? "1px #ECBB00 solid "
                              : _.category === "c3"
                              ? "1px #FF8A62 solid "
                              : _.category === "c4"
                              ? "1px #E85757 solid "
                              : null,
                          marginRight: 8,
                        }}
                      >
                        {_.pending ? _.category : _.filtered_category}
                      </div>
                      Safety
                    </div>
                    <div style={{ fontSize: 20, fontWeight: "bold" }}>
                      {_.incident_title
                        ? _.incident_title
                        : _.incident_summary
                        ? _.incident_summary.incident_title
                        : ""}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: 8 }}> {_.park}, </div>

                        <div>{_.zone_type}</div>
                      </div>
                      <div>
                        {!_.pending && (
                          <div>
                            {}
                            <div onClick={() => confirmAlertFunction(_)}>
                              <img src={ExportImage} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      {momentTz
                        .tz(Date.parse(_.latest_updated_at), "Asia/Singapore")
                        .format("D MMM YYYY,")}
                      {"  "}
                      {momentTz
                        .tz(_.latest_updated_at, "Asia/Singapore")
                        .format("HH:mm")}
                    </div>
                  </div>
                );
              })
          ) : (
            <div
              style={{
                height: 60,
                display: "flex",
                alignItems: "center",
                padding: 10,
              }}
            >
              No data found
            </div>
          )
        ) : isMobile &&
          selectedDraft !== "pending_save" &&
          incidentAllList.length === 0 ? (
          <div
            style={{
              height: 60,
              display: "flex",
              alignItems: "center",
              padding: 10,
            }}
          >
            No data found
          </div>
        ) : isMobile && selectedDraft != "pending_save" ? (
          incidentAllList.map((_, index) => {
            return (
              <div
                style={{ border: "1px solid #000", padding: 10, fontSize: 13 }}
                key={index}
                onClick={() => {
                  if (_.pending) {
                    return history.push(
                      `/incidents_list/new_incident/?pending=true&id=${_.id}`
                    );
                  }
                  const filterP2A =
                    props.AuthDetail.perms &&
                    props.AuthDetail.perms.some((p) => p === "p2a");

                  const filterP3A =
                    props.AuthDetail.perms &&
                    props.AuthDetail.perms.some((p) => p === "p3a");
                  const filterP4 =
                    props.AuthDetail.perms &&
                    props.AuthDetail.perms.some((p) => p === "p4");
                  const filterP4A =
                    props.AuthDetail.perms &&
                    props.AuthDetail.perms.some((p) => p === "p4a");
                  const filterP5 =
                    props.AuthDetail.perms &&
                    props.AuthDetail.perms.some((p) => p === "p5");
                  if (filterP3A) {
                    if (
                      _.filtered_category === "c2" ||
                      _.filtered_category === "c3" ||
                      _.filtered_category === "c4"
                    ) {
                      return history.push(
                        `/incidents_list/detail?id=${_.id}&search=true`
                      );
                    }
                  }

                  if (filterP2A && _.filtered_category === "c1") {
                    return history.push(
                      `/incidents_list/detail?id=${_.id}&search=true`
                    );
                  }
                  if (filterP4 || filterP4A || filterP5) {
                    return history.push(
                      `/incidents_list/detail?id=${_.id}&search=true`
                    );
                  }
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 10,
                  }}
                >
                  <div>{_.incident_id}</div>
                  <div> {_.pending ? "Pending Save" : _.incident_status}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      width: 25,
                      height: 25,
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      fontSize: 11,
                      textTransform: "uppercase",
                      background: _.pending
                        ? _.category === "c1"
                          ? "#EFFFF2"
                          : _.category === "c2"
                          ? "#FFF7CC"
                          : _.category === "c3"
                          ? "#FFD6B0"
                          : _.category === "c4"
                          ? "#FFB4B4"
                          : null
                        : _.filtered_category === "c1"
                        ? "#EFFFF2"
                        : _.filtered_category === "c2"
                        ? "#FFF7CC"
                        : _.filtered_category === "c3"
                        ? "#FFD6B0"
                        : _.filtered_category === "c4"
                        ? "#FFB4B4"
                        : null,
                      color: _.pending
                        ? _.category === "c1"
                          ? "#42BE5C"
                          : _.category === "c2"
                          ? "#ECBB00"
                          : _.category === "c3"
                          ? "#FF8A62"
                          : _.category === "c4"
                          ? "#E85757"
                          : null
                        : _.filtered_category === "c1"
                        ? "#42BE5C"
                        : _.filtered_category === "c2"
                        ? "#ECBB00"
                        : _.filtered_category === "c3"
                        ? "#FF8A62"
                        : _.filtered_category === "c4"
                        ? "#E85757"
                        : null,
                      border: _.pending
                        ? _.category === "c1"
                          ? "1px #42BE5C solid "
                          : _.category === "c2"
                          ? "1px #ECBB00 solid "
                          : _.category === "c3"
                          ? "1px #FF8A62 solid "
                          : _.category === "c4"
                          ? "1px #E85757 solid "
                          : null
                        : _.filtered_category === "c1"
                        ? "1px #42BE5C solid "
                        : _.filtered_category === "c2"
                        ? "1px #ECBB00 solid "
                        : _.filtered_category === "c3"
                        ? "1px #FF8A62 solid "
                        : _.filtered_category === "c4"
                        ? "1px #E85757 solid "
                        : null,
                      marginRight: 8,
                    }}
                  >
                    {_.pending ? _.category : _.filtered_category}
                  </div>
                  Safety
                </div>
                <div style={{ fontSize: 20, fontWeight: "bold" }}>
                  {_.pending
                    ? _.incident_title
                      ? _.incident_title
                      : _.incident_summary
                      ? _.incident_summary.incident_title
                      : ""
                    : _.case_details.incident_description.incident_title}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 10,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: 8 }}>
                      {" "}
                      {_.pending ? _.park : _.filtered_park},
                    </div>

                    <div> {_.pending ? _.zone_type : _.filtered_zone_type}</div>
                  </div>
                  <div>
                    {!_.pending && (
                      <div>
                        {}
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            confirmAlertFunction(_);
                          }}
                        >
                          <img src={ExportImage} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {momentTz
                    .tz(Date.parse(_.latest_updated_at), "Asia/Singapore")
                    .format("D MMM YYYY,")}
                  {"  "}
                  {momentTz
                    .tz(_.latest_updated_at, "Asia/Singapore")
                    .format("HH:mm")}
                </div>
              </div>
            );
          })
        ) : (
          <div className="table_incident_list">
            <Table basic>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader no">
                      <div
                        style={{ marginRight: 8 }}
                        onClick={() => {
                          if (selectedSort === "date") {
                            setSelectedSort("number");
                            setCurrentPage(1);
                          }

                          if (incidentNoSorting === "desc") {
                            setIncidentNoSorting("asc");
                            setSorting("");
                          } else {
                            setIncidentNoSorting("desc");
                            setSorting("");
                          }
                        }}
                      >
                        {" "}
                        Incident No.{" "}
                      </div>{" "}
                      {incidentNoSorting === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-down-icon"
                          onClick={() => {
                            if (selectedSort === "date") {
                              setSelectedSort("number");
                              setCurrentPage(1);
                            }

                            if (incidentNoSorting === "desc") {
                              setIncidentNoSorting("asc");
                              setSorting("");
                            } else {
                              setIncidentNoSorting("desc");
                              setSorting("");
                            }
                          }}
                        />
                      ) : incidentNoSorting === "desc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          className="sort-up-icon"
                          onClick={() => {
                            if (selectedSort === "date") {
                              setSelectedSort("number");
                              setCurrentPage(1);
                            }
                            if (incidentNoSorting === "desc") {
                              setIncidentNoSorting("asc");
                              setSorting("");
                            } else {
                              setIncidentNoSorting("desc");
                              setSorting("");
                            }
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-down-icon"
                          onClick={() => {
                            if (selectedSort === "date") {
                              setSelectedSort("number");
                              setCurrentPage(1);
                            }
                            if (incidentNoSorting === "desc") {
                              setIncidentNoSorting("asc");
                              setSorting("");
                            } else {
                              setIncidentNoSorting("desc");
                              setSorting("");
                            }
                          }}
                        />
                      )}
                      {/* <div>
                        <img src={ArrowDownHeaderIcon} />
                      </div> */}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader category">
                      <div> Category</div>
                      {/* <div>
                        <img src={ArrowDownHeaderIcon} />
                      </div> */}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader approval">
                      <div> Incident Type </div>
                      {/* <div>
                        <img src={ArrowDownHeaderIcon} />
                      </div> */}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader park">
                      <div> Incident Title</div>
                      {/* <div>
                        <img src={ArrowDownHeaderIcon} />
                      </div> */}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader zone">
                      <div> Park </div>
                      {/* <div>
                        <img src={ArrowDownHeaderIcon} />
                      </div> */}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader type">
                      <div> Zone </div>
                      {/* <div>
                        <img src={ArrowDownHeaderIcon} />
                      </div> */}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader placement">
                      <div> Follow up</div>
                      {/* <div>
                        <img src={ArrowDownHeaderIcon} />
                      </div> */}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader follow_up">
                      <div> Status</div>
                      {/* <div>
                        <img src={ArrowDownHeaderIcon} />
                      </div> */}
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader follow_up">
                      <div> Creator</div>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader date">
                      <div> Created Date</div>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader follow_up">
                      <div> Department</div>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <div className="inlist_tableHeader date">
                      <div
                        onClick={() => {
                          if (selectedSort === "number") {
                            setSelectedSort("date");
                            setCurrentPage(1);
                          }
                          if (sorting === "desc") {
                            setSorting("asc");
                            setIncidentNoSorting("");
                          } else {
                            setSorting("desc");
                            setIncidentNoSorting("");
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        Last Modified <br />
                        Date
                      </div>
                      {sorting === "desc" ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-down-icon"
                          onClick={() => {
                            if (selectedSort === "number") {
                              setSelectedSort("date");
                              setCurrentPage(1);
                            }
                            if (sorting === "desc") {
                              setSorting("asc");
                            } else {
                              setSorting("desc");
                            }
                          }}
                        />
                      ) : sorting === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          className="sort-up-icon"
                          onClick={() => {
                            if (selectedSort === "number") {
                              setSelectedSort("date");
                              setCurrentPage(1);
                            }
                            if (sorting === "desc") {
                              setSorting("asc");
                            } else {
                              setSorting("desc");
                            }
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          className="sort-down-icon"
                          onClick={() => {
                            if (selectedSort === "number") {
                              setSelectedSort("date");
                              setCurrentPage(1);
                            }
                            if (sorting === "desc") {
                              setSorting("asc");
                            } else {
                              setSorting("desc");
                            }
                          }}
                        />
                      )}

                      {/* <div>
                        <img src={ArrowDownHeaderIcon} />
                      </div> */}
                    </div>
                  </Table.HeaderCell>
                  {selectedDraft !== "pending_save" && (
                    <Table.HeaderCell>
                      <div className="inlist_tableHeader export">
                        <div> Export</div>
                        {/* <div>
                          <img src={ArrowDownHeaderIcon} />
                        </div> */}
                      </div>
                    </Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {selectedDraft === "pending_save" ? (
                  pendingItems && pendingItems.length > 0 ? (
                    pendingItems
                      .sort((a, b) =>
                        sorting
                          ? new Date(b.latest_updated_at) -
                            new Date(a.latest_updated_at)
                          : new Date(a.latest_updated_at) -
                            new Date(b.latest_updated_at)
                      )
                      .map((_, index) => {
                        return (
                          <Table.Row
                            style={{ backgroundColor: _.color }}
                            className="inlist_tableRow"
                            onClick={() => {
                              api
                                .post(
                                  HAVE_VIEWED_INCIDENT,
                                  JSON.stringify({
                                    platform: "web",
                                    incident_id: _.id,
                                    user_id: props.AuthDetail.id,
                                  }),
                                  {
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                  }
                                )
                                .then((res) => {
                                  history.push(
                                    `/incidents_list/new_incident/?pending=true&id=${_.id}`
                                  );
                                })
                                .catch((err) => {
                                  console.log("err", err);
                                });
                            }}
                          >
                            <Table.Cell alignItems="left"></Table.Cell>
                            <Table.Cell>
                              <div
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: 12,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  textTransform: "uppercase",
                                  background:
                                    _.category === "c1"
                                      ? "#EFFFF2"
                                      : _.category === "c2"
                                      ? "#FFF7CC"
                                      : _.category === "c3"
                                      ? "#FFD6B0"
                                      : _.category === "c4"
                                      ? "#FFB4B4"
                                      : null,
                                  color:
                                    _.category === "c1"
                                      ? "#42BE5C"
                                      : _.category === "c2"
                                      ? "#ECBB00"
                                      : _.category === "c3"
                                      ? "#FF8A62"
                                      : _.category === "c4"
                                      ? "#E85757"
                                      : null,
                                  border:
                                    _.category === "c1"
                                      ? "1px #42BE5C solid "
                                      : _.category === "c2"
                                      ? "1px #ECBB00 solid "
                                      : _.category === "c3"
                                      ? "1px #FF8A62 solid "
                                      : _.category === "c4"
                                      ? "1px #E85757 solid "
                                      : null,
                                }}
                              >
                                {_.category}
                              </div>
                            </Table.Cell>
                            <Table.Cell>{_.incident_type}</Table.Cell>
                            <Table.Cell>
                              {" "}
                              {_.incident_title
                                ? _.incident_title
                                : _.incident_summary
                                ? _.incident_summary.incident_title
                                : ""}
                            </Table.Cell>
                            <Table.Cell>{_.park}</Table.Cell>
                            <Table.Cell>{_.zone_type}</Table.Cell>
                            <Table.Cell>{_.is_followup_required}</Table.Cell>
                            <Table.Cell>Pending Save</Table.Cell>
                            <Table.Cell>{_.creator_name}</Table.Cell>
                            <Table.Cell>
                              {momentTz
                                .tz(Date.parse(_.date), "Asia/Singapore")
                                .format("D MMM YYYY,")}
                              {"  "}
                              {moment(_.time, "HH:mm")
                                .tz("Asia/Singapore")
                                .format("HH:mm")}
                            </Table.Cell>
                            <Table.Cell>{_.department}</Table.Cell>
                            <Table.Cell>
                              {momentTz
                                .tz(
                                  Date.parse(_.latest_updated_at),
                                  "Asia/Singapore"
                                )
                                .format("D MMM YYYY,")}
                              {"  "}
                              {momentTz
                                .tz(_.latest_updated_at, "Asia/Singapore")
                                .format("HH:mm")}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                  ) : (
                    <div
                      style={{
                        height: 60,
                        display: "flex",
                        alignItems: "center",
                        padding: 10,
                      }}
                    >
                      No data found
                    </div>
                  )
                ) : incidentAllList.length === 0 ? (
                  <div
                    style={{
                      height: 60,
                      display: "flex",
                      alignItems: "center",
                      padding: 10,
                    }}
                  >
                    No data found
                  </div>
                ) : (
                  incidentAllList.map((_, index) => {
                    return (
                      <Table.Row
                        key={index}
                        style={{ backgroundColor: _.color }}
                        className="inlist_tableRow"
                        onClick={() => {
                          api
                            .post(
                              HAVE_VIEWED_INCIDENT,
                              JSON.stringify({
                                platform: "web",
                                incident_id: _.id,
                                user_id: props.AuthDetail.id,
                              }),
                              {
                                headers: {
                                  "Content-Type": "application/json",
                                },
                              }
                            )
                            .then((res) => {})
                            .catch((err) => {
                              console.log("err", err);
                            });

                          if (_.pending) {
                            return history.push(
                              `/incidents_list/new_incident/?pending=true&id=${_.id}`
                            );
                          }
                          const filterP2A =
                            props.AuthDetail.perms &&
                            props.AuthDetail.perms.some((p) => p === "p2a");

                          const filterP3A =
                            props.AuthDetail.perms &&
                            props.AuthDetail.perms.some((p) => p === "p3a");
                          const filterP4 =
                            props.AuthDetail.perms &&
                            props.AuthDetail.perms.some((p) => p === "p4");
                          const filterP4A =
                            props.AuthDetail.perms &&
                            props.AuthDetail.perms.some((p) => p === "p4a");
                          const filterP5 =
                            props.AuthDetail.perms &&
                            props.AuthDetail.perms.some((p) => p === "p5");
                          if (filterP3A) {
                            if (
                              _.filtered_category === "c2" ||
                              _.filtered_category === "c3" ||
                              _.filtered_category === "c4"
                            ) {
                              return history.push(
                                `/incidents_list/detail?id=${_.id}&search=true`
                              );
                            }
                          }

                          if (filterP2A && _.filtered_category === "c1") {
                            return history.push(
                              `/incidents_list/detail?id=${_.id}&search=true`
                            );
                          }
                          if (filterP4 || filterP4A || filterP5) {
                            return history.push(
                              `/incidents_list/detail?id=${_.id}&search=true`
                            );
                          }
                        }}
                      >
                        <Table.Cell alignItems="left">
                          {_.incident_id}
                        </Table.Cell>
                        <Table.Cell>
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 12,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "bold",
                              fontSize: 14,
                              textTransform: "uppercase",
                              background: _.pending
                                ? _.category === "c1"
                                  ? "#EFFFF2"
                                  : _.category === "c2"
                                  ? "#FFF7CC"
                                  : _.category === "c3"
                                  ? "#FFD6B0"
                                  : _.category === "c4"
                                  ? "#FFB4B4"
                                  : null
                                : _.filtered_category === "c1"
                                ? "#EFFFF2"
                                : _.filtered_category === "c2"
                                ? "#FFF7CC"
                                : _.filtered_category === "c3"
                                ? "#FFD6B0"
                                : _.filtered_category === "c4"
                                ? "#FFB4B4"
                                : null,
                              color: _.pending
                                ? _.category === "c1"
                                  ? "#42BE5C"
                                  : _.category === "c2"
                                  ? "#ECBB00"
                                  : _.category === "c3"
                                  ? "#FF8A62"
                                  : _.category === "c4"
                                  ? "#E85757"
                                  : null
                                : _.filtered_category === "c1"
                                ? "#42BE5C"
                                : _.filtered_category === "c2"
                                ? "#ECBB00"
                                : _.filtered_category === "c3"
                                ? "#FF8A62"
                                : _.filtered_category === "c4"
                                ? "#E85757"
                                : null,
                              border: _.pending
                                ? _.category === "c1"
                                  ? "1px #42BE5C solid "
                                  : _.category === "c2"
                                  ? "1px #ECBB00 solid "
                                  : _.category === "c3"
                                  ? "1px #FF8A62 solid "
                                  : _.category === "c4"
                                  ? "1px #E85757 solid "
                                  : null
                                : _.filtered_category === "c1"
                                ? "1px #42BE5C solid "
                                : _.filtered_category === "c2"
                                ? "1px #ECBB00 solid "
                                : _.filtered_category === "c3"
                                ? "1px #FF8A62 solid "
                                : _.filtered_category === "c4"
                                ? "1px #E85757 solid "
                                : null,
                            }}
                          >
                            {_.pending ? _.category : _.filtered_category}
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {_.pending
                            ? _.incident_type
                            : _.filtered_incident_type}
                        </Table.Cell>
                        <Table.Cell>
                          {_.pending
                            ? _.incident_title
                              ? _.incident_title
                              : _.incident_summary
                              ? _.incident_summary.incident_title
                              : ""
                            : _.case_details.incident_description
                                .incident_title}
                        </Table.Cell>
                        <Table.Cell>
                          {_.pending ? _.park : _.filtered_park}
                        </Table.Cell>
                        <Table.Cell>
                          {_.pending ? _.zone_type : _.filtered_zone_type}
                        </Table.Cell>
                        <Table.Cell>
                          {_.pending
                            ? _.is_followup_required
                            : _.case_details.incident_description
                                .is_followup_required}
                        </Table.Cell>
                        <Table.Cell>
                          {_.pending ? "Pending Save" : _.incident_status}
                        </Table.Cell>
                        <Table.Cell>{_.creator_name}</Table.Cell>
                        {_.pending ? (
                          <Table.Cell>
                            {_.time && _.date && (
                              <>
                                {momentTz
                                  .tz(Date.parse(_.date), "Asia/Singapore")
                                  .format("D MMM YYYY,")}
                                {"  "}
                                {moment(_.time, "HH:mm")
                                  .tz("Asia/Singapore")
                                  .format("HH:mm")}
                              </>
                            )}
                          </Table.Cell>
                        ) : (
                          <Table.Cell>
                            {_.created_at && (
                              <>
                                {momentTz
                                  .tz(
                                    Date.parse(_.created_at),
                                    "Asia/Singapore"
                                  )
                                  .format("D MMM YYYY,")}
                                {"  "}
                                {momentTz
                                  .tz(_.created_at, "Asia/Singapore")
                                  .format("HH:mm")}
                              </>
                            )}
                          </Table.Cell>
                        )}
                        <Table.Cell>
                          {_.department ? _.department : ""}
                        </Table.Cell>
                        <Table.Cell>
                          {_.latest_updated_at && (
                            <>
                              {momentTz
                                .tz(
                                  Date.parse(_.latest_updated_at),
                                  "Asia/Singapore"
                                )
                                .format("D MMM YYYY,")}
                              {"  "}
                              {momentTz
                                .tz(_.latest_updated_at, "Asia/Singapore")
                                .format("HH:mm")}
                            </>
                          )}
                        </Table.Cell>
                        <Table.Cell onClick={(e) => e.stopPropagation()}>
                          {/* ---- generate as a pdf file */}

                          <>
                            {!_.pending && (
                              <div>
                                {}
                                <div
                                  style={{ marginRight: 15 }}
                                  onClick={() => confirmAlertFunction(_)}
                                >
                                  <img src={ExportImage} />
                                </div>
                              </div>
                            )}
                          </>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                )}
              </Table.Body>
            </Table>
          </div>
        )}
      </div>
    );
  };

  const onChangeFromDate = (date) => {
    setFromDate(date);
  };

  const searchSubmit = () => {
    if (searchInput.length > 0) {
      setShowSearchData(true);
    } else {
      setShowSearchData(false);
    }
    if (selectedDraft === "pending_save") {
      const items = JSON.parse(localStorage.getItem("pending"));

      let data = [];
      FilterPendingItems(searchInput);
    } else {
      const items = JSON.parse(localStorage.getItem("pending"));

      if (items) {
        let data = [];
        items.filter((val) => {
          if (searchInput === "") {
          } else {
            if (
              (val.incident_summary &&
                val.incident_summary.incident_title
                  .toLowerCase()
                  .includes(searchInput.toLowerCase())) ||
              (val.incident_title &&
                val.incident_title
                  .toLowerCase()
                  .includes(searchInput.toLowerCase())) ||
              (val.creator_name &&
                val.creator_name
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()))
            ) {
              setShowSearchData(true);
              return data.push(val);
            }
          }
        });

        setSearchData(data);
      }

      props.fetchAllIncidents(
        fromDate.apiFromDate,
        toDate.apiToDate,
        category,
        park,
        zone,
        type,
        placement,
        currentPage,
        selectedDraft,
        selectedCase,
        props.AuthDetail.id,
        10,
        sorting,
        incidentNoSorting,
        searchInput
      );
    }
  };
  const scrollToTopFunction = () => {
    if (window.screen.width > 800) {
      scrollRef.current.scrollIntoView();
    }
  };
  useEffect(() => {
    scrollToTopFunction();
  }, [
    fromDate.apiFromDate,
    toDate.apiToDate,
    category,
    park,
    zone,
    type,
    placement,
    currentPage,
    selectedDraft,
    selectedCase,
    filterP2,
    filterP3,
  ]);

  return (
    <>
      <div
        className={`incident_list_contianer ${
          !isMobile && "desktop_incident_list_contianer"
        } `}
        ref={scrollRef}
      >
        {isMobile && showFilter && (
          <div
            style={{
              position: "fixed",
              background: "rgba(0,0,0,.5)",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></div>
        )}
        {isMobile && showFilter && (
          <div className={`sidebar ${isMobile && "sidebar_mobile"}`}>
            <SideBar />
          </div>
        )}
        {!isMobile && (
          <div className={`sidebar`}>
            <SideBar />
          </div>
        )}

        <div
          className={`incident_list ${
            isMobile ? "mobile_incident_list" : "desktop_incident_list"
          }`}
        >
          {isMobile ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flex: 0.2, padding: 10 }}>
                <div
                  style={{
                    background: "#008237",
                    height: 35,
                    borderRadius: 10,
                    fontSize: 35,
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    history.push(`/incidents_list/new_incident`);
                  }}
                >
                  +
                </div>
              </div>
              <div style={{ flex: 0.9, padding: 10 }}>
                {" "}
                <form
                  className="search_bar_form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchInput.length > 0) {
                      searchSubmit();
                    }
                    setAllowDownload(true);
                  }}
                >
                  <input
                    type="text"
                    onChange={(e) => {
                      setIsRemoveSearchInput(false);
                      if (e.target.value.length === 0) {
                        setShowSearchData(false);
                        if (selectedDraft !== "pending_save") {
                          props.fetchAllIncidents(
                            fromDate.apiFromDate,
                            toDate.apiToDate,
                            category,
                            park,
                            zone,
                            type,
                            placement,
                            currentPage,
                            selectedDraft,
                            selectedCase,
                            props.AuthDetail.id,
                            10,
                            sorting,
                            incidentNoSorting,
                            ""
                          );
                        } else {
                          setIsRemoveSearchInput(true);
                        }
                      }
                      setAllowDownload(false);
                      setSearchInput(e.target.value);
                    }}
                    placeholder="Search"
                    value={searchInput}
                  />
                  {searchInput.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "#008237",
                        fontSize: "small",
                        cursor: "pointer",
                        position: "absolute",
                        right: 30,
                        top: 8,
                        zIndex: 10,
                        fontSize: 20,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowSearchData(false);
                        setSearchInput("");
                      }}
                    >
                      X
                    </div>
                  )}
                  <img
                    src={SearchIcon}
                    className="search_icon"
                    onClick={searchSubmit}
                  />
                </form>
              </div>
              <div style={{ flex: 0.2, padding: 10 }}>
                {showSearchData ? (
                  <div className="inlist_expo" style={{ marginBottom: 10 }}>
                    <ExcelFile
                      filename="Incidents List"
                      element={
                        <div
                          style={{
                            background: "#008237",
                            height: 35,
                            borderRadius: 10,
                            fontSize: 35,
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img src={WhiteExport} />
                        </div>
                      }
                    >
                      <ExcelSheet
                        dataSet={_AllExcelFilesForSearch}
                        name="Incidents Lists"
                      />
                    </ExcelFile>
                  </div>
                ) : (
                  <>
                    <div
                      className="inlist_expo"
                      style={{
                        background: "#008237",
                        height: 35,
                        borderRadius: 10,
                        fontSize: 35,
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setAllowDownload(true);
                        setCloneincidentList([]);

                        if (timeDiff / (1000 * 60 * 60 * 24) <= 31) {
                          downloadAlert(
                            "This file size may be big. Downloading may take some time."
                          );
                          fetchExportList();
                        } else {
                          return downloadAlert(
                            "Please select less than 31 days."
                          );
                        }
                      }}
                    >
                      <img src={WhiteExport} />
                    </div>
                    {cloneincidentList.length > 0 &&
                      !props.exportLoading &&
                      timeDiff / (1000 * 60 * 60 * 24) <= 31 && (
                        <ExcelFile
                          filename="Incidents List"
                          element={<button>Download Data</button>}
                          hideElement={true}
                        >
                          <ExcelSheet
                            dataSet={_AllExcelFiles}
                            name="Incidents Lists"
                          />
                        </ExcelFile>
                      )}
                  </>
                )}
                {/* <div
                style={{
                  background: "#008237",
                  height: 35,
                  borderRadius: 10,
                  fontSize: 35,
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={WhiteExport} />
              </div> */}
              </div>
            </div>
          ) : (
            <div className="search_bar">
              <form
                className="search_bar_form"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (searchInput.length > 0) {
                    searchSubmit();
                  }
                  setAllowDownload(true);
                }}
              >
                <input
                  type="text"
                  onChange={(e) => {
                    setIsRemoveSearchInput(false);
                    if (e.target.value.length === 0) {
                      setShowSearchData(false);
                      if (selectedDraft !== "pending_save") {
                        props.fetchAllIncidents(
                          fromDate.apiFromDate,
                          toDate.apiToDate,
                          category,
                          park,
                          zone,
                          type,
                          placement,
                          currentPage,
                          selectedDraft,
                          selectedCase,
                          props.AuthDetail.id,
                          10,
                          sorting,
                          incidentNoSorting,
                          ""
                        );
                      } else {
                        setIsRemoveSearchInput(true);
                      }
                    }
                    setAllowDownload(false);
                    setSearchInput(e.target.value);
                  }}
                  placeholder="Search"
                  value={searchInput}
                />
                {searchInput.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      color: "#008237",
                      fontSize: "small",
                      cursor: "pointer",
                      position: "absolute",
                      right: 30,
                      top: 8,
                      zIndex: 10,
                      fontSize: 20,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowSearchData(false);
                      setSearchInput("");
                    }}
                  >
                    X
                  </div>
                )}
                <img
                  src={SearchIcon}
                  className="search_icon"
                  onClick={searchSubmit}
                />
              </form>
            </div>
          )}
          <IncidentTableList />
          {selectedDraft !== "pending_save" &&
            props.incidentList.length > 0 &&
            !showSearchData &&
            !props.incidentLoading && (
              <Pagination
                activePage={currentPage}
                firstItem={null}
                lastItem={null}
                siblingRange={props.total_pages > 5 ? 3 : null}
                floated="right"
                totalPages={props.total_pages}
                onPageChange={handlePaginationChange}
                className={`${
                  currentPage === 1
                    ? props.total_pages === currentPage
                      ? "paginat__fist_disable paginat__last_disable"
                      : "paginat__fist_disable"
                    : props.total_pages === currentPage
                    ? "paginat__last_disable"
                    : null
                }`}
                style={{
                  opacity: props.incidentLoading || loading ? 0 : 1,
                  marginTop: 30,
                  marginBottom: 30,
                }}
              />
            )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  incidentList: state.incident.incidentLists,
  incidentListForSearch: state.incident.incidentListForSearch,
  incidentListForExport: state.incident.incidentListForExport,
  subDeparts: state.incident.subDeparts,
  incidentLoading: state.incident.loading,
  nexturl: state.incident.nexturl,
  total_pages: state.incident.total_pages,
  AuthDetail: state.loggedInUserDetail.userDetail,
  exportLoading: state.incident.exportLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllSubDepartment: () => dispatch(fetchAllSubDepartment()),
  changeLoaderForExport: () => dispatch(changeLoaderForExport()),
  fetchAllIncidentsForExport: (
    fromDate,
    toDate,
    category,
    park,
    zone,
    type,
    placement,
    currentPage,
    selectedDraft,
    selectedCase,
    userid,
    size,
    sorting,
    incidentNoSorting,
    search
  ) =>
    dispatch(
      fetchAllIncidentsForExport(
        fromDate,
        toDate,
        category,
        park,
        zone,
        type,
        placement,
        currentPage,
        selectedDraft,
        selectedCase,
        userid,
        size,
        sorting,
        incidentNoSorting,
        search
      )
    ),
  fetchAllIncidentsForSearch: (data) =>
    dispatch(fetchAllIncidentsForSearch(data)),
  fetchAllIncidents: (
    fromDate,
    toDate,
    category,
    park,
    zone,
    type,
    placement,
    currentPage,
    selectedDraft,
    selectedCase,
    uid,
    size,
    sorting,
    incidentNoSorting,
    search
  ) =>
    dispatch(
      fetchAllIncidents(
        fromDate,
        toDate,
        category,
        park,
        zone,
        type,
        placement,
        currentPage,
        selectedDraft,
        selectedCase,
        uid,
        size,
        sorting,
        incidentNoSorting,
        search
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(IncidentList);
