import React, { useState, useEffect, useRef, useReducer } from "react";
import { Button, Dimmer, Loader, TextArea } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { StepperNav } from "vertical-stepper-nav";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import { fetchUserDetails } from "../../../store/actions/userAccountDetailAction";
import { userLogout } from "../../../store/actions/loggedInUserDetailAction";
import DatePicker from "react-datepicker";
import "reactjs-popup/dist/index.css";
import moment from "moment";
import "./userDetail.css";
import { auth, db } from "../../../config/firebase";
import { useMediaQuery } from "react-responsive";

//routes
import { USER_AUDIT_ROUTE } from "../../../routes";

//images
import LeftArrowIcon from "../../../assets/images/incident/leftarrow.svg";
import RightArrowIcon from "../../../assets/images/incident/rightarrow.svg";
import DownArrowIcon from "../../../assets/images/incident/arrowdowndrop.svg";
import ExitIcon from "../../../assets/images/incident/exit.svg";
import CalendarIcon from "../../../assets/images/incident/ic_calendar.svg";

//pages
import UserProfile from "./forms/userProfile";
import UserPermission from "./forms/userPermission";
import UserAccount from "./forms/userAccount";
import UserReset from "./forms/userReset";

import { confirmAlert } from "react-confirm-alert";
import config from "../../../config/config";
import api from "../../../config/api";

function UserDetail(props) {
  let history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const userId = query.get("id");
  const profileBool = query.get("profile");
  const { SAVE_USER, CHANGE_PASSWORD, SAVE_USER_AUDIT, UNLOCK_USER } =
    config.api_endpoint;
  var shouldSave = false;
  var shouldReset = false;
  var suspend_reason = "";
  var reactive_reason = "";

  const scrollRef = useRef(null);
  const [detailSelected, setDetailSelected] = useState("Profile");
  const [userProfileLoaded, setUserProfileLoaded] = useState(false);
  const [showReactiveBtn, setShowReactiveBtn] = useState(false);
  const [showSuspendBtn, setShowSuspendBtn] = useState(false);
  const [showArchiveBtn, setShowArchiveBtn] = useState(true);
  const [profileValues, setProfileValues] = useState({});
  const [originalProfileValues, setOriginalProfileValues] = useState({});
  const [suspendModal, setSuspendModal] = useState(false);
  const [suspendModalSuccess, setSuspendModalSuccess] = useState(false);
  const [placeList, setPlaceList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const [resetValues, setResetValues] = useState({
    password: "",
    repassword: "",
  });
  const [toggleDatePicker, setToggleDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  // const [shouldSave, setShouldSave] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [dateError, setDateError] = useState(false);
  const fetchUserDetailsFunction = () => {
    props.fetchUserDetails(userId);
  };
  const [suspendReasonError, setSuspendReasonError] = useState(false);
  const [dateTimeError, setDateTimeError] = useState(false);
  const [reactiveModal, setReactiveModal] = useState(false);
  const [reactiveError, setReactiveError] = useState(false);
  const [cacheData, setCacheData] = useState([]);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const onChangeProfileView = (key, value) => {
    if (key === "email") {
      setProfileValues({
        ...profileValues,
        [key]: value.replace(/\s/g, ""),
      });
    } else {
      setProfileValues({
        ...profileValues,
        [key]: value,
      });
    }
  };
  // useEffect(() => {

  //   setUsername(username.replace(/\s/g, ""));
  // }, [username]);
  const onChangePasswordView = (key, value) => {
    setResetValues({
      ...resetValues,
      [key]: value,
    });
  };

  // for fetching user lists
  useEffect(async () => {
    fetchUserDetailsFunction();
  }, [userId]);

  useEffect(() => {
    if (props.userObj) {
      if (props.userObj.id == userId) {
        setProfileValues(props.userObj);
        setOriginalProfileValues(props.userObj);
        setUserProfileLoaded(true);

        if (props.userObj.is_active == -1) {
          setShowReactiveBtn(true);
          setShowSuspendBtn(false);
        } else {
          setShowReactiveBtn(false);
          setShowSuspendBtn(true);
        }

        if (props.userObj.is_active == 0 && props.userObj.is_new == 0) {
          setShowArchiveBtn(false);
        }
      }
    }
  }, [props.userObj]);

  // user profile error list
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [staffidError, setStaffidError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [parkError, setParkError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [subdepartmentError, setSubdepartmentError] = useState(false);
  const [userProfileError, setUserProfileError] = useState(false);
  const [userResetError, setUserResetError] = useState(false);

  const validateReset = () => {
    const { password, repassword } = resetValues;

    setUserResetError("");
    shouldReset = true;
    // onClose();
    if (
      password == "" ||
      repassword == "" ||
      password.length < 6 ||
      repassword.length < 6
    ) {
      setUserResetError("Please enter at least 6 characters.");
      shouldReset = false;
      return;
      // onClose();
    }
    if (password != repassword) {
      setUserResetError("New Password and confirm password didn't match.");
      shouldReset = false;
      return;
    } else {
      shouldReset = true;
      return confirmAlert({
        customUI: ({ onClose }) => customModal(onClose),
      });
    }
  };

  const findChangesBeforeSave = () => {
    var changes = [];
    var userFriendlyNames = {
      name: "Name",
      email: "Email",
      mobile_no: "Mobile Number",
      staff_id: "Staff ID",
      role: "Role",
      park: "Park",

      department: "Department",
      sub_department: "Sub Department",
      account_expiry: "Account Expiry",
    };

    Object.keys(originalProfileValues).map(function (key, keyIndex) {
      // use keyName to get current key's name
      // and a[keyName] to get its value

      if (originalProfileValues[key] != profileValues[key]) {
        changes.push(
          userFriendlyNames[key] +
            " changed from " +
            originalProfileValues[key] +
            " to " +
            profileValues[key]
        );
      }
    });

    return changes;
  };
  let filterP9 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p9");
  const validateUserProfile = () => {
    // const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const {
      name,
      email,
      mobile_no,
      staff_id,
      role,
      park,
      department,
      sub_department,
    } = profileValues;

    setUserProfileError(false);
    shouldSave = true; //setShouldSave(true);

    if (name == "") {
      setNameError("Required Field");
      shouldSave = false; // setShouldSave(false);
      setUserProfileError(true);
    } else {
      if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(name)) {
        shouldSave = false;
        setNameError("Must be valid.");
        setUserProfileError(true);
      } else {
        setNameError(false);
      }
    }

    if (email == "") {
      setEmailError("Required Field");
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      setEmailError(false);
    }

    if (!regex.test(email)) {
      setEmailError("Email is invalid");
      shouldSave = false;
      setUserProfileError(true);
    } else {
      setEmailError("");
    }

    if (mobile_no == "" || mobile_no == "+65") {
      setMobileError("Required Field");
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      let cloneMobileNo = mobile_no.replace("+", "");
      // if (!/^[0-9]+$/.test(cloneMobileNo)) {
      if (
        !/^[+]?\d+$/.test(cloneMobileNo) ||
        mobile_no == "+65" ||
        mobile_no.length > 13
      ) {
        setMobileError("Must be vaild");
        shouldSave = false; //setShouldSave(false);
        setUserProfileError(true);
      } else {
        setMobileError(false);
      }
    }

    if (staff_id == "") {
      setStaffidError("Please enter the staff id");
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      if (!/^[0-9]+$/.test(staff_id)) {
        setStaffidError("Must vaild");
        shouldSave = false; //setShouldSave(false);
        setUserProfileError(true);
      } else {
        setStaffidError(null);
      }
    }

    if (staff_id.length != 8 || parseInt(staff_id) == NaN) {
      setStaffidError("Required 8 numbers");
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      if (!/^[0-9]+$/.test(staff_id)) {
        setStaffidError("Must vaild");
        shouldSave = false; //setShouldSave(false);
        setUserProfileError(true);
      } else {
        setStaffidError(null);
      }
    }

    if (role == "") {
      setRoleError(true);
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      setRoleError(false);
    }

    if (park == "") {
      setParkError(true);
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      setParkError(false);
    }

    if (department == "") {
      setDepartmentError(true);
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      setDepartmentError(false);
    }

    if (sub_department == "") {
      setSubdepartmentError(true);
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      setSubdepartmentError(false);
    }
  };

  const sidebarcssColors = (title) => {
    if (title == "Profile" && userProfileError) {
      return "error";
    } else {
      return "nth";
    }
  };

  const reactiveButtonAction = async () => {
    setReactiveError(false);
    setShowLoader(true);
    api
      .post(
        SAVE_USER,
        JSON.stringify({
          id: userId,
          // is_active: 1,
          archive_date: "",
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        console.log("User Suspended: ", res);

        api
          .post(
            SAVE_USER_AUDIT,
            JSON.stringify({
              user_id: userId,
              email: props.userObj.email,
              changed_by: auth.currentUser.uid,
              description: ["Account reactivated. Reason: " + reactive_reason],
              platform: "web",
            }),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((rese) => {
            console.log("User Reactive Audit Saved: ", rese);
            setShowLoader(false);
            return confirmAlert({
              customUI: ({ onClose }) => reactivatedSuccessModal(onClose),
            });
            // history.push("/user_management");
          })
          .catch((erre) => {
            console.log("User Reactive Audit Error Saved: ", erre);
            setShowLoader(false);
          });
      })
      .catch((err) => {
        console.log("User Reactive Error Saved: ", err);
        setShowLoader(false);
      });
  };

  const sucessfullySuspendedModal = () => {
    return (
      <div
        className="suspendAlertBox"
        onClick={() => {
          setSuspendModalSuccess(false);
          history.push("/user_management");
        }}
      >
        <div
          style={{
            width: 400,
            minHeight: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
            zIndex: 2,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={() => {
              setSuspendModalSuccess(false);
              history.push("/user_management");
            }}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Suspended successful
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            This user has been suspended successfully.
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div></div>
            <div onClick={() => null}>
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={() => {
                  setSuspendModalSuccess(false);
                  history.push("/user_management");
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const suspendButtonAction = async () => {
    if (suspend_reason == "") {
      setSuspendReasonError(true);
    } else {
      setSuspendReasonError(false);
    }
    if (!selectedDate) {
      return setDateTimeError(true);
    }
    setShowLoader(true);
    // if (new Date().getDay() == new Date(selectedDate).getDay()) {

    api
      .post(
        SAVE_USER,
        JSON.stringify({
          id: userId,
          // is_active: -1,
          archive_date: selectedDate
            ? moment(selectedDate).format("YYYY-MM-DD")
            : "",
          platform: "web",
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        api
          .post(
            SAVE_USER_AUDIT,
            JSON.stringify({
              user_id: userId,
              email: props.userObj.email,
              changed_by: auth.currentUser.uid,
              description: ["Account suspended. Reason: " + suspend_reason],
              platform: "web",
            }),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((rese) => {
            console.log("User Suspend Audit Saved: ", rese);

            setSuspendModal(false);
            setShowLoader(false);
            setSuspendModalSuccess(true);
          })
          .catch((erre) => {
            console.log("User Suspend Audit Error Saved: ", erre);
            setShowLoader(false);
          });
      })
      .catch((err) => {
        console.log("User Suspend Error Saved: ", err);
        setShowLoader(false);
      });
    // } else {
    //   const susUserList = JSON.parse(localStorage.getItem("suspendUserList"));
    //   const data = {
    //     id: userId,
    //     is_active: -1,
    //     selectedDate,
    //     audit: {
    //       user_id: userId,
    //       email: props.userObj.email,
    //       changed_by: auth.currentUser.uid,
    //       description: ["Account suspended. Reason: " + suspend_reason],
    //       platform: "web",
    //     },
    //   };
    //   if (susUserList == null) {
    //     localStorage.setItem("suspendUserList", JSON.stringify([data]));
    //     console.log("This is is null", JSON.stringify([data]));
    //   } else {
    //     if (susUserList.length == 0) {
    //       localStorage.setItem(
    //         "suspendUserList",
    //         JSON.stringify(susUserList.push(data))
    //       );
    //       history.push("/user_management");
    //     } else {
    //       const searchIndex = susUserList.findIndex((s) => s.id == userId);

    //       if (searchIndex != -1) {
    //         susUserList[searchIndex].selectedDate = selectedDate;
    //         susUserList[searchIndex].audit.description[0] = [
    //           "Account suspended. Reason: " + suspend_reason,
    //         ];
    //         localStorage.setItem(
    //           "suspendUserList",
    //           JSON.stringify(susUserList)
    //         );
    //         history.push("/user_management");
    //       } else {
    //         susUserList.push(data);

    //         localStorage.setItem(
    //           "suspendUserList",
    //           JSON.stringify(susUserList)
    //         );
    //         history.push("/user_management");
    //       }
    //     }
    //   }
    // }

    setShowLoader(false);
  };

  const archiveButtonAction = async (onClose) => {
    let cachefilter = cacheData.filter((c) => c.id != userId);

    setShowLoader(true);
    api
      .post(
        SAVE_USER_AUDIT,
        JSON.stringify({
          user_id: userId,
          email: props.userObj.email,
          changed_by: props.AuthDetail.id,
          description: [
            `${props.AuthDetail.name} archived the ${props.userObj.name}`,
          ],
          platform: "web",
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((rese) => {
        api
          .remove(
            SAVE_USER,
            JSON.stringify({
              platform: "web",
              id: userId,
              name: props.userObj.name,
              email: props.userObj.email,
              deleted_by: props.AuthDetail.id,
            }),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((res) => {
            scrollRef.current.scrollIntoView();
            onClose();
            console.log("User Archive Done: ", res);
            setShowLoader(false);

            history.push("/user_management");
          })
          .catch((err) => {
            scrollRef.current.scrollIntoView();
            onClose();
            console.log("User Error Archive: ", err);
            setShowLoader(false);
          });
      })
      .catch((erre) => {
        console.log("User Archive ", erre);
        setShowLoader(false);
      });

    // confirmAlert({
    //   title: "Confirm to archive",
    //   message: "Do you want to archive the user?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => {
    //         console.log("Archive Yes clicked.");

    //       },
    //     },
    //     {
    //       label: "No",
    //       onClick: () => {},
    //     },
    //   ],
    // });
  };

  function customModalSuccess(onClose) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Password Reset Successful
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            Password has been reset successfully. The user will receive an email
            with the password that you have changed.
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div></div>
            <div onClick={() => null}>
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={() => {
                  if (profileBool) {
                    auth
                      .signOut()
                      .then(() => {
                        userLogout();
                      })
                      .catch((err) => {
                        console.log("err", err);
                      });
                    // ggggggggggggg
                  }
                  onClose();
                  scrollRef.current.scrollIntoView();
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function customModalFail(onClose, message) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Password Reset Failed.
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>{message}</div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div></div>
            <div onClick={() => null}>
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={() => {
                  if (profileBool) {
                    auth
                      .signOut()
                      .then(() => {
                        userLogout();
                      })
                      .catch((err) => {
                        console.log("err", err);
                      });
                    // ggggggggggggg
                  }
                  onClose();
                  scrollRef.current.scrollIntoView();
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function customModalDeleteUser(onClose) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Delete User
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            Are you sure you want to delete this user?
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button className="witness_cancel_button" onClick={onClose}>
                No
              </Button>
            </div>
            <div onClick={() => null}>
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={() => {
                  archiveButtonAction(onClose);
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function customModalText(onClose, text) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Error Message
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>{text}</div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button className="witness_cancel_button" onClick={onClose}>
                OK
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function customModalSuccessText(onClose) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Success
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            Successfully unlock this user.
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button
                className="witness_cancel_button"
                onClick={() => {
                  onClose();
                  history.push("/user_management");
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const unlockUserAction = async (onClose) => {
    api
      .post(
        UNLOCK_USER,
        JSON.stringify({ user_id: props.userObj.id, platform: "web" }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        console.log("res", res);
        if (res.message != "Success") {
          onClose();
          return confirmAlert({
            customUI: ({ onClose }) => customModalText(onClose, res.message),
          });
        } else {
          onClose();
          return confirmAlert({
            customUI: ({ onClose }) => customModalSuccessText(onClose),
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  function customModalUnlockUser(onClose) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Unlock User
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            Are you sure you want to unlock this user?
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <Button className="witness_cancel_button" onClick={onClose}>
                No
              </Button>
            </div>
            <div onClick={() => null}>
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={() => {
                  unlockUserAction(onClose);
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function customModal(onClose) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 250,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
              marginTop: 10,
            }}
          >
            Reset Password
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            Are you sure you want to reset the password of this account? The
            account holder will receive an email upon reset password is
            successful.{" "}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 50,
            }}
          >
            <div>
              <Button className="witness_cancel_button" onClick={onClose}>
                No
              </Button>
            </div>
            <div>
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={() => {
                  onClose();
                  console.log("Dialog Yes clicked.");
                  // validateReset(onClose);

                  if (shouldReset == true) {
                    setShowLoader(true);

                    api
                      .post(
                        CHANGE_PASSWORD,
                        JSON.stringify({
                          platform: "web",
                          email: profileValues.email,
                          password: resetValues.password,
                        })
                      )
                      .then((res) => {
                        if (res.message != "Success") {
                          setShowLoader(false);
                          return confirmAlert({
                            customUI: ({ onClose }) =>
                              customModalFail(onClose, res.message),
                          });
                        }
                        console.log("resgg", res);
                        if (res.message == "Success") {
                          setResetValues({
                            password: "",
                            repassword: "",
                          });

                          api
                            .post(
                              SAVE_USER_AUDIT,
                              JSON.stringify({
                                user_id: userId,
                                email: profileValues.email,
                                changed_by: auth.currentUser.uid,
                                description: [
                                  `${props.AuthDetail.name}  password changed`,
                                ],
                                platform: "web",
                              }),
                              {
                                headers: { "Content-Type": "application/json" },
                              }
                            )
                            .then((rese) => {
                              api
                                .post(
                                  SAVE_USER,
                                  JSON.stringify({
                                    id: props.AuthDetail.id,
                                    is_active: 1,
                                    is_new: 0,
                                    platform: "web",
                                  })
                                )
                                .then((result) => {
                                  setShowLoader(false);
                                  confirmAlert({
                                    customUI: ({ onClose }) =>
                                      customModalSuccess(onClose),
                                  });
                                })
                                .catch((err) => {
                                  console.log("weee", err.message);
                                });
                            })
                            .catch((erre) => {
                              console.log("User Audit Error Saved: ", erre);
                              setShowLoader(false);
                            });
                        } else {
                          setShowLoader(false);
                          // confirmAlert({
                          //   customUI: ({ onClose }) =>
                          //     customModalSuccess(onClose),
                          // });
                        }

                        // alert("Password has been reset.");
                      })
                      .catch((err) => {
                        console.log("User Error Reset: ", err);
                        setShowLoader(false);
                      });
                  }
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const resetPasswordButton = () => {
    validateReset();

    // confirmAlert({
    //   title: "Confirm to reset",
    //   message: "Do you want to reset the user password?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => {
    //         console.log("Dialog Yes clicked.");
    //         validateReset();

    //         if (shouldReset == true) {
    //           setShowLoader(true);
    //           api
    //             .post(
    //               CHANGE_PASSWORD,
    //               JSON.stringify({
    //                 platform: "web",
    //                 email: profileValues.email,
    //                 password: resetValues.password,
    //               }),
    //               {
    //                 headers: { "Content-Type": "application/json" },
    //               }
    //             )
    //             .then((res) => {
    //               console.log("User Password Reset Done: ", res);
    //               setShowLoader(false);
    //               alert("Password has been reset.");
    //             })
    //             .catch((err) => {
    //               console.log("User Error Reset: ", err);
    //               setShowLoader(false);
    //             });
    //         }
    //       },
    //     },
    //     {
    //       label: "No",
    //       onClick: () => {},
    //     },
    //   ],
    // });
  };
  const mainSaveButton = async () => {
    validateUserProfile();
    var changes = findChangesBeforeSave();
    let data = {
      id: profileValues.id,
      name: profileValues.name,
      park: profileValues.park,
      is_active: profileValues.is_active,
      is_new: profileValues.is_new,
      department: profileValues.department,
      mobile_no: profileValues.mobile_no,

      staff_id: profileValues.staff_id,
      sub_department: profileValues.sub_department,
      account_expiry:
        profileValues.account_expiry == "Invalid date"
          ? ""
          : profileValues.account_expiry,
      role: profileValues.role,
      platform: "web",
    };

    if (originalProfileValues.email != profileValues.email) {
      data.email = profileValues.email;
    }

    if (shouldSave == true) {
      setShowLoader(true);
      api
        .post(
          SAVE_USER,
          JSON.stringify({
            ...data,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          console.log("User Info Saved: ", res);
          if (res.message !== "Success") {
            setShowLoader(false);
            return confirmAlert({
              customUI: ({ onClose }) => customModalText(onClose, res.message),
            });
          }

          api
            .post(
              SAVE_USER_AUDIT,
              JSON.stringify({
                user_id: userId,
                email: profileValues.email,
                changed_by: auth.currentUser.uid,
                description: changes,
                platform: "web",
              }),
              {
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((rese) => {
              console.log("User Audit Saved: ", rese);
              setShowLoader(false);
              history.push("/user_management");
            })
            .catch((erre) => {
              console.log("User Audit Error Saved: ", erre);
              setShowLoader(false);
            });
        })
        .catch((err) => {
          console.log("User Error Saved: ", err);
          setShowLoader(false);
        });
    }
    // confirmAlert({
    //   title: "Confirm to submit",
    //   message: "Do you want to save the user details?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => {

    //       },
    //     },
    //     {
    //       label: "No",
    //       onClick: () => {},
    //     },
    //   ],
    // });
  };

  // left side bar menus
  let sidebar_menus = [
    {
      title: "Profile",
    },
    {
      title: "Account",
    },
    {
      title: "Reset Password",
    },
  ];

  const Sidebar = () => {
    return (
      <div className="user_detail_sidebar">
        {sidebar_menus.map((c, index) => {
          return (
            <>
              {c.title == "Account" ? (
                !profileBool ? (
                  <div
                    className={`ud_option_container ${
                      c.title == detailSelected && "active"
                    }`}
                    onClick={() => {
                      setDetailSelected(c.title);
                    }}
                  >
                    <div style={{ width: "95%" }}>{c.title}</div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          background: "#E72D35",
                          color: "#fff",
                          width: 20,
                          height: 20,
                          borderRadius: 50,
                          display: "flex",
                          justifyContent: "center",
                          marginRight: 10,
                          opacity: sidebarcssColors(c.title) == "error" ? 1 : 0,
                        }}
                      >
                        !
                      </div>
                      <img
                        src={
                          c.title == detailSelected
                            ? RightArrowIcon
                            : DownArrowIcon
                        }
                      />
                    </div>
                  </div>
                ) : null
              ) : (
                <div
                  className={`ud_option_container ${
                    c.title == detailSelected && "active"
                  }`}
                  onClick={() => {
                    setDetailSelected(c.title);
                  }}
                >
                  <div style={{ width: "95%" }}>{c.title}</div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        background: "#E72D35",
                        color: "#fff",
                        width: 20,
                        height: 20,
                        borderRadius: 50,
                        display: "flex",
                        justifyContent: "center",
                        marginRight: 10,
                        opacity: sidebarcssColors(c.title) == "error" ? 1 : 0,
                      }}
                    >
                      !
                    </div>
                    <img
                      src={
                        c.title == detailSelected
                          ? RightArrowIcon
                          : DownArrowIcon
                      }
                    />
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  };
  const MobileSideBar = () => {
    return (
      <div style={{ display: "flex", margin: "15px" }}>
        {sidebar_menus.map((c, index) => {
          return (
            <div
              className={`mobile_filter_tabs ${
                c.title == detailSelected && "active"
              }`}
              style={{ display: "flex", position: "relative" }}
            >
              <Button
                onClick={() => {
                  setDetailSelected(c.title);
                }}
              >
                {c.title}
              </Button>
              <div
                style={{
                  background: "#E72D35",
                  color: "#fff",
                  width: 20,
                  height: 20,
                  borderRadius: 50,
                  display: "flex",
                  justifyContent: "center",
                  marginRight: 10,
                  opacity: sidebarcssColors(c.title) == "error" ? 1 : 0,
                  position: "absolute",
                  top: 6,
                  right: 0,
                }}
              >
                !
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  useEffect(() => {}, [selectedDate, toggleDatePicker]);

  const customUserSuspendModal = () => {
    return (
      <div
        onClick={() => {
          setDateTimeError(false);
          setSuspendReasonError(false);
          setSuspendModal(false);
        }}
        className="suspendAlertBox"
      >
        <div
          style={{
            width: 400,
            minHeight: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
            zIndex: 2,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              src={ExitIcon}
              onClick={() => {
                setDateTimeError(false);
                setSuspendReasonError(false);
                setSuspendModal(false);
              }}
            />
          </div>
          <div>
            <div className="input_container">
              <div className={`input_title ${dateTimeError && "error"}`}>
                Date*
              </div>
              <div className={`datePicker`} style={{ width: "105%" }}>
                <DatePicker
                  onChangeRaw={(e) => e.preventDefault()}
                  className={`${dateTimeError && "ggerror"}`}
                  selected={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                  }}
                  values={
                    selectedDate && moment(selectedDate).format("D MMM yyyy")
                  }
                />
                {}
                <img src={CalendarIcon} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={`input_title ${suspendReasonError && "error"}`}>
              Remarks to suspend account*
            </h3>
            <TextArea
              rows={8}
              id="suspend_text_area"
              className={`suspend_text_area ${suspendReasonError && "error"}`}
              onChange={(e, data) => {
                suspend_reason = data.value;
              }}
            />
          </div>
          <div>
            <Button
              className="suspend_submit_btn"
              onClick={suspendButtonAction}
            >
              Suspend
            </Button>
            <Button
              className="suspend_cancel_btn"
              onClick={() => {
                setDateTimeError(false);
                setSuspendReasonError(false);
                setSuspendModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  };

  function reactivatedSuccessModal(onClose) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Reactivation successful
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            User has been reactivated.
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div></div>
            <div>
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={() => {
                  onClose();
                  history.push("/user_management");
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const UserReactivePopup = () => {
    return (
      <>
        <di style={{ width: "100%" }}>
          <Button
            className="ud_reactive_button"
            onClick={() => setReactiveModal(true)}
          >
            Reactivate Account
          </Button>
        </di>
        {reactiveModal && <div className="ud_reactive_popup_overlay"></div>}
        {reactiveModal && (
          <div
            className="ud_reactive_popup"
            onClick={(e) => e.stopPropagation()}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <img src={ExitIcon} onClick={() => setReactiveModal(false)} />
            </div>
            <div>
              <h3 className={`${reactiveError && "errorReactiveTxt"} `}>
                Remarks to reactive account*
              </h3>
              <TextArea
                rows={8}
                id="reactive_text_area"
                className={`suspend_text_area ${reactiveError && "error"}`}
                onChange={(e, data) => {
                  reactive_reason = data.value;
                }}
              />
            </div>
            <div>
              <Button
                className="reactive_submit_btn"
                onClick={(e) => {
                  e.stopPropagation();
                  if (reactive_reason == "") {
                    return setReactiveError(true);
                  } else {
                    reactiveButtonAction();
                  }
                }}
              >
                Reactivate Account
              </Button>
              <Button
                className="reactive_cancel_btn"
                onClick={() => setReactiveModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className="user_detail_container"
      ref={scrollRef}
      style={{ position: "relative" }}
    >
      {suspendModal && customUserSuspendModal()}
      {suspendModalSuccess && sucessfullySuspendedModal()}

      <div className="user_detail">
        <div className="user_detail_header">
          <div
            to=""
            className="ud_header_leftside"
            onClick={() => (!profileBool ? history.goBack() : null)}
          >
            {!profileBool ? (
              <img src={LeftArrowIcon} style={{ marginTop: 0 }} />
            ) : null}
            <div className="user_detail_title">
              {profileBool ? "My Profile Page" : "User Details"}
            </div>
          </div>
        </div>
        {isMobile && <MobileSideBar />}
        <div className="user_detail_form">
          {showLoader == true ? (
            <Dimmer active>
              <Loader active> Loading </Loader>
            </Dimmer>
          ) : null}
          {!isMobile && <Sidebar />}

          <div className="ud_form ud_add_flex">
            <div className="ud_form_container ud_remove_width">
              {/* {console.log("data >> ", props.userObj)} */}
              {detailSelected == "Profile" && props.userObj !== undefined ? (
                <>
                  {props.reloader ? (
                    <div>Loader</div>
                  ) : (
                    <UserProfile
                      values={profileValues && profileValues}
                      onChangeProfileView={onChangeProfileView}
                      profileViewInputError={{
                        nameError,
                        emailError,
                        mobileError,
                        staffidError,
                        roleError,
                        parkError,
                        departmentError,
                        subdepartmentError,
                      }}
                      profileBool={profileBool}
                      optionsState={{
                        placeList,
                        setPlaceList,
                        roleList,
                        setRoleList,
                        departmentList,
                        setDepartmentList,
                        subDepartmentList,
                        setSubDepartmentList,
                      }}
                    />
                  )}
                </>
              ) : detailSelected == "Permission" &&
                props.userObj !== undefined ? (
                <>
                  {props.reloader ? (
                    <div>Loader</div>
                  ) : (
                    <UserPermission
                      values={profileValues && profileValues}
                      onChangeProfileView={onChangeProfileView}
                    />
                  )}
                </>
              ) : detailSelected == "Account" && props.userObj !== undefined ? (
                <>
                  {props.reloader ? (
                    <div>Loader</div>
                  ) : (
                    <UserAccount
                      values={profileValues && profileValues}
                      is_active={profileValues.is_active}
                      onChangeProfileView={onChangeProfileView}
                      dateError={dateError}
                    />
                  )}
                </>
              ) : detailSelected == "Reset Password" ? (
                <UserReset
                  values={profileValues && profileValues}
                  resetvalues={resetValues}
                  onChangePasswordView={onChangePasswordView}
                  userResetError={userResetError}
                  profileBool={profileBool}
                />
              ) : null}

              <div style={{ width: "100%", height: "60px" }}></div>
            </div>
            <div className="ud_incident_footer">
              <div style={{ display: "flex" }}>
                {detailSelected == "Account" && showSuspendBtn ? (
                  <>
                    {/* // <UserSuspendPopup /> */}
                    <div style={{ width: "100%" }}>
                      <Button
                        className="ud_suspend_button"
                        onClick={() => {
                          setToggleDatePicker(!toggleDatePicker);
                          setSuspendModal(true);
                          // confirmAlert({
                          //   customUI: ({ onClose }) =>
                          //     customUserSuspendModal(
                          //       onClose,
                          //       currentSelectedDate
                          //     ),
                          // });
                        }}
                      >
                        Archive account
                      </Button>
                    </div>
                    {/* <div style={{ width: "100%" }}>
                      <Button className="ud_suspend_button" onClick={() => {}}>
                        Archive account
                      </Button>
                    </div> */}
                  </>
                ) : null}
                <div style={{ width: "100%" }}>
                  {detailSelected == "Profile" &&
                  // props.userObj !== undefined &&
                  // showArchiveBtn == true &&
                  !profileBool &&
                  filterP9 ? (
                    <Button
                      className="ud_archive_button"
                      // onClick={archiveButtonAction}

                      onClick={() => {
                        return confirmAlert({
                          customUI: ({ onClose }) =>
                            customModalDeleteUser(onClose),
                        });
                      }}
                    >
                      Delete User
                    </Button>
                  ) : null}
                  {props.userObj &&
                    props.userObj.is_active == 0 &&
                    props.userObj.is_new == 0 && (
                      <Button
                        className="ud_cancel_button"
                        style={{
                          width: 150,
                        }}
                        onClick={() => {
                          return confirmAlert({
                            customUI: ({ onClose }) =>
                              customModalUnlockUser(onClose),
                          });
                        }}
                      >
                        Unlock User
                      </Button>
                    )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                {!profileBool && filterP9 && (
                  <div
                    className="view_user_audit"
                    onClick={() =>
                      history.push(
                        USER_AUDIT_ROUTE +
                          "?id=" +
                          userId +
                          "&name=" +
                          profileValues["name"]
                      )
                    }
                  >
                    View Audit Trail
                  </div>
                )}

                {detailSelected == "Account" && showReactiveBtn && filterP9 ? (
                  <UserReactivePopup />
                ) : null}
                <div style={{ width: "100%" }}>
                  <Button
                    className="ud_cancel_button"
                    onClick={() => {
                      if (profileBool) {
                        return history.push("/");
                      } else {
                        return history.goBack();
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                {detailSelected == "Reset Password" && profileBool ? (
                  <div style={{ width: "100%" }}>
                    <Button
                      className="ud_save_button"
                      onClick={resetPasswordButton}
                    >
                      Save
                    </Button>
                  </div>
                ) : detailSelected != "Reset Password" ? (
                  <div style={{ width: "100%" }}>
                    {props.AuthDetail.id === profileValues.id || filterP9 ? (
                      <Button
                        className="ud_save_button"
                        onClick={mainSaveButton}
                      >
                        Save
                      </Button>
                    ) : null}
                  </div>
                ) : detailSelected == "Reset Password" ? (
                  <div style={{ width: "100%" }}>
                    <Button
                      className="ud_reset_email"
                      onClick={resetPasswordButton}
                    >
                      Save
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userObj: state.userAccountDetail.userObj.data,
  reloader: state.userAccountDetail.loader,
  AuthDetail: state.loggedInUserDetail.userDetail,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserDetails: (userId) => dispatch(fetchUserDetails(userId)),
  userLogout: () => dispatch(userLogout()),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
