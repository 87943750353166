import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Button,
  Loader,
  Dimmer,
  Pagination,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { db } from "../../../../../config/firebase";
import config from "../../../../../config/config";
import api from "../../../../../config/api";
import { confirmAlert } from "react-confirm-alert";
// images
import DeleteIcon from "../../../../../assets/images/incident/btn_delete.svg";
import NotificationListForm from "./NotificationListForm";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../../routes";
import SearchIcon from "../../../../../assets/images/incident/search.svg";
import RightArrowIcon from "../../../../../assets/images/incident/rightarrow.svg";
import DownArrowIcon from "../../../../../assets/images/incident/arrowdowndrop.svg";
import "../cases.css";
import UserList from "./UserList";
import DraftFooter from "../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../components/ReopenFooter";
import ClosedFooter from "../../../../../components/ClosedFooter";
import { connect } from "react-redux";
import PlaceList from "../../../../../components/PlaceList";
import { useMediaQuery } from "react-responsive";

function NotificationsList(props) {
  const [showUserList, setShowUserList] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [search, setSearch] = useState("");
  const [showSearchData, setShowSearchData] = useState(false);
  const [searchDataList, setSearchDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchDataPagiantion, setSearchPagination] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpage] = useState(0);
  const [lastItem, setLastItem] = useState({
    from: 0,
    to: 10,
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [currentUsersPage, setCurrentUsersPage] = useState({});

  const [searchValue, setSearchValue] = useState("");

  const [userList, setUserList] = useState([]);

  const { departState, setDepartState } = props.departState;
  const { usersState, setUsersState } = props.usersState;
  const { departFoldState, setDepartFoldState } = props.departFoldState;
  const onChangeUsersState = props.onChangeUsersState;
  const getUsersState = props.getUsersState;
  const onChangeDepartState = props.onChangeDepartState;
  const getDepartState = props.getDepartState;
  const getDepartFoldState = props.getDepartFoldState;
  const userListForNoti = props.userListForNoti;
  const userListForNotiLoader = props.userListForNotiLoader;
  const onChangeDepartFoldState = props.onChangeDepartFoldState;
  const incident_classification = props.incident_classification;
  const departmentList = props.departmentList;
  const setSearchUsersPagination = props.setSearchUsersPagination;
  const searchUsersDataPagiantion = props.searchUsersDataPagiantion;
  const totalUsersPage = props.totalUsersPage;
  const setTotalUserspage = props.setTotalUserspage;
  const lastUsersItem = props.lastUsersItem;
  const setLastUsersItem = props.setLastUsersItem;
  const allSubDepartmentList = props.allSubDepartmentList;
  const totalpageForAllSubDepart = props.totalpageForAllSubDepart;
  const lastItemForAllSubDepart = props.lastItemForAllSubDepart;
  const subDepartmentForPagination = props.subDepartmentForPagination;
  const { SEARCH_NOTI_USERS } = config.api_endpoint;
  // useEffect(() => {}, [departState, nt]);

  useEffect(() => {}, [props.reSubDepartmentList]);
  const setSearchBy = props.setSearchBy;
  const searchBy = props.searchBy;

  let filterP4 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4");
  let filterP4A =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4a");
  let filterP5 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p5");

  let filterP6 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p6");
  let filterP7 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p7");
  let filterP15 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p15");

  const onChangeIncidentOverView = props.onChangeIncidentOverView;
  const incidentStatus = props.incidentStatus;
  const previousIncident = props.previousIncident;

  let history = useHistory();
  let departs = [
    {
      key: "ORD",
      title: "Operation Department (ORD)",
    },
    {
      key: "ZOD",
      title: "Zoology Department (ZOD)",
    },
    {
      key: "SFD",
      title: "Safety Department (SFD)",
    },
    {
      key: "FBD",
      title: "Food and Beverages Department (FBD)",
    },
  ];

  let users = [
    {
      id: 0,
      key: "ORD",
      title: "Andrew Ong",
    },
    {
      id: 1,
      key: "ORD",
      title: "Apple Wee",
    },
    {
      id: 2,
      key: "ORD",
      title: "Ben Tan",
    },
    {
      id: 3,
      key: "ZOD",
      title: "Brian Lee",
    },
    {
      id: 4,
      key: "ZOD",
      title: "Cindy Lim",
    },
    {
      id: 5,
      key: "ZOD",
      title: "ROY LEE",
    },
    {
      id: 6,
      key: "SFD",
      title: "ZinLang Lim",
    },
    {
      id: 7,
      key: "FBD",
      title: "ROYI LEEN",
    },
  ];
  const handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage;
    setLastItem({
      from: (page - 1) * 10,
      to: page * 10,
    });
    let from = (page - 1) * 10;
    let to = page * 10;
    setSearchPagination(searchDataList.slice(from, to));

    setCurrentPage(page);
  };

  const handleUsersPaginationChange = (activePage, title) => {
    window.scrollTo(0, 0);
    let page = activePage;

    setLastUsersItem({
      from: (page - 1) * 50,
      to: page * 50,
    });
    let from = (page - 1) * 50;
    let to = page * 50;
    if (searchUsersDataPagiantion[title]) {
      setSearchUsersPagination({
        ...searchUsersDataPagiantion,
        [title]: userListForNoti[title].slice(from, to),
      });
    }

    // setCurrentUsersPage(page);
    setCurrentUsersPage({
      ...currentUsersPage,
      [title]: page,
    });
  };
  const submitForm = () => {
    props.mainSaveButton();
  };

  const Sidebar = () => {
    return (
      <div className="departbar" style={{ paddingBottom: 50 }}>
        {/* Cases */}

        {loading || props.sub_depart_loading ? (
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
        ) : showSearchData ? (
          <>
            {props.sub_depart_loading ? (
              <div style={{ position: "relative", top: 10 }}>
                <Loader size="mini" active></Loader>
              </div>
            ) : searchDataPagiantion.length == 0 ? (
              <div style={{ textAlign: "center", color: "#8F8F8F" }}>
                No user found.
              </div>
            ) : searchBy == "user" ? (
              searchDataPagiantion.map((c, index) => {
                return (
                  <div>
                    {c.title && (
                      <>
                        <div className="option_container">
                          <div>
                            {getDepartState(c.key) == "minuSign" ? (
                              <div>
                                <input
                                  type="checkbox"
                                  id="minusbox"
                                  className="minusCheckbox"
                                  onChange={(e) => {
                                    onChangeDepartState(
                                      c.key,
                                      e.target.checked
                                    );
                                    getDepartFoldState(c.key)
                                      ? onChangeDepartFoldState(c.key, false)
                                      : onChangeDepartFoldState(c.key, true);
                                    props.handleClickDepartment(c.title);
                                  }}
                                />
                                <label for="minusbox" className="minusLabel">
                                  -
                                </label>
                                <span style={{ fontSize: 14, marginLeft: 5 }}>
                                  {c.title}
                                </span>
                              </div>
                            ) : (
                              <Checkbox
                                label={c.title}
                                checked={getDepartState(c.key, c.state)}
                                className="checkBox"
                                onChange={(e, data) => {
                                  onChangeDepartState(c.key, data.checked);
                                  getDepartFoldState(c.key)
                                    ? onChangeDepartFoldState(c.key, false)
                                    : onChangeDepartFoldState(c.key, true);
                                  props.handleClickDepartment(c.title);
                                }}
                              />
                            )}
                          </div>

                          {/* <div style={{ width: "95%" }}>
                            <b>{c.title}</b>
                          </div> */}
                          <div
                            style={{ display: "flex" }}
                            onClick={() =>
                              getDepartFoldState(c.key)
                                ? onChangeDepartFoldState(c.key, false)
                                : onChangeDepartFoldState(c.key, true)
                            }
                          >
                            {getDepartFoldState(c.key)}
                            <div
                              style={{
                                background: "#E72D35",
                                color: "#fff",
                                width: 20,
                                height: 20,
                                borderRadius: 50,
                                display: "flex",
                                justifyContent: "center",
                                marginRight: 10,
                                opacity: 0,
                              }}
                            >
                              !
                            </div>
                            <div
                            // onClick={() =>
                            //   props.handleClickDepartment(c.title)
                            // }
                            >
                              <img
                                src={
                                  getDepartFoldState(c.title)
                                    ? userListForNoti[c.title]
                                      ? DownArrowIcon
                                      : RightArrowIcon
                                    : RightArrowIcon
                                }
                              />
                            </div>
                          </div>
                        </div>

                        {getDepartFoldState(c.title) &&
                        userListForNoti[c.title] &&
                        userListForNoti[c.title].length == 0 ? (
                          <div
                            style={{ textAlign: "center", color: "#8F8F8F" }}
                          >
                            No user found.
                          </div>
                        ) : (
                          <>
                            {getDepartFoldState(c.title) &&
                              searchUsersDataPagiantion[c.title] &&
                              searchUsersDataPagiantion[c.title].map(
                                (u, index2) => {
                                  return u.key == c.key ? (
                                    <div className="user_container">
                                      <Checkbox
                                        label={u.name + ", " + u.key}
                                        checked={getUsersState(
                                          u.id,
                                          u.key,
                                          u.state
                                        )}
                                        className="checkBox"
                                        onChange={(e, data) =>
                                          onChangeUsersState(
                                            u.id,
                                            data.checked,
                                            u.key
                                          )
                                        }
                                        iconStyle={{ fill: "white" }}
                                      />
                                    </div>
                                  ) : null;
                                }
                              )}
                            {getDepartFoldState(c.title) &&
                              userListForNoti[c.title] &&
                              userListForNoti[c.title].length > 50 && (
                                <div>
                                  <Pagination
                                    activePage={
                                      currentUsersPage[c.title]
                                        ? currentUsersPage[c.title]
                                        : 1
                                    }
                                    firstItem={null}
                                    lastItem={null}
                                    siblingRange={3}
                                    floated="right"
                                    totalPages={totalUsersPage}
                                    onPageChange={(e, { activePage }) =>
                                      handleUsersPaginationChange(
                                        activePage,
                                        c.title
                                      )
                                    }
                                  />
                                </div>
                              )}
                          </>
                        )}
                        <div
                          style={{
                            marginTop:
                              getDepartFoldState(c.title) &&
                              userListForNoti[c.title] &&
                              userListForNoti[c.title].length > 50 &&
                              60,
                          }}
                        ></div>
                        <hr size="1" />
                      </>
                    )}
                  </div>
                );
              })
            ) : (
              searchDataPagiantion.map((c, index) => {
                return (
                  <div>
                    {c.title && (
                      <>
                        <div className="option_container">
                          <div>
                            {getDepartState(c.key) == "minuSign" ? (
                              <div>
                                <input
                                  type="checkbox"
                                  id="minusbox"
                                  className="minusCheckbox"
                                  onChange={(e) => {
                                    onChangeDepartState(
                                      c.key,
                                      e.target.checked
                                    );
                                    getDepartFoldState(c.key)
                                      ? onChangeDepartFoldState(c.key, false)
                                      : onChangeDepartFoldState(c.key, true);
                                    props.handleClickDepartment(c.title);
                                  }}
                                />
                                <label for="minusbox" className="minusLabel">
                                  -
                                </label>
                                <span style={{ fontSize: 14, marginLeft: 5 }}>
                                  {c.title}
                                </span>
                              </div>
                            ) : (
                              <Checkbox
                                label={c.title}
                                checked={getDepartState(c.key, c.state)}
                                className="checkBox"
                                onChange={(e, data) => {
                                  onChangeDepartState(c.key, data.checked);
                                  getDepartFoldState(c.key)
                                    ? onChangeDepartFoldState(c.key, false)
                                    : onChangeDepartFoldState(c.key, true);
                                  props.handleClickDepartment(c.title);
                                }}
                              />
                            )}
                          </div>

                          {/* <div style={{ width: "95%" }}>
                          <b>{c.title}</b>
                        </div> */}
                          <div
                            style={{ display: "flex" }}
                            onClick={() =>
                              getDepartFoldState(c.key)
                                ? onChangeDepartFoldState(c.key, false)
                                : onChangeDepartFoldState(c.key, true)
                            }
                          >
                            <div
                              style={{
                                background: "#E72D35",
                                color: "#fff",
                                width: 20,
                                height: 20,
                                borderRadius: 50,
                                display: "flex",
                                justifyContent: "center",
                                marginRight: 10,
                                opacity: 0,
                              }}
                            >
                              !
                            </div>
                            <div
                              onClick={() =>
                                props.handleClickDepartment(c.title)
                              }
                            >
                              <img
                                src={
                                  !getDepartFoldState(c.title)
                                    ? searchUsersDataPagiantion[c.title]
                                      ? DownArrowIcon
                                      : RightArrowIcon
                                    : RightArrowIcon
                                }
                              />
                            </div>
                          </div>
                        </div>

                        {!getDepartFoldState(c.title) &&
                        searchUsersDataPagiantion[c.title] &&
                        searchUsersDataPagiantion[c.title].length == 0 ? (
                          <div
                            style={{ textAlign: "center", color: "#8F8F8F" }}
                          >
                            No user found.
                          </div>
                        ) : (
                          <>
                            {!getDepartFoldState(c.title) &&
                              searchUsersDataPagiantion[c.title] &&
                              searchUsersDataPagiantion[c.title].map(
                                (u, index2) => {
                                  return u.key == c.key ? (
                                    <div className="user_container">
                                      <Checkbox
                                        label={u.name + ", " + u.key}
                                        checked={getUsersState(
                                          u.id,
                                          u.key,
                                          u.state
                                        )}
                                        className="checkBox"
                                        onChange={(e, data) =>
                                          onChangeUsersState(
                                            u.id,
                                            data.checked,
                                            u.key
                                          )
                                        }
                                        iconStyle={{ fill: "white" }}
                                      />
                                    </div>
                                  ) : null;
                                }
                              )}
                            {!getDepartFoldState(c.title) &&
                              userListForNoti[c.title] &&
                              userListForNoti[c.title].length > 50 && (
                                <div>
                                  <Pagination
                                    activePage={
                                      currentUsersPage[c.title]
                                        ? currentUsersPage[c.title]
                                        : 1
                                    }
                                    firstItem={null}
                                    lastItem={null}
                                    siblingRange={3}
                                    floated="right"
                                    totalPages={totalUsersPage}
                                    onPageChange={(e, { activePage }) =>
                                      handleUsersPaginationChange(
                                        activePage,
                                        c.title
                                      )
                                    }
                                  />
                                </div>
                              )}
                          </>
                        )}
                        <div
                          style={{
                            marginTop:
                              getDepartFoldState(c.title) &&
                              userListForNoti[c.title] &&
                              userListForNoti[c.title].length > 50 &&
                              60,
                          }}
                        ></div>
                        <hr size="1" />
                      </>
                    )}
                  </div>
                );
              })
            )}
          </>
        ) : departFoldState.length == 0 ? (
          <div style={{ textAlign: "center", color: "#8F8F8F", marginTop: 25 }}>
            Please select incident classfication.
          </div>
        ) : (
          subDepartmentForPagination.map((c, index) => {
            return (
              <div>
                {c.title && (
                  <>
                    <div className="option_container">
                      <div>
                        {getDepartState(c.key) == "minuSign" ? (
                          <div>
                            <input
                              type="checkbox"
                              id="minusbox"
                              className="minusCheckbox"
                              onChange={(e) => {
                                onChangeDepartState(c.key, e.target.checked);
                                getDepartFoldState(c.key)
                                  ? onChangeDepartFoldState(c.key, false)
                                  : onChangeDepartFoldState(c.key, true);
                                props.handleClickDepartment(c.title);
                              }}
                            />
                            <label for="minusbox" className="minusLabel">
                              -
                            </label>
                            <span style={{ fontSize: 14, marginLeft: 5 }}>
                              {c.title}
                            </span>
                          </div>
                        ) : (
                          <Checkbox
                            label={c.title}
                            checked={getDepartState(c.key)}
                            className="checkBox"
                            onChange={(e, data) => {
                              onChangeDepartState(c.key, data.checked);
                              getDepartFoldState(c.key)
                                ? onChangeDepartFoldState(c.key, false)
                                : onChangeDepartFoldState(c.key, true);
                              props.handleClickDepartment(c.title);
                            }}
                          />
                        )}
                        {c.key == "Admin" ? (c.state ? "tre" : "false") : null}
                      </div>

                      {/* <div style={{ width: "95%" }}>
                        <b>{c.title}</b>
                      </div> */}
                      <div
                        style={{ display: "flex" }}
                        onClick={() =>
                          getDepartFoldState(c.key)
                            ? onChangeDepartFoldState(c.key, false)
                            : onChangeDepartFoldState(c.key, true)
                        }
                      >
                        <div
                          style={{
                            background: "#E72D35",
                            color: "#fff",
                            width: 20,
                            height: 20,
                            borderRadius: 50,
                            display: "flex",
                            justifyContent: "center",
                            marginRight: 10,
                            opacity: 0,
                          }}
                        >
                          !
                        </div>
                        <div
                          onClick={() => props.handleClickDepartment(c.title)}
                        >
                          <img
                            src={
                              !getDepartFoldState(c.title)
                                ? searchUsersDataPagiantion[c.title]
                                  ? DownArrowIcon
                                  : RightArrowIcon
                                : RightArrowIcon
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {!getDepartFoldState(c.title) &&
                    userListForNoti[c.title] &&
                    userListForNoti[c.title].length == 0 ? (
                      <div style={{ textAlign: "center", color: "#8F8F8F" }}>
                        No user found.
                      </div>
                    ) : (
                      <>
                        {!getDepartFoldState(c.title) &&
                          searchUsersDataPagiantion[c.title] &&
                          searchUsersDataPagiantion[c.title].map(
                            (u, index2) => {
                              return u.key == c.key ? (
                                <div className="user_container">
                                  <Checkbox
                                    label={u.name + ", " + u.key}
                                    checked={getUsersState(
                                      u.id,
                                      u.key,
                                      u.state
                                    )}
                                    className="checkBox"
                                    onChange={(e, data) =>
                                      onChangeUsersState(
                                        u.id,
                                        data.checked,
                                        u.key
                                      )
                                    }
                                    iconStyle={{ fill: "white" }}
                                  />
                                </div>
                              ) : null;
                            }
                          )}
                        {!getDepartFoldState(c.title) &&
                          userListForNoti[c.title] &&
                          userListForNoti[c.title].length > 50 && (
                            <div>
                              <Pagination
                                activePage={
                                  currentUsersPage[c.title]
                                    ? currentUsersPage[c.title]
                                    : 1
                                }
                                firstItem={null}
                                lastItem={null}
                                siblingRange={3}
                                floated="right"
                                totalPages={totalUsersPage}
                                onPageChange={(e, { activePage }) =>
                                  handleUsersPaginationChange(
                                    activePage,
                                    c.title
                                  )
                                }
                              />
                            </div>
                          )}
                      </>
                    )}
                    <div
                      style={{
                        marginTop:
                          getDepartFoldState(c.title) &&
                          userListForNoti[c.title] &&
                          userListForNoti[c.title].length > 50 &&
                          60,
                      }}
                    ></div>
                    <hr size="1" />
                  </>
                )}
              </div>
            );
          })
        )}
        {showSearchData &&
          props.sub_depart_loading &&
          searchDataPagiantion.length > 1 && (
            <>
              <Pagination
                activePage={currentPage}
                firstItem={null}
                lastItem={null}
                siblingRange={3}
                floated="right"
                totalPages={totalPage}
                onPageChange={handlePaginationChange}
              />
            </>
          )}
        {!showSearchData && subDepartmentForPagination.length > 1 && (
          <Pagination
            activePage={props.currentAllSubDepartPage}
            firstItem={null}
            lastItem={null}
            siblingRange={3}
            floated="right"
            totalPages={totalpageForAllSubDepart}
            onPageChange={props.handlePaginationForAllSubDepart}
          />
        )}
      </div>
    );
  };
  const searchDepartmentDataForNoti = (title) => {
    const filterData = props.checkAllDepartmentNoti.filter(
      (c) => c.title === title
    );
    if (filterData.length === 0) {
      return false;
    } else {
      return true;
    }
  };
  const departmentContainer = (depart) => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            marginTop: 25,
            padding: "0px 15px",
            paddingBottom: "10px",
            // cursor: "pointer",
          }}
        >
          <Checkbox
            label=""
            // checked={getDepartState(c.key)}
            className="checkBox"
            checked={searchDepartmentDataForNoti(depart.title)}
            onChange={(e, data) => {
              props.onChangeDepartmentForNoti(depart.title, data.checked);
            }}
          />
          <div style={{ width: "95%" }}>
            <b>{depart.title}</b>
          </div>
          <img
            src={
              showUserList && selectedDepartment == depart.title
                ? RightArrowIcon
                : DownArrowIcon
            }
            onClick={() => {
              if (selectedDepartment != depart.title) {
                setShowUserList(true);
                setSelectedDepartment(depart.title);
              } else {
                setShowUserList(!showUserList);
                setSelectedDepartment(depart.title);
              }
            }}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
        {showUserList && selectedDepartment == depart.title && (
          <UserList
            checkAllDepartmentNoti={props.checkAllDepartmentNoti}
            title={depart.title}
          />
        )}

        <hr size="1" />
      </div>
    );
  };
  const fetchFilter = (filter) => {
    setSearchUsersPagination([]);
    setShowSearchData(true);
    if (filter == "department") {
      setLoading(true);
      props.reSearchByDepartment(search);
      setSearchValue(search);
      api
        .get(SEARCH_NOTI_USERS, {
          platform: "web",
          keyword: search,
          search_by: filter,
        })
        .then((res) => {
          const { data } = res;
          let updateData = [];
          let id = 0;
          data.map((d) => {
            id += 1;
            updateData.push({
              id,
              key: d.name,
              title: d.name,
              state: true,
            });
          });
          let sortData = updateData.sort((a, b) => a - b);

          setSearchDataList(sortData);
          setLastItem({
            from: 0,
            to: 10,
          });
          setTotalpage(Math.ceil(updateData.length / 10));
          setSearchPagination(updateData.slice(0, 10));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
    } else {
      props.reSearchByUser(search);
      props.fetchUsersBySearch(
        search,
        setSearchDataList,
        setLastItem,
        setTotalpage,
        setSearchPagination
      );
    }
  };
  const submitHandle = (e) => {
    e.preventDefault();
    props.setCurrentAllSubDepartPage(1);
    setSearchValue(search);
    if (search.length > 0) {
      setShowSearchData(true);
      setSearchUsersPagination([]);
    } else {
      setShowSearchData(false);
      props.fetchDefaultDepartment();
      // setSearchBy("department");
      // setSearchBy("department");
    }
    if (searchBy == "department") {
      setLoading(true);

      return props.reSearchByDepartment(search);
      api
        .get(SEARCH_NOTI_USERS, {
          platform: "web",
          keyword: search,
          search_by: searchBy,
        })
        .then((res) => {
          const { data } = res;
          console.log("res", res);
          let updateData = [];
          let id = 0;
          data.map((d) => {
            id += 1;
            updateData.push({
              id,
              key: d.name,
              title: d.name,
              state: true,
            });
          });
          let sortData = updateData.sort((a, b) => a - b);

          setSearchDataList(sortData);
          setLastItem({
            from: 0,
            to: 10,
          });
          setTotalpage(Math.ceil(updateData.length / 10));
          setSearchPagination(updateData.slice(0, 10));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
    } else {
      return props.reSearchByUser(search);
      props.fetchUsersBySearch(
        search,
        setSearchDataList,
        setLastItem,
        setTotalpage,
        setSearchPagination
      );
    }

    // let data = [];
    // data = props.subDeparts.filter((val) => {
    //   if (search == "") {
    //     setLoading(false);
    //     setShowSearchData(false);
    //   } else if (val.title.toLowerCase().includes(search.toLowerCase())) {
    //     setShowSearchData(true);
    //     setLoading(false);
    //     return val;
    //   } else {
    //     setLoading(false);
    //   }
    // });

    // let sortData = data.sort((a, b) => a - b);

    // setSearchDataList(sortData);
    // setLastItem({
    //   from: 0,
    //   to: 10,
    // });
    // setTotalpage(Math.ceil(data.length / 10));
    // setSearchPagination(data.slice(0, 10));
    // setLoading(false);
  };

  // const allSubDepartmentCheckboxView = () => {
  //   return <div className="departbar">{/* MAP */}</div>;
  // };

  console.log(props.reUsersList);

  const allSubDepartmentCheckboxView = () => {
    return (
      <div className="departbar">
        {console.log("props", props.reUsersList)}
        {console.log("props1", props.reSearchDepartment)}
        {/* MAP */}
        {showSearchData
          ? searchBy == "user"
            ? props.reSearchDepartment.map((res) => {
                return (
                  <>
                    <>
                      <div className="option_container">
                        {res.state == "minusSign" ? (
                          <div>
                            <input
                              type="checkbox"
                              id="minusbox"
                              className="minusCheckbox"
                              onChange={(e) => {
                                props.handleSubDepartCheckBox(
                                  res.title,
                                  e.target.checked,
                                  true
                                );
                              }}
                            />
                            <label for="minusbox" className="minusLabel">
                              -
                            </label>
                            <span style={{ fontSize: 14, marginLeft: 5 }}>
                              {res.title}
                            </span>
                          </div>
                        ) : (
                          <Checkbox
                            label={res.title}
                            checked={res.state}
                            className="checkBox"
                            onChange={(e, data) => {
                              props.handleSubDepartCheckBox(
                                res.title,
                                data.checked,
                                true
                              );
                            }}
                          />
                        )}

                        <div
                          onClick={() => {
                            console.log("result", res);
                            props.reHandleImageClickDepartment(res.title, true);
                          }}
                        >
                          <img
                            src={
                              res.displayUserList
                                ? DownArrowIcon
                                : RightArrowIcon
                            }
                          />
                        </div>
                      </div>
                      {/* Users List  */}
                      {res.displayUserList && (
                        <>
                          {!props.reUsersList[res.title] ||
                          props.reUsersList[res.title] === undefined ||
                          props.reUsersList[res.title].filter((person) => {
                            return person.name
                              .toLowerCase()
                              .includes(
                                props.clickSearchBtnNoti
                                  .toString()
                                  .toLowerCase()
                              );
                          }).length == 0 ? (
                            <div
                              style={{ textAlign: "center", color: "#8F8F8F" }}
                            >
                              No user found.
                            </div>
                          ) : (
                            props.reUsersList[res.title] &&
                            props.reUsersList[res.title] !== undefined &&
                            props.reUsersList[res.title].length > 0 &&
                            props.reUsersList[res.title]
                              .filter((person) => {
                                return person.name
                                  .toLowerCase()
                                  .includes(
                                    props.clickSearchBtnNoti
                                      .toString()
                                      .toLowerCase()
                                  );
                              })
                              .map((user) => {
                                return (
                                  <>
                                    {/* {user.name
                                    .toLowerCase()
                                    .startsWith(
                                      props.clickSearchBtnNoti
                                        .toString()
                                        .toLowerCase()
                                    ) && ( */}
                                    <div className="user_container">
                                      <Checkbox
                                        label={user.name + ", " + user.key}
                                        checked={user.state}
                                        className="checkBox"
                                        onChange={(e, data) => {
                                          props.reUserStateOnChange(
                                            user.id,
                                            user.key,
                                            data.checked,
                                            true
                                          );
                                        }}
                                        iconStyle={{ fill: "white" }}
                                      />
                                    </div>
                                    {/* )} */}
                                  </>
                                );
                              })
                          )}
                        </>
                      )}

                      <hr size="1" />
                    </>
                  </>
                );
              })
            : props.reSearchDepartment.map((res) => {
                return (
                  <>
                    <>
                      <div className="option_container">
                        {res.state == "minusSign" ? (
                          <div>
                            <input
                              type="checkbox"
                              id="minusbox"
                              className="minusCheckbox"
                              onChange={(e) => {
                                props.handleSubDepartCheckBox(
                                  res.title,
                                  e.target.checked,
                                  true
                                );
                              }}
                            />
                            <label for="minusbox" className="minusLabel">
                              -
                            </label>
                            <span style={{ fontSize: 14, marginLeft: 5 }}>
                              {res.title}
                            </span>
                          </div>
                        ) : (
                          <Checkbox
                            label={res.title}
                            checked={res.state}
                            className="checkBox"
                            onChange={(e, data) => {
                              props.handleSubDepartCheckBox(
                                res.title,
                                data.checked,
                                true
                              );
                            }}
                          />
                        )}

                        <div
                          onClick={() => {
                            console.log("result1", res);
                            props.reHandleImageClickDepartment(res.title, true);
                          }}
                        >
                          <img
                            src={
                              res.displayUserList
                                ? DownArrowIcon
                                : RightArrowIcon
                            }
                          />
                        </div>
                      </div>
                      {/* Users List  */}

                      {res.displayUserList && (
                        <>
                          {!props.reUsersList[res.title] ||
                          props.reUsersList[res.title] == undefined ? (
                            <div
                              style={{ textAlign: "center", color: "#8F8F8F" }}
                            >
                              No user found.
                            </div>
                          ) : (
                            props.reUsersList[res.title] &&
                            props.reUsersList[res.title] != undefined &&
                            props.reUsersList[res.title].length > 0 &&
                            props.reUsersList[res.title].map((user) => {
                              return (
                                <div className="user_container">
                                  <Checkbox
                                    label={user.name + ", " + user.key}
                                    checked={user.state}
                                    className="checkBox"
                                    onChange={(e, data) => {
                                      props.reUserStateOnChange(
                                        user.id,
                                        user.key,
                                        data.checked,
                                        true
                                      );
                                    }}
                                    iconStyle={{ fill: "white" }}
                                  />
                                </div>
                              );
                            })
                          )}
                        </>
                      )}

                      <hr size="1" />
                    </>
                  </>
                );
              })
          : props.reSubDepartmentList.map((res) => {
              return (
                <>
                  <div className="option_container">
                    {res.state == "minusSign" ? (
                      <div>
                        <input
                          type="checkbox"
                          id="minusbox"
                          className="minusCheckbox"
                          onChange={(e) => {
                            props.handleSubDepartCheckBox(
                              res.title,
                              e.target.checked
                            );
                          }}
                        />
                        <label for="minusbox" className="minusLabel">
                          -
                        </label>
                        <span style={{ fontSize: 14, marginLeft: 5 }}>
                          {res.title}
                        </span>
                      </div>
                    ) : (
                      <Checkbox
                        label={res.title}
                        checked={res.state}
                        className="checkBox"
                        onChange={(e, data) => {
                          props.handleSubDepartCheckBox(
                            res.title,
                            data.checked
                          );
                        }}
                      />
                    )}

                    <div
                      onClick={() => {
                        console.log("results", res.title);
                        props.reHandleImageClickDepartment(res.title);
                      }}
                    >
                      <img
                        src={
                          res.displayUserList ? DownArrowIcon : RightArrowIcon
                        }
                      />
                    </div>
                  </div>
                  {/* Users List  */}

                  {res.displayUserList && (
                    <>
                      {!props.reUsersList[res.title] ||
                      props.reUsersList[res.title] == undefined ? (
                        <div style={{ textAlign: "center", color: "#8F8F8F" }}>
                          No user found.
                        </div>
                      ) : (
                        props.reUsersList[res.title] &&
                        props.reUsersList[res.title] != undefined &&
                        props.reUsersList[res.title].length > 0 &&
                        props.reUsersList[res.title].map((user) => {
                          return (
                            <div className="user_container">
                              <Checkbox
                                label={user.name + ", " + user.key}
                                checked={user.state}
                                className="checkBox"
                                onChange={(e, data) => {
                                  props.reUserStateOnChange(
                                    user.id,
                                    user.key,
                                    data.checked
                                  );
                                }}
                                iconStyle={{ fill: "white" }}
                              />
                            </div>
                          );
                        })
                      )}
                    </>
                  )}

                  <hr size="1" />
                </>
              );
            })}
      </div>
    );
  };

  useEffect(() => {}, [searchValue]);

  return (
    <div
      className="incident_overview_container"
      style={{
        height: "auto",
        // overflow: "",
      }}
    >
      <div
        style={{
          overflowX: "hidden",
          height: "auto",
          // overflowY: "auto",
        }}
      >
        <div className="notisearch_bar">
          <div style={{ display: "flex", flexDirection: isMobile && "column" }}>
            <PlaceList
              custom_classes={`incident-place-list  incident-list-dropdown ${
                isMobile ? "mobile_srch" : "srch"
              }`}
              icon_classes="incident-place-list-icon"
              onChange={(e, data) => {
                if (search.length > 0) {
                  fetchFilter(data.value);
                }
                setSearchBy(data.value);
                setCurrentPage(1);
              }}
              value={searchBy}
              options={[
                {
                  value: "department",
                  text: "Department",
                },
                {
                  value: "user",
                  text: "User",
                },
              ]}
            />

            <form
              className="notisearch_bar_form"
              onSubmit={(e) => {
                e.preventDefault();
                setSearchValue(search);
                submitHandle(e);
              }}
            >
              <input
                type="text"
                style={{ width: "90%" }}
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  if (e.target.value.length == 0) {
                    props.fetchDefaultDepartment();
                    setLoading(false);
                    setShowSearchData(false);
                    setCurrentPage(1);
                    props.setCurrentAllSubDepartPage(1);
                    // props.refreshAllSubDepart();
                    // setSearchBy("department");
                  }
                  setSearch(e.target.value);
                }}
              />
              {search.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#008237",
                    fontSize: "small",
                    cursor: "pointer",
                    position: "absolute",
                    right: 30,
                    top: 10,
                    zIndex: 10,
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    props.fetchDefaultDepartment();
                    setLoading(false);
                    setShowSearchData(false);
                    setCurrentPage(1);
                    // setSearchBy("department");
                    props.setCurrentAllSubDepartPage(1);
                    setSearch("");
                  }}
                >
                  X
                </div>
              )}
              <img
                src={SearchIcon}
                className="notisearch_icon"
                onClick={(e) => {
                  e.preventDefault();
                  setSearchValue(search);
                  submitHandle(e);
                }}
              />
            </form>
          </div>
        </div>
        {allSubDepartmentCheckboxView()}
        {/* {Sidebar()} */}

        {/* {departmentList.map((depart) => {
          return departmentContainer(depart);
        })} */}
        {/* {departmentContainer()} */}
      </div>

      {/* Footer */}
      {console.log("reSubDepartmentList", props.reSubDepartmentList)}
      <div
        className="incident_footer"
        style={{
          bottom: -100,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          {props.overViewValues.is_emergency_incident == "Yes" ||
          props.showNotiPage ? (
            <div
              onClick={() => {
                props.setCheckStatus("Yes");
                props.mainSaveButton();
              }}
            >
              <Button className="witness_cancel_button">Submit </Button>
            </div>
          ) : null}
          {props.paramPendingBool && (
            <div>
              <Button
                className="incident_cancel_button"
                onClick={props.deletePendingItem}
              >
                Delete
              </Button>
            </div>
          )}
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              if (props.changedValue) {
                return confirmAlert({
                  customUI: ({ onClose }) =>
                    props.customModalForConfirmCancel(onClose),
                });
              } else {
                history.push("/incidents_list");
              }
            }}
          >
            <Button className="witness_cancel_button">Cancel</Button>
          </div>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              submitForm();
            }}
          >
            <Button className="witness_save_button">Save </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  subDeparts: state.incident.subDeparts,
  sub_depart_loading: state.incident.sub_depart_loading,
});
export default connect(mapStateToProps, null)(NotificationsList);
