import userAccountDetailsActionTypes from "./actionTypes/userAccountDetailActionTypes";
import api from "../../config/api";
import config from "../../config/config";
import moment from "moment";

const { GET_USER_DETAILS, GET_USER_AUDIT } = config.api_endpoint;

const {
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAIL,

  FETCH_USER_AUDIT,
  FETCH_USER_AUDIT_SUCCESS,
  FETCH_USER_AUDIT_FAIL,
} = userAccountDetailsActionTypes;

export const fetchUserDetailsRequest = () => {
  return {
    type: FETCH_USER_DETAILS,
  };
};

export const fetchUserDetailsSuccess = (data) => {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchUserDetailsFail = (error) => {
  return {
    type: FETCH_USER_DETAILS_FAIL,
    payload: error,
  };
};

export const fetchUserDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchUserDetailsRequest());
    return api
      .get(GET_USER_DETAILS, {
        id: id,
      })
      .then((res) => {
        dispatch(fetchUserDetailsSuccess(res));
      })
      .catch((err) => console.log("err", err));
  };
};

// fetch audit Lists
export const fetchUserAuditRequest = () => {
  return {
    type: FETCH_USER_AUDIT,
  };
};

export const fetchUserAuditSuccess = (data) => {
  return {
    type: FETCH_USER_AUDIT_SUCCESS,
    payload: data,
  };
};

export const fetchUserAuditFail = (error) => {
  return {
    type: FETCH_USER_AUDIT_FAIL,
    payload: error,
  };
};

export const fetchUserAudit = (id, page) => {
  return (dispatch) => {
    dispatch(fetchUserAuditRequest());

    return api
      .get(GET_USER_AUDIT, {
        platform: "web",
        user_id: id,
        page: page - 1,
        size: 10,
      })
      .then((res) => {
        dispatch(fetchUserAuditSuccess(res));
      })
      .catch((err) => console.log("err", err));
  };
};
