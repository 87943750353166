import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

//images
import ArrowDownDropDownIcon from "../assets/images/incident/arrowdowndrop.svg";

function IncidentPlacementList(obj) {
  // later need to retrieve
  let types = [
    {
      key: "All",
      text: "All",
      value: "All",
    },
  ];

  return (
    <Dropdown
      search
      selection
      options={obj.options}
      // defaultValue="All"
      value={obj.value}
      icon={<img src={ArrowDownDropDownIcon} className={obj["icon_classes"]} />}
      className={obj["custom_classes"]}
      onChange={obj.onChange}
    />
  );
}

export default IncidentPlacementList;
