import React, { useState, useEffect } from "react";
import { Button, Input } from "semantic-ui-react";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../../routes";
import { useHistory } from "react-router-dom";

//css
import "./cases.css";

function IncidentSummary(props) {
  const values = props.summaryValues;
  const onChangeIncidentSummary = props.onChangeIncidentSummary;
  const { titleError, remarkError } = props.summaryInputError;
  const previousIncident = props.previousIncident;
  const onListenValueChange = props.onListenValueChange;

  const checkPreviousValueAndCurrent = (previous, current, key) => {
    let splitUnderScore = key.replace("_", " ");

    if (previous != current) {
      onListenValueChange(
        key,
        `Update ${splitUnderScore}  from ${previous} to ${current}`
      );
    } else {
      onListenValueChange(key, null);
    }
  };

  return (
    <div className="incident_overview_container">
      <div>
        <h3 style={{ color: titleError && "#E72D35" }}>
          Incident Title* ( Max 100 Characters Only )
        </h3>
        <Input
          fluid
          className={`damaged_input ${titleError && "error"}`}
          value={values.incident_title}
          maxLength="100"
          onChange={(e) => {
            onChangeIncidentSummary("incident_title", e.target.value);
            checkPreviousValueAndCurrent(
              previousIncident.case_details.incident_description.incident_title,
              e.target.value,
              "incident_title"
            );
          }}
        />
      </div>
      {titleError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          Required field
        </div>
      )}
      <h3 style={{ color: remarkError && "#E72D35" }}>Incident Details*</h3>
      <textarea
        className={`remark_text_area ${remarkError && "error"}`}
        value={values.incident_remarks}
        onChange={(e) => {
          onChangeIncidentSummary("incident_remarks", e.target.value);
          checkPreviousValueAndCurrent(
            previousIncident.case_details.incident_description.incident_remarks,
            e.target.value,
            "incident_detail"
          );
        }}
      ></textarea>
      {remarkError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          Required field
        </div>
      )}
      {/* Footer */}
    </div>
  );
}

export default IncidentSummary;
