import React, { Component } from "react";
import { Link } from "react-router-dom";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    console.log("error ---------", error);
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "80vh",
          }}
        >
          <h1
            style={{
              color: "#008237",
            }}
          >
            Something went wrong.
          </h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              window.history.go(-1);
              this.setState({
                hasError: false,
              });

              // window.location.href = "/incidents_list?index=1";

              // window.history.go(-1);
              // setTimeout(() => {
              //   window.location.reload();
              // }, 50);
              // return false;
            }}
          >
            <input
              action="action"
              type="submit"
              value="Go Back"
              style={{
                color: "blue",
                textDecoration: "underline",
                background: "none",
                outline: "none",
                border: "none",
                cursor: "pointer",
              }}
            />
          </form>

          {/* <div
            onClick={() => {
              window.history.go(-1);
              setTimeout(() => {
                window.location.reload();
              }, 0);
            }}
            style={{ color: "blue", textDecoration: "underline" }}
          >
            Go Back
          </div> */}
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
