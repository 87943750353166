import React, { useState, useEffect } from "react";
import { Input, Loader } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import {
  getUserLoginDetail,
  getuserloginDetailFail,
  getuserloginDetailSuccess,
} from "../../store/actions/loggedInUserDetailAction";
import { useLocation, useHistory } from "react-router-dom";
//images
import ViewPassword from "../../assets/images/user/ic_password_view.svg";
import UnViewPassword from "../../assets/images/user/ic_password_unview.svg";
import { Link } from "react-router-dom";
import { auth } from "../../config/firebase";

import config from "../../config/config";
import axios from "axios";
import ArrowLeftIcon from "../../assets/images/incident/leftarrow.svg";
import { DASHBOARD_ROUTE } from "../../routes";
import api from "../../config/api";
import { browserName } from "react-device-detect";

function LoginLink(props) {
  // const microsoftProvider = new firebase.auth().OAuthProvider("microsoft.com");
  const { SETTINGS, SAVE_USER, SEND_EMAIL, SAVE_USER_AUDIT, GET_USER_DETAILS } =
    config.api_endpoint;
  const { url } = config;
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramDisableError = query.get("disableerror");
  const resetParam = query.get("reset");
  const minusError = query.get("minusError");

  // password input type
  const [newPasswordState, setnewPasswordState] = useState({
    type: "password",
  });
  const [logInWrong, setLogInWrong] = useState(0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [disableError, setDisableError] = useState("");
  const [successReset, setSuccessReset] = useState("");
  const [loading, setLoading] = useState(false);
  const [usernameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [err, setErr] = useState(props.credential && props.credential);
  let history = useHistory();
  const [errorAlert] = useState(false);

  // show/hide icon
  const [beforeNPIcon, setbeforeNPIcon] = useState(UnViewPassword);
  const [attempts, setAttempts] = useState(null);

  const login = (e) => {
    e.preventDefault();
    setError("");
    if (password == "") {
      return setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    setLoading(true);
    var pendingCred = err.credential;
    var email = err.email;
    auth
      .signInWithEmailAndPassword(email, password)
      .then(function (result) {
        return result.user.linkWithCredential(pendingCred);
      })
      .then(async function (res) {
        setLoading(true);
        let accessToken = err.credential.accessToken;
        const AuthStr = "Bearer ".concat(accessToken);
        await axios
          .get(
            url +
              GET_USER_DETAILS +
              "?access_token=" +
              accessToken +
              "&platform=web",
            {
              headers: {
                Authorization: AuthStr,
              },
            }
          )
          .then((res) => {
            console.log(res);
            props.getuserloginDetailSuccess(res.data);
            setSuccessReset("");
            setLoading(false);
            const date = moment().add(7, "d").toDate();
            localStorage.setItem("authExpire", date);
            props.setLoggedIn(true);
            history.push(DASHBOARD_ROUTE);
            setError("");
            api
              .post(
                SAVE_USER_AUDIT,
                JSON.stringify({
                  user_id: res.data.data.id,
                  email: res.data.data.email,
                  changed_by: res.data.data.id,
                  description: [`Login on ${browserName}`],
                  platform: "web",
                }),
                {
                  headers: { "Content-Type": "application/json" },
                }
              )
              .then((res) => {
                setLoading(false);
              });
          })
          .catch((err) => {
            props.getuserloginDetailFail();
            setLoading(false);
          });
      })
      .catch((err) => {
        setSuccessReset("");
        setLoading(false);
        if (err.code) {
          setLogInWrong(logInWrong + 1);
          setError("User doesn't exist");
        }

        if (err.code == "auth/user-not-found") {
          setError("User doesn't exist");
        }
        if (err.code == "auth/invalid-email") {
          setError("Invalid email");
        }
        if (err.code == "auth/wrong-password") {
          setError("Invalid password");
        }
      });
  };

  useEffect(() => {
    localStorage.removeItem("loginLink");
    if (paramDisableError) {
      setDisableError("Your account is disabled");
    }
    if (resetParam) {
      setSuccessReset("Successfully changed password.");
    }
  }, []);

  useEffect(() => {
    axios
      .get(url + SETTINGS)
      .then((res) => {
        if (res.data.message == "Success") {
          setAttempts(res.data.data.failed_login_attempts);
        }
      })
      .catch((err) => console.log("err", err));
  }, []);

  useEffect(() => {
    if (logInWrong == attempts) {
      console.log("attempts", {
        email: username,
        is_active: 0,
        is_new: 0,
        platform: "web",
      });
      setLoading(true);

      axios
        .post(url + SAVE_USER, {
          email: username,
          is_active: 0,
          is_new: 0,
          platform: "web",
          is_disable_acc: true,
        })
        .then((result) => {
          setLogInWrong(0);
          setDisableError("Your account is disabled");
          setLoading(false);
          axios
            .post(url + SEND_EMAIL, {
              platform: "web",
              email: username,
              mail_type: "locked_acc",
            })
            .then((res) => console.log("lock res ", res))
            .catch((err) => console.log("err", err));
        })
        .catch((err) => {
          setLoading(false);
          setLogInWrong(0);
        });
    }
  }, [logInWrong]);

  useEffect(() => {
    setUsername(username.replace(/\s/g, ""));
  }, [username]);

  function AttributeValidationUsername(e) {
    setUsername(e.target.value.replace(/\s/g, ""));
  }

  useEffect(() => {
    setPassword(password.replace(/\s/g, ""));
  }, [password]);

  function AttributeValidationPassword(e) {
    setPassword(e.target.value.replace(/\s/g, ""));
  }

  return (
    <form className="login_form" style={{ zIndex: errorAlert ? 99 : 9999999 }}>
      {/* <div
        style={{
          fontWeight: "bold",
          marginBottom: 30,
          color: "#008237",
          fontSize: 18,
          display: "flex",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => history.goBack()}
      >
        <img src={ArrowLeftIcon} style={{ marginRight: 18 }} />
        <span style={{ color: "#008237", fontSize: 18 }}>MWG Login</span>
      </div> */}
      <div style={{ marginBottom: "30px", marginTop: 30 }}>
        You have an existing MWG IMS account. You will need to link your MWG
        account with your Azure account before you can proceed to login. Please
        key in the password of your MWG IMS account.
      </div>

      {resetParam && (
        <div
          style={{
            color: "#42BE5C",
            textAlign: "center",
            marginBottom: 5,
          }}
        >
          {successReset}
        </div>
      )}
      {minusError ? (
        <div
          style={{
            color: "#E72D35",
            textAlign: "center",
            marginBottom: 5,
          }}
        >
          Your account is suspended.
        </div>
      ) : disableError ? (
        <div
          style={{
            color: "#E72D35",
            textAlign: "center",
            marginBottom: 5,
          }}
        >
          {disableError}
        </div>
      ) : error ? (
        <div
          style={{
            color: "#E72D35",
            textAlign: "center",
            marginBottom: 5,
          }}
        >
          You have entered an incorrect email or password. Please note that both
          fields are case-sensitive.
        </div>
      ) : null}

      <div style={{ marginBottom: 30 }}>
        <div style={{ fontSize: 13, fontWeight: "bold", marginBottom: 5 }}>
          Account
        </div>
        <div className={`damaged_input`}>{err.email} </div>
        {usernameError && (
          <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
            This is a required field.
          </div>
        )}
      </div>
      <div style={{ marginBottom: 30 }}>
        <div style={{ fontSize: 13, fontWeight: "bold", marginBottom: 5 }}>
          Password
        </div>
        <div
          className={`ui input password-div-login ${passwordError && "error"}`}
        >
          <Input
            type={newPasswordState}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={AttributeValidationPassword}
            fluid
            className={`user_new_pass-login  ${passwordError && "error"}`}
          />

          <i
            className="icon password-icon only"
            style={{ position: "relative", left: -5, top: 3 }}
          >
            <img
              src={beforeNPIcon}
              onClick={() => {
                if (newPasswordState === "text") {
                  setnewPasswordState("password");
                  setbeforeNPIcon(UnViewPassword);
                } else {
                  setnewPasswordState("text");
                  setbeforeNPIcon(ViewPassword);
                }
              }}
            />
          </i>
        </div>
        {passwordError && (
          <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
            This is a required field.
          </div>
        )}
      </div>
      <div className="login_btn" style={{ fontWeight: "bolder" }}>
        {loading || props.AuthLoading ? (
          <Loader active inline="centered" />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => history.goBack()}
              style={{
                background: "white",
                color: "#008237",
                width: "48%",
                border: "1px solid #008237",
              }}
            >
              Cancel
            </button>
            <button onClick={login} style={{ width: "48%" }}>
              Link Account
            </button>
          </div>
        )}
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({
  AuthLoading: state.loggedInUserDetail.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getUserLoginDetail: (id) => dispatch(getUserLoginDetail(id)),
  getuserloginDetailSuccess: (res) => dispatch(getuserloginDetailSuccess(res)),
  getuserloginDetailFail: () => dispatch(getuserloginDetailFail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginLink);
