import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Button } from "semantic-ui-react";
import Geocode from "react-geocode";
import { Link } from "react-router-dom";
import MapFullLocation from "./MapFullScreen";

// images
import ExitIcon from "../../../../assets/images/incident/exit.svg";
import RefreshIcon from "../../../../assets/images/incident/refresh.svg";
import FullScreenIcon from "../../../../assets/images/incident/fullscreen.svg";

const gApi = "AIzaSyCsvLI--q5rNFAGwq0Cgr7IWkh6y11x1wQ";
Geocode.setApiKey(gApi);
Geocode.setLanguage("en");
Geocode.enableDebug();

const AnyReactComponent = ({ text, lat, lng, handleMarkerClick }) => (
  <div onClick={() => handleMarkerClick()}>
    <div
      style={{
        position: "relative",
        background: "#D0021B",
        color: "white",
        width: "130px",
        height: "30px",
        lineHeight: "30px",
        fontWeight: "bold",
        textAlign: "center",
        borderRadius: 12,
        top: -50,
        left: -60,
        fontSize: 13,
      }}
    >
      Current Location
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: "15px solid transparent",
          borderRight: " 15px solid transparent",
          borderTop: " 15px solid #D0021B",
          position: "absolute",
          left: 45,
        }}
      ></div>
    </div>
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: 36,
          height: 36,
          background: "rgba(208, 2, 27, 0.3)",
          borderRadius: 50,
          position: "absolute",
          top: -40,
          left: -18,
          zIndex: -1,
        }}
      ></div>
      <div
        style={{
          width: 20,
          height: 20,
          background: "#D0021B",
          borderRadius: 50,
          position: "absolute",
          left: -10,
          top: -33,
        }}
      ></div>
    </div>
  </div>
);

export class MapLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: null,
      lng: null,
      currentLocation: { lat: 0, lng: 0 },
    };
  }

  handleMarkerClick() {}
  refreshCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.successLocation,
        this.errorHandler,
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 60000 }
      );
    } else {
      console.log("navigator not supported");
      alert("navigator not supported");
    }
  };

  onChangeLatLngFullScreen = (lat, lng) => {
    this.setState({
      lat,
      lng,
      currentLocation: {
        lat,
        lng,
      },
    });
  };

  successLocation = (position) => {
    this.setState({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      currentLocation: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
    });
    Geocode.fromLatLng(
      position.coords.latitude,
      position.coords.longitude
    ).then(
      (response) => {
        const address = response.results[0].formatted_address;
        this.props.onChangeMainZone({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        this.props.onChangeSelectedAddress(address);
        this.props.onChangeZoneType("Other: Pin current location");
      },
      (error) => {
        console.error(error);
      }
    );
  };

  errorHandler = (e) => {
    console.log(e);
  };

  componentDidMount() {
    this.props.onChangeSubZone("");

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.successLocation,
        this.errorHandler,
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 600000 }
      );
    } else {
      console.log("navigator not supported");
      alert("navigator not supported");
    }
  }

  onChangeLocation = (lat, lng) => {
    this.setState({
      lat,
      lng,
      currentLocation: { lat, lng },
    });
  };

  render() {
    return (
      <>
        {this.props.showFullScreenLocation ? (
          <MapFullLocation
            hideFullScreen={this.props.hideFullScreen}
            onChangeLatLngFullScreen={this.onChangeLatLngFullScreen}
            onChangeZoneType={this.props.onChangeZoneType}
            onChangeMainZone={this.props.onChangeMainZone}
            onChangeSelectedAddress={this.props.onChangeSelectedAddress}
            locationState={{
              lat: this.state.lat,
              lng: this.state.lng,
              currentLocation: this.state.currentLocation,
            }}
            onChangeLocation={this.onChangeLocation}
          />
        ) : (
          <div style={{ padding: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              onClick={this.props.close}
            >
              <img src={ExitIcon} />
            </div>
            <h3>Zone</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 25,
                alignItems: "center",
              }}
            >
              <div> Other: Pin Current Location</div>
              <div
                onClick={() => this.props.showFullScreenFunction()}
                style={{
                  position: "absolute",
                  right: 20,
                  top: 180,
                  zIndex: 999999,
                }}
              >
                {" "}
                <img
                  src={FullScreenIcon}
                  width="20px"
                  height="20px"
                  color="grey"
                />
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.refreshCurrentLocation();
                }}
              >
                <img src={RefreshIcon} />
              </div>
            </div>
            <div style={{ height: "300px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: gApi,
                }}
                options={{
                  fullscreenControl: false,
                }}
                center={this.state.currentLocation}
                zoom={16}
                yesIWantToUseGoogleMapApiInternals
                onClick={(e) => {
                  const pos = { lat: e.lat, lng: e.lng };
                  this.props.onChangeMainZone({
                    lat: e.lat,
                    lng: e.lng,
                  });
                  this.props.onChangeZoneType("Other: Pin current location");
                  this.setState({
                    lat: e.lat,
                    lng: e.lng,
                    currentLocation: pos,
                  });
                  Geocode.fromLatLng(e.lat, e.lng).then(
                    (response) => {
                      const address = response.results[0].formatted_address;
                      this.props.onChangeMainZone({
                        lat: e.lat,
                        lng: e.lng,
                      });
                      this.props.onChangeSelectedAddress(address);
                      this.props.onChangeZoneType(
                        "Other: Pin current location"
                      );
                    },
                    (error) => {
                      console.error(error);
                    }
                  );
                }}
              >
                <AnyReactComponent
                  lat={this.state.lat}
                  lng={this.state.lng}
                  text="Current Location"
                  handleMarkerClick={this.handleMarkerClick}
                />
              </GoogleMapReact>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 25,
              }}
            >
              <div
                onClick={() => {
                  this.props.close();
                  this.props.onChangeMainZone("");
                  this.props.onChangeZoneType("");
                }}
              >
                <Button className="witness_cancel_button">Cancel</Button>
              </div>
              <div>
                <Button
                  className="witness_save_button"
                  onClick={() => {
                    this.props.close();
                  }}
                >
                  Save{" "}
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default MapLocation;
