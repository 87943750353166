import React, { useState, useEffect } from "react";
import { Input, TextArea } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";

//images
import CalendarIcon from "../../../../assets/images/incident/ic_calendar.svg";

//css
import "./forms.css";

function UserAccount(props) {
  const [fromDate, setFromDate] = useState(null);
  const [lastLogin, setLastLogin] = useState(null);
  const values = props.values;

  const onChangeProfileView = props.onChangeProfileView;
  useEffect(() => {
    if (values) {
      if (values.account_expiry) {
        setFromDate(new Date(values.account_expiry));
      }
      if (values.last_login_at == "Not logged in yet") {
        setLastLogin("Not logged in yet.");
      } else {
        const data = moment
          .utc(values.last_login_at)
          .local("SG")
          .format("DD MMM YYYY,");

        const time = moment
          .utc(values.last_login_at)
          .local("SG")
          .format("HH:mm:ss");
        setLastLogin(data + "  " + time);
      }
    }
  }, []);

  return (
    <div className="useraccount_container">
      <h3>Account Created </h3>
      <Input
        fluid
        className="user_account_created disabled"
        value={
          values.created_at &&
          moment(values.created_at).format("DD MMM YYYY, HH:MM:SS")
        }
        disabled
      />

      <h3>Last Login </h3>
      <Input
        fluid
        className="user_last_login disabled"
        value={lastLogin && lastLogin}
        disabled
      />

      <h3>Set Account Expiry</h3>
      <div className="sidebar_datePicker">
        <DatePicker
          selected={fromDate}
          dateFormat="yyyy-MM-dd"
          readOnly={props.is_active == -1 ? true : false}
          onChange={(date) => {
            onChangeProfileView(
              "account_expiry",
              moment(date).format("YYYY-MM-DD")
            );
            setFromDate(date);
          }}
          onChangeRaw={(e) => e.preventDefault()}
        />
        <img src={CalendarIcon} />
      </div>
      {/* {props.dateError && (
        <div className="input_error_text">Date is invalid.</div>
      )} */}
    </div>
  );
}

export default UserAccount;
