import React from "react";
import { Button } from "semantic-ui-react";
import { INCIDENTS_AUDIT_ROUTE } from "../routes";
import { useHistory, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

function PendingApprovalFooter(props) {
  let history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramId = query.get("id");

  let filterP6 = props.perms.some((p) => p == "p6");
  let filterP7 = props.perms.some((p) => p == "p7");
  let filterP8 = props.perms.some((p) => p == "p8");
  let filterP16 = props.perms.some((p) => p == "p16");

  function Cat2To4() {
    return (
      <div
        style={{ width: "100%" }}
        onClick={() => {
          props.setCheckStatus("Closed");
          props.mainSaveButton();
        }}
      >
        <Button
          className="incident_cancel_button"
          style={{ width: "fit-content" }}
        >
          Close Incident
        </Button>
      </div>
    );
  }

  function ApproveCat() {
    return (
      <>
        {console.log(props.authDetail.role === "super_user")}
        {console.log(props)}
        {props.authDetail &&
        props.authDetail.role &&
        props.authDetail.role != "super_admin" ? (
          props.created_by == props.authDetail.id ? (
            <></>
          ) : props.authDetail.role === "super_user" ? (
            <></>
          ) : (
            <div
              style={{ width: "100%" }}
              onClick={() => {
                props.setCheckStatus("Approve");
                props.mainSaveButton();
              }}
            >
              <Button className="witness_cancel_button">Approve </Button>
            </div>
          )
        ) : (
          <div
            style={{ width: "100%" }}
            onClick={() => {
              props.setCheckStatus("Approve");
              props.mainSaveButton();
            }}
          >
            <Button className="witness_cancel_button">Approve </Button>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="incident_footer" style={{ bottom: 0 }}>
      <div style={{ display: "flex", width: 400 }}>
        {/* <div
          style={{ width: "100%" }}
          onClick={() => {
            props.setCheckStatus("Archive");
            props.mainSaveButton();
          }}
        >
          <Button className="incident_archive_button">Archive</Button>
        </div> */}
        {/* {props.showNotiPage && (
          <div
            style={{ width: "100%" }}
            onClick={() => {
              props.setCheckStatus(null);
              props.mainSaveButton();
            }}
          >
            <Button className="witness_cancel_button">Submit </Button>
          </div>
        )} */}
        {filterP6 && props.prevCategory == "c1" && <>{Cat2To4()}</>}
        {filterP7 && props.prevCategory == "c2" && <>{Cat2To4()}</>}
        {filterP7 && props.prevCategory == "c3" && <>{Cat2To4()}</>}
        {filterP7 && props.prevCategory == "c4" && <>{Cat2To4()}</>}
        {filterP8 &&
          props.prevCategory != "c1" &&
          props.authDetail &&
          props.authDetail.role &&
          (props.authDetail.role != "super_admin" ? (
            props.created_by == props.authDetail.id ? (
              <></>
            ) : props.authDetail.role === "super_user" ? (
              <></>
            ) : (
              <div
                style={{ width: "100%" }}
                onClick={() => {
                  props.setCheckStatus("Reject");
                  props.mainSaveButton();
                }}
              >
                <Button className="reject_button">Reject </Button>
              </div>
            )
          ) : (
            <div
              style={{ width: "100%" }}
              onClick={() => {
                props.setCheckStatus("Reject");
                props.mainSaveButton();
              }}
            >
              <Button className="reject_button">Reject </Button>
            </div>
          ))}
        {filterP8 && props.prevCategory == "c2" && <>{ApproveCat()}</>}
        {filterP8 && props.prevCategory == "c3" && <>{ApproveCat()}</>}
        {filterP8 && props.prevCategory == "c4" && <>{ApproveCat()}</>}
      </div>
      <div style={{ display: "flex" }}>
        {filterP16 && (
          <div
            className="view_audit"
            onClick={() =>
              history.push(`${INCIDENTS_AUDIT_ROUTE}?id=${paramId}`)
            }
          >
            View Audit Trail
          </div>
        )}
        <div style={{ width: "100%" }} onClick={() => {}}>
          <Button
            className="witness_cancel_button"
            onClick={() => {
              if (
                props.valueChanged == null ||
                props.valueChanged == undefined
              ) {
                return history.goBack();
              } else {
                if (Object.values(props.valueChanged)[0] == null) {
                  history.push("/incidents_list");
                  // history.goBack();
                } else {
                  if (Object.values(props.valueChanged).length > 0) {
                    return confirmAlert({
                      customUI: ({ onClose }) =>
                        props.customModalForConfirmCancel(onClose),
                    });
                  } else {
                    history.push("/incidents_list");
                    // history.goBack();
                  }
                }
              }
            }}
          >
            Cancel
          </Button>
        </div>
        <div style={{ width: "100%" }} onClick={props.mainSaveButton}>
          <Button className="witness_save_button">Save </Button>
        </div>
      </div>
    </div>
  );
}

export default PendingApprovalFooter;
