import React from "react";
import { Route } from "react-router";
import { GROUP_LIST_ROUTE, VIEW_GROUP_ROUTE } from "../../routes";
import GroupList from "./groupList";
import "./groupList.css";
import ViewGroup from "./viewGroup";

export const index = () => (
  <React.Fragment>
    <Route exact path={GROUP_LIST_ROUTE} component={GroupList} />
    <Route exact path={VIEW_GROUP_ROUTE} component={ViewGroup} />
  </React.Fragment>
);
export default index;