import userAccountActionTypes from "../actions/actionTypes/userAccountActionTypes";

const {
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAIL,
} = userAccountActionTypes;

const initialState = {
  error: null,
  userLists: [],
  loading: false,
  nextUrl: null,
  total_pages: 0,
};

const userAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_USERS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        nextUrl: action.payload.next,
        userLists: action.payload.data,
        total_pages: action.payload.total_pages,
      };
    case FETCH_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export default userAccountReducer;
