import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Table, Pagination, Loader } from "semantic-ui-react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { connect } from "react-redux";
import { fetchUserAudit } from "../../../store/actions/userAccountDetailAction";

import "./userAudit.css";

//images
import LeftArrowIcon from "../../../assets/images/incident/leftarrow.svg";
import ExitIcon from "../../../assets/images/incident/exit.svg";
import moment from "moment";

function UserAudit(props) {
  const history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const userId = query.get("id");
  const userName = query.get("name");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    props.fetchUserAudit(userId, currentPage);
    setCurrentPage(1);
  }, [userId]);

  const handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage;
    setCurrentPage(page);
  };

  useEffect(() => {
    props.fetchUserAudit(userId, currentPage);
  }, [currentPage]);

  return (
    <div className="user_audit_container">
      <div>
        <div
          to=""
          className="user_audit_header_leftside"
          onClick={() => history.goBack()}
        >
          <img src={LeftArrowIcon} style={{ marginTop: 0 }} />
          <div className="user_audit_header_title">View Audit Trail</div>
        </div>
      </div>

      {/* List */}
      <div className="user_audit_list">
        <h3>Audit Trail for {userName}</h3>
        <Table basic>
          {/* Header */}
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Timestamp</Table.HeaderCell>
              <Table.HeaderCell width={3}>Changed By</Table.HeaderCell>
              <Table.HeaderCell width={7}>
                Description of Change
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>Details</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {console.log("list", props.auditList)}
          {/* Body */}
          {props.auditLoading ? (
            <div
              style={{
                height: 60,
                display: "flex",
                alignItems: "center",
                width: "96%",
                padding: 10,

                position: "absolute",
              }}
            >
              <Loader active inline="centered" />
            </div>
          ) : (
            <Table.Body>
              {props.auditList.length == 0 ? (
                <div
                  style={{
                    height: 60,
                    display: "flex",
                    alignItems: "center",
                    width: "96%",
                    padding: 10,
                    backgroundColor: "white",
                    position: "absolute",
                  }}
                >
                  No data found
                </div>
              ) : (
                props.auditList.map((a) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        {" "}
                        {moment
                          .utc(a.timestamp)
                          .local("SG")
                          .format("D MMM YYYY,")}
                        {"  "}
                        {moment.utc(a.timestamp).local("SG").format("HH:mm")}
                      </Table.Cell>
                      <Table.Cell>{a.changed_by_name}</Table.Cell>
                      <Table.Cell>
                        {a.description.map((d) => {
                          return <p>{d}</p>;
                        })}
                      </Table.Cell>
                      <Table.Cell>
                        <Popup
                          modal
                          nested
                          trigger={
                            <Button className="user_audit_btn">
                              View Details
                            </Button>
                          }
                        >
                          {(close) => {
                            return (
                              <div className="user_audit_popup">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <img src={ExitIcon} onClick={close} />
                                </div>
                                <div>
                                  <h3 className="user_audit_header_title">
                                    Details
                                  </h3>
                                  <div className="user_audit_detail_content">
                                    <div
                                      style={{
                                        borderBottom: "1px solid #d8d8d8",
                                      }}
                                    >
                                      <div className="user_audit_header">
                                        <div className="user_audit_name">
                                          {a.changed_by_name}
                                        </div>
                                        <div className="user_audit_dot"></div>
                                        <div>{a.role.replace("_", " ")}</div>
                                      </div>
                                      <div className="user_audit_timestamp">
                                        {moment
                                          .utc(a.timestamp)
                                          .local("SG")
                                          .format("D MMM YYYY,")}
                                        {"  "}
                                        {moment
                                          .utc(a.timestamp)
                                          .local("SG")
                                          .format("HH:mm")}
                                      </div>
                                    </div>
                                    <div className="user_audit_description">
                                      <div className="user_audit_description_title ">
                                        Description of Change
                                      </div>
                                      <div className="user_audit_description_content">
                                        {a.description.map((des) => {
                                          return (
                                            <div className="user_audit_description_name">
                                              {des}
                                            </div>
                                          );
                                        })}
                                        {/* <div className="description_name">
                                        First name defined as <span>Herry</span>
                                      </div>
                                      <div className="description_name">
                                        Contact details of casualty defined as{" "}
                                        <span>”903778468”</span>
                                      </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                        </Popup>
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              )}
            </Table.Body>
          )}
        </Table>
        {props.auditList.length > 0 && (
          <Pagination
            activePage={currentPage}
            firstItem={null}
            lastItem={null}
            siblingRange={3}
            floated="right"
            totalPages={props.total_pages}
            onPageChange={handlePaginationChange}
            style={{ opacity: props.auditLoading ? 0 : 1 }}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auditList: state.userAudit.auditLists,
  auditLoading: state.userAudit.loading,
  nexturl: state.userAudit.nexturl,
  total_pages: state.userAudit.total_pages,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserAudit: (id, page) => dispatch(fetchUserAudit(id, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAudit);
