import userAuditActionTypes from "../actions/actionTypes/userAccountDetailActionTypes";

const {
  FETCH_USER_AUDIT,
  FETCH_USER_AUDIT_SUCCESS,
  FETCH_USER_AUDIT_FAIL,
} = userAuditActionTypes;

const initialState = {
  error: null,
  auditLists: [],
  loading: true,
  nextUrl: null,
  total_pages: 0,
};

const userAuditReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_AUDIT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_AUDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        nextUrl: action.payload.next,
        auditLists: action.payload.data,
        total_pages: action.payload.total_pages,
      };
    case FETCH_USER_AUDIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export default userAuditReducer;
