import React, { useState, useEffect } from "react";
import Incident_detail_dropdown from "../../../../../components/incident_detail_dropdown";
import DatePicker from "react-datepicker";
import { Button, Input, Checkbox, Dropdown } from "semantic-ui-react";
import MapLocation from "./MapLocation";
import { INCIDENTS_AUDIT_ROUTE } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import PlaceList from "../../../../../components/PlaceList";
import ZoneList from "../../../../../components/ZoneList";
import TypeList from "../../../../../components/TypeList";
import moment from "moment";
import { db } from "../../../../../config/firebase";

//images
import CalendarIcon from "../../../../../assets/images/incident/ic_calendar.svg";
import TimeIcon from "../../../../../assets/images/incident/ic_time.svg";
import ArrowDown from "../../../../../assets/images/incident/arrowdowndrop.svg";
import LeftArrowIcon from "../../../../../assets/images/incident/leftarrow.svg";
import TramStation from "../../../../../assets/images/incident/tramstation.svg";
import ShowArea from "../../../../../assets/images/incident/showarea.svg";
import TreeTrail from "../../../../../assets/images/incident/treetrail.svg";
import GreatRift from "../../../../../assets/images/incident/greatrift.svg";
import Kangaroo from "../../../../../assets/images/incident/kangaroo.svg";
import Lake from "../../../../../assets/images/incident/lake.svg";
import Monkey from "../../../../../assets/images/incident/monkey.svg";
import KidsWorld from "../../../../../assets/images/incident/kidsworld.svg";
import CurrentLocation from "../../../../../assets/images/incident/currentlocation.svg";
import TypeLocation from "../../../../../assets/images/incident/typelocation.svg";

const IncidentLocation = (props) => {
  let history = useHistory();

  const { hideShowZoneList, showShowZoneList, showZoneDropDown } = props;
  const [showFullScreenLocation, setShowFullScreenLocation] = useState(false);
  const [filterZoneList, setFilterZoneList] = useState([]);
  const [parkListLoader, setParkListLoader] = useState(false);
  const [conditionAreaList, setConditionAreaList] = useState([]);
  const [weatherList, setWeatherList] = useState([]);
  const [subZoneList, setSubZoneList] = useState([]);
  const [showSubZoneList, setShowSubZoneList] = useState(false);

  const showFullScreenFunction = () => setShowFullScreenLocation(true);
  const hideFullScreen = () => setShowFullScreenLocation(false);

  const previousIncident = props.previousIncident;
  const onListenValueChange = props.onListenValueChange;

  const checkPreviousValueAndCurrent = (previous, current, key) => {
    let splitUnderScore = key.replace("_", " ");

    if (previous != current) {
      onListenValueChange(
        key,
        `Update ${splitUnderScore}  from ${previous} to ${current}`
      );
    } else {
      onListenValueChange(key, null);
    }
  };

  const weathers = [
    {
      value: "Sunny",
      text: "Sunny",
      key: "Sunny",
    },
    {
      value: "Cloudy",
      text: "Cloudy",
      key: "Cloudy",
    },
    {
      value: "Light Rain",
      text: "Light Rain",
      key: "Light Rain",
    },
    {
      value: "Heavy Rain",
      text: "Heavy Rain",
      key: "Heavy Rain",
    },
    {
      value: "Hazy",
      text: "Hazy",
      key: "Hazy",
    },
    {
      value: "Others",
      text: "Others",
      key: "Others",
    },
  ];

  const condition_areas = [
    "No Hazard",
    "Wet Floor",
    "Algae",
    "Uneven",
    "Floor",
    "Damages",
  ];

  useEffect(() => {
    setParkListLoader(true);
    db.collection("static_data")
      .doc("parks")
      .get()
      .then((querySnapshot) => {
        let parkData = [];
        let zoneData = [];
        querySnapshot.data().parks_arr.forEach((doc, index) => {
          parkData.push({
            key: index,
            text: doc.park,
            value: doc.park,
          });
        });
        querySnapshot.data().parks_arr.forEach((doc, index) => {
          doc.mainzone.map((z, index) => {
            zoneData.push({
              id: index,
              title: z.title,
              img: z.img_url,
              key: doc.park,
              sub_zone: z.sub_zone,
            });
          });
        });

        setParkListLoader(false);
        setZoneList(zoneData); // all Zone List
        setParkList(parkData);
        setFilterZoneList(
          zoneList.filter((z) => z.key == props.locationValues.park)
        );
      })
      .catch((err) => {
        // setTypeListLoader(false);
        console.log("err", err);
      });
    db.collection("static_data")
      .doc("area_condition")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().area_condition_arr.forEach((doc, index) => {
          data.push({
            key: doc,
            text: doc,
            value: doc,
          });
        });

        setConditionAreaList(data);
      });

    db.collection("static_data")
      .doc("weather")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().weather_arr.forEach((doc, index) => {
          data.push({
            value: doc,
            text: doc,
            key: doc,
          });
        });

        setWeatherList(data);
      });
  }, []);

  useEffect(() => {
    setFilterZoneList(
      zoneList.filter((z) => z.key == props.locationValues.park)
    );
  }, [props.locationValues]);

  const values = props.locationValues;
  const onChangeIncidentLocation = props.onChangeIncidentLocation;
  const { parkError, zoneError, weatherError, areaError } =
    props.locationInputError;
  const onChangeIncidentAddressZone = props.onChangeIncidentAddressZone;
  const { selectedDate, selectedTime } = props.locationDate;
  const onChangeLocationDateTime = props.onChangeLocationDateTime;
  const onChangeSubZone = props.onChangeSubZone;
  const onChangeZoneType = props.onChangeZoneType;
  const selectedAddress = props.selectedAddress;
  const mainZone = props.mainZone;
  const { parkList, setParkList, zoneList, setZoneList } = props.optionsState;

  useEffect(() => {
    // if (localStorage.getItem("lat") && localStorage.getItem("lng")) {
    //   setCurrnetLocation({
    //     lat: localStorage.getItem("lat"),
    //     lng: localStorage.getItem("lng"),
    //   });
    // }
  }, []);

  return (
    <div
      className="incident_overview_container"
      onClick={() => hideShowZoneList()}
    >
      {/* Park  */}
      <h3 className={`dropdown_title ${parkError && "error"}`}>Park* </h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown ${
          parkError && "error"
        }`}
        icon_classes="incident-place-list-icon"
        value={values.park}
        onClick={hideShowZoneList}
        onChange={(e, data) => {
          setFilterZoneList(zoneList.filter((z) => z.key == data.value));
          props.onChangeMainZone("");
          props.onChangeZoneType("");
          onChangeIncidentLocation("park", data.value);
          checkPreviousValueAndCurrent(
            previousIncident.case_details.incident_description.park,
            data.value,
            "park"
          );
        }}
        options={parkList}
      />
      {parkError && (
        <div style={{ color: "#E72D35", marginTop: 15 }}>Required field</div>
      )}

      {/* Zone  */}
      <div
        className="input_title"
        style={{ marginTop: 30, color: zoneError && "#e72d35" }}
      >
        Zone*
      </div>
      <div
        style={{
          width: "100%",
          height: 48,
          border: "1px solid #D8D8D8",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 12,
          paddingLeft: 15,
          paddingRight: 6,
          marginBottom: 30,
          cursor: "pointer",
          position: "relative",
          background: zoneError && "#FFF0F0",
          border: zoneError ? "1px solid #E72D35" : "1px solid #D8D8D8",
        }}
        onClick={(e) => {
          e.stopPropagation();
          showShowZoneList();
          setFilterZoneList(
            zoneList.filter((z) => z.key == props.locationValues.park)
          );
        }}
      >
        <div>
          {selectedAddress ? (
            selectedAddress
          ) : props.zoneType ? (
            props.zoneType
          ) : (
            <span style={{ color: "rgba(191,191,191,.87)" }}>
              Please Select...
            </span>
          )}
        </div>
        <div>
          <img src={ArrowDown} />
        </div>
        {showZoneDropDown && (
          <div
            className="zonelistdropdown"
            style={{
              background: zoneError && "#FFF0F0",
              border: zoneError && "1px solid #E72D35",
            }}
          >
            {!values.park ? (
              "No result."
            ) : filterZoneList.length == 0 ? (
              "No Data"
            ) : showSubZoneList ? (
              <div>
                <div
                  style={{
                    color: "#008237",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    marginBottom: 10,
                  }}
                  onClick={() => setShowSubZoneList(false)}
                >
                  <img src={LeftArrowIcon} style={{ width: "8px" }} />
                  <span style={{ marginLeft: 10 }}>Back</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {subZoneList.map((z) => {
                    return (
                      <div key={z.id}>
                        {z.title.toLowerCase() ==
                        "other: pin current location" ? (
                          <Popup
                            trigger={
                              <div
                                className={`zonelist_item ${
                                  selectedAddress && "active"
                                }`}
                                key={z.id}
                              >
                                <div>
                                  <img src={z.img_url} />
                                  <div>{z.title}</div>
                                </div>
                              </div>
                            }
                            className={`${
                              showFullScreenLocation && "location_content"
                            }`}
                            modal
                            closeOnDocumentClick={false}
                          >
                            {(close) => {
                              return (
                                <div style={{ width: "100%" }}>
                                  <MapLocation
                                    close={close}
                                    onChangeIncidentAddressZone={
                                      onChangeIncidentAddressZone
                                    }
                                    title={values.selectedAddress}
                                    showFullScreenLocation={
                                      showFullScreenLocation
                                    }
                                    showFullScreenFunction={
                                      showFullScreenFunction
                                    }
                                    hideFullScreen={hideFullScreen}
                                    onChangeMainZone={props.onChangeMainZone}
                                    onChangeZoneType={props.onChangeZoneType}
                                    onChangeSubZone={props.onChangeSubZone}
                                    onChangeSelectedAddress={
                                      props.onChangeSelectedAddress
                                    }
                                    mainZone={mainZone}
                                    setViewLocation={props.setViewLocation}
                                    selectedAddress={props.selectedAddress}
                                  />
                                </div>
                              );
                            }}
                          </Popup>
                        ) : (
                          <div
                            className={`zonelist_item ${
                              mainZone == z.title && "active"
                            }`}
                            key={z.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                z.sub_zone &&
                                typeof z.sub_zone == "object" &&
                                z.sub_zone.length > 0
                              ) {
                                setShowSubZoneList(true);
                                setSubZoneList(z.sub_zone);
                              } else {
                                hideShowZoneList();

                                props.onChangeMainZone(z.title);
                                props.onChangeZoneType(z.title);
                                onChangeSubZone("");
                                props.onChangeSelectedAddress(null);
                                checkPreviousValueAndCurrent(
                                  previousIncident.case_details
                                    .incident_description.zone,
                                  z.title,
                                  "zone"
                                );
                              }
                            }}
                          >
                            <div
                              className={
                                z.id == 1
                                  ? "img_active"
                                  : z.id == 2
                                  ? "img_active"
                                  : z.id == 3
                                  ? "img_active"
                                  : z.id == 4
                                  ? "img_active"
                                  : null
                              }
                            >
                              <img src={z.img_url} />
                              <div>
                                {z.title.length > 46
                                  ? z.title.slice(0, 43) + "..."
                                  : z.title}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              filterZoneList.map((z) => {
                return (
                  <>
                    {z.title.toLowerCase() == "other: pin current location" ? (
                      <Popup
                        trigger={
                          <div
                            className={`zonelist_item ${
                              selectedAddress && "active"
                            }`}
                            key={z.id}
                          >
                            <div>
                              <img src={z.img} />
                              <div>{z.title}</div>
                            </div>
                          </div>
                        }
                        className={`${
                          showFullScreenLocation && "location_content"
                        }`}
                        modal
                        closeOnDocumentClick={false}
                      >
                        {(close) => {
                          return (
                            <div style={{ width: "100%" }}>
                              <MapLocation
                                close={close}
                                onChangeIncidentAddressZone={
                                  onChangeIncidentAddressZone
                                }
                                title={values.selectedAddress}
                                showFullScreenLocation={showFullScreenLocation}
                                showFullScreenFunction={showFullScreenFunction}
                                hideFullScreen={hideFullScreen}
                                onChangeIncidentLocationZone={
                                  props.onChangeIncidentLocationZone
                                }
                                onChangeSelectedAddress={
                                  props.onChangeSelectedAddress
                                }
                                selectedAddress={props.selectedAddress}
                                onChangeMainZone={props.onChangeMainZone}
                                onChangeZoneType={props.onChangeZoneType}
                                onChangeSubZone={props.onChangeSubZone}
                                checkPreviousValueAndCurrent={
                                  checkPreviousValueAndCurrent
                                }
                                previousIncident={previousIncident}
                              />
                            </div>
                          );
                        }}
                      </Popup>
                    ) : (
                      <div
                        className={`zonelist_item ${
                          mainZone == z.title && "active"
                        }`}
                        key={z.id}
                        onClick={(e) => {
                          e.stopPropagation();

                          if (
                            z.sub_zone &&
                            typeof z.sub_zone == "object" &&
                            z.sub_zone.length > 0
                          ) {
                            setShowSubZoneList(true);
                            setSubZoneList(z.sub_zone);
                          } else {
                            hideShowZoneList();

                            props.onChangeMainZone(z.title);
                            props.onChangeZoneType(z.title);
                            onChangeSubZone("");
                            props.onChangeSelectedAddress(null);
                            checkPreviousValueAndCurrent(
                              previousIncident.case_details.incident_description
                                .zone,
                              z.title,
                              "zone"
                            );
                          }
                        }}
                      >
                        <div>
                          <img src={z.img} />
                          <div>{z.title}</div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })
            )}
          </div>
        )}
      </div>

      {typeof mainZone != "object" &&
        mainZone.toLowerCase() == "other: type in location" && (
          <div style={{ marginBottom: 30 }}>
            <h3>Enter location</h3>
            <Input
              fluid
              className="damaged_input"
              value={props.subZone}
              onChange={(e) => onChangeSubZone(e.target.value)}
            />
          </div>
        )}
      {zoneError && (
        <div
          style={{
            color: "#E72D35",
            marginLeft: 3,
            fontSize: 13,
            marginBottom: 20,
            marginTop: -25,
          }}
        >
          Required field
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",

          marginTop: -30,
        }}
      >
        {/* Date */}
        <div style={{ flex: 1 }}>
          <div className="input_container">
            <div className="input_title">Date*</div>
            <div className="datePicker">
              <DatePicker
                onChangeRaw={(e) => e.preventDefault()}
                selected={selectedDate}
                onClick={hideShowZoneList}
                onChange={(date) => {
                  onChangeIncidentLocation(
                    "date",
                    moment(date).format("D MMM yyyy")
                  );
                  checkPreviousValueAndCurrent(
                    previousIncident.case_details.incident_description.date,
                    moment(date).format("D MMM yyyy"),
                    "date"
                  );
                  onChangeLocationDateTime("selectedDate", date);
                }}
                value={
                  selectedDate && moment(selectedDate).format("D MMM yyyy")
                }
              />
              <img src={CalendarIcon} />
            </div>
          </div>
        </div>
        {/* Time */}
        <div style={{ flex: 1 }}>
          <div className="input_container2">
            <div className="input_title">Time*</div>
            <div className="datePicker timePicker">
              <DatePicker
                onClick={hideShowZoneList}
                onChangeRaw={(e) => e.preventDefault()}
                selected={selectedTime && selectedTime}
                onChange={(date) => {
                  onChangeIncidentLocation(
                    "time",
                    moment(date).format("HH:mm:ss")
                  );
                  checkPreviousValueAndCurrent(
                    previousIncident.case_details.incident_description.time,
                    moment(date).format("HH:mm:ss"),
                    "time"
                  );
                  onChangeLocationDateTime("selectedTime", date);
                }}
                values={moment(selectedTime).format("HH:mm:ss a")}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                timeCaption="Time"
                dateFormat={"hh:mm:ss a"}
              />
              <img src={TimeIcon} />
            </div>
          </div>
        </div>
      </div>
      {/* Weather */}
      {/* <h3 className={`dropdown_title ${weatherError && "error"}`}>Weather </h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown`}
        icon_classes="incident-place-list-icon"
        value={values.weather}
        onChange={(e, data) => {
          onChangeIncidentLocation("weather", data.value);
          checkPreviousValueAndCurrent(
            previousIncident.case_details.incident_description.weather,
            data.value,
            "weather"
          );
        }}
        options={weatherList}
        disabled={selectedAddress || props.zoneType ? false : true}
        onClick={hideShowZoneList}
      /> */}

      {/* Condition of area */}
      {/* <h3 className={`dropdown_title ${areaError && "error"}`}>
        Condition of Area{" "}
      </h3>
      <Dropdown
        className="incident-place-list  incident-list-dropdown"
        icon={<img src={ArrowDown} className="incident-place-list-icon" />}
        style={{ paddingTop: 10 }}
        fluid
        multiple
        selection
        options={conditionAreaList}
        value={values.area_condition}
        onChange={(e, data) => {
          props.onChangeIncidentLocationCondtionsArea(data.value);
        }}
        placeholder="Please Select..."
        onClick={hideShowZoneList}
      /> */}
      {/* {conditionAreaList.map((c) => {
        return (
          <div style={{ marginBottom: 10 }}>
            <Checkbox
              label={c}
              checked={values.area_condition.includes(c)}
              className="checkBox"
              onChange={(e, data) =>
                props.onChangeIncidentLocationCondtionsArea(c)
              }
            />
           ----------- <input
              type="checkbox"
              onChange={() => props.onChangeIncidentLocationCondtionsArea(c)}
              className="multi_checkboxs"
              checked={values.area_condition.includes(c)}
              id={c}
            ></input>
            <label htmlFor={c} style={{ marginLeft: 10, cursor: "pointer" }}>
              {c}
            </label>
          </div>-------------------
        );
      })} */}
      {/* Footer */}
    </div>
  );
};

export default IncidentLocation;
