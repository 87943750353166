import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Input, Checkbox, Dropdown } from "semantic-ui-react";
import MapLocation from "./MapLocation";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import PlaceList from "../../../../components/PlaceList";
import moment from "moment";
import { db } from "../../../../config/firebase";
import { useLocation } from "react-router-dom";

//images
import CalendarIcon from "../../../../assets/images/incident/ic_calendar.svg";
import TimeIcon from "../../../../assets/images/incident/ic_time.svg";
import ArrowDown from "../../../../assets/images/incident/arrowdowndrop.svg";
import ArrowDownDropDownIcon from "../../../../assets/images/incident/arrowdowndrop.svg";
import LeftArrowIcon from "../../../../assets/images/incident/leftarrow.svg";
import TramStation from "../../../../assets/images/incident/tramstation.svg";
import ShowArea from "../../../../assets/images/incident/showarea.svg";
import TreeTrail from "../../../../assets/images/incident/treetrail.svg";
import GreatRift from "../../../../assets/images/incident/greatrift.svg";
import Kangaroo from "../../../../assets/images/incident/kangaroo.svg";
import Lake from "../../../../assets/images/incident/lake.svg";
import Monkey from "../../../../assets/images/incident/monkey.svg";
import KidsWorld from "../../../../assets/images/incident/kidsworld.svg";
import CurrentLocation from "../../../../assets/images/incident/currentlocation.svg";
import TypeLocation from "../../../../assets/images/incident/typelocation.svg";

// test image
import TestShowArea from "../../../../assets/images/incident/sz_ShowArea.svg";
import TestTramStation from "../../../../assets/images/incident/sz_TramStation1.png";
import TestTreetopsTrail from "../../../../assets/images/incident/sz_TreetopsTrail.png";
import TestEntrancePlaza from "../../../../assets/images/incident/sz_EntrancePlaza.svg";

const IncidentLocation = (props) => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramId = query.get("id");
  const { hideShowZoneList, showShowZoneList, showZoneDropDown } = props;
  const [showFullScreenLocation, setShowFullScreenLocation] = useState(false);
  const [filterZoneList, setFilterZoneList] = useState([]);
  const [parkListLoader, setParkListLoader] = useState(false);
  const [conditionAreaList, setConditionAreaList] = useState([]);
  const [weatherList, setWeatherList] = useState([]);
  const [subZoneList, setSubZoneList] = useState([]);
  const [showSubZoneList, setShowSubZoneList] = useState(false);
  // const condition_areas = [
  //   "No Hazard",
  //   "Wet Floor",
  //   "Algae",
  //   "Uneven",
  //   "Floor",
  //   "Damages",
  // ];
  let condition_areas = [];
  const showFullScreenFunction = () => setShowFullScreenLocation(true);
  const hideFullScreen = () => setShowFullScreenLocation(false);

  const zoneListFake = [
    // { id: 1, title: "Tram Station ", img: TramStation },
    // { id: 2, title: "Show Area", img: ShowArea },
    // { id: 3, title: "Treetops Trail", img: TreeTrail },
    // { id: 4, title: "Great Rift Valleyof Ethiopia", img: GreatRift },

    { id: 1, title: "Tram Station ", img: TestShowArea },
    { id: 2, title: "Show Area", img: TestTramStation },
    { id: 3, title: "Treetops Trail", img: TestTreetopsTrail },
    { id: 4, title: "Great Rift Valleyof Ethiopia", img: TestEntrancePlaza },
    { id: 5, title: "Australasia", img: Kangaroo },
    { id: 6, title: "PBTL", img: Lake },
    { id: 7, title: "Primate Kingdom", img: Monkey },
    { id: 8, title: "Kidsworld", img: KidsWorld },
    { id: 9, title: "Other: Pin Current Location", img: CurrentLocation },
    { id: 10, title: "Other: Type in Location", img: TypeLocation },
  ];

  useEffect(() => {}, []);

  const submitForm = () => {};
  const saveDraftForm = () => {
    props.saveMainDraftButton();
  };

  useEffect(() => {
    setParkListLoader(true);
    db.collection("static_data")
      .doc("parks")
      .get()
      .then((querySnapshot) => {
        let parkData = [];
        let zoneData = [];
        querySnapshot.data().parks_arr.forEach((doc, index) => {
          parkData.push({
            key: index,
            text: doc.park,
            value: doc.park,
          });
        });

        querySnapshot.data().parks_arr.forEach((doc, index) => {
          doc.mainzone.map((z, index) => {
            zoneData.push({
              id: index,
              title: z.title,
              img: z.img_url,
              key: doc.park,
              sub_zone: z.sub_zone,
            });
          });
        });

        setParkListLoader(false);
        setZoneList(zoneData); // all Zone List

        if (props.locationValues.park) {
          setFilterZoneList(
            zoneList.filter((z) => z.key == props.locationValues.park)
          );
        }
        setParkList(parkData);
      })

      .catch((err) => {
        // setTypeListLoader(false);
        console.log("err", err);
      });

    db.collection("static_data")
      .doc("area_condition")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().area_condition_arr.forEach((doc, index) => {
          data.push({
            key: doc,
            text: doc,
            value: doc,
          });
        });

        setConditionAreaList(data);
      });
    db.collection("static_data")
      .doc("weather")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().weather_arr.forEach((doc, index) => {
          data.push({
            value: doc,
            text: doc,
            key: doc,
          });
        });

        setWeatherList(data);
      });
  }, []);

  const values = props.locationValues;
  const onChangeIncidentLocation = props.onChangeIncidentLocation;
  const {
    parkError,
    zoneError,
    weatherError,
    areaError,
    dateError,
    timeError,
  } = props.locationInputError;
  const onChangeIncidentAddressZone = props.onChangeIncidentAddressZone;
  const { selectedDate, selectedTime } = props.locationDate;
  const onChangeLocationDateTime = props.onChangeLocationDateTime;
  const onChangeSubZone = props.onChangeSubZone;
  const onChangeZoneType = props.onChangeZoneType;

  const selectedAddress = props.selectedAddress;
  const mainZone = props.mainZone;
  const { parkList, setParkList, zoneList, setZoneList } = props.optionsState;

  useEffect(() => {
    if (props.locationValues.park && zoneList) {
      setFilterZoneList(
        zoneList.filter((z) => z.key == props.locationValues.park)
      );
    }
  }, [props.locationValues.park, paramId, zoneList]);

  useEffect(() => {}, [values.area_condition]);

  return (
    <div
      className="incident_overview_container"
      onClick={() => hideShowZoneList()}
    >
      {/* Park  */}
      <h3 className={`dropdown_title ${parkError && "error"}`}>Park* </h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown ${
          parkError && "error"
        }`}
        icon_classes="incident-place-list-icon"
        onClick={hideShowZoneList}
        value={values.park}
        onChange={(e, data) => {
          setFilterZoneList(zoneList.filter((z) => z.key == data.value));
          props.onChangeMainZone("");
          props.onChangeZoneType("");

          onChangeIncidentLocation("park", data.value);
          props.CheckfilledMatodaryField();
        }}
        options={parkList}
      />
      {parkError && (
        <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
          Required field
        </div>
      )}
      {/* Zone  */}
      <div
        className="input_title"
        style={{ marginTop: 30, color: zoneError && "#e72d35" }}
      >
        Zone*
      </div>
      <div
        style={{
          width: "100%",
          height: 48,
          border: "1px solid #D8D8D8",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 12,
          paddingLeft: 15,
          paddingRight: 6,
          marginBottom: 30,
          cursor: "pointer",
          position: "relative",
          backgroundColor: zoneError && "#FFF0F0",

          // opacity: values.park == "" ? 0.5 : 1,
          // cursor: values.park == "" && "not-allowed",
          border: zoneError ? "1px solid #E72D35" : "1px solid #D8D8D8",
        }}
        onClick={(e) => {
          e.stopPropagation();
          showShowZoneList();
        }}
      >
        <div>
          {selectedAddress ? (
            selectedAddress
          ) : props.zoneType ? (
            props.zoneType
          ) : (
            <span style={{ color: "rgba(191,191,191,.87)" }}>
              Please Select...
            </span>
          )}
        </div>
        <div>
          <img src={ArrowDown} />
        </div>
        {showZoneDropDown && (
          <div
            className="zonelistdropdown"
            style={{
              background: zoneError && "#FFF0F0",
              border: zoneError && "1px solid #E72D35",
            }}
          >
            {!values.park ? (
              "No result."
            ) : filterZoneList.length == 0 ? (
              "No Data"
            ) : showSubZoneList ? (
              <div>
                <div
                  style={{
                    color: "#008237",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    marginBottom: 10,
                  }}
                  onClick={() => setShowSubZoneList(false)}
                >
                  <img src={LeftArrowIcon} style={{ width: "8px" }} />
                  <span style={{ marginLeft: 10 }}>Back</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {subZoneList.map((z) => {
                    return (
                      <div key={z.id}>
                        {z.title.toLowerCase() ==
                        "other: pin current location" ? (
                          <Popup
                            trigger={
                              <div
                                className={`zonelist_item ${
                                  selectedAddress && "active"
                                }`}
                                key={z.id}
                              >
                                <div>
                                  <img src={z.img_url} />
                                  <div>{z.title}</div>
                                </div>
                              </div>
                            }
                            className={`${
                              showFullScreenLocation && "location_content"
                            }`}
                            modal
                            closeOnDocumentClick={false}
                          >
                            {(close) => {
                              return (
                                <div style={{ width: "100%" }}>
                                  <MapLocation
                                    close={close}
                                    onChangeIncidentAddressZone={
                                      onChangeIncidentAddressZone
                                    }
                                    title={values.selectedAddress}
                                    showFullScreenLocation={
                                      showFullScreenLocation
                                    }
                                    showFullScreenFunction={
                                      showFullScreenFunction
                                    }
                                    hideFullScreen={hideFullScreen}
                                    onChangeMainZone={props.onChangeMainZone}
                                    onChangeZoneType={props.onChangeZoneType}
                                    onChangeSubZone={props.onChangeSubZone}
                                    onChangeSelectedAddress={
                                      props.onChangeSelectedAddress
                                    }
                                    mainZone={mainZone}
                                    setViewLocation={props.setViewLocation}
                                    selectedAddress={props.selectedAddress}
                                  />
                                </div>
                              );
                            }}
                          </Popup>
                        ) : (
                          <div
                            className={`zonelist_item ${
                              mainZone == z.title && "active"
                            }`}
                            key={z.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                z.sub_zone &&
                                typeof z.sub_zone == "object" &&
                                z.sub_zone.length > 0
                              ) {
                                setShowSubZoneList(true);
                                setSubZoneList(z.sub_zone);
                              } else {
                                hideShowZoneList();
                                props.onChangeMainZone(z.title);
                                props.onChangeZoneType(z.title);
                                onChangeSubZone("");
                                props.onChangeSelectedAddress(null);
                                setShowSubZoneList(false);
                                props.CheckfilledMatodaryField();
                              }
                            }}
                          >
                            <div
                              className={
                                z.id == 1
                                  ? "img_active"
                                  : z.id == 2
                                  ? "img_active"
                                  : z.id == 3
                                  ? "img_active"
                                  : z.id == 4
                                  ? "img_active"
                                  : null
                              }
                            >
                              <img src={z.img_url} />
                              <div>
                                {z.title.length > 46
                                  ? z.title.slice(0, 43) + "..."
                                  : z.title}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              filterZoneList.map((z) => {
                return (
                  <div key={z.id}>
                    {z.title.toLowerCase() == "other: pin current location" ? (
                      <Popup
                        trigger={
                          <div
                            className={`zonelist_item ${
                              selectedAddress && "active"
                            }`}
                            key={z.id}
                          >
                            <div>
                              <img src={z.img} />
                              <div>{z.title}</div>
                            </div>
                          </div>
                        }
                        className={`${
                          showFullScreenLocation && "location_content"
                        }`}
                        modal
                        closeOnDocumentClick={false}
                      >
                        {(close) => {
                          return (
                            <div style={{ width: "100%" }}>
                              <MapLocation
                                close={close}
                                onChangeIncidentAddressZone={
                                  onChangeIncidentAddressZone
                                }
                                title={values.selectedAddress}
                                showFullScreenLocation={showFullScreenLocation}
                                showFullScreenFunction={showFullScreenFunction}
                                hideFullScreen={hideFullScreen}
                                onChangeMainZone={props.onChangeMainZone}
                                onChangeZoneType={props.onChangeZoneType}
                                onChangeSubZone={props.onChangeSubZone}
                                onChangeSelectedAddress={
                                  props.onChangeSelectedAddress
                                }
                                mainZone={mainZone}
                                setViewLocation={props.setViewLocation}
                                selectedAddress={props.selectedAddress}
                              />
                            </div>
                          );
                        }}
                      </Popup>
                    ) : (
                      <div
                        className={`zonelist_item ${
                          mainZone == z.title && "active"
                        }`}
                        key={z.id}
                        onClick={(e) => {
                          if (
                            z.sub_zone &&
                            typeof z.sub_zone == "object" &&
                            z.sub_zone.length > 0
                          ) {
                            setShowSubZoneList(true);
                            setSubZoneList(z.sub_zone);
                          } else {
                            e.stopPropagation();
                            hideShowZoneList();
                            props.onChangeMainZone(z.title);
                            props.onChangeZoneType(z.title);
                            onChangeSubZone("");
                            props.onChangeSelectedAddress(null);
                            setShowSubZoneList(false);
                            props.CheckfilledMatodaryField();
                          }
                        }}
                      >
                        <div
                          className={
                            z.id == 1
                              ? "img_active"
                              : z.id == 2
                              ? "img_active"
                              : z.id == 3
                              ? "img_active"
                              : z.id == 4
                              ? "img_active"
                              : null
                          }
                        >
                          <img src={z.img} />
                          <div>
                            {z.title.length > 46
                              ? z.title.slice(0, 43) + "..."
                              : z.title}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
      {props.zoneType.toLowerCase() == "other: type in location" && (
        <div style={{ marginBottom: 30 }}>
          <h3>Enter location</h3>
          <Input
            fluid
            className="damaged_input"
            value={props.subZone}
            onChange={(e) => {
              onChangeSubZone(e.target.value);
              props.CheckfilledMatodaryField();
            }}
          />
        </div>
      )}
      {zoneError && (
        <div
          style={{
            color: "#E72D35",
            marginLeft: 3,
            marginTop: -25,
            marginBottom: 20,
            fontSize: 13,
          }}
        >
          Required field
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: -30,
        }}
      >
        {/* Date */}
        <div style={{ flex: 1 }}>
          <div className="input_container">
            <div
              className="input_title"
              style={{
                color: dateError ? "red" : null,
              }}
            >
              Date*
            </div>
            <div className={`datePicker ${dateError && "error"}`}>
              <DatePicker
                onChangeRaw={(e) => e.preventDefault()}
                onClick={hideShowZoneList}
                selected={selectedDate}
                onChange={(date) => {
                  onChangeIncidentLocation(
                    "date",
                    moment(date).format("D MMM yyyy")
                  );

                  onChangeLocationDateTime("selectedDate", date);
                  props.CheckfilledMatodaryField();
                }}
                value={
                  selectedDate && moment(selectedDate).format("D MMM yyyy")
                }
              />
              <img src={CalendarIcon} />
            </div>
          </div>
          {dateError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              Required field
            </div>
          )}
        </div>
        {/* Time */}
        <div style={{ flex: 1 }}>
          <div className="input_container2">
            <div
              className="input_title"
              style={{
                color: timeError ? "red" : null,
              }}
            >
              Time*
            </div>
            <div className={`datePicker timePicker ${timeError && "error"}`}>
              <DatePicker
                onClick={hideShowZoneList}
                onChangeRaw={(e) => e.preventDefault()}
                selected={selectedTime && selectedTime}
                onChange={(date) => {
                  onChangeIncidentLocation(
                    "time",
                    moment(date).format("HH:mm:ss")
                  );

                  onChangeLocationDateTime("selectedTime", date);
                  props.CheckfilledMatodaryField();
                }}
                values={moment(selectedTime).format("HH:mm:ss a")}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                timeCaption="Time"
                dateFormat={"hh:mm:ss a"}
              />
              <img src={TimeIcon} />
            </div>
          </div>
          {timeError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              Required field
            </div>
          )}
        </div>
      </div>
      {/* Weather */}

      {/* <h3 className={`dropdown_title ${weatherError && "error"}`}>Weather </h3>
      <PlaceList
        custom_classes={`incident-place-list  incident-list-dropdown`}
        icon_classes="incident-place-list-icon"
        value={values.weather}
        onChange={(e, data) => {
          onChangeIncidentLocation("weather", data.value);
          props.CheckfilledMatodaryField();
        }}
        options={weatherList}
        disabled={selectedAddress || props.zoneType ? false : true}
        onClick={hideShowZoneList}
      /> */}

      {/* Condition of area */}
      {/* <h3 className={`dropdown_title ${areaError && "error"}`}>
        Condition of Area{" "}
      </h3>
      <Dropdown
        className="incident-place-list  incident-list-dropdown"
        style={{ paddingTop: 10 }}
        icon={
          <img
            src={ArrowDownDropDownIcon}
            className="incident-place-list-icon"
          />
        }
        fluid
        multiple
        selection
        options={conditionAreaList}
        value={values.area_condition}
        onChange={(e, data) => {
          props.onChangeIncidentLocationCondtionsArea(data.value);
          props.CheckfilledMatodaryField();
        }}
        placeholder="Please Select..."
        onClick={hideShowZoneList}
      /> */}

      {/* {conditionAreaList.map((c) => {
        return (
          <div style={{ marginBottom: 10 }}>
            <Checkbox
              label={c}
              checked={values.area_condition.includes(c)}
              className="checkBox"
              onChange={(e, data) =>
                props.onChangeIncidentLocationCondtionsArea(c)
              }
            />
           ----- <input
              type="checkbox"
              onChange={() => props.onChangeIncidentLocationCondtionsArea(c)}
              className="multi_checkboxs"
              checked={values.area_condition.includes(c)}
              id={c}
            ></input>
            <label htmlFor={c} style={{ marginLeft: 10, cursor: "pointer" }}>
              {c}
            </label> ------------
          </div>
        );
      })} */}

      {/* Footer */}
    </div>
  );
};

export default IncidentLocation;
