import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import {
  Button,
  Input,
  Grid,
  Table,
  Pagination,
  Checkbox,
  Loader,
} from "semantic-ui-react";
import {
  fetchGroupUsers,
  fetchGroupManagement,
} from "../../store/actions/userGroupManagement";
import { connect } from "react-redux";

import api from "../../config/api";
import config from "../../config/config";
import { db } from "../../config/firebase";
import RoleList from "../../components/RoleList";

//import icons
import LeftArrowIcon from "../../assets/images/incident/leftarrow.svg";
import ArrowDownHeaderIcon from "../../assets/images/incident/arrowdownheader.svg";
import SearchIcon from "../../assets/images/incident/search.svg";
import ExitIcon from "../../assets/images/incident/exit.svg";
import { confirmAlert } from "react-confirm-alert";
//import font
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFilter,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

//import css
import "./viewGroup.css";

import { GROUP_LIST_ROUTE } from "../../routes";
import GroupFilterList from "../../components/GroupFilterList";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import AddGroup from "./addGroup";
import moment from "moment";

function ViewGroup(props) {
  let history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [allChecked, setAllChecked] = useState(false);
  const [sync, setSync] = useState(false);
  const [description, setDescription] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [memberStates, setMemberStates] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [sortData, setSortData] = useState({
    isDescending: true,
    label: "date",
    count: 1,
  });
  const [cacheData, setCacheData] = useState([]);
  const [showSearchData, setShowSearchData] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [sortTriggerData, setSortTriggerData] = useState(false);
  const [sortingList, setSortingList] = useState([]);
  const [updateRoleList, setUpdateRoleList] = useState([]);
  // clone all user list for modal condition
  const [modalUserList, setModalUserList] = useState([]);
  // after click update btn, it will filter
  const [filterUserList, setFilterUserList] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [changeFilterRole, setChangeFilterRole] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sortLoader, setSortLoader] = useState(false);
  const { GET_ALL_GROUPS, SAVE_USER } = config.api_endpoint;

  const scrollRef = useRef(null);

  const scrollToTopFunction = () => {
    if (window.screen.width > 800) {
      scrollRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    scrollToTopFunction();
  }, [currentPage]);

  const onChangeAllChecked = (state) => {
    setAllChecked(state);

    let cloneMemberStates = memberStates;

    for (var index = 0; index < cloneMemberStates.length; index++) {
      cloneMemberStates[index].state = state;
    }

    setMemberStates(cloneMemberStates);
    setSync(!sync);
  };

  const onChangeMemberState = (id, state, role) => {
    setSync(!sync);
    let cloneMemberStates = memberStates;
    const findIndex = cloneMemberStates.findIndex((_, index) => _.id === id);

    cloneMemberStates[findIndex].state = state;
    cloneMemberStates[findIndex].role = role;
    setMemberStates(cloneMemberStates);

    var allChecked = true;
    for (var index = 0; index < memberStates.length; index++) {
      if (memberStates[index].state == false) {
        allChecked = false;
      }
    }
    setAllChecked(allChecked);
  };

  const getMemberState = (id) => {
    const findIndex = memberStates.findIndex((_, index) => _.id === id);

    if (findIndex == -1) {
      setMemberStates([
        ...memberStates,
        { id: id, state: allChecked ? allChecked : false },
      ]);
      return allChecked ? allChecked : false;
    }

    return allChecked ? allChecked : memberStates[findIndex].state;
  };

  const checkMemberState = () => {
    var hasCheckedItem = false;
    for (var index = 0; index < memberStates.length; index++) {
      if (memberStates[index].state == true) {
        hasCheckedItem = true;
        break;
      }
    }
    return hasCheckedItem;
  };
  const paramId = query.get("id");
  const handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage;
    setCurrentPage(page);
    setAllChecked(false);
    setMemberStates([]);
    props.fetchGroupUsers(paramId, page, sortData.isDescending, "");
  };

  useEffect(() => {
    props.fetchGroupUsers(
      paramId,
      currentPage,
      sortData.isDescending,
      searchInput
    );
  }, [paramId, currentPage, sortData]);

  useEffect(() => {
    setRoleList([]);
    db.collection("static_data")
      .doc("roles")
      .get()
      .then((doc) => {
        let data = [];
        if (doc.data()) {
          doc.data()["roles_arr"].forEach((_role) => {
            data.push({
              key: _role.id,
              text: _role.title,
              value: _role.id,
            });
          });

          setRoleList(data);
        }
      });
  }, []);

  const updateUserListFunction = (onClose) => {
    setLoader(true);
    let succesMessageCount = [];
    let updatedData;
    let cloneCacheData = cacheData;
    filterUserList.map((_) => {
      let findIndex = cloneCacheData.findIndex((n) => n.id == _.id);
      if (findIndex != -1) {
        cloneCacheData[findIndex].role = _.role;
      }

      api
        .post(
          SAVE_USER,
          JSON.stringify({
            platform: "web",
            id: _.id,
            role: _.role,
          })
        )
        .then((res) => {
          if (res.message.toLowerCase() == "success") {
            succesMessageCount.push(res.message);
            if (succesMessageCount.length === filterUserList.length) {
              setLoader(false);
              setMemberStates([]);
              onClose();
              props.fetchGroupUsers(
                paramId,
                currentPage,
                sortData.isDescending,
                searchInput
              );
            }
          }
        })
        .catch((err) => {});
    });

    setLoader(true);
  };

  const searchSubmit = () => {
    if (searchInput.length > 0) {
      setShowSearchData(true);
      props.fetchGroupUsers(
        paramId,
        currentPage,
        sortData.isDescending,
        searchInput
      );
    } else {
      setShowSearchData(false);
    }
  };

  function customModal(onClose) {
    if (filterUserList.length > 0) {
      return (
        <div
          className="incidentAlertBox"
          onClick={() => {
            setModalOpen(false);
            onClose();
          }}
        >
          <div
            className="member-popup"
            style={{
              width: 500,
              backgroundColor: "white",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "13px 20px",
              minHeight: 200,
              maxHeight: 350,
              overflowY: "scroll",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="rm_member_popup">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <img src={ExitIcon} onClick={onClose} />
              </div>
              <div>
                <h3 className="title">
                  <center>Update the Members of the group</center>
                </h3>

                {filterUserList &&
                  filterUserList.map((_) => {
                    return (
                      <div className="member_content" key={_.id}>
                        <div>
                          <div className="header">
                            <Grid>
                              <Grid.Row>
                                <Grid.Column width={10}>
                                  <div className="member_name">{_.name}</div>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                  {/* <div className="member_role"> */}
                                  {/* {_.role} */}
                                  <RoleList
                                    custom_classes="gp_dropdown"
                                    icon_classes="icon-profile-list-dropdown"
                                    value={_.role}
                                    onChange={(e, data) => {
                                      let cloneUserFilter = filterUserList;
                                      let findIndex = filterUserList.findIndex(
                                        (c) => c.id == _.id
                                      );
                                      cloneUserFilter[findIndex].role =
                                        data.value;
                                      setFilterUserList(cloneUserFilter);

                                      setChangeFilterRole(!changeFilterRole);
                                    }}
                                    forceSelection={false} // added here
                                    selectOnBlur={false}
                                    options={roleList}
                                  />
                                  {/* </div> */}
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </div>
                          <div className="member_id">{_.staff_id}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div style={{ "padding-top": "20px" }}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Button onClick={onClose} style={{ width: "100%" }}>
                        Cancel{" "}
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Button
                        style={{
                          width: "100%",
                          background: "#008237",
                          color: "white",
                        }}
                        className="modal_update"
                        onClick={() => updateUserListFunction(onClose)}
                      >
                        {loader ? (
                          <Loader
                            active
                            inline="centered"
                            size="tiny"
                            inverted
                            indeterminate
                          />
                        ) : (
                          <span>Update</span>
                        )}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="incidentAlertBox" onClick={onClose}>
          <div
            style={{
              width: 400,
              height: 200,
              backgroundColor: "white",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "13px 20px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="rm_member_popup">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={ExitIcon}
                  onClick={() => {
                    setModalOpen(false);
                    onClose();
                  }}
                />
              </div>
              <div>
                <h3
                  className="title"
                  style={{
                    color: "black",
                    "padding-left": "10px",
                  }}
                >
                  <b>Confirm</b>
                </h3>
              </div>

              <div>
                <center>Please select a member to remove.</center>
              </div>

              <div style={{ "padding-top": "20px" }}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}></Grid.Column>
                    <Grid.Column width={8}>
                      <Button
                        onClick={onClose}
                        style={{
                          width: "100%",
                          background: "#008237",
                          color: "white",
                        }}
                      >
                        OK{" "}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  useEffect(() => {
    props.fetchGroupUsers(
      paramId,
      currentPage,
      sortData.isDescending,
      searchInput
    );

    if (props.gpList.length > 0) {
      setDescription(props.gpList.filter((g) => g.id == paramId)[0]);
    } else {
      api
        .get(GET_ALL_GROUPS)
        .then((res) => {
          setDescription(res.data.filter((g) => g.id == paramId)[0]);
        })
        .catch((err) => console.log("err", err));
    }
  }, []);

  useEffect(() => {}, [loader]);

  const sortedDataFunction = () => {
    setSortLoader(true);
    let cloneUserList = [...props.userList];
    if (cloneUserList.length == 0) {
      return setSortLoader(false);
    }

    if (sortData.label == "date") {
      cloneUserList.sort((a, b) =>
        sortData.isDescending
          ? new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          : new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    } else if (sortData.label == "name") {
      cloneUserList.sort((a, b) =>
        sortData.isDescending
          ? b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 1
          : a.name.toLowerCase() < b.name.toLowerCase()
          ? 1
          : -1
      );
    } else if (sortData.label == "staff") {
      cloneUserList.sort((a, b) =>
        sortData.isDescending
          ? b.staff_id - a.staff_id
          : a.staff_id - b.staff_id
      );
    } else if (sortData.label == "department") {
      cloneUserList.sort((a, b) =>
        sortData.isDescending
          ? b.department.toLowerCase() > a.department.toLowerCase()
            ? -1
            : 1
          : a.department.toLowerCase() < b.department.toLowerCase()
          ? 1
          : -1
      );
    } else if (sortData.label == "park") {
      cloneUserList.sort((a, b) =>
        sortData.isDescending
          ? b.park.toLowerCase() > a.park.toLowerCase()
            ? -1
            : 1
          : a.park.toLowerCase() < b.park.toLowerCase()
          ? 1
          : -1
      );
    }
    setTimeout(() => {
      setSortLoader(false);
    }, 200);

    setSortingList(cloneUserList);
    setSortTriggerData(!sortTriggerData);
  };

  useEffect(() => {
    if (props.userList.length > 0) {
      sortedDataFunction();
    }
  }, [props.userList, sortData]);

  useEffect(() => {}, [
    sortingList,
    sortTriggerData,
    sortData,
    sortData.isDescending,
    sortData.label,
    sortData.count,
  ]);
  useEffect(() => {
    if (modalOpen) {
      return confirmAlert({
        customUI: ({ onClose }) => customModal(onClose),
      });
    }
  }, [filterUserList, modalOpen, changeFilterRole]);

  useEffect(() => {}, [changeFilterRole]);

  useEffect(() => {
    if (props.userList.length > 0) {
      setModalUserList(props.userList);
    }
  }, [props.userList]);

  return (
    <div style={{ marginBottom: 100 }}>
      <div
        style={{
          "overflow-y": "auto",
          "overflow-x": "hidden",
          "min-height": "70vh",
        }}
      >
        <div className="viewgroup_header" style={{ marginTop: 25 }}>
          <div
            to=""
            className="header_leftside"
            onClick={() => history.goBack()}
          >
            <img src={LeftArrowIcon} style={{ marginTop: 0 }} />
            <div className="detail_title">User Group listing</div>
          </div>
        </div>
        <div className="viewgroup_detail">
          {/* <Grid style={{ marginTop: -25 }}>
            <Grid.Row>
              <Grid.Column width={11}></Grid.Column>
              <Grid.Column width={5}>
                <div className="button_group_top">
                  <div style={{ width: "60%" }}>
                    <div>
                      <Button className="delete_button">
                        Delete the group{" "}
                      </Button>
                    </div>
                  </div>
                  <div style={{ width: "40%" }}>
                    <div>
                      <Button className="edit_button">Edit</Button>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid> */}
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <h2 style={{ marginTop: -20 }}>
                  {description && description.group_name}
                </h2>
                {/* <p>Kseddo elusmad tempar incidunt ut labore magna alliqua</p> */}
                <div>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        {description &&
                          description.perms.map((d) => {
                            return <div className="permission_field">{d}</div>;
                          })}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div style={{ position: "relative" }}>
          <div className="col-45" ref={scrollRef}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <div>
                    <h2>Group List</h2>
                  </div>
                </Grid.Column>

                {/* <Grid.Column width={3}> */}

                {/* </Grid.Column> */}
              </Grid.Row>
            </Grid>
          </div>
          <div className="search_bar" style={{ top: 15, background: "none" }}>
            <form
              className="search_bar_form"
              onSubmit={(e) => {
                e.preventDefault();
                searchSubmit();
              }}
            >
              <input
                type="text"
                onChange={(e) => {
                  if (e.target.value.length == 0) {
                    props.fetchGroupUsers(
                      paramId,
                      1,
                      sortData.isDescending,
                      ""
                    );
                    setShowSearchData(false);
                  }
                  setSearchInput(e.target.value);
                }}
                placeholder="Search"
                value={searchInput}
              />
              {searchInput.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#008237",
                    fontSize: "small",
                    cursor: "pointer",
                    position: "absolute",
                    right: 30,
                    top: 8,
                    zIndex: 10,
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowSearchData(false);
                    props.fetchGroupUsers(
                      paramId,
                      1,
                      sortData.isDescending,
                      ""
                    );
                    setSearchInput("");
                  }}
                >
                  X
                </div>
              )}
              <img
                src={SearchIcon}
                className="usersearch_icon"
                onClick={() => searchSubmit()}
              />
            </form>
          </div>
          {/* <div className="col-45">
            <Grid>
              <Grid.Row>
                <Grid.Column width={9}>
                  <div>
                    <h2>Group List</h2>
                  </div>
                </Grid.Column>

                <Grid.Column width={5}>
                  <div className="gpsearch_bar">
                    <form
                      className="gpsearch_bar_form"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input type="text" />
                      <img src={SearchIcon} className="usersearch_icon" />
                    </form>
                  </div>
                </Grid.Column>

                <Grid.Column width={3}>
                  <Popup
                    className="add-member-popup"
                    modal
                    nested
                    trigger={
                      <div className="gplist_add">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp; Add member
                      </div>
                    }
                  >
                    {(close) => {
                      return (
                        <div className="add_member_popup">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <img
                              src={ExitIcon}
                              onClick={close}
                              style={{ width: "2%" }}
                            />
                          </div>
                          <AddGroup close={close} />
                        </div>
                      );
                    }}
                  </Popup>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div> */}
          {props.userLoading || sortLoader ? (
            <div>
              <Loader active inline="centered" />
            </div>
          ) : (
            <div className="table_group_list">
              <Table basic>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>
                      <div className="gplist_tableHeader name">
                        <div>
                          <Checkbox
                            label=""
                            className="checkBox"
                            checked={allChecked}
                            onChange={(e, data) =>
                              onChangeAllChecked(data.checked)
                            }
                          />
                        </div>
                      </div>
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      <div
                        className="gplist_tableHeader name"
                        onClick={() => {
                          setSortTriggerData(!sortTriggerData);
                          if (sortData.count == 1) {
                            setSortData({
                              isDescending: false,
                              label: "staff",
                              count: sortData.count + 1,
                            });
                          } else {
                            setSortData({
                              isDescending: !sortData.isDescending,
                              label: "staff",
                              count: sortData.count + 1,
                            });
                          }
                        }}
                      >
                        <div>Staff ID </div>
                        {sortData.count == 1 ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : sortData.isDescending &&
                          sortData.label == "staff" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            className="sort-gp-users-up-icon"
                          />
                        ) : !sortData.isDescending &&
                          sortData.label == "staff" ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : !sortData.isDescending &&
                          sortData.label != "staff" ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        )}
                      </div>
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      <div
                        className="gplist_tableHeader description"
                        onClick={() => {
                          setSortTriggerData(!sortTriggerData);
                          if (sortData.count == 1) {
                            setSortData({
                              isDescending: false,
                              label: "name",
                              count: sortData.count + 1,
                            });
                          } else {
                            setSortData({
                              isDescending: !sortData.isDescending,
                              label: "name",
                              count: sortData.count + 1,
                            });
                          }
                        }}
                      >
                        <div>Name </div>
                        {sortData.count == 1 ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : sortData.isDescending &&
                          sortData.label == "name" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            className="sort-gp-users-up-icon"
                          />
                        ) : !sortData.isDescending &&
                          sortData.label == "name" ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : !sortData.isDescending &&
                          sortData.label != "name" ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        )}
                      </div>
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      <div
                        className="gplist_tableHeader noofmember"
                        onClick={() => {
                          setSortTriggerData(!sortTriggerData);
                          if (sortData.count == 1) {
                            setSortData({
                              isDescending: false,
                              label: "department",
                              count: sortData.count + 1,
                            });
                          } else {
                            setSortData({
                              isDescending: !sortData.isDescending,
                              label: "department",
                              count: sortData.count + 1,
                            });
                          }
                        }}
                      >
                        <div>Department </div>
                        {sortData.count == 1 ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : sortData.isDescending &&
                          sortData.label == "department" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            className="sort-gp-users-up-icon"
                          />
                        ) : !sortData.isDescending &&
                          sortData.label == "department" ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : !sortData.isDescending &&
                          sortData.label != "department" ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        )}
                      </div>
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      <div
                        className="gplist_tableHeader noofmember"
                        onClick={() => {
                          setSortTriggerData(!sortTriggerData);
                          if (sortData.count == 1) {
                            setSortData({
                              isDescending: false,
                              label: "park",
                              count: sortData.count + 1,
                            });
                          } else {
                            setSortData({
                              isDescending: !sortData.isDescending,
                              label: "park",
                              count: sortData.count + 1,
                            });
                          }
                        }}
                      >
                        <div>Park </div>
                        {sortData.count == 1 ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : sortData.isDescending &&
                          sortData.label == "park" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            className="sort-gp-users-up-icon"
                          />
                        ) : !sortData.isDescending &&
                          sortData.label == "park" ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : !sortData.isDescending &&
                          sortData.label != "park" ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        )}
                      </div>
                    </Table.HeaderCell>

                    <Table.HeaderCell width={2}>
                      <div
                        className="gplist_tableHeader noofmember"
                        onClick={() => {
                          setSortTriggerData(!sortTriggerData);
                          // props.fetchGroupUsers(
                          //   paramId,
                          //   1,
                          //   sortData.isDescending,
                          //   ""
                          // );
                          if (sortData.count == 1) {
                            setSortData({
                              isDescending: false,
                              label: "date",
                              count: sortData.count + 1,
                            });
                            setCurrentPage(1);
                          } else {
                            setSortData({
                              isDescending: !sortData.isDescending,
                              label: "date",
                              count: sortData.count + 1,
                            });
                            setCurrentPage(1);
                          }
                        }}
                      >
                        <div>Join date </div>
                        {sortData.count == 1 ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : sortData.isDescending &&
                          sortData.label == "date" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            className="sort-gp-users-up-icon"
                          />
                        ) : !sortData.isDescending &&
                          sortData.label == "date" ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : !sortData.isDescending &&
                          sortData.label != "date" ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="sort-gp-users-down-icon"
                          />
                        )}
                      </div>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {props.userList.length == 0 ? (
                    <div
                      style={{
                        height: 60,
                        display: "flex",
                        alignItems: "center",
                        padding: 10,
                        width: 150,
                      }}
                    >
                      No data found
                    </div>
                  ) : (
                    sortingList.length > 0 &&
                    sortingList.map((_, index) => (
                      <Table.Row key={_.id}>
                        <Table.Cell>
                          <Checkbox
                            label=""
                            className="checkbox"
                            checked={getMemberState(_.id)}
                            onChange={(e, data) =>
                              onChangeMemberState(_.id, data.checked, _.role)
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>{_.staff_id}</Table.Cell>
                        <Table.Cell>{_.name}</Table.Cell>
                        <Table.Cell>
                          {/* {_.role.charAt(0).toUpperCase() +
                            _.role.slice(1).replace("_", " ")} */}
                          {_.department}
                        </Table.Cell>
                        <Table.Cell>{_.park}</Table.Cell>
                        {/* 12 Nov 2021, 23:30 */}
                        <Table.Cell>
                          {moment(_.created_at).format("D MMM YYYY,")}
                          {"  "}
                          {moment(_.created_at).format("HH:mm")}
                        </Table.Cell>
                      </Table.Row>
                    ))
                  )}
                </Table.Body>
              </Table>
              {!showSearchData &&
                !props.userLoading &&
                !sortLoader &&
                props.userList.length > 0 && (
                  <Pagination
                    activePage={currentPage}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={3}
                    floated="right"
                    onPageChange={handlePaginationChange}
                    className={`${
                      currentPage == 1
                        ? props.total_pages == currentPage
                          ? "paginat__fist_disable paginat__last_disable"
                          : "paginat__fist_disable"
                        : props.total_pages == currentPage
                        ? "paginat__last_disable"
                        : null
                    }`}
                    totalPages={props.total_pages}
                    style={{ opacity: props.userLoading ? 0 : 1 }}
                  />
                )}
            </div>
          )}
        </div>
      </div>
      <div className="gp_footer">
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <div className="button_group">
                <div style={{ width: "30%", marginRight: 10 }}>
                  <Link to={GROUP_LIST_ROUTE}>
                    <Button className="cancel_button">
                      <div style={{ position: "relative", left: -3 }}>
                        Cancel
                      </div>
                    </Button>
                  </Link>
                </div>
                <div style={{ width: "100%" }}>
                  <Button
                    className="update_button"
                    onClick={() => {
                      let result = modalUserList.filter((o1) =>
                        memberStates.some(
                          (o2) => o1.id === o2.id && o2.state == true
                        )
                      );

                      setModalOpen(true);
                      setFilterUserList(result);
                    }}
                  >
                    Update member
                  </Button>
                  {/* <Popup
                    className="member-popup"
                    modal
                    nested
                    trigger={
                      <Button className="update_button">Update member</Button>
                    }
                  >
                    {(close) => {

                    }}
                  </Popup> */}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={4}></Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  gpList: state.userGroupManagement.gpList,
  userList: state.userGroupManagement.userList,
  total_pages: state.userGroupManagement.total_pages,
  nextUrl: state.userGroupManagement.nextUrl,
  userLoading: state.userGroupManagement.userLoading,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchGroupManagement: () => dispatch(fetchGroupManagement()),
  fetchGroupUsers: (role, currentPage, sort, search) =>
    dispatch(fetchGroupUsers(role, currentPage, sort, search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewGroup);
