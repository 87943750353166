import userAccountDetailActionTypes from "../actions/actionTypes/userAccountDetailActionTypes";

const {
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAIL,
} = userAccountDetailActionTypes;

const initialState = {
  error: null,
  userObj: {},
  loading: false,
};

const userDetailsAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        userObj: action.payload,
      };
    case FETCH_USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export default userDetailsAccountReducer;
