import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Input, Loader } from "semantic-ui-react";
import config from "../../config/config";
import api from "../../config/api";
import Cryptr from "cryptr";

import ArrowLeftIcon from "../../assets/images/incident/leftarrow.svg";
import axios from "axios";

function Forgot() {
  const { SEND_OTP } = config.api_endpoint;
  const { url } = config;
  const { SECRET_CODE } = config;

  const cryptr = new Cryptr(SECRET_CODE);

  const history = useHistory();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEmail(email.replace(/\s/g, ""));
  }, [email]);

  const submitHanlde = (e) => {
    const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

    let cloneEmail = email;
    e.preventDefault();
    if (email.length < 1) {
      return setError("Please enter valid email.");
    } else if (!regex.test(email)) {
      return setError("Please enter valid email.");
    } else {
      setLoading(true);
      setError("");
      axios
        .post(url + SEND_OTP, {
          platform: "web",
          email,
        })
        .then((res) => {
          console.log("res", res);
          // const encryptedString = cryptr.encrypt(res.data.opt_code);
          // localStorage.setItem("enot", encryptedString);
          setLoading(false);
          if (cloneEmail.toString().includes("+")) {
            cloneEmail = cloneEmail.replace("+", "2p");
          }
          history.push(`/reset_success?email=${cloneEmail}`);
          return;
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data) {
            setError(
              "An email with instructions on how to reset the password will be sent to your registered email address."
            );
          }
          console.log("err", err);
        });
    }
  };
  function AttributeValidationUsername(e) {
    setEmail(e.target.value.replace(/\s/g, ""));
  }
  return (
    <form className="login_form" style={{ zIndex: 9999999 }}>
      <div style={{ flex: 1 }}>
        <div
          style={{
            fontSize: 15,
            fontWeight: "bold",
            marginBottom: 30,
            display: "flex",
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img
            src={ArrowLeftIcon}
            style={{ marginRight: 18, cursor: "pointer" }}
            onClick={() => history.goBack()}
          />
          <span style={{ color: "#008237", fontSize: 18 }}>
            Forgot password
          </span>
        </div>

        <div style={{ marginBottom: 30 }}>
          <div style={{ fontSize: 13, fontWeight: "bold", marginBottom: 5 }}>
            Please key in your staff email address
          </div>

          <Input
            fluid
            value={email}
            className="damaged_input"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={AttributeValidationUsername}
          />
        </div>
        {error && (
          <div
            style={{
              color: "#E72D35",
              textAlign: "left",
              marginLeft: 5,
              marginTop: -25,
              fontSize: 13,
            }}
          >
            {error}
          </div>
        )}
      </div>
      <div
        style={{
          color: "#008237",
          fontSize: 14,
          marginBottom: 30,
        }}
      >
        If you do not have an email, please approach Park Services Department.
      </div>
      <div className="login_btn">
        {loading ? (
          <Loader active inline="centered" />
        ) : (
          <button onClick={submitHanlde}>Submit</button>
        )}
      </div>
    </form>
  );
}

export default Forgot;
