import  React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Menu } from 'semantic-ui-react'

export const MenuItem = (props) => {
  const { disabled, to, children, dispatch, ...rest} = props;
  const click = () => {
    dispatch(push(to));
  };
  return <Menu.Item onClick={disabled ? undefined : click} {...rest}>{children}</Menu.Item>;
}

MenuItem.displayName = 'MenuItem';

export default connect()(MenuItem);
