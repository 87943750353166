import React, { useState, useEffect } from "react";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { StepperNav } from "vertical-stepper-nav";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import { fetchUserDetails } from "../../../store/actions/userAccountDetailAction";
import { useMediaQuery } from "react-responsive";

import "./userDetail.css";

//routes
import { USER_AUDIT_ROUTE } from "../../../routes";

//images
import LeftArrowIcon from "../../../assets/images/incident/leftarrow.svg";
import RightArrowIcon from "../../../assets/images/incident/rightarrow.svg";
import DownArrowIcon from "../../../assets/images/incident/arrowdowndrop.svg";
import ExitIcon from "../../../assets/images/incident/exit.svg";

//pages
import UserProfile from "./forms/userProfile";
import UserPermission from "./forms/userPermission";
import UserAccount from "./forms/userAccount";
import UserReset from "./forms/userReset";

import { confirmAlert } from "react-confirm-alert";
import config from "../../../config/config";
import api from "../../../config/api";

function UserDetail(props) {
  let history = useHistory();
  const { SAVE_USER } = config.api_endpoint;
  var shouldSave = false;
  let pathName = window.location.pathname;
  const [detailSelected, setDetailSelected] = useState("Profile");
  const [userProfileLoaded, setUserProfileLoaded] = useState(false);
  const [showReactiveBtn, setShowReactiveBtn] = useState(false);
  const [showSuspendBtn, setShowSuspendBtn] = useState(false);
  const [profileValues, setProfileValues] = useState({});
  const [placeList, setPlaceList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const [userObj, setUserObj] = useState({
    account_expiry: "",
    department: "",
    email: "",
    mobile_no: "+65",
    name: "",
    park: "",
    role: "",
    staff_id: "",
    sub_department: "",
  });

  const onChangeProfileView = (key, value) => {
    setProfileValues({
      ...profileValues,
      [key]: value,
    });
  };

  useEffect(() => {
    if (userObj) {
      setProfileValues(userObj);
      setShowReactiveBtn(false);
      setShowSuspendBtn(false);
    }
  }, [userObj]);

  // user profile error list
  const [nameError, setNameError] = useState(false);
  const [emailErrorTxt, setEmailErrorTxt] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [staffidError, setStaffidError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [parkError, setParkError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [subdepartmentError, setSubdepartmentError] = useState(false);
  const [userProfileError, setUserProfileError] = useState(false);

  const validateUserProfile = () => {
    // const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // const regex = /[^a-z\d\-]/gi;

    const {
      name,
      email,
      mobile_no,
      staff_id,
      role,
      park,
      department,
      sub_department,
    } = profileValues;

    setUserProfileError(false);
    shouldSave = true; //setShouldSave(true);

    if (name == "") {
      setNameError("Required Field");
      shouldSave = false; // setShouldSave(false);
      setUserProfileError(true);
    } else {
      if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(name)) {
        shouldSave = false;
        setNameError("Must be valid.");
        setUserProfileError(true);
      } else {
        setNameError(false);
      }
    }

    if (email == "") {
      setEmailError("Required Field");
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      setEmailError("");
    }

    if (!regex.test(email)) {
      setEmailError("Email is invalid");
      shouldSave = false;
      setUserProfileError(true);
    } else {
      setEmailError("");
    }

    if (mobile_no == "") {
      setMobileError("Required Field");
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      let cloneMobileNo = mobile_no.replace("+", "");
      // if (!/^[0-9]+$/.test(cloneMobileNo)) {
      if (
        !/^[+]?\d+$/.test(cloneMobileNo) ||
        mobile_no == "+65" ||
        mobile_no.length > 13
      ) {
        setMobileError("Must be vaild");
        shouldSave = false; //setShouldSave(false);
        setUserProfileError(true);
      } else {
        setMobileError(false);
      }
    }

    if (staff_id == "") {
      setStaffidError("Please enter the staff id");
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      if (!/^[0-9]+$/.test(staff_id)) {
        setStaffidError("Must vaild");
        shouldSave = false; //setShouldSave(false);
        setUserProfileError(true);
      } else {
        setStaffidError(null);
      }
    }

    if (staff_id.length != 8 || parseInt(staff_id) == NaN) {
      setStaffidError("Required 8 numbers");
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      if (!/^[0-9]+$/.test(staff_id)) {
        setStaffidError("Must vaild");
        shouldSave = false; //setShouldSave(false);
        setUserProfileError(true);
      } else {
        setStaffidError(null);
      }
    }

    if (role == "") {
      setRoleError(true);
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      setRoleError(false);
    }

    if (park == "") {
      setParkError(true);
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      setParkError(false);
    }

    if (department == "") {
      setDepartmentError(true);
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      setDepartmentError(false);
    }

    if (sub_department == "") {
      setSubdepartmentError(true);
      shouldSave = false; //setShouldSave(false);
      setUserProfileError(true);
    } else {
      setSubdepartmentError(false);
    }
  };

  const sidebarcssColors = (title) => {
    if (title == "Profile" && userProfileError) {
      return "error";
    } else {
      return "nth";
    }
  };

  function customModal(onClose) {
    return (
      <div className="incidentAlertBox" onClick={onClose}>
        <div
          style={{
            width: 400,
            height: 200,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "13px 20px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={onClose}
          >
            <img src={ExitIcon} />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            Create user.
          </div>
          <div style={{ fontSize: 14, marginBottom: 18 }}>
            This email address already exist in the system. Please use another
            email address.
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div></div>
            <div>
              <Button
                className="downloadBtn"
                style={{ paddingLeft: 40 }}
                onClick={onClose}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const mainSaveButton = async () => {
    setEmailErrorTxt(null);

    validateUserProfile();

    if (shouldSave == true) {
      setShowLoader(true);
      api
        .post(
          SAVE_USER,
          JSON.stringify({
            ...profileValues,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          if (
            res.message == "Email already exists" ||
            res.message == "Existing user in firebase."
          ) {
            setEmailErrorTxt("Email already exists");
            confirmAlert({
              customUI: ({ onClose }) => customModal(onClose),
            });
            return setShowLoader(false);
          } else {
            setShowLoader(false);
            history.push("/user_management");
          }
        })
        .catch((err) => {
          console.log("User Error Saved: ", err);
          setShowLoader(false);
        });
    }
    // confirmAlert({
    //   title: "Confirm to submit",
    //   message: "Do you want to save the user details?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => {

    //       },
    //     },
    //     {
    //       label: "No",
    //       onClick: () => {},
    //     },
    //   ],
    // });
  };

  // left side bar menus
  let sidebar_menus = [
    {
      title: "Profile",
    },
    {
      title: "Account",
    },
    // {
    //   title: "Reset Password",
    // },
  ];

  const Sidebar = () => {
    return (
      <div className="user_detail_sidebar">
        {sidebar_menus.map((c, index) => {
          return (
            <div
              className={`ud_option_container ${
                c.title == detailSelected && "active"
              }`}
              onClick={() => {
                setDetailSelected(c.title);
              }}
            >
              <div style={{ width: "95%" }}>{c.title}</div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    background: "#E72D35",
                    color: "#fff",
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    display: "flex",
                    justifyContent: "center",
                    marginRight: 10,
                    opacity: sidebarcssColors(c.title) == "error" ? 1 : 0,
                  }}
                >
                  !
                </div>
                <img
                  src={
                    c.title == detailSelected ? RightArrowIcon : DownArrowIcon
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const MobileSideBar = () => {
    return (
      <div style={{ display: "flex", margin: "15px" }}>
        {sidebar_menus.map((c, index) => {
          return (
            <div
              className={`mobile_filter_tabs ${
                c.title == detailSelected && "active"
              }`}
              style={{ display: "flex", position: "relative" }}
            >
              <Button
                onClick={() => {
                  setDetailSelected(c.title);
                }}
              >
                {c.title}
              </Button>
              <div
                style={{
                  background: "#E72D35",
                  color: "#fff",
                  width: 20,
                  height: 20,
                  borderRadius: 50,
                  display: "flex",
                  justifyContent: "center",
                  marginRight: 10,
                  opacity: sidebarcssColors(c.title) == "error" ? 1 : 0,
                  position: "absolute",
                  top: 6,
                  right: 0,
                }}
              >
                !
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const UserSuspendPopup = () => {
    return (
      <Popup
        modal
        nested
        trigger={
          <div style={{ width: "100%" }}>
            <Button className="ud_suspend_button">Suspend account</Button>
          </div>
        }
        className="ud_suspend_popup"
      >
        {(close) => {
          return (
            <div className="ud_suspend_popup">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <img src={ExitIcon} onClick={close} />
              </div>
              <div>
                <h3 className="">Remarks to suspend account</h3>
                <textarea rows={8} className="suspend_text_area" />
              </div>
              <div>
                <Button className="suspend_submit_btn">Suspend</Button>
                <Button className="suspend_cancel_btn" onClick={close}>
                  Cancel
                </Button>
              </div>
            </div>
          );
        }}
      </Popup>
    );
  };

  const UserReactivePopup = () => {
    return (
      <Popup
        modal
        nested
        trigger={
          <div style={{ width: "100%" }}>
            <Button className="ud_reactive_button">Reactive account</Button>
          </div>
        }
        className="ud_reactive_popup"
      >
        {(close) => {
          return (
            <div className="ud_reactive_popup">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <img src={ExitIcon} onClick={close} />
              </div>
              <div>
                <div className="user_suspend_date_content">
                  <div className="user_suspend_date_title ">
                    Date of account created
                  </div>
                  <div className="user_suspend_date">
                    <span className="user_suspend_date_label">
                      30 Apri 2021 10:11:12
                    </span>
                  </div>
                </div>
                <div className="user_suspend_reason_content">
                  <div className="user_suspend_reason_title ">
                    Remarks to suspend account
                  </div>
                  <div className="user_suspend_reason">
                    <span className="user_suspend_reason_label">
                      User left job.
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <Button className="reactive_submit_btn">
                  Reactive account
                </Button>
                <Button className="reactive_cancel_btn" onClick={close}>
                  Cancel
                </Button>
              </div>
            </div>
          );
        }}
      </Popup>
    );
  };

  return (
    <div className="user_detail_container">
      <div className="user_detail">
        <div className="user_detail_header">
          <div
            to=""
            className="ud_header_leftside"
            onClick={() => history.goBack()}
          >
            <img src={LeftArrowIcon} style={{ marginTop: 0 }} />
            <div className="user_detail_title">
              {pathName == "/user_management/new_user_detail"
                ? "New User"
                : "User Details"}
            </div>
          </div>
        </div>
        {isMobile && <MobileSideBar />}
        <div className="user_detail_form">
          {showLoader == true ? (
            <Dimmer active>
              <Loader active> Loading </Loader>
            </Dimmer>
          ) : null}
          {!isMobile && <Sidebar />}

          <div className="ud_form ud_add_flex">
            <div className="ud_form_container ud_remove_width">
              {/* {console.log("data >> ", props.userObj)} */}
              {detailSelected == "Profile" && userObj !== undefined ? (
                <>
                  <UserProfile
                    values={profileValues && profileValues}
                    onChangeProfileView={onChangeProfileView}
                    profileViewInputError={{
                      nameError,
                      emailError,
                      mobileError,
                      staffidError,
                      roleError,
                      parkError,
                      departmentError,
                      subdepartmentError,
                      emailErrorTxt,
                    }}
                    optionsState={{
                      placeList,
                      setPlaceList,
                      roleList,
                      setRoleList,
                      departmentList,
                      setDepartmentList,
                      subDepartmentList,
                      setSubDepartmentList,
                    }}
                  />
                </>
              ) : detailSelected == "Account" && userObj !== undefined ? (
                <>
                  <UserAccount
                    values={profileValues && profileValues}
                    onChangeProfileView={onChangeProfileView}
                  />
                </>
              ) : detailSelected == "Reset Password" ? (
                <UserReset />
              ) : null}

              <div style={{ width: "100%", height: "60px" }}></div>
            </div>
            <div className="ud_incident_footer">
              <div style={{ display: "flex" }}>
                {detailSelected == "Account" && showSuspendBtn ? (
                  <UserSuspendPopup />
                ) : null}
              </div>
              <div style={{ display: "flex" }}>
                {detailSelected == "Account" && showReactiveBtn ? (
                  <UserReactivePopup />
                ) : null}
                <div style={{ width: "100%" }}>
                  <Button
                    className="ud_cancel_button"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </div>
                {detailSelected != "Reset Password" ? (
                  <div style={{ width: "100%" }}>
                    <Button className="ud_save_button" onClick={mainSaveButton}>
                      Save
                    </Button>
                  </div>
                ) : detailSelected == "Reset Password" ? (
                  <div style={{ width: "100%" }}>
                    <Button className="ud_reset_email">
                      Reset password email
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect()(UserDetail);
