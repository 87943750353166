import React from "react";

import "./Login.css";

import Logo from "../../assets/images/logo/logowhite.svg";
import Logo2 from "../../assets/images/logo/logoLoginWhite.svg";
import logo3 from "../../assets/images/logo/logo2.svg";
import One from "../../assets/images/circles/one.svg";
import Two from "../../assets/images/circles/two.svg";
import Three from "../../assets/images/circles/three.svg";
import Four from "../../assets/images/circles/four.svg";
import Five from "../../assets/images/circles/five.svg";
import Six from "../../assets/images/circles/six.svg";
import Login from "./Login";
import Forgot from "./Forgot";
import ResendOtp from "./ResendOtp";
import ResetPassword from "./ResetPassword";
import ResetPasswordIcon from "./ResetPasswordIcon";
import { Redirect, Route } from "react-router";
import {
  FORGOT_ROUTE,
  LOGIN_LINK,
  LOGIN_ROUTE,
  LOGIN_WITH_ID_ROUTE,
  RESET_OTP_ROUTE,
  RESET_PASSWORD_ROUTE,
  RESET_SUCCESS_ROUTE,
} from "../../routes";
import LoginWithID from "./LoginWithID";
import loginLink from "./loginLink";

function index(props) {
  function notFoundPage() {
    return (
      <div
        style={{
          color: "#333",
          fontSize: 25,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Go Back ! <br />
      </div>
    );
  }

  return (
    <>
      <div style={{ background: "#F8F8F8" }}>
        <div className="login_header">
          <div className="logo_container">
            <div className="logo">
              <img src={logo3} />
              {/* <img src={Logo2} /> */}
            </div>
            <div className="login_header_title">Incident Management System</div>
          </div>

          <div className="circle_one">
            <img src={One} />
          </div>
          <div className="circle_two">
            <img src={Two} />
          </div>
          <div className="circle_three">
            <img src={Three} />
          </div>
        </div>
        <div className="route_login">
          <Route
            exact
            path={LOGIN_ROUTE}
            render={() => <Login setLoggedIn={props.setLoggedIn} />}
          />
          <Route
            path={LOGIN_WITH_ID_ROUTE}
            render={() => <LoginWithID setLoggedIn={props.setLoggedIn} />}
          />
          <Route path={FORGOT_ROUTE} component={Forgot} />
          <Route path={LOGIN_LINK} component={loginLink} />
          <Route path={RESET_OTP_ROUTE} component={ResendOtp} />
          <Route path={RESET_PASSWORD_ROUTE} component={ResetPassword} />
          <Route path={RESET_SUCCESS_ROUTE} component={ResetPasswordIcon} />
          <Route
            render={() =>
              props.selected != "" &&
              props.selected != "login_with_id" &&
              props.selected != "forgot" &&
              props.selected != "reset_otp" &&
              props.selected != "reset_password" &&
              props.selected != "linkexistingaccount" &&
              props.selected != "reset_success" && <Redirect to="/" />
            }
          />
        </div>

        {/* <Route component={Login} /> */}
        <div className="circle_four">
          <img src={Four} />
        </div>
        <div className="circle_five">
          <img src={Five} />
        </div>
        <div className="circle_six">
          <img src={Six} />
        </div>
      </div>
    </>
  );
}

export default index;
