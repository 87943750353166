import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { storage } from "../../../../../../config/firebase";
import api from "../../../../../../config/api";
import config from "../../../../../../config/config";

//images
import GalleryIcon from "../../../../../../assets/images/incident/gallery.svg";

function IncidentUpdateForm(props) {
  const [images, setImages] = useState([]);

  const [remark, setRemark] = useState("");

  const { hideUpdateForm } = props;

  const { UPDATE_INCIDENTS } = config.api_endpoint;

  return (
    <div className="incident_overview_container">
      <div className="in_update_form_container">
        <div className="title">Photo</div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {props.incidentUpImg &&
            props.incidentUpImg.map((i) => (
              <div
                style={{
                  width: 180,
                  height: 150,
                  marginRight: 10,
                  marginBottom: 10,
                }}
              >
                {i && (
                  <img
                    src={URL.createObjectURL(i)}
                    width="180px"
                    height="150px"
                    style={{ borderRadius: 12 }}
                  />
                )}

                {/* 
                <img
                  src={i.img}
                  width="180px"
                  height="150px"
                  style={{ borderRadius: 12 }}
                /> */}
              </div>
            ))}
        </div>
        <div className="upload_image">
          <img src={GalleryIcon} />
          <input
            type="file"
            onChange={(e) => {
              // setImages([...images, { imglocal: e.target.files[0] }]);
              if (e.target.files[0]) {
                props.setIncidentUpImg([
                  ...props.incidentUpImg,

                  e.target.files[0],
                ]);
              }
            }}
            style={{ position: "absolute", opacity: 0, cursor: "pointer" }}
          />
          <span style={{ marginLeft: 8 }}>Upload image</span>
        </div>
        <div>
          <h3>Remark</h3>
          <textarea
            className="remark_text_area"
            style={{ marginBottom: 50 }}
            onChange={(e) => props.setIncidentUpRemark(e.target.value)}
          ></textarea>
        </div>
        {/* Footer */}
        <div
          className="incident_footer"
          style={{ bottom: -65, justifyContent: "flex-end" }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{ width: "100%" }}
              onClick={() => {
                props.setIncidentUpImg([]);
                hideUpdateForm();
              }}
            >
              <Button className="witness_cancel_button">Cancel</Button>
            </div>
            <div style={{ width: "100%" }} onClick={props.mainSaveButton}>
              <Button className="witness_save_button">Save </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncidentUpdateForm;
