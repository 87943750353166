import Firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyALA4FOoiqu-64pa907ILVi5e5bgtK_2lM",
  authDomain: "mandaiims.firebaseapp.com",
  projectId: "mandaiims",
  storageBucket: "mandaiims.appspot.com",
  messagingSenderId: "762532801174",
  appId: "1:762532801174:web:67f3dadbab3d3a56bcb0b7",
  measurementId: "G-2GD0BPLWDD",
};

const firebase = Firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
const Timestamp = Firebase.firestore.Timestamp;
let messaging = null;

if (Firebase.messaging.isSupported()) {
  console.log("Support Push Noti");
  messaging = firebase.messaging();
} else {
  console.log("Not supported push noti");
}
const provider = new Firebase.auth.OAuthProvider("microsoft.com");
provider.setCustomParameters({
  prompt: "consent",
  tenant: "68888baa-6230-4456-9d16-95d34db8fe8b",
});
export { db, storage, auth, messaging, Timestamp, provider };

// export const provider = new Firebase.auth.OAuthProvider("microsoft.com");
