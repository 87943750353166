import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import LockIcon from "../../assets/images/circles/lock.svg";
import ArrowLeftIcon from "../../assets/images/incident/leftarrow.svg";
import { Button } from "semantic-ui-react";

function ResetPasswordIcon() {
  const history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const email = query.get("email");
  console.log("email", email);
  return (
    <div className="login_form" style={{ zIndex: 9999999 }}>
      <div
        style={{
          fontSize: 15,
          fontWeight: "bold",
          marginBottom: 30,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <img
          src={ArrowLeftIcon}
          style={{ marginRight: 18 }}
          onClick={() => history.goBack()}
        />
        <span style={{ color: "#008237", fontSize: 18 }}>Forgot password</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <div style={{ width: 250, textAlign: "center" }}>
          <div>
            <img src={LockIcon} />
          </div>
          <div style={{ marginTop: 20, color: "#008237" }}>
            An email have been sent to you to reset your password.
          </div>
        </div>
        <Button
          className="otp_next"
          onClick={() => {
            history.push(`/reset_otp?email=${email}`);
          }}
        >
          Next &gt;
        </Button>
      </div>
    </div>
  );
}

export default ResetPasswordIcon;
