import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

//images
import ArrowDownDropDownIcon from "../assets/images/incident/arrowdowndrop.svg";

function SubDepartmentList(obj) {
  // later need to retrieve
  let departments = [];

  return (
    <Dropdown
      // search
      selection
      options={
        obj.options && obj.options.length > 0 ? obj.options : departments
      }
      value={obj["value"]}
      // defaultValue='All'
      icon={<img src={ArrowDownDropDownIcon} className={obj["icon_classes"]} />}
      className={obj["custom_classes"]}
      onChange={obj["onChange"]}
      disabled={obj.profileBool}
    />
  );
}

export default SubDepartmentList;
