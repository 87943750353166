import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

//images
import ArrowDownDropDownIcon from "../assets/images/incident/arrowdowndrop.svg";

function YearList(obj) {
  // later need to retrieve
  var today = new Date();
  var current_year = today.getFullYear();

  var years = [];
  var ages = [];

  for (var i = 5; i >= 1; i--) {
    years.push({
      key: current_year - i,
      text: current_year - i,
      value: current_year - i,
    });
  }

  for (var i = 0; i < 6; i++) {
    years.push({
      key: current_year + i,
      text: current_year + i,
      value: current_year + i,
    });
  }

  for (let i = 1; i < 100; i++) {
    ages.push({
      key: i,
      text: i,
      value: i,
    });
  }

  return (
    <Dropdown
      placeholder={obj["title"]}
      // search
      selection
      options={years}
      value={parseInt(obj["value"])}
      onChange={obj["onChange"]}
      icon={
        <img
          src={ArrowDownDropDownIcon}
          style={{
            float: "right",
            marginTop: "2px",
            marginRight: "-20px",
            color: "#008237",
            cursor: "pointer",
          }}
          className={obj["icon_classes"]}
        />
      }
      className={obj["custom_classes"]}
    />
  );
}

export default YearList;
