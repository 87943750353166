import React, { useState, useRef, useEffect } from "react";
import { Checkbox, Input, Button, Loader } from "semantic-ui-react";
import SignaturePad from "react-signature-canvas";
import Popup from "reactjs-popup";
import { storage } from "../../../../../../config/firebase";
import { useHistory } from "react-router-dom";

//css
import "reactjs-popup/dist/index.css";

// images
import ExitIcon from "../../../../../../assets/images/incident/exit.svg";
import DraftFooter from "../../../../../../components/DraftFooter";
import PendingApprovalFooter from "../../../../../../components/PendingApprovalFooter";
import PendingRejectFooter from "../../../../../../components/PendingRejectFooter";
import ReopenFooter from "../../../../../../components/ReopenFooter";
import ClosedFooter from "../../../../../../components/ClosedFooter";

function WitnessEmployee(props) {
  let history = useHistory();
  const [day, setDay] = useState("AM");
  const [rc, setRc] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null);
  const [previousSignature, setPreviousSignature] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const sigPad = useRef({});
  const [isView, setIsView] = useState(props.isView);
  const [values, setValues] = useState({
    witness_type: "employee",
    guest_name: "",
    guest_contact_number: "",
    guest_address: "",
    guest_email: "",
    guest_Signature: "",
    guest_remarks: "",
    witness_no: 0,
  });
  const [prevData, setPrevData] = useState(null);
  console.log("isView.....", props.isView);
  // error
  // const [noError, setNoError] = useState(false);
  // const [nameError, setNameError] = useState(false);
  // const [desError, setDesError] = useState(false);
  // const [contactError, setContactError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [remarkError, setRemarkError] = useState(false);

  const incidentStatus = props.incidentStatus;
  const {
    changeShowWitnessFormBool,
    changeHideWitnessFormBool,
    showWitnessForm,
    employeeForm,
    selectedDetail,
  } = props;

  const clearSignature = () => {
    sigPad.current.clear();
  };

  const doneSignature = () => {
    setSignatureImage(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
  };
  const previousIncident = props.previousIncident;
  function Navigation() {
    return (
      <>
        {incidentStatus == "Draft" ? (
          <DraftFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={props.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Pending Approval" ? (
          <PendingApprovalFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
            authDetail={props.AuthDetail}
            created_by={props.previousIncident.created_by}
          />
        ) : incidentStatus == "Approve" ? (
          <PendingRejectFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Closed" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={values.category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            onListenValueChange={onListenValueChange}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Open" ? (
          <ClosedFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            showNotiPage={props.showNotiPage}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : incidentStatus == "Archived" ? (
          <ReopenFooter
            mainSaveButton={props.mainSaveButton}
            setIncidentStatus={props.setIncidentStatus}
            setCheckStatus={props.setCheckStatus}
            perms={props.AuthDetail.perms}
            prevCategory={previousIncident.filtered_category}
            customModalForConfirmCancel={props.customModalForConfirmCancel}
            valueChanged={props.valueChanged}
            isArchived={true}
            showNotiPage={props.showNotiPage}
            onListenValueChange={props.onListenValueChange}
            is_emergency_incident={props.overViewValues.is_emergency_incident}
          />
        ) : (
          <div
            className="incident_footer"
            style={{ bottom: 0, justifyContent: "flex-end" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }} onClick={() => {}}>
                <Button
                  className="witness_cancel_button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  // function Navigation() {
  //   return (
  //     <>
  //       {incidentStatus == "Draft" ? (
  //         <DraftFooter
  //           mainSaveButton={props.mainSaveButton}
  //           setIncidentStatus={props.setIncidentStatus}
  //           setCheckStatus={props.setCheckStatus}
  //           perms={props.AuthDetail.perms}
  //           prevCategory={props.previousIncident.filtered_category}
  //           customModalForConfirmCancel={props.customModalForConfirmCancel}
  //           valueChanged={props.valueChanged}
  //           showNotiPage={props.showNotiPage}
  //           is_emergency_incident={props.overViewValues.is_emergency_incident}
  //         />
  //       ) : incidentStatus == "Pending Approval" ? (
  //         <PendingApprovalFooter
  //           mainSaveButton={props.mainSaveButton}
  //           setIncidentStatus={props.setIncidentStatus}
  //           setCheckStatus={props.setCheckStatus}
  //           perms={props.AuthDetail.perms}
  //           prevCategory={props.previousIncident.filtered_category}
  //           customModalForConfirmCancel={props.customModalForConfirmCancel}
  //           valueChanged={props.valueChanged}
  //           showNotiPage={props.showNotiPage}
  //         />
  //       ) : incidentStatus == "Approve" ? (
  //         <PendingRejectFooter
  //           mainSaveButton={props.mainSaveButton}
  //           setIncidentStatus={props.setIncidentStatus}
  //           setCheckStatus={props.setCheckStatus}
  //           perms={props.AuthDetail.perms}
  //           prevCategory={props.previousIncident.filtered_category}
  //           customModalForConfirmCancel={props.customModalForConfirmCancel}
  //           valueChanged={props.valueChanged}
  //           showNotiPage={props.showNotiPage}
  //         />
  //       ) : incidentStatus == "Closed" ? (
  //         <ReopenFooter
  //           mainSaveButton={props.mainSaveButton}
  //           setIncidentStatus={props.setIncidentStatus}
  //           setCheckStatus={props.setCheckStatus}
  //           perms={props.AuthDetail.perms}
  //           prevCategory={props.previousIncident.filtered_category}
  //           customModalForConfirmCancel={props.customModalForConfirmCancel}
  //           valueChanged={props.valueChanged}
  //           onListenValueChange={props.onListenValueChange}
  //           showNotiPage={props.showNotiPage}
  //         />
  //       ) : incidentStatus == "Open" ? (
  //         <ClosedFooter
  //           mainSaveButton={props.mainSaveButton}
  //           setIncidentStatus={props.setIncidentStatus}
  //           setCheckStatus={props.setCheckStatus}
  //           perms={props.AuthDetail.perms}
  //           prevCategory={props.previousIncident.filtered_category}
  //           customModalForConfirmCancel={props.customModalForConfirmCancel}
  //           valueChanged={props.valueChanged}
  //           showNotiPage={props.showNotiPage}
  //         />
  //       ) : (
  //         <div
  //           className="incident_footer"
  //           style={{ bottom: 0, justifyContent: "flex-end" }}
  //         >
  //           <div style={{ display: "flex" }}>
  //             <div style={{ width: "100%" }} onClick={() => {}}>
  //               <Button
  //                 className="witness_cancel_button"
  //                 onClick={() => {
  //                   history.goBack();
  //                 }}
  //               >
  //                 Cancel
  //               </Button>
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //     </>
  //   );
  // }
  let filterP4 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4");
  let filterP4A =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p4a");

  let filterP5 =
    props.AuthDetail.perms && props.AuthDetail.perms.some((p) => p == "p5");

  useEffect(() => {
    setLoader(false);
    let index = props.totalForms.length > 0 && props.totalForms.length - 1;
    let w_no =
      parseInt(
        props.totalForms.length > 0 &&
          props.totalForms[index].witness_no.split(/\s+/)[1]
      ) + 1;
    if (props.previousIncident && props.selectedWitnessNo) {
      let filteredData =
        props.previousIncident.case_details.witness_particulars.filter(
          (c) => c.witness_no == props.selectedWitnessNo
        );

      setPrevData(filteredData[0]);
    } else {
      setPrevData(null);
    }
    if (selectedDetail) {
      props.setIsView(true);
      const {
        guest_name,
        guest_contact_number,
        guest_address,
        guest_email,
        guest_Signature,
        witness_no,
        guest_remarks,
      } = selectedDetail;
      setValues({
        witness_type: "employee",
        guest_name,
        guest_contact_number,
        guest_address,
        guest_email,
        guest_Signature,
        witness_no,
        guest_remarks,
      });
      // setDay(shift_on_incident);
      let modifiedSignature;
      if (guest_Signature && guest_Signature.includes(":443")) {
        modifiedSignature = guest_Signature.replace(":443", "");
      } else {
        modifiedSignature = guest_Signature;
      }
      setSignatureImage(modifiedSignature);
      setPreviousSignature(modifiedSignature);
    } else {
      props.setIsView(false);
      setValues({
        witness_type: "employee",
        guest_name: "",
        guest_contact_number: "",
        guest_address: "",
        guest_email: "",
        guest_Signature: "",
        guest_remarks: "",
        // witness_no: `Witness ${
        //   props.totalForms.length == 0 ? 1 : props.totalForms.length + 1
        // }`,
        witness_no: `Witness ${props.totalForms.length == 0 ? 1 : w_no}`,
      });
      // setDay("AM");
      setSignatureImage(null);
      setPreviousSignature(null);
    }
  }, [selectedDetail]);

  const submitForm = () => {
    setRc(!rc);
    setSubmit(true);
    let data = {
      ...values,
      // shift_on_incident: day,
      guest_Signature:
        signatureImage && signatureImage.replace("data:image/png;base64,", ""),
    };
    if (data.guest_name != "") {
      if (!/^(?!-)(?!.*-$)[a-zA-Z- ]+$/.test(data.guest_name)) {
        setSubmit(false);
        setNameError("Must be valid.");
      } else {
        setNameError("");
        setSubmit(true);
      }
    }
    if (data.guest_contact_number != "") {
      if (data.guest_contact_number.length > 10) {
        setSubmit(false);
        setContactError("Must be valid.");
      } else if (!/^\d*(\.\d+)?$/.test(data.guest_contact_number)) {
        setSubmit(false);
        setContactError("Must be valid.");
      } else {
        setContactError("");
        setSubmit(true);
      }
    }

    if (data.guest_address != "") {
      if (/[$&+:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(data.guest_address)) {
        setSubmit(false);
        setAddressError("Must be valid.");
      } else {
        setAddressError("");
        setSubmit(true);
      }
    }

    if (data.guest_email != "") {
      if (
        !/^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/.test(
          data.guest_email
        )
      ) {
        setSubmit(false);
        setEmailError("Must be valid.");
      } else {
        setEmailError("");
        setSubmit(true);
      }
    } else {
      setEmailError("");
      setSubmit(true);
    }
    if (data.guest_remarks != "") {
      if (/[$&+:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(data.guest_remarks)) {
        setSubmit(false);
        setRemarkError("Must be valid.");
      } else {
        setRemarkError("");
        setSubmit(true);
      }
    }
  };

  useEffect(() => {
    let data = {
      ...values,
      // shift_on_incident: day,
      guest_Signature: signatureImage
        ? signatureImage.replace("data:image/png;base64,", "")
        : null,
    };
    console.log("nameError", nameError);
    console.log("contactError", contactError);
    console.log("addressError", addressError);
    console.log("remarkError", remarkError);
    if (
      !nameError &&
      !contactError &&
      !addressError &&
      !emailError &&
      !remarkError &&
      submit
    ) {
      if (data) {
        setLoader(true);

        // if (!noError && !nameError && !desError && !contactError) {
        if (previousSignature) {
          storage
            .refFromURL(previousSignature)
            .delete()
            .catch((err) => console.log("err", err));
        }
        if (previousSignature == signatureImage) {
          employeeForm(data);
          if (!props.prevData && !props.selectedWitnessNo) {
            createNewFormForAduit();
          }
          return changeHideWitnessFormBool();
        } else {
          const nameForImage = new Date().getTime() + Math.random();
          if (signatureImage) {
            if (!props.prevData && !props.selectedWitnessNo) {
              createNewFormForAduit();
            }
            storage
              .ref(`/signatures/${nameForImage}`)
              .putString(signatureImage, "data_url")
              .then(async (img) => {
                const image = await storage
                  .ref(`/signatures/${nameForImage}`)
                  .getDownloadURL();

                data.guest_Signature = image;
                console.log("image", image);
                const cloneRemovedPhotoValues = props.newAddedSignature;
                cloneRemovedPhotoValues.push(image);
                props.setNewAddedSignature(cloneRemovedPhotoValues);
                employeeForm(data);
                return changeHideWitnessFormBool();
              })
              .catch((err) => console.log("err", err));
          } else {
            employeeForm(data);
            return changeHideWitnessFormBool();
          }
        }
      } else {
        return null;
      }
    } else {
      console.log("weeeeeeeeee");
      return null;
    }
  }, [rc, , submit]);

  const onListenValueChange = props.onListenValueChange;

  const checkPreviousValueAndCurrent = (previous, current, key, number) => {
    let splitUnderScore = key.replaceAll("_", " ");

    if (previous != current) {
      onListenValueChange(
        number + key,
        `Update ${splitUnderScore} from ${previous} to ${current} in ${number} form `
      );
    } else {
      onListenValueChange(key, null);
    }
  };
  const createNewFormForAduit = () => {
    onListenValueChange(props.totalForms + 1, "Added Witness Form");
  };

  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        {/* First Line */}
        <div style={{ marginBottom: 30 }}>
          <h3 style={{ color: nameError && "#E72D35" }}>Name </h3>
          <Input
            disabled={props.isView === true ? true : false}
            fluid
            className={`damaged_input ${nameError && "error"}`}
            value={values.guest_name}
            onKeyPress={(event) => {
              if (!/^(?!-)(?!.*-$)[a-zA-Z- ]+$/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              setValues({
                ...values,
                guest_name: e.target.value,
              });
            }}
          />
          {nameError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              {nameError}
            </div>
          )}
        </div>
        {/* Second Line */}
        <div style={{ marginBottom: 30 }}>
          <h3 style={{ color: contactError && "#E72D35" }}>Contact Number </h3>
          <Input
            disabled={props.isView === true ? true : false}
            fluid
            className={`damaged_input ${contactError && "error"}`}
            value={values.guest_contact_number}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              setValues({
                ...values,
                guest_contact_number: e.target.value,
              });
            }}
          />
          {contactError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              {contactError}
            </div>
          )}
        </div>
      </div>
      {/* Third Line */}
      <div style={{ marginBottom: 30 }}>
        <h3 style={{ color: addressError && "#E72D35" }}>Address</h3>
        <Input
          disabled={props.isView === true ? true : false}
          fluid
          className={`damaged_input ${addressError && "error"}`}
          value={values.guest_address}
          onKeyPress={(event) => {
            if (/[$&+:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            setValues({
              ...values,
              guest_address: e.target.value,
            });
          }}
        />
        {addressError && (
          <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
            {addressError}
          </div>
        )}
      </div>
      {/* Fourth Line */}
      <div style={{ marginBottom: 30 }}>
        <h3 style={{ color: emailError && "#E72D35" }}>Email Address</h3>
        <Input
          disabled={props.isView === true ? true : false}
          fluid
          className={`damaged_input ${emailError && "error"}`}
          value={values.guest_email}
          onChange={(e) => {
            setValues({
              ...values,
              guest_email: e.target.value,
            });
          }}
        />
        {emailError && (
          <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
            {emailError}
          </div>
        )}
      </div>
      <div style={{ marginBottom: 30 }}>
        <h3 style={{ color: remarkError && "#E72D35" }}>Remarks</h3>
        <Input
          disabled={props.isView === true ? true : false}
          fluid
          className={`damaged_input ${remarkError && "error"}`}
          value={values.guest_remarks}
          onKeyPress={(event) => {
            if (/[$&+:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            setValues({
              ...values,
              guest_remarks: e.target.value,
            });
          }}
        />
        {remarkError && (
          <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
            {remarkError}
          </div>
        )}
      </div>
      {/* Check CheckBox */}
      {/* <div style={{ marginBottom: 30 }}>
        <h3>Shift on the Day of the Incident </h3>
        <div>
          <Checkbox
            radio
            label="AM"
            checked={day == "AM"}
            onChange={() => {
              setDay("AM");
              if (props.selectedWitnessNo && prevData) {
                checkPreviousValueAndCurrent(
                  prevData.shift_on_incident,
                  "AM",
                  "Shift_on_the_Day_of_the_Incident",
                  props.selectedWitnessNo
                );
              }
            }}
            className="checkBox"
          />
        </div>
        <div>
          <Checkbox
            radio
            label="PM"
            checked={day == "PM"}
            onChange={() => {
              setDay("PM");
              if (props.selectedWitnessNo && prevData) {
                checkPreviousValueAndCurrent(
                  prevData.shift_on_incident,
                  "PM",
                  "Shift_on_the_Day_of_the_Incident",
                  props.selectedWitnessNo
                );
              }
            }}
            className="checkBox"
          />
        </div>
      </div> */}
      <p>
        By signing this form, you consent to the collection, use and disclosure
        of your personal data for the purpose to manage and prepare any
        incidents or accidents report in accordance with the Personal Data
        Protection Act 2012 and our data protection policy is available at{" "}
        <a
          href="https://www.mandai.com/"
          target="_blank"
          style={{ color: "#228A4C" }}
        >
          www.mandai.com :{" "}
        </a>
      </p>

      <p>
        Please visit our website at{" "}
        <a
          href="https://www.mandai.com/"
          target="_blank"
          style={{ color: "#228A4C" }}
        >
          www.mandai.com{" "}
        </a>{" "}
        for further details on our data protection policy, including how you may
        access and correct your personal data or withdraw consent to the
        collection, use or disclosure of your personal data.
      </p>
      {/* eighth Line */}
      <div style={{ marginBottom: 100 }}>
        <h3 className="input_title">Signature</h3>
        <div
          disabled={props.isView === true ? true : false}
          style={{
            width: "100%",
            height: 360,
            // background: "#F8F8F8",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #D8D8D8",
            borderRadius: 12,
          }}
        >
          <Popup
            disabled={props.isView === true ? true : false}
            modal
            trigger={
              <div
                style={{
                  width: "95%",
                  height: 300,
                  border: "1px solid #D8D8D8",
                  borderRadius: 12,
                  backgroundImage: `url(${signatureImage})`,
                  backgroundRepeat: "no-repeat",
                  position: "relative",
                  backgroundPosition: "center",
                }}
              >
                {console.log(props.isView)}
                <div>
                  {" "}
                  <button
                    disabled={props.isView === true ? true : false}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSignatureImage(null);
                    }}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      background: "none",
                      padding: "10px 30px",
                      color: "#008237",
                      fontWeight: "bold",
                      border: "1px solid #008237",
                      borderRadius: 12,
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            }
            closeOnDocumentClick={false}
          >
            {(close) => {
              return (
                <div className="signature_Container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img src={ExitIcon} onClick={close} />
                  </div>
                  <h3 className="input_title">Signature</h3>
                  <SignaturePad
                    ref={sigPad}
                    canvasProps={{
                      className: "signatureCanvas",
                    }}
                  />
                  <div>
                    <button
                      onClick={clearSignature}
                      className="signature_clear_button"
                    >
                      Clear
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button onClick={close} className="signature_reset_button">
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        doneSignature();
                        close();
                      }}
                      className="signature_done_button"
                    >
                      Done
                    </button>
                  </div>
                </div>
              );
            }}
          </Popup>
        </div>
      </div>
      {props.isView === true ? null : (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: -50 }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{ width: "100%" }}
              onClick={() => {
                changeHideWitnessFormBool();
              }}
            >
              <Button className="witness_cancel_button">Cancel</Button>
            </div>
            <div style={{ width: "100%" }} onClick={submitForm}>
              <Button className="witness_save_button" style={{ width: 180 }}>
                {" "}
                {loader ? (
                  <Loader
                    active
                    inline="centered"
                    size="tiny"
                    inverted
                    indeterminate
                  />
                ) : (
                  <span>
                    {props.selectedDetail ? `Update Witness` : `Add Witness`}
                  </span>
                )}{" "}
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Main Footer */}

      {filterP4A &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" &&
        props.previousIncident.created_by == props.AuthDetail.id && (
          <>{Navigation()}</>
        )}
      {filterP4 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c1" && <>{Navigation()}</>}

      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c2" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c3" && <>{Navigation()}</>}
      {filterP5 &&
        props.previousIncident &&
        props.previousIncident.filtered_category == "c4" && <>{Navigation()}</>}
      {props.previousIncident &&
      props.previousIncident.filtered_category == "" ? (
        <>
          {filterP4 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
          {filterP5 && props.previousIncident && <>{Navigation()}</>}
        </>
      ) : null}
      {/* <div className="witness_footer">
        <div style={{ display: "flex" }}>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              changeHideWitnessFormBool();
            }}
          >
            <Button className="witness_cancel_button">Cancel</Button>
          </div>
          <div style={{ width: "100%" }} onClick={submitForm}>
            <Button className="witness_save_button">
              {" "}
              {loader ? (
                <Loader
                  active
                  inline="centered"
                  size="tiny"
                  inverted
                  indeterminate
                />
              ) : (
                <span>Save</span>
              )}{" "}
            </Button>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default WitnessEmployee;
