import userActionTypes from "../actions/actionTypes/userActionTypes";

const {
  GET_LOGGED_IN_USER_DETAIL,
  GET_LOGGED_IN_USER_DETAIL_SUCCESS,
  GET_LOGGED_IN_USER_DETAIL_FAIL,
  USER_LOGOUT,
  STORE_TOKEN_USER,
} = userActionTypes;

const intialState = {
  loggedIn: false,
  userDetail: {},
  loading: true,
  token: null,
};

const loggedInUserDetailReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_LOGGED_IN_USER_DETAIL:
      return {
        ...state,
        loggedIn: false,
        userDetail: {},
        loading: true,
      };
    case USER_LOGOUT:
      return {
        ...state,
        token: null,
        loggedIn: false,
        userDetail: {},
        loading: false,
      };
    case GET_LOGGED_IN_USER_DETAIL_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        userDetail: {
          ...action.payload.data,
          // is_active:
          //   action.payload.data.is_active == 0 &&
          //   action.payload.data.is_new == 1
          //     ? 1
          //     : action.payload.data.is_active,
          // is_new:
          //   action.payload.data.is_active == 0 &&
          //   action.payload.data.is_new == 1
          //     ? 0
          //     : action.payload.data.is_new,
        },
        loading: false,
      };
    case GET_LOGGED_IN_USER_DETAIL_FAIL:
      return {
        ...state,
        loggedIn: false,
        userDetail: {},
        loading: false,
      };
    case STORE_TOKEN_USER:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};
export default loggedInUserDetailReducer;
