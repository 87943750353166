import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Button } from "semantic-ui-react";
import Geocode from "react-geocode";
import { Link } from "react-router-dom";

// images
import LeftArrownIcon from "../../../../assets/images/incident/leftarrow.svg";

const gApi = "AIzaSyCsvLI--q5rNFAGwq0Cgr7IWkh6y11x1wQ";
Geocode.setApiKey(gApi);
Geocode.setLanguage("en");
Geocode.enableDebug();

const AnyReactComponent = ({ text, lat, lng, handleMarkerClick }) => (
  <div onClick={() => handleMarkerClick()}>
    <div
      style={{
        position: "relative",
        background: "#D0021B",
        color: "white",
        width: "130px",
        height: "30px",
        lineHeight: "30px",
        fontWeight: "bold",
        textAlign: "center",
        borderRadius: 12,
        top: -50,
        left: -60,
        fontSize: 13,
      }}
    >
      Current Location
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: "15px solid transparent",
          borderRight: " 15px solid transparent",
          borderTop: " 15px solid #D0021B",
          position: "absolute",
          left: 45,
        }}
      ></div>
    </div>
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: 36,
          height: 36,
          background: "rgba(208, 2, 27, 0.3)",
          borderRadius: 50,
          position: "absolute",
          top: -40,
          left: -18,
          zIndex: -1,
        }}
      ></div>
      <div
        style={{
          width: 20,
          height: 20,
          background: "#D0021B",
          borderRadius: 50,
          position: "absolute",
          left: -10,
          top: -33,
        }}
      ></div>
    </div>
  </div>
);

export class MapFullLocation extends Component {
  constructor(props) {
    super(props);
    this.state = { lat: null, lng: null, currentLocation: { lat: 0, lng: 0 } };
  }

  handleMarkerClick() {}
  refreshCurrentLocation = () => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        this.setState({
          lat,
          lng,
          currentLocation: pos,
        });
      }
    );
  };
  successLocation = (position) => {
    this.setState({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      currentLocation: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
    });
    Geocode.fromLatLng(
      position.coords.latitude,
      position.coords.longitude
    ).then(
      (response) => {
        const address = response.results[0].formatted_address;
        this.props.onChangeSelectedAddress(address);
        this.props.onChangeMainZone({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        this.props.onChangeZoneType("Other: Pin current location");
      },
      (error) => {
        console.error(error);
      }
    );
  };

  errorHandler = (e) => {
    console.log(e);
  };

  componentDidMount() {
    if (this.props.locationState) {
      const { lat, lng } = this.props.locationState;
      this.setState({
        lat,
        lng,
        currentLocation: { lat, lng },
      });
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.successLocation,
        this.errorHandler,
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 600000 }
      );
    } else {
      console.log("navigator not supported");
      alert("navigator not supported");
    }
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 1,
          height: "100vh",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            zIndex: 2,
            color: "#017A69",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 18,
            fontWeight: "bold",
            marginTop: 15,
          }}
          onClick={() => this.props.hideFullScreen()}
        >
          <img src={LeftArrownIcon} />
          <span style={{ marginLeft: 10 }}> Back</span>
        </div>
        <div style={{ height: "100vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: gApi,
            }}
            options={{
              fullscreenControl: false,
            }}
            center={this.state.currentLocation}
            zoom={16}
            onClick={(e) => {
              const pos = { lat: e.lat, lng: e.lng };
              this.props.onChangeLocation(e.lat, e.lng);
              this.setState({
                lat: e.lat,
                lng: e.lng,
                currentLocation: pos,
              });
              this.props.onChangeMainZone({
                lat: e.lat,
                lng: e.lng,
              });
              this.props.onChangeZoneType("Other: Pin current location");
              Geocode.fromLatLng(e.lat, e.lng).then(
                (response) => {
                  const address = response.results[0].formatted_address;
                  this.props.onChangeMainZone({
                    lat: e.lat,
                    lng: e.lng,
                  });
                  this.props.onChangeSelectedAddress(address);
                  this.props.onChangeZoneType("Other: Pin current location");
                },
                (error) => {
                  console.error(error);
                }
              );
            }}
          >
            <AnyReactComponent
              lat={this.state.lat}
              lng={this.state.lng}
              text="Current Location"
              handleMarkerClick={this.handleMarkerClick}
            />
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}

export default MapFullLocation;
