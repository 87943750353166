import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

//images
import ArrowDownDropDownIcon from "../assets/images/incident/arrowdowndrop.svg";

function TypeList(obj) {
  // later need to retrieve
  let types = [
    {
      key: "All",
      text: "All",
      value: "All",
    },
    {
      key: "Safety",
      text: "Safety",
      value: "Safety",
    },
    {
      key: "Place Management",
      text: "Place Management",
      value: "Place Management",
    },
    {
      key: "Security",
      text: "Security",
      value: "Security",
    },
    {
      key: "Others",
      text: "Others",
      value: "Others",
    },
  ];

  return (
    <Dropdown
      // search
      selection
      options={obj.options ? obj.options : types}
      // defaultValue="All"
      value={obj.value}
      icon={<img src={ArrowDownDropDownIcon} className={obj["icon_classes"]} />}
      className={obj["custom_classes"]}
      onChange={obj["onChange"]}
    />
  );
}

export default TypeList;
