import incidentActionTypes from "../actions/actionTypes/incidentAcionTypes";

const {
  FETCH_ALL_AUDIT_INCIDENT,
  FETCH_ALL_AUDIT_INCIDENT_SUCCESS,
  FETCH_ALL_AUDIT_INCIDENT_FAIL,
} = incidentActionTypes;

const initialState = {
  error: null,
  auditLists: [],
  loading: true,
  nextUrl: null,
  total_pages: 0,
};

const incidentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_AUDIT_INCIDENT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_AUDIT_INCIDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        nextUrl: action.payload.next,
        auditLists: action.payload.data,
        total_pages: action.payload.total_pages,
      };
    case FETCH_ALL_AUDIT_INCIDENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export default incidentReducer;
