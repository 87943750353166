import userAccountActionTypes from "./actionTypes/userAccountActionTypes";
import api from "../../config/api";
import config from "../../config/config";
import moment from "moment";

const { GET_USERS } = config.api_endpoint;

const { FETCH_ALL_USERS, FETCH_ALL_USERS_SUCCESS, FETCH_ALL_USERS_FAIL } =
  userAccountActionTypes;

export const fetchAllUsersRequest = () => {
  return {
    type: FETCH_ALL_USERS,
  };
};

export const fetchAllUsersSuccess = (data) => {
  return {
    type: FETCH_ALL_USERS_SUCCESS,
    payload: data,
  };
};

export const fetchAllUsersFail = (error) => {
  return {
    type: FETCH_ALL_USERS_FAIL,
    payload: error,
  };
};

export const fetchAllUsers = (
  fromDate,
  toDate,
  park,
  department,
  role,
  user_type,
  currentPage,
  sort,
  label,
  search
) => {
  return (dispatch) => {
    dispatch(fetchAllUsersRequest());
    fromDate = fromDate ? fromDate.replace(/\s/g, "%20") : fromDate;
    toDate = toDate ? toDate.replace(/\s/g, "%20") : toDate;
    park = park == "All" ? "" : park.replace(/\s/g, "%20");
    department = department == "All" ? "" : department.replace(/\s/g, "%20");
    role = role == "All" ? "" : role.replace(/\s/g, "%20");
    user_type = user_type == "All" ? "" : user_type;
    currentPage = currentPage - 1;
    sort = sort ? "desc" : "asc";
    return api
      .get(GET_USERS, {
        platform: "web",
        joined_from_date: fromDate,
        joined_to_date: toDate,
        role: role,
        park: park,
        department: department,
        user_type: user_type,
        page: currentPage,
        size: 10,
        sorting: sort,
        sorting_label: label,
        search,
      })
      .then((res) => {
        dispatch(fetchAllUsersSuccess(res));
      })
      .catch((err) => console.log("err", err));
  };
};
