import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
import createRootReducer from './reducers';

const createAppStore = (initialState, history) => {
  return createStore(
    createRootReducer(history),
    initialState,
    compose(
      applyMiddleware(
        thunk,
        routerMiddleware(history)
      )
    )
  )
}

export default createAppStore;
