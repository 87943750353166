import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import { useLocation, Redirect } from "react-router-dom";
import Navigation from "./components/Navigation";
import {
  DASHBOARD_ROUTE,
  INCIDENTS_LIST_ROUTE,
  GROUP_LIST_ROUTE,
  USER_LIST_ROUTE,
  LOGIN_ROUTE,
  SETTINGS_ROUTE,
  NOTIFICATION_List_ROUTE,
} from "./routes";
import Dashboard from "./views/dashboard";
import User from "./views/user_management/index";
import Incident from "./views/incidents/index";
import Settings from "./views/Settings";
import GroupIndex from "./views/group_management/index";
import Notification from "./views/Notifications/index";
import UserAuthForm from "./views/Login/";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import {
  getUserLoginDetail,
  userLogout,
  storeToken,
} from "./store/actions/loggedInUserDetailAction";
import {
  fetchAllIncidentsForExport,
  notiRedDot,
} from "./store/actions/incidentAction";
import config from "./config/config";
import api from "./config/api";
import ErrorBoundary from "./views/Errors/ErrorBoundary";

// Firestore
import { auth, db, messaging } from "./config/firebase";
import Login from "./views/Login/LoginWithID";

function App(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  let location = useLocation();
  let selected = location.pathname.split("/")[1];
  console.log(selected);

  const { SAVE_USER, STORE_DEVICE_TOKEN, SAVE_USER_AUDIT, GET_NOTIS_READ } =
    config.api_endpoint;

  // suspend users that set date  from localstorage
  useEffect(() => {
    const susUserList = JSON.parse(localStorage.getItem("suspendUserList"));
    let clonseSusUserList = susUserList;
    if (susUserList != null && susUserList.length > 0) {
      susUserList.map((s) => {
        if (new Date(s.selectedDate).getDay() == new Date().getDay()) {
          api
            .post(
              SAVE_USER,
              JSON.stringify({
                id: s.id,
                is_active: s.is_active,
              }),
              {
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((res) => {
              api
                .post(
                  SAVE_USER_AUDIT,
                  JSON.stringify({
                    user_id: s.audit.user_id,
                    email: s.audit.email,
                    changed_by: s.audit.changed_by,
                    description: [s.audit.description[0]],
                    platform: "web",
                  }),
                  {
                    headers: { "Content-Type": "application/json" },
                  }
                )
                .then((rese) => {
                  let finalList = clonseSusUserList.filter((c) => c.id != s.id);

                  localStorage.setItem(
                    "suspendUserList",
                    JSON.stringify(finalList)
                  );
                })
                .catch((erre) => {});
            })
            .catch((err) => {});
        }
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const currentDate = moment().toDate();
    const expireDate = localStorage.getItem("authExpire");

    if (new Date(expireDate) == "Invalid Date") {
      localStorage.removeItem("authExpire");

      setLoading(false);
      props.userLogout();
      return auth.signOut();
    } else if (new Date(currentDate) > new Date(expireDate)) {
      localStorage.removeItem("authExpire");

      setLoading(false);
      props.userLogout();
      return auth.signOut();
    } else {
      auth.onAuthStateChanged((user) => {
        if (user) {
          props.getUserLoginDetail(user.uid);
          fetchNotiReadCount();

          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  }, [selected]);
  console.log(props);

  useEffect(() => {
    if (props.AuthDetail.is_active == -1) {
      localStorage.removeItem("authExpire");
      window.location.href = `/?minusError=True`;

      props.userLogout();
      return auth.signOut();
    }

    if (props.AuthDetail.is_active == 0 && props.AuthDetail.is_new == 0) {
      localStorage.removeItem("authExpire");
      window.location.href = `/?disableerror=True`;
      props.userLogout();
      return auth.signOut();
    }

    if (props.AuthDetail.is_active == 1 && props.AuthDetail.is_new == 1) {
      console.log(props);
      localStorage.removeItem("authExpire");

      window.location.href = `/reset_password?email=${props.AuthDetail.email}&id=${props.AuthDetail.id}`;
      props.userLogout();
      return auth.signOut();
    }

    if (props.AuthDetail.is_active == 1 && props.AuthDetail.is_new == 0) {
      const from_date = moment().startOf("month").format("D MMM yyyy");

      const end_date = moment().endOf("month").format("D MMM yyyy");
    }
    if (props.AuthDetail.is_active == 0 && props.AuthDetail.is_new == 1) {
      const from_date = moment().startOf("month").format("D MMM yyyy");

      const end_date = moment().endOf("month").format("D MMM yyyy");
      auth.onAuthStateChanged((user) => {
        if (user) {
          api
            .post(
              SAVE_USER,
              JSON.stringify({
                id: user.uid,
                is_active: 1,
                is_new: 0,
                platform: "web",
              })
            )
            .then((result) => {})
            .catch((err) => console.log("err", err));
        } else {
          props.userLogout();
          auth.signOut();
        }
      });
    }
    if (Object.keys(props.AuthDetail).length == 0) {
      setLoading(false);
    }
  }, [props.AuthDetail]);

  const fetchNotiReadCount = () => {
    api
      .get(GET_NOTIS_READ, {
        platform: "web",
        user_id: props.AuthDetail.id,
      })
      .then((res) => {
        props.notiRedDot(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        return props.userLogout();
      } else {
        fetchNotiReadCount();
        if (messaging) {
          messaging
            .requestPermission()
            .then(() => {
              return messaging.getToken();
            })
            .then((token) => {
              api
                .post(
                  STORE_DEVICE_TOKEN,
                  JSON.stringify({
                    user_id: user.uid,
                    platform: "web",
                    device_token: token,
                  })
                )
                .then((res) => {
                  props.storeToken(token);
                })
                .catch((err) => console.log("err", err));
            })
            .catch((err) => console.log(err));
        }

        if (messaging) {
          messaging.onMessage((payload) => {
            fetchNotiReadCount();
          });
        }
      }
    });
  }, []);

  // useEffect(() => {
  //   if (
  //     window.location.hostname == "mandaiims.web.app" ||
  //     window.location.hostname == "mandaiims.firebaseapp.com"
  //   ) {
  //     window.location.assign("https://ims.mab.link/");
  //   }
  // }, []);

  return (
    <div>
      {loading || props.AuthLoading ? (
        <div
          style={{
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Dimmer active inverted>
            <Loader inverted></Loader>
          </Dimmer>
        </div>
      ) : props.AuthDetail.is_active == 1 && props.AuthDetail.is_new == 1 ? (
        <Redirect
          to={`/reset_password?email=${props.AuthDetail.email}&id=${props.AuthDetail.id}`}
        />
      ) : props.AuthDetail.is_active == 0 && props.AuthDetail.is_new == 0 ? (
        <Redirect to={`/`} />
      ) : !loading && !props.AuthLoggedIn ? (
        <>
          <UserAuthForm
            loggedIn={loggedIn}
            selected={selected}
            setLoggedIn={setLoggedIn}
          />
        </>
      ) : (
        <Navigation selected={selected}>
          <ErrorBoundary>
            <Route exact={true} path={DASHBOARD_ROUTE} component={Dashboard} />
            <Route path={INCIDENTS_LIST_ROUTE} component={Incident} />
            <Route path={USER_LIST_ROUTE} component={User} />
            <Route path={GROUP_LIST_ROUTE} component={GroupIndex} />
            <Route path={SETTINGS_ROUTE} component={Settings} />
            <Route path={NOTIFICATION_List_ROUTE} component={Notification} />
          </ErrorBoundary>
        </Navigation>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  AuthDetail: state.loggedInUserDetail.userDetail,
  AuthLoggedIn: state.loggedInUserDetail.loggedIn,
  AuthLoading: state.loggedInUserDetail.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getUserLoginDetail: (id) => dispatch(getUserLoginDetail(id)),
  fetchAllIncidentsForExport: (fromDate, toDate) =>
    dispatch(fetchAllIncidentsForExport(fromDate, toDate)),
  userLogout: () => dispatch(userLogout()),
  storeToken: (token) => dispatch(storeToken(token)),
  notiRedDot: (data) => dispatch(notiRedDot(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
