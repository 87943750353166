import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Dropdown, Loader } from "semantic-ui-react";
import { Chart } from "react-google-charts";
import moment from "moment";
import axios from "axios";
import config from "../../config/config";
import api from "../../config/api";
import momentTz from "moment-timezone";
import { db, auth } from "../../config/firebase";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Dropdowns
import PlaceList from "../../components/PlaceList";
import MonthList from "../../components/MonthList";
import YearList from "../../components/YearList";
import ChartList from "../../components/ChartList";

const BuildForm = (props) => {
  let form_id = "",
    form_category = "",
    form_main_place = "",
    form_sub_place = "",
    form_datetime = "",
    form_type = "";

  form_id = props.form_id;
  form_category = props.form_category;
  form_main_place = props.form_main_place;
  form_sub_place = props.form_sub_place;
  form_datetime = props.form_datetime;
  form_type = props.form_type;

  return (
    <div className="form-indi">
      <div className="form-indi-left">
        <div className="form-indi-type">{form_type}</div>
        <div>
          <p className="form-indi-mainplace">{form_main_place}</p>
          <p className="form-indi-subplace">{form_sub_place}</p>
          <p className="form-indi-datetime">{form_datetime}</p>
        </div>
      </div>
      <div className="form-indi-right">
        <p className="form-indi-id">{form_id}</p>
        <br></br>
        <br></br>
        <div className="form-indi-cat-div">
          <p>{form_category}</p>
        </div>
      </div>
    </div>
  );
};
const initialState = {};

function IncidentList(props) {
  const [queryDate, setQueryDate] = useState(momentTz().format("MMMYYYY"));
  const [queryYear, setQueryYear] = useState(momentTz().format("YYYY"));
  const [queryMonth, setQueryMonth] = useState(momentTz().format("MMMM"));
  const [queryPlace, setQueryPlace] = useState("Singapore Zoo");
  const [loader, setLoader] = useState(true);

  const [showCatPie, setShowCatPie] = useState(false);
  const [categoryCount, setCategoryCount] = useState({
    c1: 0,
    c2: 0,
    c3: 0,
    c4: 0,
  });
  const [incidentStatusCount, setIincidentStatusCount] = useState({
    all: 0,
    archived: 0,
    closed: 0,
    draft: 0,
    open: 0,
    pending_approval: 0,
    pending_save: 0,
  });
  const [typeIncidentCount, setTypeIncidentCount] = useState({
    others: 0,
    place_management: 0,
    safety: 0,
    security: 0,
  });
  const [showTypePie, setShowTypePie] = useState(false);
  const [showStatusPie, setShowStatusPie] = useState(false);

  // api
  const { url } = config;
  const { SAVE_USER, GET_DASHBOARD_COUNT } = config.api_endpoint;
  const { GET_INCIDENTS } = config.api_endpoint;
  // pie charts data
  const [catPie, setCatPie] = useState({
    c1: 0,
    c2: 0,
    c3: 0,
    c4: 0,
  });

  const [typePie, setTypePie] = useState([
    ["Type", "No of forms"],
    ["Safety", 0],
    ["Security", 0],
    ["Place Management", 0],
    ["Others", 0],
  ]);

  const [statusPie, setStatusPie] = useState([
    ["Status", "No of forms"],
    ["Open", 0],
    ["Pending Approval", 0],
    ["Draft", 0],
    ["Closed", 0],
    ["Archived", 0],
  ]);

  const [parkList, setParkList] = useState([]);

  useEffect(() => {
    db.collection("static_data")
      .doc("parks")
      .get()
      .then((querySnapshot) => {
        let parkData = [];

        parkData.push({
          key: "All",
          text: "All",
          value: "All",
        });
        querySnapshot.data().parks_arr.forEach((doc, index) => {
          parkData.push({
            key: index,
            text: doc.park,
            value: doc.park,
          });
        });

        setParkList(parkData);
      })
      .catch((err) => {
        console.log("Error getting dashboard parks:", err);
      });
  }, []);

  const constmonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setLoader(true);
    const localItems = JSON.parse(localStorage.getItem("pending"));
    let filterLocalItems = [];

    let itemsLength = 0;
    if (localItems == null || localItems == undefined) {
      itemsLength = 0;
    } else {
      localItems.map((l, index) => {
        if (
          new Date(l.latest_updated_at).getFullYear() == queryYear &&
          constmonths[new Date(l.latest_updated_at).getMonth()] == queryMonth
        ) {
          filterLocalItems.push(l);
        }
      });

      itemsLength = filterLocalItems.length;
    }

    api
      .get(GET_DASHBOARD_COUNT, {
        platform: "web",
        year: queryYear,
        month: queryMonth,
        park: queryPlace == "All" ? "" : queryPlace,
        user_id: props.AuthDetail.id,
      })
      .then((res) => {
        setCategoryCount(res.category);
        setIincidentStatusCount({
          ...res.incident_status,
          pending_save: itemsLength,
        });
        setTypeIncidentCount(res.incident_type_numbers);

        // if (
        //   res.category.c1 == 0 &&
        //   res.category.c2 == 0 &&
        //   res.category.c3 == 0 &&
        //   res.category.c4 == 0
        // ) {
        //   setShowCatPie(false);
        //   setLoader(false);
        // } else {
        setShowCatPie(true);
        setCatPie({
          cat1: res.category.c1,
          cat2: res.category.c2,
          cat3: res.category.c3,
          cat4: res.category.c4,
        });
        setLoader(false);
        // }

        // if (
        //   res.incident_type_numbers.Safety == 0 &&
        //   (res.incident_type_numbers.Security == 0) &
        //     (res.incident_type_numbers["Place Management"] == 0) &&
        //   res.incident_type_numbers.Others == 0
        // ) {
        //   setShowTypePie(false);
        //   setLoader(false);
        // } else {
        setShowTypePie(true);
        setTypePie(
          [
            ["Type", "No of forms"],
            ["Safety", res.incident_type_numbers.Safety],
            ["Place Management", res.incident_type_numbers["Place Management"]],
            ["Security", res.incident_type_numbers.Security],

            ["Others", res.incident_type_numbers.Others],
          ],
          [queryDate]
        );
        setLoader(false);
        // }

        // if (
        //   res.incident_status.Closed == 0 &&
        //   res.incident_status.Open == 0 &&
        //   res.incident_status["Pending Approval"] == 0 &&
        //   res.incident_status.Draft == 0 &&
        //   res.incident_status.Archived == 0 &&
        //   itemsLength == 0
        // ) {
        //   setShowStatusPie(false);
        // } else {
        //   setShowStatusPie(true);
        setStatusPie(
          [
            ["Status", "No of forms"],
            ["Open", res.incident_status.Open],
            ["Pending Approval", res.incident_status["Pending Approval"]],
            ["Draft", res.incident_status.Draft],
            ["Pending Save", itemsLength],
            ["Closed", res.incident_status.Closed],
            ["Archived", res.incident_status.Archived],
          ],
          [queryDate]
        );
        setLoader(false);
        // }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [queryDate, queryYear, queryMonth, queryPlace]);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const getToken = await auth.currentUser.getIdToken();

        axios
          .post(
            url + SAVE_USER,
            {
              id: user.uid,
              is_active: 1,
              is_new: 0,
              platform: "web",
            },
            {
              headers: { Authorization: `Bearer ${getToken}` },
            }
          )
          .then((result) => {})
          .catch((err) => console.log("err", err));
      } else {
        auth.signOut();
      }
    });
  });

  const dots = (color) => {
    return (
      <div
        style={{
          width: 10,
          height: 10,
          background: color,
          borderRadius: 50,
          marginRight: 8,
        }}
      ></div>
    );
  };

  const pieCatdata = {
    labels: ["Cat 1", "Cat 2", "Cat 3", "Cat 4"],
    datasets: [
      {
        label: "Categories",
        data: [catPie.cat1, catPie.cat2, catPie.cat3, catPie.cat4],
        backgroundColor: ["#0DBE57", "#ECBB00", "#FF8962", "#E85757"],

        borderWidth: 1,
      },
    ],
  };

  const pieTypeIncidentdata = {
    labels: ["Safety", "Security", "Place Management", "Others"],
    datasets: [
      {
        label: "Categories",
        data: [
          typeIncidentCount.Safety,
          typeIncidentCount["Security"],
          typeIncidentCount["Place Management"],
          typeIncidentCount["Others"],
        ],
        backgroundColor: ["#008237", "#2D8CE3", "#E72D35", "#8F8F8F"],

        borderWidth: 1,
      },
    ],
  };
  const pieTypeStatusdata = {
    labels: [
      "Open",
      "Pending Approval",
      "Draft",
      "Pending Save",
      "Closed",
      "Archived",
    ],
    datasets: [
      {
        label: "Categories",
        data: [
          incidentStatusCount["Open"],
          incidentStatusCount["Pending Approval"],
          incidentStatusCount["Draft"],
          incidentStatusCount["pending_save"],
          incidentStatusCount["Closed"],
          incidentStatusCount["Archived"],
        ],
        backgroundColor: [
          "#ECBB00",
          "#E72D35",
          "#ff8c00",
          "#2D8CE3",
          "#089B46",
          "#8F8F8F",
        ],

        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,

    plugins: {
      datalabels: {
        display: true,
        color: "white",
        formatter: (value, ctx) => {
          if (value == 0) {
            return null;
          } else {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += data;
            });
            let percentage = ((value * 100) / sum).toFixed(2) + "%";
            if (((value * 100) / sum).toFixed(2) < 7) {
              return null;
            } else {
              return percentage;
            }
          }
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div id="dashboard-div">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 15,
        }}
        className="filter-list"
      >
        <div style={{}}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 14,
              marginLeft: 20,
            }}
          >
            Parks
          </div>
          <PlaceList
            title="place-list dashboard-dropdown"
            custom_classes="place-list dashboard-dropdown"
            icon_classes="dashboard-place-dropdown-icon"
            value={queryPlace}
            options={parkList}
            onChange={(e, data) => {
              setQueryPlace(data.value);
            }}
          />
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              fontSize: 14,
              marginLeft: 20,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 14,
                marginLeft: 10,
              }}
            >
              Months
            </div>
            <MonthList
              title="Select Month"
              custom_classes="month-list dashboard-dropdown"
              value={queryMonth}
              onChange={(e, data) => {
                var _sdate = "01 " + data.value + " " + queryYear;

                _sdate = momentTz(_sdate);

                setQueryMonth(data.value);
                setQueryDate(_sdate.format("MMM-YYYY"));
              }}
            />
          </div>
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 14,
              }}
            >
              Year
            </div>

            <YearList
              title="Select Year"
              custom_classes="year-list dashboard-dropdown"
              value={queryYear}
              onChange={(e, data) => {
                var _sdate = "01-" + queryMonth + "-" + data.value;

                _sdate = momentTz(_sdate);

                setQueryYear(data.value);
                setQueryDate(_sdate.format("MMM-YYYY"));
              }}
            />
          </div>
        </div>
      </div>

      {loader ? (
        <div style={{ position: "absolute", left: "50%" }}>
          <Loader active inline="centered" />
        </div>
      ) : (
        <div className="dahsboard_chart_container">
          <div className="chart_container">
            <div className="chart_title">Incident Category</div>
            {categoryCount.c1 !== 0 ||
            categoryCount.c2 !== 0 ||
            categoryCount.c3 !== 0 ||
            categoryCount.c4 !== 0 ? (
              <div style={{ padding: 30 }}>
                <Pie
                  data={pieCatdata}
                  options={options}
                  plugins={[ChartDataLabels]}
                />
              </div>
            ) : (
              <h3 style={{ textAlign: "center", marginBottom: 50 }}>
                No Data{" "}
              </h3>
            )}

            <div className="value_container">
              <div className="values_div">
                {dots("#0DBE57")}Cat1 -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {categoryCount.c1}
                </span>
              </div>
              <div className="values_div">
                {dots("#ECBB00")}Cat2 -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {categoryCount.c2}
                </span>
              </div>

              <div className="values_div">
                {dots("#FF8962")}Cat3 -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {categoryCount.c3}
                </span>
              </div>

              <div className="values_div">
                {dots("#E85757")}Cat4 -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {categoryCount.c4}
                </span>
              </div>
            </div>
          </div>
          <div className="chart_container">
            <div className="chart_title">Type of Incident</div>
            {typeIncidentCount.Safety !== 0 ||
            typeIncidentCount["Security"] !== 0 ||
            typeIncidentCount["Place Management"] !== 0 ||
            typeIncidentCount["Others"] !== 0 ? (
              <div style={{ padding: 30 }}>
                <Pie
                  data={pieTypeIncidentdata}
                  options={options}
                  plugins={[ChartDataLabels]}
                />
              </div>
            ) : (
              <h3 style={{ textAlign: "center", marginBottom: 50 }}>
                No Data{" "}
              </h3>
            )}

            <div className="value_container">
              <div className="values_div">
                {dots("#008237")}Safety -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {typeIncidentCount.Safety}
                </span>
              </div>

              <div className="values_div">
                {dots("#2D8CE3")}Security -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {typeIncidentCount["Security"]}
                </span>
              </div>

              <div className="values_div">
                {dots("#E72D35")}Place Management -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {typeIncidentCount["Place Management"]}
                </span>
              </div>

              <div className="values_div">
                {dots("#8F8F8F")}Others -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {typeIncidentCount["Others"]}
                </span>
              </div>
            </div>
          </div>
          <div className="chart_container">
            <div className="chart_title">Type of Status</div>
            {incidentStatusCount["Open"] !== 0 ||
            incidentStatusCount["Pending Approval"] !== 0 ||
            incidentStatusCount["Draft"] !== 0 ||
            incidentStatusCount["pending_save"] !== 0 ||
            incidentStatusCount["Closed"] !== 0 ||
            incidentStatusCount["Archived"] !== 0 ? (
              <div style={{ padding: 30 }}>
                <Pie
                  data={pieTypeStatusdata}
                  options={options}
                  plugins={[ChartDataLabels]}
                />
              </div>
            ) : (
              <h3 style={{ textAlign: "center", marginBottom: 50 }}>
                No Data{" "}
              </h3>
            )}

            <div className="value_container">
              <div className="values_div">
                {dots("#ECBB00")}Open -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {incidentStatusCount["Open"]}
                </span>
              </div>

              <div className="values_div">
                {dots("#E72D35")}Pending Approval -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {incidentStatusCount["Pending Approval"]}
                </span>
              </div>

              <div className="values_div">
                {dots("#ff8c00")}Draft -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {incidentStatusCount["Draft"]}
                </span>
              </div>

              <div className="values_div">
                {dots("#2D8CE3")} Pending Save -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {incidentStatusCount["pending_save"]}
                </span>
              </div>

              <div className="values_div">
                {dots("#0DBE57")}Closed -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {incidentStatusCount["Closed"]}
                </span>
              </div>

              <div className="values_div">
                {dots("#8F8F8F")}Archived -{" "}
                <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                  {incidentStatusCount["Archived"]}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  AuthDetail: state.loggedInUserDetail.userDetail,
});
export default connect(mapStateToProps, null)(IncidentList);
