import React, { useState, useEffect } from "react";
import { Checkbox, Input, Button } from "semantic-ui-react";
import WitnessEmployee from "./WitnessEmployee";
import WitnessGuest from "./WitnessGuest";

function WitnessForm(props) {
  const [checkBox, setCheckBox] = useState("guest");
  const [checkdayIncident, setCheckIncident] = useState(null);
  const {
    changeShowWitnessFormBool,
    changeHideWitnessFormBool,
    showWitnessForm,
  } = props;

  const employeeForm = (values) => {
    props.addForm({
      employee_or_guest: "employee",
      ...values,
    });
  };

  const guestForm = (values) =>
    props.addForm({
      employee_or_guest: "guest",
      ...values,
    });

  useEffect(() => {
    if (props.selectedDetail) {
      setCheckBox(props.selectedDetail.employee_or_guest);
    } else {
      setCheckBox("employee");
    }
  }, [props.selectedDetail]);

  return (
    <div className="witness_form_container">
      <div className="witness_form">
        <div className="witness_form_title">Witness particulars</div>
      </div>
      {/* Check CheckBox */}
      {/* <div style={{ marginBottom: 30 }}>
        <h3>Witness is an employee or a guest? </h3>
        <div>
          <Checkbox
            radio
            label="Employee"
            checked={checkBox == "employee"}
            onChange={() => setCheckBox("employee")}
            className="checkBox"
          />
        </div>
        <div>
          <Checkbox
            radio
            label="Guest"
            checked={checkBox == "guest"}
            onChange={() => setCheckBox("guest")}
            className="checkBox"
          />
        </div>
      </div> */}

      {checkBox == "employee" ? (
        <WitnessEmployee
          employeeForm={employeeForm}
          changeShowWitnessFormBool={changeShowWitnessFormBool}
          changeHideWitnessFormBool={changeHideWitnessFormBool}
          showWitnessForm={showWitnessForm}
          selectedDetail={props.selectedDetail}
          totalForms={props.totalForms}
          mainSaveButton={props.mainSaveButton}
          showNotiPage={props.showNotiPage}
          overViewValues={props.overViewValues}
          paramPendingBool={props.paramPendingBool}
          changedValue={props.changedValue}
          customModalForConfirmCancel={props.customModalForConfirmCancel}
          newAddedSignature={props.newAddedSignature}
          setNewAddedSignature={props.setNewAddedSignature}
        />
      ) : (
        <WitnessGuest
          guestForm={guestForm}
          changeShowWitnessFormBool={changeShowWitnessFormBool}
          changeHideWitnessFormBool={changeHideWitnessFormBool}
          showWitnessForm={showWitnessForm}
          selectedDetail={props.selectedDetail}
          totalForms={props.totalForms}
        />
      )}
    </div>
  );
}

export default WitnessForm;
