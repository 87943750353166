import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

//images
import ArrowDownDropDownIcon from "../assets/images/incident/arrowdowndrop.svg";

function CategoryList(obj) {
  // later need to retrieve
  let categories = [
    {
      key: "All",
      text: "All",
      value: "All",
    },
    {
      key: "C1",
      text: "C1",
      value: "C1",
    },
    {
      key: "C2",
      text: "C2",
      value: "C2",
    },
    {
      key: "C3",
      text: "C3",
      value: "C3",
    },
    {
      key: "C4",
      text: "C4",
      value: "C4",
    },
  ];
  let filterCategories = [];
  let filterP2 = obj.perms.some((p) => p == "p2");
  let filterP3 = obj.perms.some((p) => p == "p3");
  let filterP2a = obj.perms.some((p) => p == "p2a");
  let filterP3a = obj.perms.some((p) => p == "p3a");

  if (filterP2 && filterP3) {
    filterCategories.push({
      key: "All",
      text: "All",
      value: "All",
    });
  }

  if (filterP2) {
    filterCategories.push({
      key: "C1",
      text: "C1",
      value: "C1",
    });
  }
  if (filterP3) {
    filterCategories.push(
      {
        key: "C2",
        text: "C2",
        value: "C2",
      },
      {
        key: "C3",
        text: "C3",
        value: "C3",
      },
      {
        key: "C4",
        text: "C4",
        value: "C4",
      }
    );
  }
  if (filterP2a && filterP3a) {
    filterCategories.push(
      {
        key: "C1",
        text: "C1",
        value: "C1",
      },
      {
        key: "C2",
        text: "C2",
        value: "C2",
      },
      {
        key: "C3",
        text: "C3",
        value: "C3",
      },
      {
        key: "C4",
        text: "C4",
        value: "C4",
      }
    );
  } else {
    if (filterP2a) {
      filterCategories.push({
        key: "C1",
        text: "C1",
        value: "C1",
      });
    } else if (filterP3a) {
      filterCategories.push(
        {
          key: "C2",
          text: "C2",
          value: "C2",
        },
        {
          key: "C3",
          text: "C3",
          value: "C3",
        },
        {
          key: "C4",
          text: "C4",
          value: "C4",
        }
      );
    }
  }
  return (
    <Dropdown
      search
      selection
      // options={filterCategories}
      options={categories}
      // defaultValue="All"
      value={obj.value}
      icon={<img src={ArrowDownDropDownIcon} className={obj["icon_classes"]} />}
      className={obj["custom_classes"]}
      onChange={obj.onChange}
    />
  );
}

export default CategoryList;
