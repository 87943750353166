import React, { useState, useRef, useEffect } from "react";
import {
  Input,
  Grid,
  Dropdown,
  Button,
  Loader,
  Dimmer,
  Checkbox,
} from "semantic-ui-react";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import YearList from "../../../../../components/YearList";
import { useHistory } from "react-router";
import { storage, db } from "../../../../../config/firebase";
import { confirmAlert } from "react-confirm-alert";
import { useMediaQuery } from "react-responsive";
//css
import "reactjs-popup/dist/index.css";

// images
import ArrowDownDropDownIcon from "../../../../../assets/images/incident/arrowdowndrop.svg";
import ExitIcon from "../../../../..//assets/images/incident/exit.svg";

function CasualtyForm(props) {
  const history = useHistory();
  const [submit, setSubmit] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null);
  const [previousSignature, setPreviousSignature] = useState(null);
  const [loader, setLoader] = useState(false);
  const [rc, setRc] = useState(false);
  const [values, setValues] = useState({
    injury_name: "",
    age: "",
    gender: "",
    contact_no: "",
    address: "",
    email: "",
    nationality: "",
    injury_type: [],
    is_bodily_injury: "Yes",
    other: "",
    injury_desription: "",
    casualty_no: 0,
    allow_up: "Yes",
  });
  const [causedInjuryList, setCausedInjuryList] = useState([]);
  const [other_injury, setOtherInjury] = useState(null);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  // error
  const [injuryNameError, setInjuryNameError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorTxt, setEmailErrorTxt] = useState("");
  const [nationalityError, setNationalityError] = useState(false);
  const [calssificationError, setCalssificationError] = useState(false);
  const [contractorError, setContractorError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [desriptionError, setDesriptionError] = useState(false);
  const [otherError, setOtherError] = useState(false);
  const [signatureError, setSignatureError] = useState(false);

  const sigPad = useRef({});
  const {
    changeShowCasualyFormBool,
    changeHideCasualyFormBool,
    showCasualtyForm,
    selectedDetail,
  } = props;

  const clearSignature = () => {
    sigPad.current.clear();
  };

  const doneSignature = () => {
    setSignatureImage(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  useEffect(() => {
    db.collection("static_data")
      .doc("what_caused_injury")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.data().what_caused_injury_arr.forEach((doc, index) => {
          data.push({
            value: doc,
            text: doc,
            key: doc,
          });
        });

        setCausedInjuryList(data);
      });
  }, []);

  useEffect(() => {
    setLoader(false);
    let index = props.totalForms.length > 0 && props.totalForms.length - 1;
    let c_no =
      parseInt(
        props.totalForms.length > 0 &&
          props.totalForms[index].casualty_no.split(/\s+/)[1]
      ) + 1;
    if (selectedDetail) {
      const {
        injury_name,
        age,
        gender,
        contact_no,
        address,
        email,
        nationality,
        incident_calssification,
        contractor_shift,
        is_bodily_injury,
        injury_type,
        injury_desription,
        signature,
        casualty_no,
        other,
        allow_up,
      } = selectedDetail;
      setValues({
        injury_name,
        age,
        gender,
        contact_no,
        address,
        email,
        nationality,
        incident_calssification,
        contractor_shift,
        is_bodily_injury,
        injury_type,
        injury_desription,
        casualty_no,
        other,
        allow_up,
      });
      setSignatureImage(signature);
      setPreviousSignature(signature);
    } else {
      setValues({
        injury_name: "",
        age: "",
        gender: "",
        contact_no: "",
        address: "",
        email: "",
        nationality: "",
        incident_calssification: "",
        contractor_shift: "",
        is_bodily_injury: "Yes",
        injury_type: [],
        injury_desription: "",
        other: "",
        // casualty_no: `Casualty ${
        //   props.totalForms.length == 0 ? 1 : props.totalForms.length + 1
        // }`,
        casualty_no: `Casualty ${props.totalForms.length == 0 ? 1 : c_no}`,
        allow_up: "Yes",
      });
      setSignatureImage(null);
      setPreviousSignature(null);
    }
  }, [selectedDetail]);

  const submitForm = () => {
    setRc(!rc);
    const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
    let data = {
      ...values,
      signature: signatureImage
        ? signatureImage.replace("data:image/png;base64,", "")
        : null,
    };

    if (data.injury_name == "") {
      // setInjuryNameError("Required Field");
    } else {
      // if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(data.injury_name)) {
      if (/[^A-Za-z ]/g.test(data.injury_name)) {
        setInjuryNameError("Must be valid.");
      } else {
        setInjuryNameError("");
      }
    }

    if (data.age == "") {
      // setAgeError("Required Field");
    } else {
      if (!/^[0-9]+$/.test(data.age) || data.age > 100 || data.age == 0) {
        setAgeError("Must be vaild.");
      } else {
        setAgeError("");
      }
    }

    if (data.gender == "") {
      setGenderError("Required Field");
    } else {
      setGenderError("");
    }

    if (data.address == "" && data.contact_no == "" && data.email == "") {
      setAddressError(
        "Please one must be filled between address, email and contact number."
      );
      setContactError(
        "Please one must be filled between address, email and contact number."
      );
      setEmailError(true);
      setEmailErrorTxt(
        "Please one must be filled between address, email and contact number."
      );
    }
    if (data.address.length > 0) {
      if (data.address.length < 3) {
        setAddressError("Please enter at least 3 characters.");
      } else {
        if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(data.address)) {
          setAddressError("Must be vaild");
          // setEmailError(false);
          // setContactError("");
        } else {
          setAddressError("");
          setEmailError(false);
          setContactError("");
        }
      }
    } else {
      setAddressError("");
    }

    if (data.email.length > 0) {
      // if (data.email.length == 0) {
      //   setEmailErrorTxt(
      //     "Please one must be filled between address, email and contact number."
      //   );
      //   setEmailError(true);
      // } else {
      if (!regex.test(data.email)) {
        setEmailErrorTxt("Must be valid");
        setEmailError(true);
        // setContactError("");
        // setAddressError("");
      } else {
        setAddressError("");
        setContactError("");
        setEmailError(false);
      }
      // }
    } else {
      setEmailError(false);
      setEmailErrorTxt("");
    }

    if (data.contact_no.length > 0) {
      if (data.contact_no == "") {
        setContactError(
          "Please one must be filled between address, email and contact number."
        );
      } else if (data.contact_no.length > 15) {
        setContactError("Must be valid.");
        // setAddressError("");
        // setEmailError(false);
      } else {
        if (
          !/^\d*(\.\d+)?$/.test(data.contact_no) ||
          data.contact_no.length > 15 ||
          !/^[0-9]+$/.test(data.contact_no)
        ) {
          setContactError("Must be vaild");
        } else {
          setContactError("");
          if (addressError && addressError.includes("Please one must be")) {
            setAddressError("");
          }
          if (emailErrorTxt && emailErrorTxt.includes("Please one must be")) {
            setEmailError(false);
          }
        }
      }
    } else {
      setContactError("");
    }

    // if (data.address == "" && data.contact_no == "" && data.email == "") {
    //   setAddressError("");
    //   setContactError("");
    //   setEmailError(false);
    //   setEmailErrorTxt("");
    // } else {
    //   setAddressError(
    //     "Please one must be filled between address, email and contact number."
    //   );
    //   setContactError(
    //     "Please one must be filled between address, email and contact number."
    //   );
    //   setEmailError(true);
    //   setEmailErrorTxt(
    //     "Please one must be filled between address, email and contact number."
    //   );
    // }

    if (data.incident_calssification == "") {
      setCalssificationError("Required Field");
    } else {
      if (
        /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
          data.incident_calssification
        )
      ) {
        setCalssificationError("Must be valid.");
      } else {
        setCalssificationError("");
      }
    }

    if (data.contractor_shift == "") {
      setContractorError("Required Field");
    } else {
      setContractorError("");
    }

    if (values.is_bodily_injury == "Yes") {
      if (data.injury_type == "" || data.injury_type.length == 0) {
        setTypeError("Required Field");
      } else {
        setTypeError("");
      }
      if (data.injury_type.includes("Others")) {
        if (data.other == "") {
          setOtherError("Required Field");
        } else {
          if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(data.other)) {
            setOtherError(true);
          } else {
            setOtherError(false);
          }
        }
      }
    } else {
      setValues({
        ...values,
        injury_type: [],
        other: "",
      });
      setTypeError("");
      setOtherError("");
    }

    // if (data.signature == null) {
    //   setSignatureError(true);
    // } else {
    //   setSignatureError(false);
    // }

    // if (data.injury_desription == "") {
    //   setDesriptionError("Required Field");
    // } else {
    //   setDesriptionError("");
    // }
  };

  useEffect(() => {
    const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

    if (submit) {
      let data = {
        ...values,
        signature: signatureImage
          ? signatureImage.replace("data:image/png;base64,", "")
          : null,
      };

      if (data.address.length > 0) {
        if (data.address.length < 3) {
          setAddressError("Please enter at least 3 characters.");
        } else {
          if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(data.address)) {
            setAddressError("Must be vaild");
            // setEmailError(false);
            // setContactError("");
          } else {
            setAddressError("");
            setEmailError(false);
            setContactError("");
          }
        }
      }

      if (data.email.length > 0) {
        if (data.email.length == 0) {
          setEmailErrorTxt(
            "Please one must be filled between address, email and contact number."
          );
          setEmailError(true);
        } else {
          if (!regex.test(data.email)) {
            setEmailErrorTxt("Must be valid");
            setEmailError(true);
            // setContactError("");
            // setAddressError("");
          } else {
            setAddressError("");
            setContactError("");
            setEmailError(false);
          }
        }
      }

      if (data.contact_no.length > 0) {
        if (data.contact_no == "") {
          setContactError(
            "Please one must be filled between address, email and contact number."
          );
        } else if (data.contact_no.length > 15) {
          setContactError("Must be valid.");
          // setAddressError("");
          // setEmailError(false);
        } else {
          if (
            !/^\d*(\.\d+)?$/.test(data.contact_no) ||
            data.contact_no.length > 15 ||
            !/^[0-9]+$/.test(data.contact_no)
          ) {
            setContactError("Must be vaild");
          } else {
            setContactError("");
            if (addressError && addressError.includes("Please one must be")) {
              setAddressError("");
            }
            if (emailErrorTxt && emailErrorTxt.includes("Please one must be")) {
              setEmailError(false);
            }
          }
        }
      } else {
        setContactError("");
      }
      // if (data.address == "" && data.contact_no == "" && data.email == "") {
      //   setAddressError("");
      //   setContactError("");
      //   setEmailError(false);
      //   setEmailErrorTxt("");
      // } else {
      //   setAddressError(
      //     "Please one must be filled between address, email and contact number."
      //   );
      //   setContactError(
      //     "Please one must be filled between address, email and contact number."
      //   );
      //   setEmailError(true);
      //   setEmailErrorTxt(
      //     "Please one must be filled between address, email and contact number."
      //   );
      // }
      if (
        // !injuryNameError &&
        // !ageError &&
        !genderError &&
        !typeError &&
        !otherError
      ) {
        if (!contactError && !addressError && !emailError) {
          // setContactError("");
          // setEmailError(false);
          // setAddressError("");

          if (data) {
            setLoader(true);

            const nameForImage = new Date().getTime() + Math.random();

            if (previousSignature == signatureImage) {
              props.addForm(data);

              return props.changeHideCasualyFormBool();
            } else {
              if (previousSignature) {
                storage
                  .refFromURL(previousSignature)
                  .delete()
                  .catch((err) => console.log("err", err));
              }
              storage
                .ref(`/signatures/${nameForImage}`)
                .putString(signatureImage, "data_url")
                .then(async (img) => {
                  const image = await storage
                    .ref(`/signatures/${nameForImage}`)
                    .getDownloadURL();

                  data.signature = image;
                  const cloneRemovedPhotoValues = props.newAddedSignature;
                  cloneRemovedPhotoValues.push(image);
                  props.setNewAddedSignature(cloneRemovedPhotoValues);
                  props.addForm(data);

                  return props.changeHideCasualyFormBool();
                })
                .catch((err) => console.log("err", err));
            }
          } else {
            setLoader(false);
            return null;
          }
        }
      } else {
        setLoader(false);
        return null;
      }
      setSubmit(false);
    }
  }, [
    // injuryNameError,
    // ageError,
    // genderError,
    // contactError,
    // addressError,
    // emailError,
    // nationalityError,
    // typeError,
    // desriptionError,
    // otherError,
    // signatureImage,
    submit,
    rc,
  ]);

  const { nationalityList } = props.optionsState;

  return (
    <div className="casual_form_container">
      <div className="casual_form">
        <div className="casual_form_title">Casualty details</div>
        {/* First Line  */}
        <div
          style={{
            display: "flex",
            marginBottom: 30,
            flexDirection: isMobile && "column",
          }}
        >
          <div style={{ width: !isMobile && "60%" }}>
            <h3 className={`input_title ${injuryNameError && "error"}`}>
              Name
            </h3>
            <Input
              className={`casual_input not_fluid ${injuryNameError && "error"}`}
              value={values.injury_name}
              onKeyPress={(event) => {
                // if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(event.key)) {
                if (/[^A-Za-z ]/g.test(event.key)) {
                  event.preventDefault();
                }
              }}
              style={{ width: isMobile && "100%" }}
              onChange={(e) => {
                setValues({
                  ...values,
                  injury_name: e.target.value,
                });
              }}
            />
            {injuryNameError && (
              <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
                {injuryNameError}
              </div>
            )}
          </div>

          <div className="casual_dropdown_gender_container">
            <h3 className={`input_title ${ageError && "error"}`}>Age</h3>
            <Input
              maxLength={3}
              className={`casual_input  ${ageError && "error"}`}
              style={{
                width: !isMobile && 180,
                display: "flex",
                justifyContent: "flex-end",
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              // icon_classes="incident-place-list-icon-age"
              value={values.age}
              onChange={(e, data) => {
                setValues({
                  ...values,
                  age: e.target.value,
                });
              }}
            />
            {/* <Input
            className={`casual_input ${contactError && "error"}`}
            value={values.contact_no}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              setValues({
                ...values,
                contact_no: e.target.value,
              });
            }}
          /> */}
            {ageError && (
              <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
                {ageError}
              </div>
            )}
          </div>

          <div className="casual_dropdown_gender_container">
            <h3 className={`input_title ${genderError && "error"}`}>Gender*</h3>
            <Dropdown
              selection
              options={[
                {
                  value: "Male",
                  text: "Male",
                },
                {
                  value: "Female",
                  text: "Female",
                },
                {
                  value: "Unknown",
                  text: "Unknown",
                },
              ]}
              className={`casual_dropdown_gender ${genderError && "error"}`}
              icon={
                <img
                  src={ArrowDownDropDownIcon}
                  className="incident-place-list-icon-age"
                />
              }
              placeholder="Please Select..."
              value={values.gender}
              onChange={(e, data) => {
                setValues({
                  ...values,
                  gender: data.value,
                });
              }}
            />
            {genderError && (
              <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
                {genderError}
              </div>
            )}
          </div>
        </div>

        {/* Second Line */}

        {/* Third Line */}
        <div style={{ width: "100%", marginBottom: 30 }}>
          <h3 className={`input_title ${addressError && "error"}`}>Address </h3>
          <Input
            className={`casual_input ${addressError && "error"}`}
            value={values.address}
            // onKeyPress={(event) => {
            //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
            //     event.preventDefault();
            //   }
            // }}
            onChange={(e) => {
              setValues({
                ...values,
                address: e.target.value,
              });
            }}
          />
          {addressError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              {addressError}
            </div>
          )}
        </div>
        <div style={{ flex: 1, marginBottom: 30 }}>
          <h3 className={`input_title ${emailError && "error"}`}>
            Email address
          </h3>
          <Input
            className={`casual_input  ${emailError && "error"}`}
            value={values.email}
            onChange={(e) => {
              setValues({
                ...values,
                email: e.target.value,
              });
            }}
          />
          {emailError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              {emailErrorTxt}
            </div>
          )}
        </div>
        {/* Fourth Line */}
        <div style={{ display: "flex", marginBottom: 30 }}>
          <div style={{ flex: 2, marginBottom: 30 }}>
            <h3 className={`input_title  ${contactError && "error"}`}>
              Contact Number.
            </h3>
            <Input
              className={`casual_input not_fluid ${contactError && "error"}`}
              value={values.contact_no}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                setValues({
                  ...values,
                  contact_no: e.target.value,
                });
              }}
            />
            {contactError && (
              <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
                {contactError}
              </div>
            )}
          </div>
          <div
            className="casual_divide_mid_container spacer"
            style={{ flex: 1 }}
          >
            <h3 className={`input_title ${nationalityError && "error"}`}>
              Nationality{" "}
            </h3>
            <Dropdown
              selection
              compact
              className={`casual_dropdown_age ${nationalityError && "error"}`}
              icon={
                <img
                  src={ArrowDownDropDownIcon}
                  className="incident-place-list-icon-age"
                />
              }
              placeholder="Please Select..."
              options={nationalityList}
              value={values.nationality}
              onChange={(e, data) => {
                setValues({
                  ...values,
                  nationality: data.value,
                });
              }}
            />
            {nationalityError && (
              <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
                {nationalityError}
              </div>
            )}
          </div>
        </div>
        {/* Fifth Line */}
        {/* <div
          style={{
            display: "flex",
            marginBottom: 30,
            justifyContent: "space-between",
          }}
        ></div> */}
        {/* CheckBox Container */}
        <div
          className="checkbox_container"
          style={{ marginBottom: 30, marginTop: -30 }}
        >
          <h3>Is there bodily injury?* </h3>
          <div>
            <Checkbox
              radio
              label="Yes"
              checked={values.is_bodily_injury == "Yes"}
              onChange={(e, data) =>
                setValues({
                  ...values,
                  is_bodily_injury: "Yes",
                })
              }
              className="checkBox"
            />
          </div>
          <div>
            <Checkbox
              radio
              label="No"
              checked={values.is_bodily_injury == "No"}
              onChange={(e, data) => {
                setValues({
                  ...values,
                  is_bodily_injury: "No",
                  injury_type: [],
                });
                setTypeError(false);
              }}
              className="checkBox"
            />
          </div>
        </div>
        {/* Sixth Line */}
        <div className="dropdown_fluid">
          <h3 className={`input_title ${typeError && "error"}`}>
            What caused the injury? *
          </h3>
          <Dropdown
            selection
            compact
            options={causedInjuryList}
            className={`casual_dropdown_age ${typeError && "error"}`}
            icon={
              <img
                src={ArrowDownDropDownIcon}
                className="incident-place-list-icon-age"
              />
            }
            multiple
            disabled={values.is_bodily_injury == "No" ? true : false}
            value={values.injury_type}
            onChange={(e, data) => {
              setValues({
                ...values,
                injury_type: data.value,
              });
              setOtherInjury(null);
            }}
          />
          {typeError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              {typeError}
            </div>
          )}
        </div>
        {values.injury_type.includes("Others") && (
          <div style={{ marginBottom: 30 }}>
            <h3>Others*</h3>
            <Input
              fluid
              className={`damaged_input  ${otherError && "error"}`}
              value={values.other}
              onKeyPress={(event) => {
                if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) =>
                setValues({
                  ...values,
                  other: e.target.value,
                })
              }
            />
            {otherError && (
              <div style={{ color: "#E72D35", marginTop: 15 }}>
                Required Field
              </div>
            )}
          </div>
        )}
        {/* <div style={{ marginTop: 80 }}>
          <h3 style={{ color: otherError && "#E72D35" }}>Other</h3>
          <Input
            fluid
            className={`damaged_input ${otherError && "error"}`}
            value={values.other}
            onChange={(e) => {
              setValues({
                ...values,
                other: e.target.value,
              });
            }}
          />
        </div>
        {otherError && (
          <div style={{ color: "#E72D35", marginTop: 15 }}>
            Please make a decision
          </div>
        )} */}
        {/* Seventh Line */}
        <div style={{ marginBottom: 30, marginTop: 30 }}>
          <h3 className={`input_title ${desriptionError && "error"}`}>
            Description of Injury
          </h3>
          <textarea
            className={`remark_text_area ${desriptionError && "error"}`}
            value={values.injury_desription}
            // onKeyPress={(event) => {
            //   if (/[$&+,:;=?[\]@#|{}'<>.^*()%!-/`~]/.test(event.key)) {
            //     event.preventDefault();
            //   }
            // }}
            onChange={(e) => {
              setValues({
                ...values,
                injury_desription: e.target.value,
              });
            }}
          ></textarea>
          {desriptionError && (
            <div style={{ color: "#E72D35", marginLeft: 3, fontSize: 13 }}>
              {desriptionError}
            </div>
          )}
        </div>
        {/* CheckBox Container */}
        <div className="checkbox_container" style={{ marginBottom: 30 }}>
          <h3>Allow to follow-up with you? </h3>
          {/* {values.allow_up} */}
          <div>
            <Checkbox
              radio
              label="Yes"
              checked={values.allow_up == "Yes"}
              onChange={(e, data) =>
                setValues({
                  ...values,
                  allow_up: "Yes",
                })
              }
              className="checkBox"
            />
          </div>
          <div>
            <Checkbox
              radio
              label="No"
              checked={values.allow_up == "No"}
              onChange={(e, data) =>
                setValues({
                  ...values,
                  allow_up: "No",
                })
              }
              className="checkBox"
            />
          </div>
        </div>
        <p>
          By signing this form, you consent to the collection, use and
          disclosure of your personal data for the purpose to manage and prepare
          any incidents or accidents report in accordance with the Personal Data
          Protection Act 2012 and our data protection policy is available at{" "}
          <a
            href="https://www.mandai.com/"
            target="_blank"
            style={{ color: "#228A4C" }}
          >
            www.mandai.com :{" "}
          </a>
        </p>

        <p>
          Please visit our website at{" "}
          <a
            href="https://www.mandai.com/"
            target="_blank"
            style={{ color: "#228A4C" }}
          >
            www.mandai.com
          </a>{" "}
          for further details on our data protection policy, including how you
          may access and correct your personal data or withdraw consent to the
          collection, use or disclosure of your personal data.
        </p>
        {/* eighth Line */}
        <div style={{ marginBottom: 100 }}>
          <h3
            className="input_title"
            style={{ color: signatureError ? "#e72d35" : null }}
          >
            Signature
          </h3>
          <div
            style={{
              width: "100%",
              height: 360,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 12,
              border: signatureError
                ? " 1px solid #e72d35 "
                : "1px solid #D8D8D8",
              backgroundColor: signatureError && "#fff0f0",
            }}
          >
            <Popup
              modal
              trigger={
                <div
                  style={{
                    width: "95%",
                    height: 300,
                    border: "1px solid #D8D8D8",
                    borderRadius: 12,
                    backgroundImage: `url(${signatureImage})`,
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                    backgroundPosition: "center",
                  }}
                >
                  <div>
                    {" "}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSignatureImage(null);
                      }}
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        background: "none",
                        padding: "10px 30px",
                        color: "#008237",
                        fontWeight: "bold",
                        border: "1px solid #008237",
                        borderRadius: 12,
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              }
              closeOnDocumentClick={false}
            >
              {(close) => {
                return (
                  <div className="signature_Container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <img src={ExitIcon} onClick={close} />
                    </div>
                    <h3 className="input_title">Signature</h3>

                    <SignaturePad
                      ref={sigPad}
                      canvasProps={{
                        className: "signatureCanvas",
                      }}
                    />
                    <div>
                      <button
                        onClick={clearSignature}
                        className="signature_clear_button"
                      >
                        Clear
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        onClick={close}
                        className="signature_reset_button"
                      >
                        Cancel
                      </button>

                      <button
                        onClick={() => {
                          doneSignature();
                          close();
                        }}
                        className="signature_done_button"
                      >
                        Done
                      </button>
                    </div>
                  </div>
                );
              }}
            </Popup>
          </div>
        </div>
        {signatureError && (
          <div
            style={{
              color: "#E72D35",
              marginLeft: 3,
              fontSize: 13,
              marginTop: -100,
            }}
          >
            {" "}
            Required field
          </div>
        )}
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: -50 }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{ width: "100%" }}
            onClick={() => changeHideCasualyFormBool()}
          >
            <Button className="casual_cancel_button">Cancel </Button>
          </div>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              setRc(!rc);
              setSubmit(true);
              submitForm();
            }}
          >
            <Button
              className="casual_save_button"
              style={{ width: 180 }}
              onClick={() => setRc(!rc)}
            >
              {loader ? (
                <Loader
                  active
                  inline="centered"
                  size="tiny"
                  inverted
                  indeterminate
                />
              ) : (
                <span>Add Casualty </span>
              )}
            </Button>
          </div>
        </div>
      </div>

      {/* Main Footer */}
      <div
        className="incident_footer"
        style={{
          bottom: -65,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          {props.overViewValues.is_emergency_incident == "Yes" ||
          props.showNotiPage ? (
            <div
              onClick={() => {
                props.setCheckStatus("Yes");
                props.mainSaveButton();
              }}
            >
              <Button className="witness_cancel_button">Submit </Button>
            </div>
          ) : null}
          {props.paramPendingBool && (
            <div>
              <Button
                className="incident_cancel_button"
                onClick={props.deletePendingItem}
              >
                Delete
              </Button>
            </div>
          )}
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              if (props.changedValue) {
                return confirmAlert({
                  customUI: ({ onClose }) =>
                    props.customModalForConfirmCancel(onClose),
                });
              } else {
                history.push("/incidents_list");
              }
            }}
          >
            <Button className="witness_cancel_button">Cancel</Button>
          </div>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              props.mainSaveButton();
            }}
          >
            <Button className="witness_save_button">Save</Button>
          </div>
        </div>
      </div>
      {/* <div className="casual_footer">
        <div style={{ display: "flex" }}>
          <div
            style={{ width: "100%" }}
            onClick={() => changeHideCasualyFormBool()}
          >
            <Button className="casual_cancel_button">Cancel </Button>
          </div>
          <div
            style={{ width: "100%" }}
            onClick={() => {
              setRc(!rc);
              setSubmit(true);
              submitForm();
            }}
          >
            <Button className="casual_save_button">
              {loader ? (
                <Loader
                  active
                  inline="centered"
                  size="tiny"
                  inverted
                  indeterminate
                />
              ) : (
                <span>Save</span>
              )}
            </Button>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default CasualtyForm;
