import React, { useState, useEffect } from "react";
import { Dropdown, Input } from "semantic-ui-react";

//dropdowns
import RoleList from "../../../../components/RoleList";
import UserGroupList from "../../../../components/UserGroupList";

//images
import DeleteIcon from "../../../../assets/images/incident/btn_delete.svg";

//css
import "./forms.css";

function UserPermission() {

  return (
    <div className="userpermission_container">
      <h3>Role </h3>
      <RoleList custom_classes="profile-list-dropdown" icon_classes="icon-profile-list-dropdown" />

      <h3>User group </h3>
      <UserGroupList custom_classes="profile-list-dropdown group-list-dropdown" icon_classes="icon-group-list-dropdown" />
      
    </div>
  );
}

export default UserPermission;
